import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import Hidden from '@mui/material/Hidden'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import Typography from '@mui/material/Typography'
import { values } from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'
import { CSSObject } from 'tss-react'
import { withStyles } from 'tss-react/mui'

import Logo from '@/app/component/atom/logo'
import IconText from '@/app/component/layout/icon-text'
import { PAGE } from '@/app/definitions'
import { Balance, Organization, SelectOption } from '@/app/types'

import AccountMenu from './account-menu'
import HelpMenu from './help-menu'
import OrgBalance from './org-balance'

const nonAuthNavMap = [
  {
    name: PAGE.ACCOUNT.LOGIN,
    title: 'Log in',
    url: '/login',
    showInHeader: true,
  },
  {
    name: PAGE.ACCOUNT.SIGNUP,
    title: 'Sign up',
    url: '/signup',
    showInHeader: true,
  },
  {
    name: PAGE.ACCOUNT.REQUEST_RESET_PASSWORD,
    title: 'Password Reset',
    url: '/request-reset-password',
    showInHeader: false,
  },
  {
    name: PAGE.ACCOUNT.RESET_PASSWORD,
    title: 'Password Reset',
    url: '/reset-password',
    showInHeader: false,
  },
]

const authNavMap = [
  {
    id: 'contacts',
    name: PAGE.CONTACTS.INDEX,
    title: 'Contacts',
    url: '/contacts',
    icon: 'perm_contact_calendar',
    isActive: (currentPage: string) => values(PAGE.CONTACTS).indexOf(currentPage) > -1,
  },
  {
    id: 'campaigns',
    name: PAGE.CAMPAIGNS.INDEX,
    title: 'Campaigns',
    url: '/campaigns',
    icon: 'dns',
    isActive: (currentPage: string) => values(PAGE.CAMPAIGNS).indexOf(currentPage) > -1,
  },
  {
    id: 'conversations',
    name: PAGE.CONVERSATIONS.INDEX,
    title: 'Conversations',
    url: '/conversations',
    icon: 'message',
    isActive: (currentPage: string) => values(PAGE.CONVERSATIONS).indexOf(currentPage) > -1,
  },
  {
    id: 'logs',
    name: PAGE.LOGS.INDEX,
    title: 'Logs',
    url: '/logs',
    icon: 'message',
    isActive: (currentPage: string) => values(PAGE.LOGS).indexOf(currentPage) > -1,
  },
  {
    id: 'phoneNumbers',
    name: PAGE.PHONENUMBERS.INDEX,
    title: 'Phone Numbers',
    url: '/phonenumbers',
    icon: 'phone',
    isActive: (currentPage: string) => values(PAGE.PHONENUMBERS).indexOf(currentPage) > -1,
  },
  {
    id: 'analytics',
    name: PAGE.ANALYTICS.INDEX,
    title: 'Analytics',
    url: '/analytics',
    isActive: (currentPage: string) => values(PAGE.ANALYTICS).indexOf(currentPage) > -1,
  },
  {
    id: 'exports',
    name: PAGE.EXPORTS.INDEX,
    title: 'Exports',
    url: '/exports',
    icon: 'export',
    isActive: (currentPage: string) => values(PAGE.EXPORTS).indexOf(currentPage) > -1,
  },
]

const accountNavMap = [
  {
    title: 'Dashboard',
    url: '/',
    icon: 'home',
    isActive: (currentPage: string) => values(PAGE.DASHBOARD).indexOf(currentPage) > -1,
    isHidden: (hasOrg: boolean) => !hasOrg,
  },
  {
    title: 'Profile',
    url: '/account/profile',
    icon: 'work',
    isActive: (currentPage: string) => values(PAGE.ACCOUNT.PROFILE).indexOf(currentPage) > -1,
    isHidden: () => false,
  },
  {
    title: 'Billing',
    url: '/account/billing',
    icon: 'payment',
    isActive: (currentPage: string) => values(PAGE.ACCOUNT.BILLING).indexOf(currentPage) > -1,
    isHidden: (hasOrg: boolean) => !hasOrg,
  },
  {
    title: 'Organization',
    url: '/account/organization',
    icon: 'supervisor_account',
    isActive: (currentPage: string) => values(PAGE.ACCOUNT.ORGANIZATION).indexOf(currentPage) > -1,
    isHidden: (hasOrg: boolean) => !hasOrg,
  },
]

type Classes = {
  accountMenuContainer: string
  content: string
  drawer: string
  dropdownIcon: string
  dropdownLink: string
  helpMenuContainer: string
  itemContainer: string
  link: string
  linkActive: string
  nav: string
  right: string
}

type Props = {
  activeOrg?: Organization
  balance: Balance
  classes?: Classes
  currentPage: string
  email: string
  token: string
  organizations?: SelectOption<number>[]

  logout?: () => void
  switchOrganization: (args: {
    token: string
    item: {
      label: string
      value: number
    }
  }) => Promise<void>
}

type State = {
  accountMenuOpen: boolean
  anchorHelpMenu: Element | null
  mobileMenuOpen: boolean
  orgListExpanded: boolean
}

class Header extends React.PureComponent<Props, State> {
  state = {
    accountMenuOpen: false,
    anchorHelpMenu: null,
    mobileMenuOpen: false,
    orgListExpanded: false,
  }

  toggleAccountMenu = () => {
    this.setState({
      accountMenuOpen: !this.state.accountMenuOpen,
      orgListExpanded: false,
    })
  }

  toggleMobileNav = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    })
  }

  togglePanelExpand = () => {
    this.setState({
      orgListExpanded: !this.state.orgListExpanded,
    })
  }

  renderNonAuthNav = () => {
    const classes = withStyles.getClasses(this.props)

    return (
      <div
        style={{
          display: 'flex',
          textAlign: 'right',
        }}
      >
        {nonAuthNavMap
          .filter(({ name, showInHeader }) => this.props.currentPage !== name && showInHeader)
          .map(({ title, url }) => (
            <Link key={url} to={url} className={classes.link}>
              <Button variant="outlined" color="inherit" style={{ borderColor: '#fff' }}>
                {title}
              </Button>
            </Link>
          ))}
      </div>
    )
  }

  renderAuthNav = () => {
    const classes = withStyles.getClasses(this.props)

    return (
      <>
        <Hidden smUp>
          <Drawer open={this.state.mobileMenuOpen} onClose={this.toggleMobileNav}>
            <div tabIndex={0} role="button" onClick={this.toggleMobileNav}>
              <List component="nav" className={classes.drawer}>
                {authNavMap.map((navItem) => (
                  <ListItemButton id={`nav-mobile-${navItem.id}`} key={navItem.url} component={Link} to={navItem.url}>
                    <IconText>
                      <Icon>{navItem.icon}</Icon>
                      <Typography>{navItem.title}</Typography>
                    </IconText>
                  </ListItemButton>
                ))}
              </List>
            </div>
          </Drawer>
        </Hidden>
        <nav id="header-nav" className={classes.nav}>
          <Hidden xsDown>
            <ul>
              {authNavMap.map((navItem) => (
                <li key={navItem.url}>
                  <Link
                    id={`nav-${navItem.id}`}
                    to={navItem.url}
                    className={navItem.isActive(this.props.currentPage) ? classes.linkActive : classes.link}
                  >
                    <Button
                      color="inherit"
                      sx={{
                        paddingLeft: (theme) => theme.spacing(2),
                        paddingRight: (theme) => theme.spacing(2),
                      }}
                    >
                      {navItem.title}
                    </Button>
                  </Link>
                </li>
              ))}
            </ul>
          </Hidden>
        </nav>
      </>
    )
  }

  renderAccountMenu = () => {
    const classes = withStyles.getClasses(this.props)
    const {
      activeOrg,
      balance,
      currentPage,
      email,
      organizations = [],
      token,
      logout = () => {},
      switchOrganization = () => Promise.resolve(),
    } = this.props
    const { accountMenuOpen, orgListExpanded } = this.state

    const isAuthorized = !!token
    const hasOrg = isAuthorized && !!activeOrg

    return (
      <div id="header-account" className={classes.itemContainer}>
        {hasOrg && (
          <OrgBalance balance={balance} balanceIsBelowNotificationAmount={activeOrg.balanceIsBelowNotificationAmount} />
        )}
        <div className={classes.accountMenuContainer}>
          <AccountMenu
            activeOrg={activeOrg}
            currentPage={currentPage}
            email={email}
            hasOrg={hasOrg}
            menuOpen={accountMenuOpen}
            navItems={accountNavMap}
            organizations={organizations}
            panelExpanded={orgListExpanded}
            onLogoutClick={logout}
            onMenuToggle={this.toggleAccountMenu}
            onOrganizationChange={({ label, value }) => {
              this.toggleAccountMenu()
              switchOrganization({ token, item: { label, value } }).then(() => {
                // hacky. Need to wait on all other updates.
                setTimeout(() => {
                  window.location.reload()
                }, 500)
              })
            }}
            onPanelToggle={this.togglePanelExpand}
          />
        </div>
      </div>
    )
  }

  render() {
    const classes = withStyles.getClasses(this.props)
    const { activeOrg, token } = this.props

    const isAuthorized = !!token
    const hasOrg = isAuthorized && !!activeOrg

    return (
      <>
        <Hidden smUp>
          <IconButton color="inherit" className={classes.dropdownIcon} onClick={this.toggleMobileNav}>
            <Icon>dehaze</Icon>
          </IconButton>
        </Hidden>
        <Link to="/" className={classes.link}>
          <Logo />
        </Link>
        <div className={classes.content}>
          {hasOrg && this.renderAuthNav()}
          <div className={classes.right}>
            <div className={classes.helpMenuContainer}>
              <HelpMenu classes={classes} />
            </div>
            {isAuthorized && this.renderAccountMenu()}
            {!isAuthorized && this.renderNonAuthNav()}
          </div>
        </div>
      </>
    )
  }
}

export const linkCss = {
  whiteSpace: 'nowrap !important',
  textDecoration: 'none',
  color: 'inherit',
  margin: '0 0.25rem',
}

const styles = () =>
  ({
    link: {
      ...linkCss,
    },
    linkActive: {
      ...linkCss,
      '& button': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      },
    },
    content: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      maxWidth: '100%',
    },
    dropdownLink: {
      width: '100%',
      display: 'flex',
      textDecoration: 'none',
      color: 'inherit',
    },
    nav: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',

      '& ul': {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        listStyleType: 'none',
        margin: '0',
        padding: '0 0 0 8px',
      },

      '& li a': {
        display: 'block',
      },

      '& li a button': {
        fontSize: '1rem',
      },
    },
    drawer: {
      width: '250px',
    },
    dropdownIcon: {
      marginRight: '1rem',
    },
    itemContainer: {
      display: 'flex',
    },
    accountMenuContainer: {
      alignSelf: 'center',
    },
    helpMenuContainer: {
      alignSelf: 'center',
      marginRight: '15px',
    },
    right: {
      display: 'flex',
    },
  }) as Record<keyof Classes, CSSObject>

export default withStyles(Header, styles)
