import { Icon, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import ExternalLink from '@mui/material/Link'
import React from 'react'

import IconText from '@/app/component/layout/icon-text'
import { TYPES } from '@/app/module/campaigns/definitions'
import { REPEAT_TOUR_KEY } from '@/app/module/tour/definitions'

type Classes = {
  dropdownLink: string
}

type Props = {
  classes: Pick<Classes, 'dropdownLink'>
}

const w = window as any

const HelpMenu: React.FC<Props> = ({ classes }) => {
  const anchorEl = React.useRef<HTMLButtonElement | null>(null)
  const [open, setOpen] = React.useState(false)

  const toggleMenu = React.useCallback(() => {
    setOpen((s) => !s)
  }, [])

  return (
    <>
      <IconButton id="header-help-trigger" color="inherit" ref={anchorEl} onClick={toggleMenu}>
        <Icon>help_outline</Icon>
      </IconButton>
      <Menu id="header-help-menu" open={open} onClose={toggleMenu} anchorEl={anchorEl.current}>
        <MenuItem id="header-help-menu-help">
          <ExternalLink
            target="_blank"
            rel="noopener"
            href="https://www.engagespark.com/support"
            className={classes.dropdownLink}
          >
            <IconText>
              <Icon>help</Icon>
              <Typography>Help Center</Typography>
            </IconText>
          </ExternalLink>
        </MenuItem>
        <MenuItem id="header-help-menu-blog">
          <ExternalLink
            target="_blank"
            rel="noopener"
            href="https://www.engagespark.com/blog/"
            className={classes.dropdownLink}
          >
            <IconText>
              <Icon>bookmark</Icon>
              <Typography>Blog</Typography>
            </IconText>
          </ExternalLink>
        </MenuItem>
        <MenuItem id="header-help-menu-video">
          <ExternalLink
            target="_blank"
            rel="noopener"
            href="https://www.youtube.com/engagespark"
            className={classes.dropdownLink}
          >
            <IconText>
              <Icon>video_library</Icon>
              <Typography>Video Tutorials</Typography>
            </IconText>
          </ExternalLink>
        </MenuItem>
        <MenuItem>
          <ExternalLink
            target="_blank"
            rel="noopener"
            href="https://www.engagespark.com/support/how-can-i-use-your-api/"
            className={classes.dropdownLink}
          >
            <IconText>
              <Icon>code</Icon>
              <Typography>Developer APIs</Typography>
            </IconText>
          </ExternalLink>
        </MenuItem>
        <MenuItem id="header-help-menu-chat" disabled={!w.Intercom}>
          <ExternalLink onClick={() => w.Intercom('show')} className={classes.dropdownLink}>
            <IconText>
              <Icon>support_agent</Icon>
              <Typography>Chat with Support</Typography>
            </IconText>
          </ExternalLink>
        </MenuItem>
        <MenuItem id="header-help-menu-tour-drip">
          <ExternalLink href={`/campaigns/create/drip?${REPEAT_TOUR_KEY}=true`} className={classes.dropdownLink}>
            <IconText>
              <Icon>{TYPES.DRIP.icon}</Icon>
              <Typography>Drip Campaign Tour</Typography>
            </IconText>
          </ExternalLink>
        </MenuItem>
      </Menu>
    </>
  )
}

export default HelpMenu
