import React from 'react'
import { useSelector } from 'react-redux'

import { selectShowOrgWarning, selectShowTotpWarning, selectUserTOTP } from '@/app/module/user/store/selectors'
import { selectOrgPlan, selectShowCanceled } from '@/app/module/user/store/ts/selectors'

import CanceledPlanWarning from './canceled-plan-warning'
import TOTPWarning from './totp-warning'

const OrgWarning: React.FC = () => {
  const showOrgTotpWarning = useSelector(selectShowOrgWarning)
  const showTotpWarning = useSelector(selectShowTotpWarning)
  const totpEnabled = useSelector(selectUserTOTP)
  const showCanceledWarning = useSelector(selectShowCanceled)
  const plan = useSelector(selectOrgPlan)

  if (!plan) {
    return null
  }

  return (
    <>
      {showCanceledWarning && plan.end && <CanceledPlanWarning end={plan.end} />}
      {showTotpWarning && <TOTPWarning showOrgWarning={showOrgTotpWarning} totpEnabled={totpEnabled} />}
    </>
  )
}

export default OrgWarning
