import React from 'react'

import AudioPlayer from '@/app/component/atom/audio-player'
import { PartType, TGetFileFunction } from '@/app/module/conversations/types'

type Props = {
  part: PartType
  getFile: TGetFileFunction
}

const getAudioUrl = (part: PartType) => {
  if (Array.isArray(part.audioFiles)) {
    return part.audioFiles[0]
  }
  return part.audioFiles
}

export default function Voice({ part, getFile }: Props) {
  const [playId, setPlaying] = React.useState('')
  const [file, setFile] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const audioUrl = getAudioUrl(part)

  return (
    <AudioPlayer
      playId={playId}
      loading={loading}
      source={file}
      onPlayToggle={(val: string) => {
        setPlaying(val ? part.id : '')
        if (val && !file) {
          setLoading(true)
          getFile(audioUrl).then((res: any) => {
            setFile(res.url)
            setLoading(false)
          })
        }
      }}
    />
  )
}
