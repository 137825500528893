import Button from '@mui/material/Button'

import { ContactType } from '@/app/module/conversations/types'
import { FixMeLater, FunctionType } from '@/config/types'

import Contact from './contact'

type Props = {
  activeContact: ContactType
  setActiveContact: FixMeLater
  contacts: ContactType[]
  loadMore: FunctionType
  nextPage: string
}

export default function Contacts(props: Props) {
  const { activeContact, setActiveContact, contacts, loadMore, nextPage } = props
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {contacts.map((contact: ContactType) => (
        <Contact
          key={contact.contactId}
          setActiveContact={setActiveContact}
          contact={contact}
          isActive={contact.contactId === activeContact.contactId}
        />
      ))}
      {nextPage && (
        <div style={{ margin: 'auto', padding: '15px' }}>
          <Button size="large" onClick={loadMore} color="primary" variant="contained">
            Load More
          </Button>
        </div>
      )}
    </div>
  )
}
