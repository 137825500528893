import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CallIcon from '@mui/icons-material/Call'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grow from '@mui/material/Grow'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { isEmpty } from 'ramda'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import Tooltip from '@/app/component/atom/tooltip'
import Loading from '@/app/component/guard/loading'
import FloatingSaveBar from '@/app/component/layout/floating-save-bar'
import IconText from '@/app/component/layout/icon-text'
import { capitalizeFirstLetter } from '@/app/module/campaigns/helpers'
import { generateError, processContactLabel, renderText } from '@/app/module/cati/helpers'
import { CampaignType, CATIType, RunType } from '@/app/module/cati/types'
import { FunctionType } from '@/config/types'

import CATIErrorLayout from './error'
import StatusModal from './status-modal'

const useStyle = makeStyles()({
  pageCont: {
    maxWidth: '1120px',
    width: '100%',
    margin: 'auto',
    padding: '1.5rem 1rem',
    height: '100%',
  },
  endPageCont: {
    textAlign: 'center',
    marginTop: '70px',
  },
  boxCont: {
    width: '580px',
    textAlign: 'center',
    margin: 'auto',
    boxShadow: '0px 0px 0px 1px rgb(224 224 224)',
    padding: '30px',
  },
  titleText: {
    marginTop: '20px',
  },
  icon: {
    fontSize: '6rem !important',
  },
  endExitButtonsCont: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
  titleCont: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pageTitle: {
    width: '50%',
    textAlign: 'left',
    marginRight: '30px',
  },
  shortenedLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
  },
  floatingSaveBarCont: {
    position: 'sticky',
    bottom: '5px',
    width: '100%',
    zIndex: 9,
  },
  floatingSaveBarSubCont: {
    display: 'flex',
    padding: '1rem 2rem',
    width: '100%',
    maxWidth: '1120px',
    bottom: '0px',
    margin: 'auto',
    background: 'white',
    boxShadow: '0px 0px 0px 1px rgb(224 224 224)',
    borderRadius: '4px',
  },
  catiStepCont: {
    marginTop: '10vh',
    marginBottom: '200px',
  },
  catiStepContent: {
    marginLeft: '5px',
  },
  contactDetailsCont: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: '50%',
  },
  callButtonCont: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '150px',
  },
  callButton: {
    marginLeft: '15px',
  },
  userStatusCont: {
    display: 'flex',
    alignItems: 'center',
  },
})

const exitButtonOptions: { label: string; status: 'save' | 'exit'; tooltip: string; id: string; exitLabel?: string }[] =
  [
    {
      label: 'Save & Continue later',
      exitLabel: 'Save & Keep Survey Open',
      status: 'save',
      tooltip: 'Save & Continue later',
      id: 'cati-save-continue-btn',
    },
    {
      label: 'Save & Close Permanently',
      exitLabel: 'Save & Close Survey Permanently',
      status: 'exit',
      tooltip: 'Save & Close Permanently',
      id: 'cati-save-close-btn',
    },
  ]

export type Props = {
  token: string
  orgId: number
  itemId: string
  runId: string
  error: any
  getCATIItem: FunctionType
  getCATIStep: FunctionType
  toggleCallDialog: FunctionType
  loading: boolean
  data: CATIType
  campaign: CampaignType
  contact: RunType
  done: boolean
  getSubDetails: FunctionType
  setSubStatus: FunctionType
  history: any
  closeCATISurvey: FunctionType
}

export default function CATIItem(props: Props) {
  const {
    token,
    orgId,
    itemId,
    runId,
    error,
    getCATIItem,
    getCATIStep,
    loading,
    data,
    campaign,
    toggleCallDialog,
    contact,
    done,
    getSubDetails,
    setSubStatus,
    history,
    closeCATISurvey,
  } = props
  const { classes } = useStyle()

  const exitButtonGroupRef = React.useRef(null)
  const [exitButtonOpen, setExitButtonOpen] = React.useState(false)
  const [initialized, setInitialized] = React.useState(false)
  const [showStatusModal, setShowStatusModal] = React.useState<null | 'update' | 'save' | 'exit'>(null)
  const [selectedAnswer, setSelectedAnswer] = React.useState('')
  const handleToggle = () => {
    setExitButtonOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: any) => {
    if (exitButtonGroupRef.current && (exitButtonGroupRef.current as any).contains(event.target)) {
      return
    }
    setExitButtonOpen(false)
  }

  const handleMenuItemClick = (status: 'save' | 'exit') => {
    if (campaign.userStatuses && campaign.userStatuses.length > 0) {
      setShowStatusModal(status)
    } else {
      postStatusUpdateAction(status)
    }
    setExitButtonOpen(false)
  }

  React.useEffect(() => {
    if (token && orgId && itemId && runId) {
      getCATIItem({ token, orgId, itemId, runId })
      getSubDetails({ token, orgId, subId: runId })
      setInitialized(true)
    }
  }, [itemId, orgId, runId, token, getCATIItem, getSubDetails])

  const postStatusUpdateAction = (action: string) => {
    if (action === 'save') {
      history.push('/cati/contacts')
      toggleCallDialog({ isOpen: false, contactName: '' })
    } else if (action === 'exit') {
      toggleCallDialog({ isOpen: false, contactName: '' })
      closeCATISurvey({ token, orgId, itemId, runId }).then(() => {
        history.push('/cati/contacts')
      })
    }
  }

  const processedError = generateError(campaign, done, () => history.push('/cati/contacts'), error)
  const contactLabel = processContactLabel(contact)
  return (
    <Loading isLoading={loading || !initialized}>
      {showStatusModal && (
        <StatusModal
          isClose={showStatusModal === 'exit'}
          onCancel={() => setShowStatusModal(null)}
          onConfirm={(updatedStatus: string) => {
            setSubStatus({ token, orgId, subId: runId, status: updatedStatus }).then(() => {
              postStatusUpdateAction(showStatusModal)
              setShowStatusModal(null)
            })
          }}
          onClose={() => {
            postStatusUpdateAction(showStatusModal)
            setShowStatusModal(null)
          }}
          userStatuses={campaign.userStatuses}
          userStatus={data.userStatus}
        />
      )}
      <div className={classes.pageCont}>
        {!isEmpty(processedError) && initialized && (campaign.template || !isEmpty(error)) ? (
          <CATIErrorLayout {...processedError} />
        ) : (
          <>
            {data.state && !data.state.next ? (
              <div id="cati-survey-no-more-questions-cont" className={classes.endPageCont}>
                <div className={classes.boxCont}>
                  <Icon className={classes.icon}>task_alt</Icon>
                  <Typography className={classes.titleText} variant="h4">
                    This survey is now done.
                  </Typography>
                  <div className={classes.endExitButtonsCont}>
                    {exitButtonOptions.map((option) => (
                      <Tooltip key={option.id} title={option.tooltip}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginRight: '5px', marginLeft: '5px' }}
                          id={option.id}
                          key={option.label}
                          onClick={() => handleMenuItemClick(option.status)}
                        >
                          {option.exitLabel}
                        </Button>
                      </Tooltip>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className={classes.titleCont}>
                  <div className={classes.pageTitle}>
                    <Tooltip title={campaign.name}>
                      <Typography variant="h5" color="textSecondary" className={classes.shortenedLabel}>
                        {!isEmpty(campaign) ? campaign.name : ''}
                      </Typography>
                    </Tooltip>
                    {campaign.userStatuses && campaign.userStatuses.length > 0 && (
                      <div className={classes.userStatusCont}>
                        <Typography variant="body2" color="textSecondary" id="cati-status-text">
                          {data.userStatus ? `Status: ${data.userStatus}` : 'Set status'}
                        </Typography>
                        <IconButton id="cati-edit-status" onClick={() => setShowStatusModal('update')}>
                          <EditIcon fontSize="small" style={{ fontSize: '1rem', padding: '0px' }} />
                        </IconButton>
                      </div>
                    )}
                  </div>
                  {contact.id && (
                    <div className={classes.contactDetailsCont}>
                      <IconText>
                        <Icon>person</Icon>
                        <Tooltip title={contactLabel}>
                          <Typography variant="h6" className={classes.shortenedLabel}>
                            {contactLabel}
                          </Typography>
                        </Tooltip>
                      </IconText>
                      <div className={classes.callButtonCont}>
                        <Button
                          className={classes.callButton}
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            toggleCallDialog({
                              contactId: contact.id,
                              contactName: contactLabel || `contact (${contact.id})`,
                              isOpen: true,
                            })
                          }
                          startIcon={<CallIcon />}
                        >
                          Call contact
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                {data && data.state && data.state.next && (
                  <>
                    <Card className={classes.catiStepCont}>
                      <CardContent>
                        <Toolbar
                          style={{
                            margin: '-24px -24px 0px',
                            backgroundColor: '#f5f5f5',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                marginBottom: '15px',
                                width: '100%',
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  height: '22px',
                                  width: '100%',
                                }}
                              >
                                {`${(data.state.previous?.length || 0) + 1}. ${
                                  data.state.next.type === 'catiMessage' ? 'Message' : 'Question'
                                }`}
                              </Typography>
                            </div>
                          </div>
                        </Toolbar>
                        <div
                          style={{
                            paddingTop: '15px',
                          }}
                        >
                          <Typography className={classes.catiStepContent} variant="h5" color="textSecondary">
                            {renderText(data.state.next.message)}
                          </Typography>
                        </div>
                        {data.state.next.openEnded && (
                          <div
                            style={{
                              paddingTop: '60px',
                            }}
                          >
                            <TextField
                              id="cati-open-ended-text-input"
                              label="Response"
                              multiline
                              rows={4}
                              variant="outlined"
                              fullWidth
                              value={selectedAnswer}
                              onChange={(e) => setSelectedAnswer(e.currentTarget.value)}
                            />
                          </div>
                        )}
                        {data.state.next.validAnswers && (
                          <div
                            style={{
                              paddingTop: '15px',
                            }}
                          >
                            <FormControl variant="standard">
                              <RadioGroup
                                name="cati-single-select"
                                onChange={(_, value) => setSelectedAnswer(value)}
                                value={selectedAnswer}
                              >
                                {data.state.next.validAnswers.map((answer) => (
                                  <FormControlLabel
                                    control={<Radio />}
                                    key={answer}
                                    label={capitalizeFirstLetter(answer)}
                                    value={answer}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </div>
                        )}
                      </CardContent>
                    </Card>
                    <FloatingSaveBar>
                      <ButtonGroup
                        variant="contained"
                        color="primary"
                        ref={exitButtonGroupRef}
                        aria-label="exit button"
                      >
                        <Button id="cati-exit-survey-dropdown-btn" onClick={handleToggle}>
                          Exit Survey
                        </Button>
                        <Button
                          color="primary"
                          size="small"
                          aria-controls={exitButtonOpen ? 'exit-button-menu' : undefined}
                          aria-expanded={exitButtonOpen ? 'true' : undefined}
                          aria-label="exit button behavior"
                          aria-haspopup="menu"
                          onClick={handleToggle}
                        >
                          <ArrowDropDownIcon />
                        </Button>
                      </ButtonGroup>
                      <Popper
                        style={{ zIndex: 1300 }}
                        open={exitButtonOpen}
                        anchorEl={exitButtonGroupRef.current}
                        role={undefined}
                        transition
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                  {exitButtonOptions.map((option) => (
                                    <Tooltip key={option.id} title={option.tooltip}>
                                      <MenuItem
                                        id={option.id}
                                        key={option.label}
                                        onClick={() => handleMenuItemClick(option.status)}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    </Tooltip>
                                  ))}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                      <div style={{ display: 'flex', flex: '1', justifyContent: 'flex-end' }}>
                        <Button
                          id="cati-item-next"
                          variant="contained"
                          color="primary"
                          style={{ marginRight: '0.5rem' }}
                          disabled={data.state.next.type === 'catiQuestion' && !selectedAnswer}
                          onClick={() => {
                            getCATIStep({
                              token,
                              orgId,
                              itemId,
                              runId,
                              stepId: data.state.next?.id,
                              answer: selectedAnswer,
                            }).then((res: any) => {
                              if (!(res instanceof Error)) {
                                setSelectedAnswer('')
                              }
                            })
                          }}
                        >
                          Complete step
                        </Button>
                      </div>
                    </FloatingSaveBar>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Loading>
  )
}
