import { without } from 'ramda'

import {
  ACTION_GET_FILES_FAILURE,
  ACTION_GET_FILES_ITEM_FAILURE,
  ACTION_GET_FILES_ITEM_REQUEST,
  ACTION_GET_FILES_ITEM_SUCCESS,
  ACTION_GET_FILES_REQUEST,
  ACTION_GET_FILES_SUCCESS,
  ACTION_UPLOAD_FILES_ITEM_FAILURE,
  ACTION_UPLOAD_FILES_ITEM_REQUEST,
  ACTION_UPLOAD_FILES_ITEM_SUCCESS,
} from '@/app/module/files/definitions'
import { createReducer } from '@/app/service/util'

import defaultState from './state'

export default createReducer(defaultState, {
  [ACTION_GET_FILES_REQUEST]: (state) => ({
    ...state,
    list: {
      ...state.list,
      loading: true,
    },
  }),
  [ACTION_GET_FILES_SUCCESS]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      data: value,
    },
  }),
  [ACTION_GET_FILES_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      error: value,
    },
  }),

  [ACTION_GET_FILES_ITEM_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
    },
  }),
  [ACTION_GET_FILES_ITEM_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      data: value,
    },
  }),
  [ACTION_GET_FILES_ITEM_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  [ACTION_UPLOAD_FILES_ITEM_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
    },
  }),
  [ACTION_UPLOAD_FILES_ITEM_SUCCESS]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
    },
    item: {
      ...state.item,
      loading: false,
      data: value,
    },
  }),
  [ACTION_UPLOAD_FILES_ITEM_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
    },
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),
})
