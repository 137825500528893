import { GlobalStyles } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import cookies from 'js-cookie'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import ConfirmDialog from '@/app/component/atom/delete-dialog'
import ExternalLink from '@/app/component/atom/external-link'
import { reduceIndexed } from '@/app/module/campaigns/helpers'
import { useCheckPurchaseAvailable } from '@/app/module/plans/utils'

import CustomPlanItem from './custom-plan-item'
import PlanItem from './plan-item'
import PlansFeatures from './plans-features'
import { PlanAction } from './subscribe-button'
import SubscribePlan from './subscribePlan'

const DEFAULT_PAGE_SIZE = 4

const confirmText = (price) => (
  <>
    We will charge <strong>${price}</strong> to your organization's balance.
  </>
)

const defaultPlanConfirmation = {
  callback: () => {},
  display: false,
  text: (
    <>
      <span>
        You are now upgrading your subscription to the <strong>Professional</strong> plan with yearly billing.
      </span>
      <br />
      <span>{confirmText('99.00')}</span>
    </>
  ),
  title: '',
  label: 'Professional',
  price: 99.0,
  cycle: false,
}

export default function Pricing(props) {
  const {
    plans = [],
    plan: currentPlan,
    subscribePlan,
    getPlan,
    orgId,
    token,
    cards,
    history,
    showTrialMode = false,
    activateTrialMode,
    activeOrg = {},
  } = props
  const activePlan = currentPlan?.planId
  const { classes } = useStyles()
  const [chargeYearly, setChargeYearly] = React.useState(true)
  const [trialConfirmation, setTrialConfirmation] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [planChanged, setPlanChanged] = React.useState(false)
  const [planConfirmation, setPlanConfirmation] = React.useState(defaultPlanConfirmation)
  const selectedPlanIndex = reduceIndexed(
    (acc, plan, index) => {
      if (plan.id === activePlan) {
        return index
      }
      return acc
    },
    -1,
    Array.isArray(plans) ? plans : [],
  )
  const checkPurchaseAvailable = useCheckPurchaseAvailable()

  React.useEffect(() => {
    if (selectedPlanIndex > 3) {
      setPage(1)
    } else {
      setPage(0)
    }
    if (planChanged) {
      setPlanChanged(false)
      history.push('/')
    }
    // eslint-disable-next-line
  }, [activePlan, selectedPlanIndex])

  const subscribePlanAction = React.useCallback(
    ({ label, planId, upgrade = false }) => {
      subscribePlan({
        orgId,
        token,
        item: {
          billing: upgrade || chargeYearly ? 'yearly' : 'monthly',
          planId,
          label,
        },
      }).then((res) => {
        if (!(res instanceof Error)) {
          setPlanChanged(true)
          getPlan({
            orgId,
            token,
          })
        }
      })
    },
    [chargeYearly, orgId, token, getPlan, subscribePlan],
  )

  const generateCustomTooltip = (planId) => {
    if (activeOrg.role !== 'owner') {
      return <>Only the owner of an organization can do this.</>
    }
    if (activePlan === planId && !chargeYearly) {
      return (
        <>
          Switch the Billing Cycle toggle above to <strong>yearly</strong> to upgrade your current plan.
        </>
      )
    }
    return ''
  }

  const handlePlanSelect = React.useCallback(
    (plan, action) => {
      const { id: planId, label, priceMonthly, priceYearly } = plan
      switch (action) {
        case PlanAction.Subscribe:
          setPlanConfirmation({
            callback: () => subscribePlanAction({ label, planId }),
            title: 'Subscribe!',
            plan,
            cycle: chargeYearly,
            text: (
              <>
                <Typography variant="h6">
                  You are now subscribing to the <strong>{label}</strong> plan with{' '}
                  <strong>{chargeYearly ? 'yearly' : 'monthly'}</strong> billing.
                </Typography>
                <Typography variant="h6">{confirmText(chargeYearly ? priceYearly : priceMonthly)}</Typography>
              </>
            ),
            display: true,
          })
          break
        case PlanAction.UpgradeToYearly:
          setPlanConfirmation({
            callback: () => subscribePlanAction({ label, planId, upgrade: true }),
            title: 'Upgrade Subscription',
            plan,
            cycle: true,
            text: (
              <>
                <Typography variant="h6">
                  You are now upgrading to the <strong>{label}</strong> plan with <strong>yearly</strong> billing.
                </Typography>
                <Typography variant="h6">{confirmText(priceYearly)}</Typography>
              </>
            ),
            display: true,
          })
          break
        case PlanAction.Downgrade:
          setPlanConfirmation({
            callback: () => subscribePlanAction({ label, planId }),
            title: 'Downgrade Plan',
            plan,
            cycle: chargeYearly,
            text: (
              <>
                <Typography variant="h6">
                  You are now downgrading to the <strong>{label}</strong> plan with{' '}
                  <strong>{chargeYearly ? 'yearly' : 'monthly'}</strong> billing.
                </Typography>
                <Typography variant="h6">{confirmText(chargeYearly ? priceYearly : priceMonthly)}</Typography>
              </>
            ),
            display: true,
          })
          break
        case PlanAction.Upgrade:
          setPlanConfirmation({
            callback: () => subscribePlanAction({ label, planId }),
            title: 'Upgrade Plan',
            plan,
            cycle: chargeYearly,
            display: true,
            text: (
              <>
                <Typography variant="h6">
                  You are now upgrading to the <strong>{label}</strong> plan with{' '}
                  <strong>{chargeYearly ? 'yearly' : 'monthly'}</strong> billing.
                </Typography>
                <Typography variant="h6">{confirmText(chargeYearly ? priceYearly : priceMonthly)}</Typography>
              </>
            ),
          })
          break

        default:
          break
      }
    },
    [chargeYearly, subscribePlanAction],
  )

  return (
    <div>
      <GlobalStyles styles={globalStyles} />
      {trialConfirmation && (
        <ConfirmDialog
          text={
            <>
              <ul>
                <TrialListItem>You can test engageSPARK for 14 days before choosing a plan.</TrialListItem>
                <TrialListItem>
                  During the testing period, you can interact with a maximum of 50 contacts.
                </TrialListItem>
                <TrialListItem>
                  You will need to add funds to cover usage fees for sending SMS, calls, etc.
                </TrialListItem>
              </ul>
              <span>Reach out to support for free testing credits.</span>
            </>
          }
          onClose={() => setTrialConfirmation(false)}
          onConfirm={() => {
            setTrialConfirmation(false)
            activateTrialMode({ trialMode: true })
            cookies.set('trialMode', true, { sameSite: 'strict' })
          }}
          isOpen={true}
          hideDeleteButton={false}
          title="14-Day Testing Period"
          deleteButtonText="Continue Testing"
          cancelButtonText="Select a Plan"
        />
      )}
      {planConfirmation.display && (
        <SubscribePlan
          {...props}
          planConfirmation={planConfirmation}
          onClose={() => setPlanConfirmation(defaultPlanConfirmation)}
          onConfirm={() => {
            planConfirmation.callback()
            setPlanConfirmation(defaultPlanConfirmation)
          }}
          cards={cards}
        />
      )}
      <div>
        {currentPlan?.trialDaysLeft < 0 && (
          <div className={`${classes.trialCont} ${classes.expiredCont}`}>
            <div className={classes.trialSubCont}>
              <Typography variant="h6" sx={{ textAlign: 'center', my: 2 }}>
                You do not have an active subscription. Choose one now to engage your contacts.
              </Typography>
            </div>
          </div>
        )}
        <Box
          sx={{
            py: 4,
            bgcolor: 'background.paper',
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: 'primary.light',
          }}
        >
          <div>
            <Typography className={classes.centered} variant="h4" color="primary">
              Choose your Plan
            </Typography>
          </div>
          <Box sx={{ mb: 4 }}>
            <Typography className={classes.centered} variant="h6" color="textSecondary">
              Select the plan that best suits your needs—reach out to us if you have questions!
            </Typography>
          </Box>
          <Box sx={{ mx: 'auto', textAlign: 'center' }} data-testid="billing-cycle">
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
              <div>
                <Typography variant="h6" color={chargeYearly ? 'textSecondary' : 'primary'}>
                  monthly
                </Typography>
              </div>
              <div>
                <Switch checked={chargeYearly} onChange={() => setChargeYearly(!chargeYearly)} />
              </div>
              <div>
                <Typography variant="h6" color={chargeYearly ? 'primary' : 'textSecondary'}>
                  yearly
                </Typography>
              </div>
            </Box>
          </Box>
        </Box>
        <Box sx={{ maxWidth: '870px', mb: 12, mt: 6, mx: 'auto' }}>
          <Box sx={{ textAlign: 'right' }}>
            <Typography color="textSecondary" variant="subtitle2">
              Text messages, voice minutes, and other usage fees are charged{' '}
              <span className={classes.underLinedText}>
                <strong>in addition</strong>
              </span>{' '}
              to Subscription Plan fees. <br />
              For more information{' '}
              <ExternalLink href="https://www.engagespark.com/pricing" target="_blank">
                click here
              </ExternalLink>
            </Typography>
          </Box>

          <div className={classes.planContainer}>
            {Array.isArray(plans) &&
              plans
                .slice(DEFAULT_PAGE_SIZE * page, DEFAULT_PAGE_SIZE * page + DEFAULT_PAGE_SIZE + page)
                .map((plan, index) => (
                  <PlanItem
                    chargeYearly={chargeYearly}
                    currentPlanBilling={currentPlan?.billing}
                    customButtonTooltip={generateCustomTooltip(plan.id)}
                    index={index}
                    isActivePlan={activePlan === plan.id}
                    key={plan.id}
                    isOwner={activeOrg.role === 'owner'}
                    lastIndex={plans.length} // plus 1 for custom plan
                    plan={plan}
                    selectedPlanIndex={selectedPlanIndex}
                    checkPurchaseAvailable={checkPurchaseAvailable}
                    onSelect={handlePlanSelect}
                  />
                ))}
            <CustomPlanItem />
          </div>

          <PlansFeatures />
        </Box>
        {showTrialMode && currentPlan?.trialDaysLeft > -1 && (
          <div className={classes.trialCont}>
            <div className={classes.trialSubCont}>
              <Link href="#" onClick={() => setTrialConfirmation(true)}>
                <Typography variant="h5">Continue testing for a limited time for free {'>>'}</Typography>
              </Link>
            </div>
          </div>
        )}
      </div>
      {plans.length > DEFAULT_PAGE_SIZE && (
        <div className={page === 1 ? classes.previousPageButtonContainer : classes.nextPageButtonContainer}>
          <Button
            onClick={() => setPage(page === 1 ? 0 : 1)}
            color="primary"
            variant="contained"
            className={classes.nextPageButton}
          >
            Show {page === 1 ? 'Basic Plans' : 'More Plans'}
          </Button>
        </div>
      )}
    </div>
  )
}

export const TrialListItem = ({ children }) => (
  <li style={{ listStyleType: 'square', marginLeft: '18px' }}>{children}</li>
)

const useStyles = makeStyles()((theme) => ({
  planContainer: {
    display: 'flex',
    gap: '1px',
  },
  nextPageButtonContainer: {
    position: 'absolute',
    right: -10,
    top: '70%',
    transform: 'rotate(90deg)',
    transformOrigin: '100% 0',
  },
  previousPageButtonContainer: {
    position: 'absolute',
    left: 25,
    top: '65%',
    transform: 'rotate(270deg)',
    transformOrigin: '0 100%',
  },
  nextPageButton: {
    width: '200px',
  },
  centered: {
    textAlign: 'center',
  },
  trialCont: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  expiredCont: {
    color: theme.palette.secondary.main,
  },
  underLinedText: {
    textDecoration: 'underline',
  },
}))

const globalStyles = {
  ul: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
}
