import { DelayState, TimeUnit } from '@/app/module/campaigns/types'

export const getEmptyDelta = (timezone: string, nextIndex: number): DelayState => ({
  country: '',
  dayOfWeek: '',
  daysOfWeek: {},
  time: '',
  timezone,
  timezones: [],
  unit: !nextIndex ? TimeUnit.Immediate : TimeUnit.Day,
  value: !nextIndex ? 0 : 1,
  valueError: '',
})
