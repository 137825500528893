import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import React from 'react'

import { DRAGTYPE, SMS_ACTION_DEFAULT_SENDERID } from '@/app/module/campaigns/definitions'
import {
  ActionMessage,
  ActionsOnly,
  CampaignQuery,
  CampaignTemplateType,
  ImageProps,
  Language,
  LocalAction,
  PartFamily,
  PersonalizationType,
  SenderIdOption,
  SnippetType,
  VoiceProps,
} from '@/app/module/campaigns/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { unwrapActions, wrapActions } from '@/app/module/campaigns/utils/actions/wrap'
import { CountryTimezone, FilesType, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'

import QuestionBox from './question'

type Props = {
  item: ActionMessage
  id: string
  personalizationList: PersonalizationType
  senderIds: SenderIdOption[]
  title: string
  whatsappSenderIds: SenderIdOption[]
  orgId: number
  token: string
  campaignIds: SelectOption<number>[]
  files: FilesType
  timezone: string
  timezones: TimezoneWithCountryCodeType
  countryCode: string
  countriesTimezones: CountryTimezone[]
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  languages: Language[]
  defaultLanguage: string
  type: CampaignTemplateType
  index: number

  changeHandler: (item: Partial<ActionMessage>, changed?: boolean) => void
  getCampaigns: (payload: { orgId: number; token: string; query: CampaignQuery }) => Promise<void>
  getImageProps: (qid: string) => ImageProps
  getSnippets: (payload: { orgId: number; token: string }) => Promise<void>
  getVoiceProps: (qid: string) => VoiceProps
  saveSnippet: (payload: {
    orgId: number
    token: string
    item: Pick<SnippetType, 'category' | 'snippet'>
  }) => Promise<SnippetType>
}

export default function CampaignContentQuestionAction(props: Props) {
  const {
    item,
    id,
    personalizationList,
    title,
    senderIds,
    whatsappSenderIds,
    orgId,
    token,
    campaignIds,
    timezone,
    timezones,
    countryCode,
    countriesTimezones,
    snippets,
    languages,
    defaultLanguage,
    files,
    getCampaigns,
    getImageProps,
    getSnippets,
    getVoiceProps,
    saveSnippet,
  } = props

  const [state, setState] = React.useState(fromProps(props.item))

  React.useEffect(() => {
    setState(fromProps(item))
  }, [item])

  const changeHandler = (newItem: { actionsOnly: LocalAction[] }) => {
    setState({
      ...state,
      ...newItem,
    })
    const returnToProps = backToProps({
      ...state,
      ...newItem,
    })

    props.changeHandler({
      ...item,
      ...returnToProps,
    })
  }

  const actionValues = React.useMemo(
    () =>
      getActionOptionsForCampaign({
        campaignType: props.type,
        partType: PartFamily.Action,
        actionNamespace: ActionContext.ActionOnly,
      }),
    [props.type],
  )

  return (
    <div id="action-block-cont">
      <Divider
        style={{
          margin: '0rem -24px 1rem',
        }}
      />
      <div>
        <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          {title}
        </Typography>
        <div>
          <QuestionBox
            files={files}
            dragContext={`${DRAGTYPE}-actions`}
            actionsList={state.actionsOnly}
            actionsValues={actionValues}
            changeHandler={({ actionsList }: { actionsList: LocalAction[] }) =>
              changeHandler({
                actionsOnly: actionsList,
              })
            }
            senderIds={senderIds}
            whatsappSenderIds={whatsappSenderIds}
            personalizationList={personalizationList}
            defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.actionsOnly}
            getCampaigns={getCampaigns}
            getImageProps={getImageProps}
            imageId={`${id}-action`}
            voiceId={`${id}-action`}
            orgId={orgId}
            token={token}
            question={false}
            campaignIds={campaignIds}
            index={props.index}
            getVoiceProps={getVoiceProps}
            timezones={timezones}
            timezone={timezone}
            countriesTimezones={countriesTimezones}
            countryCode={countryCode}
            getSnippets={getSnippets}
            snippets={snippets}
            saveSnippet={saveSnippet}
            languages={languages}
            defaultLanguage={defaultLanguage}
          />
        </div>
      </div>
    </div>
  )
}

const fromProps = ({ actionsOnly }: { actionsOnly: ActionsOnly }) => ({
  actionsOnly: unwrapActions(actionsOnly?.actions || []),
})

const backToProps = ({ actionsOnly }: { actionsOnly: LocalAction[] }) => ({
  actionsOnly: {
    actions: wrapActions(actionsOnly),
  },
})
