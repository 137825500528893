export default {
  details: {
    init: false,
    loading: false,
    error: {},
    data: {},
  },
  organization: {
    init: false,
    loading: false,
    error: {},
    data: {},
  },
  password: {
    loading: false,
  },
  auditLog: {
    loading: false,
    error: {},
  },
  invitations: {
    init: false,
    loading: false,
    error: {},
    data: [],
  },
  users: {
    init: false,
    loading: false,
    error: {},
    data: [],
  },
  totp: {
    loading: false,
    data: {},
    error: {},
  },
  recoveryCodes: {
    loading: false,
    data: {},
    error: {},
  },
  snippets: {
    loading: false,
    data: [],
    error: {},
  },
  callDialog: {
    isOpen: false,
    contactName: '',
    contactId: 0,
  },
  apiKeys: {
    loading: false,
    data: {
      total: 0,
      tokens: [],
    },
    error: {},
  },
}
