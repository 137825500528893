import { Button, CardActions, DialogContent, Typography } from '@mui/material'
import React from 'react'

import { SchemaPurchase } from '@/__generated/es-api'
import Dialog from '@/app/component/atom/dialog'
import DialogHeader from '@/app/component/atom/dialog/header'
import { Form } from '@/app/component/form'
import { linesDefaultValues, linesFields, linesSchema } from '@/app/module/payments/lines-form'
import { formatDate } from '@/app/service/util'
import download from '@/app/service/util/download'
import { ReceiptLines } from '@/app/types'

type Props = {
  orgId: number
  receipt: SchemaPurchase
  onClose: () => void
}

const DownloadReceiptDialog: React.FC<Props> = ({ orgId, receipt, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const downloadReceipt = async (lines: ReceiptLines) => {
    setIsLoading(true)
    try {
      await download.receipts(orgId, receipt.id, lines)
      onClose()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogHeader icon="receipt" title={<>Download Receipt for {formatDate(receipt.created)}</>} onClose={onClose} />
      <DialogContent>
        <Typography color="textPrimary" variant="subtitle2">
          Enter whatever organizational, legal, or project details you want to appear on the receipt, such as your
          organization's legal name, address, tax ID, phone number, PO number, etc. You can add up to 5 lines of detail.
        </Typography>
        <br />
        <Form defaultValues={linesDefaultValues} fields={linesFields} schema={linesSchema} onSubmit={downloadReceipt}>
          <CardActions sx={{ p: 0 }}>
            <Button disabled={isLoading} variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={isLoading} type="submit" variant="contained">
              Download
            </Button>
          </CardActions>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default DownloadReceiptDialog
