import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import IconText from '@/app/component/layout/icon-text'

type DeleteDialogProps = {
  text: string
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  hideDeleteButton?: boolean
  deleteButtonText?: string
  cancelButtonText?: string
  icon?: string
  title?: string
  disabled?: boolean
  hideCancel?: boolean
}

export default function DeleteDialog({
  text,
  isOpen,
  onClose,
  onConfirm,
  hideDeleteButton = false,
  deleteButtonText = 'Delete',
  cancelButtonText = 'Cancel',
  icon = 'warning',
  title = 'Warning',
  disabled = false,
  hideCancel = false,
}: DeleteDialogProps) {
  return (
    <Dialog id="delete-dialog" open={!!isOpen}>
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>{icon}</Icon>
            <Typography variant="h6">{title}</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          component="div"
          sx={{
            paddingTop: 1,
          }}
        >
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!hideCancel && (
          <Button variant="outlined" id="delete-dialog-cancel-btn" onClick={onClose}>
            {cancelButtonText}
          </Button>
        )}
        {!hideDeleteButton && (
          <Button
            variant="contained"
            color="primary"
            id="delete-dialog-confirm-btn"
            onClick={onConfirm}
            disabled={disabled}
          >
            {deleteButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
