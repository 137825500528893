import moment from 'moment'
import { z } from 'zod'

import config from '@/config'

export enum OrgReportGrouping {
  Month = 'month',
  Total = '',
}

export const ZDateTimeString = z.string().refine(
  (value) =>
    !value ||
    value === '0001-01-01T00:00:00Z' || // backend default date value, consider as not set
    moment(value, config.display.format.orgReportFilter, true).isValid(),
  {
    message: 'Invalid date time string',
  },
)

export const ZDateFilter = z
  .object({
    from: ZDateTimeString.optional(),
    until: ZDateTimeString.optional(),
    tz: z.string().optional(),
  })
  .refine(
    (value) => {
      const { from, until } = value
      if (!from || !until) {
        return true
      }

      const fromMoment = moment(from, config.display.format.orgReportFilter, true)
      const untilMoment = moment(until, config.display.format.orgReportFilter, true)

      return !fromMoment.isAfter(untilMoment)
    },
    {
      message: 'To date should be after from date',
    },
  )

export const ZOrgReportFilter = z.object({
  campaignIds: z.array(z.number()).optional().nullable(),
  date: ZDateFilter.optional(),
})

export const ZOrgReportQuery = z.object({
  grouping: z.nativeEnum(OrgReportGrouping),
  filter: ZOrgReportFilter,
})

export const ZOrgReportHeader = z.object({
  id: z.string(),
  title: z.string(),
  numeric: z.boolean(),
})

export const ZOrgReportResult = z.object({
  headers: z.array(ZOrgReportHeader),
  cells: z.array(z.array(z.string())),
})

export const ZGetOrgReportFileResponse = z.object({
  query: ZOrgReportQuery,
  result: ZOrgReportResult,
})
