import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Toolbar from '@mui/material/Toolbar'
import { compose } from 'ramda'
import { Component } from 'react'

import Loading from '@/app/component/guard/loading'
import createHydrated from '@/app/component/wrapper/hydratable'
import createPaginatedTable from '@/app/component/wrapper/table/paginated'
import createSearchTable from '@/app/component/wrapper/table/searchable'

import ContactsTableField from './field'
import SubscribeTable from './table'

const TABS = ['contacts', 'groups', 'segments']

const ContactsTable = compose(createSearchTable, createPaginatedTable)(SubscribeTable)

const GroupsTable = compose(createSearchTable, createPaginatedTable, createHydrated)(SubscribeTable)

const SegmentsTable = compose(createSearchTable, createPaginatedTable, createHydrated)(SubscribeTable)

export default class CampaignSubscriptions extends Component {
  state = {
    tab: TABS[0],
  }

  tabs = {
    contacts: {
      title: 'Contacts',
      Content: ({
        token,
        orgId,
        item,
        itemId,
        contacts,
        getContacts,
        subscribeContact,
        unsubscribe,
        navigateContacts,
        timezone,
        timezones,
        countriesTimezones,
        countryCode,
        resumeSubscription,
      }) => (
        <ContactsTable
          root="contacts"
          token={token}
          orgId={orgId}
          campaign={item}
          {...contacts}
          ordered={contacts.data.map((contact) => contact.id)}
          hydrateProps={['token', 'orgId']}
          hydrate={getContacts}
          getData={getContacts}
          navigate={navigateContacts}
          searchLabel="Search contacts"
          emptyMessageDefault="No contacts found."
          query={{
            ...contacts.query,
            subscriptionStatus: itemId,
          }}
          Field={ContactsTableField}
          onResume={({ subId }) => resumeSubscription({ token, orgId, campaignId: itemId, subId })}
          onSubscribe={({ contactId, delay, startAt, t0, t0timezone }) =>
            subscribeContact({
              token,
              orgId,
              itemId,
              contactId,
              delay,
              startAt,
              t0,
              t0timezone,
            })
          }
          onUnsubscribe={(ids) =>
            unsubscribe({
              token,
              orgId,
              itemId,
              item: ids,
            })
          }
          timezone={timezone}
          timezones={timezones}
          countriesTimezones={countriesTimezones}
          countryCode={countryCode}
        />
      ),
    },
    groups: {
      title: 'Groups',
      Content: ({
        token,
        orgId,
        item,
        itemId,
        groups,
        getGroups,
        subscribeGroup,
        unsubscribe,
        navigateGroups,
        timezone,
        timezones,
        countriesTimezones,
        countryCode,
      }) => (
        <GroupsTable
          root="groups"
          token={token}
          orgId={orgId}
          campaign={item}
          {...groups}
          hydrateProps={['token', 'orgId']}
          hydrate={getGroups}
          getData={getGroups}
          navigate={navigateGroups}
          emptyMessageDefault="No groups found."
          searchLabel="Search groups"
          onSubscribe={({ groupId, allowResubscribe, delay, startAt, t0, t0timezone }) =>
            subscribeGroup({
              token,
              orgId,
              itemId,
              groupId,
              allowResubscribe,
              delay,
              startAt,
              t0,
              t0timezone,
            })
          }
          onUnsubscribe={(ids) =>
            unsubscribe({
              token,
              orgId,
              itemId,
              item: ids,
            })
          }
          timezone={timezone}
          timezones={timezones}
          countriesTimezones={countriesTimezones}
          countryCode={countryCode}
        />
      ),
    },
    segments: {
      title: 'Segments',
      Content: ({
        token,
        orgId,
        item,
        itemId,
        segments,
        getSegments,
        subscribeSegment,
        unsubscribe,
        navigateSegments,
        timezone,
        timezones,
        countriesTimezones,
        countryCode,
      }) => (
        <SegmentsTable
          root="segments"
          token={token}
          orgId={orgId}
          campaign={item}
          {...segments}
          hydrateProps={['token', 'orgId']}
          hydrate={getSegments}
          getData={getSegments}
          navigate={navigateSegments}
          emptyMessageDefault="No segments found."
          searchLabel="Search segments"
          onSubscribe={({ groupId, allowResubscribe, delay, startAt, t0, t0timezone }) =>
            subscribeSegment({
              token,
              orgId,
              itemId,
              segmentId: groupId,
              allowResubscribe,
              delay,
              startAt,
              t0,
              t0timezone,
            })
          }
          onUnsubscribe={(ids) =>
            unsubscribe({
              token,
              orgId,
              itemId,
              item: ids,
            })
          }
          timezone={timezone}
          timezones={timezones}
          countriesTimezones={countriesTimezones}
          countryCode={countryCode}
        />
      ),
    },
  }

  render() {
    const { Content } = this.tabs[this.state.tab]

    const { loading, error } = this.props[this.state.tab] || {}

    return (
      <Loading isLoading={loading} error={error}>
        <Toolbar
          style={{
            position: 'absolute',
            width: '100%',
            justifyContent: 'space-between',
            backgroundColor: '#f5f5f5',
          }}
        >
          <Tabs
            value={this.state.tab}
            indicatorColor="primary"
            style={{
              position: 'relative',
              zIndex: '2',
            }}
            onChange={(e, val) => this.setState({ tab: val })}
          >
            {TABS.map((tab) => (
              <Tab key={tab} value={tab} label={this.tabs[tab].title} className={`subscription-tab-${tab}`} />
            ))}
          </Tabs>
        </Toolbar>
        <Content {...this.props} />
      </Loading>
    )
  }
}
