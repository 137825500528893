import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import { prop } from 'ramda'
import { PureComponent } from 'react'

import StripeInput from './stripe-input'

export default class extends PureComponent {
  static displayName = 'StripeElementWrapper'

  render() {
    const { component, label, onChange } = this.props
    const { focused, empty, error } = this.props

    return (
      <div>
        <FormControl variant="standard" fullWidth margin="normal">
          <InputLabel focused={focused} shrink={true} error={!!error}>
            {label}
          </InputLabel>
          <Input
            fullWidth
            inputComponent={StripeInput}
            onChange={(value) => {
              const { empty: inputEmpty, error: inputError } = value
              onChange({
                empty: inputEmpty,
                error: prop('message', inputError),
                focused,
              })
            }}
            onFocus={() => {
              onChange({
                focused: true,
                empty,
                error,
              })
            }}
            onBlur={() => {
              onChange({
                focused: false,
                empty,
                error,
              })
            }}
            inputProps={{ component }}
          />
        </FormControl>
      </div>
    )
  }
}
