import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { usePostDidRegisterWhatsAppMutation } from '@/__generated/es-api'
import { invalidatePhoneNumbersCache } from '@/app/module/phonenumbers/store/utils'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { useAppDispatch } from '@/app/store/redux-hooks'

export const useRegisterWhatsappSenderId = () => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const [registerDid, response] = usePostDidRegisterWhatsAppMutation()

  /**
   * @param didId - eS DID ID
   * @param profileName - WhatsApp profile name
   * @param wabaId - WhatsApp Business Account ID
   */
  const registerWhatsappSenderId = useCallback(
    async (didId: number, wabaId: string, profileName?: string) => {
      await registerDid({
        orgId: orgId.toString(),
        didId: didId.toString(),
        body: {
          wabaId,
          profileName,
        },
      }).unwrap()
      invalidatePhoneNumbersCache(dispatch)
    },
    [orgId, dispatch, registerDid],
  )

  return [registerWhatsappSenderId, response] as const
}
