import { Component } from 'react'

import TableCell from '@/app/component/layout/table/cell'
import TableRow from '@/app/component/layout/table/row'

import CampaignsTableField from './field'

export default class CampaignsTableRow extends Component {
  render() {
    const { loading, data, specs, headers } = this.props
    return (
      <TableRow isLoading={loading}>
        {headers.map(({ fields = [] }, i) => (
          <TableCell
            key={i}
            index={i}
            style={{
              height: '69px',
            }}
          >
            {fields.map((key) => (
              <CampaignsTableField key={key} name={key} specs={specs[key]} field={data[key]} />
            ))}
          </TableCell>
        ))}
      </TableRow>
    )
  }
}
