import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  IconButton,
  List,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import IconText from '@/app/component/layout/icon-text'
import { Organization, SelectOption } from '@/app/types'

type NavItem = {
  icon: string
  title: string
  url: string
  isActive: (currentPage: string) => boolean
  isHidden: (hasOrg: boolean) => boolean
}

type Props = {
  activeOrg?: Organization
  currentPage: string
  email: string
  hasOrg: boolean
  menuOpen: boolean
  navItems: NavItem[]
  organizations: SelectOption<number>[]
  panelExpanded: boolean
  onLogoutClick: () => void
  onMenuToggle: () => void
  onOrganizationChange: (org: SelectOption<number>) => void
  onPanelToggle: () => void
}

const AccountMenu: React.FC<Props> = ({
  activeOrg,
  currentPage,
  email,
  hasOrg,
  navItems,
  menuOpen,
  organizations,
  panelExpanded,
  onLogoutClick,
  onMenuToggle,
  onOrganizationChange,
  onPanelToggle,
}) => {
  const anchorElRef = React.useRef<HTMLButtonElement | null>(null)
  const { classes } = useStyles()

  const displayNavItems = React.useMemo(() => navItems.filter((item) => !item.isHidden(hasOrg)), [hasOrg, navItems])

  return (
    <>
      <IconButton id="header-account-trigger" color="inherit" ref={anchorElRef} onClick={onMenuToggle}>
        <Icon>account_circle</Icon>
      </IconButton>
      <Menu id="header-account-menu" open={menuOpen} onClose={onMenuToggle} anchorEl={anchorElRef.current}>
        <MenuItem disabled={true} className={classes.dropdownItem}>
          <IconText>
            <Icon>person</Icon>
            <Typography>{email}</Typography>
          </IconText>
        </MenuItem>
        {displayNavItems.map(({ title, url, icon, isActive }, i) => (
          <MenuItem
            component={Link}
            to={url}
            key={i}
            selected={isActive(currentPage)}
            className={i + 1 === displayNavItems.length ? classes.dropdownItem : ''}
          >
            <IconText>
              <Icon>{icon}</Icon>
              <Typography>{title}</Typography>
            </IconText>
          </MenuItem>
        ))}
        {organizations.length > 1 && (
          <MenuItem className={classes.dropdownExpand}>
            <Accordion expanded={panelExpanded} onChange={onPanelToggle} className={classes.dropdownExpandPanel}>
              <AccordionSummary className={classes.dropdownExpandPanelSum} expandIcon={<Icon>expand_more</Icon>}>
                <div>
                  <Typography variant="caption">Switch organization to:</Typography>
                  <IconText>
                    <Icon>supervised_user_circle</Icon>
                    <Typography>
                      {activeOrg?.name} ({activeOrg?.orgId})
                    </Typography>
                  </IconText>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.dropdownExpandPanelDetails}>
                <List className={classes.dropdownExpandPanelList}>
                  {organizations.map(({ label, value }) => (
                    <MenuItem
                      key={value}
                      selected={value === activeOrg?.orgId}
                      disabled={value === activeOrg?.orgId}
                      onClick={() => onOrganizationChange({ label, value })}
                    >
                      <IconText>
                        <Icon />
                        <Typography>{label}</Typography>
                      </IconText>
                    </MenuItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </MenuItem>
        )}
        <MenuItem className={classes.dropdownItem}>
          <div className={classes.dropdownLink} onClick={onLogoutClick}>
            <IconText>
              <Icon className={classes.dropdownIcon}>exit_to_app</Icon>
              <Typography>Log out</Typography>
            </IconText>
          </div>
        </MenuItem>
      </Menu>
    </>
  )
}

const useStyles = makeStyles()((theme: Theme) => ({
  dropdownItem: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  },
  dropdownLink: {
    width: '100%',
    display: 'flex',
    textDecoration: 'none',
    color: 'inherit',
  },
  dropdownIcon: {
    marginRight: '1rem',
  },
  dropdownExpand: {
    height: 'auto',
    minHeight: '49px',
    padding: '0',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  },
  dropdownExpandPanel: {
    padding: '0',
    marginBottom: '0px !important',
    width: '100%',
  },
  dropdownExpandPanelSum: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  dropdownExpandPanelDetails: {
    padding: '0',
  },
  dropdownExpandPanelList: {
    padding: '0',
    width: '100%',
  },
}))

export default AccountMenu
