import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { omit, pathOr } from 'ramda'
import React from 'react'

import { Checkbox } from '@/app/component/atom/form'
import IconText from '@/app/component/layout/icon-text'
import { getPartType } from '@/app/module/campaigns/component/item/steps/content/types/multimessage/content'
import { estimateSubscription } from '@/app/module/campaigns/helpers'
import { isReminderCampaign } from '@/app/module/campaigns/models/classes/reminder-campaign.class'
import isCampaignClass from '@/app/module/campaigns/models/guard'
import CampaignInterface from '@/app/module/campaigns/models/interfaces/campaign.interface'
import { selectTimezones } from '@/app/module/campaigns/store/selectors'
import { CampaignTemplateType, Delta, LegacyCampaignItem, TimeUnit } from '@/app/module/campaigns/types'
import { CountryTimezone, TimezoneWithCountryCodeType } from '@/app/types'

import Delay from './delay'
import ReminderEventTime from './reminder-event-time'
import StartAt from './start-at'

type Props = {
  campaign: LegacyCampaignItem | CampaignInterface
  countriesTimezones: CountryTimezone[]
  countryCode: string
  isOpen: boolean
  timezone: string
  timezones: TimezoneWithCountryCodeType
  title: React.ReactNode
  onClose: () => void
  onConfirm: (payload: {
    delay?: Omit<Delta, 'timezones' | 'valueError'>
    startAt?: string
    t0?: string
    t0timezone?: string
  }) => void
}

export default function DelayDialog({
  campaign,
  countriesTimezones,
  countryCode,
  isOpen,
  timezone,
  timezones,
  title = '',
  onClose,
  onConfirm,
}: Props) {
  const [delay, setDelay] = React.useState({
    unit: TimeUnit.Immediate,
    value: 0,
    dayOfWeek: '',
    daysOfWeek: {},
    time: '',
    timezone,
    timezones: selectTimezones(pathOr([], [countryCode, 'tzs'], timezones)),
    country: countryCode,
    at: undefined,
    valueError: '',
  })
  const [isDefaultArgs, setIsDefaultArgs] = React.useState(true)
  const [selectedMessage, selectMessage] = React.useState('')
  const [t0, setT0] = React.useState('')
  const [t0timezone, setT0Timezone] = React.useState(timezone)

  const { message, protip } = estimateSubscription(delay, new Date())

  const isReminder = isReminderCampaign(campaign)
  const hasError = !!delay.valueError || (!isDefaultArgs && !t0)

  const handleChangeReminderArgs = React.useCallback((t0Value: string, tzValue: string) => {
    setT0(t0Value)
    setT0Timezone(tzValue)
  }, [])

  const handleConfirm = React.useCallback(() => {
    const newTimezone = t0 ? t0timezone : '' // if t0 is empty, ignore t0timezone
    if (delay.unit === TimeUnit.Immediate) {
      onConfirm({
        startAt: selectedMessage,
        t0,
        t0timezone: newTimezone,
      })
    } else {
      onConfirm({
        delay: omit(['timezones', 'valueError'], delay),
        t0,
        t0timezone: newTimezone,
        ...(campaign.type === 'drip' && {
          startAt: selectedMessage,
        }),
      })
    }
  }, [campaign.type, delay, selectedMessage, t0, t0timezone, onConfirm])

  const messages = React.useMemo(() => {
    if (campaign.type !== CampaignTemplateType.Drip || isCampaignClass(campaign)) {
      return []
    }
    return campaign.variables.parts.map((part, index) => {
      const type = getPartType(part)
      return {
        type,
        id: part.id,
        delta: part.delta,
        index: index + 1,
        label: part.label,
      }
    })
  }, [campaign])

  return (
    <Dialog maxWidth="sm" fullWidth={true} id="delete-dialog" open={!!isOpen}>
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>input</Icon>
            <Typography className="subscription-delay-title" variant="h6">
              {title}
            </Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {campaign.type === 'drip' && (
          <>
            <DialogContentText>What message should the contact(s) start at?</DialogContentText>
            <StartAt messages={messages} selectMessage={selectMessage} selectedMessage={selectedMessage} />
          </>
        )}
        <DialogContentText>When should the subscription start?</DialogContentText>
        <Delay
          isFirst={true}
          published={false}
          item={delay}
          changeHandler={setDelay}
          timezones={timezones}
          countriesTimezones={countriesTimezones}
          setLoading={() => {}}
          type="message"
          text=""
        />
        <div>
          <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
            {message}
          </Typography>
        </div>
        <div>
          <Typography variant="caption" color="primary" style={{ fontWeight: 'bold' }}>
            {protip}
          </Typography>
        </div>
        {isReminder && (
          <>
            <Checkbox
              editable
              label="Use the event time as configured in the campaign"
              name="event-time-enabled"
              value={String(isDefaultArgs)}
              onChange={({ value }) => {
                const boolValue = value === 'true'
                setIsDefaultArgs(boolValue)

                if (boolValue) {
                  // reset t0 and t0timezone when switching to default
                  setT0('')
                  setT0Timezone('')
                }
              }}
            />

            {!isDefaultArgs && (
              <ReminderEventTime
                countriesTimezones={countriesTimezones}
                countryCode={countryCode}
                t0={t0}
                t0timezone={t0timezone}
                timezones={timezones}
                onChange={handleChangeReminderArgs}
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" id="subdelay-dialog-cancel-btn" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="subdelay-dialog-continue-btn"
          color="primary"
          disabled={hasError}
          variant="contained"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
