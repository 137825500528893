import { z } from 'zod'

import { SchemaCustomfield } from '@/__generated/es-api'
import { isPhone } from '@/app/component/form/utils/validation'
import { SelectOption } from '@/app/types'

const getCommonFields = (
  customFields: SchemaCustomfield[],
  languageOptions: SelectOption<string>[],
  defaultCountryCode: string,
) =>
  ({
    firstName: { fieldProps: { label: 'First name', name: 'firstName' }, type: 'text' },
    lastName: { fieldProps: { label: 'Last name', name: 'lastName' }, type: 'text' },
    fullPhoneNumber: {
      fieldProps: { label: 'Phone number', name: 'fullPhoneNumber', defaultCountry: defaultCountryCode },
      type: 'tel',
    },
    language: {
      fieldProps: {
        label: 'Language',
        name: 'language',
        options: languageOptions.map((l) => ({ ...l, value: l.label })), // To fix the sensitive case
        freeSolo: true,
      },
      type: 'autocomplete',
    },
    ...customFields.reduce(
      (acc, { id, name }) => ({
        ...acc,
        [`customFields${id}`]: {
          fieldProps: { label: name, name: `customFields${id}` },
          type: 'text',
        },
      }),
      {},
    ),
  }) as const

export const getCreateContactFields = (
  customFields: SchemaCustomfield[],
  languageOptions: SelectOption<string>[],
  defaultCountryCode: string,
) => getCommonFields(customFields, languageOptions, defaultCountryCode)

export const getCreateContactSchema = (customFields: SchemaCustomfield[]) =>
  z.object({
    firstName: z.string().optional().describe('First name'),
    lastName: z.string().optional().describe('Last name'),
    fullPhoneNumber: z.string().optional().describe('Phone number').transform(isPhone(true)),
    language: z.string().optional().describe('Language'),
    ...customFields.reduce(
      (acc, { id, name }) => ({
        ...acc,
        [`customFields${id}`]: z.string().optional().describe(name),
      }),
      {},
    ),
  })

export const getUpdateContactFields = (
  customFields: SchemaCustomfield[],
  languageOptions: SelectOption<string>[],
  defaultCountryCode: string,
) =>
  ({
    ...getCommonFields(customFields, languageOptions, defaultCountryCode),
    createdAt: { fieldProps: { label: 'Created at', name: 'createdAt', disabled: true }, type: 'datetime' },
    updatedAt: { fieldProps: { label: 'Updated at', name: 'updatedAt', disabled: true }, type: 'datetime' },
    lastUseAt: { fieldProps: { label: 'Last use at', name: 'lastUseAt', disabled: true }, type: 'datetime' },
    carrier: { fieldProps: { label: 'Carrier', name: 'carrier', disabled: true }, type: 'text' },
  }) as const

export const getUpdateContactSchema = (customFields: SchemaCustomfield[]) =>
  z.object({
    firstName: z.string().optional().describe('First name'),
    lastName: z.string().optional().describe('Last name'),
    fullPhoneNumber: z.string().optional().describe('Phone number').transform(isPhone(true)),
    language: z.string().optional().describe('Language'),
    ...customFields.reduce(
      (acc, { id, name }) => ({
        ...acc,
        [`customFields${id}`]: z.string().optional().describe(name),
      }),
      {},
    ),
    createdAt: z.string().optional().describe('Created at'),
    updatedAt: z.string().optional().describe('Updated at'),
    lastUseAt: z.string().optional().describe('Last use at'),
    carrier: z.string().optional().describe('Carrier'),
  })
