import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { isEmpty, pathOr } from 'ramda'
import React from 'react'

import { Phone } from '@/app/component/atom/form'
import IconText from '@/app/component/layout/icon-text'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { displayMap, Q_ACTIONS } from '@/app/module/campaigns/definitions'
import { hasPhoneError } from '@/app/service/validate'

const unwrapValue = (value: any) =>
  pathOr(
    {
      to: '',
    },
    ['transfer'],
    value,
  )

const Transfer = (props: any) => {
  const { onClose, onSave, countryCode, countriesTimezones } = props
  const { to } = unwrapValue(props.value)
  const [phonenumber, setPhonenumber] = React.useState('')

  React.useEffect(() => {
    if (!isEmpty(to)) {
      setPhonenumber(to)
    }
  }, [to])

  return (
    <CustomDialog open={true} onClose={onClose}>
      <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>{displayMap.transfer.icon}</Icon>
            <Typography variant="h6">{Q_ACTIONS.transfer.label}</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '24px' }}>
        <div
          style={{
            width: '80vw',
            minWidth: '300px',
            maxWidth: '100%',
          }}
        >
          <form
            id="add-call-transfer-phonenumber"
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <CardContent>
              <div style={{ marginBottom: '10px' }}>
                <Typography variant="body2" color="textSecondary">
                  Enter the Phone Number that you would like to connect the contact to. The contact will hear light
                  music while we ring the phone number you enter below. When the person at the number below picks up,
                  the contact and the person will be able to speak with each other.
                </Typography>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <Typography variant="body2" color="textSecondary">
                  Please note that transferred calls are charged as two separate calls: <br />
                  (1) the cost of the call with the contact's phone plus
                  <br />
                  (2) the cost of calling the phone number below.
                  <br />
                </Typography>
              </div>
              <FormControl variant="standard" fullWidth>
                <Phone
                  name="phone"
                  label="Phone number"
                  editable={true}
                  error={hasPhoneError(phonenumber)}
                  defaultCountry={countryCode}
                  countries={countriesTimezones}
                  value={phonenumber}
                  onChange={({ value }) => {
                    setPhonenumber(value)
                  }}
                />
              </FormControl>
            </CardContent>
          </form>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="transfer-phone-save"
          disabled={!phonenumber || !!hasPhoneError(phonenumber)}
          variant="contained"
          color="primary"
          id="transfer-phone-save"
          onClick={() =>
            onSave({
              transfer: {
                to: phonenumber,
              },
            })
          }
        >
          Save
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}
export default Transfer
