import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React from 'react'

import { noop } from '@/app/definitions'
import { usePrevious } from '@/app/helpers'
import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import {
  ContentWhatsApp,
  PersonalizationType,
  VoiceProps,
  WhatsAppMessage,
  WhatsAppQuestion,
} from '@/app/module/campaigns/types'
import { FileUploadType } from '@/app/types'

import AudioMessage from './audio-message'
import DocumentMessage from './document-message'
import { loadDefaultTab, shouldDisplayConfirmation, WhatsAppTab } from './helpers'
import ImageMessage from './image-message'
import TextMessage from './text-message'
import VideoMessage from './video-message'

type Props = Pick<
  VoiceProps,
  | 'defaultUpload'
  | 'files'
  | 'loading'
  | 'playId'
  | 'getFile'
  | 'onMicAccessError'
  | 'saveFileHandler'
  | 'setDefaultUpload'
  | 'setPlaying'
> & {
  audio?: string | null
  autoFocus?: boolean
  canRegisterTemplate: boolean
  defaultLanguage: string
  document?: string | null
  editable?: boolean
  enableMediaCaption: boolean
  /**
   * Indicates if the whatsapp message uses whatsapp buttons so we can show the confirmation message accordingly
   */
  hasButtons?: boolean
  /**
   * Indicates if the whatsapp message uses whatsapp list so we can show the confirmation message accordingly
   */
  hasList?: boolean
  image?: string | null
  info: string
  item: Pick<WhatsAppMessage | WhatsAppQuestion, 'message'>
  label: string
  personalizationList: PersonalizationType
  required?: boolean
  text?: string | null
  video?: string | null
  changeHandler: (value: ContentWhatsApp) => void
  onTabChange?: (tab: WhatsAppTab) => void
  removeMedia: () => void
  saveHandler: (file: FileUploadType) => Promise<void>
}

const WhatsApp: React.FC<Props> = (props) => {
  const {
    autoFocus,
    canRegisterTemplate,
    defaultLanguage,
    defaultUpload,
    editable,
    enableMediaCaption,
    files = {},
    hasButtons,
    hasList,
    info,
    label,
    loading = false,
    personalizationList,
    playId,
    required = true,
    changeHandler,
    getFile,
    onMicAccessError,
    onTabChange,
    removeMedia = noop,
    saveFileHandler,
    saveHandler,
    setDefaultUpload,
    setPlaying,
  } = props
  const [image, setImage] = React.useState(props.image || '')
  const [video, setVideo] = React.useState(props.video || '')
  const [audio, setAudio] = React.useState(props.audio || '')
  const [document, setDocument] = React.useState(props.document || '')
  const [tab, setTab] = React.useState(loadDefaultTab(props))
  const [text, setText] = React.useState(props.text || '')
  const [error, setError] = React.useState('')
  const [videoError, setVideoError] = React.useState(false)
  const [confirmationDialog, setConfirmationDialog] = React.useState<{
    display: boolean
    tempTab?: WhatsAppTab
  }>({
    display: false,
  })

  useGetFile(props, 'image')

  const message = props.item?.message || {}

  React.useEffect(() => {
    setAudio((s) => {
      if (message.audio !== undefined && message.audio !== s) {
        return message.audio || ''
      }
      return s
    })
  }, [message.audio])
  React.useEffect(() => {
    setDocument((s) => {
      if (message.document !== undefined && message.document !== s) {
        return message.document || ''
      }
      return s
    })
  }, [message.document])
  React.useEffect(() => {
    setImage((s) => {
      if (message.image !== undefined && message.image !== s) {
        return message.image || ''
      }
      return s
    })
  }, [message.image])
  React.useEffect(() => {
    setText((s) => {
      if (message.text !== undefined && message.text !== s) {
        return message.text || ''
      }
      return s
    })
  }, [message.text])
  React.useEffect(() => {
    setVideo((s) => {
      if (message.video !== undefined && message.video !== s) {
        return message.video || ''
      }
      return s
    })
  }, [message.video])

  React.useEffect(() => {
    setAudio((s) => {
      if (props.audio !== undefined && props.audio !== s) {
        return props.audio || ''
      }
      return s
    })
  }, [props.audio])

  React.useEffect(() => {
    setDocument((s) => {
      if (props.document !== undefined && props.document !== s) {
        return props.document || ''
      }
      return s
    })
  }, [props.document])

  React.useEffect(() => {
    setImage((s) => {
      if (props.image !== undefined && props.image !== s) {
        return props.image || ''
      }
      return s
    })
  }, [props.image])

  React.useEffect(() => {
    setVideo((s) => {
      if (props.video !== undefined && props.video !== s) {
        return props.video || ''
      }
      return s
    })
  }, [props.video])

  React.useEffect(() => {
    if (tab === WhatsAppTab.Audio) {
      setText('')
    }
  }, [tab])

  React.useEffect(() => {
    if (videoError) {
      setTimeout(() => {
        setVideoError(false)
      }, 3000)
    }
  }, [videoError])

  const handleMessageTypeSwitch = (selectedTab?: WhatsAppTab) => {
    if (!selectedTab) {
      return
    }

    setImage('')
    setAudio('')
    setVideo('')
    setDocument('')
    removeMedia()
    setTab(selectedTab)
    if (onTabChange) {
      onTabChange(selectedTab)
    }
    setConfirmationDialog({
      display: false,
    })
  }

  const confirmationText = React.useMemo(() => {
    if (!hasButtons && !hasList) {
      return 'Switching message types will discard your existing content. Do you want to continue?'
    }
    if (hasButtons) {
      return 'Only text message can be used with WhatsApp Buttons, switching message types will discard your existing content, remove the buttons and change the message response to Specific Response. Do you want to continue?'
    }
    return 'Only text message can be used with a WhatsApp List, switching message types will discard your existing content, remove the list and change the message response to Specific Response. Do you want to continue?'
  }, [hasButtons, hasList])

  return (
    <div className="whatsapp-cont">
      <ConfirmationDialog
        id="whatsapp-message-type-switch-dialog"
        text={confirmationText}
        onClose={() => setConfirmationDialog({ display: false })}
        onConfirm={() => handleMessageTypeSwitch(confirmationDialog.tempTab)}
        isOpen={confirmationDialog.display}
        deleteButtonText="Confirm"
        title="Change WhatsApp message type"
        icon="sms"
      />
      <Tabs
        value={tab}
        indicatorColor="primary"
        onChange={(e, selectedTab: WhatsAppTab) => {
          if (image || text || video || audio || document) {
            const showDialog = shouldDisplayConfirmation(
              selectedTab,
              {
                audio,
                document,
                image,
                text,
                video,
              },
              !!(hasButtons || hasList),
            )

            if (showDialog) {
              setConfirmationDialog({
                display: true,
                tempTab: selectedTab,
              })
              return
            }
          }
          handleMessageTypeSwitch(selectedTab)
        }}
      >
        {tabNames.map(({ label: tabLabel, value }, index) => (
          <Tab label={tabLabel} key={`tab-${index}`} id={`tab-${index}`} value={value} />
        ))}
      </Tabs>
      {tab === WhatsAppTab.Text && (
        <TextMessage
          audio={audio}
          autoFocus={autoFocus}
          editable={editable}
          error={error}
          info={info}
          label={label}
          personalizationList={personalizationList}
          required={required}
          text={text}
          changeHandler={({ text: newText }) => {
            setText(newText || '')
            changeHandler({
              text: newText,
              image: null,
              video: null,
              document: null,
              audio: null,
            })
          }}
          onBlur={({ message: value }) => {
            if (!value && required) {
              setError('WhatsApp message needs to have text added')
            }
          }}
          onErrorChange={setError}
        />
      )}
      {tab === WhatsAppTab.Image && (
        <ImageMessage
          enableMediaCaption={enableMediaCaption}
          files={files}
          image={image}
          personalizationList={personalizationList}
          text={text}
          changeHandler={({ image: newImage, text: newText }) => {
            if (newImage !== undefined) setImage(newImage || '')
            if (newText !== undefined) setText(newText || '')
            changeHandler({
              text: newText,
              image: newImage,
              video: null,
              document: null,
              audio: null,
            })
          }}
          saveHandler={saveHandler}
        />
      )}
      {tab === WhatsAppTab.Audio && (
        <AudioMessage
          audio={audio}
          defaultLanguage={defaultLanguage}
          defaultUpload={defaultUpload}
          files={files}
          filterType="whatsapp"
          info={info}
          loading={loading}
          personalizationList={personalizationList}
          playId={playId}
          changeHandler={changeHandler}
          getFile={getFile}
          onMicAccessError={onMicAccessError}
          removeMedia={removeMedia}
          saveFileHandler={saveFileHandler}
          setDefaultUpload={setDefaultUpload}
          setPlaying={setPlaying}
        />
      )}
      {tab === WhatsAppTab.Video && (
        <VideoMessage
          canRegisterTemplate={canRegisterTemplate}
          enableMediaCaption={enableMediaCaption}
          files={files}
          personalizationList={personalizationList}
          text={text}
          video={video}
          changeHandler={({ video: newVideo, text: newText }) => {
            if (newVideo !== undefined) setVideo(newVideo || '')
            if (newText !== undefined) setText(newText || '')
            changeHandler({
              text: newText,
              image: null,
              video: newVideo,
              document: null,
              audio: null,
            })
          }}
          getFile={getFile}
          saveHandler={saveHandler}
        />
      )}
      {tab === WhatsAppTab.Document && (
        <DocumentMessage
          document={document}
          enableMediaCaption={enableMediaCaption}
          files={files}
          personalizationList={personalizationList}
          text={text}
          changeHandler={({ document: newDocument, text: newText }) => {
            if (newDocument !== undefined) setDocument(newDocument || '')
            if (newText !== undefined) setText(newText || '')
            changeHandler({
              text: newText,
              image: null,
              video: null,
              document: newDocument,
              audio: null,
            })
          }}
          saveHandler={saveHandler}
        />
      )}
    </div>
  )
}

export const tabNames = [
  {
    label: 'Text Message',
    value: 'text',
  },
  {
    label: 'Image',
    value: 'image',
  },
  {
    label: 'Voice',
    value: 'audio',
  },
  {
    label: 'Video',
    value: 'video',
  },
  {
    label: 'PDF',
    value: 'document',
  },
]

export const useGetFile = (props: Props, type: 'audio' | 'document' | 'image' | 'video') => {
  const { getFile } = props

  const prev = usePrevious(props[type])
  const current = props[type]

  React.useEffect(() => {
    // prevent infinite loop since getFile is not memorized in the parent component
    if (current && current !== prev) {
      getFile(current)
    }
  }, [current, prev, getFile])
}

export default WhatsApp
