import { CircularProgress } from '@mui/material'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'

type AudioPlayerProps = {
  autoPlay?: boolean
  loading?: boolean
  playId: string
  source: string
  onPlayToggle?: (id: string) => void
}

const useAudio = (source: string | null) => {
  const [isPlaying, setPlaying] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [current, setCurrent] = useState(moment.duration(0, 'seconds'))
  const [length, setLength] = useState(moment.duration(0, 'seconds'))
  const [shouldAutoPlay, setShouldAutoPlay] = useState(false)

  const audioRef = useRef<HTMLAudioElement | null>(null)
  const dragRef = useRef(false)

  useEffect(() => {
    let rafId: number | null = null

    // clean up previous audio
    if (audioRef.current) {
      audioRef.current.pause()
      if (rafId) {
        cancelAnimationFrame(rafId)
      }
      if (!source) {
        audioRef.current = null
        dragRef.current = false
        setLoading(false)
        setPlaying(false)
        setProgress(0)
        setCurrent(moment.duration(0, 'seconds'))
        setLength(moment.duration(0, 'seconds'))
      }
    }
    // only create new audio if source is provided and it's different from the previous one
    if (source && audioRef.current?.src !== source) {
      const newAudio = new Audio(source)

      newAudio.addEventListener('loadedmetadata', () => {
        audioRef.current = newAudio
        setLength(moment.duration(newAudio.duration, 'seconds'))
        if (shouldAutoPlay) {
          newAudio.play().then(() => {
            setPlaying(true)
          })
        }
      })

      newAudio.addEventListener('timeupdate', () => {
        rafId = requestAnimationFrame(() => {
          if (!dragRef.current) {
            const nextCurrent = moment.duration(Math.round(newAudio.currentTime), 'seconds')
            setCurrent(nextCurrent)
            setProgress((100 * nextCurrent.asSeconds()) / moment.duration(newAudio.duration, 'seconds').asSeconds())
          }
        })
      })

      newAudio.addEventListener('ended', () => {
        setPlaying(false)
      })
    }

    return () => {
      if (rafId) {
        audioRef.current?.pause()
        cancelAnimationFrame(rafId)
      }
    }
  }, [shouldAutoPlay, source])

  return {
    progress,
    current,
    length,
    isLoading,
    isPlaying,
    setPlaying: async (shouldPlay: boolean) => {
      if (!audioRef.current) {
        // audio not loaded yet
        setShouldAutoPlay(shouldPlay)
        return
      }
      if (shouldPlay) {
        setLoading(true)
        try {
          if (current.asSeconds() === length.asSeconds()) {
            audioRef.current.currentTime = 0
          }

          await audioRef.current.play()
          setPlaying(true)
        } finally {
          setLoading(false)
        }
      } else {
        audioRef.current.pause()
        setPlaying(false)
      }
    },
    setDragStart: (value: number) => {
      dragRef.current = true
      setCurrent(moment.duration(Math.floor((value * length.asSeconds()) / 100), 'seconds'))
      setProgress(value)
    },
    setDragEnd: () => {
      if (audioRef.current) {
        audioRef.current.currentTime = current.asSeconds()
      }
      dragRef.current = false
    },
  }
}

const AudioPlayer = ({ autoPlay, loading, playId, source, onPlayToggle }: AudioPlayerProps) => {
  const { progress, current, length, isLoading, isPlaying, setPlaying, setDragStart, setDragEnd } = useAudio(source)

  useEffect(() => {
    if (autoPlay) {
      setPlaying(true)
    }
  }, [setPlaying, autoPlay])

  const toggleStreaming = useCallback(() => {
    setPlaying(!isPlaying)
    onPlayToggle?.(playId)
  }, [isPlaying, playId, onPlayToggle, setPlaying])

  const isDisabled = false
  const audioLoading = isLoading || loading

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <IconButton disabled={audioLoading} onClick={toggleStreaming}>
        {audioLoading && <CircularProgress size={24} />}
        {!audioLoading && (
          <>
            {isPlaying && <Icon>pause_circle_filled</Icon>}
            {!isPlaying && <Icon>play_circle_filled</Icon>}
          </>
        )}
      </IconButton>
      <Typography style={{ opacity: isDisabled ? '0.5' : '1' }}>
        {moment.utc(current.asMilliseconds()).format('mm:ss')}
      </Typography>
      <div
        style={{
          width: '100px',
          margin: '0 1rem',
        }}
      >
        <Slider
          value={progress}
          disabled={isDisabled}
          onChange={(e, value) => {
            if (Array.isArray(value)) {
              setDragStart(value[0])
            } else {
              setDragStart(value)
            }
          }}
          onChangeCommitted={() => setDragEnd()}
        />
      </div>
      <Typography style={{ opacity: isDisabled ? '0.5' : '1' }}>
        {moment.utc(length.asMilliseconds()).format('mm:ss')}
      </Typography>
    </div>
  )
}

export default AudioPlayer
