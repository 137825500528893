import Chip from '@mui/material/Chip'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { pathOr } from 'ramda'
import { makeStyles } from 'tss-react/mui'

import AudioPlayer from '@/app/component/atom/audio-player'
import CountryFlag from '@/app/component/atom/flag'
import IconText from '@/app/component/layout/icon-text'
import { formatDate, formatDateTime, formatPhoneNumber, parsePhoneNumber } from '@/app/service/util/format'

const useStyles = makeStyles()((theme) => ({
  success: {
    color: pathOr('', ['palette', 'success', 'main'], theme),
  },
  error: {
    color: pathOr('', ['palette', 'error', 'main'], theme),
  },
  errorIcon: {
    marginLeft: '5px',
    marginRight: '-5px',
  },
}))

export const DISPLAY_MAP = {
  hidden: () => '',
  text: ({ field, icon, title = '' }) => {
    if (icon && field !== undefined && field !== '') {
      return (
        <IconText>
          <Icon>{icon}</Icon>
          <Typography component="span" title={title}>
            {field}
          </Typography>
        </IconText>
      )
    }
    return (
      <Typography component="span" title={title}>
        {field}
      </Typography>
    )
  },
  audio: ({ field }) => <AudioPlayer source={field} />,
  date: ({ field, tz }) => (
    <IconText>
      <Icon>calendar_today</Icon>
      <Typography component="span">{formatDate(field, tz)}</Typography>
    </IconText>
  ),
  datetime: ({ field, tz }) => (
    <IconText>
      <Typography component="span">{formatDateTime(field, tz)}</Typography>
    </IconText>
  ),
  boolean: ({ classes, field }) => {
    if (field === 'true') {
      return <Icon className={classes.success}>done</Icon>
    }
    if (field === 'false') {
      return <Icon className={classes.error}>clear</Icon>
    }
    return <Icon>remove</Icon>
  },
  tag: ({ field }) => <Chip label={field} />,
  tagList: ({ field = [] }) => field.map((tag, index) => <Chip key={index} label={tag} />),
  phone: ({ field }) => {
    const { country = '' } = parsePhoneNumber(field)
    return (
      <IconText>
        <CountryFlag code={country} />
        <Typography component="span">{formatPhoneNumber(`+${field}`)}</Typography>
      </IconText>
    )
  },
}

function FieldStatic({ name, specs = {}, field = {}, title = '', displayMap = DISPLAY_MAP }) {
  const { classes } = useStyles()
  const Content = pathOr(displayMap.text, [specs.display], displayMap)
  return (
    <Content
      name={name}
      classes={classes}
      field={typeof field === 'string' ? field.replace(/\n/g, ' \u23CE ') : field}
      title={title}
      {...specs}
    />
  )
}

export default FieldStatic
