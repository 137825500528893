import { Button } from '@mui/material'
import { reverse } from 'ramda'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import { ContactType, TGetFileFunction } from '@/app/module/conversations/types'
import { SMSLog, WhatsAppLog } from '@/app/types/log'

import Part from './parts'

const useStyle = makeStyles()({
  historyWrapper: {
    padding: '10px 10px 0px',
    textAlign: 'center',
    background: 'rgb(245, 245, 245)',
    transition: 'height 0.1s',
    position: 'relative',
  },
})

type Props = {
  activeContact: ContactType

  getFile: TGetFileFunction
  onLoadMore: () => void
}

export default function History({ activeContact, getFile, onLoadMore }: Props) {
  const { classes } = useStyle()

  const renderedHistory = React.useMemo(() => {
    if (!activeContact.history) {
      return null
    }
    return reverse(activeContact.history).map((part: WhatsAppLog | SMSLog) => (
      <Part getFile={getFile} type={part.type} key={part.id} part={part} />
    ))
  }, [activeContact.history, getFile])

  return (
    <div className={classes.historyWrapper}>
      {!!activeContact.nextPage && (
        <Button variant="text" onClick={onLoadMore}>
          Load older messages
        </Button>
      )}
      {renderedHistory}
    </div>
  )
}
