import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useRequirePermission } from '@/app/module/user/store/ts/hooks'
import { Balance } from '@/app/types'
import { AppState } from '@/app/types/store'

export const getPlanCountries = (countriesIncluded: string) => {
  const numberCountries = Number(countriesIncluded)
  if (!Number.isNaN(numberCountries)) {
    return {
      value: countriesIncluded,
      unit: numberCountries > 1 ? 'Countries' : 'Country',
    }
  }

  switch (countriesIncluded) {
    case 'global':
      return {
        value: 'Global',
        unit: '',
      }

    default:
      return {
        value: countriesIncluded,
        unit: '',
      }
  }
}

export const checkPurchaseAvailable = (amount: number, balance: number, hasCCPermission: boolean) =>
  balance >= amount || hasCCPermission

/**
 * Assuming that the user has owner role since we're won't allow the user to access this part without being owner
 */
export const useCheckPurchaseAvailable = () => {
  const orgBalance = useSelector<AppState, Balance | undefined>((state) => state.payments?.autoRecharge?.data)
  const hasCCPermission = useRequirePermission('creditCard')

  // if balance is sufficient or user has permission to use credit card, return true
  return useCallback(
    (amount: number) => checkPurchaseAvailable(amount, orgBalance?.balance || 0, hasCCPermission),
    [orgBalance?.balance, hasCCPermission],
  )
}
