import cookie from 'js-cookie'
import { Redirect } from 'react-router-dom'

import { PAGE } from '@/app/definitions'
import { TRoute } from '@/app/service/render/wrapper'

import BillingRoute from './account/billing'
import OrganizationsRoute from './account/organization'
import ProfileRoute from './account/profile'
import AnalyticsRoute from './analytics'
import ReportFileRoute from './analytics/report-file'
import CampaignsRoute from './campaigns'
import CampaignsCreateRoute from './campaigns/create'
import CampaignsItemRoute from './campaigns/item'
import CampaignsManageRoute from './campaigns/manage'
import CampaignsActionRulesRoute from './campaigns/rules'
import CampaignsSummaryRoute from './campaigns/summary'
import CATISubs from './cati/subs'
import CATISurvey from './cati/survey'
import CheckInvitationRoute from './check-invitation'
import ContactsRoute from './contacts'
import ContactsAddItemRoute from './contacts/add'
import ContactsEditItemRoute from './contacts/edit'
import ConversationsRoute from './conversations'
import DashboardRoute from './dashboard'
import ExportsRoute from './exports'
import LoginRoute from './login'
import LogsRoute from './logs'
import NoOrgRoute from './no-org'
import NotFoundRoute from './not-found'
import PhoneNumbersRoute from './phonenumbers'
import PhoneNumbersItemRoute from './phonenumbers/item'
import PlansRoute from './plans'
import ResetPasswordRoute from './resetPassword'
import SignupRoute from './signup'
import TermsRoute from './terms-of-service'
import VerifyOTPRoute from './verify-otp'
import VerifyRecoveryCodeRoute from './verify-recovery-code'

const routes: TRoute[] = [
  {
    name: PAGE.HOME,
    path: '/',
    exact: true,
    component: () => {
      const redirectURL = cookie.get('redirectURL')
      cookie.remove('redirectURL')
      return redirectURL ? <Redirect to={redirectURL} /> : <Redirect to="/dashboard" />
    },
  },
  {
    name: PAGE.CONTACTS.INDEX,
    path: '/contacts',
    exact: true,
    component: ContactsRoute,
  },
  {
    name: PAGE.CONTACTS.INDEX,
    path: '/contacts/page/:page',
    exact: true,
    component: ({ match }) => <Redirect to={`/contacts/?page=${match.params.page}`} />,
  },
  {
    name: PAGE.CONTACTS.ADD,
    path: '/contacts/add',
    exact: true,
    component: ContactsAddItemRoute,
  },
  {
    name: PAGE.CONTACTS.EDIT,
    path: '/contacts/edit/:contactsId',
    exact: true,
    component: ContactsEditItemRoute,
  },
  {
    name: PAGE.CONTACTS.UPLOAD,
    path: '/contacts/upload',
    exact: true,
    component: ContactsRoute,
  },
  {
    name: PAGE.CONTACTS.SEARCH,
    path: '/contacts/search/:searchTerm',
    exact: true,
    component: ({ match }) => <Redirect to={`/contacts/?search=${match.params.searchTerm}`} />,
  },
  {
    name: PAGE.CONTACTS.SEARCH,
    path: '/contacts/search/:searchTerm/page/:page',
    exact: true,
    component: ({ match }) => (
      <Redirect to={`/contacts/?search=${match.params.searchTerm}&page=${match.params.page}`} />
    ),
  },
  {
    name: PAGE.CONTACTS.FILTER,
    path: '/contacts/filters/:filterType/:filterId',
    exact: true,
    component: ({ match }) => (
      <Redirect to={`/contacts/?filterType=${match.params.filterType}&filterId=${match.params.filterId}`} />
    ),
  },
  {
    name: PAGE.CONTACTS.FILTER,
    path: '/contacts/filters/:filterType/:filterId/page/:page',
    exact: true,
    component: ({ match }) => (
      <Redirect
        to={`/contacts/?filterType=${match.params.filterType}&filterId=${match.params.filterId}&page=${match.params.page}`}
      />
    ),
  },
  {
    name: PAGE.GROUPS.ADD,
    path: '/groups/add',
    exact: true,
    component: ContactsRoute,
  },
  {
    name: PAGE.GROUPS.EDIT,
    path: '/groups/edit/:groupsId',
    exact: true,
    component: ContactsRoute,
  },
  {
    name: PAGE.SEGMENTS.ADD,
    path: '/segments/add',
    exact: true,
    component: ContactsRoute,
  },
  {
    name: PAGE.SEGMENTS.EDIT,
    path: '/segments/edit/:segmentsId',
    exact: true,
    component: ContactsRoute,
  },
  {
    name: PAGE.CUSTOMFIELDS.ADD,
    path: '/customfields/add',
    exact: true,
    component: ContactsRoute,
  },
  {
    name: PAGE.LOGS.INDEX,
    path: '/logs',
    exact: true,
    component: LogsRoute,
  },
  {
    name: PAGE.MESSAGES.INDEX,
    path: '/messages',
    component: () => <Redirect to={'/logs'} />,
  },
  {
    name: PAGE.CONVERSATIONS.INDEX,
    path: '/conversations',
    exact: true,
    component: ConversationsRoute,
  },
  {
    name: PAGE.PHONENUMBERS.INDEX,
    path: '/phonenumbers',
    exact: true,
    component: PhoneNumbersRoute,
  },
  {
    name: PAGE.PHONENUMBERS.ITEM,
    path: '/phonenumbers/:phoneNumberId',
    exact: true,
    component: ({ match }) => <Redirect to={`/phonenumbers/${match.params.phoneNumberId}/sms`} />,
  },
  {
    name: PAGE.PHONENUMBERS.ITEM,
    path: '/phonenumbers/:phoneNumberId/:tabName',
    exact: true,
    component: PhoneNumbersItemRoute,
  },
  {
    name: PAGE.EXPORTS.INDEX,
    path: '/exports',
    exact: true,
    component: ExportsRoute,
  },
  {
    name: PAGE.DASHBOARD.INDEX,
    path: '/dashboard',
    exact: true,
    component: DashboardRoute,
  },
  {
    name: PAGE.PLANS.INDEX,
    path: '/plans',
    exact: true,
    component: PlansRoute,
  },
  {
    name: PAGE.DEMOS.INDEX,
    path: '/demos',
    exact: true,
    component: () => <Redirect to={'/'} />,
  },
  {
    name: PAGE.CAMPAIGNS.INDEX,
    path: '/campaigns',
    exact: true,
    component: CampaignsRoute,
  },
  {
    name: PAGE.CAMPAIGNS.ADD,
    path: '/campaigns/create',
    exact: true,
    component: CampaignsCreateRoute,
  },
  {
    name: PAGE.CAMPAIGNS.ADD,
    path: '/campaigns/create/:campaignType',
    exact: true,
    component: CampaignsItemRoute,
  },
  {
    name: PAGE.CAMPAIGNS.EDIT,
    path: '/campaigns/edit/:campaignsId',
    exact: true,
    component: CampaignsItemRoute,
  },
  {
    name: PAGE.CAMPAIGNS.EDIT,
    path: '/campaigns/edit/:campaignsId/:step',
    exact: true,
    component: CampaignsItemRoute,
  },
  {
    name: PAGE.CAMPAIGNS.MANAGE,
    path: '/campaigns/manage/:campaignsId',
    exact: true,
    component: CampaignsManageRoute,
  },
  {
    name: PAGE.CAMPAIGNS.SUMMARY,
    path: '/campaigns/summary/:campaignsId/:step',
    exact: true,
    component: CampaignsSummaryRoute,
  },
  {
    name: PAGE.CAMPAIGNS.SUMMARY,
    path: '/campaigns/summary/:campaignsId',
    exact: true,
    component: CampaignsSummaryRoute,
  },
  {
    name: PAGE.CAMPAIGNS.RULES,
    path: '/campaigns/action-rules',
    exact: true,
    component: CampaignsActionRulesRoute,
  },
  {
    name: PAGE.ACCOUNT.LOGIN,
    path: '/login',
    exact: true,
    component: LoginRoute,
  },
  {
    name: PAGE.ACCOUNT.SIGNUP,
    path: '/signup',
    exact: true,
    component: SignupRoute,
  },
  {
    name: PAGE.ACCOUNT.REQUEST_RESET_PASSWORD,
    path: '/request-reset-password',
    exact: true,
    component: ResetPasswordRoute,
  },
  {
    name: PAGE.ACCOUNT.VERIFY_OTP,
    path: '/2fa',
    exact: true,
    component: VerifyOTPRoute,
  },
  {
    name: PAGE.ACCOUNT.VERIFY_RECOVERY_CODE,
    path: '/recovery-code',
    exact: true,
    component: VerifyRecoveryCodeRoute,
  },
  {
    name: PAGE.ACCOUNT.RESET_PASSWORD,
    path: '/reset-password',
    exact: true,
    component: ResetPasswordRoute,
  },
  {
    name: PAGE.INVITATION,
    path: '/invite',
    component: CheckInvitationRoute,
  },
  {
    name: PAGE.ACCOUNT.INDEX,
    path: '/account',
    exact: true,
    component: () => <Redirect to="/account/profile" />,
  },
  {
    name: PAGE.ACCOUNT.PROFILE.INDEX,
    path: '/account/profile',
    exact: true,
    component: () => <Redirect to="/account/profile/info" />,
  },
  {
    name: PAGE.ACCOUNT.PROFILE.INDEX,
    path: '/account/profile/:tabName',
    exact: true,
    component: ProfileRoute,
  },
  {
    name: PAGE.ACCOUNT.ORGANIZATION.INDEX,
    path: '/account/organization',
    exact: true,
    component: () => <Redirect to="/account/organization/info" />,
  },
  {
    name: PAGE.ACCOUNT.ORGANIZATION.INDEX,
    path: '/account/organization/:tabName',
    exact: true,
    component: OrganizationsRoute,
  },
  {
    name: PAGE.ACCOUNT.ORGANIZATION.INDEX,
    path: '/account/organization/:tabName/:selectedOrgId',
    exact: true,
    component: OrganizationsRoute,
  },
  {
    name: PAGE.ACCOUNT.BILLING.INDEX,
    path: '/account/billing',
    exact: true,
    component: () => <Redirect to="/account/billing/pay" />,
  },
  {
    name: PAGE.ACCOUNT.BILLING.INDEX,
    path: '/account/billing/:tabName',
    exact: true,
    component: BillingRoute,
  },
  {
    name: PAGE.ANALYTICS.INDEX,
    path: '/analytics',
    exact: true,
    component: AnalyticsRoute,
  },
  {
    name: PAGE.ANALYTICS.FILE,
    path: '/analytics/:exportId',
    component: ReportFileRoute,
  },
  {
    name: PAGE.TERMS,
    path: '/terms-of-service',
    exact: true,
    component: TermsRoute,
  },
  {
    name: PAGE.CATI.SURVEY,
    path: '/cati/:itemId/sub/:runId',
    exact: true,
    component: CATISurvey,
  },
  {
    name: PAGE.CATI.SUBS,
    path: '/cati/contacts',
    exact: true,
    component: CATISubs,
  },
  {
    name: PAGE.NOORG,
    path: '/no-org',
    exact: true,
    component: ({ match }) => {
      const redirectURL = cookie.get('redirectURL')
      cookie.remove('redirectURL')
      return redirectURL ? <Redirect to={redirectURL} /> : <NoOrgRoute match={match} />
    },
  },
  {
    name: PAGE.NOTFOUND,
    path: '**',
    component: NotFoundRoute,
  },
]

export default routes
