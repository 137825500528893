import Chip from '@mui/material/Chip'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import { match, reduce, replace, split, test, trim } from 'ramda'
import React, { useMemo, useState } from 'react'

import { CAMPAIGN_CHANGE_DELAY } from '@/app/module/campaigns/definitions'
import { helperTextForChoiceInput } from '@/app/module/campaigns/helpers'

const getRange = (input) => {
  if (test(/^[0-9]+-[0-9]+$/, input)) {
    const [min, max] = split('-', input)
    if (Number(min) <= Number(max) && Number(min) >= 0) {
      return [
        {
          min: Number(min),
          max: Number(max),
        },
      ]
    }
  }
  return []
}

const getReply = (input) => (test(/^[0-9]+-[0-9]+$/, input) ? [] : [replace(/"/g, '', input)])
const getVoiceReply = (input) => (test(/^[0-9]+$/, input) ? [replace(/"/g, '', input)] : [])
const getSpeechReply = (input) => [replace(/"/g, '', input)]

export const processInputs = (rawInput, valueMap = {}, rangesAvailable = false, isSpeech = false) => {
  const input = replace(/(?:\r\n|\r|\n)/g, ',', rawInput)
  return reduce(
    ({ ranges, replies }, rawEntry) => {
      let newReplies = []
      const entry = trim(rawEntry)

      if (isSpeech) {
        newReplies = [...replies, ...getSpeechReply(entry)]
      } else {
        newReplies = [...replies, ...(rangesAvailable ? getReply(entry) : getVoiceReply(entry))]
      }

      const newRanges = {}
      if (rangesAvailable) {
        if (!valueMap[entry]) {
          newRanges.ranges = [...ranges, ...getRange(entry)]
        } else {
          newRanges.ranges = [...ranges]
        }
      }

      return {
        ...newRanges,
        replies: newReplies.filter((item) => item && !valueMap[item.toLowerCase()]),
      }
    },
    { ranges: [], replies: [] },
    match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g, input),
  )
}

export default function InputChoice(props) {
  const rangesAvailable = !!props.tagList.ranges
  const isSpeech = !!props.tagList.speech

  const [error, setError] = useState(false)
  const [value, setValue] = useState('')
  const [blurTimeout, setBlurTimeout] = useState(null)

  const valueMap = useMemo(() => {
    const map = {}
    const { replies = [], ranges = [] } = props.tagList || {}
    for (let i = 0; i < replies.length; i += 1) {
      const item = replies[i].toLowerCase()
      map[item] = 1
    }
    for (let i = 0; i < ranges.length; i += 1) {
      const item = ranges[i]
      map[`${item.min}-${item.max}`] = 1
    }

    return map
  }, [props.tagList])

  const updateInput = (input) => {
    if (blurTimeout) {
      clearTimeout(blurTimeout)
    }
    const { replies, ranges = [] } = processInputs(input, valueMap, rangesAvailable, isSpeech)
    if (replies.length > 0 || ranges.length > 0) {
      props.onChange({
        replies: [...props.tagList.replies, ...replies],
        ...(rangesAvailable && {
          ranges: [...props.tagList.ranges, ...ranges],
        }),
        speech: isSpeech,
      })
      setValue('')
    } else {
      setError(true)
    }
  }

  React.useEffect(() => {
    if (!value && error) {
      setError(false)
    }
  }, [value, error])

  const checkBlur = () => {
    if (blurTimeout) {
      clearTimeout(blurTimeout)
    }
    setBlurTimeout(
      setTimeout(() => {
        if (value) {
          setError(true)
        }
      }, CAMPAIGN_CHANGE_DELAY),
    )
  }

  const handleDelete = (index, type) => {
    props.onChange({
      ...props.tagList,
      [type]: [...props.tagList[type].slice(0, index), ...props.tagList[type].slice(index + 1)],
    })
  }

  const noChoice = !props.tagList.replies.length && !props.tagList.ranges?.length && value === ''

  return (
    <div className="input-choice">
      <div
        style={{
          display: 'flex',
          paddingRight: '5px',
        }}
      >
        <TextField
          InputProps={props.InputProps}
          inputProps={props.inputProps}
          variant="standard"
          disabled={props.disabled}
          onBlur={checkBlur}
          onFocus={() => {
            if (error) {
              setError(false)
            }
          }}
          helperText={helperTextForChoiceInput(rangesAvailable, props.tagList.speech)}
          error={error || noChoice}
          multiline={true}
          style={{
            height: 'auto',
            maxHeight: 'none',
            minHeight: '42px',
            whiteSpace: 'unset',
            marginRight: '5px',
            flex: 1,
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ',') {
              e.preventDefault()
              if (value) {
                updateInput(value)
              }
            }
          }}
          onChange={(e) => setValue(e.currentTarget.value)}
          value={value}
        />
        <IconButton
          disabled={!value}
          size="small"
          style={{
            height: '100%',
          }}
          onClick={() => updateInput(value)}
          aria-label="add"
        >
          <Icon size="small">add</Icon>
        </IconButton>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          marginTop: '5px',
        }}
      >
        {props.tagList.replies.map((input, index) => (
          <Chip
            key={`${index}-${input}`}
            data-testid={`input-choice-replies-chip-${index}`}
            color="primary"
            variant="outlined"
            style={{
              marginRight: '5px',
            }}
            size="small"
            label={input}
            onDelete={props.disabled ? undefined : () => handleDelete(index, 'replies')}
          />
        ))}
        {rangesAvailable &&
          props.tagList.ranges.map(({ min, max }, index) => (
            <Chip
              key={`${min}-${max}-${index}`}
              data-testid={`input-choice-ranges-chip-${index}`}
              color="secondary"
              variant="outlined"
              style={{
                marginRight: '5px',
              }}
              size="small"
              label={
                <React.Fragment>
                  {min}↔{max}
                </React.Fragment>
              }
              onDelete={() => handleDelete(index, 'ranges')}
            />
          ))}
      </div>
    </div>
  )
}
