import React from 'react'

import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Notifications from '@/app/module/notifications'
import ReportFileContainer from '@/app/module/reports/components/report-file.container'
import { MatchProps } from '@/app/types'

type Props = {
  match: MatchProps
}

const ReportFile: React.FC<Props> = ({ match }) => (
  <AuthGuard url={match.url} path={match.path}>
    <Layout title="Analytics - engageSPARK">
      <AuthHeader currentPage={PAGE.ANALYTICS.INDEX} />
      <ReportFileContainer />
    </Layout>
    <Notifications />
  </AuthGuard>
)

export default ReportFile
