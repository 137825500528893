import { Typography } from '@mui/material'
import { append, remove, update } from 'ramda'
import React, { CSSProperties } from 'react'
import { v1 as uuid } from 'uuid'

import InputField from '@/app/component/atom/form/input'
import { CampaignAddButton } from '@/app/module/campaigns/component/helpers'
import { useCampaignDebouncedState } from '@/app/module/campaigns/component/hooks/use-campaign-debounced-state'
import { ERROR_WHATSAPP_LIST_BUTTON_LENGTH, WA_LIST_BUTTON_LENGTH } from '@/app/module/campaigns/definitions'
import {
  CampaignTemplateType,
  LocalWhatsAppList,
  LocalWhatsAppListItem,
  PartFamily,
  TWhatsAppList,
} from '@/app/module/campaigns/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { emptyAction, unwrapActions, wrapActions } from '@/app/module/campaigns/utils/actions/wrap'
import validate, { hasMaxError, hasRequiredError } from '@/app/service/validate'
import { ChangeEvent } from '@/app/types'

import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import { trimContent } from './types/multimessage/content'
import WhatsappListItem from './whatsapp-list-item'

type Props = {
  actionProps: ActionProps
  apiProps: ApiProps
  campaignType: CampaignTemplateType
  onList: TWhatsAppList
  snippetProps: SnippetProps

  onChange: (value: TWhatsAppList) => void
}

const ResponseWhatsappList: React.FC<Props> = ({
  actionProps,
  apiProps,
  campaignType,
  onList,
  snippetProps,
  onChange,
}) => {
  const initialState = React.useMemo(() => fromOnList(onList), [onList])
  const handleChange = React.useCallback((value: LocalWhatsAppList) => onChange(toOnList(value)), [onChange])
  const [state, setState] = useCampaignDebouncedState(initialState, handleChange)

  const addOptionDisabled = state.items.length >= 10
  const listActions = React.useMemo(
    () =>
      getActionOptionsForCampaign({
        campaignType,
        partType: PartFamily.WhatsApp,
        actionNamespace: ActionContext.OnList,
      }),
    [campaignType],
  )

  const handleButtonBlur = React.useCallback(
    (e: ChangeEvent) => {
      setState((s) => ({
        ...s,
        button: trimContent(e.value),
      }))
    },
    [setState],
  )
  const handleButtonChange = React.useCallback(
    (e: ChangeEvent) => {
      setState((s) => ({
        ...s,
        button: e.value,
      }))
    },
    [setState],
  )

  const handleListItemChange = React.useCallback(
    (listIndex: number, value: LocalWhatsAppListItem) =>
      setState((s) => ({
        ...s,
        items: update(listIndex, value, s.items),
      })),
    [setState],
  )
  const handleListItemClose = React.useCallback(
    (listIndex: number) => setState((s) => ({ ...s, items: remove(listIndex, 1, s.items) })),
    [setState],
  )
  const handleListItemAdd = React.useCallback(() => {
    if (addOptionDisabled) {
      return
    }

    setState((s) => ({
      ...s,
      items: append(
        {
          id: uuid(),
          actions: [emptyAction],
          description: '',
          label: '',
          title: '',
        },
        s.items,
      ),
    }))
  }, [addOptionDisabled, setState])

  return (
    <div className="campaign-whatsapp-list-tab-content">
      <Typography>WhatsApp List</Typography>
      <div>
        <Typography color="textSecondary" variant="caption" gutterBottom={true}>
          Specify up to 10 options that the contact can select and what actions we should take for each option.
        </Typography>
        <div style={{ height: 12 }} />
        <InputField
          data-testid={`whatsapp-list-button-${actionProps.index}`}
          error={validateButton(state.button)}
          label="Button"
          name={`whatsapp-list-button-${actionProps.index}`}
          style={inputStyle}
          value={state.button}
          variant="standard"
          onBlur={handleButtonBlur}
          onChange={handleButtonChange}
        />
        {state.items.map((listItem, i) => (
          <WhatsappListItem
            {...actionProps}
            {...apiProps}
            {...snippetProps}
            actionOptions={listActions}
            hasTranscribe={false}
            key={listItem.id}
            listIndex={i}
            transcribeEnabled={false}
            value={listItem}
            onChange={handleListItemChange}
            onClose={handleListItemClose}
          />
        ))}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CampaignAddButton
            disabled={addOptionDisabled}
            icon="add"
            text="Add option"
            tooltip={addOptionDisabled ? 'You can only specify up to 10 options' : 'Add option'}
            onClick={handleListItemAdd}
          />
        </div>
      </div>
    </div>
  )
}

const inputStyle: CSSProperties = {
  height: 'auto',
  maxHeight: 'none',
  minHeight: '42px',
  whiteSpace: 'unset',
  marginRight: '5px',
  flex: 1,
}

const fromOnList = (onList: TWhatsAppList): LocalWhatsAppList => {
  return {
    ...onList,
    items: onList.items.map((item) => ({
      ...item,
      actions: unwrapActions(item.actions || []),
    })),
  }
}

const toOnList = (onList: LocalWhatsAppList): TWhatsAppList => {
  return {
    ...onList,
    items: onList.items.map((item) => ({
      ...item,
      actions: wrapActions(item.actions || []),
    })),
  }
}

const validateButton = validate(hasRequiredError, hasMaxError(WA_LIST_BUTTON_LENGTH, ERROR_WHATSAPP_LIST_BUTTON_LENGTH))

export default ResponseWhatsappList

export type { Props as ResponseWhatsappListProps }
