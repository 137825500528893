import { compose, concat, indexBy, isEmpty, keys, map, reduce } from 'ramda'
import React, { useEffect, useMemo } from 'react'

import PzMenu, { PzMenuRef } from '@/app/component/atom/pz-menu'
import PzPill from '@/app/component/atom/pz-pill'
import { wrapEachBrackets } from '@/app/module/utils/personalization'

import InputHighlight from './input-highlight'

type PersonalizationList = Record<string, Array<{ label: string; value: string }>>

type Props = {
  helperText?: string
  id: string
  name: string
  error?: string
  label?: string
  multiline?: boolean
  required?: boolean
  rows?: number
  type?: string
  personalizationList?: PersonalizationList
  value: string
  onChange: (value: string) => void
}

const EmailTextField: React.FC<Props> = (props) => {
  const { personalizationList = {}, value, onChange } = props
  const inputRef = React.useRef<HTMLInputElement>()
  const highlightContainerRef = React.useRef<HTMLDivElement>()
  const [selectionStart, setSelectionStart] = React.useState(0)

  const menuRef = React.useRef<PzMenuRef | null>(null)

  const pzMap = useMemo(() => PZMAP(personalizationList), [personalizationList]) as Record<string, { label: string }>

  useEffect(() => {
    if (!inputRef.current || document.activeElement === inputRef.current || selectionStart === undefined) {
      return
    }

    inputRef.current?.focus()
  }, [selectionStart])

  const updateSelectionStart = () => {
    if (!inputRef.current || typeof inputRef.current.selectionStart !== 'number') {
      return
    }

    setSelectionStart(inputRef.current.selectionStart)
  }

  const resetSelectionPosition = (updatedSelectionPosition: number) => {
    setTimeout(() => {
      if (!inputRef.current) {
        return
      }

      inputRef.current.setSelectionRange(updatedSelectionPosition, updatedSelectionPosition)
    }, 0)
  }

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    chunks: { index: number; startIndex: number; value: string }[],
  ) => {
    if (!(e.target instanceof HTMLInputElement) && !(e.target instanceof HTMLTextAreaElement)) {
      return
    }
    if (e.key === 'Backspace') {
      if (inputRef.current?.selectionStart !== inputRef.current?.selectionEnd) {
        return
      }

      const gotChunk = checkIfProtected({ pos: e.target.selectionStart || 0, chunks })
      if (!isEmpty(gotChunk)) {
        const tempSelectionStart = inputRef.current?.selectionStart || selectionStart
        e.preventDefault()
        const { startIndex = 0, value: matchedString } = gotChunk
        if (matchedString) {
          onChange(`${value.slice(0, startIndex)}${value.slice(startIndex + matchedString.length)}`)
          resetSelectionPosition(tempSelectionStart - matchedString.length)
        }
      }
    }
    if (e.key === '@') {
      if (!inputRef.current) {
        return
      }
      const currentSelection = inputRef.current.selectionStart || 0
      if (currentSelection < 1 || value.charAt(currentSelection - 1) === ' ') {
        menuRef.current?.open()
        e.preventDefault()
      }
    }
  }

  return (
    <>
      <div
        className={props.id}
        style={{
          marginBottom: '25px',
          position: 'relative',
        }}
      >
        <InputHighlight
          {...props}
          autoFocus
          variant="outlined"
          fullWidth
          size="small"
          onKeyDown={onKeyDown}
          onSelect={updateSelectionStart}
          inputRef={inputRef}
          onChange={(e: { name: string; value: string }) => onChange(e.value)}
          highlightPattern={Object.keys(pzMap).map(wrapEachBrackets).join('|')}
          highlightContainerRef={highlightContainerRef}
          isMatch={(val: any) => !!pzMap[val]}
          HighlightComponent={({ match }) => <PzPill label={pzMap[match]?.label || ''} />}
          scrollable={true}
        />
        <div
          style={{
            position: 'absolute',
            right: '2px',
            top: '4px',
            zIndex: 999,
            backgroundColor: 'white',
            borderRadius: '50%',
          }}
        >
          <PzMenu
            id="email-text-field"
            personalizationList={personalizationList}
            ref={menuRef}
            onSelect={(item) => {
              const toBeAdded = `{{${item.label}}}`
              const newMessage = `${value.slice(0, selectionStart)}${toBeAdded}${value.slice(selectionStart)}`
              onChange(newMessage)

              const newSelectionStart = (inputRef.current?.selectionStart || selectionStart) + toBeAdded.length

              resetSelectionPosition(newSelectionStart)
              setSelectionStart(newSelectionStart)
            }}
          />
        </div>
      </div>
    </>
  )
}

const PZMAP = (personalizationList: PersonalizationList, prefix = '{{', suffix = '}}') =>
  compose(
    indexBy(({ label }) => `${prefix}${label}${suffix}`),
    map(({ label, value }) => ({
      label,
      value,
    })),
    reduce((acc: { label: string; value: string }[], key: string) => concat(acc, personalizationList[key]), []),
    keys,
  )(personalizationList)

const checkIfProtected = ({ pos, chunks }: { pos: number; chunks: { startIndex: number; value: string }[] }) =>
  reduce(
    (accum: { startIndex?: number; value?: string }, chunk) => {
      const { startIndex, value } = chunk
      if (
        value.length > 0 &&
        value.startsWith('{{') &&
        value.endsWith('}}') &&
        startIndex <= pos &&
        startIndex + value.length >= pos
      ) {
        return chunk
      }
      return accum
    },
    {},
    chunks,
  )

export default EmailTextField
