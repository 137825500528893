import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import React from 'react'

import { PersonalizationType } from '@/app/module/campaigns/types'
import Message from '@/app/module/logs/component/send/message'

export const UploadContainer = styled('div')({
  height: '200px',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  marginTop: '20px',
  cursor: 'pointer',
})

export const PreviewImage = styled('img')({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
})

export const EditOverlay = styled('div')({
  position: 'absolute',
  top: '2px',
  right: '2px',
})

export const EditBtn = styled(Button)({
  boxShadow: 'unset',
  backgroundColor: '#fff',
  border: 'none',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
  },
})

export const PlayOverlay = styled('div')({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
})

export const PlayContainer = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  height: '100%',
  alignItems: 'center',
})

export const PlayBtn = styled(IconButton)({
  color: '#fff',
  backgroundColor: '#2f80ac',
  '&:hover': {
    backgroundColor: 'rgba(47, 128, 172, 0.85)',
  },
})

export const RelativeContainer = styled('div')({
  position: 'relative',
})

export const InfoContainer = styled('div')({
  marginTop: '5px',
})

export const DocumentPlaceholder = styled('img')({
  maxHeight: '120px',
})

type CaptionProps = {
  enableMediaCaption: boolean
  personalizationList: PersonalizationType
  text: string

  onChange: (text: string) => void
}

export const Caption: React.FC<CaptionProps> = ({ enableMediaCaption, personalizationList, text, onChange }) => {
  if (!enableMediaCaption) {
    return null
  }

  return (
    <Message
      label="Caption"
      message={text || ''}
      whatsapp={true}
      personalizationList={personalizationList}
      onChange={({ message: updatedValue }) => {
        onChange(updatedValue)
      }}
    />
  )
}

export type { CaptionProps }
