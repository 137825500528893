import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input'
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'

import Flag from '@/app/component/atom/flag'
import { isCountryCode } from '@/app/service/util/country'

type Props<T extends FieldValues> = {
  name: Path<T>
  defaultCountry?: string
} & Pick<MuiTelInputProps, 'label' | 'autoFocus' | 'required'>

export function RHFPhoneField<T extends FieldValues>({ name, defaultCountry, ...props }: Props<T>) {
  const { control } = useFormContext<T>()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiTelInput
          {...field}
          variant="standard"
          fullWidth
          size="small"
          defaultCountry={defaultCountry && isCountryCode(defaultCountry) ? defaultCountry : undefined}
          sx={{ mb: 2 }}
          {...props}
          error={!!error}
          slotProps={{
            htmlInput: {
              'aria-label': name,
            },
          }}
          helperText={error?.message}
          getFlagElement={(isoCode, { imgProps: { width } }) => <Flag code={isoCode} width={width} />}
        />
      )}
    />
  )
}
