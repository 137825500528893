import TextField from '@mui/material/TextField'
import React from 'react'

import { ChoiceValueSingle } from '@/app/module/campaigns/types'

type Props = {
  disabled?: boolean
  tagList: ChoiceValueSingle
  onChange: (value: ChoiceValueSingle) => void
}

export default function SingleChoice({ disabled, tagList, onChange }: Props) {
  const [error, setError] = React.useState(false)

  const value = tagList.reply

  React.useEffect(() => {
    if (!value && error) {
      setError(false)
    }
  }, [value, error])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          paddingRight: '5px',
        }}
      >
        <TextField
          variant="standard"
          className="campaign-question-single-choice-input"
          disabled={disabled}
          error={error || !value}
          multiline={true}
          style={{
            height: 'auto',
            maxHeight: 'none',
            minHeight: '42px',
            whiteSpace: 'unset',
            marginRight: '5px',
            flex: 1,
          }}
          value={value}
          onChange={(e) => onChange({ reply: e.currentTarget.value })}
          onFocus={() => {
            if (error) {
              setError(false)
            }
          }}
        />
      </div>
    </div>
  )
}
