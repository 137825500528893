import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { activateTrialMode } from '@/app/module/auth/store/actions'
import { selectToken } from '@/app/module/auth/store/selectors'
import { createNotification } from '@/app/module/notifications/store/actions'
import { getCards } from '@/app/module/payments/store/actions'
import { selectCards } from '@/app/module/payments/store/selectors'
import { selectActiveOrg, selectOrgId, selectUserId } from '@/app/module/user/store/selectors'

import Plans from './component'
import { deletePlan, getPlan, getPlans, subscribePlan } from './store/actions'
import { selectPlan, selectPlans } from './store/selectors'

const selector = ({ auth, user, plans, payments }) => ({
  error: pathOr([], ['list', 'error'], plans) || pathOr([], ['item', 'error'], plans),
  isLoading:
    pathOr(false, ['list', 'loading'], plans) ||
    pathOr(false, ['item', 'loading'], plans) ||
    user.details.loading ||
    user.organization.loading,
  plan: selectPlan(plans),
  plans: selectPlans(plans),
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  userId: selectUserId({ user }),
  profile: pathOr({}, ['details', 'data'], user),
  hydrateProps: ['token', 'orgId'],
  activeOrg: selectActiveOrg({ user }),
  cards: selectCards({ payments }),
})

const dispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId }) =>
        () =>
          Promise.all([
            dispatch(getPlan({ token, orgId })),
            dispatch(getCards({ token, orgId })),
            dispatch(getPlans({ token })),
          ]),
      subscribePlan,
      deletePlan,
      getPlan,
      createNotification,
      getCards,
      activateTrialMode,
    },
    dispatch,
  )

export default connect(selector, dispatcher)(withRouter(Plans))
