import React from 'react'

import { useCampaignDebouncedState } from '@/app/module/campaigns/component/hooks/use-campaign-debounced-state'
import { CAMPAIGN_RETRIES } from '@/app/module/campaigns/definitions'
import {
  CampaignTemplateType,
  LocalOnInvalidReply,
  LocalOnRetriesExhausted,
  LocalOnTimeout,
  RepeatQuestion,
} from '@/app/module/campaigns/types'

import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import { ConfigDivider } from './response-configs-components'
import ResponseVoiceFailure from './response-voice-failure'
import ResponseVoiceInvalid from './response-voice-invalid'
import ResponseVoiceKeypressAfter from './response-voice-keypress-after'
import ResponseVoiceRepeatKey from './response-voice-repeat-key'
import ResponseVoiceRetries from './response-voice-retries'
import { fromConfigs, SpecificConfigs, SpecificConfigsState, toConfigs } from './response-voice-specific-configs-utils'
import ResponseVoiceTimeout from './response-voice-timeout'

type Props = {
  actionProps: ActionProps
  apiProps: ApiProps
  campaignType: CampaignTemplateType
  configs: SpecificConfigs
  id: string
  snippetProps: SnippetProps

  onChange: (value: SpecificConfigs) => void
}

const ResponseVoiceSpecificConfigs: React.FC<Props> = ({
  actionProps,
  apiProps,
  campaignType,
  configs,
  id,
  snippetProps,
  onChange,
}) => {
  const initialState = React.useMemo(() => fromConfigs(configs), [configs])
  const handleChange = React.useCallback((value: SpecificConfigsState) => onChange(toConfigs(value)), [onChange])
  const [state, setState] = useCampaignDebouncedState(initialState, handleChange)

  const handleKeyPressAfterChange = React.useCallback(
    (value: boolean) =>
      setState((s) => ({
        ...s,
        keypressAfterAudio: value,
      })),
    [setState],
  )
  const handleRepeatKeyChange = React.useCallback(
    (value: Partial<RepeatQuestion>) =>
      setState((s) => ({
        ...s,
        repeatQuestion: {
          ...s.repeatQuestion,
          ...value,
        },
      })),
    [setState],
  )
  const handleInvalidChange = React.useCallback(
    (value: Partial<LocalOnInvalidReply>) =>
      setState((s) => ({ ...s, onInvalidReply: { ...s.onInvalidReply, ...value } })),
    [setState],
  )
  const handleTimeoutChange = React.useCallback(
    (value: Partial<LocalOnTimeout>) =>
      setState((s) => {
        const shouldUpdateRetries = 'enabled' in value
        if (!shouldUpdateRetries) {
          return { ...s, onTimeout: { ...s.onTimeout, ...value } }
        }

        const defaultRetries = typeof s.retries === 'number' ? s.retries : CAMPAIGN_RETRIES
        return {
          ...s,
          onTimeout: { ...s.onTimeout, ...value },
          retries: value.enabled || s.onInvalidReply.enabled ? defaultRetries : 0,
        }
      }),
    [setState],
  )
  const handleRetriesChange = React.useCallback(
    ({ value }: { value: string }) => setState((s) => ({ ...s, retries: Number(value) })),
    [setState],
  )
  const handleFailureChange = React.useCallback(
    (value: Partial<LocalOnRetriesExhausted>) =>
      setState((s) => ({ ...s, onRetriesExhausted: { ...s.onRetriesExhausted, ...value } })),
    [setState],
  )

  const retriesEnabled = !!(state.onInvalidReply.enabled || state.onTimeout.enabled)

  return (
    <>
      <ConfigDivider />
      <ResponseVoiceKeypressAfter keypressAfterAudio={state.keypressAfterAudio} onChange={handleKeyPressAfterChange} />
      <ConfigDivider />
      <ResponseVoiceRepeatKey repeatQuestion={state.repeatQuestion} onChange={handleRepeatKeyChange} />
      <ConfigDivider />
      <ResponseVoiceInvalid
        actionProps={actionProps}
        apiProps={apiProps}
        campaignType={campaignType}
        id={id}
        info="Specify what we should do if the contact presses an invalid key. Afterwards, we will wait for the contact to press a key again—until they run out of chances."
        invalid={state.onInvalidReply}
        snippetProps={snippetProps}
        onChange={handleInvalidChange}
      />
      <ConfigDivider />
      <ResponseVoiceTimeout
        actionProps={actionProps}
        apiProps={apiProps}
        campaignType={campaignType}
        id={id}
        info="Specify what we should do if the contact does not press a key within a period of time that you specify. Afterwards, we will wait for the contact to press a key—until they run out of chances."
        inputAfterText="seconds"
        inputBeforeText="Take these actions if the contact doesn't press a key after: "
        snippetProps={snippetProps}
        timeout={state.onTimeout}
        onChange={handleTimeoutChange}
      />
      <ConfigDivider />
      <ResponseVoiceRetries retries={state.retries} retriesEnabled={retriesEnabled} onChange={handleRetriesChange} />
      <ConfigDivider />
      <ResponseVoiceFailure
        actionProps={actionProps}
        apiProps={apiProps}
        campaignType={campaignType}
        failure={state.onRetriesExhausted}
        id={id}
        info="Specify what we should do after the contact runs out of chances."
        snippetProps={snippetProps}
        onChange={handleFailureChange}
      />
    </>
  )
}

export default React.memo(ResponseVoiceSpecificConfigs)

export type { Props as ResponseVoiceSpecificConfigsProps }
