import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { find, propEq } from 'ramda'
import React, { useMemo } from 'react'

import IconText from '@/app/component/layout/icon-text'
import { useDebouncedFn, useDeepState, usePrevious } from '@/app/helpers'
import { CAMPAIGN_CHANGE_DELAY } from '@/app/module/campaigns/definitions'
import { getMessageContent, ItemType, LanguageType, MessageType } from '@/app/module/campaigns/helpers'
import { decodeText } from '@/app/module/campaigns/store/selectors'

import LanguageSelector from './language-selector'

type Props = {
  languages?: LanguageType[]
  defaultLanguage: string
  item: ItemType
  info: string
  children: any
  type: 'audio' | 'message'
  content: 'playfile' | 'text' | 'image' | 'say'
  disabled?: boolean
  selectedVoice?: string
  onChange: (value: any) => void
  onLanguageChange?: (language: string) => void
}

export default function LanguageWrapper(props: Props) {
  const {
    defaultLanguage,
    info,
    children,
    item,
    type,
    content,
    disabled = false,
    selectedVoice,
    onChange,
    onLanguageChange,
  } = props

  const [activeLanguage, setActiveLanguage] = React.useState<string>('')

  const languages = useMemo(() => props.languages || [], [props.languages])

  const multilingual = languages?.length > 0

  const [message] = useDeepState(item[type])
  const [activeContent, setActiveContent] = React.useState<string>(
    getMessageContent({ message, defaultLanguage, languages }, activeLanguage, content),
  )
  const [, setLastContent] = React.useState<string>()
  const [error, setError] = React.useState<string>('')

  React.useEffect(() => {
    const newContent = getMessageContent({ message, defaultLanguage, languages }, activeLanguage, content)
    setLastContent((s) => {
      if (s === undefined || s !== newContent) {
        setActiveContent(newContent)
      }

      return s
    })
  }, [activeLanguage, content, defaultLanguage, languages, message])

  React.useEffect(() => {
    setLastContent(undefined) // reset last content when language changes
  }, [activeLanguage, defaultLanguage, languages])

  const previousVoice = usePrevious(selectedVoice)

  React.useEffect(() => {
    if (content === 'say' && selectedVoice !== previousVoice) {
      if (!activeLanguage || activeLanguage === defaultLanguage) {
        onChange({
          ...item,
          [type]: {
            ...item[type],
            voice: selectedVoice,
          },
        })
      } else {
        onChange({
          ...item,
          [type]: {
            ...item[type],
            translations: {
              ...item[type]?.translations,
              [activeLanguage]: {
                ...(item[type].translations?.[activeLanguage] || {}),
                voice: selectedVoice,
              },
            },
          },
        })
      }
    }
  }, [activeLanguage, content, defaultLanguage, item, previousVoice, selectedVoice, type, onChange])

  const debouncedSetLastContent = useDebouncedFn(setLastContent, CAMPAIGN_CHANGE_DELAY) // debounce it so it matches the change handler delay in the parent component
  const onContentChange = ({ message: value }: { message: string }) => {
    const updatedValue = content === 'text' || content === 'say' ? decodeText(value) : value
    setActiveContent(updatedValue)
    setError(updatedValue ? '' : error)

    let updatedMessage: MessageType = {
      ...item[type],
      [content]: updatedValue,
    }
    if (content === 'say' && selectedVoice) {
      updatedMessage.voice = selectedVoice
    }
    if (multilingual) {
      const found = find(propEq('value', activeLanguage), languages)
      if (found && found.value !== defaultLanguage) {
        const translation = {
          [content]: updatedValue,
        }

        if (content === 'say' && selectedVoice) {
          translation.voice = selectedVoice
        }

        updatedMessage = {
          ...item[type],
          translations: {
            ...item[type]?.translations,
            [activeLanguage]: translation,
          },
        }
      }
    }

    if (onChange) {
      debouncedSetLastContent(updatedValue)
      onChange({
        ...item,
        [type]: updatedMessage,
      })
    }
  }

  const handleLanguageChange = React.useCallback(
    (language: string) => {
      setActiveLanguage(language)
      if (onLanguageChange) {
        onLanguageChange(language)
      }
    },
    [onLanguageChange],
  )

  return (
    <div>
      <div
        style={{
          marginTop: '5px',
          display: 'flex',
          justifyContent: info ? 'space-between' : 'flex-end',
        }}
      >
        {info && (
          <IconText>
            <Icon>info</Icon>
            <Typography variant="caption" color="textSecondary">
              {info}
            </Typography>
          </IconText>
        )}
        {languages?.length > 0 && (
          <LanguageSelector
            disabled={disabled}
            languages={languages}
            activeLanguage={activeLanguage}
            setActiveLanguage={handleLanguageChange}
            defaultLanguage={defaultLanguage}
            multilingual={multilingual}
            item={item}
            type={type}
            content={content}
          />
        )}
      </div>
      {children(activeContent, onContentChange, error, activeLanguage)}
    </div>
  )
}
