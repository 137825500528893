import { Card } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import { SchemaOrg } from '@/__generated/es-api'
import Navigator, { TNavigatorItem } from '@/app/component/atom/navigator'
import Loading from '@/app/component/guard/loading'
import SidebarLayout from '@/app/component/layout/sidebar-layout'
import createHydrated from '@/app/component/wrapper/hydratable'
import { CommonUserType, InvitePayload, InviteType, UserType } from '@/app/module/user/types'
import { LoadingError, Permissions, UserRole } from '@/app/types'

import AuditLog from './audit-log'
import OrganizationInfo from './organization-info'
import PrivacyData from './privacy-data'
import Subscription from './subscription'
import Users from './users'

type OrganizationProps = {
  activeOrgId: number
  apiToken: string
  organization: SchemaOrg
  classes: Record<string, string>
  error?: LoadingError
  formattedInvitations: CommonUserType[]
  formattedUsers: CommonUserType[]
  invitations: {
    loading: boolean
    error: string
    data: InviteType[]
    init: boolean
  }
  history: History
  loading: boolean
  orgId: number
  permissions: Permissions
  role: UserRole
  tabName: string
  token: string
  userId: number
  users: {
    loading: boolean
    error: string
    data: UserType[]
    init: boolean
  }
  deleteInvitation: (payload: { token: string; orgId: number; inviteId: number }) => Promise<void>
  deleteUser: (payload: { token: string; orgId: number; userId: number }) => Promise<void>
  downloadAuditLog: (params: { orgId: number; token: string }) => Promise<void>
  getInvitations: (payload: { token: string; orgId: number }) => Promise<void>
  getUsers: (payload: { token: string; orgId: number }) => Promise<void>
  hydrate: (params: { token: string; orgId: number }) => void
  inviteUser: (payload: { token: string; orgId: number; item: InvitePayload }) => Promise<void>
}

export const TABS = {
  INFO: 'info',
  SUBSCRIPTION: 'subscription',
  PRIVACY: 'privacy',
  USERS: 'users',
  AUDIT: 'audit',
}

const TABS_MAP = {
  [TABS.INFO]: {
    name: TABS.INFO,
    title: 'Info',
    icon: 'info',
    Content: (props: OrganizationProps) => <OrganizationInfo orgId={props.orgId} />,
  },
  [TABS.SUBSCRIPTION]: {
    name: TABS.SUBSCRIPTION,
    title: 'Subscription Plan',
    icon: 'subscriptions',
    Content: () => <Subscription />,
  },
  [TABS.PRIVACY]: {
    name: TABS.PRIVACY,
    title: 'Privacy & Data',
    icon: 'privacy_tip',
    Content: () => <PrivacyData />,
  },
  [TABS.USERS]: {
    name: TABS.USERS,
    title: 'Users',
    icon: 'group',
    Content: (props: OrganizationProps) => <Users {...props} />,
  },
  [TABS.AUDIT]: {
    name: TABS.AUDIT,
    title: 'Audit Log',
    icon: 'receipt_long',
    Content: (props: OrganizationProps) => <AuditLog {...props} />,
  },
}

const tabNames = Object.values(TABS)

function OrganizationComponent(props: OrganizationProps) {
  const navigate = useNavigate()
  const { loading, error, tabName } = props
  const { Content } = TABS_MAP[tabName] || {}

  const navigatorItems: TNavigatorItem<string>[] = React.useMemo(
    () =>
      tabNames.map((name) => ({
        icon: TABS_MAP[name].icon,
        label: TABS_MAP[name].title,
        path: name,
      })),
    [],
  )

  const handleItemClick = React.useCallback(
    (tab: string) => {
      navigate(`/account/organization/${tab}`)
    },
    [navigate],
  )

  const sidebar = (
    <div>
      <Navigator
        activePath={tabName}
        id="organization"
        items={navigatorItems}
        title="Organization"
        onItemClick={handleItemClick}
      />
    </div>
  )

  const content = (
    <ContentContainer data-testid="organization-content">
      <div id={`org-content-${tabName}`}>
        <Content {...props} />
      </div>
    </ContentContainer>
  )

  return (
    <Loading isLoading={loading} error={error}>
      <SidebarLayout sidebar={sidebar} content={content} />
    </Loading>
  )
}

const ContentContainer = styled(Card)({
  height: '100%',
  overflow: 'auto',
  padding: 16,
})

export default createHydrated(OrganizationComponent)
