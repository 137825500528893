import { History } from 'history'
import { values } from 'ramda'
import { connect } from 'react-redux'

import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Notifications from '@/app/module/notifications'
import { UserOrganization } from '@/app/module/user'
import { TABS } from '@/app/module/user/component/organization'
import { selectOrgId } from '@/app/module/user/store/selectors'
import NotFoundPage from '@/app/page/not-found'

type Props = {
  history: History
  match: {
    params: {
      tabName: string
    }
    url: string
  }
  selectedStateId: number
}

function OrganizationPage({ selectedStateId, match, history }: Props) {
  if (values(TABS).indexOf(match.params.tabName) === -1) {
    return <NotFoundPage />
  }

  return (
    <AuthGuard path={match.url} url={match.url}>
      <Layout disableScrolling title="Organization - engageSPARK">
        <AuthHeader currentPage={PAGE.ACCOUNT.ORGANIZATION.INDEX} />
        <UserOrganization history={history} selectedOrgId={selectedStateId} tabName={match.params.tabName} />
        <Notifications />
      </Layout>
    </AuthGuard>
  )
}

export default connect(({ user }) => ({
  selectedStateId: selectOrgId({ user }),
}))(OrganizationPage)
