import { path, pathOr } from 'ramda'

import {
  defaultPageSize as defaultContactsPageSize,
  getContactsFailure,
  getContactsRequest,
  getContactsSuccess,
} from '@/app/module/contacts/store/actions'
import {
  ACTION_ADD_TO_GROUP_FAILURE,
  ACTION_ADD_TO_GROUP_REQUEST,
  ACTION_ADD_TO_GROUP_SUCCESS,
  ACTION_GET_GROUPS_FAILURE,
  ACTION_GET_GROUPS_ITEM_FAILURE,
  ACTION_GET_GROUPS_ITEM_REQUEST,
  ACTION_GET_GROUPS_ITEM_SUCCESS,
  ACTION_GET_GROUPS_REQUEST,
  ACTION_GET_GROUPS_SUCCESS,
  ACTION_GROUPS_NAVIGATE,
  ACTION_REMOVE_FROM_GROUP_FAILURE,
  ACTION_REMOVE_FROM_GROUP_REQUEST,
  ACTION_REMOVE_FROM_GROUP_SUCCESS,
  ACTION_RESET_GROUPS_QUERY,
  ERROR_GET_GROUPS_ITEM_CONTACTS_SERVER,
  ERROR_GET_GROUPS_SERVER,
} from '@/app/module/groups/definitions'
import { createNotification } from '@/app/module/notifications/store/actions'
import request from '@/app/module/request'
import { createAsyncActions, preventParallel, toQuery } from '@/app/service/util'
import config from '@/config'

const GROUP_TYPE = pathOr({}, ['modules', 'groups', 'type'], config)

const {
  request: getGroupsItemRequest,
  success: getGroupsItemSuccess,
  failure: getGroupsItemFailure,
} = createAsyncActions({
  request: ACTION_GET_GROUPS_ITEM_REQUEST,
  success: ACTION_GET_GROUPS_ITEM_SUCCESS,
  failure: ACTION_GET_GROUPS_ITEM_FAILURE,
})

const {
  request: getGroupsRequest,
  success: getGroupsSuccess,
  failure: getGroupsFailure,
} = createAsyncActions({
  request: ACTION_GET_GROUPS_REQUEST,
  success: ACTION_GET_GROUPS_SUCCESS,
  failure: ACTION_GET_GROUPS_FAILURE,
})

const {
  request: addToGroupRequest,
  success: addToGroupSuccess,
  failure: addToGroupFailure,
} = createAsyncActions({
  request: ACTION_ADD_TO_GROUP_REQUEST,
  success: ACTION_ADD_TO_GROUP_SUCCESS,
  failure: ACTION_ADD_TO_GROUP_FAILURE,
})

const {
  request: removeFromGroupRequest,
  success: removeFromGroupSuccess,
  failure: removeFromGroupFailure,
} = createAsyncActions({
  request: ACTION_REMOVE_FROM_GROUP_REQUEST,
  success: ACTION_REMOVE_FROM_GROUP_SUCCESS,
  failure: ACTION_REMOVE_FROM_GROUP_FAILURE,
})

export {
  addToGroupFailure,
  addToGroupRequest,
  addToGroupSuccess,
  getGroupsFailure,
  getGroupsItemFailure,
  getGroupsItemRequest,
  getGroupsItemSuccess,
  getGroupsRequest,
  getGroupsSuccess,
  removeFromGroupFailure,
  removeFromGroupRequest,
  removeFromGroupSuccess,
}

export const getGroupsItem =
  ({ itemId, token, orgId }) =>
  (dispatch) => {
    dispatch(getGroupsItemRequest(itemId))
    if (!token || !orgId || !itemId) {
      return {}
    }
    return request.groups
      .getItem({ token, orgId, query: { itemId } })
      .then((res) => {
        dispatch(getGroupsItemSuccess(res))
      })
      .catch((err) => {
        dispatch(
          getGroupsItemFailure({
            status: err.status,
            error: err.message,
            message: ERROR_GET_GROUPS_SERVER,
          }),
        )
      })
  }

export const getGroups = preventParallel(
  ({ orgId, query = {} }) => `${orgId}-${toQuery(query)}`,
  ({ token, orgId, query = {} }) =>
    (dispatch) => {
      const search = query.searchTerm || query.search
      const q = {
        ...query,
        search,
        type: GROUP_TYPE.type,
      }
      dispatch(getGroupsRequest(q))

      const { page = 1, size } = q

      return request.groups
        .getList({ token, orgId, query: q })
        .then(({ groups = [], total = 0 } = {}) => {
          dispatch(
            getGroupsSuccess({
              values: groups,
              page,
              search,
              size: size || total,
              total,
            }),
          )
        })
        .catch((err) => {
          dispatch(
            getGroupsFailure({
              status: err.status,
              error: err.message,
              message: ERROR_GET_GROUPS_SERVER,
            }),
          )
          return err
        })
    },
)

export const resetGroupsQuery = () => ({
  type: ACTION_RESET_GROUPS_QUERY,
})

export const addToGroup =
  ({ token, orgId, contactIds, groupIds, isAllContacts, excludeIds, includedGroupIds, search }) =>
  (dispatch) => {
    dispatch(addToGroupRequest())

    const item = {
      groupIds,
      contacts: {
        includedContactIds: contactIds,
        excludedContactIds: excludeIds,
        allContactsAreSelected: isAllContacts,
        includedGroupIds,
        search,
      },
    }
    const notificationKey = contactIds.join('-')

    return request.groups
      .addContacts({ token, orgId, groupIds, item })
      .then((response = {}) => {
        dispatch(
          createNotification({
            [`add-to-group-${notificationKey}`]: {
              type: response.error ? 'warning' : 'success',
              // eslint-disable-next-line no-nested-ternary
              message: response.error
                ? "Contact(s) weren't added to selected group(s). Please try again"
                : response.backgroundTask
                  ? 'Contacts are being added to the selected group(s). This process can take a couple of minutes to complete'
                  : 'Contact(s) successfully added to the selected group(s)',
            },
          }),
        )
        dispatch(addToGroupSuccess())
      })
      .catch((err) => {
        dispatch(addToGroupFailure(err))
        return err
      })
  }

export const getGroupContacts =
  ({ token, orgId, groupId, query }) =>
  (dispatch) => {
    const q = {
      ...query,
      type: GROUP_TYPE.type,
    }
    dispatch(getContactsRequest(q))

    const { page = 1, size = defaultContactsPageSize } = q

    return request.groups
      .getContacts({ token, orgId, groupId, query: q })
      .then(({ contacts = [], total, orgTotal }) =>
        dispatch(
          getContactsSuccess({
            values: contacts,
            page: parseInt(page || q.page, 10),
            size: size || defaultContactsPageSize,
            total,
            orgTotal,
            searchTerm: '',
            filter: q.filter,
          }),
        ),
      )
      .catch((err) => {
        dispatch(
          getContactsFailure({
            status: err.status,
            error: err.message,
            message: ERROR_GET_GROUPS_ITEM_CONTACTS_SERVER,
          }),
        )
      })
  }

export const removeFromGroup = (props) => (dispatch) => {
  dispatch(removeFromGroupRequest())
  const { token, orgId, contactIds, groupId, includedGroupIds } = props
  const item = {
    contactIds,
    includedGroupIds,
  }
  const notificationKey = contactIds.join('-')
  return request.groups
    .removeContacts({ token, orgId, groupId, item })
    .then((response = {}) => {
      dispatch(
        getGroupContacts({
          token,
          orgId,
          groupId,
          query: {
            page: 1,
            type: 'NORMAL',
            filter: {
              name: GROUP_TYPE.name,
              value: groupId,
            },
          },
        }),
      )
      dispatch(
        createNotification({
          [`add-to-group-${notificationKey}`]: {
            type: response.error ? 'warning' : 'success',
            message: response.error
              ? "Contacts weren't removed from selected group. Please try again"
              : 'Contacts successfully removed from the selected group',
          },
        }),
      )
      dispatch(removeFromGroupSuccess())
    })
    .catch((err) => {
      dispatch(
        createNotification({
          [`add-to-group-${notificationKey}`]: {
            type: 'error',
            message: path(['response', 'error'], err) || '',
          },
        }),
      )
      return err
    })
}

export const navigateGroups = ({ page = 1, searchTerm }) => ({
  type: ACTION_GROUPS_NAVIGATE,
  value: { page, searchTerm },
})
