import { DragIndicator, Lock, LockOpen, Visibility, VisibilityOff } from '@mui/icons-material'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { move, reduce, update } from 'ramda'
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Tooltip from '@/app/component/atom/tooltip'
import Drag from '@/app/module/campaigns/component/item/steps/content/question/action/drag'
import Drop from '@/app/module/campaigns/component/item/steps/content/question/action/drop'
import { Column, FunctionType } from '@/config/types'

type Props = {
  headers: Column[]
  setHeaders: FunctionType
}

export default function ConfigureTable({ headers, setHeaders }: Props) {
  const [isConfigureListOpen, setIsConfigureListOpen] = React.useState<null | HTMLElement>(null)

  const handleToggleVisibility = (index: number) => {
    setHeaders(
      update(
        index,
        {
          ...headers[index],
          visible: !headers[index].visible,
        },
        headers,
      ),
    )
  }

  const handleToggleSticky = (index: number) => {
    setHeaders(
      update(
        index,
        {
          ...headers[index],
          sticky: headers[index].sticky === 'left' ? undefined : 'left',
        },
        headers,
      ),
    )
  }

  const handleOnDrop = (oldIndex: number, newIndex: number) => {
    setHeaders(move(oldIndex, newIndex, headers))
  }

  const visibleCount = reduce((accum: number, elem: Column) => accum + (elem.visible ? 1 : 0), 0, headers)

  return (
    <div>
      <Tooltip title="Manage table columns">
        <IconButton
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => setIsConfigureListOpen(event.currentTarget)}
        >
          <Icon>settings</Icon>
        </IconButton>
      </Tooltip>
      <DndProvider backend={HTML5Backend}>
        <Menu
          id="configure-menu"
          anchorEl={isConfigureListOpen}
          open={Boolean(isConfigureListOpen)}
          onClose={() => setIsConfigureListOpen(null)}
        >
          <div>
            {headers.map((header: Column, index: number) => (
              <Drop
                key={index}
                dragContext="column-drag"
                onMove={(fromIndex: number) => handleOnDrop(fromIndex, index)}
              >
                <Drag index={index} dragContext="column-drag">
                  <MenuItem disabled={visibleCount < 3 && header.visible}>
                    <Tooltip title="Drag/ Drap">
                      <DragIndicator
                        style={{
                          fontSize: '1.2rem',
                          marginRight: '10px',
                          marginLeft: '-10px',
                        }}
                      />
                    </Tooltip>
                    <CustomCheckbox
                      tooltip="Hide/ Unhide"
                      disabled={visibleCount < 3 && header.visible}
                      checked={header.visible}
                      icon={VisibilityOff}
                      checkedIcon={Visibility}
                      onToggle={() => handleToggleVisibility(index)}
                    />
                    <CustomCheckbox
                      tooltip="Lock/ Unlock"
                      checked={header.sticky === 'left'}
                      icon={LockOpen}
                      checkedIcon={Lock}
                      onToggle={() => handleToggleSticky(index)}
                    />
                    <Typography>{header.label}</Typography>
                  </MenuItem>
                </Drag>
              </Drop>
            ))}
          </div>
        </Menu>
      </DndProvider>
    </div>
  )
}

type CustomCheckboxProps = {
  checked: boolean
  icon: typeof Lock
  checkedIcon: typeof Lock
  onToggle: FunctionType
  disabled?: boolean
  tooltip?: string
}

const CustomCheckbox = ({ checked, icon, checkedIcon, onToggle, disabled, tooltip = '' }: CustomCheckboxProps) => {
  const ActiveIcon = checked ? checkedIcon : icon
  return (
    <Tooltip title={tooltip}>
      <div
        onClick={disabled ? () => {} : onToggle}
        style={{
          cursor: 'pointer',
          paddingRight: '4px',
        }}
      >
        <ActiveIcon
          style={{
            fontSize: '0.8rem',
            opacity: '0.7',
            color: checked ? '#205978' : 'rgba(0, 0, 0, 0.54)',
          }}
        />
      </div>
    </Tooltip>
  )
}
