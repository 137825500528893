import { styled } from '@mui/material'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import React from 'react'

import { Plan } from '@/app/module/plans/types'
import { getPlanCountries } from '@/app/module/plans/utils'
import { numberWithCommas } from '@/app/service/util/format'

type Props = {
  plan: Plan
}

const Benefits: React.FC<Props> = ({ plan }) => {
  const renderCountry = () => {
    const { value, unit } = getPlanCountries(plan.countriesIncluded)
    return (
      <>
        <FeatureHighlight>{value}</FeatureHighlight>
        {!!unit && ` ${unit}`}
      </>
    )
  }

  return (
    <>
      <FeatureRow>
        <FeatureIconSuccess>star</FeatureIconSuccess>
        <Typography variant="subtitle1">
          <FeatureHighlight>{numberWithCommas(plan.storedContactsMax)}</FeatureHighlight> Total Contacts
        </Typography>
      </FeatureRow>
      <FeatureRow>
        <FeatureIconSuccess>star</FeatureIconSuccess>
        <Typography variant="subtitle1">
          <FeatureHighlight>{plan.usersIncluded}</FeatureHighlight> User Accounts
        </Typography>
      </FeatureRow>
      <FeatureRow>
        <FeatureIconSuccess>star</FeatureIconSuccess>
        <Typography variant="subtitle1">{renderCountry()}</Typography>
      </FeatureRow>
    </>
  )
}

const FeatureRow = styled('div')(() => ({
  display: 'flex',
  margin: '5px 10px',
}))

const FeatureIconSuccess = styled(Icon)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginRight: '10px',
  alignSelf: 'center',
}))

const FeatureHighlight = styled('span')(() => ({
  fontWeight: 'bold',
}))

export default Benefits
