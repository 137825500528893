import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'

import { Option } from '@/app/component/form/types'

type Props<T extends FieldValues> = {
  name: Path<T>
  options?: Option[]
  autoFocus?: boolean
  required?: boolean
}

export function RHFToggleButtonGroup<T extends FieldValues>({ name, options, autoFocus }: Props<T>) {
  const { control } = useFormContext<T>()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ...restField } }) => (
        <ToggleButtonGroup
          value={value.length ? value : [options?.[0].value]}
          sx={{ mb: 2 }}
          onChange={(_, newValue) => {
            if (newValue.length) {
              onChange(newValue)
            }
          }}
          {...restField}
        >
          {options?.map((option, i) => (
            <ToggleButton
              key={option.value}
              aria-label={option.label}
              autoFocus={autoFocus && i === 0}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    ></Controller>
  )
}
