import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectToken } from '@/app/module/auth/store/selectors'
import { createNotification } from '@/app/module/notifications/store/actions'
import { getPlan } from '@/app/module/plans/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { esApi, getErrorMessage } from '@/app/store/api'
import { useAppDispatch } from '@/app/store/redux-hooks'

export const useCancelSubscription = () => {
  const orgId = useSelector(selectOrgId)
  const token = useSelector(selectToken)
  const dispatch = useAppDispatch()
  const [cancel, res] = esApi.endpoints.cancelOrgPlan.useMutation()

  const cancelSubscription = useCallback(async () => {
    try {
      const result = await cancel({
        orgId: orgId.toString(),
      }).unwrap()

      dispatch(
        createNotification({
          'cancel-subscription': {
            type: 'success',
            message: `Successfully canceled subscription plan.`,
          },
        }),
      )
      dispatch(getPlan({ token, orgId }))

      return result
    } catch (error) {
      dispatch(
        createNotification({
          'cancel-subscription': {
            type: 'error',
            message: getErrorMessage(error, 'Failed to cancel subscription plan. Please try again.'),
          },
        }),
      )
      return false
    }
  }, [orgId, token, cancel, dispatch])

  return [cancelSubscription, res] as const
}
