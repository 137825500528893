import { DialogContent, DialogTitle, Icon, IconButton } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

import { SchemaOrg } from '@/__generated/es-api'
import Loader from '@/app/component/atom/loader'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { useGetOrganization } from '@/app/module/user/hooks/use-get-organization'
import { useUpdateOrganization } from '@/app/module/user/hooks/use-update-organization'
import { selectNotificationsHeaders, selectNotificationsSpecs, selectOrgId } from '@/app/module/user/store/selectors'
import validate from '@/app/service/validate'
import { FormSpecs } from '@/app/types'
import { AppState } from '@/app/types/store'

import SpendNotifications from './spend-notifications'

type Props = {
  open: boolean
  onClose: () => void
}

const SpendNotificationsContainer: React.FC<Props> = ({ open, onClose }) => {
  const headers = useSelector(selectNotificationsHeaders)
  const isUserLoading = useSelector(({ user }: AppState) => user.details.loading)
  const orgId = useSelector(selectOrgId)
  const specs = useSelector(selectNotificationsSpecs)

  const { data: organization, isLoading: isOrgLoading } = useGetOrganization(orgId)
  const [updateOrg, res] = useUpdateOrganization()

  const handleSubmit = React.useCallback(
    async (payload: { item: SchemaOrg }) => {
      updateOrg(orgId, payload.item)
    },
    [orgId, updateOrg],
  )

  const loading = isOrgLoading || isUserLoading || res.isLoading

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle id="limits-title">
        <span>Notifications and Spend Limits</span>
        <IconButton style={{ float: 'right' }} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {organization && (
          <SpendNotifications
            headers={headers}
            loading={loading}
            organization={organization}
            specs={specs as FormSpecs}
            validation={validation}
            onSubmit={handleSubmit}
          />
        )}
        {loading && <Loader />}
      </DialogContent>
    </CustomDialog>
  )
}

export const hasNumberError = (value: string) => {
  if (!value) {
    return ''
  }
  const number = Number(value)
  if (Number.isNaN(number)) {
    return 'Must be a number'
  }
  if (number < 0) {
    return 'Must be a non-negative number'
  }
  return ''
}

const validation = {
  balanceNotification: validate(hasNumberError),
  maxDailySpend: validate(hasNumberError),
  spendNotification: validate(hasNumberError),
}

export default SpendNotificationsContainer
