import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'

import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import { OrgPlan } from '@/app/module/plans/types'
import { useCancelSubscription } from '@/app/module/user/hooks/use-cancel-subscription'
import { useUpdateSubscription } from '@/app/module/user/hooks/use-update-subscription'
import { openIntercom } from '@/app/service/intercom/utils'

import CancelSubscriptionConfirmation from './cancel-subscription-confirmation'

type Props = {
  activePlan: OrgPlan
}

/**
 *  Assuming the user has edaquate permissions, this component allows the user to change their subscription plan.
 *  There should be an active plan.
 */
const ChangeSubscription: React.FC<Props> = ({ activePlan }) => {
  const { canceled } = activePlan
  const [cancelSubscription, { isLoading: isCanceling }] = useCancelSubscription()
  const [updateSubscription, { isLoading: isReactivating }] = useUpdateSubscription()
  const [isReactivateDialogOpen, setIsReactivateDialogOpen] = useState(false)
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)

  const openCancelDialog = () => {
    setIsCancelDialogOpen(true)
  }

  const closeCancelDialog = () => {
    setIsCancelDialogOpen(false)
  }

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription()
    } finally {
      closeCancelDialog()
    }
  }

  const openReactivateDialog = () => {
    setIsReactivateDialogOpen(true)
  }

  const closeReactivateDialog = () => {
    setIsReactivateDialogOpen(false)
  }

  const handleReactivateSubscription = async () => {
    try {
      // Update organization with reactivated subscription
      await updateSubscription({
        billing: activePlan.billing,
        planId: activePlan.planId,
        undoCancel: true,
        undoDowngrade: false,
      })
    } finally {
      closeReactivateDialog()
    }
  }

  if (canceled) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="body2">
          To change your plan, please first cancel the expiration of the current plan.
        </Typography>

        <Typography variant="body2">
          Undo the cancelation and automatically renew my subscription at the end of the current billing period:
        </Typography>

        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={isReactivating}
            loading={isReactivating}
            onClick={openReactivateDialog}
          >
            Reactivate my subscription
          </Button>
        </div>

        <ConfirmationDialog
          id="reactivate-subscription-dialog"
          isOpen={isReactivateDialogOpen}
          onClose={closeReactivateDialog}
          onConfirm={handleReactivateSubscription}
          title="Reactivate Subscription"
          text={
            <Typography>
              Are you sure you want to reactivate your subscription? Your subscription will automatically renew at the
              end of the current billing period.
            </Typography>
          }
          icon="autorenew"
          deleteButtonText="Reactivate"
          disabled={isReactivating}
          loading={isReactivating}
        />
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="body2">To change your plan, please</Typography>
        <Button color="primary" size="small" variant="contained" onClick={() => openIntercom('show')}>
          Contact support
        </Button>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant="body2">Cancel your subscription to the end of the current billing period:</Typography>

        <Button color="error" variant="outlined" sx={{ mt: 1 }} onClick={openCancelDialog} loading={isCanceling}>
          Cancel subscription
        </Button>
      </Box>

      <CancelSubscriptionConfirmation
        activePlan={activePlan}
        open={isCancelDialogOpen}
        onClose={closeCancelDialog}
        onCancel={handleCancelSubscription}
        isLoading={isCanceling}
      />
    </Box>
  )
}

export default ChangeSubscription
