import Button from '@mui/material/Button'
import { compose } from 'ramda'
import { Component, Fragment } from 'react'

import Loading from '@/app/component/guard/loading'
import FormLayout from '@/app/component/layout/form'
import createHydrated from '@/app/component/wrapper/hydratable'
import validate, { hasPhoneError, hasRequiredError } from '@/app/service/validate'

class Group extends Component {
  submitHandler({ item }) {
    const { token, orgId, page, itemId, submit, getContacts, onExit } = this.props

    submit({
      token,
      orgId,
      item: {
        id: itemId,
        ...item,
      },
      // eslint-disable-next-line consistent-return
    }).then((res) => {
      if (!(res instanceof Error)) {
        onExit()
        return getContacts({
          token,
          orgId,
          query: {
            page,
          },
        })
      }
    })
  }

  render() {
    const { loading, error, headers, specs, data } = this.props

    return (
      <Loading isLoading={loading} error={error}>
        <FormLayout
          loading={loading}
          headers={headers}
          config={specs}
          values={data}
          validation={{
            fullPhoneNumber: validate(hasRequiredError, hasPhoneError),
          }}
          onSubmit={({ item }) => this.submitHandler({ item })}
          Actions={({ changed, hasErrors }) => (
            <Fragment>
              <Button color="primary" variant="contained" type="submit" disabled={loading || !changed || hasErrors}>
                Save
              </Button>
            </Fragment>
          )}
        />
      </Loading>
    )
  }
}

export default compose(createHydrated)(Group)
