import { values } from 'ramda'

import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Notifications from '@/app/module/notifications'
import { UserProfile } from '@/app/module/user'
import { TABS } from '@/app/module/user/component/profile'
import NotFoundPage from '@/app/page/not-found'

export default function ProfilePage({ name, routes, match, history }) {
  if (values(TABS).indexOf(match.params.tabName) === -1) {
    return <NotFoundPage />
  }

  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <Layout title="Profile - engageSPARK">
        <AuthHeader currentPage={PAGE.ACCOUNT.PROFILE.INDEX} />
        <UserProfile history={history} tabName={match.params.tabName} />
        <Notifications />
      </Layout>
    </AuthGuard>
  )
}
