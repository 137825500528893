import { pathOr, reduce } from 'ramda'

import { cannot } from '@/app/helpers'
import {
  LocalAction,
  LocalActionType,
  LocalNonEmptyAction,
  TWhatsAppButtonItem,
  TWhatsAppButtons,
  TWhatsAppList,
  TWhatsAppListItem,
} from '@/app/module/campaigns/types'
import { Action } from '@/app/module/campaigns/types/schemas/actions/all-actions'

export const isNonEmptyAction = (action: LocalAction): action is LocalNonEmptyAction => !!action.type

type SenderIds = {
  senderId: string
  senderIdQuestion: string
  senderIdWhatsapp: string
}

export const processActionsInWhatsappButtons = (
  onButtons: TWhatsAppButtons | undefined,
  senderIds: SenderIds,
  processFn: (item: TWhatsAppButtonItem, sIds: SenderIds) => TWhatsAppButtonItem,
): TWhatsAppButtons | undefined => {
  if (!onButtons) {
    return onButtons
  }

  const buttons = onButtons.buttons || [] // should not be undefined, but just in case

  return {
    ...onButtons,
    buttons: buttons.map((item) => processFn(item, senderIds)),
  }
}

export const processActionsInWhatsappList = (
  list: TWhatsAppList | undefined,
  senderIds: SenderIds,
  processFn: (item: TWhatsAppListItem, sIds: SenderIds) => TWhatsAppListItem,
): TWhatsAppList | undefined => {
  if (!list) {
    return list
  }

  const items = list.items || [] // should not be undefined, but just in case

  return {
    ...list,
    items: items.map((item) => processFn(item, senderIds)),
  }
}

type ActionsList = {
  actions?: Action[]
}[]

export const countActionsInCallResult = (callResultActions: ActionsList) =>
  reduce((accum, actions) => pathOr([], ['actions'], actions).length + accum, 0, callResultActions)

export const hasPreview = (action: LocalAction) => {
  if (!action.type) {
    // for empty placeholder actions
    return false
  }

  switch (action.type) {
    case LocalActionType.API:
    case LocalActionType.Jump:
    case LocalActionType.PlayAudio:
    case LocalActionType.ResumeSubscription:
    case LocalActionType.SendEmail:
    case LocalActionType.SendSMS:
    case LocalActionType.SendSMSToPhoneNumbers:
    case LocalActionType.SendWhatsApp:
    case LocalActionType.SubscribeToCampaign:
    case LocalActionType.Topup:
    case LocalActionType.Transfer:
    case LocalActionType.UpdateContact:
      return true

    case LocalActionType.FinishFlow:
    case LocalActionType.Hangup:
    case LocalActionType.PauseSubscription:
    case LocalActionType.Replay:
    case LocalActionType.Resend:
    case LocalActionType.Unsubscribe:
    case LocalActionType.UnsubscribeAll:
      return false
    default:
      return cannot(action)
  }
}
