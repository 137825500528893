import fetch from 'isomorphic-unfetch'
import { keys, reduce, values } from 'ramda'

import { errorHandler, memoizeAsync, responseHandler } from '@/app/service/util'

export default (reqConfig) => ({
  ...reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        ...reduce(
          (specAcc, specKey) => {
            const {
              method,
              headers = () => ({}),
              cacheFor,
              getPath,
              json = true,
              jsonResponse = true,
              retries = 1,
              retriesStatusCodes = [502],
            } = reqConfig[key][specKey]
            const requestFn = async ({ token, item = {}, file, ...rest } = {}, retryCount = retries) => {
              let localRetryCount = retryCount

              const path = getPath(rest)
              const options = {
                method,
                ...headers(token),
              }
              if (method !== 'GET') {
                options.body = file || (json ? JSON.stringify(item) : item)
              }

              try {
                const res = await fetch(path, options)
                if (!res.ok && retriesStatusCodes.includes(res.status) && localRetryCount) {
                  localRetryCount -= 1
                  return requestFn({ token, item, file, ...rest }, localRetryCount)
                }
                return responseHandler(json, jsonResponse)(res)
              } catch (error) {
                return errorHandler(error)
              }
            }

            return {
              ...specAcc,
              [specKey]:
                method === 'GET'
                  ? memoizeAsync(({ ...rest } = {}) => `${values(rest).join('-')}`, requestFn, cacheFor)
                  : requestFn,
            }
          },
          {},
          keys(reqConfig[key]),
        ),
      },
    }),
    {},
    keys(reqConfig),
  ),
})
