import React from 'react'

import { ChoiceValueMultiple } from '@/app/module/campaigns/types'

import ChoiceContainer from './question/choice/choice-container'
import InputChoice from './question/choice/input-choice'

type Props = {
  choiceLabel?: string
  'data-testid'?: string
  disabled?: boolean
  value: ChoiceValueMultiple

  onChange: (value: ChoiceValueMultiple) => void
}

const ChoiceTagMultiple: React.FC<Props> = ({ choiceLabel, 'data-testid': dataTestId, disabled, value, onChange }) => (
  <ChoiceContainer label={choiceLabel}>
    <InputChoice disabled={disabled} inputProps={{ 'data-testid': dataTestId }} tagList={value} onChange={onChange} />
  </ChoiceContainer>
)

export default React.memo(ChoiceTagMultiple)
