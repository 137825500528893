import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react'

import { CampaignItem } from '@/app/module/campaigns/types'

type ItemValue = {
  item?: CampaignItem
  setItem?: (item: CampaignItem) => void
}

const defaultValue = {}

const ItemContext = createContext<ItemValue>(defaultValue)

type Props = PropsWithChildren<{
  item: CampaignItem
  setItem: (item: CampaignItem) => void
}>

const ItemProvider: React.FC<Props> = ({ children, item, setItem }) => {
  const value = useMemo(() => ({ item, setItem }), [item, setItem])

  return <ItemContext.Provider value={value}>{children}</ItemContext.Provider>
}

export const useItemContext = () => useContext(ItemContext)

export default ItemProvider
