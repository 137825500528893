/* eslint-disable camelcase */
import { IVRCampaignItem } from '@/app/module/campaigns/types'
import { Action } from '@/app/module/campaigns/types/schemas/actions/all-actions'
import { Condition, FileType } from '@/app/types'

export type InboundHook = {
  id: number
  conditions: {
    conditions?: Condition | null
    unexpected: boolean
  }
  actions: Action[]
  label: string
}

export type PhoneNumber = {
  callHooks: InboundHook[] | null
  countryCode: string
  created: string
  files: Record<string, FileType>
  hooks: InboundHook[] | null
  inboundCallCamp: IVRCampaignItem | null
  inboundCallCampId: number
  name: string
  phoneNumber: string
  smsPrice: number
  smsPriceFloat: number
  voicePrice: number
  voicePriceFloat: number
  voiceUnitSeconds: number
  whatsappHooks: InboundHook[] | null

  // supported channels
  sms: boolean
  voice: boolean
  whatsApp: boolean

  // WA specific
  whatsAppMetaOtp?: boolean
  whatsAppRegistered: boolean
}

export enum ChannelType {
  Campaign = 'campaign',
  Sms = 'sms',
  Voice = 'voice',
  WhatsApp = 'whatsapp',
}

export type FacebookSignupEventData = {
  type: string
} & (
  | {
      event: 'FINISH' | 'FINISH_ONLY_WABA'
      data: { waba_id: string }
    }
  | {
      event: 'CANCEL'
      data: { current_step: string }
    }
  | {
      event: 'ERROR'
      data: { error_message: string }
    }
)
