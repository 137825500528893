import { Alert, Skeleton, Typography } from '@mui/material'
import React from 'react'

import CustomTooltip from '@/app/component/atom/tooltip'
import { useGetAutoDeletePreview } from '@/app/module/user/hooks/use-get-auto-delete-preview'
import { numberWithCommas } from '@/app/service/util/format'

type Props = {
  days: number
  isEnabled: boolean
}

const AutoDeletionPreview: React.FC<Props> = ({ days, isEnabled }) => {
  const { data, isFetching, error } = useGetAutoDeletePreview(days, !isEnabled)

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        Failed to load auto-deletion statistics
      </Alert>
    )
  }

  return (
    <Alert severity={isEnabled ? 'warning' : 'info'} sx={{ mt: 2 }}>
      <Typography variant="subtitle2" fontWeight="bold">
        Number of contacts that will be deleted today once auto-deletion is enabled:
      </Typography>
      <Typography component="div" variant="body2">
        {isEnabled ? (
          <div>
            {isFetching ? (
              <CustomTooltip
                title="We're calculating the number of contacts that will be deleted. You can enable auto-deletion anyway."
                interactive
              >
                <Skeleton
                  data-testid="auto-deletion-preview-skeleton"
                  height={20}
                  width={60}
                  sx={{
                    cursor: 'help',
                  }}
                />
              </CustomTooltip>
            ) : (
              numberWithCommas(data?.due ?? 0)
            )}
          </div>
        ) : (
          <>No contacts will be deleted automatically.</>
        )}
      </Typography>
    </Alert>
  )
}

export default AutoDeletionPreview
