import {
  Card,
  CardActionArea,
  CardProps,
  Divider,
  Grid,
  Icon,
  lighten,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import React from 'react'

import { CampaignTemplate } from '@/app/module/campaigns/types'

export type CampaignConfig = {
  name: string
  icon: string | ((props: { width?: string; height?: string }) => React.ReactElement)
  template: CampaignTemplate
  description: string
  disabled?: boolean
  example: string
  category: string
}

type Props = {
  campaignTypes: CampaignConfig[]

  onSelect: (template: CampaignTemplate) => void
}

const CampaignCategory = ({
  title,
  list,
  onSelect,
}: {
  title: string
  list: CampaignConfig[]
  onSelect: Props['onSelect']
}) => {
  return (
    <Content>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{
          xs: 2,
          sm: 2,
          md: 4,
        }}
      >
        {list.map((campaignType) => {
          const { description, disabled, example, icon: TypeIcon, name, template } = campaignType
          return (
            <Grid key={template} item xs={12} md={4} lg={3}>
              <Tooltip title={!disabled ? '' : 'Coming soon'}>
                <Item alternative={true} variant="outlined" sx={{ height: '100%' }}>
                  <ItemActionArea disabled={disabled} sx={{ height: '100%' }} onClick={() => onSelect(template)}>
                    <Typography
                      color="primary"
                      sx={{
                        marginBottom: (theme) => theme.spacing(),
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                      variant="h5"
                    >
                      {typeof TypeIcon === 'string' ? <Icon color="action">{TypeIcon}</Icon> : <TypeIcon />} {name}
                    </Typography>
                    <Typography sx={{ mb: 1, color: 'text.secondary' }}>{description}</Typography>
                    <Typography>
                      <b>Example uses: </b> {example}
                    </Typography>
                  </ItemActionArea>
                </Item>
              </Tooltip>
            </Grid>
          )
        })}
      </Grid>
    </Content>
  )
}

const CreateCampaigns: React.FC<Props> = ({ campaignTypes, onSelect }) => {
  const blastCampaigns = campaignTypes.filter((campaign) => campaign.category === 'blast')
  const surveyCampaigns = campaignTypes.filter((campaign) => campaign.category === 'survey')
  const timeCampaigns = campaignTypes.filter((campaign) => campaign.category === 'time')

  return (
    <Container>
      <Header>
        <Typography color="primary" variant="h5">
          How do you want to engage your audience? Select a campaign type below.
        </Typography>
        <Typography variant="subtitle1">
          <b>Note:</b> campaigns can integrate with each other to increase their power. For example, automatically send
          an IVR Survey to participants 1 day after they finish an educational Drip campaign—to test their comprehension
          and get feedback.
        </Typography>
      </Header>
      <Divider variant="middle" />
      <CampaignCategory title="Blast Campaigns" list={blastCampaigns} onSelect={onSelect} />
      <CampaignCategory title="Survey Campaigns" list={surveyCampaigns} onSelect={onSelect} />
      <CampaignCategory title="Time-Based Campaigns" list={timeCampaigns} onSelect={onSelect} />
    </Container>
  )
}

const Container = styled('div')(() => ({}))

const Header = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}))

const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}))

const Item = styled(Card, { shouldForwardProp: (prop) => prop !== 'alternative' })<
  CardProps & { alternative: boolean }
>(({ theme, alternative }) => ({
  backgroundColor: alternative ? lighten(theme.palette.primary.main, 0.95) : theme.palette.highlight.light,
  ...(!alternative && {
    border: `1px solid ${lighten(theme.palette.primary.main, 0.7)}`,
  }),
}))

const ItemActionArea = styled(CardActionArea)(({ disabled, theme }) => ({
  opacity: disabled ? 0.5 : 1,
  padding: theme.spacing(2, 2),
}))

export default CreateCampaigns
