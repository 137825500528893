import { fireEvent, screen, within } from '@testing-library/dom'
import React from 'react'
import { CallBackProps } from 'react-joyride'

import { byRoleAction, byRoleWaitAndAction, byTestIdAction, byTextAction, safeWaitFor } from '@/app/module/utils/screen'

import { skippedSteps } from './definitions'
import { enrichSteps } from './helpers'
import { useTourContext } from './tour.context'
import { TourStep } from './types'

type Props = {}

const DripTour: React.FC<Props> = () => {
  const { setTour } = useTourContext()

  const callback = React.useCallback(
    (data: CallBackProps) => {
      const goNext = async () => {
        if (data.lifecycle !== 'complete') {
          return
        }

        const currentStep = dripSteps[data.index]
        await currentStep.onUnmounting?.()
        const nextStepIndex = data.index + 1
        if (nextStepIndex >= dripSteps.length) {
          setTour(undefined)
          return
        }
        setTour((s) => {
          if (!s) {
            return s
          }
          return { ...s, stepIndex: nextStepIndex }
        })
      }

      switch (data.action) {
        case 'next':
          goNext()
          break

        case 'update':
          if (data.lifecycle === 'tooltip') {
            const nextStep = dripSteps[data.index]
            nextStep?.onMounted?.()
          }
          break

        case 'close':
        case 'skip':
        case 'stop': {
          setTour(undefined)
          setTimeout(() => {
            setTour(() => ({
              disableScrolling: true,
              disableScrollParentFix: true,
              hideBackButton: true,
              stepIndex: 0,
              steps: enrichSteps(skippedSteps),
              callback: (d) => {
                switch (d.action) {
                  case 'close':
                  case 'skip':
                  case 'stop':
                    setTour(undefined)
                    break

                  default:
                    break
                }
              },
            }))
          }, 0)
          break
        }

        default:
          break
      }
    },
    [setTour],
  )

  React.useEffect(() => {
    setTour(() => ({
      continuous: true,
      disableScrolling: true,
      disableScrollParentFix: true,
      hideBackButton: true,
      locale: {
        last: 'Finish',
      },
      stepIndex: 0,
      steps: enrichSteps(dripSteps),
      callback,
    }))
  }, [callback, setTour])

  return null
}

export const preDripSteps: TourStep[] = [
  {
    content: 'Would you like a quick overview of the Drip Campaign?',
    hideCloseButton: false,
    showSkipButton: true,
    locale: {
      skip: 'No, thanks',
      next: 'Yes, start the tour',
    },
    target: '#root',
  },
  {
    content: '', // empty content to show the skip button
    target: '#root',
  },
]

const dripSteps: TourStep[] = [
  {
    content: (
      <>
        The Drip allows you to send messages in <b>multiple languages, depending on the recipient</b>. If you need only
        one language, you can skip this and go straight to the content tab.
      </>
    ),
    target: '#delete-dialog-cancel-btn',
    onUnmounting: async () => {
      byTextAction('click', 'No, skip languages')

      await safeWaitFor(() => !!document.querySelector('.message-block'))
    },
  },
  {
    content: "On the Content tab, you can select the type of message you'd like to send.",
    target: '#no-message',
    onUnmounting: async () => {
      byRoleAction('click', 'button', { name: 'SMS Message' })
    },
  },
  {
    content: "For the first time, you're starting with a simple SMS Message. Type the SMS text here.",
    target: '.tour-sms-input',
    onUnmounting: async () => {
      byRoleAction('change', 'textbox', undefined, { target: { value: 'Hello, ' } })
    },
  },
  {
    content: "Don't forget to personalize your message.",
    target: 'button[data-testid="messages-send-pz-menu-toggle"]',
    onUnmounting: async () => {
      byTestIdAction('click', 'messages-send-pz-menu-toggle')
      await safeWaitFor(() => screen.findByTestId('messages-send-pz-menu'))
      byRoleAction('click', 'menuitem', { name: 'Contact first name' })
    },
  },
  {
    content: 'How long after subscription should this SMS be sent? Set the delay for your message here.',
    target: '.message-block .campaign-item-content-delay',
  },
  {
    content: "Let's add another message by clicking on this button.",
    target: '.campaign-item-content-add-button:last-of-type',
    onUnmounting: async () => {
      await byRoleWaitAndAction('click', 'button', { name: 'Add message' })
      await safeWaitFor(() => screen.findByRole('menuitem', { name: 'SMS Question' }))
    },
  },
  {
    content: 'Here you can choose the type of message to add.',
    target: '#message-add-menu-0 .MuiPaper-root',
    onUnmounting: async () => {
      byRoleAction('click', 'menuitem', { name: 'SMS Question' })
    },
  },
  {
    content: (
      <>
        To the left, you can set the <b>report label</b>, for example "Age Question".
      </>
    ),
    target: '.message-block #message-label-1 .campaignTitle',
    onUnmounting: async () => {
      byTestIdAction('click', 'message-label-1-toggle')

      await safeWaitFor(() => screen.findByTestId('message-label-1'))

      byTestIdAction('change', 'message-label-1', undefined, { target: { value: 'Age Question' } })
      byTestIdAction('blur', 'message-label-1')
    },
  },
  {
    content:
      "If you don't want to ask this question every time, conditions allow you to skip it. Conditions are very powerful—that's why we made a dedicated tour for them.",
    target: 'button[data-testid="open-conditions-1"]',
  },
  {
    content:
      'This is the Campaign Navigator. It allows you to quickly move within a Drip. You can also use it to re-order messages.',
    target: '#campaign-navigator',
  },
  {
    content:
      "Now that your messages are all set, it's time to choose who receives them! Navigate to the Contacts tab by clicking here.",
    target: '.campaign-sidebar-option.option-2',
    onUnmounting: async () => {
      byTestIdAction('click', 'campaign-sidebar-option-subscriptions')

      await safeWaitFor(() => screen.findByTestId('campaign-subscriptions'))
    },
  },
  {
    content:
      "Select the recipients for your drip here—either individually or entire groups. Don't worry if you don't have any contacts yet; you can still launch the campaign and add contacts to it later.",
    target: '[data-testid="campaign-subscriptions"]',
  },
  {
    content: 'Proceed to the next step by clicking Next.',
    target: '#campaign-item-next',
    onUnmounting: async () => {
      byTestIdAction('click', 'campaign-item-next')

      await safeWaitFor(() => screen.getByTestId('senderId-select-0-0'))
    },
  },
  {
    content:
      'Before you can launch a campaign you need to select a sender ID for your messages and calls. WhatsApp messages and sometimes also SMS need a registered sender ID. For SMS questions in particular you need a phone number that your contact can reply to. Please contact support to get sender IDs and virtual phone numbers.',
    target: '#campaign-item-senderid',
    onUnmounting: async () => {
      fireEvent.mouseDown(within(screen.getByTestId('senderId-select-0-0')).getByRole('combobox'))

      await safeWaitFor(() => screen.findByRole('option', { name: 'System Default' }))

      byRoleAction('click', 'option', { name: 'System Default' })
    },
  },
  {
    content: 'Proceed to the next step by clicking here.',
    target: '#campaign-item-next',
    onUnmounting: async () => {
      byTestIdAction('click', 'campaign-item-next')

      await safeWaitFor(() => screen.getByTestId('campaign-validation-popup'))
    },
  },
  {
    content:
      'On the final step you can review the errors in your campaign and fix them before launching. Note that you can save a draft campaign at any time, even if it contains errors.',
    target: '.campaign-validation-popup-cont',
  },
  {
    content: 'Schedule your campaign to start later by clicking here.',
    target: '#schedule-campaign-radio .MuiFormControlLabel-root:nth-child(2)',
    onUnmounting: async () => {
      byTextAction('click', 'on a specific date and time')

      await safeWaitFor(() => screen.getByText('Select a specific date and time'))
    },
  },
  {
    content: 'Finally, once all errors are resolved, you can launch your campaign here.',
    target: '#campaign-item-confirm',
  },
  {
    content:
      'Congratulations! You have completed the Drip Campaign tour. You can always start the tour again from the help menu here in the top right corner.',
    target: '#header-help-trigger',
  },
]

export default DripTour
