import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { append, map, pathOr, prepend, reduce, remove, replace } from 'ramda'
import React from 'react'
import { v1 as uuid } from 'uuid'

import { PersonalizationType } from '@/app/module/campaigns/types'
import { Condition } from '@/app/types'
import { FunctionType } from '@/config/types'

import ConditionGroup from './condition-group'
import { Groups, operands } from './definitions'

type Props = {
  data: Condition
  groups: Groups
  item?: any
  personalizationList: PersonalizationType
  segments: Groups
  title: string
  onClose: () => void
  onSaveConditions: FunctionType
}

export default function Conditions(props: Props) {
  const { personalizationList, groups, segments, onSaveConditions, onClose, item, title } = props

  const [data, setData] = React.useState(remove(0, 1, props.data))
  const [idArray, setIdArray] = React.useState<any[]>([])
  const [disabled, setDisabled] = React.useState(false)
  React.useEffect(() => {
    setData(remove(0, 1, props.data))
    setIdArray(map(() => uuid(), remove(0, 1, props.data)))
  }, [props.data])

  React.useEffect(() => {
    setDisabled(
      reduce(
        (accum: boolean, conditionRow: any) =>
          accum ||
          reduce(
            (conditionAccum: boolean, condition: any) =>
              conditionAccum || !pathOr('', [0], condition) || !replace(/{|}/g, '', pathOr('', [1], condition)),
            accum,
            remove(0, 1, conditionRow),
          ),
        false,
        data,
      ),
    )
  }, [data])

  return (
    <Dialog open={true} maxWidth="md" fullWidth={true}>
      <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">Conditions</Typography>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div>
          {data.map((conditionRow, rowIndex) => (
            <div
              key={rowIndex}
              style={{
                marginBottom: 18,
              }}
            >
              <div
                data-testid={`condition-group-${rowIndex}-title`}
                style={{
                  display: 'flex',
                  marginBottom: '10px',
                }}
              >
                {rowIndex === 0 ? (
                  <Typography variant="subtitle2">{`${title} only if the following conditions apply:`}</Typography>
                ) : (
                  <Typography variant="subtitle2">
                    <strong style={{ color: '#2f80ac' }}>OR</strong> if the following conditions apply:
                  </Typography>
                )}
              </div>
              <ConditionGroup
                conditionRow={conditionRow as Condition[]}
                personalizationList={personalizationList}
                groups={groups}
                segments={segments}
                key={`row${idArray[rowIndex]}`}
                rowIndex={rowIndex}
                isFirst={rowIndex === 0}
                isLast={rowIndex === data.length - 1}
                groupedOperators={true}
                operands={operands}
                onAddConditionGroup={() => {
                  setData(append(['and', ['', '', '']], data))
                  setIdArray(append(uuid(), idArray))
                }}
                onChangeCondition={({ updatedConditionGroup }: any) => {
                  const conditions = [...data]
                  if (updatedConditionGroup.length === 1) {
                    setData(remove(rowIndex, 1, conditions))
                    setIdArray(remove(rowIndex, 1, conditions))
                  } else {
                    conditions[rowIndex] = updatedConditionGroup
                    setData(conditions)
                  }
                }}
              />
              <Divider />
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            width: '100%',
          }}
        >
          <Button
            style={{
              color: '#ff5252',
              marginLeft: '-10px',
            }}
            onClick={() => {
              onSaveConditions({
                ...item,
                when: null,
              })
            }}
            size="small"
          >
            Remove all conditions
          </Button>
        </div>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={() => {
            const when = prepend('or', data)
            onSaveConditions({
              ...item,
              when,
            })
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
