import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import { omit } from 'ramda'

import Tooltip from '@/app/component/atom/tooltip'
import { LIBRARY_MESSAGE_TYPES } from '@/app/module/campaigns/definitions'
import { Message, ReminderMessage, SnippetType } from '@/app/module/campaigns/types'

const getTooltipText = (inLibrary: boolean, isIncluded: boolean) => {
  if (inLibrary) {
    return 'Message already added to the library.'
  }

  return isIncluded ? 'Save message to library for later use.' : `This message type can't be saved.`
}

const omitList = ['id', 'changed', 'delta', 'offset']

type LibrarySaveButtonProps = {
  index: number
  isLoading: boolean
  snippets: SnippetType[]
  message: Message | ReminderMessage
  onClick: () => void
}

export function LibrarySaveButton({ index, snippets, message, isLoading, onClick }: LibrarySaveButtonProps) {
  const isIncluded = LIBRARY_MESSAGE_TYPES.includes(message.type)
  const jsonMessage = JSON.stringify(omit(omitList, message))
  const inLibrary = !!snippets.find(
    (s) => s.category === 'message' && jsonMessage === JSON.stringify(omit(omitList, s.snippet)),
  )

  return (
    <Tooltip title={getTooltipText(inLibrary, isIncluded)}>
      <span>
        <IconButton
          id={`save-part-to-library-${index}`}
          disabled={isLoading || inLibrary || !isIncluded}
          onClick={onClick}
        >
          <Icon>{inLibrary ? 'library_add_check' : 'library_add'}</Icon>
        </IconButton>
      </span>
    </Tooltip>
  )
}
