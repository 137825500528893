import { styled } from '@mui/material'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { filter, find, findIndex, includes, indexOf, map, path, pathOr, propEq, reduce, remove } from 'ramda'
import React from 'react'

import DateTimeField from '@/app/component/atom/form/date-time'
import SearchableSelect from '@/app/component/atom/form/searchable-select'
import TagInput from '@/app/component/atom/form/tagInput'
import PzInput from '@/app/component/atom/pz-input'
import { getValueForTagInputInConditions } from '@/app/module/campaigns/helpers'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { addCurlyBrackets, removeCurlyBrackets } from '@/app/module/utils/personalization'
import { receiveDateTimeFormat } from '@/app/service/util/format'
import { Condition, SelectOption } from '@/app/types'

import ConditionField from './condition-field'
import { Groups, isOperandsList, Operands, OperandsList } from './definitions'

type Props = {
  condition: Condition
  error?: boolean
  groupedOperators?: boolean
  groups: Groups
  index: number
  isFirst: boolean
  operands: OperandsList
  personalizationList: PersonalizationType
  segments: Groups
  onConditionChange: (updatedConditionGroup: Condition) => void
  onDelete: () => void
}

const SingleCondition: React.FC<Props> = ({
  condition,
  error = false,
  groupedOperators = false,
  groups,
  index,
  segments,
  isFirst,
  operands = [],
  personalizationList,
  onDelete,
  onConditionChange,
}) => {
  const { contact: initialContact = [], customfields = [], message: messages = [] } = personalizationList
  const groupsList = React.useMemo(() => convertGroups(groups), [groups])
  const segmentsList = React.useMemo(() => convertGroups(segments), [segments])
  const [filterGroupOperands, setFilterGroupOperands] = React.useState(
    checkIfGroup({
      groups: groupsList,
      segments: segmentsList,
      value: getFieldValue(condition),
    }),
  )
  const [field, setField] = React.useState(getFieldValue(condition))
  const [operand, setOperand] = React.useState(pathOr('', [0], condition))
  const [textValue, setTextValue] = React.useState<string | string[]>(pathOr('', [2], condition))
  const fieldError = !getFieldValue(condition)
  const operandError = !pathOr('', [0], condition)

  const renderInput = (operandType: string) => {
    switch (operandType) {
      case 'TextField':
        return (
          <PzInput
            data-testid="conditions-value"
            label={textValue ? ' ' : 'Enter value'}
            name="conditions-value"
            personalizationList={conditionPzList}
            value={textValue as string}
            onChange={(value: string) => {
              setTextValue(value)
              const updatedCondition: Condition = [...condition]
              updatedCondition[2] = value
              onConditionChange(updatedCondition)
            }}
          />
        )
      case 'DateTime':
        return (
          <DateTimeField
            data-testid="conditions-value"
            disablePast={false}
            editable={true}
            label={textValue ? ' ' : 'Enter value'}
            name="conditions-value-datetime"
            value={Array.isArray(textValue) ? textValue[0] : textValue}
            onChange={({ value }: { value: moment.Moment | null }) => {
              const updatedValue = value ? value.format(receiveDateTimeFormat) : ''
              setTextValue(updatedValue)
              const [term1, term2, ...rest] = condition
              onConditionChange([term1, term2, updatedValue, ...rest.slice(1)])
            }}
          />
        )
      case 'List':
        return (
          <TagInput
            data-testid="conditions-value"
            label={textValue ? ' ' : 'Enter value'}
            value={getValueForTagInputInConditions(textValue, condition)}
            onChange={({ value }: { value: string[] }) => {
              setTextValue(value)
              const updatedCondition = [condition[0], condition[1], ...value]
              onConditionChange(updatedCondition as Condition)
            }}
          />
        )
      default:
        return <React.Fragment />
    }
  }

  const contact = React.useMemo(
    () => [
      ...initialContact,
      {
        label: 'Contact Creation Date',
        value: 'contact.created',
      },
    ],
    [initialContact],
  )
  const fieldOptions = React.useMemo(
    () => [
      ...(messages.length > 0
        ? [
            {
              title: 'Messages',
              data: prepareOptions(messages),
            },
          ]
        : []),
      {
        title: 'Contact fields',
        data: prepareOptions(contact),
      },
      {
        title: 'Custom fields',
        data: prepareOptions(customfields),
      },
      ...(groupsList.length > 0
        ? [
            {
              title: '# of contacts in group',
              data: prepareOptions(groupsList),
            },
          ]
        : []),
      ...(segmentsList.length > 0
        ? [
            {
              title: '# of contacts in segment',
              data: prepareOptions(segmentsList),
            },
          ]
        : []),
    ],
    [contact, customfields, groupsList, messages, segmentsList],
  )

  const handleFieldChange = React.useCallback(
    (value: string) => {
      setField(value)
      const updatedCondition: Condition = [...condition]
      updatedCondition[1] = value
      onConditionChange(updatedCondition)
    },
    [condition, onConditionChange],
  )
  const handleFieldSelect = React.useCallback(
    (selected: string) => {
      setField(selected)
      const updatedCondition: Condition = [...condition]
      updatedCondition[1] = selected || ''
      const isGroup = checkIfGroup({ groups: groupsList, segments: segmentsList, value: selected })
      if (isGroup) {
        if (!includes(operand, groupOperands)) {
          updatedCondition[0] = ''
        }
      }
      setFilterGroupOperands(isGroup)
      onConditionChange(updatedCondition)
    },
    [condition, groupsList, operand, segmentsList, onConditionChange],
  )
  const conditionPzList = React.useMemo(
    () => ({
      ...personalizationList,
      groups: groupsList.map((group) => ({
        label: `Group size of ${group.label}`,
        value: group.value,
      })),
      segments: segmentsList.map((segment) => ({
        label: `Segment size of ${segment.label}`,
        value: segment.value,
      })),
    }),
    [groupsList, personalizationList, segmentsList],
  )

  return (
    <React.Fragment>
      <Typography
        color={'textSecondary'}
        data-testid={`single-condition-${index}-title`}
        sx={{
          marginTop: '15px',
        }}
        variant="subtitle2"
      >
        {!!index && <And>and </And>}
        {getFieldLabel({ field, filterGroupOperands, contact })}
      </Typography>
      <Grid data-testid={`single-condition-${index}`} container spacing={0}>
        <Grid container spacing={2} xs={11}>
          <GridItem item xs={4}>
            <ConditionField
              error={error}
              field={field}
              fieldError={fieldError}
              options={fieldOptions}
              personalizationList={conditionPzList}
              onChange={handleFieldChange}
              onSelect={handleFieldSelect}
            />
          </GridItem>
          <GridItem item xs={4}>
            <SearchableSelect
              id="conditions-operand-select"
              error={!operand && error}
              grouped={groupedOperators}
              label={operand ? ' ' : 'Select operand'}
              hideRemoveSelection={true}
              helperText={operandError && 'You must select a value'}
              formControlProps={{
                margin: 'none',
                style: {
                  width: '100%',
                },
              }}
              value={operand}
              onChange={(selectedId: string) => {
                setOperand(selectedId)
                let updatedCondition = [...condition]
                updatedCondition[0] = selectedId
                const currentOpType = getOperandType({ op: pathOr('', [0], condition), operands })
                const newOpType = getOperandType({ op: selectedId, operands })
                if (newOpType !== currentOpType) {
                  if (newOpType === 'DateTime') {
                    const now = new Date().toISOString()
                    setTextValue(now)
                    updatedCondition[2] = now
                  } else {
                    setTextValue('')
                    updatedCondition = remove(2, updatedCondition.length - 2, updatedCondition)
                  }
                } else {
                  updatedCondition[2] = pathOr('', [2], updatedCondition)
                }
                onConditionChange(updatedCondition as Condition)
              }}
              options={filterGroupOperands ? filterOperands({ operands, toFilterList: groupOperands }) : operands}
              keyPropFn={(option: SelectOption<string>) => option.value}
              valuePropFn={(option: SelectOption<string>) => option.label}
              showAll={true}
            />
          </GridItem>
          <GridItem item xs={4}>
            {renderInput(getOperandType({ op: pathOr('', [0], condition), operands }))}
          </GridItem>
        </Grid>
        <GridItem xs={1}>
          <ButtonContainer>
            <div>
              {!isFirst && (
                <IconButton data-testid={`single-condition-${index}-delete`} onClick={() => onDelete()}>
                  <Icon fontSize="small">close</Icon>
                </IconButton>
              )}
            </div>
          </ButtonContainer>
        </GridItem>
      </Grid>
    </React.Fragment>
  )
}

const And = styled('span')(() => ({
  color: '#2f80ac',
}))

const GridItem = styled(Grid)(() => ({}))

const ButtonContainer = styled('div')(() => ({
  height: '100%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
}))

const prepareOptions = (options: SelectOption<string>[]) =>
  options.map((option) => ({
    label: option.label,
    value: addCurlyBrackets(option.value),
  }))

const getFieldValue = (condition: Condition) => pathOr('', [1], condition)

const findInGroup = ({ op, operands }: { op: string; operands: OperandsList }) =>
  reduce((accum, operandSet) => find(propEq('value', op), operandSet.data) || accum, {}, operands)

const getOperandType = ({ op, operands }: { op: string; operands: OperandsList | Operands['data'] }) =>
  isOperandsList(operands)
    ? pathOr('TextField', ['component'], findInGroup({ op, operands: operands as OperandsList }))
    : pathOr('TextField', ['component'], find(propEq('value', op), operands as Operands['data']))

const convertGroups = ({ data = {}, ordered = [] }: Props['groups']) =>
  map(
    (value) => ({
      label: path([value, 'name'], data) as string,
      value: `groupsize.${value}`,
    }),
    ordered,
  )

const groupOperands = ['==', '!=', '>', '>=', '<', '<=', 'mod2_equal', 'mod3_equal', 'mod4_equal', 'mod5_equal']

const filterOperands = ({ operands, toFilterList }: { operands: OperandsList; toFilterList: string[] }) =>
  map(
    (operandGroup) => ({
      ...operandGroup,
      data: filter((op) => indexOf(op.value, toFilterList) !== -1, operandGroup.data),
    }),
    operands,
  )

const checkIfGroup = ({
  groups,
  segments,
  value,
}: {
  groups: SelectOption<string>[]
  segments: SelectOption<string>[]
  value: string
}) => {
  const noBracketsValue = removeCurlyBrackets(value)
  return (
    findIndex(propEq('value', noBracketsValue), groups) !== -1 ||
    findIndex(propEq('value', noBracketsValue), segments) !== -1
  )
}

const getFieldLabel = ({
  field,
  filterGroupOperands,
  contact = [],
}: {
  field: string
  filterGroupOperands: boolean
  contact: SelectOption<string>[]
}) => {
  if (!field) {
    return ''
  }
  if (filterGroupOperands) {
    return 'the number of contacts in'
  }
  if (find(propEq('value', field), contact)) {
    return 'the contact field'
  }
  return 'the custom field'
}

export default SingleCondition
