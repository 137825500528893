import { equals, pathOr } from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'

import { Groups } from '@/app/component/conditions/definitions'
import { BaseLanguageType } from '@/app/module/campaigns/helpers'
import { selectFilesState, selectMessagesState } from '@/app/module/campaigns/store/selectors'
import {
  CampaignQuery,
  ConditionActions,
  FilesState,
  IVRCampaignItem,
  MessagesState,
  SnippetType,
} from '@/app/module/campaigns/types'
import { CountryTimezone, FileType, FileUploadType, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'

import Reconnect from './reconnect'

type Props = {
  campaignIds: SelectOption<number>[]
  countriesTimezones: CountryTimezone[]
  countryCode: string
  groups: Groups
  item: IVRCampaignItem
  itemId: number
  loading: boolean
  orgId: number
  segments: Groups
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  timezone: string
  timezones: TimezoneWithCountryCodeType
  token: string
  voiceList: BaseLanguageType[]

  getCampaigns: (payload: { orgId: number; token: string; query: CampaignQuery }) => Promise<void>
  getFile: (payload: { file: string; msgId: string; orgId: number; token: string }) => Promise<FileType>
  getSnippets: (payload: { token: string; orgId: number }) => Promise<void>
  onChange: (item: IVRCampaignItem) => void
  onMicAccessError: () => void
  saveFile: (payload: { item: FileUploadType; msgId: string; orgId: number; token: string }) => Promise<FileType>
  saveSnippet: (payload: {
    orgId: number
    token: string
    item: Pick<SnippetType, 'category' | 'snippet'>
  }) => Promise<SnippetType>
}

const ReconnectContainer: React.FC<Props> = ({
  campaignIds,
  countriesTimezones,
  countryCode,
  groups,
  item,
  itemId,
  loading,
  orgId,
  segments,
  snippets,
  timezone,
  timezones,
  token,
  voiceList,
  getCampaigns,
  getFile,
  getSnippets,
  onChange,
  onMicAccessError,
  saveFile,
  saveSnippet,
}) => {
  const [voicePlayingId, setVoicePlayingId] = React.useState<string>()

  const files = useSelector(selectFilesState) as FilesState
  const messages = useSelector(selectMessagesState) as unknown as MessagesState

  const getFileHandler = React.useCallback(
    (msgId: string, file: string) => getFile({ file, msgId, orgId, token }),
    [orgId, token, getFile],
  )
  const handleReconnectChange = React.useCallback(
    ({ reconnectMax, reconnectOnProgress }: { reconnectMax: number; reconnectOnProgress: boolean }) => {
      const newItem = {
        ...item,
        reconnectMax,
        reconnectOnProgress,
      }
      if (equals(item, newItem)) {
        return
      }
      onChange({
        ...item,
        reconnectMax,
        reconnectOnProgress,
      })
    },
    [item, onChange],
  )
  const saveFileHandler = React.useCallback(
    (msgId: string, file: FileUploadType) =>
      saveFile({
        item: file,
        msgId,
        orgId,
        token,
      }),
    [orgId, token, saveFile],
  )
  const setDefaultUpload = React.useCallback(
    (defaultUpload: number) => {
      onChange({
        ...item,
        uiStore: {
          ...item.uiStore,
          defaultUpload,
        },
      })
    },
    [item, onChange],
  )

  const changeHandler = React.useCallback(
    ({ value }: { value: ConditionActions[] }) => {
      onChange({
        ...item,
        variables: {
          ...item.variables,
          reconnectActions: value,
        },
      })
    },
    [item, onChange],
  )
  const getImageProps = React.useCallback(
    (qid: string) => ({
      id: qid,
      loading: files.loadingItems.indexOf(qid) > -1,
      getFile: (file: string) => getFileHandler(qid, file),
      saveFileHandler: (file: FileUploadType) => saveFileHandler(qid, file),
    }),
    [files.loadingItems, getFileHandler, saveFileHandler],
  )
  const getVoiceProps = React.useCallback(
    (qid: string) => ({
      id: qid,
      defaultUpload: pathOr(0, ['uiStore', 'defaultUpload'], item),
      enableTTS: true,
      files: files.data || {},
      filterType: item.type || '',
      loading: files.loadingItems.indexOf(qid) > -1,
      personalizationList: messages.personalizationList,
      playId: voicePlayingId,
      source: files.data,
      voiceList,
      getFile: (file: string) => getFileHandler(qid, file),
      onMicAccessError,

      setDefaultUpload,
      saveFileHandler: (file: FileUploadType) => saveFileHandler(qid, file),
      setPlaying: (val: string) => {
        setVoicePlayingId(val)
      },
    }),
    [
      files.data,
      files.loadingItems,
      item,
      messages.personalizationList,
      voiceList,
      voicePlayingId,
      getFileHandler,
      onMicAccessError,
      saveFileHandler,
      setDefaultUpload,
    ],
  )

  if (loading || (!!itemId && !item.type)) {
    return null
  }

  return (
    <Reconnect
      actions={pathOr(defaultActions, ['variables', 'reconnectActions'], item)}
      campaignIds={campaignIds}
      campaignType={item.type}
      countriesTimezones={countriesTimezones}
      countryCode={countryCode}
      defaultLanguage={item.uiStore?.defaultLanguage || ''}
      groups={groups}
      languages={item.uiStore?.languages || []}
      orgId={orgId}
      personalizationList={messages.personalizationList}
      reconnectMax={item.reconnectMax}
      reconnectOnProgress={item.reconnectOnProgress}
      senderIds={messages.senderIds}
      segments={segments}
      snippets={snippets}
      timezone={timezone}
      timezones={timezones}
      token={token}
      changeHandler={changeHandler}
      getImageProps={getImageProps}
      getCampaigns={getCampaigns}
      getSnippets={getSnippets}
      getVoiceProps={getVoiceProps}
      onChange={handleReconnectChange}
      saveSnippet={saveSnippet}
    />
  )
}

const defaultActions = [{ when: null, actions: [] }]

export default ReconnectContainer
