import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { reduce } from 'ramda'
import React from 'react'

import CustomTooltip from '@/app/component/atom/tooltip'
import { cannot } from '@/app/helpers'
import { defaultRepeatQuestion } from '@/app/module/campaigns/definitions'
import { LanguageType } from '@/app/module/campaigns/helpers'
import {
  generateDefaultSpeechReplies,
  generateSpeechSettings,
  hasSpeechLanguage,
} from '@/app/module/campaigns/language-helpers'
import {
  CampaignTemplateType,
  Language,
  OnAnswerType,
  PersonalizationType,
  SenderIdOption,
  SnippetType,
  TabTypes,
  VoiceQuestion,
} from '@/app/module/campaigns/types'
import { CountryTimezone, FilesType, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'

import { GetCampaigns, GetImageProps, GetSnippets, GetVoiceProps, SaveSnippet } from './part-config-props'
import ResponseVoiceSpecific from './response-voice-specific'
import ResponseVoiceSpecificConfigs from './response-voice-specific-configs'
import { SpecificConfigs } from './response-voice-specific-configs-utils'
import ResponseVoiceSpeech from './response-voice-speech'
import ResponseVoiceSpeechConfigs from './response-voice-speech-configs'
import { SpeechConfigs } from './response-voice-speech-configs-utils'
import { SpeechValue } from './response-voice-speech-utils'
import ResponseVoiceSpoken from './response-voice-spoken'
import { SpokenValue } from './response-voice-spoken-utils'

type Props = {
  campaignIds: SelectOption<number>[]
  countriesTimezones: CountryTimezone[]
  countryCode: string
  defaultLanguage: string
  files: FilesType
  id: string
  index: number
  item: VoiceQuestion
  languages: LanguageType[]
  orgId: number
  personalizationList: PersonalizationType
  senderIds: SenderIdOption[]
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  timezones: TimezoneWithCountryCodeType
  timezone: string
  token: string
  type: CampaignTemplateType
  whatsappSenderIds: SenderIdOption[]

  changeHandler: (item: Partial<VoiceQuestion>) => void
  getCampaigns: GetCampaigns
  getImageProps: GetImageProps
  getSnippets: GetSnippets
  getVoiceProps: GetVoiceProps
  saveSnippet: SaveSnippet
}

export default function CampaignContentQuestionVoice(props: Props) {
  const {
    item,
    id,
    getImageProps,
    getVoiceProps,
    personalizationList,
    senderIds,
    getCampaigns,
    orgId,
    token,
    campaignIds,
    files,
    timezone,
    timezones,
    countryCode,
    countriesTimezones,
    getSnippets,
    saveSnippet,
    snippets,
    languages,
    defaultLanguage,
    changeHandler,
    whatsappSenderIds,
  } = props
  const [responseType, setResponseType] = React.useState<TabTypes>(getResponseType(item))
  React.useEffect(() => {
    setResponseType(getResponseType(item))
  }, [item])

  const canEnableSpeech = React.useMemo(() => hasSpeechLanguage(languages), [languages])

  const handleChange = React.useCallback(
    (value: Partial<VoiceQuestion>) =>
      changeHandler({
        id: item.id,
        ...value,
      }),
    [item.id, changeHandler],
  )

  const speechLanguages = React.useMemo<Language[]>(
    () =>
      reduce(
        (acc: Language[], l: LanguageType) => {
          if (!l.speechLanguage) {
            return acc
          }

          return [
            ...acc,
            {
              name: l.speechLanguage.name,
              value: l.speechLanguage.value,
            },
          ]
        },
        [],
        languages,
      ),
    [languages],
  )

  const handleAnswerTypeChange = React.useCallback(
    (e: React.SyntheticEvent, val: TabTypes) => {
      switch (val) {
        case 'keypress':
          return handleChange({
            spoken: undefined,
            onAnswer: [
              {
                replies: ['1'],
                ranges: [],
                actions: [],
                label: '',
              },
              {
                replies: ['2'],
                ranges: [],
                actions: [],
                label: '',
              },
            ],
            speechSettings: undefined,
            useAi: false,
          })
        case 'spoken':
          return handleChange({
            onAnswer: [],
            spoken: {
              actions: [],
              silence: 3,
              maxSeconds: 10,
              stopKey: '#',
              transcribe: false,
            },
            speechSettings: undefined,
            useAi: false,
          })

        case 'speech': {
          return handleChange({
            spoken: undefined,
            onAnswer: [
              {
                speechReplies: generateDefaultSpeechReplies(speechLanguages),
                ranges: [],
                actions: [],
                label: '',
              },
              {
                speechReplies: generateDefaultSpeechReplies(speechLanguages),
                ranges: [],
                actions: [],
                label: '',
              },
            ],
            speechSettings: generateSpeechSettings(languages),
            useAi: false,
          })
        }
        default:
          return cannot(val)
      }
    },
    [languages, speechLanguages, handleChange],
  )

  const actionProps = React.useMemo(
    () => ({
      campaignIds,
      countriesTimezones,
      countryCode,
      defaultLanguage,
      files,
      hasTranscribe: false,
      index: props.index,
      languages,
      personalizationList,
      senderIds,
      timezone,
      timezones,
      transcribeEnabled: false,
      whatsappSenderIds,
      getCampaigns,
      getImageProps,
      getVoiceProps,
    }),
    [
      campaignIds,
      countriesTimezones,
      countryCode,
      defaultLanguage,
      files,
      languages,
      personalizationList,
      props.index,
      senderIds,
      timezone,
      timezones,
      whatsappSenderIds,
      getCampaigns,
      getImageProps,
      getVoiceProps,
    ],
  )
  const apiProps = React.useMemo(() => ({ orgId, token }), [orgId, token])
  const snippetProps = React.useMemo(
    () => ({ snippets, getSnippets, saveSnippet }),
    [snippets, getSnippets, saveSnippet],
  )

  const handleVoiceSpecificChange = React.useCallback(
    (value: OnAnswerType[]) => handleChange({ onAnswer: value }),
    [handleChange],
  )

  const handleVoiceSpokenChange = React.useCallback(
    (spoken: SpokenValue) => changeHandler({ ...item, ...spoken }),
    [item, changeHandler],
  )

  const handleSpecificConfigsChange = React.useCallback(
    (configs: SpecificConfigs) => handleChange({ ...configs }),
    [handleChange],
  )
  const specificConfigs = React.useMemo(
    () => ({
      keypressAfterAudio: item.keypressAfterAudio,
      repeatQuestion: item.repeatQuestion || defaultRepeatQuestion,
      onInvalidReply: item.onInvalidReply,
      onTimeout: item.onTimeout,
      retries: item.retries,
      onRetriesExhausted: item.onRetriesExhausted,
    }),
    [
      item.keypressAfterAudio,
      item.repeatQuestion,
      item.onInvalidReply,
      item.onTimeout,
      item.retries,
      item.onRetriesExhausted,
    ],
  )
  const spoken = React.useMemo<SpokenValue | undefined>(() => {
    if (!item.spoken) {
      return undefined
    }
    return {
      processingReply: item.processingReply,
      spoken: item.spoken,
    }
  }, [item.processingReply, item.spoken])

  const handleSpeechConfigChange = React.useCallback(
    (configs: SpeechConfigs) => handleChange({ ...configs }),
    [handleChange],
  )
  const speechConfigs = React.useMemo(
    () => ({
      onTimeout: item.onTimeout,
      retries: item.retries,
      onRetriesExhausted: item.onRetriesExhausted,
      speechSettings: item.speechSettings,
    }),
    [item.onTimeout, item.retries, item.onRetriesExhausted, item.speechSettings],
  )
  const handleSpeechChange = React.useCallback((speech: SpeechValue) => handleChange({ ...speech }), [handleChange])
  const speech = React.useMemo<SpeechValue | undefined>(() => {
    if (!item.speechSettings) {
      return undefined
    }
    return {
      onAnswer: item.onAnswer,
      processingReply: item.processingReply,
      speechSettings: item.speechSettings,
      useAi: item.useAi,
    }
  }, [item.onAnswer, item.processingReply, item.speechSettings, item.useAi])

  return (
    <div
      className="voice-q-choice-block"
      style={{
        marginTop: '2rem',
      }}
    >
      <Divider
        style={{
          margin: '1rem -24px',
        }}
      />
      <Tabs value={responseType} indicatorColor="primary" onChange={handleAnswerTypeChange}>
        <Tab
          value="keypress"
          label="Keypress Response"
          style={{ zIndex: 2 }}
          icon={<Icon>dialpad</Icon>}
          wrapped
          className="campaign-keypress-tab"
        />
        {canEnableSpeech && (
          <Tab
            value="speech"
            label="Speech Recognition"
            style={{ zIndex: 2 }}
            icon={<Icon>mic</Icon>}
            wrapped
            className="campaign-speech-tab"
          />
        )}
        {!canEnableSpeech && (
          <CustomTooltip title="You have to setup a speech language before you can start using speech recognition">
            <div>
              <Tab
                disabled
                value="speech"
                label="Speech Recognition"
                style={{ zIndex: 2 }}
                icon={<Icon>mic</Icon>}
                wrapped
                className="campaign-speech-tab"
              />
            </div>
          </CustomTooltip>
        )}
        <Tab
          value="spoken"
          label="Spoken Response"
          style={{ zIndex: 2 }}
          icon={<Icon>voicemail</Icon>}
          wrapped
          className="campaign-spoken-tab"
        />
      </Tabs>
      <Divider
        style={{
          margin: '-1px -24px 1rem',
        }}
      />
      {responseType === 'keypress' && (
        <div className="campaign-keypress-tab-content">
          <ResponseVoiceSpecific
            actionProps={actionProps}
            apiProps={apiProps}
            campaignType={props.type}
            id={props.id}
            onAnswer={item.onAnswer}
            snippetProps={snippetProps}
            onChange={handleVoiceSpecificChange}
          />
          <ResponseVoiceSpecificConfigs
            actionProps={actionProps}
            apiProps={apiProps}
            campaignType={props.type}
            configs={specificConfigs}
            id={id}
            snippetProps={snippetProps}
            onChange={handleSpecificConfigsChange}
          />
        </div>
      )}
      {responseType === 'speech' && !!speech && (
        <div className="campaign-speech-tab-content">
          <ResponseVoiceSpeech
            actionProps={actionProps}
            apiProps={apiProps}
            campaignType={props.type}
            id={props.id}
            snippetProps={snippetProps}
            speech={speech}
            onChange={handleSpeechChange}
          />
          {!!speechConfigs.speechSettings && (
            <ResponseVoiceSpeechConfigs
              actionProps={actionProps}
              apiProps={apiProps}
              campaignType={props.type}
              configs={speechConfigs as SpeechConfigs}
              id={id}
              snippetProps={snippetProps}
              useAi={speech.useAi}
              onChange={handleSpeechConfigChange}
            />
          )}
        </div>
      )}
      {responseType === 'spoken' && !!spoken && (
        <ResponseVoiceSpoken
          actionProps={actionProps}
          apiProps={apiProps}
          campaignType={props.type}
          id={props.id}
          snippetProps={snippetProps}
          spoken={spoken}
          onChange={handleVoiceSpokenChange}
        />
      )}
    </div>
  )
}

const getResponseType = (item: VoiceQuestion) => {
  if (item.spoken) {
    return 'spoken'
  }
  if (item.speechSettings) {
    return 'speech'
  }
  return 'keypress'
}
