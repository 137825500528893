import { pathOr } from 'ramda'

import {
  defaultPageSize as defaultContactsPageSize,
  getContactsFailure,
  getContactsRequest,
  getContactsSuccess,
} from '@/app/module/contacts/store/actions'
import request from '@/app/module/request'
import {
  ACTION_GET_SEGMENTS_FAILURE,
  ACTION_GET_SEGMENTS_ITEM_FAILURE,
  ACTION_GET_SEGMENTS_ITEM_REQUEST,
  ACTION_GET_SEGMENTS_ITEM_SUCCESS,
  ACTION_GET_SEGMENTS_REQUEST,
  ACTION_GET_SEGMENTS_SUCCESS,
  ACTION_RESET_SEGMENTS_QUERY,
  ACTION_SEGMENTS_NAVIGATE,
  ERROR_GET_SEGMENTS_ITEM_CONTACTS_SERVER,
  ERROR_GET_SEGMENTS_SERVER,
} from '@/app/module/segments/definitions'
import { createAsyncActions, preventParallel, toQuery } from '@/app/service/util'
import envConfig from '@/config'

const SEGMENT_TYPE = pathOr({}, ['modules', 'segments', 'type'], envConfig)

const {
  request: getSegmentsRequest,
  success: getSegmentsSuccess,
  failure: getSegmentsFailure,
} = createAsyncActions({
  request: ACTION_GET_SEGMENTS_REQUEST,
  success: ACTION_GET_SEGMENTS_SUCCESS,
  failure: ACTION_GET_SEGMENTS_FAILURE,
})

const {
  request: getSegmentsItemRequest,
  success: getSegmentsItemSuccess,
  failure: getSegmentsItemFailure,
} = createAsyncActions({
  request: ACTION_GET_SEGMENTS_ITEM_REQUEST,
  success: ACTION_GET_SEGMENTS_ITEM_SUCCESS,
  failure: ACTION_GET_SEGMENTS_ITEM_FAILURE,
})

export { getSegmentsFailure, getSegmentsRequest, getSegmentsSuccess }

export const getSegments = preventParallel(
  ({ orgId, query = {} }) => `${orgId}-${toQuery(query)}`,
  ({ token, orgId, query = {} }) =>
    (dispatch) => {
      const search = query.searchTerm || query.search
      const q = {
        ...query,
        search,
        type: SEGMENT_TYPE.type,
      }
      dispatch(getSegmentsRequest(q))

      const { page = 1, size } = q

      return request.groups
        .getList({ token, orgId, query: q })
        .then(({ groups = [], total = 0 } = {}) => {
          dispatch(
            getSegmentsSuccess({
              values: groups,
              page,
              search,
              size: size || total,
              total,
            }),
          )
        })
        .catch((err) => {
          dispatch(
            getSegmentsFailure({
              status: err.status,
              error: err.message,
              message: ERROR_GET_SEGMENTS_SERVER,
            }),
          )
          return err
        })
    },
)

export const resetSegmentsQuery = () => ({
  type: ACTION_RESET_SEGMENTS_QUERY,
})

export const getSegmentsItem =
  ({ itemId, token, orgId }) =>
  (dispatch) => {
    dispatch(getSegmentsItemRequest(itemId))
    if (!token || !orgId || !itemId) {
      return {}
    }
    return request.groups
      .getItem({ token, orgId, query: { itemId } })
      .then((res) => {
        dispatch(getSegmentsItemSuccess(res))
      })
      .catch((err) => {
        dispatch(
          getSegmentsItemFailure({
            status: err.status,
            error: err.message,
            message: ERROR_GET_SEGMENTS_SERVER,
          }),
        )
      })
  }

export const getSegmentContacts =
  ({ token, orgId, segmentId, query }) =>
  (dispatch) => {
    const q = {
      ...query,
      type: SEGMENT_TYPE.type,
    }
    dispatch(getContactsRequest(q))

    const { page = 1, size = defaultContactsPageSize } = q

    return request.groups
      .getContacts({ token, orgId, groupId: segmentId, query: q })
      .then(({ contacts = [], total, orgTotal }) => {
        dispatch(
          getContactsSuccess({
            values: contacts,
            page: parseInt(page || q.page, 10),
            size: size || defaultContactsPageSize,
            total,
            orgTotal,
            searchTerm: '',
            filter: q.filter,
          }),
        )
      })
      .catch((err) => {
        dispatch(
          getContactsFailure({
            status: err.status,
            error: err.message,
            message: ERROR_GET_SEGMENTS_ITEM_CONTACTS_SERVER,
          }),
        )
      })
  }

export const navigateSegments = ({ page = 1, searchTerm }) => ({
  type: ACTION_SEGMENTS_NAVIGATE,
  value: { page, searchTerm },
})
