import { styled } from '@mui/material'
import React from 'react'

import { Input } from '@/app/component/atom/form'
import PzMenu, { PzMenuRef } from '@/app/component/atom/pz-menu'
import { PersonalizationType } from '@/app/module/campaigns/types'

type Props = {
  personalizationList: PersonalizationType
  t0: string
  onChange: (t0: string) => void
}

const EventTimeAdvancedInput: React.FC<Props> = ({ personalizationList, t0, onChange }) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const menuRef = React.useRef<PzMenuRef | null>(null)
  const [selectionStart, setSelectionStart] = React.useState(0)

  const updateSelectionStart = () => {
    if (!inputRef.current || typeof inputRef.current.selectionStart !== 'number') {
      return
    }

    setSelectionStart(inputRef.current.selectionStart)
  }

  const resetSelectionPosition = (updatedSelectionPosition: number) => {
    setTimeout(() => {
      if (!inputRef.current) {
        return
      }

      inputRef.current.setSelectionRange(updatedSelectionPosition, updatedSelectionPosition)
    }, 0)
  }

  return (
    <Container>
      <Input
        inputRef={inputRef}
        label="Enter value"
        name="t0-advanced-input"
        value={t0}
        onBlur={updateSelectionStart}
        onChange={({ value }) => onChange(value)}
      />
      <MenuContainer>
        <PzMenu
          id="t0-pz-menu"
          containerSx={{
            marginBottom: '-5px',
          }}
          personalizationList={personalizationList}
          ref={menuRef}
          variant="text"
          onSelect={(item) => {
            const toBeAdded = `{{${item.value}}}`
            const newMessage = `${t0.slice(0, selectionStart)}${toBeAdded}${t0.slice(selectionStart)}`

            onChange(newMessage)

            const newSelectionStart = (inputRef.current?.selectionStart || selectionStart) + toBeAdded.length
            resetSelectionPosition(newSelectionStart)
            setSelectionStart(newSelectionStart)
          }}
        />
      </MenuContainer>
    </Container>
  )
}

const Container = styled('div')(() => ({
  position: 'relative',
}))

const MenuContainer = styled('div')(() => ({
  position: 'absolute',
  right: '2px',
  top: '4px',
  zIndex: 999,
  backgroundColor: 'white',
  borderRadius: '50%',
}))

export default React.memo(EventTimeAdvancedInput)
