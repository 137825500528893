import { Box, Grid, styled, Typography } from '@mui/material'
import React from 'react'

import CopyBox from '@/app/component/atom/copy-box'
import { useGetOrganization } from '@/app/module/user/hooks/use-get-organization'

import { OrganizationFormSkeleton } from './organization-form'
import UpdateOrganization from './update-organization'

type Props = {
  orgId: number
}

const OrganizationInfo: React.FC<Props> = ({ orgId }) => {
  const { isLoading, isFetching } = useGetOrganization(orgId)

  const isSkeleton = isLoading || isFetching

  return (
    <div>
      <Grid container>
        <Grid item md={8} xs={12}>
          <Box display="flex" justifyContent="space-between" p={2}>
            <Typography variant="h5" color="primary">
              Organization Info
            </Typography>
            <CopyContainer>
              <CopyBox text={orgId.toString()} title="Organization ID" />
            </CopyContainer>
          </Box>
          {!isSkeleton && <UpdateOrganization orgId={orgId} />}
          {isSkeleton && (
            <Box p={2}>
              <OrganizationFormSkeleton />
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

const CopyContainer = styled('div')({
  '& .MuiFormControl-root': {
    margin: 0,
  },
})

export default OrganizationInfo
