import { Dispatch } from '@reduxjs/toolkit'

import { ERROR_RESUME_SUBSCRIPTION } from '@/app/module/campaigns/definitions'
import { CampaignSubscription } from '@/app/module/campaigns/types'
import { getContactsItem } from '@/app/module/contacts/store/actions'
import { createNotification } from '@/app/module/notifications/store/actions'
import baseRequest from '@/app/module/request'
import { processErrors } from '@/app/service/util/errorCodes'

const request = baseRequest as any

type ResumeSubscriptionResponse = {
  sub: CampaignSubscription
}

export const resumeSubscription =
  ({
    token,
    orgId,
    campaignId,
    subId,
  }: {
    token: string
    orgId: number
    campaignId: number
    contactId: number
    subId: number
  }) =>
  (dispatch: Dispatch) => {
    return request.cati
      .setStatus({ token, orgId, subId, item: { paused: false } })
      .then((res: ResumeSubscriptionResponse | Error) => {
        if (res instanceof Error) {
          return res
        }

        dispatch(
          createNotification({
            'resume-subscription-success': {
              type: 'success',
              message: 'Subscription resumed successfully',
            },
          }),
        )
        return getContactsItem({ token, orgId, itemId: res.sub.contactId, campaignId })(dispatch)
      })
      .catch((err: any) => {
        const errorMessage = processErrors(err, ERROR_RESUME_SUBSCRIPTION)
        dispatch(
          createNotification({
            'resume-subscription-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }
