import React from 'react'

import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'

type Props = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const UseAiDialog: React.FC<Props> = ({ open, onClose, onConfirm }) => (
  <ConfirmationDialog
    id="use-ai-dialog"
    deleteButtonText="Enable AI"
    isOpen={open}
    title="AI enhanced answer recognition"
    icon="assistant"
    text={
      <>
        <br />
        <span>Use Artificial Intelligence to determine which answer the contact probably meant.</span>
        <br />
        <span>
          For example, if the answer choices are female and male, and the contact replies, "I am a woman"; the AI will
          identify female as the response, and our system will take the same steps it would take if the contact replied
          female directly.
        </span>
        <br />
        <br />
        <span>
          <b>NOTE:</b> This means we will send data to a large language model like ChatGPT. The ONLY thing we send is
          the question as you typed it above, and the reply as the contact sends it—nothing else.
        </span>
        <br />
        <br />
        <span>
          In particular, we will NOT send the phone number, first or last name or any other contact data—unless it's
          included in the question or the reply.
        </span>
        <br />
        <br />
        <b>
          By enabling this feature you confirm that you understand this and consent to the data sharing as described
          above.
        </b>
      </>
    }
    onClose={onClose}
    onConfirm={onConfirm}
  />
)

export default UseAiDialog
