import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import Select from '@/app/component/atom/form/select'

const PaginationContainer = styled('div')({
  display: 'flex',
  width: '100%',
  padding: '4px 24px',
  justifyContent: 'center',
  alignItems: 'center',

  '@media (min-width: 800px)': {
    margin: '0',
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const PaginationContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const PageSelect = styled('div')({
  margin: '-8px 1rem 0',
})

const PaginationSizedContent = styled(PaginationContent)({
  display: 'none',

  '@media (min-width: 800px)': {
    display: 'flex',
  },
})

const ShowText = styled(Typography)({
  display: 'none',
  opacity: '0.5',

  '@media (min-width: 800px)': {
    display: 'block',
  },
})

export const getLastPage = (size?: number, total?: number) => {
  const pageSize = size || total
  if (!pageSize || !total) {
    return 1
  }

  return Math.ceil(total / pageSize) || 1
}

type TablePaginationProps = {
  namespace: string
  page: number
  size: number
  total: number
  pageSizeValues: Array<{ label: string; value: number }>
  onPageChange: (page: number) => void
  onPageSizeChange: (size: number) => void
}

function TablePagination({
  namespace,
  page,
  size,
  total,
  pageSizeValues,
  onPageChange,
  onPageSizeChange,
}: TablePaginationProps) {
  const lastPage = getLastPage(size, total)

  const pageSelectValues = Array.from(new Array(lastPage), (_val, i) => ({
    label: String(i + 1),
    value: String(i + 1),
  }))

  const showFrom = (page - 1) * size + 1
  const showTo = page === lastPage ? total : page * size

  if (total > 0 && page > lastPage) {
    onPageChange(lastPage)
  }

  return (
    <PaginationContainer>
      <PaginationSizedContent>
        {pageSizeValues && (
          <PaginationContent>
            <div>
              <Select
                id={`${namespace}-page-size`}
                label=" "
                name={`select-${namespace}-page-size`}
                value={String(size)}
                values={pageSizeValues.map((p) => ({ label: p.label, value: String(p.value) }))}
                editable={true}
                onChange={({ value }) => onPageSizeChange(parseInt(value, 10))}
              />
            </div>
            <ShowText>{namespace} per page</ShowText>
          </PaginationContent>
        )}
      </PaginationSizedContent>
      <PaginationContent>
        <ShowText>
          Showing {showFrom}-{showTo} of {total}
        </ShowText>
        <PaginationContent>
          <IconButton onClick={() => onPageChange(1)} disabled={page === 1} aria-label="First Page">
            <FirstPageIcon />
          </IconButton>
          <IconButton onClick={() => onPageChange(Number(page) - 1)} disabled={page === 1} aria-label="Previous Page">
            <KeyboardArrowLeft />
          </IconButton>
          <PageSelect>
            <Select
              id={`${namespace}-page-select`}
              label=" "
              name={`select-${namespace}-page`}
              value={String(page)}
              values={pageSelectValues}
              editable={lastPage > 1}
              onChange={({ value }) => onPageChange(parseInt(value, 10))}
            />
          </PageSelect>
          <IconButton onClick={() => onPageChange(Number(page) + 1)} disabled={page >= lastPage} aria-label="Next Page">
            <KeyboardArrowRight />
          </IconButton>
          <IconButton
            onClick={() => onPageChange(Math.max(1, lastPage))}
            disabled={page >= lastPage}
            aria-label="Last Page"
          >
            <LastPageIcon />
          </IconButton>
        </PaginationContent>
      </PaginationContent>
    </PaginationContainer>
  )
}

export default TablePagination
