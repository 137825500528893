import React from 'react'

import { useCampaignParts } from '@/app/module/campaigns/component/hooks/use-campaign-parts'
import CampaignContentQuestionAction from '@/app/module/campaigns/component/item/steps/content/question/action'
import { LocalAction, LocalActionType, LocalNonEmptyAction, PersonalizationType } from '@/app/module/campaigns/types'
import { ActionOption } from '@/app/module/campaigns/utils/actions/options'
import { FilesType } from '@/app/types'

type Props = {
  action: LocalAction
  actionOptions: ActionOption[]
  disabledIndexesFrom: Record<string, number>
  dragContext: string
  files: FilesType
  index: number
  isLast: boolean
  personalizationList: PersonalizationType
  onAddToLibrary: (action: LocalNonEmptyAction) => Promise<void>
  onChange: (value: LocalActionType, index: number) => void
  onMove: (from: number, to: number) => void
  onRemove: (index: number) => void
}

const ActionListItem: React.FC<Props> = ({
  action,
  actionOptions,
  dragContext,
  disabledIndexesFrom,
  files,
  index,
  isLast,
  personalizationList,
  onAddToLibrary,
  onChange,
  onMove,
  onRemove,
}) => {
  // TODO: move this hook to jump preview component to avoid re-rendering
  const parts = useCampaignParts()

  const optionList = React.useMemo(
    () =>
      actionOptions.map((option) => ({
        ...option,
        disabled: disabledIndexesFrom[option.value] !== undefined && index > disabledIndexesFrom[option.value],
      })),
    [actionOptions, disabledIndexesFrom, index],
  )

  return (
    <CampaignContentQuestionAction
      action={action}
      dragContext={dragContext}
      files={files}
      index={index}
      isLast={isLast}
      parts={parts}
      personalizationList={personalizationList}
      values={optionList}
      onChange={({ value }: { value: LocalActionType }) => onChange(value, index)}
      onCopy={onAddToLibrary}
      onMove={(from: number) => onMove(from, index)}
      onRemove={() => {
        if (!action.type || isLast) {
          return
        }

        onRemove(index)
      }}
    />
  )
}

export default React.memo(ActionListItem)
