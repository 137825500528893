import { styled } from '@mui/material'
import moment from 'moment'
import React from 'react'

import { PhoneNumber } from '@/app/module/phonenumbers/types'
import config from '@/config'

import ConnectMetaButton from './connect-meta-button'
import ConnectMetaStatus from './connect-meta-status'

type Props = {
  orgId: number
  phoneNumber: PhoneNumber
  phoneNumberId: number
}

const MetaConnect: React.FC<Props> = ({ orgId, phoneNumber, phoneNumberId }) => {
  const isWhitelisted = !!config.meta.orgWhitelist && config.meta.orgWhitelist.includes(orgId)
  const isTPPEligible = moment(phoneNumber.created).isSameOrAfter('2025-01-01T00:00:00Z', 'day')
  const showConnectMeta = phoneNumber.whatsApp && (isWhitelisted || isTPPEligible)

  if (!showConnectMeta) {
    return null
  }

  return (
    <Container>
      <ConnectMetaStatus registered={phoneNumber.whatsAppRegistered} />
      {!phoneNumber.whatsAppRegistered && (
        <ConnectMetaButton
          didId={phoneNumberId}
          phoneNumber={phoneNumber.phoneNumber}
          whatsappMetaOtp={phoneNumber.whatsAppMetaOtp ?? false}
        />
      )}
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export default MetaConnect
export type { Props as MetaConnectProps }
