import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { isEmpty, path } from 'ramda'
import { makeStyles } from 'tss-react/mui'

import CardLayout from '@/app/component/layout/card'
import ErrorLayout from '@/app/component/layout/error'

function ContactsUploadSuccessContent({ error }) {
  const { classes } = useStyles()
  return (
    <div>
      {isEmpty(error) ? (
        <div className={classes.wrap}>
          <Icon className={classes.icon}>check_circle</Icon>
          <Typography variant="subtitle1" gutterBottom={true}>
            File uploaded
          </Typography>
          <Typography gutterBottom={true}>{`
              We've started processing your upload. We'll notify you when it's done.
              Please don't leave the engageSPARK website until we notify you that that
              processing has completed.
            `}</Typography>
        </div>
      ) : (
        <div className={classes.errorWrap}>
          <CardLayout>
            <ErrorLayout>
              <Typography>{error.message}</Typography>
            </ErrorLayout>
          </CardLayout>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles()((theme) => ({
  wrap: {
    textAlign: 'center',
  },
  icon: {
    fontSize: '4rem',
    margin: '2rem 0',
    color: path(['palette', 'success', 'main'], theme),
  },
  errorWrap: {
    marginTop: '-2rem',
  },
}))

export default ContactsUploadSuccessContent
