import { z } from 'zod'

import { BackendCampaignTemplate } from '@/app/module/campaigns/types'
import { ZOffset } from '@/app/module/campaigns/types/schemas/common'
import ZActionsOnlyPart from '@/app/module/campaigns/types/schemas/parts/actions-only'
import ZSMSMessagePart from '@/app/module/campaigns/types/schemas/parts/sms-message'
import ZSMSQuestionPart from '@/app/module/campaigns/types/schemas/parts/sms-question'

import { ZBaseCampaignItem } from './base'

const ZReminderActionsOnly = ZActionsOnlyPart.extend({
  offset: ZOffset,
})
const ZReminderSMSMessage = ZSMSMessagePart.extend({
  offset: ZOffset,
})
const ZReminderSMSQuestion = ZSMSQuestionPart.extend({
  offset: ZOffset,
})

export const ZReminderPart = z.union([ZReminderActionsOnly, ZReminderSMSMessage, ZReminderSMSQuestion])

export const ZReminderDefaultArgs = z.object({
  t0: z.string(),
  t0timezone: z.string(),
})

export const ZReminderCampaignVariables = z.object({
  defaultArgs: ZReminderDefaultArgs.optional(),
  defaultSenderId: z.boolean().optional(),
  parts: z.array(ZReminderPart),
  senderId: z.string(),
  senderIdQuestions: z.string(),
  senderIdReplies: z.string(),
})

export const ZReminderCampaignDTO = ZBaseCampaignItem.extend({
  template: z.literal(BackendCampaignTemplate.Reminder),
  variables: ZReminderCampaignVariables,
})
