// ACTIONS
export const ACTION_GET_SEGMENTS_REQUEST = '@actions/SEGMENTS/GET_LIST_REQUEST'
export const ACTION_GET_SEGMENTS_SUCCESS = '@actions/SEGMENTS/GET_LIST_SUCCESS'
export const ACTION_GET_SEGMENTS_FAILURE = '@actions/SEGMENTS/GET_LIST_FAILURE'

export const ACTION_GET_SEGMENTS_ITEM_REQUEST = '@actions/SEGMENTS/GET_ITEM_REQUEST'
export const ACTION_GET_SEGMENTS_ITEM_SUCCESS = '@actions/SEGMENTS/GET_ITEM_SUCCESS'
export const ACTION_GET_SEGMENTS_ITEM_FAILURE = '@actions/SEGMENTS/GET_ITEM_FAILURE'

export const ACTION_RESET_SEGMENTS_QUERY = '@actions/SEGMENTS/RESET_QUERY'

export const ACTION_SEGMENTS_NAVIGATE = '@actions/SEGMENTS/NAVIGATE'

// ERRORS
export const ERROR_GET_SEGMENTS_SERVER = 'Failed to load segments. Please try again later.'
export const ERROR_SAVE_SEGMENTS_ITEM_SERVER = 'Failed to save segment. Please try again later.'
export const ERROR_DELETE_SEGMENTS_ITEM_SERVER = 'Failed to delete segment. Please try again later.'
export const ERROR_GET_SEGMENTS_ITEM_CONTACTS_SERVER =
  'Failed to get contacts for the selected segment. Please try again later.'
