import {
  ACTION_ADD_CUSTOM_FIELDS_FAILURE,
  ACTION_ADD_CUSTOM_FIELDS_REQUEST,
  ACTION_ADD_CUSTOM_FIELDS_SUCCESS,
  ACTION_DELETE_CUSTOM_FIELDS_FAILURE,
  ACTION_DELETE_CUSTOM_FIELDS_REQUEST,
  ACTION_DELETE_CUSTOM_FIELDS_SUCCESS,
  ACTION_GET_CUSTOM_FIELDS_FAILURE,
  ACTION_GET_CUSTOM_FIELDS_REQUEST,
  ACTION_GET_CUSTOM_FIELDS_SUCCESS,
  ACTION_MOD_CUSTOM_FIELDS_FAILURE,
  ACTION_MOD_CUSTOM_FIELDS_REQUEST,
  ACTION_MOD_CUSTOM_FIELDS_SUCCESS,
} from '@/app/module/custom-fields/definitions'
import { createAsyncReducerMap, createReducer } from '@/app/service/util'

import defaultState from './state'

export default createReducer(defaultState, {
  ...createAsyncReducerMap(defaultState)({
    request: ACTION_GET_CUSTOM_FIELDS_REQUEST,
    success: ACTION_GET_CUSTOM_FIELDS_SUCCESS,
    failure: ACTION_GET_CUSTOM_FIELDS_FAILURE,
  }),
  [ACTION_ADD_CUSTOM_FIELDS_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [ACTION_ADD_CUSTOM_FIELDS_SUCCESS]: (state, { value: { id, name } }) => ({
    ...state,
    loading: false,
    data: [
      {
        id,
        name,
      },
      ...state.data,
    ],
  }),
  [ACTION_ADD_CUSTOM_FIELDS_FAILURE]: (state, { value }) => ({
    ...state,
    loading: false,
    error: value,
  }),
  [ACTION_MOD_CUSTOM_FIELDS_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [ACTION_MOD_CUSTOM_FIELDS_SUCCESS]: (state, { value: { id, name } }) => ({
    ...state,
    loading: false,
    data: [
      ...state.data.map((customField) => {
        if (customField.id === id) {
          return {
            id,
            name,
          }
        }
        return customField
      }),
    ],
  }),
  [ACTION_MOD_CUSTOM_FIELDS_FAILURE]: (state, { value }) => ({
    ...state,
    loading: false,
    error: value,
  }),
  [ACTION_DELETE_CUSTOM_FIELDS_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [ACTION_DELETE_CUSTOM_FIELDS_SUCCESS]: (state, { value: { itemId } }) => ({
    ...state,
    loading: false,
    data: state.data.filter((customField) => customField.id !== Number(itemId)),
  }),
  [ACTION_DELETE_CUSTOM_FIELDS_FAILURE]: (state, { value }) => ({
    ...state,
    loading: false,
    error: value,
  }),
})
