import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { filter, find, isEmpty, keys, omit, pathOr, propEq, reduce, values, where, without } from 'ramda'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import TimezonePicker from '@/app/component/atom/timezone-picker'
import {
  CallWindowDay,
  CallWindowSlots,
  CampaignItemWithVoice,
  CallWindow as TCallWindow,
} from '@/app/module/campaigns/types'
import { CountryTimezone, TimezoneWithCountryCodeType } from '@/app/types'

import SingleWindowDay from './single-window-day'

type Props = {
  countriesTimezones: CountryTimezone[]
  countryCode: string
  item: CampaignItemWithVoice
  timezones: TimezoneWithCountryCodeType

  onChange: (item: CampaignItemWithVoice) => void
}

const CallWindow: React.FC<Props> = ({ countriesTimezones, countryCode, item, timezones, onChange }) => {
  const { classes } = useStyles()
  const { callWindow } = item
  const [anytime, setAnytime] = React.useState(true)
  const [country, setCountry] = React.useState(countryCode)
  const [timezone, setTimezone] = React.useState(callWindow?.timezone)

  const updateTimezone = () => {
    if (callWindow?.timezone) {
      setTimezone(callWindow.timezone)
    } else {
      setTimezone(pathOr('', [country, 'tzs', 0, 'timezone'], timezones))
      onChange({
        ...item,
        callWindow: {
          ...item.callWindow,
          timezone: pathOr('', [country, 'tzs', 0, 'timezone'], timezones),
        },
      })
    }
  }

  React.useEffect(() => {
    setAnytime(!hasWindow(item.callWindow))
    const propCountry = keys(
      filter(where({ tzs: find(propEq('timezone', callWindow?.timezone)) }))(timezones),
    )[0] as string
    setCountry(propCountry || countryCode)
    updateTimezone()
    // eslint-disable-next-line
  }, [])

  const handleTimezoneChange = (value: string) => {
    setTimezone(value)
    onChange({
      ...item,
      callWindow: {
        ...item.callWindow,
        timezone: value,
      },
    })
  }

  const updateSlots = (slots: CallWindowSlots) => {
    onChange({
      ...item,
      callWindow: {
        ...item.callWindow,
        ...slots,
      },
    })
    setAnytime(
      !hasWindow({
        ...item.callWindow,
        ...slots,
      }),
    )
  }

  const duplicateDay = ({ label, day }: { label: string; day: CallWindowDay }) => {
    const updatedSlots = reduce<string, CallWindowSlots>(
      (accum, weekDay: string) =>
        pathOr([], [weekDay], item.callWindow).length > 0
          ? {
              ...accum,
              [weekDay]: day,
            }
          : accum,
      {},
      without([label], daysOfTheWeek),
    )
    updateSlots(updatedSlots)
  }
  const toggleAnytime = () => {
    if (!anytime) {
      onChange({
        ...item,
        callWindow: {
          ...item.callWindow,
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        },
      })
    } else {
      onChange({
        ...item,
        callWindow: {
          timezone,
          ...item.callWindow,
          monday: [
            {
              start: '08:00',
              end: '20:00',
            },
          ],
          tuesday: [
            {
              start: '08:00',
              end: '20:00',
            },
          ],
          wednesday: [
            {
              start: '08:00',
              end: '20:00',
            },
          ],
          thursday: [
            {
              start: '08:00',
              end: '20:00',
            },
          ],
          friday: [
            {
              start: '08:00',
              end: '20:00',
            },
          ],
          saturday: [
            {
              start: '08:00',
              end: '20:00',
            },
          ],
          sunday: [
            {
              start: '08:00',
              end: '20:00',
            },
          ],
        },
      })
    }
    setAnytime(!anytime)
  }

  return (
    <Card style={{ marginBottom: '2rem' }} className="call-window-cont">
      <CardHeader title="Configure your calls" style={{ padding: '12px 24px' }} />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Call Time Windows</Typography>
            <Typography variant="body1" gutterBottom={true} className={classes.scheduleCampaignText}>
              You can tell us when it's okay to call your contacts. For example, you may subscribe a contact to a Voice
              Survey, but it's the middle of the night. In that case you might prefer the call to go out the next
              morning.
            </Typography>
          </Grid>
          <React.Fragment>
            <Grid item xs={2}>
              <Typography variant="subtitle1" style={{ color: 'rgba(0, 0, 0, 0.54)', paddingTop: '5px' }}>
                Call the contacts:
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={<Checkbox checked={anytime} onChange={toggleAnytime} value="checkedB" color="primary" />}
                label="Anytime"
              />
            </Grid>
          </React.Fragment>
        </Grid>
        {!anytime && (
          <React.Fragment>
            <Grid container spacing={1}>
              <Grid item xs={5} sx={{ marginBottom: 2 }}>
                <TimezonePicker
                  countriesTimezones={countriesTimezones}
                  countryCode={countryCode}
                  name="call-window"
                  timezone={timezone || ''}
                  timezones={timezones}
                  onChange={handleTimezoneChange}
                />
              </Grid>
            </Grid>
            {daysOfTheWeek.map((day) => (
              <SingleWindowDay
                key={day}
                label={day}
                day={pathOr([], [day], item.callWindow)}
                duplicateDay={duplicateDay}
                updateSlots={updateSlots}
              />
            ))}
          </React.Fragment>
        )}
        <Typography variant="subtitle2" className={classes.scheduleCaption}></Typography>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles()({
  scheduleCampaignText: {
    marginTop: '5px',
  },
  scheduleCaption: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: '10px',
  },
})

const hasWindow = (timezoneWindow: TCallWindow) =>
  reduce(
    (accum, element) => {
      if (element && !isEmpty(element)) {
        return true
      }
      return accum
    },
    false,
    values(omit(['timezone'], timezoneWindow)),
  )

const daysOfTheWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

export default CallWindow
