import { Button } from '@mui/material'
import { green, red } from '@mui/material/colors'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import { capitalizeFirstLetter } from '@/app/module/campaigns/helpers'

import PlanList from './list'
import PlanBlock from './plan-block'

const orgMap = ({ orgName, orgId, username, role, autoRecharge }) => [
  {
    label: 'Organization ',
    value: `${orgName} ${orgId && `(${orgId})`}`,
  },
  {
    label: 'Role',
    value: capitalizeFirstLetter(role),
  },
  {
    label: 'Email',
    value: username,
  },
  {
    label: 'Auto recharge',
    value: (
      <div style={{ display: 'flex' }}>
        {autoRecharge ? (
          <Typography
            className="auto-recharge-flag"
            variant="subtitle2"
            style={{
              color: green.A700,
            }}
          >
            ENABLED
          </Typography>
        ) : (
          <Typography
            className="auto-recharge-flag"
            variant="subtitle2"
            style={{
              color: red.A700,
            }}
          >
            DISABLED
          </Typography>
        )}
        &nbsp;&nbsp;(<Link href="/account/billing/pay">Manage</Link>)
      </div>
    ),
  },
]

const MyOrgInfo = ({ profile, activeOrg, isLoading = false, autoRecharge, onAction }) => {
  const { name: orgName = '', orgId = '', role = '' } = activeOrg
  const { firstName, username } = profile
  return (
    <PlanBlock
      isLoading={isLoading}
      action={
        <Button variant="contained" onClick={onAction}>
          Create Campaign
        </Button>
      }
      title={`Welcome ${firstName || ''}`}
    >
      <PlanList items={orgMap({ orgName, orgId, username, role, autoRecharge: autoRecharge.autoRecharge })} />
    </PlanBlock>
  )
}

export default MyOrgInfo
