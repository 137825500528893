import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import moment from 'moment-timezone'
import { filter, find, keys, path, pathOr, propEq, where } from 'ramda'
import React, { PureComponent } from 'react'
import { createSelector } from 'reselect'
import { withStyles } from 'tss-react/mui'

import { DateTime, Radio } from '@/app/component/atom/form'
import TimezonePicker from '@/app/component/atom/timezone-picker'
import Loading from '@/app/component/guard/loading'
import { validateContent } from '@/app/module/campaigns/campaign-validation-helpers'
import { TYPES } from '@/app/module/campaigns/definitions'
import { selectTimezones } from '@/app/module/campaigns/store/selectors'

const scheduleList = [
  {
    label: 'immediately',
    value: 'immediate',
  },
  {
    label: 'on a specific date and time',
    value: 'specific',
  },
]

const immediateMessage = ({ totalContacts, totalGroups, campaignTitle }) => (
  <React.Fragment>
    When you click the <strong>Launch Now</strong> button,{' '}
    {totalContacts + totalGroups === 0 ? (
      <React.Fragment> messages will be sent based on when you subscribe each contact to this campaign.</React.Fragment>
    ) : (
      <React.Fragment>
        all selected contacts ({totalContacts}){' '}
        {totalGroups > 0 && (
          <React.Fragment>(and all contacts in the {totalGroups} groups/ segments selected)</React.Fragment>
        )}{' '}
        will be sent the first message in this {campaignTitle} <strong>immediately</strong>.
      </React.Fragment>
    )}
    <br />
    <strong>You will no longer be able to edit this campaign.</strong>
    <br />
    If you want this campaign to launch at a later point in time, choose “on a specific date and time".
  </React.Fragment>
)

const scheduledMessage = ({ totalContacts, totalGroups, campaignTitle, scheduleTime, timezone }) => (
  <React.Fragment>
    When you click the <strong>Schedule for Later</strong> button, the campaign will be scheduled to go live on{' '}
    <strong>
      {scheduleTime} ({timezone})
    </strong>
    .<br />
    {totalContacts + totalGroups === 0 ? (
      <React.Fragment> Messages will be sent based on when you subscribe each contact to this campaign.</React.Fragment>
    ) : (
      <React.Fragment>
        All selected contacts ({totalContacts}){' '}
        {totalGroups > 0 && (
          <React.Fragment>(and all contacts in the {totalGroups} groups/ segments selected)</React.Fragment>
        )}{' '}
        will be sent the first message in this {campaignTitle} after this campaign goes live.
      </React.Fragment>
    )}
    <br />
    <strong>
      You will still be able to edit this campaign before it goes live by selecting "Cancel launch & return to draft" in
      this campaign's Actions menu.
    </strong>
  </React.Fragment>
)

const immediateDripMessage = ({ totalContacts, totalGroups, delay }) => (
  <React.Fragment>
    When you click the <strong>Launch Now</strong> button,{' '}
    {totalContacts + totalGroups === 0 ? (
      <React.Fragment> messages will be sent based on when you subscribe each contact to this campaign.</React.Fragment>
    ) : (
      <React.Fragment>
        all selected contacts ({totalContacts}){' '}
        {totalGroups > 0 && (
          <React.Fragment>(and all contacts in the {totalGroups} groups/ segments selected)</React.Fragment>
        )}{' '}
        will be sent the first message in this Drip Campaign <strong>{delay}</strong> after this campaign goes live.
        <br />
        All other messages will be sent as configured, relative to{' '}
        {delay === 'immediately' ? 'when the first message is sent' : 'the first message'}.
      </React.Fragment>
    )}
    <br />
    <strong>You will no longer be able to edit this campaign.</strong>
    <br />
    If you want this campaign to launch at a later point in time, choose “on a specific date and time".
  </React.Fragment>
)

const scheduledDripdMessage = ({ totalContacts, totalGroups, scheduleTime, timezone, delay }) => (
  <React.Fragment>
    When you click the <strong>Schedule for Later</strong> button, the campaign will be scheduled to go live on{' '}
    <strong>
      {scheduleTime} ({timezone})
    </strong>
    .<br />
    {totalContacts + totalGroups === 0 ? (
      <React.Fragment> Messages will be sent based on when you subscribe each contact to this campaign.</React.Fragment>
    ) : (
      <React.Fragment>
        All selected contacts ({totalContacts}){' '}
        {totalGroups > 0 && (
          <React.Fragment>(and all contacts in the {totalGroups} groups/ segments selected)</React.Fragment>
        )}{' '}
        will be sent this first message in this <strong>{delay}</strong> after this campaign goes live.
      </React.Fragment>
    )}
    <br />
    All other messages will be sent as configured, relative to when the first message is sent.
    <br />
    <strong>
      You will still be able to edit this campaign before it goes live by selecting "Cancel launch & return to draft" in
      this campaign's Actions menu.
    </strong>
  </React.Fragment>
)

const scheduleCaptions = (campaignOptions) => ({
  SMSBLAST: {
    immediate: immediateMessage(campaignOptions),
    specific: scheduledMessage(campaignOptions),
  },
  IVR: {
    immediate: immediateMessage(campaignOptions),
    specific: scheduledMessage(campaignOptions),
  },
  DRIP: {
    immediate: immediateDripMessage(campaignOptions),
    specific: scheduledDripdMessage(campaignOptions),
  },
})

const selectDelay = (item) => pathOr({}, ['variables', 'parts', 0, 'delta'], item)

const parseDelay = createSelector(
  selectDelay,
  ({ unit = 'day', value = '', dayOfWeek = '', time = '', timezone = '' }) => {
    if (!time) {
      if (unit === 'day' && value === 0) {
        return 'immediately'
      }
      return `${value} ${unit}${value > 1 ? 's' : ''}`
    }
    const theFirst = value < 1 ? 'the first ' : ''
    return `at ${`${unit === 'day' ? theFirst : ''}${moment(time, 'HH:mm').format('hh:mm A')}`} (${timezone})${
      unit === 'week' ? `, on ${theFirst}${dayOfWeek.charAt(0).toUpperCase()}${dayOfWeek.slice(1)}` : ''
    }${value > 0 ? `, ${value} ${unit}${value > 1 ? 's' : ''}` : ''}`
  },
)

const styles = () => ({
  scheduleCampaignText: {
    marginTop: '5px',
  },
  scheduleCaption: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: '10px',
  },
})

const getContactsCount = (contacts = {}, total = 0) =>
  contacts.allContactsAreSelected
    ? total - pathOr(0, ['includedContactIds', 'length'], contacts)
    : pathOr(0, ['includedContactIds', 'length'], contacts)

const getGroupsCount = (groups = {}, total = 0) =>
  !groups.allGroupsAreSelected
    ? pathOr(0, ['includedGroupIds', 'length'], groups)
    : total - pathOr(0, ['includedGroupIds', 'length'], groups)

const getMessagesCount = (messages = {}) =>
  Object.keys(messages).reduce((acc, id) => acc + (validateContent(messages[id]) ? 0 : 1), 0)

class CampaignConfirmClass extends PureComponent {
  constructor(props) {
    super(props)
    const {
      launch = {
        immediate: true,
        at: Date.now(),
      },
    } = props.item

    const tempCountryCode = keys(filter(where({ tzs: find(propEq('timezone', launch.timezone)) }))(props.timezones))[0]
    this.state = {
      anytime: true,
      totalContacts: 0,
      totalGroups: 0,
      totalSegments: 0,
      totalMessages: [],
      totalMessageTypes: [],
      isOpen: false,
      scheduleValue: launch.immediate ? 'immediate' : 'specific',
      country: tempCountryCode || props.countryCode,
      timezone: launch.timezone || props.timezone,
      timezones: selectTimezones(pathOr([], [tempCountryCode || props.countryCode, 'tzs'], props.timezones)),
      delta: {
        unit: 'day',
        value: 0,
      },
      senderIdsUpdated: false,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
    const { contacts, groups, segments, item } = this.props
    const totalContacts = getContactsCount(item.contacts, contacts.total)
    const totalGroups = getGroupsCount(item.contacts, groups.total)
    const totalSegments = getGroupsCount(pathOr({}, ['segments'], item.uiStore), segments.total)
    const totalMessages = getMessagesCount(pathOr([], ['variables', 'parts'], item))
    const isOpen = item.launchOpen === undefined ? true : item.launchOpen
    this.setState({
      totalContacts,
      totalGroups,
      totalSegments,
      totalMessages,
      isOpen,
      type: item.type,
      senderIdsUpdated: false,
    })
  }

  render() {
    const classes = withStyles.getClasses(this.props)
    const { item, onChange, messages, loading, orgId, timezones, countriesTimezones, published } = this.props

    const { loading: msgLoading = false, error } = messages

    const campaignType = pathOr('', ['type'], item)
    const { title: campaignTitle = '' } = TYPES[campaignType.toUpperCase()]
    const delay = parseDelay(item)

    return (
      <Loading isLoading={!orgId || (!loading ? msgLoading : false)} error={error}>
        {!published && (
          <Card style={{ marginBottom: '2rem' }}>
            <CardHeader title="Contacts" style={{ padding: '12px 24px' }} />
            <CardContent>
              <Typography variant="body1" gutterBottom={true}>
                Contacts added to this campaign: <b>{this.state.totalContacts}</b>
              </Typography>
              <Typography variant="body1" gutterBottom={true}>
                Groups/ Segments added to this campaign: <b>{this.state.totalGroups}</b>
              </Typography>
            </CardContent>
          </Card>
        )}

        {!published && (
          <Card style={{ marginBottom: '2rem' }}>
            <CardHeader title="Schedule this Campaign" style={{ padding: '12px 24px' }} />
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="body1" gutterBottom={true} className={classes.scheduleCampaignText}>
                    I want this campaign to launch:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Radio
                    id="schedule-campaign-radio"
                    row={true}
                    value={this.state.scheduleValue}
                    values={scheduleList}
                    onChange={({ value: scheduleValue }) => {
                      onChange({
                        ...item,
                        launch: {
                          ...item.launch,
                          immediate: scheduleValue === 'immediate',
                          ...(scheduleValue !== 'immediate' && {
                            at: moment().format('YYYY-MM-DDTHH:mm'),
                            timezone: this.state.timezone,
                          }),
                        },
                      })
                      this.setState({
                        scheduleValue,
                      })
                    }}
                  />
                </Grid>
                {this.state.scheduleValue === 'specific' && (
                  <React.Fragment>
                    <Grid item xs={2}>
                      <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                        Select a specific date and time
                      </Typography>
                      <DateTime
                        name="schedule-date-time"
                        clearable={false}
                        editable={true}
                        timezone={this.state.timezone}
                        value={moment.parseZone(path(['item', 'launch', 'at'], this.props)).format('YYYY-MM-DDTHH:mm')}
                        onChange={({ value }) => {
                          onChange({
                            ...item,
                            launch: {
                              ...item.launch,
                              at: value.format('YYYY-MM-DDTHH:mm:ss'),
                              timezone: this.state.timezone,
                            },
                          })
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TimezonePicker
                        countriesTimezones={countriesTimezones}
                        countryCode={this.state.country}
                        name="schedule-date-time"
                        timezone={this.state.timezone}
                        timezones={timezones}
                        onChange={(timezone, country) => {
                          this.setState({
                            country,
                            timezone,
                          })
                          onChange({
                            ...item,
                            launch: {
                              ...item.launch,
                              timezone,
                            },
                          })
                        }}
                      />
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Typography variant="caption" className={classes.scheduleCaption}>
                {pathOr(
                  '',
                  [campaignType.toUpperCase(), this.state.scheduleValue],
                  scheduleCaptions({
                    totalContacts: this.state.totalContacts,
                    totalGroups: this.state.totalGroups,
                    scheduleTime: moment
                      .parseZone(path(['item', 'launch', 'at'], this.props))
                      .format('MMM DD, YYYY [at] hh:mm A'),
                    timezone: this.state.timezone,
                    campaignTitle,
                    delay,
                  }),
                )}
              </Typography>
            </CardContent>
          </Card>
        )}
      </Loading>
    )
  }
}

const CampaignConfirm = withStyles(CampaignConfirmClass, styles)

export default CampaignConfirm
