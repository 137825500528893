import { FormControlLabel, Switch } from '@mui/material'
import React from 'react'

import SpeechTranscribeDialog from './speech-transcribe-dialog'

type Props = {
  useAi: boolean
  onChange: (value: boolean) => void
}

const SpeechTranscribeToggle: React.FC<Props> = ({ useAi, onChange }) => {
  const [transcribeOpen, setUseAiOpen] = React.useState(false)

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target
      if (checked) {
        setUseAiOpen(true)
      } else {
        onChange(e.target.checked)
      }
    },
    [onChange],
  )
  const handleConfirm = React.useCallback(() => {
    setUseAiOpen(false)
    onChange(true)
  }, [onChange])
  const handleClose = React.useCallback(() => {
    setUseAiOpen(false)
  }, [])

  return (
    <div>
      <FormControlLabel
        className="speech-transcribe-toggle"
        control={<Switch checked={useAi} onChange={handleChange} />}
        label="AI enhanced answer recognition"
      />
      <SpeechTranscribeDialog open={transcribeOpen} onClose={handleClose} onConfirm={handleConfirm} />
    </div>
  )
}

export default SpeechTranscribeToggle
