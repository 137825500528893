import { TIMEOUT_DEFAULT } from '@/app/module/campaigns/definitions'
import {
  LocalOnRetriesExhausted,
  LocalOnTimeout,
  OnRetriesExhausted,
  OnTimeout,
  SpeechSettings,
} from '@/app/module/campaigns/types'

import { fromFailure, fromTimeout, toFailure, toTimeout } from './response-configs-utils'

export type SpeechConfigs = {
  onTimeout: OnTimeout
  retries: number
  onRetriesExhausted: OnRetriesExhausted
  speechSettings: SpeechSettings
}

export type SpeechConfigsState = {
  onTimeout: LocalOnTimeout
  retries: number
  onRetriesExhausted: LocalOnRetriesExhausted
  speechSettings: SpeechSettings
}

export const fromConfigs = (configs: SpeechConfigs): SpeechConfigsState => ({
  onTimeout: fromTimeout(configs.onTimeout, configs.speechSettings),
  retries: configs.retries,
  onRetriesExhausted: fromFailure(configs.onRetriesExhausted),
  speechSettings: configs.speechSettings,
})

export const toConfigs = (state: SpeechConfigsState): SpeechConfigs => ({
  onTimeout: toTimeout(state.onTimeout),
  retries: state.retries,
  onRetriesExhausted: toFailure(state.onRetriesExhausted),
  speechSettings:
    state.onTimeout.timeoutSeconds !== state.speechSettings.timeoutSeconds // only update speech timeout when needed
      ? {
          ...state.speechSettings,
          timeoutSeconds: state.onTimeout.enabled ? state.onTimeout.timeoutSeconds || TIMEOUT_DEFAULT : undefined,
        }
      : state.speechSettings,
})
