import { Delete, FileDownloadOutlined } from '@mui/icons-material'
import { Box, Icon, IconProps, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import CustomTooltip from '@/app/component/atom/tooltip'
import { formatExportFunction, formatExportStatus } from '@/app/module/exports/helpers'
import { Export, ExportStatus } from '@/app/module/exports/types'
import { filterOrgReportFiles, getOrgReportFile } from '@/app/module/reports/utils'
import { dateTime } from '@/app/service/util'
import { BackendFile } from '@/app/types'

type Props = {
  index: number
  item: Export

  onDelete: (exportId: number) => Promise<void>
  onFileDownload: (exportId: number, filename: string) => Promise<void>
}

const ExportRow: React.FC<Props> = ({ index, item, onDelete, onFileDownload }) => {
  const history = useHistory()
  const { classes, cx } = useStyles()

  const files = React.useMemo(() => filterOrgReportFiles(item.files || []), [item.files])
  const orgReportFile = React.useMemo(() => getOrgReportFile(item.files || []), [item.files])

  const handleOrgReportView = React.useCallback(() => {
    if (!orgReportFile) {
      return
    }
    history.push(`/analytics/${item.id}?filename=${orgReportFile.filename}`)
  }, [item.id, orgReportFile, history])

  return (
    <Paper className={cx(classes.container, 'export-row')} data-testid={`export-row-${index}`}>
      <Grid container>
        <Grid className={classes.links} item xs={5} padding={2}>
          {!files.length && (
            <span className={classes.link}>
              <Typography variant="subtitle2">
                <em>{noFilesMap[item.status]}</em>
              </Typography>
            </span>
          )}
          {files.map((file: BackendFile) => {
            if (item.status !== 'ready') {
              return (
                <span key={file.filename} className={classes.link}>
                  {file.filename}
                </span>
              )
            }

            return (
              <CustomTooltip key={file.filename} title="Click to download">
                <Link
                  className={classes.link}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    onFileDownload(item.id, file.filename)
                  }}
                >
                  {file.filename} <FileDownloadOutlined />
                </Link>
              </CustomTooltip>
            )
          })}
        </Grid>
        <Grid item xs={2} padding={2}>
          {formatExportFunction(item.function)}
        </Grid>
        <Grid item xs={2} padding={2}>
          {dateTime(item.created)}
        </Grid>
        <Grid item xs={2} padding={2}>
          <Box display="flex" alignItems="center">
            <Icon color={statusColorMap[item.status] || 'warning'}>circle</Icon>&nbsp;{formatExportStatus(item.status)}
          </Box>
        </Grid>
        <Grid item xs={1} padding={2}>
          <CustomTooltip title="View report">
            <IconButton
              data-testid="view-report"
              sx={{
                visibility: orgReportFile ? 'visible' : 'hidden',
              }}
              onClick={handleOrgReportView}
            >
              <Icon>visibility</Icon>
            </IconButton>
          </CustomTooltip>
          <CustomTooltip title="Delete export">
            <IconButton data-testid="delete-export" onClick={() => onDelete(item.id)}>
              <Delete />
            </IconButton>
          </CustomTooltip>
        </Grid>
      </Grid>
    </Paper>
  )
}

const statusColorMap: Record<ExportStatus, IconProps['color']> = {
  ready: 'success',
  failed: 'error',
  queued: 'warning',
}

const noFilesMap: Record<ExportStatus, string> = {
  ready: 'No files available',
  failed: 'No files available',
  queued:
    'The export is in progress and no files are available yet. The files will be available once the export is complete.',
}

const useStyles = makeStyles()(() => ({
  container: {
    height: '100%',
  },
  link: {
    alignItems: 'flex-end',
    display: 'flex',
    fontSize: 16,
    whiteSpace: 'nowrap',
  },
  links: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export default React.memo(ExportRow)
