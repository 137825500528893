import { Icon, MenuItem, Select as MuiSelect, SelectChangeEvent, styled, Typography } from '@mui/material'
import React from 'react'

import CustomTooltip from '@/app/component/atom/tooltip'
import { Language } from '@/app/module/campaigns/types'

type Props = {
  activeSpeechLang: string
  defaultSpeechLang: string
  speechLanguages: Language[]

  onChange: (value: string) => void
}

const ResponseVoiceSpeechLanguageSelect: React.FC<Props> = ({
  activeSpeechLang,
  defaultSpeechLang,
  speechLanguages,
  onChange,
}) => {
  const handleChange = React.useCallback((e: SelectChangeEvent<string>) => onChange(e.target.value), [onChange])
  const renderValue = React.useCallback(
    (selected?: string) => {
      const found = speechLanguages.find((item) => item.value === selected)
      if (found) {
        return (
          <Typography>
            {found.name}
            {found.value === defaultSpeechLang && ' (default)'}
          </Typography>
        )
      }
      return <Typography>Select a language</Typography>
    },
    [defaultSpeechLang, speechLanguages],
  )

  return (
    <SelectContainer>
      <CustomTooltip title="Select speech recognition language">
        <Icon color="primary">interpreter_mode</Icon>
      </CustomTooltip>
      <Select
        data-testid="speech-language-select"
        id="speech-language-select"
        value={activeSpeechLang}
        variant="standard"
        onChange={handleChange}
        renderValue={renderValue}
      >
        {speechLanguages.map(({ name, value }) => (
          <SelectItem className="speech-language-select-item" key={value} value={value}>
            <SelectItemText>
              {name}
              {defaultSpeechLang === value && ' (default)'}
            </SelectItemText>
          </SelectItem>
        ))}
      </Select>
    </SelectContainer>
  )
}

const SelectContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
})

const Select = styled(MuiSelect<string>)({
  marginLeft: '10px',
  minWidth: '200px',
})

const SelectItem = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

const SelectItemText = styled(Typography)({
  marginRight: '5px',
})

export default ResponseVoiceSpeechLanguageSelect

export type { Props as ResponseVoiceSpeechLanguageSelectProps }
