import FormControlLabel from '@mui/material/FormControlLabel'
import Icon from '@mui/material/Icon'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import { once } from 'ramda'

import CountryFlag from '@/app/component/atom/flag'
import Autocomplete from '@/app/component/atom/form/autocomplete'
import IconText from '@/app/component/layout/icon-text'

const getCountries = once((countriesList) =>
  Object.keys(countriesList).map((name) => ({
    label: name,
    value: name,
    component: () => (
      <Typography>
        <CountryFlag code={countriesList[name]} />
        &nbsp;{name}
      </Typography>
    ),
  })),
)

export default ({
  countryValue,
  countryCodeError,
  countriesList,
  countriesOption,
  onCountryOptionChange,
  onCountryCodeChange,
}) => {
  const countries = getCountries(countriesList)
  return (
    <div>
      <FormControlLabel
        label="Single country"
        value="single"
        onChange={(e) => {
          e.persist?.()
          e.preventDefault()
          onCountryOptionChange({ value: e.target.value })
        }}
        control={<Radio checked={countriesOption === 'single'} color="primary" />}
      />
      <FormControlLabel
        label="Multiple countries"
        value="multi"
        onChange={(e) => {
          e.persist?.()
          e.preventDefault()
          onCountryOptionChange({ value: e.target.value })
        }}
        control={<Radio checked={countriesOption === 'multi'} color="primary" />}
      />
      {countriesOption === 'single' && (
        <Autocomplete
          name="uploads-country"
          label="Search for a country"
          editable={true}
          restricted={true}
          error={countryCodeError}
          values={countries}
          value={countryValue.input}
          onChange={({ name, value, error = '' }) => {
            onCountryCodeChange({
              name,
              error,
              value: {
                input: value,
                code: countriesList[value],
              },
            })
          }}
          onBlur={({ name, value, error = '' }) => {
            onCountryCodeChange({
              name,
              error,
              value: {
                input: value,
                code: countriesList[value],
              },
            })
          }}
        />
      )}
      {countriesOption === 'multi' && (
        <IconText>
          <Icon color="primary">info</Icon>
          <Typography>
            Please upload contacts only with phone numbers prefixed with the international country code
          </Typography>
        </IconText>
      )}
    </div>
  )
}
