import { pathOr } from 'ramda'
import React, { Component } from 'react'

import AudioEmpty from './empty'
import AudioVisualiser from './visualizer'

type Props = {
  analyser?: AnalyserNode
  isPlaying: boolean
}

type State = {
  audioData: Uint8Array
}

export default class AudioAnalyser extends Component<Props, State> {
  container: React.RefObject<HTMLDivElement>

  rafId = 0

  constructor(props: Props) {
    super(props)
    this.state = { audioData: new Uint8Array(0) }
    this.tick = this.tick.bind(this)
    this.container = React.createRef()
  }

  componentDidMount() {
    const { analyser } = this.props

    if (analyser) {
      this.initialize()
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isPlaying, analyser } = this.props

    if ((!prevProps.analyser && analyser && isPlaying) || (!prevProps.isPlaying && isPlaying && analyser)) {
      this.initialize()
    }

    if (analyser && prevProps.isPlaying && !isPlaying) {
      cancelAnimationFrame(this.rafId)
    }
  }

  initialize() {
    this.rafId = requestAnimationFrame(this.tick)
  }

  tick() {
    const { analyser, isPlaying } = this.props
    if (!analyser) {
      return
    }

    const dataArray = new Uint8Array(analyser.frequencyBinCount)
    analyser.getByteTimeDomainData(dataArray)
    this.setState({ audioData: dataArray })
    if (isPlaying) {
      this.rafId = requestAnimationFrame(this.tick)
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.rafId)
  }

  render() {
    const { isPlaying, analyser } = this.props

    return (
      <div
        ref={this.container}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {(!isPlaying || !analyser) && <AudioEmpty />}
        {isPlaying && (
          <AudioVisualiser
            width={pathOr(0, ['current', 'offsetWidth'], this.container)}
            height={pathOr(0, ['current', 'offsetHeight'], this.container)}
            audioData={this.state.audioData}
          />
        )}
      </div>
    )
  }
}
