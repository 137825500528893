import { useCallback, useRef } from 'react'

import { FacebookSignupEventData } from '@/app/module/phonenumbers/types'
import { useFacebookSdk } from '@/app/module/utils/facebook-sdk'
import { captureError } from '@/app/service/util/error'

// this handles the signup process with Meta and returns the waba_id
export function useEmbbedSignupWhatsapp(
  whatsappMetaOtp: false,
): [() => Promise<string>, () => void, { sdkStatus: { failed: boolean; loaded: boolean } }]
export function useEmbbedSignupWhatsapp(
  whatsappMetaOtp: true,
  phoneNumber: string,
): [() => Promise<string>, () => void, { sdkStatus: { failed: boolean; loaded: boolean } }]
export function useEmbbedSignupWhatsapp(whatsappMetaOtp: boolean, phoneNumber?: string) {
  const { launchEmbeddedSignup, failed, loaded } = useFacebookSdk()
  const eventHandlerRef = useRef<(event: MessageEvent<string>) => void>()

  const handleEmbeddedSignup = useCallback(
    () =>
      new Promise<string>((resolve, reject) => {
        const signupEventHandler = (event: MessageEvent<string>) => {
          try {
            if (!event.origin.endsWith('facebook.com') || typeof event.data !== 'string') {
              // ignore messages from other origins
              return
            }
            const eventData = JSON.parse(event.data) as FacebookSignupEventData
            if (eventData.type !== 'WA_EMBEDDED_SIGNUP') {
              // ignore messages that are not related to the signup process
              return
            }

            switch (eventData.event) {
              case 'FINISH':
              case 'FINISH_ONLY_WABA': {
                resolve(eventData.data.waba_id)
                return
              }

              case 'CANCEL': {
                reject(new Error('Cancelled'))
                return
              }

              case 'ERROR': {
                reject(eventData.data.error_message)
                return
              }

              default: {
                reject(new Error('Invalid event'))
              }
            }
          } catch (error) {
            captureError(error)
          }
        }

        eventHandlerRef.current = signupEventHandler
        window.addEventListener('message', signupEventHandler)
        launchEmbeddedSignup(whatsappMetaOtp, phoneNumber).catch(reject)
      }),
    [phoneNumber, whatsappMetaOtp, launchEmbeddedSignup],
  )

  const cleanup = useCallback(() => {
    if (eventHandlerRef.current) {
      window.removeEventListener('message', eventHandlerRef.current)
    }
  }, [])

  return [handleEmbeddedSignup, cleanup, { sdkStatus: { failed, loaded } }] as const
}
