import { DateTimePicker, DateTimePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment-timezone'
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'

const dateTimeFormat = 'YYYY-MM-DD HH:mm'

type Props<T extends FieldValues> = {
  name: Path<T>
  label?: string
  required?: boolean
  timezone?: string
} & Pick<DateTimePickerProps<Moment>, 'autoFocus' | 'disabled' | 'format'>

export function RHFDateTimePicker<T extends FieldValues>({
  name,
  label,
  required,
  timezone = moment.tz.guess(),
  ...props
}: Props<T>) {
  const { control } = useFormContext<T>()

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <DateTimePicker
            label={label}
            {...field}
            value={field.value ? moment.tz(field.value, timezone) : null}
            onChange={(date) => {
              try {
                field.onChange(date?.toISOString() || null)
              } catch {
                field.onChange(null)
              }
            }}
            format={dateTimeFormat}
            slotProps={{
              textField: {
                variant: 'standard',
                size: 'small',
                required,
                InputLabelProps: { shrink: true },
                error: !!fieldState.error,
                helperText: fieldState.error?.message,
                fullWidth: true,
                inputProps: {
                  'aria-label': name,
                },
                sx: { mb: 2 },
              },
            }}
            {...props}
          />
        )}
      />
    </LocalizationProvider>
  )
}
