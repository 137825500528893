import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import React from 'react'
import { DropzoneRef } from 'react-dropzone'

import Uploader from '@/app/component/atom/upload'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { FileUploadType } from '@/app/types'

import {
  Caption,
  EditBtn,
  EditOverlay,
  PlayBtn,
  PlayContainer,
  PlayOverlay,
  PreviewImage,
  RelativeContainer,
  UploadContainer,
} from './common'
import { videoAcceptList, videoExtensionList } from './media-types'

type Props = {
  canRegisterTemplate: boolean
  enableMediaCaption: boolean
  files: Record<string, any>
  personalizationList: PersonalizationType
  text: string
  video: string

  changeHandler: (value: { video?: string; text?: string }) => void
  getFile: (path: string) => Promise<any>
  saveHandler: (file: FileUploadType) => Promise<void>
}

const VideoMessage: React.FC<Props> = ({
  canRegisterTemplate,
  enableMediaCaption,
  files,
  personalizationList,
  text,
  video,

  changeHandler,
  getFile,
  saveHandler,
}) => {
  const videoUploadRef = React.useRef<DropzoneRef>(null)
  const videoPlayerRef = React.useRef<HTMLVideoElement>(null)
  const [videoLoading, setVideoLoading] = React.useState(false)
  const [videoFile, setVideoFile] = React.useState('')
  const [videoError, setVideoError] = React.useState(false)

  React.useEffect(() => {
    if (videoError) {
      setTimeout(() => {
        setVideoError(false)
      }, 3000)
    }
  }, [videoError])

  const downloadVideo = React.useCallback(async () => {
    setVideoLoading(true)
    const videoURL = await getFile(video)
    if (videoURL instanceof Error) {
      setVideoError(true)
    } else {
      setVideoFile(videoURL.url)
      setTimeout(() => {
        if (videoPlayerRef && videoPlayerRef.current) {
          videoPlayerRef.current.play()
        }
      }, 500)
    }
    setVideoLoading(false)
  }, [video, getFile])

  return (
    <>
      <UploadContainer style={{ display: video ? 'none' : 'flex' }}>
        <RelativeContainer>
          <Uploader
            accept={videoAcceptList}
            currentFile={videoFile}
            extensions={videoExtensionList}
            files={files}
            maxFileSize="16mb"
            namespace="whatsapp-video"
            uploadRef={videoUploadRef}
            onAccept={({ file }: { file: File }) => {
              saveHandler({
                file,
                filename: file.name,
                type: 'video',
              })
            }}
          />
        </RelativeContainer>
      </UploadContainer>
      <UploadContainer style={{ display: video ? 'flex' : 'none' }}>
        <RelativeContainer>
          {videoLoading ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            <>
              {videoFile ? (
                <video ref={videoPlayerRef} width="400" height="200" controls src={videoFile} />
              ) : (
                <>
                  <PreviewImage
                    src={files?.[`thumb/${video}`]?.url || ''}
                    alt="Video Preview"
                    onError={(e: any) => {
                      e.target.onerror = null
                      e.target.src = '/static/no-preview-available.svg'
                    }}
                  />
                  <PlayOverlay>
                    <PlayContainer>
                      <PlayBtn id="whatsapp-video-play-btn" onClick={downloadVideo}>
                        <Icon>play_arrow</Icon>
                      </PlayBtn>
                    </PlayContainer>
                  </PlayOverlay>
                </>
              )}
              <EditOverlay>
                <EditBtn
                  id="whatsapp-edit-btn"
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => videoUploadRef.current?.open()}
                  startIcon={<Icon>edit</Icon>}
                >
                  Replace
                </EditBtn>
              </EditOverlay>
              {videoError && <Alert severity="warning">Couldn't load the video. Please try again later.</Alert>}
            </>
          )}
        </RelativeContainer>
      </UploadContainer>
      <Caption
        enableMediaCaption={enableMediaCaption && (!video || canRegisterTemplate)}
        personalizationList={personalizationList}
        text={text}
        onChange={(updatedValue) => {
          changeHandler({
            text: updatedValue,
          })
        }}
      />
    </>
  )
}

export default VideoMessage
export type { Props as VideoMessageProps }
