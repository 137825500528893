import { styled } from '@mui/material'
import React, { ReactNode } from 'react'

export interface SidebarLayoutProps {
  sidebar: ReactNode
  content: ReactNode
}

const SidebarLayout: React.FC<SidebarLayoutProps> = ({ sidebar, content }) => {
  return (
    <Container>
      <SidebarContainer>{sidebar}</SidebarContainer>
      <ContentContainer>{content}</ContentContainer>
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  height: 'calc(100vh - 64px)', // Adjust based on header height
  width: '100%',
  overflow: 'hidden',
})

const SidebarContainer = styled('div')(({ theme }) => ({
  width: 240,
  backgroundColor: theme.palette.background.paper,
  borderRight: `1px solid ${theme.palette.divider}`,
  height: '100%',
  overflow: 'auto',
}))

const ContentContainer = styled('div')({
  flex: 1,
  padding: 16,
  height: '100%',
  overflow: 'auto',
})

export default SidebarLayout
