import cookies from 'js-cookie'
import { pathOr } from 'ramda'
import { Store } from 'redux'

import { cannot } from '@/app/helpers'
import { ERROR_EXPORT_CAMPAIGN_REPORT_SERVER, OTHER_UPDATED_CAMPAIGN } from '@/app/module/campaigns/definitions'
import ReminderCampaignClass from '@/app/module/campaigns/models/classes/reminder-campaign.class'
import { selectCampaignItem } from '@/app/module/campaigns/store/selectors'
import { LegacyCampaignItem } from '@/app/module/campaigns/types'
import { ACTION_DELETE_EXPORT_SUCCESS, ACTION_GET_EXPORTS_SUCCESS } from '@/app/module/exports/definitions'
import { Export } from '@/app/module/exports/types'
import { createNotification } from '@/app/module/notifications/store/actions'
import { OrgReportMimeType } from '@/app/module/reports/types'
import requestSpecs from '@/app/module/request'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { captureError } from '@/app/service/util/error'
import { BackendFile } from '@/app/types'
import http from '@/config/http'

const request = requestSpecs as any

export type CampaignUpdatedPayload = {
  type: 'campaign'
  campaign: {
    id: number
    name: string
    published: boolean
    open: boolean
    launched: boolean
    aborted: boolean
    archived: boolean
    deleted: boolean
    version: number
  }
}

export type ExportDonePayload = {
  type: 'exportDone'
  export: Export
}

export type ExportChangedPayload = {
  type: 'exportChanged'
  exportChanged: {
    id: number
    what: 'create' | 'update' | 'delete'
  }
}

export const handleCampaignUpdated = (store: Store, payload: CampaignUpdatedPayload) => {
  const campaignId = payload.campaign.id
  const state = store.getState()
  const currentCampaign = selectCampaignItem(state) as LegacyCampaignItem | ReminderCampaignClass | {}
  const loading = pathOr(false, ['campaigns', 'item', 'loading'], state)
  if (
    !('id' in currentCampaign) ||
    loading ||
    currentCampaign.id !== campaignId ||
    currentCampaign.version >= payload.campaign.version
  ) {
    return
  }

  store.dispatch(
    createNotification({
      'campaign-updated': {
        type: 'warning',
        message: OTHER_UPDATED_CAMPAIGN,
      },
    }),
  )
}

/**
 * This function is used to filter out the org report json file\
 */
export const handleExportFiles = (exp: Export | undefined) => {
  if (!exp || !exp.id) {
    return []
  }

  switch (exp.function) {
    case 'campreport':
    case 'cxupload':
    case 'smsreport':
      return exp.files || []

    case 'orgspend':
      return (exp.files || []).filter((file: BackendFile) => file.contentType === OrgReportMimeType.Spend)

    default:
      return cannot(exp.function)
  }
}

export const createExportNotification = async (
  store: Store,
  orgId: number,
  token: string,
  exp: Export,
  files: BackendFile[],
) => {
  switch (exp.function) {
    case 'campreport':
    case 'cxupload':
    case 'smsreport': {
      const paths = files.map((file: BackendFile) =>
        http.campaigns.exportReport.getSignablePath({
          orgId,
          exportId: exp.id,
          name: file.filename,
        }),
      )

      const data = {
        paths,
      }
      const res = await request.auth.sign({ token, item: data })

      const urls = paths.map((p) => http.campaigns.exportReport.getDownloadPath({ signedPath: p, urlToken: res[p] }))
      const hasMany = urls.length > 1
      store.dispatch(
        createNotification({
          'export-report-success': {
            type: 'success',
            disableAutoHide: true,
            links: urls.map((url, i) => ({
              name: `{{link_${i}}}`,
              title: !hasMany ? 'Download report file.' : `Download report file ${i + 1}.`,
              onClick: (e) => {
                e.preventDefault()
                window.open(url, '_blank')
              },
            })),
            message: `Your report is ready. Click the link${
              hasMany ? 's' : ''
            } below to download the report file${hasMany ? 's' : ''}. ${urls.map((_, i) => `{{link_${i}}}`).join('')}`,
          },
        }),
      )
      return
    }

    case 'orgspend': {
      const urls = files.map((file) => `/analytics/${exp.id}?filename=${file.filename}`)
      const hasMany = urls.length > 1
      store.dispatch(
        createNotification({
          'export-report-success': {
            type: 'success',
            disableAutoHide: true,
            links: urls.map((url, i) => ({
              name: `{{link_${i}}}`,
              title: !hasMany ? 'View report file.' : `View report file ${i + 1}.`,
              onClick: (e) => {
                e.preventDefault()
                window.open(url, '_self')
              },
            })),
            message: `Your report is ready. Click the link${
              hasMany ? 's' : ''
            } below to view the report file${hasMany ? 's' : ''}. ${urls.map((_, i) => `{{link_${i}}}`).join('')}`,
          },
        }),
      )
      return
    }

    default:
      cannot(exp.function)
  }
}

export const handleExportDone = async (store: Store, payload: ExportDonePayload) => {
  try {
    const state = store.getState()
    const orgId = selectOrgId(state)
    const token = cookies.get('authToken')

    if (!orgId || !token) {
      return
    }
    const files = handleExportFiles(payload.export)

    if (!files?.length) {
      return
    }

    store.dispatch({
      type: ACTION_GET_EXPORTS_SUCCESS,
      value: {
        exports: [payload.export],
      },
    })

    if (payload.export.status === 'failed') {
      store.dispatch(
        createNotification({
          'export-report-error': {
            type: 'error',
            message: ERROR_EXPORT_CAMPAIGN_REPORT_SERVER,
          },
        }),
      )
    } else {
      await createExportNotification(store, orgId, token, payload.export, files)
    }
  } catch (error) {
    captureError(error)
    store.dispatch(
      createNotification({
        'export-report-error': {
          type: 'error',
          message: ERROR_EXPORT_CAMPAIGN_REPORT_SERVER,
        },
      }),
    )
  }
}

export const handleExportChanged = (store: Store, payload: ExportChangedPayload) => {
  const exportId = pathOr<number>(0, ['exportChanged', 'id'], payload)
  const changeReason = pathOr<string>('', ['exportChanged', 'what'], payload)

  if (!exportId) {
    return
  }

  switch (changeReason) {
    case 'delete': {
      store.dispatch({
        type: ACTION_DELETE_EXPORT_SUCCESS,
        value: exportId,
      })
      store.dispatch(createNotification({})) // clear notifications
      break
    }

    default: // Ignoring create, update for now
      break
  }
}
