import React from 'react'

import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Exports from '@/app/module/exports'
import Notifications from '@/app/module/notifications'

type Props = {
  name: string
  routes: any[]
  match: any
}

const ExportsPage: React.FC<Props> = ({ match }) => (
  <AuthGuard path={match.path} url={match.url}>
    <Layout title="Exports - engageSPARK">
      <AuthHeader currentPage={PAGE.EXPORTS.INDEX} />
      <Exports />
    </Layout>
    <Notifications />
  </AuthGuard>
)

export default ExportsPage
