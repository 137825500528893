import React from 'react'

import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import { openIntercom } from '@/app/service/intercom/utils'

type Props = {
  onClose: () => void
}

const PurchaseUnavailableDialog: React.FC<Props> = ({ onClose }) => (
  <ConfirmationDialog
    deleteButtonText="Contact Support"
    icon="priority_high"
    hideCancel
    isOpen
    text="Unfortunately, you do not have sufficient funds and are unable to add credit cards at this time. Please contact our support team for further assistance."
    title="Purchase Unavailable"
    onClose={onClose}
    onConfirm={() => {
      onClose()
      openIntercom('show')
    }}
  />
)

export default PurchaseUnavailableDialog
