import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import React from 'react'
import { DropzoneRef } from 'react-dropzone'

import Uploader from '@/app/component/atom/upload'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { FileUploadType } from '@/app/types'

import { Caption, EditOverlay, PreviewImage, RelativeContainer, UploadContainer } from './common'
import { imageAcceptList, imageExtensionList } from './media-types'

type Props = {
  enableMediaCaption: boolean
  files: Record<string, any>
  image: string
  personalizationList: PersonalizationType
  text: string

  changeHandler: (value: { image?: string; text?: string }) => void
  saveHandler: (file: FileUploadType) => Promise<void>
}

const ImageMessage: React.FC<Props> = ({
  enableMediaCaption,
  files,
  image,
  personalizationList,
  text,

  changeHandler,
  saveHandler,
}) => {
  const imageUploadRef = React.useRef<DropzoneRef>(null)
  const imageFile = image ? files?.[image]?.url || '' : ''

  return (
    <>
      <UploadContainer style={{ display: imageFile ? 'none' : 'flex' }}>
        <RelativeContainer>
          <Uploader
            accept={imageAcceptList}
            currentFile={imageFile}
            extensions={imageExtensionList}
            files={files}
            namespace="whatsapp-image"
            uploadRef={imageUploadRef}
            onAccept={({ file }: { file: File }) => {
              saveHandler({
                file,
                filename: file.name,
                type: 'image',
              })
            }}
          />
        </RelativeContainer>
      </UploadContainer>
      <UploadContainer style={{ display: imageFile ? 'flex' : 'none' }} onClick={() => imageUploadRef.current?.open()}>
        <RelativeContainer>
          <PreviewImage alt="WhatsApp" src={imageFile} />
          <EditOverlay>
            <Button variant="contained" size="small" color="secondary" startIcon={<Icon>edit</Icon>}>
              Edit
            </Button>
          </EditOverlay>
        </RelativeContainer>
      </UploadContainer>
      <Caption
        enableMediaCaption={enableMediaCaption}
        personalizationList={personalizationList}
        text={text}
        onChange={(updatedValue) => {
          changeHandler({
            text: updatedValue,
          })
        }}
      />
    </>
  )
}

export default ImageMessage
export type { Props as ImageMessageProps }
