import React from 'react'

import OverviewTable from '@/app/component/atom/overview-table'
import { VoiceOverview } from '@/app/module/campaigns/types'
import { formatDuration, getPercentage } from '@/app/service/util/format'

type Props = {
  data: VoiceOverview
}

const VoiceOverviewTable: React.FC<Props> = ({ data }) => {
  const tableData = React.useMemo(
    () => [
      {
        name: 'Voice calls made',
        value: data.totalEgress,
      },
      {
        name: 'Calls picked up (% of made)',
        value: `${data.answered} (${getPercentage(data.answered, data.totalEgress)})`,
      },
      {
        name: 'Voicemails detected (% of picked up)',
        value: `${data.amdDetected} (${getPercentage(data.amdDetected, data.answered)})`,
      },
      {
        name: 'Calls not picked up (% of made)',
        value: `${data.notAnswered} (${getPercentage(data.notAnswered, data.totalEgress)})`,
      },
      {
        name: 'Calls failed (% of made)',
        value: `${data.failedToRing} (${getPercentage(data.failedToRing, data.totalEgress)})`,
      },
      {
        name: 'Call duration (average / median)',
        value: `${formatDuration(data.answeredSecondsAvg)} / ${formatDuration(data.answeredSecondsMedian)}`,
      },
    ],
    [data],
  )

  return <OverviewTable data={tableData} title="Voice Calls" />
}

export default VoiceOverviewTable
