import { Card, CardActions, CardHeader, cardHeaderClasses, CardProps, Icon, styled, Typography } from '@mui/material'
import React from 'react'

import CustomTooltip from '@/app/component/atom/tooltip'
import { Plan } from '@/app/module/plans/types'
import { getPlanCountries } from '@/app/module/plans/utils'
import { numberWithCommas } from '@/app/service/util/format'

import PlanPrice from './plan-price'
import SubscribeButton, { PlanAction } from './subscribe-button'

type Props = {
  chargeYearly: boolean
  currentPlanBilling?: 'monthly' | 'yearly'
  customButtonTooltip: React.ReactNode
  index: number
  isActivePlan: boolean
  isOwner: boolean
  lastIndex: number
  plan: Plan
  selectedPlanIndex: number

  checkPurchaseAvailable: (amount: number) => boolean
  onSelect: (plan: Plan, action: PlanAction) => void
}

const PlanItem: React.FC<Props> = ({
  chargeYearly,
  currentPlanBilling,
  customButtonTooltip,
  index,
  isActivePlan,
  isOwner,
  lastIndex,
  plan,
  selectedPlanIndex,
  checkPurchaseAvailable,
  onSelect,
}) => {
  return (
    <StyledCard index={index} lastIndex={lastIndex}>
      <StyledCardHeader
        action={isActivePlan && <Icon>star</Icon>}
        title={plan.label}
        subheader={isActivePlan ? 'Active Plan' : ''}
        subheaderTypographyProps={{ align: 'center' }}
        titleTypographyProps={{ align: 'center' }}
      />
      <CardContent>
        <PlanPrice chargeYearly={chargeYearly} priceMonthly={plan.priceMonthly} priceYearly={plan.priceYearly} />
        <div>
          <FeatureRow>
            <Typography variant="body1">
              Total Contacts:
              <FeatureHighlight className="feature-highlight">
                {numberWithCommas(plan.storedContactsMax)}
              </FeatureHighlight>
              <CustomTooltip interactive title={<>The maximum number of contacts that can be stored in the account</>}>
                <Icon style={{ verticalAlign: 'middle', marginBottom: 5, marginLeft: 5 }} fontSize="small">
                  info
                </Icon>
              </CustomTooltip>
            </Typography>
          </FeatureRow>
          <FeatureRow>
            <React.Fragment>
              <Typography variant="body1">
                User Accounts:
                <FeatureHighlight className="feature-highlight">{plan.usersIncluded}</FeatureHighlight>
              </Typography>
            </React.Fragment>
          </FeatureRow>
          <FeatureRow>
            <React.Fragment>
              <Typography variant="body1">
                Countries:
                <FeatureHighlight className="feature-highlight">
                  {getPlanCountries(plan.countriesIncluded).value}
                </FeatureHighlight>
              </Typography>
            </React.Fragment>
          </FeatureRow>
        </div>
      </CardContent>
      <CardActions>
        <CustomTooltip title={customButtonTooltip}>
          <div style={{ width: '100%' }}>
            <SubscribeButton
              chargeYearly={chargeYearly}
              currentPlanBilling={currentPlanBilling}
              currentPlanIndex={selectedPlanIndex}
              isOwner={isOwner}
              plan={plan}
              planIndex={index}
              checkPurchaseAvailable={checkPurchaseAvailable}
              onClick={onSelect}
            />
          </div>
        </CustomTooltip>
      </CardActions>
    </StyledCard>
  )
}

const getCardBorder = (index: number, lastIndex: number) => {
  if (index === lastIndex) {
    return {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }
  }

  if (index === 0) {
    return {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    }
  }

  return {
    borderRadius: 0,
  }
}

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => !['index', 'lastIndex'].includes(prop as string),
})<CardProps & { index: number; lastIndex: number }>(({ index, lastIndex }) => ({
  height: 'auto',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  ...getCardBorder(index, lastIndex),
}))

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  height: '63px',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',

  [cardHeaderClasses.subheader]: {
    color: '#fff',
  },
}))

const CardContent = styled('div')({
  backgroundColor: 'rgb(245, 245, 245)',
  height: '100%',
  color: 'rgba(0, 0, 0, 0.54)',
  padding: '10px 1px',
})

const FeatureRow = styled('div')({
  display: 'flex',
  margin: '5px 10px',
})

const FeatureHighlight = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  marginLeft: 5,
}))

export default PlanItem
