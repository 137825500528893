import { Button, Typography } from '@mui/material'
import React from 'react'

import { SelectOption } from '@/app/types'

import CampaignsFilterDialog from './campaigns-filter-dialog'

type Props = {
  disabled?: boolean
  loading: boolean
  options: SelectOption<string>[]
  value: number[]
  onCampaignsSearch?: (search: string) => void
  onSave: (value: number[]) => void
}

const ellipsisify = (text: number[] | string[], limit: number) => {
  return text.length > limit ? `${text.slice(0, limit).join(', ')}...` : text.join(', ')
}

const OrganizationReportCampaignsFilter: React.FC<Props> = ({
  disabled,
  loading,
  options,
  value,
  onCampaignsSearch,
  onSave,
}) => {
  const [open, setOpen] = React.useState(false)

  const stringCampaignOptions = React.useMemo(
    () =>
      options.map((option) => ({
        ...option,
        value: String(option.value),
      })),
    [options],
  )
  const selectedCampaignsText = React.useMemo(() => {
    if (!value.length) {
      return 'Include spending from all campaigns.'
    }

    if (value.length === 1) {
      return (
        <span>
          Only include spending from <b>1 campaign:</b> {value[0]}.
        </span>
      )
    }

    return (
      <span title={` Only include spending from ${value.length} campaigns: ${value.join(', ')}`}>
        Only include spending from <b>{value.length} campaigns:</b> {ellipsisify(value, 3)}
      </span>
    )
  }, [value])

  const handleClose = () => setOpen(false)
  const handleSave = (v: number[]) => {
    onSave(v)
    setOpen(false)
  }

  return (
    <div>
      <Typography variant="subtitle2">
        Campaigns: {selectedCampaignsText}
        {!disabled && (
          <Button color="primary" size="small" variant="text" onClick={() => setOpen(true)}>
            Edit
          </Button>
        )}
        {!disabled && !!value.length && (
          <Button color="primary" size="small" variant="text" onClick={() => onSave([])}>
            Clear
          </Button>
        )}
      </Typography>
      {!disabled && open && (
        <CampaignsFilterDialog
          loading={loading}
          options={stringCampaignOptions}
          value={value}
          onCampaignsSearch={onCampaignsSearch}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
    </div>
  )
}

export default OrganizationReportCampaignsFilter
