import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { compose, equals, indexBy, insert, isEmpty, map, omit, pathOr, prop } from 'ramda'
import React, { Component } from 'react'
import { v1 as uuid } from 'uuid'

import DeleteDialog from '@/app/component/atom/delete-dialog'
import TablePagination from '@/app/component/atom/table-pagination'
import { TYPES } from '@/app/module/campaigns/definitions'
import { welcomeHasContent } from '@/app/module/campaigns/helpers'
import ReminderCampaignClass, {
  isReminderCampaign,
} from '@/app/module/campaigns/models/classes/reminder-campaign.class'
import isCampaignClass from '@/app/module/campaigns/models/guard'
import { countActionsInCallResult } from '@/app/module/campaigns/utils/actions/list'
import { fromMessage, toMessage } from '@/app/module/campaigns/utils/part-content'
import { duplicateMessage } from '@/app/module/campaigns/utils/part-content/duplicate'

import Accordion from './components/accordion'
import CallResultActions from './components/call-result-actions'
import { findJumpsSource, selectCampaignTypeContent } from './content'
import { contentTypeList, pageSizeValue } from './definitions'
import MessageWrapper from './message-wrapper'
import { NoMessage } from './no-message'
import { getDefaultMessageType } from './utils/default-message'
import { getEmptyMessage } from './utils/empty-message'
import WelcomeMessage from './welcome-message'

const generateDeleteText = (foundParts) => (
  <React.Fragment>
    <div>
      This question cannot be deleted because there are still <strong>“Jump to Question”</strong> actions with this
      question as target. Please change or delete the actions in these questions to be able to delete this question:
    </div>
    <div
      style={{
        marginTop: '30px',
      }}
    >
      {foundParts.map((part, i) => (
        <Typography
          key={i}
          style={{
            fontWeight: 600,
          }}
        >
          {part}
        </Typography>
      ))}
    </div>
  </React.Fragment>
)

const getWelcomeData = (props) => {
  const campaignContent = selectCampaignTypeContent(props)

  if (!campaignContent.welcome) {
    return undefined
  }

  return {
    [campaignContent.welcomeType]: pathOr(null, ['variables', 'welcome'], props.item),
    hasQuestion: false,
    id: uuid(),
    type: campaignContent.welcomeKey,
  }
}

class CampaignDripContent extends Component {
  state = {
    deleteConfirm: false,
    conditions: false,
    deleteText: null,
    toDelete: null,
    changeWarn: false,
    voicePlayingId: null,
    page: 0,
    pageSize: 10,
    type: pathOr('drip', ['item', 'type'], this.props),
    welcome: getWelcomeData(this.props),
    messages: getMessages(this.props.item),
    ordered: getOrdered(this.props.item),
  }

  defaultArray = []

  defaultObject = {}

  // to prevent rerunning guard function on every update
  CONTENT_CACHE = {}

  unmounting = false

  scrollElement = null

  scrolling = null

  scrollTimeout = null

  static validate() {
    return true
  }

  componentDidMount() {
    const { activePart, item, type, template, timezone } = this.props
    this.firstMessageRef = React.createRef()
    if (isEmpty(pathOr({}, ['variables', 'parts'], item))) {
      const defaultMessageType = getDefaultMessageType(type, template)

      if (defaultMessageType) {
        this.addMessage(0, getEmptyMessage(type, defaultMessageType, timezone, 0))
      }
    }

    if (activePart) {
      this.scrollToMessage(activePart)
    }

    this.scrollElement = document.getElementById('scrollable-area')
    this.scrollElement?.addEventListener('scroll', this.handleContainerScroll)
  }

  componentDidUpdate(prevProps) {
    const { activePart, item, setActivePart } = this.props
    const { page, pageSize } = this.state

    // for EDIT cases where the item's request gets received after mount
    if (prevProps.item.id !== item.id) {
      this.setState({
        messages: getMessages(item),
        ordered: getOrdered(item),
      })
    } else {
      // for reorder messages case
      const previousOrdered = getOrdered(prevProps.item)
      const currentOrdered = getOrdered(item)

      if (!equals(previousOrdered, currentOrdered)) {
        let newPage = page
        // if the active part is not in current page (moved to another page), set the page to its new page
        if (activePart) {
          const index = currentOrdered.indexOf(activePart)
          newPage = Math.floor(index / pageSize)
        }

        this.setState({
          messages: getMessages(item),
          ordered: currentOrdered,
          page: newPage,
        })
      }
    }

    if (activePart) {
      if (prevProps.activePart !== activePart) {
        if (!this.scrolling) {
          this.scrollToMessage(activePart)
        }
      } else {
        const pageMessages = this.getPageMessage()
        if (!pageMessages.includes(activePart)) {
          setActivePart(pageMessages[0])
        }
      }
    }
  }

  componentWillUnmount() {
    this.unmounting = true
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout)
    }
    if (this.scrollElement) {
      this.scrollElement.removeEventListener('scroll', this.handleContainerScroll)
    }
  }

  handleContainerScroll = () => {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout)
    }
    this.scrolling = true

    this.scrollTimeout = setTimeout(() => {
      this.scrolling = false
    }, 50)

    if (!this.scrollElement) {
      return
    }
    const messages = this.getPageMessage()
    const messageInfos = messages
      .map((id) => {
        const elem = document.getElementById(`message-${id}`)
        if (!elem) {
          return null
        }
        const rect = elem.getBoundingClientRect()
        return {
          id,
          rect,
        }
      })
      .filter(Boolean)
    if (!messageInfos.length) {
      return
    }
    const containerRect = this.scrollElement.getBoundingClientRect()
    const closest = messageInfos.reduce((lastClosest, info, index) => {
      if (!lastClosest) {
        return info
      }

      // if scrolled to the bottom, set the last item as active
      const containerScrollHeight = this.scrollElement.scrollHeight
      const containerScrollTop = this.scrollElement.scrollTop
      const snapThreshold = 1.05
      const remainingScroll = containerScrollHeight - containerScrollTop
      const remainingThreshold = remainingScroll / containerRect.height
      if (index === messageInfos.length - 1 && remainingThreshold <= snapThreshold) {
        return info
      }

      const lastTop = lastClosest.rect.y
      const lastBottom = lastClosest.rect.y + lastClosest.rect.height

      const containerTop = containerRect.y
      const containerBottom = containerRect.y + containerRect.height

      // check if the last item still visible
      const isTopEdgeVisible = lastTop >= containerTop && lastTop <= containerBottom
      const isBottomVisible = lastBottom >= containerTop && lastBottom <= containerBottom
      const isWrappedOver = lastTop <= containerTop && lastBottom >= containerBottom // top is above viewport and bottom is below viewport

      const isVisible = isTopEdgeVisible || isBottomVisible || isWrappedOver

      if (isVisible) {
        return lastClosest
      }
      return info
    }, undefined)

    this.props.setActivePart(closest.id)
  }

  scrollToMessage = (id) => {
    if (this.scrolling) {
      return
    }

    const index = this.state.ordered.indexOf(id)
    const page = Math.floor(index / this.state.pageSize)
    if (index > -1) {
      if (page !== this.state.page) {
        this.setState(
          {
            page,
          },
          () => {
            document.getElementById(`message-${id}`)?.scrollIntoView()
            this.focusInput(id)
          },
        )
      } else {
        document.getElementById(`message-${id}`)?.scrollIntoView()
        this.focusInput(id)
      }
    }
  }

  focusInput = (id) => {
    setTimeout(() => {
      document.querySelector(`#message-${id} #send-message-text`)?.focus({
        preventScroll: true,
      })
    }, 0)
  }

  changeHandler = (value = {}, changed = true) => {
    this.update(({ messages, ordered }) => ({
      ordered,
      messages: {
        ...messages,
        [value.id]: {
          ...messages[value.id],
          ...value,
          changed,
        },
      },
    }))
  }

  welcomeChangeHandler = (value = {}) => {
    const { item, onChange } = this.props
    const campaignContent = selectCampaignTypeContent(this.props)
    const content = welcomeHasContent(campaignContent, value)
    onChange({
      ...item,
      variables: {
        ...item.variables,
        welcome: content
          ? {
              ...pathOr({}, [campaignContent.welcomeType], value),
              ...((pathOr('drip', ['type'], item) === 'smssurvey' ||
                pathOr('drip', ['type'], item) === 'whatsappsurvey') && {
                language: 'en',
              }),
            }
          : null,
      },
    })
    this.setState({
      welcome: value,
    })
  }

  callResultActionsChangeHandler = ({ value = [] }) => {
    const { item, onChange } = this.props
    onChange({
      ...item,
      variables: {
        ...item.variables,
        callResultActions: value,
      },
    })
  }

  getFileHandler = (msgId, file) => {
    const { token, orgId, getFile } = this.props

    return getFile({
      token,
      orgId,
      msgId,
      file,
    })
  }

  saveFileHandler = (msgId, { file, filename, filter, type }) => {
    const { token, orgId, saveFile } = this.props

    return saveFile({
      token,
      orgId,
      msgId,
      item: {
        file,
        filename,
        filter,
        type,
      },
    })
  }

  setDefaultUpload = (defaultUpload) => {
    const { item, onChange } = this.props
    onChange({
      ...item,
      uiStore: {
        ...item.uiStore,
        defaultUpload,
      },
    })
  }

  update(fn) {
    const { item, onChange } = this.props
    const { messages = {}, ordered = [] } = fn(this.state)

    if (this.unmounting) {
      onChange({
        ...item,
        variables: {
          ...item.variables,
          parts: isReminderCampaign(item)
            ? item.fromMessages(messages, ordered)
            : ordered.map((id) => fromMessage(messages[id])),
        },
      })
    } else {
      this.setState(
        {
          messages,
          ordered,
        },
        () => {
          onChange({
            ...item,
            variables: {
              ...item.variables,
              parts: isReminderCampaign(item)
                ? item.fromMessages(messages, ordered)
                : ordered.map((id) => fromMessage(messages[id])),
            },
          })
        },
      )
    }
  }

  addMessage = (index, message) => {
    const ensuredIndex = index ?? 0
    this.props.addNewItem({
      id: message.id,
    })
    this.update(({ messages, ordered }) => ({
      messages: {
        ...messages,
        [message.id]: message,
      },
      ordered: [...ordered.slice(0, ensuredIndex), message.id, ...ordered.slice(ensuredIndex)],
    }))
  }

  checkForJumps = ({ id }) => {
    if (this.props.item.type === 'drip') {
      this.removeMessage(id)
    } else {
      const parts = pathOr([], ['item', 'variables', 'parts'], this.props)
      const foundParts = findJumpsSource(id, parts)
      if (foundParts.length) {
        this.setState({
          deleteConfirm: true,
          toDelete: id,
          deleteText: generateDeleteText(foundParts),
        })
      } else {
        this.removeMessage(id)
      }
    }
  }

  removeMessage = (id) => {
    this.update(({ messages, ordered }) => {
      return {
        messages: omit([id], messages),
        ordered: ordered.filter((msgId) => msgId !== id),
      }
    })
  }

  duplicateMessage = (id) => {
    this.update(({ messages, ordered }) => {
      const { item, addNewItem } = this.props

      const index = ordered.indexOf(id)
      const newId = uuid()
      addNewItem({
        id: newId,
      })
      const message = messages[id]
      const newOrdered = insert(index + 1, newId, ordered)

      if (isReminderCampaign(item)) {
        return {
          messages: {
            ...messages,
            [newId]: ReminderCampaignClass.duplicateMessage(message),
          },
          ordered: newOrdered,
        }
      }

      const duplicatedMessage = duplicateMessage(this.state.type, message, newId)
      return {
        messages: {
          ...messages,
          [newId]: duplicatedMessage,
        },
        ordered: newOrdered,
      }
    })
  }

  renderIVRCallResultActions = (children) => {
    const callResultActionsCount = countActionsInCallResult(
      pathOr([], TYPES[this.state.type.toUpperCase()].callResultActionsPath, this.props.item),
    )
    return (
      <Accordion
        display={callResultActionsCount > 0}
        placeholder="Call Result Actions"
        title="Click here to specify what actions we should take if the contact does/ doesn't answer the call"
      >
        {children}
      </Accordion>
    )
  }

  renderWelcome = () => {
    const campaignContent = selectCampaignTypeContent(this.props)

    if (!campaignContent.welcome || this.state.page) {
      return null
    }

    const { item, messages } = this.props
    const { welcome } = this.state

    const welcomeData = {
      [campaignContent.welcomeType]: pathOr(null, ['variables', 'welcome'], item),
    }
    const content = welcomeHasContent(campaignContent, welcomeData)

    if (!content) {
      // welcome is deprecated
      return null
    }

    return (
      <WelcomeMessage
        {...this.props}
        campaignType={this.state.type}
        content={content}
        personalizationList={messages.personalizationList || this.defaultObject}
        defaultLanguage={pathOr('', ['uiStore', 'defaultLanguage'], this.props.item)}
        languages={pathOr(this.defaultArray, ['uiStore', 'languages'], this.props.item)}
        welcome={welcome}
        getFile={this.getFileHandler}
        onChange={this.welcomeChangeHandler}
        onMicAccessError={this.props.onMicAccessError}
        onSaveConditions={this.saveConditionsHandler}
        saveFile={this.saveFileHandler}
        setDefaultUpload={this.setDefaultUpload}
        setPlayingId={this.setPlayingHandler}
      />
    )
  }

  closeHandler = (msgId) => this.checkForJumps({ id: msgId })

  createMessageHandler = (index, row, message) => {
    this.addMessage(index, message)
    if (row === this.state.pageSize - 1) {
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => window.scrollTo(0, this.firstMessageRef.current.offsetTop),
      )
    }
  }

  duplicateMessageHandler = (id, row) => {
    if (row === this.state.pageSize - 1) {
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => window.scrollTo(0, this.firstMessageRef.current.offsetTop),
      )
    }
    this.duplicateMessage(id)
  }

  saveConditionsHandler = (item) => {
    this.changeHandler(item)
    this.setState({ conditions: null })
  }

  setPlayingHandler = (val = null) => {
    this.setState({
      voicePlayingId: val,
    })
  }

  pageMessages = []

  getPageMessage = () => {
    const { ordered, page, pageSize } = this.state

    const newPageMessages = ordered.slice(page * pageSize, page * pageSize + pageSize)
    if (newPageMessages.toString() !== this.pageMessages.toString()) {
      this.pageMessages = newPageMessages
    }

    return this.pageMessages
  }

  getSupportedMessageTypes = () => {
    const { item } = this.props
    if (isCampaignClass(item)) {
      return item.supportedMessageTypes
    }
    return contentTypeList[this.state.type]
  }

  setConditions = (conditions) => this.setState({ conditions })

  render() {
    const { timezones, timezone, countriesTimezones, countryCode, setLoading, published } = this.props
    const isPagingEnabled = this.state.ordered.length > 5 - 1
    const parts = this.getPageMessage()
    const supportedMessageTypes = this.getSupportedMessageTypes()

    return (
      <div>
        <DeleteDialog
          text={this.state.deleteText}
          isOpen={this.state.deleteConfirm}
          hideDeleteButton={true}
          cancelButtonText="Close"
          onClose={() =>
            this.setState({
              deleteConfirm: false,
              toDelete: null,
            })
          }
          onConfirm={() => this.removeMessage(this.state.toDelete)}
        />

        {this.renderWelcome()}
        {this.props.item.type === 'ivr' && this.state.page === 0 && (
          <div
            style={{
              marginBottom: '18px',
            }}
          >
            {this.renderIVRCallResultActions(
              <CallResultActions
                getVoiceProps={(qid) => ({
                  id: qid,
                  defaultUpload: pathOr(0, ['uiStore', 'defaultUpload'], this.props.item),
                  setDefaultUpload: this.setDefaultUpload,
                  loading: this.props.files.loadingItems.indexOf(qid) > -1,
                  source: this.props.files.data,
                  getFile: (file) => this.getFileHandler(qid, file),
                  saveFileHandler: (item) => this.saveFileHandler(qid, item),
                  onMicAccessError: this.props.onMicAccessError,
                  playId: this.state.voicePlayingId,
                  setPlaying: (val = null) => {
                    this.setState({
                      voicePlayingId: val,
                    })
                  },
                })}
                campaignType={this.props.item.type}
                changeHandler={({ value }) => this.callResultActionsChangeHandler({ value })}
                personalizationList={this.props.messages.personalizationList}
                senderIds={this.props.messages.senderIds}
                getCampaigns={this.props.getCampaigns}
                orgId={this.props.orgId}
                token={this.props.token}
                campaignIds={this.props.campaignIds}
                actions={pathOr([], TYPES[this.state.type.toUpperCase()].callResultActionsPath, this.props.item)}
                getSnippets={this.props.getSnippets}
                saveSnippet={this.props.saveSnippet}
                snippets={this.props.snippets}
                timezones={timezones}
                timezone={timezone}
                countriesTimezones={countriesTimezones}
                countryCode={countryCode}
              />,
            )}
          </div>
        )}

        {parts.length === 0 && (
          <NoMessage
            campaignType={this.state.type}
            messageIds={parts}
            snippets={this.props.snippets}
            supportedMessageTypes={supportedMessageTypes}
            timezone={timezone}
            onCreateMessage={(message) => this.createMessageHandler(0, 0, message)}
          />
        )}

        <React.Fragment>
          {parts.map((id, row) => {
            const index = this.state.page * this.state.pageSize + row
            const message = this.state.messages[id]
            const { hasQuestion } = message

            return (
              <MessageWrapper
                apiPersonalizationList={this.props.messages.apiPersonalizationList}
                campaignIds={this.props.campaignIds}
                messageIds={parts}
                campaignType={this.state.type}
                conditions={this.state.conditions}
                countriesTimezones={countriesTimezones}
                countryCode={countryCode}
                defaultLanguage={pathOr('', ['uiStore', 'defaultLanguage'], this.props.item)}
                defaultUpload={pathOr(0, ['uiStore', 'defaultUpload'], this.props.item)}
                files={this.props.files}
                groups={this.props.groups}
                hasQuestion={hasQuestion}
                id={id}
                index={index}
                key={id}
                languages={pathOr(this.defaultArray, ['uiStore', 'languages'], this.props.item)}
                loading={this.props.loading}
                message={message}
                messageRef={row === 0 ? this.firstMessageRef : null}
                newItems={this.props.newItems}
                orgId={this.props.orgId}
                page={this.state.page}
                pageSize={this.state.pageSize}
                personalizationList={this.props.messages.personalizationList}
                published={published}
                row={row}
                segments={this.props.segments}
                senderIds={this.props.messages.senderIds}
                snippets={this.props.snippets}
                supportedMessageTypes={supportedMessageTypes}
                timezone={timezone}
                timezones={timezones}
                token={this.props.token}
                total={this.state.ordered.length}
                voiceList={this.props.voiceList}
                voicePlayingId={this.state.voicePlayingId}
                whatsappSenderIds={this.props.messages.whatsappSenderIds}
                createNotification={this.props.createNotification}
                getCampaigns={this.props.getCampaigns}
                getFile={this.getFileHandler}
                getSnippets={this.props.getSnippets}
                onChange={this.changeHandler}
                onClose={this.closeHandler}
                onMessageCreate={this.createMessageHandler}
                onMessageDuplicate={this.duplicateMessageHandler}
                onMicAccessError={this.props.onMicAccessError}
                onSaveConditions={this.saveConditionsHandler}
                saveFile={this.saveFileHandler}
                saveSnippet={this.props.saveSnippet}
                setConditions={this.setConditions}
                setDefaultUpload={this.setDefaultUpload}
                setLoading={setLoading}
                setPlayingId={this.setPlayingHandler}
              />
            )
          })}
          {isPagingEnabled && (
            <React.Fragment>
              <Card style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                <Grid container spacing={8}>
                  <Grid item md={12} xs={12}>
                    <TablePagination
                      namespace="Messages"
                      page={this.state.page + 1}
                      size={this.state.pageSize}
                      total={this.state.ordered.length}
                      pageSizeValues={pageSizeValue}
                      onPageSizeChange={(value) => {
                        this.setState({
                          pageSize: value,
                          page: 0,
                        })
                      }}
                      onPageChange={(page) => {
                        this.setState({
                          page: page - 1,
                        })
                      }}
                    />
                  </Grid>
                </Grid>
              </Card>
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
    )
  }
}

const getMessages = (item) =>
  isReminderCampaign(item)
    ? item.toMessages()
    : compose(indexBy(prop('id')), map(toMessage), pathOr([], ['variables', 'parts']))(item)
const getOrdered = (item) => compose(map(prop('id')), pathOr([], ['variables', 'parts']))(item)

export default CampaignDripContent
