import { Card, CardContent, CardHeader, styled, Typography } from '@mui/material'
import React from 'react'

import { PersonalizationType } from '@/app/module/campaigns/types'
import { CountryTimezone, TimezoneWithCountryCodeType } from '@/app/types'

import EventTimeInput from './event-time-input'
import EventTimezonePicker from './event-timezone-picker'

type Props = {
  countriesTimezones: CountryTimezone[]
  countryCode: string
  personalizationList: PersonalizationType
  t0: string
  t0timezone: string
  timezones: TimezoneWithCountryCodeType
  onChange: (t0: string, t0timezone: string) => void
}

const DefaultEventTime: React.FC<Props> = ({
  countriesTimezones,
  countryCode,
  personalizationList,
  t0,
  t0timezone,
  timezones,
  onChange,
}) => {
  const handleChangeT0 = React.useCallback((value: string) => onChange(value, t0timezone), [t0timezone, onChange])
  const handleChangeT0Timezone = React.useCallback((value: string) => onChange(t0, value), [t0, onChange])

  return (
    <StyledCard>
      <StyledCardHeader title="Event time" />
      <CardContent>
        <Typography>
          All messages in a reminder campaign are relative to the time of an event, for example the day of birth, a
          workshop, or the beginning of the harvest season.
        </Typography>
        <Typography>
          Below you can specify how we should determine the event time for a contact. Click on the options below for
          more explanations and examples.
        </Typography>
        <EventTimeInput
          personalizationList={personalizationList}
          t0={t0}
          t0timezone={t0timezone}
          onChange={handleChangeT0}
        />
        <EventTimezonePicker
          countriesTimezones={countriesTimezones}
          countryCode={countryCode}
          t0timezone={t0timezone}
          timezones={timezones}
          onChange={handleChangeT0Timezone}
        />
      </CardContent>
    </StyledCard>
  )
}

const StyledCard = styled(Card)(() => ({
  marginBottom: '2rem',
}))

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
}))

export default React.memo(DefaultEventTime)
