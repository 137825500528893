import { styled, Typography } from '@mui/material'
import React from 'react'

import { Checkbox } from '@/app/component/atom/form'
import ActionsBox from '@/app/module/campaigns/component/actions/actions-box'
import { DRAGTYPE, SMS_ACTION_DEFAULT_SENDERID, TIMEOUT_DEFAULT } from '@/app/module/campaigns/definitions'
import { CampaignTemplateType, LocalAction, LocalOnTimeout, PartFamily } from '@/app/module/campaigns/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { emptyAction } from '@/app/module/campaigns/utils/actions/wrap'
import { ChangeEvent } from '@/app/types'

import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import QuestionTimerInput from './question/timer'

type Props = {
  actionProps: ActionProps
  apiProps: ApiProps
  campaignType: CampaignTemplateType
  hideTimeoutSeconds?: boolean
  id: string
  info: string
  inputBeforeText: string
  snippetProps: SnippetProps
  timeout: LocalOnTimeout

  onChange: (value: Partial<LocalOnTimeout>) => void
}

const ResponseWhatsappTimeout: React.FC<Props> = ({
  actionProps,
  apiProps,
  campaignType,
  hideTimeoutSeconds,
  id,
  info,
  inputBeforeText,
  snippetProps,
  timeout,
  onChange,
}) => {
  const actionOptions = React.useMemo(
    () =>
      getActionOptionsForCampaign({
        campaignType,
        partType: PartFamily.WhatsApp,
        actionNamespace: ActionContext.OnTimeout,
      }),
    [campaignType],
  )

  const handleToggle = React.useCallback(
    ({ value }: ChangeEvent<string>) => {
      const isEnabled = value === 'true'
      onChange({
        actions: isEnabled ? [emptyAction] : [],
        enabled: isEnabled,
        timeout: isEnabled
          ? {
              unit: 'minute',
              value: TIMEOUT_DEFAULT,
            }
          : undefined,
      })
    },
    [onChange],
  )
  const handleActionsChange = React.useCallback(
    (actions: LocalAction[]) =>
      onChange({
        actions,
      }),
    [onChange],
  )
  const handleTimeoutChange = React.useCallback(
    (value: { value: number; unit: string }) =>
      onChange({
        timeout: value,
      }),
    [onChange],
  )

  return (
    <div className="campaign-question-timeout-box">
      <Checkbox
        name="timeout-no-reply"
        label="Timeout / no reply"
        value={(timeout.enabled || false).toString()}
        editable
        onChange={handleToggle}
      />
      <ActionsBoxContainer>
        <Typography color="textSecondary" variant="caption" gutterBottom>
          {info}
        </Typography>
        {timeout.enabled && (
          <ActionsBox
            {...actionProps}
            {...apiProps}
            {...snippetProps}
            hasTranscribe={false}
            actionOptions={actionOptions}
            actions={timeout.actions}
            defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.voiceQuestion}
            dragContext={`${DRAGTYPE}-timeout`}
            info={
              !hideTimeoutSeconds ? (
                <Typography color="textSecondary" component="div" gutterBottom variant="caption">
                  <TimeoutSelect>
                    {inputBeforeText}
                    <QuestionTimerInput
                      min={1}
                      name="timeout"
                      unit={timeout.timeout?.unit}
                      value={timeout.timeout?.value}
                      values={timeoutValues}
                      onChange={handleTimeoutChange}
                    />
                  </TimeoutSelect>
                </Typography>
              ) : undefined
            }
            transcribeEnabled={false}
            updateContactConfig={updateContactConfig}
            voiceId={`${id}-timeout`}
            onChange={handleActionsChange}
          />
        )}
      </ActionsBoxContainer>
    </div>
  )
}

const timeoutValues: { label: string; value: string }[] = [
  {
    label: 'seconds',
    value: 'second',
  },
  {
    label: 'minutes',
    value: 'minute',
  },
  {
    label: 'hours',
    value: 'hour',
  },
  {
    label: 'days',
    value: 'day',
  },
]

const updateContactConfig = {
  invalid: false,
  question: false,
  openResponse: false,
}

const ActionsBoxContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(3),
}))

const TimeoutSelect = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export default React.memo(ResponseWhatsappTimeout)
