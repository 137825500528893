import { createSelector } from 'reselect'

import { OrgPlan } from '@/app/module/plans/types'
import { selectActiveOrg, selectUserDetails } from '@/app/module/user/store/selectors'
import { CommonUserType, UserDetailsState } from '@/app/module/user/types'
import { RootState } from '@/app/store'
import { Organization } from '@/app/types'

export const selectUserDetailsState = (state: { user: { details: UserDetailsState } }) => state.user.details

export const selectUserInit = createSelector(selectUserDetailsState, (details: UserDetailsState) => details.init)

export const selectUserLoading = createSelector(selectUserDetailsState, (details: UserDetailsState) => details.loading)

export const selectSkippingTours = createSelector(selectUserDetails, (user: CommonUserType | string) => {
  if (typeof user === 'string') {
    return {}
  }

  return user.uiStore?.skippingTours || {}
})

export const selectOrgPlan = (state: RootState): OrgPlan | undefined => state.plans.item.data
export const selectInvitationsCount = (state: RootState): number => state.user.invitations.data?.length || 0

export const selectCanInviteMoreUsers = createSelector(
  selectOrgPlan,
  selectInvitationsCount,
  (plan, invitations) => plan && plan.usersNow + invitations < plan.usersIncluded,
)

export const selectShowCanceled = createSelector(selectActiveOrg, selectOrgPlan, (org, plan) => {
  if (!org || !plan || !(org as Organization).permissions.manageUsers) {
    return false
  }

  return plan.canceled && !!plan.end
})
