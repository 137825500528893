import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Notifications from '@/app/module/notifications'
import PhoneNumbers from '@/app/module/phonenumbers'

export default function PhoneNumbersPage({ name, routes, match }) {
  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <Layout title="Phone Numbers - engageSPARK">
        <AuthHeader currentPage={PAGE.PHONENUMBERS.INDEX} />
        <PhoneNumbers />
      </Layout>
      <Notifications />
    </AuthGuard>
  )
}
