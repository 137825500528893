import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import React from 'react'

import CountryFlag from '@/app/component/atom/flag'
import Tooltip from '@/app/component/atom/tooltip'
import { formatPhoneNumber, parsePhoneNumber } from '@/app/service/util'

type Props = {
  className?: string
  contactDeleted: boolean
  contactId: number
  phoneNumber?: string | number
  direction?: 'inbound' | 'outbound'
}

const Direction = ({ direction }: { direction?: 'inbound' | 'outbound' }) => {
  if (!direction) {
    return null
  }

  if (direction === 'inbound') {
    return (
      <Tooltip title="Inbound">
        <Icon color="primary">call_received</Icon>
      </Tooltip>
    )
  }

  return (
    <Tooltip title="Outbound">
      <Icon color="secondary">call_made</Icon>
    </Tooltip>
  )
}

const Content = ({ phoneNumber, contactDeleted, contactId }: Props) => {
  if (contactDeleted) {
    return '(contact deleted)'
  }

  if (!phoneNumber) {
    return `Contact ID: ${contactId}`
  }

  return (
    <>
      <CountryFlag code={parsePhoneNumber(String(phoneNumber)).country || ''} />
      {formatPhoneNumber(`+${phoneNumber}`)}
    </>
  )
}

const ContactPhoneDisplay: React.FC<Props> = ({ phoneNumber, direction, className, contactDeleted, contactId }) => {
  const color = contactDeleted ? 'textSecondary' : 'textPrimary'

  return (
    <Typography
      alignItems="center"
      component="div"
      className={className}
      color={color}
      display="flex"
      gap={1}
      variant="subtitle1"
    >
      <Content contactDeleted={contactDeleted} contactId={contactId} phoneNumber={phoneNumber} />
      <Direction direction={direction} />
    </Typography>
  )
}

export default ContactPhoneDisplay
