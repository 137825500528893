import { pathOr } from 'ramda'
import { Dispatch } from 'redux'

import httpRequest from '@/app/module/request'
import { HTTPRequest } from '@/app/module/request-types'
import { ERROR_SAVE_USER_SERVER } from '@/app/module/user/definitions'
import { saveUserFailure, saveUserRequest, saveUserSuccess } from '@/app/module/user/store/actions'
import { CommonUserType } from '@/app/module/user/types'

const request = httpRequest as HTTPRequest

// eslint-disable-next-line import/prefer-default-export
export const saveUser =
  ({ token, item }: { token: string; item: CommonUserType }) =>
  async (dispatch: Dispatch) => {
    dispatch(saveUserRequest(item))

    try {
      await request.user.save({ token, item })
      dispatch(saveUserSuccess(item))
      return item
    } catch (err) {
      if (!(err instanceof Error)) {
        // do nothing
        return err
      }

      dispatch(
        saveUserFailure({
          status: pathOr(500, ['status'], err),
          message: ERROR_SAVE_USER_SERVER,
        }),
      )
      return err
    }
  }
