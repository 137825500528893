import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Icon,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import React, { useCallback, useState } from 'react'

import { OrgPlan } from '@/app/module/plans/types'
import { formatDate } from '@/app/service/util'

type Props = {
  activePlan: OrgPlan
  open: boolean
  isLoading: boolean
  onClose: () => void
  onCancel: () => Promise<void>
}

export const CancelSubscriptionConfirmation: React.FC<Props> = ({ activePlan, open, onClose, onCancel, isLoading }) => {
  const [closeOrganization, setCloseOrganization] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const [downloadData, setDownloadData] = useState(false)

  const allChecked = closeOrganization && deleteData && downloadData

  const handleCancel = useCallback(async () => {
    if (!allChecked) return
    await onCancel()
  }, [allChecked, onCancel])

  const formattedEndDate = activePlan.nextBillingDate
    ? formatDate(activePlan.nextBillingDate)
    : 'the end of the current billing period'

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Cancel Subscription
          <IconButton onClick={onClose} size="small" aria-label="close">
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1" paragraph>
          If you cancel your subscription, it will run until the end of the current billing period ({formattedEndDate}),
          but it will not renew.
        </Typography>

        <Typography variant="body1" paragraph>
          After two weeks without a subscription, <b>this organization will be closed</b> and all its data will be{' '}
          <b>permanently deleted.</b> You can update to a plan at any point <b>during those two weeks.</b>
        </Typography>

        <Stack spacing={1} sx={{ mt: 2, mb: 3 }}>
          <FormControlLabel
            control={<Checkbox checked={closeOrganization} onChange={(e) => setCloseOrganization(e.target.checked)} />}
            label={
              <>
                Yes, please <b>close this organization</b> after the subscription expires.
              </>
            }
          />

          <FormControlLabel
            control={<Checkbox checked={deleteData} onChange={(e) => setDeleteData(e.target.checked)} />}
            label={
              <>
                Yes, please <b>permanently delete all data</b> of this organization after the subscription expires.
              </>
            }
          />

          <FormControlLabel
            control={<Checkbox checked={downloadData} onChange={(e) => setDownloadData(e.target.checked)} />}
            label="I confirm I will download all data before the subscription expires."
          />
        </Stack>

        <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 2 }}>
          At the end of the current billing period, your subscription will be canceled.
        </Typography>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button variant="contained" color="primary" onClick={onClose}>
          Don't cancel
        </Button>

        <Button variant="outlined" color="error" disabled={!allChecked} loading={isLoading} onClick={handleCancel}>
          I understand, cancel my subscription
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CancelSubscriptionConfirmation
export type { Props as CancelSubscriptionConfirmationProps }
