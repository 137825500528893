import { Card, CardContent, CardHeader, Link, styled, Typography } from '@mui/material'
import React from 'react'

import { FileType } from '@/app/types'

import TranscriptDialog from './transcript-dialog'

type Props = {
  file: FileType
  messageId: string
  transcription: string

  onChange: (transcription: string) => void
}

const ContentAudioTranscript: React.FC<Props> = ({ file, messageId, transcription, onChange }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    setDialogOpen(true)
  }

  return (
    <Container className="audio-file-transcription">
      <StyledCardHeader title={file.title} titleTypographyProps={cardTitleTypographyProps} />
      <StyledCard>
        <StyledCardContent>
          <Transcript title={transcription || 'No transcript'}>
            <Typography component="span" variant="caption">
              <LinkButton onClick={handleClick}>{transcription ? 'Edit' : 'Transcribe'}</LinkButton> | Transcript:{' '}
              {transcription || <em>No transcript</em>}
            </Typography>
          </Transcript>
        </StyledCardContent>
        {dialogOpen && (
          <TranscriptDialog
            file={file}
            messageId={messageId}
            open
            transcription={transcription}
            onClose={() => setDialogOpen(false)}
            onSubmit={onChange}
          />
        )}
      </StyledCard>
    </Container>
  )
}

const Container = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const cardTitleTypographyProps = {
  variant: 'subtitle1',
} as const

const StyledCardHeader = styled(CardHeader)({
  paddingBottom: 0.5,
  paddingLeft: 0,
  paddingTop: 0,
})

const StyledCard = styled(Card)(({ theme }) => ({
  background: theme.palette.grey[100],
  marginBottom: 2,
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: `${theme.spacing(1)} !important`,
}))

const LinkButton = styled(Link)(() => ({
  cursor: 'pointer',
}))

const Transcript = styled('div')({
  display: '-webkit-box',
  lineClamp: 3,
  WebkitLineClamp: 3,
  boxOrient: 'vertical',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
})

export default ContentAudioTranscript

export type { Props as ContentAudioTranscriptProps }
