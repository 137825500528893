import { Icon, IconButton, Skeleton, styled } from '@mui/material'
import React from 'react'

import { TGetFileFunction } from '@/app/module/conversations/types'

type Props = {
  filename: string
  height?: number
  width?: number

  getFile: TGetFileFunction
}

const VideoPlayer: React.FC<Props> = ({ filename, height = 200, width = 300, getFile }) => {
  const [src, setSrc] = React.useState<string | null>(null)

  const downloadVideo = React.useCallback(() => {
    if (!filename) {
      return
    }
    getFile(filename).then((res) => setSrc(res.url))
  }, [filename, getFile])

  React.useEffect(() => {
    if (!src) {
      return
    }
    videoPlayerRef.current?.play()
  }, [src])

  const videoPlayerRef = React.useRef<HTMLVideoElement>(null)

  return (
    <div>
      {src ? (
        <video ref={videoPlayerRef} width={width} height={height} controls src={src} />
      ) : (
        <Preview
          sx={{
            height,
            width,
            position: 'relative',
          }}
        >
          <Skeleton variant="rectangular" width={width} height={height} />
          <Overlay>
            <PlayContainer>
              <PlayButton id="video-play-btn" onClick={downloadVideo}>
                <Icon>play_arrow</Icon>
              </PlayButton>
            </PlayContainer>
          </Overlay>
        </Preview>
      )}
    </div>
  )
}

const Preview = styled('div')(() => ({}))

const Overlay = styled('div')(() => ({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
}))

const PlayContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  height: '100%',
  alignItems: 'center',
}))

const PlayButton = styled(IconButton)(() => ({
  color: '#fff',
  backgroundColor: '#2f80ac',
  '&:hover': {
    backgroundColor: 'rgba(47, 128, 172, 0.85)',
  },
}))

export default VideoPlayer
