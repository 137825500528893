import { Icon, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'

type Props<Path extends string = string> = {
  icon?: string
  isActive: boolean
  label: string
  path: Path

  onClick: (path: Path) => void
}

function NavigatorItem<Path extends string = string>({ icon, isActive, label, path, onClick }: Props<Path>) {
  const handleClick = React.useCallback(() => onClick(path), [onClick, path])
  const color = isActive ? 'primary' : undefined

  return (
    <ListItem data-testid={`navigator-item-${path.replace(/\//g, '')}`} disablePadding divider>
      <ListItemButton selected={isActive} onClick={handleClick}>
        <ListItemIcon>
          <Icon color={color}>{icon}</Icon>
        </ListItemIcon>
        <ListItemText
          primary={label}
          slotProps={{
            primary: {
              color,
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default NavigatorItem
export type { Props as NavigatorItemProps }
