import { TextField, TextFieldProps } from '@mui/material'
import { path } from 'ramda'
import { FieldError, FieldValues, Path, useFormContext } from 'react-hook-form'

import { toPathArray } from '@/app/component/form/utils/path'

type Props<T extends FieldValues> = {
  name: Path<T>
} & Pick<
  TextFieldProps,
  'label' | 'type' | 'required' | 'disabled' | 'placeholder' | 'multiline' | 'rows' | 'autoFocus'
>

export function RHFTextField<T extends FieldValues>({ name, ...props }: Props<T>) {
  const { register, formState } = useFormContext<T>()
  const error = path<FieldError>(toPathArray(name), formState.errors)

  const preventNumberInputChange = (e: Event) => {
    if (props.type === 'number') {
      e.preventDefault()
    }
  }

  return (
    <TextField
      error={!!error}
      fullWidth
      helperText={<>{error?.message}</>}
      size="small"
      slotProps={{
        htmlInput: {
          ...register(name, {
            valueAsNumber: props.type === 'number',
            setValueAs: (value) => value || undefined,
          }),
          'aria-label': name,
        },
        inputLabel: { shrink: true },
      }}
      sx={{ mb: 2 }}
      variant="standard"
      onBlur={(e) => e.target.removeEventListener('wheel', preventNumberInputChange)}
      onFocus={(e) => e.target.addEventListener('wheel', preventNumberInputChange, { passive: false })}
      onKeyDown={(e) => {
        if (props.type === 'number' && ['ArrowUp', 'ArrowDown'].includes(e.key)) {
          e.preventDefault()
        }
      }}
      {...props}
    />
  )
}
