import { styled } from '@mui/material'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'

import Loading from '@/app/component/guard/loading'
import ContentLayout from '@/app/component/layout/content'
import TableToolbar from '@/app/component/layout/table/toolbar'
import { ERROR_GET_PHONENUMBERS_SERVER } from '@/app/module/phonenumbers/definitions'
import { usePhoneNumbers } from '@/app/module/phonenumbers/hooks/use-phonenumbers'

import PhoneNumbersTable from './table'

const PhoneNumbersList: React.FC = () => {
  const { data, isFetching, error, refetch } = usePhoneNumbers()

  const phoneNumbers = data?.phoneNumbers || []
  const loadingError = error ? { message: ERROR_GET_PHONENUMBERS_SERVER, status: 400 } : undefined

  return (
    <Loading isLoading={isFetching} error={loadingError}>
      <ContentLayout>
        <StyledToolbar>
          <div>
            <Tooltip id="reload-phonenumbers" title="Reload Phone Numbers">
              <IconButton onClick={refetch}>
                <Icon>refresh</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </StyledToolbar>
      </ContentLayout>
      <div
        style={{
          margin: 'auto',
        }}
      >
        <PhoneNumbersTable loading={isFetching} pagination={false} phoneNumbers={phoneNumbers} />
      </div>
    </Loading>
  )
}

const StyledToolbar = styled(TableToolbar)({
  justifyContent: 'flex-end',
  padding: '0.5rem 24px',
})

export default PhoneNumbersList
