/* eslint-disable no-underscore-dangle */
import { FieldValues, Path } from 'react-hook-form'
import {
  ZodEffects,
  ZodFirstPartySchemaTypes,
  ZodLiteralDef,
  ZodNullable,
  ZodObject,
  ZodOptional,
  ZodRawShape,
  ZodStringDef,
  ZodType,
} from 'zod'

import type { FormFieldProps } from '@/app/component/form/field'

type FieldConfig<T extends FieldValues> = {
  [K in Path<T>]: FormFieldProps<T>
}

function getFieldDef<T>(fieldDef: ZodFirstPartySchemaTypes): ZodFirstPartySchemaTypes {
  // Unwrap types with .transform()
  if (fieldDef instanceof ZodEffects) {
    return getFieldDef<T>(fieldDef._def.schema)
  }

  // Unwrap types with .optional() or .nullable()
  if (fieldDef instanceof ZodOptional || fieldDef instanceof ZodNullable) {
    return getFieldDef<T>(fieldDef._def.innerType)
  }

  return fieldDef
}

function getFieldLabel<T>(fieldDef: ZodType<T>) {
  return fieldDef._def.description
}

function getStringSubtype(key: string, fieldDef: ZodStringDef): 'tel' | 'email' | 'url' | 'date' | 'datetime' | 'text' {
  if (key === 'phone') {
    return 'tel'
  }
  if (fieldDef.checks.find((k) => k.kind === 'email')) {
    return 'email'
  }
  if (fieldDef.checks.find((k) => k.kind === 'url')) {
    return 'url'
  }
  if (fieldDef.checks.find((k) => k.kind === 'date')) {
    return 'date'
  }
  if (fieldDef.checks.find((k) => k.kind === 'datetime')) {
    return 'datetime'
  }
  return 'text'
}

function getLiteralSubtype(fieldDef: ZodLiteralDef): 'text' | 'number' | 'switch' {
  switch (typeof fieldDef.value) {
    case 'string':
      return 'text'
    case 'number':
      return 'number'
    case 'boolean':
      return 'switch'
    default:
      throw new Error('Unsupported literal type, value needs to be a string, number or boolean.')
  }
}

function isRequired(fieldDef: ZodType) {
  // Unwrap transform types
  if (fieldDef instanceof ZodEffects) {
    return isRequired(fieldDef._def.schema)
  }

  return !(fieldDef instanceof ZodOptional || fieldDef instanceof ZodNullable)
}

export function buildFieldConfig<T extends FieldValues, S extends ZodRawShape>(
  schema: ZodObject<S>,
  defaultFields?: Partial<FieldConfig<T>>,
): FieldConfig<T> {
  const fieldMap = Object.keys(schema.shape).reduce<FieldConfig<T>>((acc, key, i) => {
    const name = key as Path<T>
    const item = schema.shape[name]
    const fieldDef = getFieldDef<T[Path<T>]>(item)
    const label = getFieldLabel<T>(item)
    const required = isRequired(item)

    switch (fieldDef._def.typeName) {
      case 'ZodLiteral': {
        const literalType = getLiteralSubtype(fieldDef._def)
        acc[name] = defaultFields?.[name] || {
          type: literalType,
          fieldProps: {
            name,
            autoFocus: i === 0,
            label,
            required,
          },
        }
        break
      }
      case 'ZodString': {
        const stringType = getStringSubtype(name, fieldDef._def)
        acc[name] = defaultFields?.[name] || {
          type: stringType,
          fieldProps: {
            name,
            autoFocus: i === 0,
            label,
            required,
          },
        }
        break
      }
      case 'ZodDate':
        acc[name] = defaultFields?.[name] || {
          type: 'datetime',
          fieldProps: {
            name,
            autoFocus: i === 0,
            label,
            required,
          },
        }
        break
      case 'ZodBoolean':
        acc[name] = defaultFields?.[name] || {
          type: 'switch',
          fieldProps: {
            name,
            autoFocus: i === 0,
            label,
          },
        }
        break
      case 'ZodNumber':
        acc[name] = defaultFields?.[name] || {
          type: 'number',
          fieldProps: {
            name,
            autoFocus: i === 0,
            label,
            required,
          },
        }
        break
      case 'ZodEnum': {
        acc[name] = defaultFields?.[name] || {
          type: 'select',
          fieldProps: {
            name,
            autoFocus: i === 0,
            label,
            required,
            options: fieldDef._def.values?.map((v: string) => ({ value: v, label: v })),
          },
        }
        break
      }
      default: {
        const defaultConfig = defaultFields?.[name]
        if (defaultConfig) {
          acc[name] = defaultConfig
        } else {
          throw new Error(`Unsupported field type: ${fieldDef._def.typeName}`)
        }
      }
    }

    return acc
  }, {} as FieldConfig<T>)

  return fieldMap
}
