import moment from 'moment'
import {
  append,
  findIndex,
  innerJoin,
  insert,
  map,
  move,
  omit,
  pathOr,
  pick,
  prepend,
  propEq,
  update,
  without,
} from 'ramda'

import {
  ACTION_ADD_NEW_LOG,
  ACTION_CLEAR_LOGS,
  ACTION_GET_CONTACT_LOGS,
  ACTION_GET_CONTACT_LOGS_COMPLETED,
  ACTION_GET_CONTACTS,
  ACTION_GET_CONTACTS_COMPLETED,
  ACTION_READ_ACTIVE_CONTACT_MESSAGE,
  ACTION_SET_ACTIVE_CONTACT_INFO,
} from '@/app/module/conversations/definitions'
import { aggregateCustomFields } from '@/app/module/conversations/helpers'
import { ContactType, ConversationsType, PartType, SubscriptionType } from '@/app/module/conversations/types'
import { createReducer } from '@/app/service/util'
import { SMSLog, WhatsAppLog } from '@/app/types/log'

import defaultState from './state'

const markAllMessagesAsRead = (history: any) =>
  map(
    (elem) => ({
      ...elem,
      new: false,
    }),
    history,
  )

const readAll = (data: any, contactId: string) => {
  const contactIndex = findIndex(propEq('contactId', contactId), data)
  if (contactIndex === -1) {
    return data
  }
  return update(
    contactIndex,
    {
      ...data[contactIndex],
      history: markAllMessagesAsRead(data[contactIndex].history),
      unreadCount: 0,
    },
    data,
  )
}

const setLoadedFlag = (data: any, contactId: string) => {
  const contactIndex = findIndex(propEq('contactId', contactId), data)
  if (contactIndex === -1) {
    return data
  }
  return update(
    contactIndex,
    {
      ...data[contactIndex],
      loaded: true,
    },
    data,
  )
}

const insertSorted = (list: any, value: any) => {
  let result = []
  let found = false
  let i = 0
  while (i < list.length && !found) {
    const currentDate = new Date(list[i].created)
    if (moment(value.created).isAfter(currentDate)) {
      result = insert(i, value, list)
      found = true
    }
    i += 1
  }
  if (!found) {
    result = append(value, list)
  }
  return result
}

const addNewContact = (data: ContactType[], value: WhatsAppLog | SMSLog) => {
  const newContact: ContactType = {
    ...pick(['contactId', 'firstName', 'lastName', 'fullPhoneNumber', 'created', 'countryCode'], value),
    groups: [],
    customFields: [],
    segments: [],
    history: [omit(['contactId', 'firstName', 'lastName', 'fullPhoneNumber', 'created', 'countryCode'], value) as any],
    subscriptions: [],
    unreadCount: 0,
  }
  if (value.new && value.engagementFlowId) {
    return append(newContact, data)
  }
  return insertSorted(data, newContact)
}

const appendOrReplace = (data: ContactType[], value: WhatsAppLog | SMSLog, activeContactId: number) => {
  const contactIndex = findIndex(propEq('contactId', value.contactId), data)
  if (contactIndex === -1) {
    return addNewContact(data, value)
  }

  const messageIndex = findIndex(propEq('id', value.id), data[contactIndex].history)
  if (messageIndex !== -1) {
    return update(
      contactIndex,
      {
        ...data[contactIndex],
        history: update(messageIndex, value, data[contactIndex].history),
      },
      data,
    )
  }

  let { history } = data[contactIndex]

  const contactLatestMessage = data[contactIndex].created
  const currentDate = new Date(contactLatestMessage)
  if (
    moment(value.created).isAfter(currentDate) &&
    !(value.new && activeContactId !== value.contactId && value.engagementFlowId)
  ) {
    return move(
      contactIndex,
      0,
      update(
        contactIndex,
        {
          ...data[contactIndex],
          created: value.created,
          history: prepend(value, history),
          ...(value.new && {
            unreadCount: data[contactIndex].unreadCount + 1,
          }),
        },
        data,
      ),
    )
  }
  history = insertSorted(history, value)
  return update(
    contactIndex,
    {
      ...data[contactIndex],
      history,
      ...(value.new && {
        unreadCount: data[contactIndex].unreadCount + 1,
      }),
    },
    data,
  )
}

const setNextPage = (data: ContactType[], contactId: number, nextPage: string) => {
  const contactIndex = findIndex(propEq('contactId', contactId), data)
  return update(
    contactIndex,
    {
      ...data[contactIndex],
      nextPage,
    },
    data,
  )
}

const transformSubscriptions = (subscriptions: SubscriptionType[]) =>
  map(
    (subscription: SubscriptionType) => ({
      ...subscription,
      id: subscription.campaignId,
      name: subscription.campaignName,
    }),
    subscriptions,
  )

export default createReducer(defaultState, {
  [ACTION_ADD_NEW_LOG]: (state: ConversationsType, { value }: { value: PartType }) => ({
    ...state,
    error: defaultState.error,
    data: {
      ...state.data,
      conversations: appendOrReplace(state.data.conversations, (value as any).data, state.data.contactInfo.contactId),
    },
  }),
  [ACTION_SET_ACTIVE_CONTACT_INFO]: (state: ConversationsType, { value }: { value: any }) => ({
    ...state,
    error: defaultState.error,
    data: {
      ...state.data,
      contactInfo: {
        contactId: value.contactId,
        firstName: pathOr('', ['contact', 'firstName'], value),
        lastName: pathOr('', ['contact', 'lastName'], value),
        fullPhoneNumber: pathOr('', ['contact', 'fullPhoneNumber'], value),
        created: pathOr('', ['contact', 'createdAt'], value),
        groups: innerJoin(
          (element: any, id) => element.id === id,
          pathOr([], ['groups'], value),
          pathOr([], ['contact', 'groups'], value),
        ),
        countryCode: value.countryIso,
        history: [],
        customFields: aggregateCustomFields(value),
        segments: innerJoin(
          (element: any, id) => element.id === id,
          pathOr([], ['segments'], value),
          pathOr([], ['contact', 'segments'], value),
        ),
        subscriptions: value.subscriptions ? transformSubscriptions(value.subscriptions) : [],
      },
    },
  }),
  [ACTION_READ_ACTIVE_CONTACT_MESSAGE]: (state: ConversationsType, { value }: { value: any }) => ({
    ...state,
    error: defaultState.error,
    data: {
      ...state.data,
      conversations: readAll(state.data.conversations, value.activeContactId),
    },
  }),
  [ACTION_GET_CONTACT_LOGS]: (state: ConversationsType, { value }: { value: any }) => ({
    ...state,
    error: defaultState.error,
    data: {
      ...state.data,
      loadingList: append(value.activeContactId, state.data.loadingList),
      conversations: setLoadedFlag(state.data.conversations, value.activeContactId),
    },
  }),
  [ACTION_GET_CONTACT_LOGS_COMPLETED]: (state: ConversationsType, { value }: { value: any }) => ({
    ...state,
    error: defaultState.error,
    data: {
      ...state.data,
      loadingList: without([value.contactId], state.data.loadingList),
      conversations: setNextPage(state.data.conversations, value.contactId, value.nextPage),
    },
  }),
  [ACTION_GET_CONTACTS]: (state: ConversationsType) => ({
    ...state,
    error: defaultState.error,
    data: {
      ...state.data,
      loading: true,
    },
  }),
  [ACTION_GET_CONTACTS_COMPLETED]: (state: ConversationsType, { value }: { value: any }) => ({
    ...state,
    error: defaultState.error,
    data: {
      ...state.data,
      loading: false,
      initialized: true,
      nextPage: value.nextPage,
    },
  }),
  [ACTION_CLEAR_LOGS]: (state: ConversationsType) => ({
    ...state,
    error: defaultState.error,
    data: {
      ...state.data,
      conversations: [],
    },
  }),
})
