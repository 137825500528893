import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import { CATIItem } from '@/app/module/cati'
import Notifications from '@/app/module/notifications'
import { FixMeLater } from '@/config/types'

export default function CATISurveyPage(props: FixMeLater) {
  const { match, history } = props
  return (
    <AuthGuard url={match.url} path={match.path}>
      <Layout title="CATI Survey - engageSPARK">
        <AuthHeader currentPage={PAGE.CATI.SURVEY} />
        {/* @ts-ignore */}
        <CATIItem history={history} itemId={match.params.itemId} runId={match.params.runId} />
      </Layout>
      <Notifications />
    </AuthGuard>
  )
}
