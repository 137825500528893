// PAGES
export const PAGE = {
  HOME: '@page/HOME',
  NOTFOUND: '@page/ERROR-404',
  NOORG: '@page/NO-ORG',
  ANALYTICS: {
    INDEX: '@page/ANALYTICS',
    FILE: '@page/ANALYTICS/FILE',
  },
  CONVERSATIONS: {
    INDEX: '@page/CONVERSATIONS',
  },
  LOGS: {
    INDEX: '@page/LOGS',
    SEARCH: '@page/LOGS/SEARCH',
  },
  MESSAGES: {
    INDEX: '@page/MESSAGES',
    SEARCH: '@page/MESSAGES/SEARCH',
  },
  PHONENUMBERS: {
    INDEX: '@page/PHONENUMBERS',
    ITEM: '@page/PHONENUMBERS/ITEM',
  },
  DEMOS: {
    INDEX: '@page/DEMOS',
  },
  DASHBOARD: {
    INDEX: '@page/DASHBOARD',
  },
  PLANS: {
    INDEX: '@page/PLANS',
  },
  CONTACTS: {
    INDEX: '@page/CONTACTS',
    ADD: '@page/CONTACTS/ADD',
    EDIT: '@page/CONTACTS/EDIT',
    UPLOAD: '@page/CONTACTS/UPLOAD',
    SEARCH: '@page/CONTACTS/SEARCH',
    FILTER: '@page/CONTACTS/FILTER',
  },
  CUSTOMFIELDS: {
    ADD: '@page/CUSTOMFIELDS/ADD',
  },
  GROUPS: {
    ADD: '@page/GROUPS/ADD',
    EDIT: '@page/GROUPS/EDIT',
  },
  SEGMENTS: {
    ADD: '@page/SEGMENTS/ADD',
    EDIT: '@page/SEGMENTS/EDIT',
  },
  CAMPAIGNS: {
    INDEX: '@page/CAMPAIGNS',
    ADD: '@page/CAMPAIGNS/ADD',
    EDIT: '@page/CAMPAIGNS/EDIT',
    MANAGE: '@page/CAMPAIGNS/MANAGE',
    SUMMARY: '@page/CAMPAIGNS/SUMMARY',
    RULES: '@page/CAMPAIGNS/RULES',
  },
  CATI: {
    SURVEY: '@page/CATI/SURVEY',
    SUBS: '@page/CATI/SUBS',
  },
  ACCOUNT: {
    INDEX: '@page/ACCOUNT',
    LOGIN: '@page/LOGIN',
    SIGNUP: '@page/SIGNUP',
    RESET_PASSWORD: '@page/RESET_PASSWORD',
    REQUEST_RESET_PASSWORD: '@page/REQUEST_RESET_PASSWORD',
    VERIFY_OTP: '@page/VERIFY_OTP',
    VERIFY_RECOVERY_CODE: '@page/VERIFY_RECOVERY_CODE',
    PROFILE: {
      INDEX: '@page/PROFILE',
    },
    ORGANIZATION: {
      INDEX: '@page/ORGANIZATION',
    },
    BILLING: {
      INDEX: '@page/BILLING',
    },
  },
  TERMS: '@page/TERMS_OF_SERVICE',
  INVITATION: '@page/INVITATION',
  EXPORTS: {
    INDEX: '@page/EXPORTS',
  },
}

// USER ROLES
export const ROLES = {
  OWNER: ['owner'],
  MANAGER: ['manager'],
  COLLABORATOR: ['collaborator'],
  REPORT_VIEWER: ['report_viewer'],
  PROJECT_REPORT_VIEWER: ['project_report_viewer'],
  CUSTOMER_SERVICE: ['customer_service'],
  CATI_AGENT: ['cati_agent'],
}

// ERRORS
export const ERROR_SERVER = 'SERVICE UNAVAILABLE'

// MISC
export const INPUT_DEFAULT_MAX_LENGTH = 30
export const MIC_ACCESS_ERROR = 'Your microphone is disabled. Please try enabling it from your settings and try again.'

// WEB SOCKETS
export const ACTION_WEBSOCKET_CONNECT = '@actions/WEBSOCKET/CONNECT'

export const ACTION_WEBSOCKET_DISCONNECT = '@actions/WEBSOCKET/DISCONNECT'

export const ACTION_WEBSOCKET_NEW_MESSAGE = '@actions/WEBSOCKET/NEW_MESSAGE'

// INCOMING MESSAGE TYPES
export const WEBSOCKET_INCOMING_MESSAGE_BALANCE = 'balance'
export const WEBSOCKET_INCOMING_SMS = 'sms'
export const WEBSOCKET_INCOMING_WHATSAPP = 'whatsapp'
export const WEBSOCKET_INCOMING_CONTACT = 'contact'
export const WEBSOCKET_INCOMING_CONTACT_MESSAGE_COMPLETED = 'contactLogsDone'
export const WEBSOCKET_INCOMING_CONTACTS_COMPLETED = 'getLogsDone'
export const WEBSOCKET_INCOMING_ERROR = 'error'
export const WEBSOCKET_INCOMING_EXPORT_CHANGED = 'exportChanged'
export const WEBSOCKET_INCOMING_EXPORT_COMPLETED = 'exportDone'
export const WEBSOCKET_INCOMING_CAMPAIGN_UPDATED = 'campaign'

export const CONSENT_COOKIE = 'es-cookie-close'

export const DEFAULT_SENDER_ID_NAME = 'System Default'
export const DEFAULT_SENDER_ID_VALUE = '-1'

export const noop = () => {}
