import Button from '@mui/material/Button'
import { green } from '@mui/material/colors'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import React from 'react'

import CustomTooltip from '@/app/component/atom/tooltip'
import { OrgPlan } from '@/app/module/plans/types'
import { getPlanCountries } from '@/app/module/plans/utils'
import { numberWithCommas } from '@/app/service/util/format'
import { UserRole } from '@/app/types'

import DashboardList, { DasboardItem } from './dashboard-list'
import numbersRow from './numbers-row'
import PlanBlock from './plan-block'

type Props = {
  isLoading: boolean
  plan?: OrgPlan
  role: UserRole
  setPlansToggle: (toggle: boolean) => void
}

const ActivePlan: React.FC<Props> = ({ setPlansToggle, plan, isLoading = false, role }) => {
  const trialDaysLeft = plan?.trialDaysLeft
  const isTrial = !plan?.planId

  const items = React.useMemo<DasboardItem[]>(() => {
    if (!plan) {
      return []
    }

    return [
      {
        label: (
          <>
            Stored contacts included&nbsp;
            <CustomTooltip interactive title="The maximum number of contacts that can be stored in the account.">
              <Icon style={{ verticalAlign: 'middle', fontSize: '120%', color: 'rgba(0, 0, 0, 0.54)' }}>info</Icon>
            </CustomTooltip>
          </>
        ),
        value: numbersRow(green.A700, numberWithCommas(plan.storedContactsMax)),
      },
      {
        label: 'User accounts included',
        value: plan.usersIncluded || 1,
      },
      {
        label: 'Countries included',
        value: getPlanCountries(plan.countriesIncluded).value,
      },
    ]
  }, [plan])

  const isOwner = role === 'owner'

  return (
    <PlanBlock
      isLoading={isLoading}
      title={!isTrial ? `Active Plan: ${plan.label}` : ''}
      action={
        !isTrial ? (
          <CustomTooltip title={!isOwner ? 'Only the owner of an organization can do this.' : ''}>
            <div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={!isOwner}
                onClick={() => {
                  setPlansToggle(true)
                }}
              >
                {isTrial ? 'Select' : 'Change'} Plan
              </Button>
            </div>
          </CustomTooltip>
        ) : undefined
      }
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: !isTrial ? 'flex-start' : 'space-between',
      }}
    >
      {!isTrial && <DashboardList items={items} />}
      {isTrial && (
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            padding: 15,
            flex: 1,
          }}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Typography variant="h5" color="secondary">
              {typeof trialDaysLeft === 'number' && trialDaysLeft > -1 ? (
                <>
                  Your trial will expire in {trialDaysLeft} day{trialDaysLeft !== 1 ? 's' : ''}
                </>
              ) : (
                <>You do not have an active subscription. Choose one now to engage your contacts.</>
              )}
            </Typography>
            <CustomTooltip title={!isOwner ? 'Only the owner of an organization can do this.' : ''}>
              <div>
                <Button
                  style={{ marginTop: '15px' }}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    setPlansToggle(true)
                  }}
                >
                  Select a Plan Now
                </Button>
              </div>
            </CustomTooltip>
          </div>
        </div>
      )}
    </PlanBlock>
  )
}

export default ActivePlan

export type { Props as ActivePlanProps }
