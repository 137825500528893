import { z } from 'zod'

import {
  OrgReportGrouping,
  ZDateFilter,
  ZGetOrgReportFileResponse,
  ZOrgReportFilter,
  ZOrgReportQuery,
  ZOrgReportResult,
} from '@/app/module/campaigns/types/schemas/reports/org-report'

export enum OrgReportFunction {
  Deliverability = 'smsreport',
  Spend = 'orgspend',
}

export type DateFilter = z.infer<typeof ZDateFilter>

export type OrgReportFilter = z.infer<typeof ZOrgReportFilter>

export type OrgReportQuery = z.infer<typeof ZOrgReportQuery>

export enum OrgReportMimeType {
  Spend = 'application/prs.espark+json;report=orgspend',
}

export type OrgReportResult = z.infer<typeof ZOrgReportResult>

export type GetOrgReportFileResponse = z.infer<typeof ZGetOrgReportFileResponse>

export { OrgReportGrouping }
