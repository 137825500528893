import { Typography } from '@mui/material'
import React from 'react'

import { Checkbox } from '@/app/component/atom/form'

import { ActionsBoxContainer } from './response-configs-components'

type Props = {
  keypressAfterAudio: boolean

  onChange: (value: boolean) => void
}

const ResponseVoiceKeypressAfter: React.FC<Props> = ({ keypressAfterAudio, onChange }) => {
  const handleChange = React.useCallback(({ value }: { value: string }) => onChange(value !== 'true'), [onChange])

  const isChecked = !keypressAfterAudio // checked means cannot reply during playback so it's the opposite of keypressAfterAudio

  return (
    <div className="campaign-question-keypress-box">
      <Checkbox
        name="keypress-after-audio"
        label="Allow replies during playback"
        value={isChecked.toString()}
        editable={true}
        onChange={handleChange}
      />
      <ActionsBoxContainer>
        <Typography color="textSecondary" variant="caption" gutterBottom={true}>
          {isChecked
            ? 'If disabled, a contact must listen to the complete question before being able to reply. (Any keys pressed while the question is playing will be ignored.)'
            : 'If enabled, a contact will be able to reply while the question is playing. If they press a key during the question, the question will stop playing, and the reply will be registered.'}
        </Typography>
      </ActionsBoxContainer>
    </div>
  )
}

export default React.memo(ResponseVoiceKeypressAfter)
