import Typography from '@mui/material/Typography'
import React from 'react'

import EditableText from '@/app/component/atom/form/editableText'

type Props = {
  label: string

  onChange: (label: string) => void
}

export default function ChoiceLabel({ label, onChange }: Props) {
  const [text, setText] = React.useState(label)

  return (
    <Typography
      component="div"
      color="primary"
      style={{
        height: '32px',
        fontSize: '0.9rem',
      }}
    >
      <EditableText
        clickableLabel={!text}
        data-testid="choice-label"
        padding="0px"
        maxLength={30}
        displayText={text || 'Add a label'}
        value={text}
        textInputStyle={{
          fontSize: '14px',
        }}
        onChange={(newLabel: string) => {
          setText(newLabel)
          onChange(newLabel)
        }}
      />
    </Typography>
  )
}
