import ClickAwayListener from '@mui/material/ClickAwayListener'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import React from 'react'
import { useForm } from 'react-hook-form'

import Input from '@/app/component/atom/form/input'
import Tooltip from '@/app/component/atom/tooltip'

const SearchForm = styled('form')({
  display: 'flex',
  alignItems: 'center',
  transition: 'width 0.3s',
  overflow: 'hidden',
})

const SearchInputContainer = styled('div')({
  display: 'inline-block',
  paddingTop: '0',
  overflow: 'hidden',
  width: '200px',
})

type Props = {
  delay?: number
  disabled?: boolean
  label?: string
  namespace: string
  searchTerm?: string | null
  tooltipTitle?: string

  onSubmit: (term: string) => void
}

export default function SearchBar({ searchTerm, delay = 1500, label, namespace, tooltipTitle, onSubmit }: Props) {
  const formRef = React.useRef<HTMLFormElement>(null)
  const [isDisplayed, setDisplayed] = React.useState(!!searchTerm)
  const { register, handleSubmit, watch, formState } = useForm({
    defaultValues: {
      term: searchTerm || '',
    },
  })

  React.useEffect(() => {
    let timeout: number | ReturnType<typeof setTimeout> = 0
    const subscription = watch(() => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        handleSubmit(async ({ term }) => onSubmit(term))()
      }, delay)
    })
    return () => {
      subscription.unsubscribe()
      clearTimeout(timeout)
    }
  }, [watch, delay, handleSubmit, onSubmit])

  const disabled = formState.isSubmitting || !formState.isDirty || !formState.isValid || formState.isValidating

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (!searchTerm) {
          setDisplayed(false)
        }
      }}
    >
      <SearchForm
        ref={formRef}
        autoComplete="off"
        style={{ width: isDisplayed ? '248px' : '40px' }}
        onSubmit={handleSubmit(async ({ term }) => onSubmit(term))}
      >
        <Tooltip title={tooltipTitle}>
          <div>
            <IconButton
              type="submit"
              id={`search-${namespace}-action`}
              disabled={isDisplayed && disabled}
              aria-label={label}
              onClick={(e) => {
                if (!isDisplayed) {
                  e.preventDefault()
                  setDisplayed(true)
                }
              }}
            >
              <Icon>search</Icon>
            </IconButton>
          </div>
        </Tooltip>
        <SearchInputContainer id={`search-${namespace}-input-container`}>
          {isDisplayed && (
            <Input
              id={`search-${namespace}-input`}
              autoFocus={true}
              type="search"
              name={`search-${namespace}-field`}
              error={formState.errors.term?.message}
              placeholder={label}
              inputProps={register('term', {
                pattern: {
                  value: /^[^%()]*$/,
                  message: 'Special characters [%()] are not allowed!',
                },
                validate: {
                  minTwoCharacters: (value: string) => {
                    if (value.length === 1) {
                      return 'Minimum two letters required.'
                    }
                    return true
                  },
                },
              })}
              InputProps={{
                id: `search-${namespace}-input-inner`,
              }}
            />
          )}
        </SearchInputContainer>
      </SearchForm>
    </ClickAwayListener>
  )
}
