import { Box, Button, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import CanceledPlanWarning from '@/app/module/dashboard/component/canceled-plan-warning'
import { selectActiveOrg } from '@/app/module/user/store/selectors'
import { selectOrgPlan, selectShowCanceled } from '@/app/module/user/store/ts/selectors'
import { Organization } from '@/app/types'

import ActiveSubscription from './active-subscription'

type Props = {}

const Subscription: React.FC<Props> = () => {
  const activeOrg = useSelector(selectActiveOrg) as Organization | undefined
  const activePlan = useSelector(selectOrgPlan)
  const showCanceledWarning = useSelector(selectShowCanceled)
  const history = useHistory()

  const toPlans = () => {
    history.push('/plans')
  }

  const noPlan = !activePlan
  const isTrial = activePlan && !activePlan.planId
  const trialEnded = activePlan && typeof activePlan.trialDaysLeft === 'number' && activePlan.trialDaysLeft <= 0
  const isOwner = activeOrg?.role === 'owner'

  return (
    <Container>
      <Typography variant="h5" color="primary" gutterBottom>
        Subscription Plan
      </Typography>
      {showCanceledWarning && activePlan?.end && (
        <Box ml={-2} mr={-2}>
          <CanceledPlanWarning end={activePlan.end} />
        </Box>
      )}
      {!!activeOrg && (
        <Grid container>
          <Grid item xs={12} sm={8}>
            {noPlan && (
              <Typography variant="h6" gutterBottom>
                You are currently not on a plan
              </Typography>
            )}
            {isTrial && !trialEnded && (
              <Typography variant="h6" gutterBottom>
                You have {activePlan.trialDaysLeft} days left on the Trial plan
              </Typography>
            )}
            {isTrial && trialEnded && (
              <Typography variant="h6" gutterBottom>
                Your trial has ended
              </Typography>
            )}
            {isOwner && (noPlan || isTrial) && (
              <Button color="primary" size="small" variant="contained" onClick={toPlans}>
                Select a plan
              </Button>
            )}
            {!isTrial && !!activePlan && <ActiveSubscription activePlan={activePlan} isOwner={isOwner} />}
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

const Container = styled('div')({
  padding: 15,
})

export default Subscription
