import { z } from 'zod'

import ZBaseAction from './base'

const ZSubscribeAction = ZBaseAction.extend({
  subscribeToCampaign: z.object({
    campaignId: z.number(),
    campaignName: z.string(),
  }),
})

export default ZSubscribeAction
