import React, { Suspense, useEffect, useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { captureError } from '@/app/service/util/error'

export type TRoute = {
  name: string
  path: string
  exact?: boolean
  component: React.ComponentType<any>
}

type WrapperProps = {
  routes: TRoute[]
  Loader: React.ComponentType
  ErrorPage: React.ComponentType
}

const Wrapper: React.FC<WrapperProps> = ({ routes, Loader, ErrorPage }) => {
  const [hasRenderError, setHasRenderError] = useState(false)
  const location = useLocation()
  const history = useHistory()

  // Scroll to top when location changes
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  // Custom error boundary using ErrorBoundary pattern
  if (hasRenderError) {
    return <ErrorPage />
  }

  return (
    <ErrorBoundary onError={() => setHasRenderError(true)}>
      <Suspense fallback={<Loader />}>
        <Switch>
          {routes.map(({ name, path, exact, component: Page }) => (
            <Route
              key={name}
              path={path}
              exact={exact}
              location={location}
              render={(props: any) => (
                <Page
                  name={name}
                  history={history}
                  location={location}
                  match={{
                    ...props.match,
                    url: `${props.match.url}${location.search}`,
                  }}
                />
              )}
            />
          ))}
        </Switch>
      </Suspense>
    </ErrorBoundary>
  )
}

// Custom ErrorBoundary component since React doesn't have a hook for error boundaries
class ErrorBoundary extends React.PureComponent<{
  children: React.ReactNode
  onError: (error: Error, errorInfo: React.ErrorInfo) => void
}> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    captureError(error, { componentStack: errorInfo.componentStack })
    this.props.onError(error, errorInfo)
  }

  render() {
    return this.props.children
  }
}

export default Wrapper
