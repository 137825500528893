import { Box, Button, Skeleton } from '@mui/material'
import { remove } from 'ramda'
import React from 'react'

import { SchemaCondition } from '@/__generated/es-api'
import DeleteDialog from '@/app/component/atom/delete-dialog'
import { Form } from '@/app/component/form'
import SubmitButton from '@/app/component/form/submit-button'
import Loading from '@/app/component/guard/loading'
import { PersonalizationType } from '@/app/module/campaigns/types'
import DeleteProtectionToggle from '@/app/module/contacts/component/form/delete-protection-toggle'
import { operands } from '@/app/module/groups/definitions'
import { useDeleteGroup } from '@/app/module/groups/hooks/use-delete-group'
import { useGetGroup } from '@/app/module/groups/hooks/use-get-group'
import { useUpdateGroup } from '@/app/module/groups/hooks/use-update-group'

import { FormContainer } from './common'
import { fields, segmentSchema } from './specs'
import { toConditions } from './utils'

type Props = {
  orgId: number
  personalizationList: PersonalizationType
  segmentId: number
  token: string

  getSegments: (payload: { token: string; orgId: number }) => void
  onExit: () => void
}

const UpdateSegmentForm: React.FC<Props> = ({ orgId, personalizationList, segmentId, token, getSegments, onExit }) => {
  const [deleteConfirm, setDeleteConfirm] = React.useState(false)
  const [deleteGroup, { isLoading: isDeleting }] = useDeleteGroup()
  const [updateGroup, { isLoading: isUpdating }] = useUpdateGroup()

  const { data, isFetching, isLoading, isUninitialized } = useGetGroup(segmentId)

  const [noAutodelete, setNoAutodelete] = React.useState(data?.noAutodelete || false)
  const [changed, setChanged] = React.useState(false)
  React.useEffect(() => {
    setNoAutodelete(data?.noAutodelete || false)
  }, [data?.noAutodelete])
  const handleNoAutodeleteChange = React.useCallback((value: boolean) => {
    setNoAutodelete(value)
    setChanged(true)
  }, [])

  const handleSubmit = React.useCallback(
    async (values: { name: string; conditions: string }) => {
      const success = await updateGroup(segmentId, {
        conditions: toConditions(values.conditions),
        type: 'SMART',
        name: values.name,
        description: '',
        noAutodelete,
      })
      if (!success) {
        return
      }
      getSegments({ token, orgId }) // FIXME: this should be removed when we move getSegments to generated API
      onExit()
    },
    [noAutodelete, orgId, segmentId, token, getSegments, onExit, updateGroup],
  )

  const handleDeleteSegment = React.useCallback(async () => {
    setDeleteConfirm(false)
    const success = await deleteGroup(segmentId)
    if (!success) {
      return
    }
    getSegments({ token, orgId }) // FIXME: this should be removed when we move getSegments to generated API
    onExit()
  }, [segmentId, orgId, token, deleteGroup, getSegments, onExit])

  const segmentFields = React.useMemo(
    () =>
      ({
        ...fields,
        conditions: {
          fieldProps: { label: 'Clauses', name: 'conditions', operands, personalizationList },
          type: 'conditions',
        },
      }) as const,
    [personalizationList],
  )

  const defaultValues = React.useMemo(() => {
    if (!data) {
      return {
        name: '',
        conditions: '[]',
      }
    }

    return {
      name: data?.name || '',
      conditions: JSON.stringify(remove(0, 1, data.conditions || (['or', ['and', ['', '', '']]] as SchemaCondition[]))),
    }
  }, [data])

  const loading = isDeleting || isFetching || isLoading || isUninitialized
  const showSkeleton = isFetching || isLoading || isUninitialized

  return (
    <Loading isLoading={loading} testId="segment-item">
      <FormContainer>
        {showSkeleton && <SegmentFormSkeleton />}
        {!showSkeleton && (
          <Form
            defaultValues={defaultValues}
            fields={segmentFields}
            name="update-segment"
            schema={segmentSchema}
            shouldReset
            onSubmit={handleSubmit}
            renderActions={({ isDirty, isValid }) => (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
                <Button
                  color="secondary"
                  disabled={!data}
                  loading={isDeleting}
                  variant="outlined"
                  onClick={() => setDeleteConfirm(true)}
                >
                  Delete segment
                </Button>
                <SubmitButton isDirty={changed || isDirty} isSubmitting={isUpdating} isValid={isValid}>
                  Save
                </SubmitButton>
              </Box>
            )}
          >
            <DeleteProtectionToggle checked={noAutodelete} isGroup onChange={handleNoAutodeleteChange} />
          </Form>
        )}
      </FormContainer>
      <DeleteDialog
        text="Are you sure you want to delete the selected segment? (Contacts inside the segment will not be deleted)"
        isOpen={deleteConfirm}
        onClose={() => setDeleteConfirm(false)}
        onConfirm={handleDeleteSegment}
      />
    </Loading>
  )
}

export function SegmentFormSkeleton() {
  return (
    <>
      <Skeleton variant="rectangular" width="100%" height={38} sx={{ mb: 2, borderRadius: 1 }} />
      <Skeleton variant="rectangular" width="100%" height={38} sx={{ mb: 2, borderRadius: 1 }} />
      <Skeleton variant="rectangular" width={100} height={38} sx={{ mb: 2, ml: 'auto', borderRadius: 1 }} />
    </>
  )
}

export default UpdateSegmentForm
