import { compose, map, pathOr } from 'ramda'
import { createSelector } from 'reselect'

import { CONTACTS_CUSTOM_FIELD_CHAR_LIMIT } from '@/app/module/contacts/definitions'
import { selectUserTimezone } from '@/app/module/user/store/selectors'

// eslint-disable-next-line
export const selectCustomFields = compose(
  map((item) => ({
    ...item,
    id: item.id.toString(),
  })),
  pathOr([], ['customFields', 'data']),
)

export const selectHeaders = () => [
  {
    title: 'Name',
    fields: ['name'],
  },
  {
    title: 'Created',
    fields: ['createdAt'],
  },
  {
    title: 'Number of contacts',
    fields: ['numberOfContacts'],
  },
]

export const selectCustomFieldsItemConfig = createSelector(selectUserTimezone, () => ({
  id: {
    type: 'hidden',
    display: 'hidden',
  },
  name: {
    type: 'text',
    display: 'text',
    editable: true,
    label: 'Name',
    autoFocus: true,
    maxLength: CONTACTS_CUSTOM_FIELD_CHAR_LIMIT,
  },
}))
