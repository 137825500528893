import { keys, map, path, pathOr, reduce } from 'ramda'
import { createSelector } from 'reselect'

import { DEFAULT_SENDER_ID_NAME, DEFAULT_SENDER_ID_VALUE } from '@/app/definitions'
import { selectCustomFields } from '@/app/module/custom-fields/store/selectors'

const partSelectData = path(['logs', 'data'])

const partSelectSenderIds = createSelector(partSelectData, pathOr([], ['senderIds']))

export const selectSenderIdValues = createSelector(partSelectSenderIds, (senderIds) => [
  {
    label: DEFAULT_SENDER_ID_NAME,
    value: DEFAULT_SENDER_ID_VALUE,
  },
  ...map(
    ({ phonenumber, sender, name }) => ({
      label: name ? `${name} (${sender || phonenumber})` : sender || phonenumber,
      value: sender || phonenumber,
    }),
    pathOr([], ['sms'], senderIds),
  ),
])

export const selectPersonalizationList = createSelector(selectCustomFields, (cf) => ({
  contact: [
    {
      label: 'Contact first name',
      value: 'contact.firstName',
    },
    {
      label: 'Contact last name',
      value: 'contact.lastName',
    },
    {
      label: 'Contact phone number',
      value: 'contact.fullPhoneNumber',
    },
    {
      label: 'Contact language',
      value: 'contact.language',
    },
    {
      label: 'Contact ID',
      value: 'contact.id',
    },
  ],
  customfields: cf.map(({ id, name }) => ({
    label: name,
    value: `customfields.${id}`,
  })),
}))

export const apiPz = [
  {
    label: 'API Status Code',
    value: 'api.statusCode',
  },
  {
    label: 'API Success',
    value: 'api.success',
  },
  {
    label: 'API Content Type',
    value: 'api.contentType',
  },
  {
    label: 'API Response Body',
    value: 'api.body',
  },
  {
    label: 'API Request Snippet',
    value: 'api.bodySnippet',
  },
]

export const selectApiPersonalizationList = createSelector(selectPersonalizationList, (list) => ({
  contact: list.contact,
  api: apiPz,
  customfields: list.customfields,
}))

export const getFilters = ({ filters }) =>
  reduce(
    (acc, key) => {
      if (filters[key].value) {
        if (typeof filters[key].value === 'string') {
          acc[key] = filters[key].value
        } else {
          acc.from = filters[key].value.from
          acc.to = filters[key].value.to
        }
      }
      return acc
    },
    {},
    keys(filters),
  )
