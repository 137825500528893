import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { History } from 'history'
import { path } from 'ramda'
import React from 'react'

import SearchBar from '@/app/component/atom/search'
import Tooltip from '@/app/component/atom/tooltip'
import Loading from '@/app/component/guard/loading'
import ContentLayout from '@/app/component/layout/content'
import PowerTable from '@/app/component/layout/power-table'
import TableToolbar from '@/app/component/layout/table/toolbar'
import { getOrgSubs as getOrgSubsAction, navigateOrgSubs, setCATISubsPageSize } from '@/app/module/cati/store/actions'
import { OrgSubscriptionTableType } from '@/app/module/cati/types'
import { removeHiddenColumns } from '@/app/module/contacts/component/list/table/contacts-table'
import { generateTableColumns } from '@/app/service/util/helpers'
import { SortOrder } from '@/app/types'
import config from '@/config'
import { Column } from '@/config/types'

const pageSizeDefault = path(['modules', 'cati', 'pageSize', 'default'], config) as number

type Props = {
  headers: Column[]
  history: History
  loading: boolean
  orgId: number
  page: number
  pageSearchTerm?: string | null
  searchTerm: string | null
  size: number
  timezone: string
  token: string
  total: number
  userId: number
  values: OrgSubscriptionTableType[]
  getOrgSubs: typeof getOrgSubsAction
  navigate: typeof navigateOrgSubs
  setPageSize: typeof setCATISubsPageSize
}

export default function Subs(props: Props) {
  const {
    values,
    total,
    searchTerm,
    size = pageSizeDefault,
    page,
    navigate,
    history,
    setPageSize,
    orgId,
    token,
    getOrgSubs,
    headers,
    timezone,
    loading,
  } = props

  const subFields: Column[] = React.useMemo(
    () => [
      {
        label: '',
        key: '',
        sticky: 'left',
        action: 'check',
        visible: false,
      },
      ...removeHiddenColumns(headers),
      {
        label: 'Actions',
        key: 'action',
        sticky: 'right',
        action: 'actions',
        visible: true,
        submitAction: {
          label: 'Go to survey',
          icon: <Icon fontSize="small">chevron_right</Icon>,
          onClick: ({ campaignId, subId }: { campaignId: number; subId: number }) => {
            history.push(`/cati/${campaignId}/sub/${subId}`)
          },
        },
      },
    ],
    [headers, history],
  )

  const columns = React.useMemo(() => generateTableColumns(subFields, timezone), [subFields, timezone])

  const getSubs = React.useCallback(() => {
    if (!orgId || !token) {
      return
    }

    getOrgSubs({
      token,
      orgId,
      query: {
        direction: SortOrder.ASC,
        page,
        size,
        searchTerm: searchTerm || '',
        template: 'cati',
        subdone: 'false',
        skipdeletedcontacts: 'true',
      },
    })
  }, [orgId, token, searchTerm, page, size, getOrgSubs])

  const noData = React.useMemo(
    () => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '90px',
          width: '100%',
        }}
      >
        <Typography variant="h5" color="textSecondary">
          {searchTerm ? `No results for "${searchTerm}".` : 'No contacts were found.'}
        </Typography>
      </div>
    ),
    [searchTerm],
  )

  React.useEffect(() => {
    getSubs()
  }, [getSubs])

  return (
    <Loading isLoading={loading}>
      <ContentLayout>
        <TableToolbar>
          <SearchBar
            namespace="subs"
            tooltipTitle="Search contacts"
            searchTerm={searchTerm}
            onSubmit={(newSearchTerm: string) => {
              navigate({
                history,
                searchTerm: newSearchTerm,
                page,
              })
            }}
          />
          <Tooltip title="Reload contacts">
            <IconButton id="reload-subs" onClick={getSubs}>
              <Icon>refresh</Icon>
            </IconButton>
          </Tooltip>
        </TableToolbar>
        <div style={{ height: 'calc(100vh - 124px)' }}>
          <PowerTable
            style={{
              height: 'calc(100vh - 206px)',
              width: '100%',
              transition: 'width 0.3s',
              maxWidth: '100vw',
              boxShadow: '0px 0px 0px 1px rgb(224 224 224)',
            }}
            data={values}
            columns={columns}
            total={total}
            noData={noData}
            tableActions={null}
            page={page}
            onPageChange={(newPage: number) => {
              navigate({
                history,
                page: newPage,
                searchTerm: searchTerm || undefined,
              })
            }}
            pageSize={size}
            onPageSizeChange={(newPageSize: number) => {
              setPageSize(newPageSize)
            }}
            rowAction={() => {}}
          />
        </div>
      </ContentLayout>
    </Loading>
  )
}
