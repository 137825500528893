import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { pathEq } from 'ramda'
import { PureComponent } from 'react'

import FilterTags from '@/app/component/atom/filters/tags'
import SearchBar from '@/app/component/atom/search'
import Tooltip from '@/app/component/atom/tooltip'
import IconText from '@/app/component/layout/icon-text'
import TableToolbar from '@/app/component/layout/table/toolbar'
import { SEND_LIMIT } from '@/app/module/logs/definitions'
import withTheme from '@/config/withTheme'

import ConfigureTable from './configure-table'

class ContactsTableToolbar extends PureComponent {
  state = {
    bulkActionsMenuAnchor: null,
    createContactMenuAnchor: null,
  }

  toggleCreateContactMenu = (target = null) => {
    this.setState({ createContactMenuAnchor: target })
  }

  render() {
    const {
      theme,
      loading,
      filters,
      activeFilter,
      search,
      selection,
      reloadContacts,
      onSendMessage,
      onDownloadContacts,
      onDeleteContacts,
      onUploadContacts,
      onAddContacts,
      onAddGroups,
      onAddCustomFields,
      onAddSegmentsFields,
      onFilterChange,
      onAddContactsToGroup,
      onRemoveContactsFromGroup,
      toggleGroups = () => {},
      groupsFilter = false,
      headers,
      setHeaders,
    } = this.props
    return (
      <TableToolbar>
        <div style={{ display: 'flex', flex: '1 auto' }}>
          <div
            style={{
              display: 'flex',
              margin: '5px',
              marginLeft: '0px',
            }}
          >
            <div id="groups-toggle" onClick={toggleGroups}>
              <Tooltip id="show-hide-groups" title="Show/ Hide Group & Segments">
                <div>
                  <Button
                    style={{
                      marginRight: '5px',
                    }}
                    variant="outlined"
                    onClick={toggleGroups}
                    startIcon={
                      <Icon
                        style={{
                          transition: 'transform 150ms ease',
                          ...(!groupsFilter && {
                            transform: 'rotate(-180deg)',
                          }),
                        }}
                      >
                        menu_open
                      </Icon>
                    }
                  >
                    &nbsp;
                  </Button>
                </div>
              </Tooltip>
            </div>

            <div
              id="bulk-actions-button"
              onClick={(e) => {
                if (selection.count > 0) {
                  this.setState({
                    bulkActionsMenuAnchor: e.currentTarget,
                  })
                }
              }}
            >
              <Tooltip
                id="bulk-actions"
                title={
                  selection.count > 0 ? (
                    'Bulk actions'
                  ) : (
                    <span style={{ textAlign: 'center', display: 'block', lineHeight: '1.7' }}>
                      Bulk Actions
                      <br />
                      Select contacts to use this option
                    </span>
                  )
                }
              >
                <div>
                  <Button
                    variant="outlined"
                    onClick={(e) =>
                      this.setState({
                        bulkActionsMenuAnchor: e.currentTarget,
                      })
                    }
                    endIcon={<Icon>arrow_drop_down</Icon>}
                    startIcon={<Icon>more_vert</Icon>}
                  >
                    Bulk Actions
                  </Button>
                </div>
              </Tooltip>
            </div>
            <Menu
              disableAutoFocusItem={true}
              id="contacts-bulk-menu"
              open={!!this.state.bulkActionsMenuAnchor}
              onClose={() =>
                this.setState({
                  bulkActionsMenuAnchor: null,
                })
              }
              anchorEl={this.state.bulkActionsMenuAnchor}
            >
              <li
                onClick={() => {
                  if (selection.count > 0 && selection.count < SEND_LIMIT) {
                    this.setState({
                      bulkActionsMenuAnchor: null,
                    })
                    onSendMessage()
                  }
                }}
              >
                <Tooltip
                  id="send-message-action"
                  open={this.state.bulkActionsMenuAnchor && selection.count >= SEND_LIMIT ? undefined : false}
                  background={theme.palette.error.main}
                  title={`This feature supports up to ${SEND_LIMIT} contacts at a time only.`}
                >
                  <div>
                    <MenuItem component="span" disabled={selection.count < 1}>
                      <IconText>
                        <Icon>sms</Icon>
                        <Typography>Send message</Typography>
                      </IconText>
                    </MenuItem>
                  </div>
                </Tooltip>
              </li>
              <li
                onClick={() => {
                  if (selection.count > 0 || selection.isAll) {
                    this.setState({
                      bulkActionsMenuAnchor: null,
                    })
                    onDeleteContacts()
                  }
                }}
              >
                <Tooltip id="bulk-delete-action" title={selection.count < 1 && 'Please select at least one contact.'}>
                  <div>
                    <MenuItem component="span" disabled={selection.count < 1}>
                      <IconText>
                        <Icon>delete</Icon>
                        <Typography>Delete contacts</Typography>
                      </IconText>
                    </MenuItem>
                  </div>
                </Tooltip>
              </li>
              <li
                onClick={() => {
                  if (selection.count > 0) {
                    this.setState({
                      bulkActionsMenuAnchor: null,
                    })
                    onDownloadContacts()
                  }
                }}
              >
                <MenuItem component="span" disabled={selection.count < 1}>
                  <IconText>
                    <Icon>cloud_download</Icon>
                    <Typography>Download contacts</Typography>
                  </IconText>
                </MenuItem>
              </li>
              <li
                onClick={() => {
                  if (selection.count > 0) {
                    this.setState({
                      bulkActionsMenuAnchor: null,
                    })
                    onAddContactsToGroup()
                  }
                }}
              >
                <MenuItem component="span" disabled={selection.count < 1}>
                  <IconText>
                    <Icon>group_add</Icon>
                    <Typography>Add contacts to group</Typography>
                  </IconText>
                </MenuItem>
              </li>
              <li
                onClick={() => {
                  if (
                    !(
                      selection.count < 1 ||
                      !pathEq(['name'], 'groups', activeFilter) ||
                      selection.isAll ||
                      selection.search !== ''
                    )
                  ) {
                    if (selection.count > 0) {
                      this.setState({
                        bulkActionsMenuAnchor: null,
                      })
                      onRemoveContactsFromGroup()
                    }
                  }
                }}
              >
                <MenuItem
                  component="span"
                  disabled={
                    selection.count < 1 ||
                    !pathEq(['name'], 'groups', activeFilter) ||
                    selection.isAll ||
                    selection.search !== ''
                  }
                >
                  <IconText>
                    <Icon>remove_circle</Icon>
                    <Typography>Remove contacts from group</Typography>
                  </IconText>
                </MenuItem>
              </li>
            </Menu>
          </div>
          <FilterTags id="contacts-filter-tags" filters={filters.data} onChange={onFilterChange} />
        </div>
        <>
          {search && (
            <SearchBar
              namespace="contacts"
              tooltipTitle="Search contacts"
              disabled={loading}
              searchTerm={search.term}
              onSubmit={(searchTerm) => {
                selection.clearSelection()
                search.onSubmit(searchTerm)
              }}
            />
          )}
          <ConfigureTable headers={headers} setHeaders={setHeaders} />
          <Tooltip id="create-contact" title="Create contacts and groups">
            <Button
              id="new-contact-menu-button"
              color="primary"
              variant="contained"
              onClick={(e) => this.toggleCreateContactMenu(e.currentTarget)}
              endIcon={<Icon>arrow_drop_down</Icon>}
              startIcon={<Icon>add</Icon>}
            >
              New
            </Button>
          </Tooltip>
          <Tooltip id="reload-data" title="Reload contacts">
            <IconButton id="reload-contacts" onClick={() => reloadContacts()}>
              <Icon>refresh</Icon>
            </IconButton>
          </Tooltip>
          <Menu
            id="contact-create-menu"
            open={!!this.state.createContactMenuAnchor}
            onClose={() => this.toggleCreateContactMenu()}
            anchorEl={this.state.createContactMenuAnchor}
          >
            <MenuItem id="upload-contacts" onClick={() => onUploadContacts()}>
              <IconText>
                <Icon>cloud_upload</Icon>
                <Typography>Upload contacts</Typography>
              </IconText>
            </MenuItem>
            <Divider />
            <MenuItem
              id="add-contact-button"
              onClick={() => {
                this.toggleCreateContactMenu()
                onAddContacts()
              }}
            >
              <IconText>
                <Icon>person_outline</Icon>
                <Typography>Add contact</Typography>
              </IconText>
            </MenuItem>
            <MenuItem
              id="add-customfields-button"
              onClick={() => {
                this.toggleCreateContactMenu()
                onAddCustomFields()
              }}
            >
              <IconText>
                <Icon>bookmark</Icon>
                <Typography>Add custom fields</Typography>
              </IconText>
            </MenuItem>
            <MenuItem
              id="add-group-button"
              onClick={() => {
                this.toggleCreateContactMenu()
                onAddGroups()
              }}
            >
              <IconText>
                <Icon>group</Icon>
                <Typography>Add group</Typography>
              </IconText>
            </MenuItem>
            <MenuItem
              id="add-segments-button"
              onClick={() => {
                this.toggleCreateContactMenu()
                onAddSegmentsFields()
              }}
            >
              <IconText>
                <Icon>group_work</Icon>
                <Typography>Add segment</Typography>
              </IconText>
            </MenuItem>
          </Menu>
        </>
      </TableToolbar>
    )
  }
}

export default withTheme(ContactsTableToolbar)
