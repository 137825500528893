import { styled } from '@mui/material'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import Typography from '@mui/material/Typography'
import React from 'react'

import { SchemaDidsItem } from '@/__generated/es-api'
import Flag from '@/app/component/atom/flag'
import SupportedIcon from '@/app/component/atom/supported-icon'
import TableLayout from '@/app/component/layout/table'
import TableRow from '@/app/component/layout/table/row'
import { ChannelType } from '@/app/module/phonenumbers/types'

import ChannelLink from './channel-link'
import Phonenumber from './phone-number'

type Props = {
  loading: boolean
  pagination?: boolean
  phoneNumbers: SchemaDidsItem[]
}

const PhoneNumbersTable: React.FC<Props> = ({ loading, pagination = false, phoneNumbers }: Props) => {
  return (
    <div>
      <TableLayout
        loading={loading}
        pageSize={phoneNumbers.length}
        pagination={pagination}
        emptyMessage={'Please contact support to order a phone number.'}
      >
        <StyledTable>
          <TableHead>
            <TableRow>
              <StyledTableCell>Country</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="center">SMS</StyledTableCell>
              <StyledTableCell align="center">WhatsApp</StyledTableCell>
              <StyledTableCell align="center">Voice</StyledTableCell>
              <StyledTableCell>Incoming Rules</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {phoneNumbers.map(
              ({
                phoneNumber,
                id,
                name,
                hookCount,
                whatsappHookCount,
                inboundCallCampId,
                callHookCount,
                countryCode,
                sms,
                voice,
                whatsApp,
              }) => (
                <StyledTableRow key={id} isLoading={loading}>
                  <StyledTableCell align="center">
                    <Flag code={countryCode} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Phonenumber phoneNumber={phoneNumber} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>{name}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <SupportedIcon hideNotSupported supported={sms} />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <SupportedIcon hideNotSupported supported={whatsApp} />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <SupportedIcon hideNotSupported supported={voice} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <LinkContainer>
                      {sms && <ChannelLink channel={ChannelType.Sms} hooksCount={hookCount} id={id} />}
                      {whatsApp && (
                        <ChannelLink channel={ChannelType.WhatsApp} hooksCount={whatsappHookCount} id={id} />
                      )}
                      {voice && <ChannelLink channel={ChannelType.Voice} hooksCount={callHookCount} id={id} />}
                    </LinkContainer>
                  </StyledTableCell>
                  <StyledTableCell>
                    {voice && (
                      <ChannelLink channel={ChannelType.Campaign} hooksCount={inboundCallCampId ?? 0} id={id} />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ),
            )}
          </TableBody>
        </StyledTable>
      </TableLayout>
      <Divider />
    </div>
  )
}

const StyledTable = styled(Table)(() => ({
  border: '1px solid rgba(224, 224, 224, 1)',
}))

const StyledTableRow = styled(TableRow)(() => ({
  padding: '15px 0px',
  '&:hover': {
    cursor: 'default !important',
  },
}))

const StyledTableCell = styled(TableCell)(() => ({
  padding: '16px',
}))

const LinkContainer = styled('div')(() => ({
  display: 'flex',
  gap: 16,
}))

export default PhoneNumbersTable
