import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import { isEmpty, values } from 'ramda'
import { Component } from 'react'
import { withStyles } from 'tss-react/mui'

import Loading from '@/app/component/guard/loading'

import ContactsUploadConfigureActions from './steps/configure/actions'
import ContactsUploadConfigureContent from './steps/configure/content'
import ContactsUploadCountriesActions from './steps/countries/actions'
import ContactsUploadCountriesContent from './steps/countries/content'
import ContactsUploadReviewActions from './steps/review/actions'
import ContactsUploadReviewContent from './steps/review/content'
import ContactsUploadSuccessActions from './steps/success/actions'
import ContactsUploadSuccessContent from './steps/success/content'
import ContactsUploadInitActions from './steps/upload/actions'
import ContactsUploadInitContent from './steps/upload/content'

const isAlternativeLabel = () => window.innerWidth < 750

const getStepTitle = (title) => (window.innerWidth < 480 ? '' : title)

const styles = () => ({
  wrap: {
    width: '100%',
    outline: 'none',
  },
  stepper: {
    padding: '24px 0',
  },
})

class ContactsUpload extends Component {
  state = {
    fileName: '',
    file: false,
    activeStep: 0,
    dropError: {},
    countriesOption: 'single',
    countryValue: {
      input: '',
      code: undefined,
    },
    countryCodeError: '',
    columnConfig: {},
    columnStatus: [],
    columnErrors: [],
  }

  steps = {
    0: {
      title: 'Upload',
      Actions: () => (
        <ContactsUploadInitActions
          closeHandler={this.closeHandler}
          submitHandler={this.submitUploadHandler}
          isSubmitDisabled={!this.state.file || this.state.loading}
        />
      ),
      Content: ({ props, state }) => (
        <ContactsUploadInitContent
          id="contact-upload-container"
          error={{
            ...props.uploadError,
            ...state.dropError,
          }}
          file={state.file}
          fileName={state.fileName}
          onAccept={({ file, fileName, error = {} }) =>
            this.setState({
              dropError: error,
              file,
              fileName,
            })
          }
          onDelete={() =>
            this.setState({
              dropError: {},
              file: false,
              fileName: '',
            })
          }
        />
      ),
    },
    1: {
      title: 'Select country',
      Actions: ({ state }) => (
        <ContactsUploadCountriesActions
          backHandler={this.stepBackHandler}
          submitHandler={this.submitCountriesHandler}
          isSubmitDisabled={
            state.countryCodeError !== '' ||
            (state.countriesOption === 'single' && state.countryValue.code === undefined) ||
            state.loading
          }
        />
      ),
      Content: ({ props, state }) => (
        <ContactsUploadCountriesContent
          countriesList={props.countriesList}
          countriesOption={state.countriesOption}
          countryValue={state.countryValue}
          countryCodeError={state.countryCodeError}
          onCountryOptionChange={({ value, error = '' }) =>
            this.setState({
              error: {},
              countriesOption: value,
              countryCodeError: error,
              countryValue: value === 'single' ? state.countryValue : { input: '', code: undefined },
            })
          }
          onCountryCodeChange={({ value, error }) =>
            this.setState({
              error: {},
              countryValue: value,
              countryCodeError: error,
            })
          }
        />
      ),
    },
    2: {
      title: 'Configure',
      Actions: ({ state }) => (
        <ContactsUploadConfigureActions
          backHandler={this.stepBackHandler}
          submitHandler={() => this.submitConfigureHandler({ state })}
          isSubmitDisabled={isEmpty(state.columnConfig) || !!state.columnErrors.find((err) => err !== '')}
        />
      ),
      Content: ({ props, state }) => (
        <ContactsUploadConfigureContent
          token={props.token}
          orgId={props.orgId}
          customFieldsLoading={props.customFieldsLoading}
          specs={props.specs}
          statuses={state.columnStatus}
          configs={state.columnConfig}
          errors={state.columnErrors}
          onConfigChange={({ errors, configs }) => {
            this.setState(() => ({
              columnErrors: errors,
              columnConfig: configs,
            }))
          }}
          onStatusChange={({ errors, statuses }) => {
            this.setState({
              columnErrors: errors,
              columnStatus: statuses,
            })
          }}
          submit={props.addCustomFieldsItem}
        />
      ),
    },
    3: {
      title: 'Review',
      Actions: ({ props, state }) => (
        <ContactsUploadReviewActions
          specs={props.specs}
          statuses={state.columnStatus}
          configs={state.columnConfig}
          backHandler={this.stepBackHandler}
          submitHandler={(item) =>
            this.submitReviewHandler({
              ...item,
              regionCodeISO: state.countryValue.code,
            })
          }
        />
      ),
      Content: ({ props, state }) => (
        <ContactsUploadReviewContent specs={props.specs} statuses={state.columnStatus} configs={state.columnConfig} />
      ),
    },
    4: {
      title: 'Finish',
      Actions: () => <ContactsUploadSuccessActions closeHandler={this.closeHandler} />,
      Content: ({ props }) => <ContactsUploadSuccessContent error={props.uploadConfirmError} />,
    },
  }

  componentDidUpdate(prevProps) {
    const { uploadData } = this.props
    if (uploadData && prevProps.uploadData.uploadKey !== uploadData.uploadKey) {
      this.setState({
        columnConfig: uploadData,
        columnStatus: !isEmpty(uploadData) ? uploadData.titlesFromFile.map(() => true) : this.state.columnStatus,
        columnErrors: !isEmpty(uploadData) ? uploadData.titlesFromFile.map(() => '') : this.state.columnErrors,
      })
    }
  }

  submitUploadHandler = () => {
    const { token, orgId, uploadContacts } = this.props

    uploadContacts({
      token,
      orgId,
      file: this.state.file,
    }).then((res) => {
      if (!(res instanceof Error)) {
        this.stepForwardHandler()
      }
    })
  }

  submitCountriesHandler = () => {
    this.stepForwardHandler()
  }

  submitConfigureHandler = ({ state }) => {
    const { columnConfig, columnStatus, columnErrors } = state

    if (columnErrors.find((error) => error !== '')) {
      return
    }

    const errors = columnConfig.titlesFromFile.map((title, i) => {
      if (
        columnStatus[i] &&
        values(columnConfig.fieldsToColumns).indexOf(i.toString()) === -1 &&
        values(columnConfig.customFieldsToColumns).indexOf(i.toString()) === -1
      ) {
        return 'Select a value or disable column'
      }
      return ''
    })

    if (errors.find((error) => error !== '')) {
      this.setState({
        columnErrors: errors,
      })
      return
    }

    this.stepForwardHandler()
  }

  submitReviewHandler = (item) => {
    const { token, orgId, confirmUpload, getUploadStatus, uploadData, size } = this.props

    return confirmUpload({
      token,
      orgId,
      item,
    }).then((res) => {
      if (res && !(res instanceof Error)) {
        this.stepForwardHandler()
        getUploadStatus({
          token,
          orgId,
          uploadKey: uploadData.uploadKey,
          query: {
            page: 1,
            size,
          },
        })
      }
    })
  }

  stepForwardHandler = () => {
    this.setState((prevState) => ({
      activeStep: Math.min(prevState.activeStep + 1, Object.keys(this.steps).length - 1),
    }))
  }

  stepBackHandler = () => {
    this.setState((prevState) => ({
      error: {},
      loading: false,
      activeStep: Math.max(0, prevState.activeStep - 1),
    }))
  }

  closeHandler = () => {
    const { page, history } = this.props
    history.push(`/contacts/page/${page}`)
  }

  render() {
    const classes = withStyles.getClasses(this.props)
    const { loading } = this.props

    const { activeStep } = this.state

    const { Content, Actions } = this.steps[activeStep]

    return (
      <div className={classes.wrap}>
        <Loading isLoading={loading}>
          <CardContent>
            <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel={isAlternativeLabel()}>
              {Object.keys(this.steps).map((key) => (
                <Step key={key}>
                  <StepLabel>{getStepTitle(this.steps[key].title)}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Content props={this.props} state={this.state} {...this.state} />
          </CardContent>
          <CardActions className={classes.actions}>
            <Actions props={this.props} state={this.state} />
          </CardActions>
        </Loading>
      </div>
    )
  }
}

export default withStyles(ContactsUpload, styles)
