import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import MUIRadio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'

import FormControl from './form-control'

type Value = {
  value: string
  label: string
  editable?: boolean
}

type RadioProps = {
  id?: string
  name: string
  none?: Value
  value: string
  values: Value[]
  error?: string
  onChange: (data: { name: string; value: string }) => void
  row?: boolean
  label?: string
}

export default function Radio({
  id,
  name,
  none,
  value,
  values = [],
  error,
  onChange,
  row = false,
  label = '',
}: RadioProps) {
  return (
    <FormControl data-testid={id} error={error}>
      {label && <FormLabel>{label}</FormLabel>}
      <RadioGroup id={id} name={name} value={value} row={row}>
        {none && (
          <FormControlLabel
            label={none.label}
            value={none.value}
            disabled={!none.editable}
            control={
              <MUIRadio
                size="small"
                color="primary"
                inputProps={{ name }}
                checked={value === none.value}
                onChange={(e) => {
                  e.persist?.()
                  onChange({
                    name,
                    value: none.value,
                  })
                }}
              />
            }
          />
        )}
        {values.map(({ value: val, label: valName, editable = true }) => (
          <FormControlLabel
            key={`radio-value-${valName}-${val}`}
            label={
              <Typography
                style={{
                  fontSize: 14,
                }}
              >
                {valName}
              </Typography>
            }
            value={val}
            disabled={!editable}
            control={
              <MUIRadio
                size="small"
                id={id && `${id}-${value}`}
                color="primary"
                inputProps={{ name }}
                checked={value === val}
                onChange={(e) => {
                  e.persist?.()
                  onChange({
                    name,
                    value: val,
                  })
                }}
              />
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
