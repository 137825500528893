import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import React from 'react'

import IconText from '@/app/component/layout/icon-text'
import { PersonalizationType } from '@/app/module/campaigns/types'
import Message from '@/app/module/logs/component/send/message'

import { InfoContainer } from './common'

type Props = {
  audio: string | null
  autoFocus?: boolean
  editable?: boolean
  error: string
  info: string
  label: string
  personalizationList: PersonalizationType
  required?: boolean
  text: string

  changeHandler: ({ message, text }: { message?: string; text: string }) => void
  onBlur: ({ message }: { message: string }) => void
  onErrorChange: (error: string) => void
}

const TextMessage: React.FC<Props> = ({
  autoFocus,
  editable,
  error,
  info,
  label,
  personalizationList,
  required = true,
  text,

  changeHandler,
  onBlur,
  onErrorChange,
}) => {
  return (
    <>
      {info && (
        <InfoContainer>
          <IconText>
            <Icon>info</Icon>
            <Typography variant="caption" color="textSecondary">
              {info}
            </Typography>
          </IconText>
        </InfoContainer>
      )}
      <Message
        autoFocus={autoFocus}
        editable={editable}
        label={`WhatsApp ${label}`}
        message={text}
        whatsapp
        error={error}
        personalizationList={personalizationList}
        onChange={({ message: updatedValue }) => {
          changeHandler({ text: updatedValue })
          onErrorChange(updatedValue ? '' : error)
        }}
        onBlur={({ message: value }) => {
          if (!value && required) {
            onErrorChange('WhatsApp message needs to have text added')
          }
          onBlur({ message: value })
        }}
      />
    </>
  )
}

export default TextMessage
export type { Props as TextMessageProps }
