import React from 'react'

import { CampaignItemWithVoice } from '@/app/module/campaigns/types'

import Voicemail from './voicemail'

type Props = {
  item: CampaignItemWithVoice

  onChange: (item: CampaignItemWithVoice) => void
}

const VoicemailContainer: React.FC<Props> = ({ item, onChange }) => <Voicemail item={item} onChange={onChange} />

export default VoicemailContainer
