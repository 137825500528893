import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import { Fragment } from 'react'

import FormControl from './form-control'

type DynamicInputsProps = {
  fields: string[]
  editable?: boolean
  readOnly?: boolean
  values?: Record<string, string>[]
  errors: Record<string, boolean>[]
  name: string
  onChange: (arg: { position: number; field: string; value: any }) => void
  addRow: () => void
  removeRow: (arg: { position: number }) => void
  onFocus?: () => void
  onBlur?: () => void
  firstRowLock?: boolean
}

export default function DynamicInputs({
  fields = [],
  editable = true,
  readOnly = false,
  values = [],
  errors = [],
  name = '',
  onChange = () => {},
  addRow = () => {},
  removeRow = () => {},
  onFocus = () => {},
  onBlur = () => {},
  firstRowLock = false,
  ...rest
}: DynamicInputsProps) {
  return (
    <div>
      {values.map((value, i) => (
        <FormControl key={i}>
          <Fragment>
            {fields.map((field, j) => (
              <TextField
                variant="standard"
                key={`${field}-${i}-${j}`}
                name={`${name}-${i}-${field}`}
                label={field}
                error={!!errors[i][field]}
                disabled={!editable}
                value={values[i][field]}
                autoComplete="off"
                onFocus={onFocus}
                onBlur={onBlur}
                style={{
                  width: `${80 / fields.length}%`,
                  marginRight: '2%',
                }}
                InputProps={{
                  readOnly,
                }}
                onChange={(e) => {
                  e.persist?.()
                  e.preventDefault()
                  onChange({
                    position: i,
                    field,
                    value: e.target.value,
                  })
                }}
                {...rest}
              />
            ))}
          </Fragment>
          <div
            style={{
              display: 'inline-block',
              textAlign: 'right',
              width: '16%',
            }}
          >
            <Fragment>
              {(!firstRowLock || i !== 0) && (
                <IconButton
                  id={`${name}-remove-row-btn-${i}`}
                  onClick={() => {
                    removeRow({ position: i })
                  }}
                >
                  <Icon>remove_circle</Icon>
                </IconButton>
              )}
            </Fragment>
            <Fragment>
              {i === values.length - 1 && (
                <IconButton id={`${name}-add-row-btn`} onClick={addRow}>
                  <Icon>add_circle</Icon>
                </IconButton>
              )}
            </Fragment>
          </div>
        </FormControl>
      ))}
    </div>
  )
}
