import React from 'react'

export const useDimensions = () => {
  const [windowHeight, setWindowHeight] = React.useState(0)
  const [windowWidth, setWindowWidth] = React.useState(0)

  React.useEffect(() => {
    let ref: number = 0
    const updateWindowDimensions = () => {
      if (!ref) {
        ref = requestAnimationFrame(() => {
          updateWindowDimensions()
          ref = 0
        })
      }
      setWindowHeight(window.innerHeight)
      setWindowWidth(window.innerWidth)
    }

    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)

    return () => {
      cancelAnimationFrame(ref)
      window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [])

  return {
    windowHeight,
    windowWidth,
  }
}
