import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { selectToken } from '@/app/module/auth/store/selectors'
import { createNested } from '@/app/service/util'

import '@/app/module/logs/store/selectors'

import { selectOrgId } from '@/app/module/user/store/selectors'

import Item from './component/item'
import { addCustomFieldsItem, getCustomFields } from './store/actions'

const itemSelector = ({ auth, user, customFields }) => ({
  hydrateProps: ['token', 'orgId', 'itemId'],
  error: {
    ...customFields.error,
  },
  loading: customFields.loading,
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
})

const itemDispatcher = (dispatch) =>
  bindActionCreators(
    {
      submit: ({ token, orgId, item }) =>
        addCustomFieldsItem({
          token,
          orgId,
          item: createNested(item),
        }),
      getCustomFields,
    },
    dispatch,
  )

// eslint-disable-next-line import/prefer-default-export
export const CustomFieldsItem = connect(itemSelector, itemDispatcher)(Item)
