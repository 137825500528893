import { Button, Card, CardActions, CardHeader, styled, Typography } from '@mui/material'
import React from 'react'

import { openIntercom } from '@/app/service/intercom/utils'

type Props = {}

const CustomPlanItem: React.FC<Props> = () => {
  const handleClick = React.useCallback(() => openIntercom('show'), [])

  return (
    <StyledCard>
      <StyledCardHeader title="Need more?" titleTypographyProps={{ align: 'center' }} />
      <CardContent>
        <PricingContainer>
          <Typography color="secondary" variant="h6">
            Contact us for pricing
          </Typography>
        </PricingContainer>
        <div>
          <FeatureRow>
            <Typography variant="body1">More contacts?</Typography>
          </FeatureRow>
          <FeatureRow>
            <Typography variant="body1">More users?</Typography>
          </FeatureRow>
          <FeatureRow>
            <Typography variant="body1">More countries?</Typography>
          </FeatureRow>
        </div>
      </CardContent>
      <CardActions>
        <div style={{ width: '100%' }}>
          <Button color="secondary" fullWidth variant="outlined" onClick={handleClick}>
            Contact Us
          </Button>
        </div>
      </CardActions>
    </StyledCard>
  )
}

const StyledCard = styled(Card)({
  height: 'auto',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
})

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  height: '63px',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
}))

const CardContent = styled('div')({
  backgroundColor: 'rgb(245, 245, 245)',
  color: 'rgba(0, 0, 0, 0.54)',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '10px 1px',
})

const PricingContainer = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const FeatureRow = styled('div')({
  display: 'flex',
  margin: '5px 10px',
})

export default CustomPlanItem
