import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Notifications from '@/app/module/notifications'
import Plans from '@/app/module/plans'

export default function MessagesPage({ name, routes, match }) {
  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <Layout title="Plans - engageSPARK">
        <AuthHeader currentPage={PAGE.PLANS.INDEX} />
        <Plans />
      </Layout>
      <Notifications />
    </AuthGuard>
  )
}
