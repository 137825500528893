import { find, map, prop, propEq, remove } from 'ramda'

import { Condition } from '@/app/types'

import { operands } from './definitions'

export type stringMap = {
  [key: string]: string
}

export type conditionType = {
  label: string
  value: string
  keywords: string[]
}

export type ListType = []

const concatenateKeywords = (keywords: string[], type: string) =>
  keywords.map((keyword: string, index: number) => (
    <>
      {index > 0 ? ` ${type === '!=' ? 'n' : ''}or` : ''} <strong>{keyword}</strong>
    </>
  ))

const getTypeLabel = (value: any) => {
  const found = find(propEq('value', value), operands)
  if (found) {
    return prop('label', found)
  }
  return ''
}

export const parseTextList = (list: conditionType[]): any =>
  list.map((condition: conditionType, index: number) => (
    <>
      {index > 0 ? ' and' : `that ${getTypeLabel(condition.value)}`}
      <>{concatenateKeywords(condition.keywords, condition.value)}</>
    </>
  ))

export const titleMap: stringMap = {
  sms: 'SMS',
  voice: 'voice call',
  whatsapp: 'WhatsApp message',
}

export const conditionText = ' that matches your selected conditions.'

export const addConditionText =
  'Do you want these actions to apply only under certain conditions, e.g., if the incoming SMS starts with a keyword?'

export const generateSummaryText = (
  actionsLength: number,
  unexpected: boolean,
  type: string,
  noConditions: boolean,
): JSX.Element => (
  <>
    Take the below{' '}
    {actionsLength > 0 ? (
      <>
        {actionsLength > 1 && <strong>{actionsLength}</strong>} action{actionsLength !== 1 ? 's' : ''}
      </>
    ) : (
      <>actions</>
    )}{' '}
    for every {unexpected ? 'unexpected' : ''} incoming {titleMap[type]}
    {!noConditions ? conditionText : ''}
  </>
)

const ungroupConditions = (conditions: Condition): Condition[] => map((condition) => ['and', condition], conditions)

export const transformConditions = (conditions: Condition | null): Condition | null => {
  if (Array.isArray(conditions)) {
    if (conditions.length > 1 && conditions[0] && Array.isArray(conditions[1]) && conditions[1][0] === 'or') {
      const grouped = remove(0, 1, conditions[1]) as Condition
      return ['or', ...ungroupConditions(grouped)]
    }
    if (conditions.length === 1) {
      return null
    }
    return conditions
  }
  return null
}
