import { EventTracker } from '@devexpress/dx-react-chart'
import { Chart, Legend, PieSeries, Tooltip } from '@devexpress/dx-react-chart-material-ui'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { pathOr } from 'ramda'
import React from 'react'

import IconText from '@/app/component/layout/icon-text'

const getMessageContent = (message: string | { text: string }) => {
  if (typeof message === 'object') {
    return message.text
  }
  return message
}

type ChartTooltipType = React.ComponentProps<typeof Tooltip>['contentComponent']

const getChartTooltip = (chartData: MessageDetailsProps['chartData']): ChartTooltipType => {
  return function ChartTooltip({ text, targetItem, ...restProps }) {
    const legendLabel = pathOr(text, [targetItem.point, 'legendLabel'], chartData)
    return <Tooltip.Content {...restProps} text={legendLabel} targetItem={targetItem} />
  }
}

type MessageDetailsProps = {
  name: string
  message: string | { text: string }
  icon: string
  isQuestion: boolean
  id: string
  chartData: Array<{ key: string; value: number; percentage: string; legendLabel?: string }>
  total: number
}

type ChartTooltipTarget = React.ComponentProps<typeof Tooltip>['targetItem']

export default function MessageDetails({ name, message, icon, isQuestion, id, chartData, total }: MessageDetailsProps) {
  const [expanded, setExpanded] = React.useState(false)

  const toggleExpanded = () => setExpanded(!expanded)

  const [targetItem, setTargetItem] = React.useState<undefined | ChartTooltipTarget>(undefined)

  return (
    <Accordion square expanded={expanded} onChange={toggleExpanded} key={id}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Icon
          style={{
            color: 'rgba(0, 0, 0, 0.54)',
            marginRight: '15px',
            marginTop: '15px',
          }}
        >
          {icon}
        </Icon>
        <div
          style={{
            flex: 1,
          }}
        >
          <Typography
            variant="subtitle1"
            color="textPrimary"
            style={{
              paddingRight: '50px',
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{
              paddingRight: '50px',
            }}
          >
            {getMessageContent(message)}
          </Typography>
        </div>
        <div>
          {isQuestion && (
            <Button
              className="campaign-summary-show-answers"
              variant="outlined"
              style={{
                borderRadius: '5px',
                marginTop: '15px',
              }}
            >
              <IconText>
                <Icon
                  style={{
                    transform: expanded ? 'rotate(180deg)' : 'rotate(0)',
                    transitionDuration: '0.4s',
                    transitionProperty: 'transform',
                  }}
                  color="primary"
                >
                  pie_chart
                </Icon>
                <Typography color="textPrimary">{`${expanded ? 'Hide' : 'View'} Answers`}</Typography>
              </IconText>
            </Button>
          )}
        </div>
      </AccordionSummary>
      {isQuestion && (
        <AccordionDetails>
          <div
            style={{
              flex: 1,
            }}
          >
            <Divider />
            <Grid container spacing={2}>
              {chartData.length > 0 ? (
                <React.Fragment>
                  <Grid item md={4}>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      style={{
                        textAlign: 'center',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                      }}
                    >
                      Answer Breakdown
                    </Typography>
                    <div
                      style={{
                        maxHeight: '300px',
                        overflowY: 'scroll',
                      }}
                    >
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Answer</TableCell>
                            <TableCell>Count</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {chartData.map(({ key, value, percentage }) => (
                            <TableRow className="campaign-summary-answer-row" key={key}>
                              <TableCell className="campaign-summary-answer-row-label" scope="row">
                                {key}
                              </TableCell>
                              <TableCell className="campaign-summary-answer-row-value">{`${value} (${percentage})`}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                    <div
                      style={{
                        fontWeight: '500',
                      }}
                    >
                      <Table size="small">
                        <TableBody>
                          <TableRow key={`total-chart-${id}`}>
                            <TableCell scope="row">
                              <Typography variant="h6">TOTAL</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h6">{total} (100%)</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </Grid>
                  <Grid item md={8}>
                    <div
                      style={{
                        width: '400px',
                        height: '400px',
                        margin: 'auto',
                      }}
                    >
                      <Chart data={chartData} width={400} height={400}>
                        <PieSeries valueField="value" argumentField="legendLabel" />
                        <EventTracker />
                        <Tooltip
                          targetItem={targetItem}
                          onTargetItemChange={setTargetItem}
                          contentComponent={getChartTooltip(chartData)}
                        />
                        <Legend
                          rootComponent={({ children }) => (
                            <div style={{ overflowY: 'scroll', maxHeight: '400px' }}>{children}</div>
                          )}
                        />
                      </Chart>
                    </div>
                  </Grid>
                </React.Fragment>
              ) : (
                <Grid item md={12}>
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    style={{
                      marginTop: '30px',
                      textAlign: 'center',
                    }}
                  >
                    No answers added yet!
                  </Typography>
                </Grid>
              )}
            </Grid>
          </div>
        </AccordionDetails>
      )}
    </Accordion>
  )
}
