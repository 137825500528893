import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { cannot } from '@/app/helpers'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_DELETE_PHONENUMBER_HOOK_SERVER } from '@/app/module/phonenumbers/definitions'
import { invalidatePhoneNumbersCache } from '@/app/module/phonenumbers/store/utils'
import { ChannelType } from '@/app/module/phonenumbers/types'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { esApi } from '@/app/store/api'
import { useAppDispatch } from '@/app/store/redux-hooks'

/**
 * Delete an inbound hook from a phone number
 */
export function useDeleteHook(type: ChannelType.Sms | ChannelType.Voice | ChannelType.WhatsApp) {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const [deleteSMSHook, deleteSMSHookRes] = esApi.endpoints.deleteOrgDidHook.useMutation()
  const [deleteVoiceHook, deleteVoiceHookRes] = esApi.endpoints.deleteOrgDidCallHook.useMutation()
  const [deleteWaHook, deleteWaHookRes] = esApi.endpoints.deleteOrgDidWaHook.useMutation()

  const method = useMemo(() => {
    switch (type) {
      case ChannelType.Sms:
        return deleteSMSHook
      case ChannelType.Voice:
        return deleteVoiceHook
      case ChannelType.WhatsApp:
        return deleteWaHook
      default:
        return cannot(type)
    }
  }, [type, deleteSMSHook, deleteVoiceHook, deleteWaHook])

  const res = useMemo(() => {
    switch (type) {
      case ChannelType.Sms:
        return deleteSMSHookRes
      case ChannelType.Voice:
        return deleteVoiceHookRes
      case ChannelType.WhatsApp:
        return deleteWaHookRes
      default:
        return cannot(type)
    }
  }, [deleteSMSHookRes, deleteVoiceHookRes, deleteWaHookRes, type])

  const deleteFn = useCallback(
    async (didId: number, hookId: number) => {
      try {
        await method({
          orgId: orgId.toString(),
          didId: didId.toString(),
          hookId: hookId.toString(),
        }).unwrap()
        invalidatePhoneNumbersCache(dispatch)
        dispatch(
          createNotification({
            'delete-hook': {
              type: 'success',
              message: 'Rule deleted successfully.',
            },
          }),
        )
        return true
      } catch {
        dispatch(
          createNotification({
            'delete-hook': {
              type: 'error',
              message: ERROR_DELETE_PHONENUMBER_HOOK_SERVER,
            },
          }),
        )
        return false
      }
    },
    [orgId, dispatch, method],
  )

  return [deleteFn, res] as const
}
