import moment from 'moment'
import { pathOr } from 'ramda'

import { setToken } from '@/app/module/auth/helpers'
import { login, loginSuccess } from '@/app/module/auth/store/actions'
import { createNotification } from '@/app/module/notifications/store/actions'
import { getPlan } from '@/app/module/plans/store/actions'
import request from '@/app/module/request'
import {
  ACTION_CREATE_API_KEY_FAILURE,
  ACTION_CREATE_API_KEY_REQUEST,
  ACTION_CREATE_API_KEY_SUCCESS,
  ACTION_DELETE_API_KEY_FAILURE,
  ACTION_DELETE_API_KEY_REQUEST,
  ACTION_DELETE_API_KEY_SUCCESS,
  ACTION_DELETE_INVITATION_FAILURE,
  ACTION_DELETE_INVITATION_REQUEST,
  ACTION_DELETE_INVITATION_SUCCESS,
  ACTION_DELETE_USER_FAILURE,
  ACTION_DELETE_USER_REQUEST,
  ACTION_DELETE_USER_SUCCESS,
  ACTION_DISABLE_TOTP_FAILURE,
  ACTION_DISABLE_TOTP_REQUEST,
  ACTION_DISABLE_TOTP_SUCCESS,
  ACTION_DOWNLOAD_AUDIT_LOG_FAILURE,
  ACTION_DOWNLOAD_AUDIT_LOG_REQUEST,
  ACTION_DOWNLOAD_AUDIT_LOG_SUCCESS,
  ACTION_ENABLE_TOTP_FAILURE,
  ACTION_ENABLE_TOTP_REQUEST,
  ACTION_ENABLE_TOTP_SUCCESS,
  ACTION_GET_API_KEYS_FAILURE,
  ACTION_GET_API_KEYS_REQUEST,
  ACTION_GET_API_KEYS_SUCCESS,
  ACTION_GET_INVITATIONS_FAILURE,
  ACTION_GET_INVITATIONS_REQUEST,
  ACTION_GET_INVITATIONS_SUCCESS,
  ACTION_GET_RECOVERY_CODES_FAILURE,
  ACTION_GET_RECOVERY_CODES_REQUEST,
  ACTION_GET_RECOVERY_CODES_SUCCESS,
  ACTION_GET_SNIPPETS_FAILURE,
  ACTION_GET_SNIPPETS_REQUEST,
  ACTION_GET_SNIPPETS_SUCCESS,
  ACTION_GET_USER_FAILURE,
  ACTION_GET_USER_REQUEST,
  ACTION_GET_USER_SUCCESS,
  ACTION_GET_USERS_FAILURE,
  ACTION_GET_USERS_REQUEST,
  ACTION_GET_USERS_SUCCESS,
  ACTION_INIT_TOTP_FAILURE,
  ACTION_INIT_TOTP_REQUEST,
  ACTION_INIT_TOTP_SUCCESS,
  ACTION_INVITE_USER_FAILURE,
  ACTION_INVITE_USER_REQUEST,
  ACTION_INVITE_USER_SUCCESS,
  ACTION_REMOVE_RECOVERY_CODES,
  ACTION_RESET_RECOVERY_CODES_FAILURE,
  ACTION_RESET_RECOVERY_CODES_REQUEST,
  ACTION_RESET_RECOVERY_CODES_SUCCESS,
  ACTION_SAVE_PASSWORD_FAILURE,
  ACTION_SAVE_PASSWORD_REQUEST,
  ACTION_SAVE_PASSWORD_SUCCESS,
  ACTION_SAVE_SNIPPET_FAILURE,
  ACTION_SAVE_SNIPPET_REQUEST,
  ACTION_SAVE_SNIPPET_SUCCESS,
  ACTION_SAVE_USER_FAILURE,
  ACTION_SAVE_USER_REQUEST,
  ACTION_SAVE_USER_SUCCESS,
  ACTION_SWITCH_ORGANIZATION_FAILURE,
  ACTION_SWITCH_ORGANIZATION_REQUEST,
  ACTION_SWITCH_ORGANIZATION_SUCCESS,
  ACTION_TOGGLE_CALL_DIALOG,
  ACTION_UPDATE_API_KEY_FAILURE,
  ACTION_UPDATE_API_KEY_REQUEST,
  ACTION_UPDATE_API_KEY_SUCCESS,
  ACTION_UPDATE_USER_FAILURE,
  ACTION_UPDATE_USER_REQUEST,
  ACTION_UPDATE_USER_SUCCESS,
  ERROR_CREATE_API_KEY_SERVER,
  ERROR_DELETE_API_KEY_SERVER,
  ERROR_DELETE_INVITATIONS,
  ERROR_DELETE_USER_SERVER,
  ERROR_DISABLE_TOTP,
  ERROR_DOWNLOAD_AUDIT_LOG,
  ERROR_ENABLE_TOTP,
  ERROR_GET_API_KEYS_SERVER,
  ERROR_GET_INVITATIONS,
  ERROR_GET_RECOVERY_CODES,
  ERROR_GET_SNIPPETS_SERVER,
  ERROR_GET_USER_SERVER,
  ERROR_GET_USERS_SERVER,
  ERROR_INIT_TOTP,
  ERROR_INVITE_USER,
  ERROR_RESET_RECOVERY_CODES,
  ERROR_SAVE_PASSWORD_SERVER,
  ERROR_SAVE_SNIPPET_SERVER,
  ERROR_SAVE_USER_SERVER,
  ERROR_SWITCH_ORGANIZATION_SERVER,
  ERROR_UPDATE_API_KEY_SERVER,
  ERROR_UPDATE_USER_SERVER,
} from '@/app/module/user/definitions'
import { createAsyncActions } from '@/app/service/util'
import { downloadFile } from '@/app/service/util/download'
import { isFailed } from '@/app/service/util/error'
import { processErrors } from '@/app/service/util/errorCodes'
import http from '@/config/http'

const {
  request: getUserRequest,
  success: getUserSuccess,
  failure: getUserFailure,
} = createAsyncActions({
  request: ACTION_GET_USER_REQUEST,
  success: ACTION_GET_USER_SUCCESS,
  failure: ACTION_GET_USER_FAILURE,
})

const {
  request: saveUserRequest,
  success: saveUserSuccess,
  failure: saveUserFailure,
} = createAsyncActions({
  request: ACTION_SAVE_USER_REQUEST,
  success: ACTION_SAVE_USER_SUCCESS,
  failure: ACTION_SAVE_USER_FAILURE,
})

const {
  request: switchOrganizationRequest,
  success: switchOrganizationSuccess,
  failure: switchOrganizationFailure,
} = createAsyncActions({
  request: ACTION_SWITCH_ORGANIZATION_REQUEST,
  success: ACTION_SWITCH_ORGANIZATION_SUCCESS,
  failure: ACTION_SWITCH_ORGANIZATION_FAILURE,
})

const {
  request: savePasswordRequest,
  success: savePasswordSuccess,
  failure: savePasswordFailure,
} = createAsyncActions({
  request: ACTION_SAVE_PASSWORD_REQUEST,
  success: ACTION_SAVE_PASSWORD_SUCCESS,
  failure: ACTION_SAVE_PASSWORD_FAILURE,
})

const {
  request: downloadAuditLogRequest,
  success: downloadAuditLogSuccess,
  failure: downloadAuditLogFailure,
} = createAsyncActions({
  request: ACTION_DOWNLOAD_AUDIT_LOG_REQUEST,
  success: ACTION_DOWNLOAD_AUDIT_LOG_SUCCESS,
  failure: ACTION_DOWNLOAD_AUDIT_LOG_FAILURE,
})

const {
  request: initTotpRequest,
  success: initTotpSuccess,
  failure: initTotpFailure,
} = createAsyncActions({
  request: ACTION_INIT_TOTP_REQUEST,
  success: ACTION_INIT_TOTP_SUCCESS,
  failure: ACTION_INIT_TOTP_FAILURE,
})

const {
  request: enableTotpRequest,
  success: enableTotpSuccess,
  failure: enableTotpFailure,
} = createAsyncActions({
  request: ACTION_ENABLE_TOTP_REQUEST,
  success: ACTION_ENABLE_TOTP_SUCCESS,
  failure: ACTION_ENABLE_TOTP_FAILURE,
})

const {
  request: disableTotpRequest,
  success: disableTotpSuccess,
  failure: disableTotpFailure,
} = createAsyncActions({
  request: ACTION_DISABLE_TOTP_REQUEST,
  success: ACTION_DISABLE_TOTP_SUCCESS,
  failure: ACTION_DISABLE_TOTP_FAILURE,
})

const {
  request: getRecoveryCodesRequest,
  success: getRecoveryCodesSuccess,
  failure: getRecoveryCodesFailure,
} = createAsyncActions({
  request: ACTION_GET_RECOVERY_CODES_REQUEST,
  success: ACTION_GET_RECOVERY_CODES_SUCCESS,
  failure: ACTION_GET_RECOVERY_CODES_FAILURE,
})

const {
  request: resetRecoveryCodesRequest,
  success: resetRecoveryCodesSuccess,
  failure: resetRecoveryCodesFailure,
} = createAsyncActions({
  request: ACTION_RESET_RECOVERY_CODES_REQUEST,
  success: ACTION_RESET_RECOVERY_CODES_SUCCESS,
  failure: ACTION_RESET_RECOVERY_CODES_FAILURE,
})

const {
  request: inviteUserRequest,
  success: inviteUserSuccess,
  failure: inviteUserFailure,
} = createAsyncActions({
  request: ACTION_INVITE_USER_REQUEST,
  success: ACTION_INVITE_USER_SUCCESS,
  failure: ACTION_INVITE_USER_FAILURE,
})

const {
  request: getInvitationsRequest,
  success: getInvitationsSuccess,
  failure: getInvitationsFailure,
} = createAsyncActions({
  request: ACTION_GET_INVITATIONS_REQUEST,
  success: ACTION_GET_INVITATIONS_SUCCESS,
  failure: ACTION_GET_INVITATIONS_FAILURE,
})

const {
  request: getUsersRequest,
  success: getUsersSuccess,
  failure: getUsersFailure,
} = createAsyncActions({
  request: ACTION_GET_USERS_REQUEST,
  success: ACTION_GET_USERS_SUCCESS,
  failure: ACTION_GET_USERS_FAILURE,
})

const {
  request: deleteUserRequest,
  success: deleteUserSuccess,
  failure: deleteUserFailure,
} = createAsyncActions({
  request: ACTION_DELETE_USER_REQUEST,
  success: ACTION_DELETE_USER_SUCCESS,
  failure: ACTION_DELETE_USER_FAILURE,
})

const {
  request: updateUserRequest,
  success: updateUserSuccess,
  failure: updateUserFailure,
} = createAsyncActions({
  request: ACTION_UPDATE_USER_REQUEST,
  success: ACTION_UPDATE_USER_SUCCESS,
  failure: ACTION_UPDATE_USER_FAILURE,
})

const {
  request: deleteInvitationRequest,
  success: deleteInvitationSuccess,
  failure: deleteInvitationFailure,
} = createAsyncActions({
  request: ACTION_DELETE_INVITATION_REQUEST,
  success: ACTION_DELETE_INVITATION_SUCCESS,
  failure: ACTION_DELETE_INVITATION_FAILURE,
})

const {
  request: getSnippetsRequest,
  success: getSnippetsSuccess,
  failure: getSnippetsFailure,
} = createAsyncActions({
  request: ACTION_GET_SNIPPETS_REQUEST,
  success: ACTION_GET_SNIPPETS_SUCCESS,
  failure: ACTION_GET_SNIPPETS_FAILURE,
})

const {
  request: saveSnippetRequest,
  success: saveSnippetSuccess,
  failure: saveSnippetFailure,
} = createAsyncActions({
  request: ACTION_SAVE_SNIPPET_REQUEST,
  success: ACTION_SAVE_SNIPPET_SUCCESS,
  failure: ACTION_SAVE_SNIPPET_FAILURE,
})

const {
  request: getApiKeysRequest,
  success: getApiKeysSuccess,
  failure: getApiKeysFailure,
} = createAsyncActions({
  request: ACTION_GET_API_KEYS_REQUEST,
  success: ACTION_GET_API_KEYS_SUCCESS,
  failure: ACTION_GET_API_KEYS_FAILURE,
})

const {
  request: createApiKeyRequest,
  success: createApiKeySuccess,
  failure: createApiKeyFailure,
} = createAsyncActions({
  request: ACTION_CREATE_API_KEY_REQUEST,
  success: ACTION_CREATE_API_KEY_SUCCESS,
  failure: ACTION_CREATE_API_KEY_FAILURE,
})

const {
  request: updateApiKeyRequest,
  success: updateApiKeySuccess,
  failure: updateApiKeyFailure,
} = createAsyncActions({
  request: ACTION_UPDATE_API_KEY_REQUEST,
  success: ACTION_UPDATE_API_KEY_SUCCESS,
  failure: ACTION_UPDATE_API_KEY_FAILURE,
})

const {
  request: deleteApiKeyRequest,
  success: deleteApiKeySuccess,
  failure: deleteApiKeyFailure,
} = createAsyncActions({
  request: ACTION_DELETE_API_KEY_REQUEST,
  success: ACTION_DELETE_API_KEY_SUCCESS,
  failure: ACTION_DELETE_API_KEY_FAILURE,
})

export {
  getUserFailure,
  getUserRequest,
  getUserSuccess,
  getUsersFailure,
  getUsersRequest,
  getUsersSuccess,
  savePasswordFailure,
  savePasswordRequest,
  savePasswordSuccess,
  saveUserFailure,
  saveUserRequest,
  saveUserSuccess,
  switchOrganizationFailure,
  switchOrganizationRequest,
  switchOrganizationSuccess,
  updateUserFailure,
  updateUserRequest,
  updateUserSuccess,
}

let pageLoadSource = ''

export const setPageLoadSource = (source) => {
  try {
    if (typeof source === 'string') {
      pageLoadSource = source
    }
  } catch {
    // ignore
  }
}

export const getPageLoadSource = () => {
  try {
    const source = pageLoadSource
    pageLoadSource = ''
    return source
  } catch {
    // ignore
  }

  return ''
}

export const getUser =
  ({ token } = {}) =>
  (dispatch) => {
    dispatch(getUserRequest({ token }))

    if (!token) {
      dispatch(getUserFailure('Missing authentication token.'))
      return Promise.resolve({ token })
    }

    return request.user
      .getDetails({ token })
      .then((user) => {
        if (user.orgs?.length) {
          const org = user.orgs.find((o) => o.orgId === user.activeOrgId)
          if (org && org.permissions.plans) {
            dispatch(getPlan({ token, orgId: user.activeOrgId }))
          }
        }
        const source = getPageLoadSource()
        dispatch(getUserSuccess({ ...user, source }))
        return {
          ...user,
          token,
        }
      })
      .catch((err) => {
        dispatch(
          getUserFailure({
            status: err.status,
            message: ERROR_GET_USER_SERVER,
          }),
        )
        return err
      })
  }

export const saveUser =
  ({ token, item }) =>
  (dispatch) => {
    dispatch(saveUserRequest(item))

    return request.user
      .save({ token, item })
      .then(() => {
        dispatch(saveUserSuccess(item))
        dispatch(
          createNotification({
            'save-user': {
              type: 'success',
              message: 'Profile updated successfully.',
            },
          }),
        )
        return item
      })
      .catch((err) => {
        dispatch(
          saveUserFailure({
            status: err.status,
            message: ERROR_SAVE_USER_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'save-user': {
              type: 'error',
              message: 'There was a problem updating your profile. Check the fields and try again.',
            },
          }),
        )
        return err
      })
  }

export const switchOrganization =
  ({ token, item = {} }) =>
  (dispatch) => {
    dispatch(switchOrganizationRequest(item))

    return request.user
      .switchOrganization({ token, item: { activeOrgId: item.value } })
      .then(() => {
        setPageLoadSource('switch-organization')
        dispatch(switchOrganizationSuccess(item))
        return item
      })
      .catch((err = {}) => {
        dispatch(
          createNotification({
            'switch-organization': {
              type: 'error',
              message: err.msg || ERROR_SWITCH_ORGANIZATION_SERVER,
            },
          }),
        )
        return err
      })
      .then(() => getUser({ token })(dispatch))
      .then(() => item)
  }

export const savePassword =
  ({ token, item = {} }) =>
  (dispatch) => {
    dispatch(savePasswordRequest(item))

    const data = {
      currentPassword: item.currentPassword,
      newPassword: item.newPassword,
      newPasswordAgain: item.newPasswordConfirm,
    }

    return request.user
      .saveUser({ token, item: data })
      .then((res) => {
        dispatch(savePasswordSuccess(item))
        dispatch(
          createNotification({
            'reset-password': {
              type: 'success',
              message: 'New password successfully set.',
            },
          }),
        )

        const authToken = res.access
        setToken(authToken, res.validUntil)

        dispatch(loginSuccess({ token: authToken }))

        return res
      })
      .catch((err) => {
        const message = pathOr(ERROR_SAVE_PASSWORD_SERVER, ['response', 'message'], err)
        dispatch(savePasswordFailure({ message }))
        dispatch(
          createNotification({
            'reset-password': {
              type: 'error',
              message,
            },
          }),
        )
        return err
      })
  }

export const downloadAuditLog =
  ({ token, orgId, from, to }) =>
  async (dispatch) => {
    dispatch(downloadAuditLogRequest())
    const filename = http.auditLog.download.getSignablePath({ orgId })
    const data = {
      paths: [filename],
    }

    try {
      const res = await request.auth.sign({ token, item: data })
      if (isFailed(res)) {
        dispatch(
          downloadAuditLogFailure({
            status: res.status,
            message: ERROR_DOWNLOAD_AUDIT_LOG,
          }),
        )
        dispatch(
          createNotification({
            'download-audit-log-failed': {
              type: 'error',
              message: ERROR_DOWNLOAD_AUDIT_LOG,
            },
          }),
        )
        return res
      }

      const url = http.files.get.getPath({
        signedPath: filename,
        urlToken: res[filename],
      })
      const urlWithDates = `${url}&startDate=${from}&endDate=${to}`
      const name = `engageSPARK_auditlog_${moment(from).format('YYYY/MM/DD')}-${moment(to).format('YYYY/MM/DD')}.csv`
      await downloadFile(urlWithDates, name)
      dispatch(downloadAuditLogSuccess())
      return res
    } catch (err) {
      dispatch(
        downloadAuditLogFailure({
          status: err.status,
          message: ERROR_DOWNLOAD_AUDIT_LOG,
        }),
      )
      dispatch(
        createNotification({
          'download-audit-log-failed': {
            type: 'error',
            message: ERROR_DOWNLOAD_AUDIT_LOG,
          },
        }),
      )
      return err
    }
  }

export const initTotp =
  ({ token }) =>
  (dispatch) => {
    dispatch(initTotpRequest())

    return request.user
      .initTotp({ token, item: {} })
      .then((res) => {
        dispatch(initTotpSuccess(res))
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_INIT_TOTP)
        dispatch(
          initTotpFailure({
            status: err.status,
            message: errorMessage,
          }),
        )
        dispatch(
          createNotification({
            'init-Totp': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const enableTotp =
  ({ token, secret, passcode }) =>
  (dispatch) => {
    dispatch(enableTotpRequest())

    return request.user
      .enableTotp({ token, item: { secret, passcode } })
      .then((res) => {
        dispatch(enableTotpSuccess(res))
        dispatch(login({ token: res.access }))
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_ENABLE_TOTP)
        dispatch(
          enableTotpFailure({
            status: err.status,
            message: errorMessage,
          }),
        )
        dispatch(
          createNotification({
            'enable-Totp': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const disableTotp =
  ({ token }) =>
  (dispatch) => {
    dispatch(disableTotpRequest())

    return request.user
      .disableTotp({ token, item: {} })
      .then((res) => {
        dispatch(disableTotpSuccess(res))
        dispatch(getUser({ token }))
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_DISABLE_TOTP)
        dispatch(
          disableTotpFailure({
            status: err.status,
            message: errorMessage,
          }),
        )
        dispatch(
          createNotification({
            'disable-Totp': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const getRecoveryCodes =
  ({ token }) =>
  (dispatch) => {
    dispatch(getRecoveryCodesRequest())

    return request.user
      .getRecoveryCodes({ token, item: {} })
      .then((res) => {
        const { recoveryCodes = [] } = res
        dispatch(getRecoveryCodesSuccess(recoveryCodes))
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_GET_RECOVERY_CODES)
        dispatch(
          getRecoveryCodesFailure({
            status: err.status,
            message: errorMessage,
          }),
        )
        dispatch(
          createNotification({
            'get-recovery-codes': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const resetRecoveryCodes =
  ({ token }) =>
  (dispatch) => {
    dispatch(resetRecoveryCodesRequest())

    return request.user
      .resetRecoveryCodes({ token, item: {} })
      .then((res) => {
        const { recoveryCodes = [] } = res
        dispatch(resetRecoveryCodesSuccess(recoveryCodes))
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_RESET_RECOVERY_CODES)
        dispatch(
          resetRecoveryCodesFailure({
            status: err.status,
            message: errorMessage,
          }),
        )
        dispatch(
          createNotification({
            'reset-recovery-codes': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const removeRecoveryCodes = () => ({
  type: ACTION_REMOVE_RECOVERY_CODES,
})

export const inviteUser =
  ({ token, orgId, item }) =>
  (dispatch) => {
    dispatch(inviteUserRequest(orgId))

    return request.organizations
      .inviteUser({ token, orgId, item })
      .then((res) => {
        dispatch(inviteUserSuccess(res))
        dispatch(
          createNotification({
            'invite-user-success': {
              type: 'success',
              message: 'User invited successfully.',
            },
          }),
        )
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_INVITE_USER)
        dispatch(inviteUserFailure(err))
        dispatch(
          createNotification({
            'invite-user-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const getInvitations =
  ({ token, orgId }) =>
  (dispatch) => {
    dispatch(getInvitationsRequest())

    return request.organizations
      .getInvitations({ token, orgId })
      .then(({ invites }) => {
        dispatch(getInvitationsSuccess(invites))
        return invites
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_GET_INVITATIONS)
        dispatch(getInvitationsFailure(err))
        dispatch(
          createNotification({
            'get-invitations-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const getUsers =
  ({ token, orgId }) =>
  (dispatch) => {
    dispatch(getUsersRequest())

    return request.organizations
      .getUsers({ token, orgId })
      .then(({ users }) => {
        dispatch(getUsersSuccess(users))
        return users
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_GET_USERS_SERVER)
        dispatch(getUsersFailure(err))
        dispatch(
          createNotification({
            'get-users-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const updateUser =
  ({ token, orgId, userId, item }) =>
  (dispatch) => {
    dispatch(updateUserRequest({ orgId, userId }))

    return request.organizations
      .updateUser({ token, orgId, userId, item })
      .then((res) => {
        dispatch(updateUserSuccess(res))
        dispatch(
          createNotification({
            'update-user-success': {
              type: 'success',
              message: 'Updated user succesfully.',
            },
          }),
        )
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_UPDATE_USER_SERVER)
        dispatch(updateUserFailure(err))
        dispatch(
          createNotification({
            'update-user-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const deleteUser =
  ({ token, orgId, userId }) =>
  (dispatch) => {
    dispatch(deleteUserRequest({ orgId, userId }))

    return request.organizations
      .deleteUser({ token, orgId, userId })
      .then((res) => {
        dispatch(deleteUserSuccess(userId))
        dispatch(
          createNotification({
            'delete-user-success': {
              type: 'success',
              message: 'Removed user from the organization successfully.',
            },
          }),
        )
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_DELETE_USER_SERVER)
        dispatch(deleteUserFailure(err))
        dispatch(
          createNotification({
            'delete-user-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const deleteInvitation =
  ({ token, orgId, inviteId }) =>
  (dispatch) => {
    dispatch(deleteInvitationRequest(orgId))

    return request.organizations
      .deleteInvitation({ token, orgId, inviteId })
      .then((res) => {
        dispatch(deleteInvitationSuccess(res))
        dispatch(
          createNotification({
            'delete-invitation-success': {
              type: 'success',
              message: 'Invitation deleted successfully.',
            },
          }),
        )
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_DELETE_INVITATIONS)
        dispatch(deleteInvitationFailure(err))
        dispatch(
          createNotification({
            'delete-invitation-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const saveSnippet =
  ({ token, orgId, item }) =>
  (dispatch) => {
    dispatch(saveSnippetRequest())

    return request.snippets
      .save({ token, orgId, item })
      .then((res) => {
        dispatch(saveSnippetSuccess(res))
        dispatch(
          createNotification({
            'save-snippet-success': {
              type: 'success',
              message: 'Added to library.',
            },
          }),
        )
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_SAVE_SNIPPET_SERVER)
        dispatch(saveSnippetFailure(err))
        dispatch(
          createNotification({
            'save-snippet-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const getSnippets =
  ({ token, orgId }) =>
  (dispatch) => {
    dispatch(getSnippetsRequest())

    return request.snippets
      .getList({ token, orgId })
      .then(({ snippets }) => {
        dispatch(getSnippetsSuccess(snippets))
        return snippets
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_GET_SNIPPETS_SERVER)
        dispatch(getSnippetsFailure(err))
        dispatch(
          createNotification({
            'get-snippets-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const toggleCallDialog = (payload) => ({
  type: ACTION_TOGGLE_CALL_DIALOG,
  value: payload,
})

export const getApiKeys =
  ({ token }) =>
  (dispatch) => {
    dispatch(getApiKeysRequest())

    return request.apiKey
      .getList({ token })
      .then((res) => {
        dispatch(getApiKeysSuccess(res))
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_GET_API_KEYS_SERVER)
        dispatch(getApiKeysFailure(err))
        dispatch(
          createNotification({
            'get-api-keys-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const createApiKey =
  ({ token }) =>
  (dispatch) => {
    dispatch(createApiKeyRequest())

    return request.apiKey
      .create({ token })
      .then((res) => {
        dispatch(createApiKeySuccess(res.token))
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_CREATE_API_KEY_SERVER)
        dispatch(createApiKeyFailure(err))
        dispatch(
          createNotification({
            'create-api-key-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const updateApiKey =
  ({ token, tokenId, item }) =>
  (dispatch) => {
    dispatch(updateApiKeyRequest())

    return request.apiKey
      .update({ token, tokenId, item })
      .then((res) => {
        dispatch(updateApiKeySuccess(res.token))
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_UPDATE_API_KEY_SERVER)
        dispatch(updateApiKeyFailure(err))
        dispatch(
          createNotification({
            'update-api-key-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const deleteApiKey =
  ({ token, tokenId }) =>
  (dispatch) => {
    dispatch(deleteApiKeyRequest())

    return request.apiKey
      .delete({ token, tokenId })
      .then((res) => {
        dispatch(deleteApiKeySuccess(tokenId))
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_DELETE_API_KEY_SERVER)
        dispatch(deleteApiKeyFailure(err))
        dispatch(
          createNotification({
            'delete-api-key-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }
