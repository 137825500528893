import { pathOr } from 'ramda'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { createApiKey, deleteApiKey, getApiKeys, updateApiKey } from '@/app/module/user/store/actions'
import { selectApiKeys } from '@/app/module/user/store/selectors'
import { UpdateAPIKeyPayload } from '@/app/module/user/types'
import { useAppDispatch } from '@/app/store/redux-hooks'

import ApiKeys from './api-keys'

type Props = {}

const ApiKeysContainer: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const token = useSelector(pathOr('', ['auth', 'login', 'data', 'token']))
  const apiKeys = useSelector(selectApiKeys)
  const loading = useSelector(pathOr(false, ['user', 'apiKeys', 'loading']))

  const createAPIKey = React.useCallback(() => createApiKey({ token })(dispatch), [token, dispatch])
  const deleteAPIKey = React.useCallback(
    async (tokenId: number) => deleteApiKey({ token, tokenId })(dispatch),
    [token, dispatch],
  )
  const updateAPIKey = React.useCallback(
    (tokenId: number, item: UpdateAPIKeyPayload) => updateApiKey({ token, tokenId, item })(dispatch),
    [token, dispatch],
  )

  useEffect(() => {
    if (!token) {
      return
    }

    getApiKeys({ token })(dispatch)
  }, [token, dispatch])

  return (
    <ApiKeys
      apiKeys={apiKeys || []}
      loading={loading}
      onCreate={createAPIKey}
      onDelete={deleteAPIKey}
      onEdit={updateAPIKey}
    />
  )
}

export default ApiKeysContainer
