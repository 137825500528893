import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { isEmpty } from 'ramda'
import React from 'react'

import { DRAGTYPE, SMS_ACTION_DEFAULT_SENDERID } from '@/app/module/campaigns/definitions'
import { ItemType } from '@/app/module/campaigns/helpers'
import {
  CampaignTemplateType,
  Language,
  PartFamily,
  PersonalizationType,
  SenderIdOption,
  SnippetType,
} from '@/app/module/campaigns/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { unwrapActions, wrapActions } from '@/app/module/campaigns/utils/actions/wrap'
import { CountryTimezone, FilesType, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'
import { FunctionType } from '@/config/types'

import QuestionBox from './question'

type Props = {
  item: ItemType
  type: CampaignTemplateType
  changeHandler: FunctionType
  id: string
  personalizationList: PersonalizationType
  senderIds: SenderIdOption[]
  whatsappSenderIds: SenderIdOption[]
  getCampaigns: FunctionType
  orgId: number
  token: string
  campaignIds: SelectOption<number>[]
  getVoiceProps: FunctionType
  getImageProps: FunctionType
  files: FilesType
  timezone: string
  timezones: TimezoneWithCountryCodeType
  countryCode: string
  countriesTimezones: CountryTimezone[]
  getSnippets: FunctionType
  saveSnippet: FunctionType
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  languages: Language[]
  defaultLanguage: string
  index: number
}

export default function CampaignContentQuestionTopup(props: Props) {
  const {
    item,
    type,
    id,
    personalizationList,
    senderIds,
    whatsappSenderIds,
    getCampaigns,
    orgId,
    token,
    campaignIds,
    getVoiceProps,
    getImageProps,
    timezone,
    timezones,
    countryCode,
    countriesTimezones,
    getSnippets,
    saveSnippet,
    snippets,
    languages,
    defaultLanguage,
    files,
  } = props

  const [state, setState] = React.useState<any>({})
  const [initialized, setInitialized] = React.useState(false)

  const { successActions, failureActions } = React.useMemo(() => {
    return getActionValues(type)
  }, [type])

  React.useEffect(() => {
    if (item.topup && !isEmpty(item.topup)) {
      setInitialized(true)
      setState(fromProps(item.topup))
    }
  }, [item?.topup])

  const changeHandler = (newItem: ItemType) => {
    setState({
      ...state,
      ...newItem,
    })
    const returnToProps = backToProps({
      ...state,
      ...newItem,
    })

    if (initialized) {
      props.changeHandler({
        ...item,
        topup: {
          ...item.topup,
          ...returnToProps,
        },
      })
    }
  }

  return (
    <div id="topup-block-cont">
      <Divider
        style={{
          margin: '0rem -24px 1rem',
        }}
      />
      <div>
        <Typography>Topup Success Actions</Typography>
        <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          Specify what actions we should take if the top up succeeds
        </Typography>
        <div>
          <QuestionBox
            files={files}
            dragContext={`${DRAGTYPE}-topups`}
            actionsList={state.onSuccess}
            actionsValues={successActions}
            changeHandler={({ actionsList }: { actionsList: string[] }) =>
              changeHandler({
                onSuccess: actionsList,
              })
            }
            senderIds={senderIds}
            whatsappSenderIds={whatsappSenderIds}
            personalizationList={personalizationList}
            defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.actionsOnly}
            getCampaigns={getCampaigns}
            getImageProps={getImageProps}
            imageId={`${id}-topup`}
            voiceId={`${id}-topup`}
            orgId={orgId}
            token={token}
            question={false}
            campaignIds={campaignIds}
            index={props.index}
            getVoiceProps={getVoiceProps}
            timezones={timezones}
            timezone={timezone}
            countriesTimezones={countriesTimezones}
            countryCode={countryCode}
            getSnippets={getSnippets}
            snippets={snippets}
            saveSnippet={saveSnippet}
            languages={languages}
            defaultLanguage={defaultLanguage}
          />
        </div>
      </div>
      <div>
        <Typography>Topup Failure Actions</Typography>
        <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          Specify what actions we should take if the top up fails
        </Typography>
        <div>
          <QuestionBox
            files={files}
            dragContext={`${DRAGTYPE}-topup`}
            actionsList={state.onError}
            actionsValues={failureActions}
            changeHandler={({ actionsList }: { actionsList: string[] }) =>
              changeHandler({
                onError: actionsList,
              })
            }
            senderIds={senderIds}
            whatsappSenderIds={whatsappSenderIds}
            personalizationList={personalizationList}
            defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.actionsOnly}
            getCampaigns={getCampaigns}
            getImageProps={getImageProps}
            imageId={`${id}-topup`}
            voiceId={`${id}-topup`}
            orgId={orgId}
            token={token}
            question={false}
            campaignIds={campaignIds}
            index={props.index}
            getVoiceProps={getVoiceProps}
            timezones={timezones}
            timezone={timezone}
            countriesTimezones={countriesTimezones}
            countryCode={countryCode}
            getSnippets={getSnippets}
            snippets={snippets}
            saveSnippet={saveSnippet}
            languages={languages}
            defaultLanguage={defaultLanguage}
          />
        </div>
      </div>
    </div>
  )
}

const getActionValues = (type: CampaignTemplateType) => {
  const successActions = getActionOptionsForCampaign({
    campaignType: type,
    partType: PartFamily.Topup,
    actionNamespace: ActionContext.TopupSuccess,
  })
  const failureActions = getActionOptionsForCampaign({
    campaignType: type,
    partType: PartFamily.Topup,
    actionNamespace: ActionContext.TopupFailure,
  })

  return {
    successActions,
    failureActions,
  }
}

const fromProps = ({ onSuccess = [], onError = [] }) => ({
  onSuccess: unwrapActions(onSuccess || []),
  onError: unwrapActions(onError || []),
})

const backToProps = ({ onSuccess = [], onError = [] }: ItemType) => ({
  onSuccess: wrapActions(onSuccess),
  onError: wrapActions(onError),
})
