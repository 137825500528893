import { filter, path, pathOr } from 'ramda'
import { compose } from 'redux'

import { saveFile } from '@/app/module/files/store/actions'
import {
  ACTION_CLEAR_CALLS,
  ACTION_CLEAR_MESSAGES,
  ACTION_CLEAR_TOPUPS,
  ACTION_CLEAR_WHATSAPP,
  ACTION_DOWNLOAD_CALLS_FAILURE,
  ACTION_DOWNLOAD_CALLS_REQUEST,
  ACTION_DOWNLOAD_CALLS_SUCCESS,
  ACTION_DOWNLOAD_MESSAGES_FAILURE,
  ACTION_DOWNLOAD_MESSAGES_REQUEST,
  ACTION_DOWNLOAD_MESSAGES_SUCCESS,
  ACTION_DOWNLOAD_TOPUPS_FAILURE,
  ACTION_DOWNLOAD_TOPUPS_REQUEST,
  ACTION_DOWNLOAD_TOPUPS_SUCCESS,
  ACTION_DOWNLOAD_WHATSAPP_FAILURE,
  ACTION_DOWNLOAD_WHATSAPP_REQUEST,
  ACTION_DOWNLOAD_WHATSAPP_SUCCESS,
  ACTION_GET_CALLS_FAILURE,
  ACTION_GET_CALLS_FILTER,
  ACTION_GET_CALLS_REQUEST,
  ACTION_GET_CALLS_SUCCESS,
  ACTION_GET_MESSAGES_FAILURE,
  ACTION_GET_MESSAGES_FILTER,
  ACTION_GET_MESSAGES_REQUEST,
  ACTION_GET_MESSAGES_SUCCESS,
  ACTION_GET_SENDER_IDS_FAILURE,
  ACTION_GET_SENDER_IDS_REQUEST,
  ACTION_GET_SENDER_IDS_SUCCESS,
  ACTION_GET_TOPUPS_FAILURE,
  ACTION_GET_TOPUPS_FILTER,
  ACTION_GET_TOPUPS_REQUEST,
  ACTION_GET_TOPUPS_SUCCESS,
  ACTION_GET_WHATSAPP_FAILURE,
  ACTION_GET_WHATSAPP_FILTER,
  ACTION_GET_WHATSAPP_REQUEST,
  ACTION_GET_WHATSAPP_SUCCESS,
  ACTION_LOGS_NAVIGATE,
  ACTION_LOGS_PAGE_SIZE,
  ACTION_SAVE_WHATSAPP_FILE_FAILURE,
  ACTION_SAVE_WHATSAPP_FILE_REQUEST,
  ACTION_SAVE_WHATSAPP_FILE_SUCCESS,
  ACTION_SEND_MESSAGE_FAILURE,
  ACTION_SEND_MESSAGE_REQUEST,
  ACTION_SEND_MESSAGE_SUCCESS,
  ACTION_SEND_WHATSAPP_MESSAGE_FAILURE,
  ACTION_SEND_WHATSAPP_MESSAGE_REQUEST,
  ACTION_SEND_WHATSAPP_MESSAGE_SUCCESS,
  ACTION_SET_SEND_MESSAGE,
  ALL,
  ERROR_DOWNLOAD_SERVER,
  ERROR_GET_CALLS_SERVER,
  ERROR_GET_MESSAGES_SERVER,
  ERROR_GET_SENDER_IDS_SERVER,
  ERROR_GET_TOPUPS_SERVER,
  ERROR_GET_WHATSAPP_SERVER,
  ERROR_SAVE_WHATSAPP_FILE,
  ERROR_SEND_MESSAGE_SERVER,
  ERROR_SEND_WHATSAPP_MESSAGE_SERVER,
} from '@/app/module/logs/definitions'
import { createNotification } from '@/app/module/notifications/store/actions'
import request from '@/app/module/request'
import { getPageSize, setPageSize } from '@/app/service/storage'
import { createAsyncActions, toQuery } from '@/app/service/util'
import config from '@/config'
import http from '@/config/http'

import { getFilters } from './selectors'

const {
  request: getMessagesRequest,
  success: getMessagesSuccess,
  failure: getMessagesFailure,
} = createAsyncActions({
  request: ACTION_GET_MESSAGES_REQUEST,
  success: ACTION_GET_MESSAGES_SUCCESS,
  failure: ACTION_GET_MESSAGES_FAILURE,
})

const {
  request: downloadMessagesRequest,
  success: downloadMessagesSuccess,
  failure: downloadMessagesFailure,
} = createAsyncActions({
  request: ACTION_DOWNLOAD_MESSAGES_REQUEST,
  success: ACTION_DOWNLOAD_MESSAGES_SUCCESS,
  failure: ACTION_DOWNLOAD_MESSAGES_FAILURE,
})

const {
  request: getCallsRequest,
  success: getCallsSuccess,
  failure: getCallsFailure,
} = createAsyncActions({
  request: ACTION_GET_CALLS_REQUEST,
  success: ACTION_GET_CALLS_SUCCESS,
  failure: ACTION_GET_CALLS_FAILURE,
})

const {
  request: downloadCallsRequest,
  success: downloadCallsSuccess,
  failure: downloadCallsFailure,
} = createAsyncActions({
  request: ACTION_DOWNLOAD_CALLS_REQUEST,
  success: ACTION_DOWNLOAD_CALLS_SUCCESS,
  failure: ACTION_DOWNLOAD_CALLS_FAILURE,
})

const {
  request: getTopupsRequest,
  success: getTopupsSuccess,
  failure: getTopupsFailure,
} = createAsyncActions({
  request: ACTION_GET_TOPUPS_REQUEST,
  success: ACTION_GET_TOPUPS_SUCCESS,
  failure: ACTION_GET_TOPUPS_FAILURE,
})

const {
  request: downloadTopupsRequest,
  success: downloadTopupsSuccess,
  failure: downloadTopupsFailure,
} = createAsyncActions({
  request: ACTION_DOWNLOAD_TOPUPS_REQUEST,
  success: ACTION_DOWNLOAD_TOPUPS_SUCCESS,
  failure: ACTION_DOWNLOAD_TOPUPS_FAILURE,
})

const {
  request: getWhatsappRequest,
  success: getWhatsappSuccess,
  failure: getWhatsappFailure,
} = createAsyncActions({
  request: ACTION_GET_WHATSAPP_REQUEST,
  success: ACTION_GET_WHATSAPP_SUCCESS,
  failure: ACTION_GET_WHATSAPP_FAILURE,
})

const {
  request: downloadWhatsappRequest,
  success: downloadWhatsappSuccess,
  failure: downloadWhatsappFailure,
} = createAsyncActions({
  request: ACTION_DOWNLOAD_WHATSAPP_REQUEST,
  success: ACTION_DOWNLOAD_WHATSAPP_SUCCESS,
  failure: ACTION_DOWNLOAD_WHATSAPP_FAILURE,
})

const {
  request: sendWhatsappMessageRequest,
  success: sendWhatsappMessageSuccess,
  failure: sendWhatsappMessageFailure,
} = createAsyncActions({
  request: ACTION_SEND_WHATSAPP_MESSAGE_REQUEST,
  success: ACTION_SEND_WHATSAPP_MESSAGE_SUCCESS,
  failure: ACTION_SEND_WHATSAPP_MESSAGE_FAILURE,
})

const {
  request: sendMessageRequest,
  success: sendMessageSuccess,
  failure: sendMessageFailure,
} = createAsyncActions({
  request: ACTION_SEND_MESSAGE_REQUEST,
  success: ACTION_SEND_MESSAGE_SUCCESS,
  failure: ACTION_SEND_MESSAGE_FAILURE,
})

const {
  request: getSenderIdsRequest,
  success: getSenderIdsSuccess,
  failure: getSenderIdsFailure,
} = createAsyncActions({
  request: ACTION_GET_SENDER_IDS_REQUEST,
  success: ACTION_GET_SENDER_IDS_SUCCESS,
  failure: ACTION_GET_SENDER_IDS_FAILURE,
})

const {
  request: saveWhatsappFileRequest,
  success: saveWhatsappFileSuccess,
  failure: saveWhatsappFileFailure,
} = createAsyncActions({
  request: ACTION_SAVE_WHATSAPP_FILE_REQUEST,
  success: ACTION_SAVE_WHATSAPP_FILE_SUCCESS,
  failure: ACTION_SAVE_WHATSAPP_FILE_FAILURE,
})

export {
  getCallsFailure,
  getCallsRequest,
  getCallsSuccess,
  getMessagesFailure,
  getMessagesRequest,
  getMessagesSuccess,
  getSenderIdsFailure,
  getSenderIdsRequest,
  getSenderIdsSuccess,
  sendMessageFailure,
  sendMessageRequest,
  sendMessageSuccess,
  sendWhatsappMessageFailure,
  sendWhatsappMessageRequest,
  sendWhatsappMessageSuccess,
}

const defaultPageSize = path(['modules', 'messages', 'pageSize', 'default'], config)

export const getMessages =
  ({ token, orgId, query = {} }) =>
  (dispatch) => {
    dispatch(getMessagesRequest())
    const {
      filters = {
        type: ALL,
        status: ALL,
      },
      pageSize = defaultPageSize,
      search = '',
      endCursor = '',
    } = query

    return request.messages
      .get({
        token,
        orgId,
        query: {
          dir: filters.type || 'all',
          failed: filters.status || 'all',
          startDate: filters.from,
          endDate: filters.to,
          limit: pageSize,
          after: endCursor,
          search,
        },
      })
      .then(({ messages, hasNextPage, nextPage } = {}) => {
        dispatch(
          getMessagesSuccess({
            messages,
            nextPage,
            hasNextPage,
          }),
        )
      })
      .catch((err) => {
        dispatch(
          getMessagesFailure({
            status: err.status,
            message: ERROR_GET_MESSAGES_SERVER,
          }),
        )
        return err
      })
  }

export const getSenderIds =
  ({ token, orgId }) =>
  (dispatch) => {
    dispatch(getSenderIdsRequest())
    return request.senderIds
      .get({ token, orgId })
      .then(({ senderIds = [] }) => {
        dispatch(getSenderIdsSuccess(senderIds))
      })
      .catch((err) => {
        dispatch(
          getSenderIdsFailure({
            status: err.status,
            message: ERROR_GET_SENDER_IDS_SERVER,
          }),
        )
        return err
      })
  }

export const getSenderIdList =
  ({ token, orgId }) =>
  (dispatch) => {
    dispatch(getSenderIdsRequest())
    return request.senderIds
      .getList({ token, orgId })
      .then((senderIds = []) => {
        dispatch(getSenderIdsSuccess(senderIds))
      })
      .catch((err) => {
        dispatch(
          getSenderIdsFailure({
            status: err.status,
            message: ERROR_GET_SENDER_IDS_SERVER,
          }),
        )
        return err
      })
  }

export const setSendMessage =
  ({ recipient, contactId, senderId }) =>
  (dispatch) =>
    dispatch({
      type: ACTION_SET_SEND_MESSAGE,
      value: { recipient, contactId, senderId },
    })

export const sendMessage =
  ({ token, orgId, isAllContacts = false, senderId, contactIds, excludeIds, message, includedGroupIds, search }) =>
  (dispatch) => {
    dispatch(sendMessageRequest())

    const item = {
      contacts: {
        allContactsAreSelected: isAllContacts,
        includedContactIds: contactIds,
        excludedContactIds: excludeIds,
        includedGroupIds,
        search,
      },
      from: senderId,
      message,
    }

    const notificationKey = contactIds.join('-')

    return request.messages
      .send({ token, orgId, item })
      .then((response = {}) => {
        const error = pathOr(false, ['error'], response)
        const failedMessages = compose(
          filter((m) => !!m.error),
          pathOr([], ['messages']),
        )(response)

        const notiType = error || failedMessages?.length ? 'warning' : 'success'
        let notiMessage = error ? 'Invalid/deleted contact or not enough balance.' : 'Message successfully sent.'
        if (failedMessages?.length) {
          notiMessage = 'Some messages failed to go out. Please contact support for more details.'
        }

        dispatch(
          createNotification({
            [`send-message-${notificationKey}`]: {
              type: notiType,
              message: notiMessage,
            },
          }),
        )
        dispatch(sendMessageSuccess())
      })
      .catch((err) => {
        dispatch(
          createNotification({
            [`send-message-${notificationKey}`]: {
              type: 'error',
              message: path(['response', 'error'], err) || ERROR_SEND_MESSAGE_SERVER,
            },
          }),
        )
        return err
      })
  }

export const getMessagesFilters = (userId) => (dispatch) => {
  const filters = JSON.parse(localStorage.getItem(`message-filters-${userId}`))
  return dispatch({
    type: ACTION_GET_MESSAGES_FILTER,
    value: filters || {},
  })
}

export const downloadMessages =
  ({ token, orgId, query }) =>
  (dispatch) => {
    const {
      filters = {
        type: ALL,
        status: ALL,
      },
      search = '',
    } = query
    dispatch(downloadMessagesRequest())
    const dlPath = http.messages.download.getSignablePath({
      orgId,
    })
    const data = {
      paths: [dlPath],
    }

    return request.auth
      .sign({ token, item: data })
      .then((res) => {
        dispatch(downloadMessagesSuccess())
        const url = http.messages.download.getPath({
          query: {
            dir: filters.type || 'all',
            failed: filters.status || 'all',
            search,
            startDate: filters.from,
            endDate: filters.to,
          },
          signedPath: dlPath,
          urlToken: res[dlPath],
        })
        window.location.href = url
      })
      .catch((err) => {
        dispatch(downloadMessagesFailure())
        dispatch(
          createNotification({
            'download-invalid-messages': {
              type: 'error',
              message: ERROR_DOWNLOAD_SERVER,
            },
          }),
        )
        return err
      })
  }

export const reloadMessages = () => (dispatch) => dispatch({ type: ACTION_CLEAR_MESSAGES })

export const getMessagesPageSize = () => (dispatch) => {
  const size = getPageSize({ defaultValue: defaultPageSize, namespace: 'messages' })

  return dispatch({
    type: ACTION_LOGS_PAGE_SIZE,
    value: size,
  })
}

export const setMessagesPageSize =
  (size = defaultPageSize) =>
  (dispatch) => {
    setPageSize({ namespace: 'messages', value: size })

    return dispatch({
      type: ACTION_LOGS_PAGE_SIZE,
      value: size,
    })
  }

export const setFilters = ({ search = '', filters }) => ({
  type: ACTION_LOGS_NAVIGATE,
  value: {
    search,
    filters,
  },
})

export const navigateLogs = ({ history, search = '', filters = {}, tab } = {}) => {
  const query = {
    search: encodeURIComponent(search),
    ...getFilters({ filters }),
  }
  if (history) {
    history.push(`/logs?tab=${tab}&${toQuery(query)}`)
  }

  return {
    type: ACTION_LOGS_NAVIGATE,
    value: {
      search,
      filters: getFilters({ filters }),
    },
  }
}

export const clearFilters = () => (dispatch) =>
  dispatch({
    type: ACTION_LOGS_NAVIGATE,
    value: {
      search: '',
      filters: {
        type: '',
        status: '',
      },
    },
  })

export const getCalls =
  ({ token, orgId, query = {} }) =>
  (dispatch) => {
    dispatch(getCallsRequest())
    const {
      filters = {
        type: ALL,
        status: ALL,
      },
      pageSize = defaultPageSize,
      search = '',
      endCursor = '',
    } = query

    return request.calls
      .get({
        token,
        orgId,
        query: {
          dir: filters.type || 'all',
          failed: filters.status || 'all',
          limit: pageSize,
          after: endCursor,
          startDate: filters.from,
          endDate: filters.to,
          search,
        },
      })
      .then(({ calls, hasNextPage, nextPage } = {}) => {
        dispatch(
          getCallsSuccess({
            calls,
            nextPage,
            hasNextPage,
          }),
        )
      })
      .catch((err) => {
        dispatch(
          getCallsFailure({
            status: err.status,
            message: ERROR_GET_CALLS_SERVER,
          }),
        )
        return err
      })
  }

export const reloadCalls = () => (dispatch) => dispatch({ type: ACTION_CLEAR_CALLS })

export const downloadCalls =
  ({ token, orgId, query = {} }) =>
  (dispatch) => {
    const {
      filters = {
        type: ALL,
        status: ALL,
      },
      search = '',
    } = query
    dispatch(downloadCallsRequest())
    const dlPath = http.calls.download.getSignablePath({
      orgId,
    })
    const data = {
      paths: [dlPath],
    }

    return request.auth
      .sign({ token, item: data })
      .then((res) => {
        dispatch(downloadCallsSuccess())
        const url = http.calls.download.getPath({
          query: {
            dir: filters.type || 'all',
            failed: filters.status || 'all',
            search,
            startDate: filters.from,
            endDate: filters.to,
          },
          signedPath: dlPath,
          urlToken: res[dlPath],
        })
        window.location.href = url
      })
      .catch((err) => {
        dispatch(downloadCallsFailure())
        dispatch(
          createNotification({
            'download-invalid-calls': {
              type: 'error',
              message: ERROR_DOWNLOAD_SERVER,
            },
          }),
        )
        return err
      })
  }

export const getCallsFilters = (userId) => (dispatch) => {
  const filters = JSON.parse(localStorage.getItem(`call-filters-${userId}`))
  return dispatch({
    type: ACTION_GET_CALLS_FILTER,
    value: filters || {},
  })
}

export const getTopups =
  ({ token, orgId, query = {} }) =>
  (dispatch) => {
    dispatch(getTopupsRequest())
    const {
      filters = {
        type: ALL,
        status: ALL,
      },
      pageSize = defaultPageSize,
      search = '',
      endCursor = '',
    } = query

    return request.topups
      .get({
        token,
        orgId,
        query: {
          dir: filters.type || 'all',
          failed: filters.status || 'all',
          limit: pageSize,
          after: endCursor,
          startDate: filters.from,
          endDate: filters.to,
          search,
        },
      })
      .then(({ topups, hasNextPage, nextPage } = {}) => {
        dispatch(
          getTopupsSuccess({
            topups,
            nextPage,
            hasNextPage,
          }),
        )
      })
      .catch((err) => {
        dispatch(
          getTopupsFailure({
            status: err.status,
            message: ERROR_GET_TOPUPS_SERVER,
          }),
        )
        return err
      })
  }

export const reloadTopups = () => (dispatch) => dispatch({ type: ACTION_CLEAR_TOPUPS })

export const downloadTopups =
  ({ token, orgId, query = {} }) =>
  (dispatch) => {
    const {
      filters = {
        type: ALL,
        status: ALL,
      },
      search = '',
    } = query
    dispatch(downloadTopupsRequest())
    const dlPath = http.topups.download.getSignablePath({
      orgId,
    })
    const data = {
      paths: [dlPath],
    }

    return request.auth
      .sign({ token, item: data })
      .then((res) => {
        dispatch(downloadTopupsSuccess())
        const url = http.topups.download.getPath({
          query: {
            dir: filters.type || 'all',
            failed: filters.status || 'all',
            search,
            startDate: filters.from,
            endDate: filters.to,
          },
          signedPath: dlPath,
          urlToken: res[dlPath],
        })
        window.location.href = url
      })
      .catch((err) => {
        dispatch(downloadTopupsFailure())
        dispatch(
          createNotification({
            'download-invalid-topups': {
              type: 'error',
              message: ERROR_DOWNLOAD_SERVER,
            },
          }),
        )
        return err
      })
  }

export const getTopupsFilters = (userId) => (dispatch) => {
  const filters = JSON.parse(localStorage.getItem(`call-filters-${userId}`))
  return dispatch({
    type: ACTION_GET_TOPUPS_FILTER,
    value: filters || {},
  })
}

export const getWhatsapp =
  ({ token, orgId, query = {} }) =>
  (dispatch) => {
    dispatch(getWhatsappRequest())
    const {
      filters = {
        type: ALL,
        status: ALL,
      },
      pageSize = defaultPageSize,
      search = '',
      endCursor = '',
    } = query

    return request.whatsapp
      .get({
        token,
        orgId,
        query: {
          dir: filters.type || 'all',
          failed: filters.status || 'all',
          limit: pageSize,
          after: endCursor,
          search,
          startDate: filters.from,
          endDate: filters.to,
        },
      })
      .then(({ messages, hasNextPage, nextPage } = {}) => {
        dispatch(
          getWhatsappSuccess({
            whatsapp: messages,
            nextPage,
            hasNextPage,
          }),
        )
      })
      .catch((err) => {
        dispatch(
          getWhatsappFailure({
            status: err.status,
            message: ERROR_GET_WHATSAPP_SERVER,
          }),
        )
        return err
      })
  }

export const reloadWhatsapp = () => (dispatch) => dispatch({ type: ACTION_CLEAR_WHATSAPP })

export const downloadWhatsapp =
  ({ token, orgId, query = {} }) =>
  (dispatch) => {
    const {
      filters = {
        type: ALL,
        status: ALL,
      },
      search = '',
    } = query
    dispatch(downloadWhatsappRequest())
    const dlPath = http.whatsapp.download.getSignablePath({
      orgId,
    })
    const data = {
      paths: [dlPath],
    }

    return request.auth
      .sign({ token, item: data })
      .then((res) => {
        dispatch(downloadWhatsappSuccess())
        const url = http.whatsapp.download.getPath({
          query: {
            dir: filters.type || 'all',
            failed: filters.status || 'all',
            search,
            startDate: filters.from,
            endDate: filters.to,
          },
          signedPath: dlPath,
          urlToken: res[dlPath],
        })
        window.location.href = url
      })
      .catch((err) => {
        dispatch(downloadWhatsappFailure())
        dispatch(
          createNotification({
            'download-invalid-whatsapp': {
              type: 'error',
              message: ERROR_DOWNLOAD_SERVER,
            },
          }),
        )
        return err
      })
  }

export const getWhatsappFilters = (userId) => (dispatch) => {
  const filters = JSON.parse(localStorage.getItem(`whatsapp-filters-${userId}`))
  return dispatch({
    type: ACTION_GET_WHATSAPP_FILTER,
    value: filters || {},
  })
}

export const setSendWhatsappMessage =
  ({ recipient, contactId, senderId }) =>
  (dispatch) =>
    dispatch({
      type: ACTION_SET_SEND_MESSAGE,
      value: { recipient, contactId, senderId },
    })

export const sendWhatsappMessage =
  ({
    token,
    orgId,
    isAllContacts = false,
    senderId,
    contactIds,
    excludeIds,
    message,
    includedGroupIds,
    search,
    image,
    audio,
    video,
    document,
  }) =>
  (dispatch) => {
    dispatch(sendWhatsappMessageRequest())

    const item = {
      contacts: {
        allContactsAreSelected: isAllContacts,
        includedContactIds: contactIds,
        excludedContactIds: excludeIds,
        includedGroupIds,
        search,
      },
      from: senderId,
      message,
      image,
      audio,
      video,
      orgId,
      document,
      contactId: contactIds[0],
    }

    return request.whatsapp
      .send({ token, orgId, item })
      .then((response = {}) => {
        dispatch(
          createNotification({
            'send-whatsapp-message': {
              type: response.error ? 'warning' : 'success',
              message: response.error ? 'Invalid/deleted contact or not enough balance.' : 'Message successfully sent.',
            },
          }),
        )
        dispatch(sendWhatsappMessageSuccess())
      })
      .catch((err) => {
        dispatch(
          createNotification({
            'send-whatsapp-message': {
              type: 'error',
              message: path(['response', 'error'], err) || ERROR_SEND_WHATSAPP_MESSAGE_SERVER,
            },
          }),
        )
        return err
      })
  }

export const saveWhatsappFile =
  ({ token, orgId, item }) =>
  (dispatch) => {
    dispatch(saveWhatsappFileRequest())

    return saveFile({
      token,
      orgId,
      item,
    })(dispatch)
      .then((res = {}) => {
        if (res instanceof Error) {
          throw res
        }
        const dlPath = http.files.get.getSignablePath({ orgId, name: res.filename })
        const data = {
          paths: [dlPath],
        }
        return Promise.all([res, dlPath, request.auth.sign({ token, item: data })])
      })
      .then(([{ filename, title }, signedPath, signedRes]) => {
        const result = {
          filename,
          title,
          url: http.files.get.getPath({
            signedPath,
            urlToken: signedRes[signedPath],
          }),
        }

        dispatch(saveWhatsappFileSuccess())
        return result
      })
      .catch((err) => {
        dispatch(saveWhatsappFileFailure())
        dispatch(
          createNotification({
            'save-whatsapp-message': {
              type: 'error',
              message: ERROR_SAVE_WHATSAPP_FILE,
            },
          }),
        )
        return err
      })
  }
