import cookies from 'js-cookie'
import moment from 'moment'
import { path, pathOr } from 'ramda'

import { ACTION_WEBSOCKET_CONNECT, ACTION_WEBSOCKET_DISCONNECT } from '@/app/definitions'
import {
  ACTION_AUTH_ACCEPT_INVITATION_FAILURE,
  ACTION_AUTH_ACCEPT_INVITATION_REQUEST,
  ACTION_AUTH_ACCEPT_INVITATION_SUCCESS,
  ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_FAILURE,
  ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_REQUEST,
  ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_SUCCESS,
  ACTION_AUTH_CHECK_INVITATION_FAILURE,
  ACTION_AUTH_CHECK_INVITATION_REQUEST,
  ACTION_AUTH_CHECK_INVITATION_SUCCESS,
  ACTION_AUTH_LOGIN_FAILURE,
  ACTION_AUTH_LOGIN_REQUEST,
  ACTION_AUTH_LOGIN_SUCCESS,
  ACTION_AUTH_LOGOUT,
  ACTION_AUTH_REQUEST_RESET_PASSWORD_FAILURE,
  ACTION_AUTH_REQUEST_RESET_PASSWORD_REQUEST,
  ACTION_AUTH_REQUEST_RESET_PASSWORD_SUCCESS,
  ACTION_AUTH_RESET_PASSWORD_FAILURE,
  ACTION_AUTH_RESET_PASSWORD_REQUEST,
  ACTION_AUTH_RESET_PASSWORD_SUCCESS,
  ACTION_AUTH_SIGNIN_FAILURE,
  ACTION_AUTH_SIGNIN_REQUEST,
  ACTION_AUTH_SIGNIN_SUCCESS,
  ACTION_AUTH_SIGNUP_DATA_MOD,
  ACTION_AUTH_SIGNUP_EMAIL_CHECK_FAILURE,
  ACTION_AUTH_SIGNUP_EMAIL_CHECK_REQUEST,
  ACTION_AUTH_SIGNUP_EMAIL_CHECK_SUCCESS,
  ACTION_AUTH_SIGNUP_EMAIL_FAILURE,
  ACTION_AUTH_SIGNUP_EMAIL_REQUEST,
  ACTION_AUTH_SIGNUP_EMAIL_SUCCESS,
  ACTION_AUTH_SIGNUP_FAILURE,
  ACTION_AUTH_SIGNUP_REQUEST,
  ACTION_AUTH_SIGNUP_SUCCESS,
  ACTION_AUTH_VERIFY_OTP_FAILURE,
  ACTION_AUTH_VERIFY_OTP_REQUEST,
  ACTION_AUTH_VERIFY_OTP_SUCCESS,
  ACTION_AUTH_VERIFY_RECOVERY_CODE_FAILURE,
  ACTION_AUTH_VERIFY_RECOVERY_CODE_REQUEST,
  ACTION_AUTH_VERIFY_RECOVERY_CODE_SUCCESS,
  ACTION_SET_OTP,
  ACTION_SET_TRIAL_MODE,
  ERROR_ACCEPT_INVITATION,
  ERROR_ACCEPT_INVITATION_WITH_SIGNUP,
  ERROR_AUTH_SIGNUP,
  ERROR_AUTH_SIGNUP_CAPTCHA,
  ERROR_AUTH_SIGNUP_EMAIL_FAILURE,
  ERROR_AUTH_VERIFY_OTP,
  ERROR_AUTH_VERIFY_RECOVERY_CODE,
  ERROR_CHECK_INVITATION,
  ERROR_GET_AUTH_CLIENT,
  ERROR_GET_AUTH_SERVER,
  ERROR_REQUEST_RESET_PASSWORD_FAILURE,
  ERROR_RESET_PASSWORD_FAILURE,
  ERROR_SERVER_AUTH_SIGNUP_EMAIL,
  REQUEST_RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  SUCCESS_AUTH_SIGNUP,
} from '@/app/module/auth/definitions'
import { isTokenAboutToExpire, setToken } from '@/app/module/auth/helpers'
import { getCampaignsPageSize } from '@/app/module/campaigns/store/actions'
import { getContactsPageSize } from '@/app/module/contacts/store/actions'
import { getMessagesFilters, getMessagesPageSize } from '@/app/module/logs/store/actions'
import { createNotification } from '@/app/module/notifications/store/actions'
import request from '@/app/module/request'
import { getUser, setPageLoadSource } from '@/app/module/user/store/actions'
import { createAsyncActions } from '@/app/service/util'
import { clearCookies } from '@/app/service/util/cookie'
import { processErrors } from '@/app/service/util/errorCodes'

const {
  request: loginRequest,
  success: loginSuccess,
  failure: loginFailure,
} = createAsyncActions({
  request: ACTION_AUTH_LOGIN_REQUEST,
  success: ACTION_AUTH_LOGIN_SUCCESS,
  failure: ACTION_AUTH_LOGIN_FAILURE,
})

const {
  request: emailCheckRequest,
  success: emailCheckSuccess,
  failure: emailCheckFailure,
} = createAsyncActions({
  request: ACTION_AUTH_SIGNUP_EMAIL_CHECK_REQUEST,
  success: ACTION_AUTH_SIGNUP_EMAIL_CHECK_SUCCESS,
  failure: ACTION_AUTH_SIGNUP_EMAIL_CHECK_FAILURE,
})

const {
  request: signupRequest,
  success: signupSuccess,
  failure: signupFailure,
} = createAsyncActions({
  request: ACTION_AUTH_SIGNUP_REQUEST,
  success: ACTION_AUTH_SIGNUP_SUCCESS,
  failure: ACTION_AUTH_SIGNUP_FAILURE,
})

const {
  request: signupEmailRequest,
  success: signupEmailSuccess,
  failure: signupEmailFailure,
} = createAsyncActions({
  request: ACTION_AUTH_SIGNUP_EMAIL_REQUEST,
  success: ACTION_AUTH_SIGNUP_EMAIL_SUCCESS,
  failure: ACTION_AUTH_SIGNUP_EMAIL_FAILURE,
})

const {
  request: resetPasswordRequest,
  success: resetPasswordSuccess,
  failure: resetPasswordFailure,
} = createAsyncActions({
  request: ACTION_AUTH_RESET_PASSWORD_REQUEST,
  success: ACTION_AUTH_RESET_PASSWORD_SUCCESS,
  failure: ACTION_AUTH_RESET_PASSWORD_FAILURE,
})

const {
  request: requestResetPasswordRequest,
  success: requestResetPasswordSuccess,
  failure: requestResetPasswordFailure,
} = createAsyncActions({
  request: ACTION_AUTH_REQUEST_RESET_PASSWORD_REQUEST,
  success: ACTION_AUTH_REQUEST_RESET_PASSWORD_SUCCESS,
  failure: ACTION_AUTH_REQUEST_RESET_PASSWORD_FAILURE,
})

const {
  request: signinRequest,
  success: signinSuccess,
  failure: signinFailure,
} = createAsyncActions({
  request: ACTION_AUTH_SIGNIN_REQUEST,
  success: ACTION_AUTH_SIGNIN_SUCCESS,
  failure: ACTION_AUTH_SIGNIN_FAILURE,
})

const {
  request: verifyOTPRequest,
  success: verifyOTPSuccess,
  failure: verifyOTPFailure,
} = createAsyncActions({
  request: ACTION_AUTH_VERIFY_OTP_REQUEST,
  success: ACTION_AUTH_VERIFY_OTP_SUCCESS,
  failure: ACTION_AUTH_VERIFY_OTP_FAILURE,
})

const {
  request: verifyRecoveryCodeRequest,
  success: verifyRecoveryCodeSuccess,
  failure: verifyRecoveryCodeFailure,
} = createAsyncActions({
  request: ACTION_AUTH_VERIFY_RECOVERY_CODE_REQUEST,
  success: ACTION_AUTH_VERIFY_RECOVERY_CODE_SUCCESS,
  failure: ACTION_AUTH_VERIFY_RECOVERY_CODE_FAILURE,
})

const {
  request: checkInvitationRequest,
  success: checkInvitationSuccess,
  failure: checkInvitationFailure,
} = createAsyncActions({
  request: ACTION_AUTH_CHECK_INVITATION_REQUEST,
  success: ACTION_AUTH_CHECK_INVITATION_SUCCESS,
  failure: ACTION_AUTH_CHECK_INVITATION_FAILURE,
})

const {
  request: acceptInvitationRequest,
  success: acceptInvitationSuccess,
  failure: acceptInvitationFailure,
} = createAsyncActions({
  request: ACTION_AUTH_ACCEPT_INVITATION_REQUEST,
  success: ACTION_AUTH_ACCEPT_INVITATION_SUCCESS,
  failure: ACTION_AUTH_ACCEPT_INVITATION_FAILURE,
})

const {
  request: acceptInvitationWithSignupRequest,
  success: acceptInvitationWithSignupSuccess,
  failure: acceptInvitationWithSignupFailure,
} = createAsyncActions({
  request: ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_REQUEST,
  success: ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_SUCCESS,
  failure: ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_FAILURE,
})

const { request: setTrialMode } = createAsyncActions({
  request: ACTION_SET_TRIAL_MODE,
})

const { request: setOTPRequest } = createAsyncActions({
  request: ACTION_SET_OTP,
})

export {
  emailCheckFailure,
  emailCheckRequest,
  emailCheckSuccess,
  loginFailure,
  loginRequest,
  loginSuccess,
  requestResetPasswordFailure,
  requestResetPasswordRequest,
  requestResetPasswordSuccess,
  resetPasswordFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  signinFailure,
  signinRequest,
  signinSuccess,
  signupFailure,
  signupRequest,
  signupSuccess,
  verifyOTPFailure,
  verifyOTPRequest,
  verifyOTPSuccess,
  verifyRecoveryCodeFailure,
  verifyRecoveryCodeRequest,
  verifyRecoveryCodeSuccess,
}

const SIGNUP_ERROR_MAP = {
  'no captcha token provided': ERROR_AUTH_SIGNUP_CAPTCHA,
  'captcha token invalid': ERROR_AUTH_SIGNUP_CAPTCHA,
}

export const getUserPreferences =
  ({ user, authToken }) =>
  (dispatch) => {
    dispatch({ type: ACTION_WEBSOCKET_CONNECT, orgId: user.activeOrgId, token: authToken })
    getMessagesFilters(user.id)(dispatch)
    getContactsPageSize(user.id)(dispatch)
    getMessagesPageSize(user.id)(dispatch)
    getCampaignsPageSize(user.id)(dispatch)
    dispatch(createNotification({}))
  }

export const signin =
  ({ username, password, verifyToken }) =>
  (dispatch) => {
    dispatch(signinRequest({ username, password }))

    return request.auth
      .signin({ item: { username, password, verify_token: verifyToken } })
      .then(({ access: reqToken, totpEnabled, validUntil }) => {
        if (!totpEnabled) {
          setToken(reqToken, validUntil)
          dispatch(signinSuccess({ token: reqToken }))
        } else {
          const expiryDate = validUntil ? moment(validUntil).toDate() : moment().add(7, 'day').toDate()
          cookies.set('otpToken', reqToken, { expires: expiryDate, sameSite: 'strict', secure: true })
          setOTP({ token: reqToken })(dispatch)
        }
        return { authToken: reqToken, totpEnabled }
      })
      .catch((err) => {
        dispatch(
          signinFailure({
            status: err.status,
            message: err.message,
          }),
        )
        return err
      })
  }

export const login =
  ({ token, item: { username, password } = {}, verifyToken }) =>
  (dispatch) => {
    dispatch(loginRequest(token ? { token } : { username, password }))

    if (token && isTokenAboutToExpire()) {
      logout()(dispatch)
      return Promise.resolve(false)
    }

    const authenticate = () =>
      !token
        ? signin({ username, password, verifyToken })(dispatch)
        : Promise.resolve({ authToken: token, totpEnabled: false })

    return authenticate()
      .then((res) => {
        const { authToken, totpEnabled } = res
        if (res instanceof Error) {
          throw res
        }
        if (totpEnabled) {
          return [authToken]
        }
        if (!token) {
          setPageLoadSource('login')
        }
        return Promise.all([authToken, getUser({ token: authToken })(dispatch)])
      })
      .then(([authToken, user]) => {
        if (user) {
          // bootstrap user preferences before dispatching the success action
          if (!(user instanceof Error)) {
            getUserPreferences({ user, authToken })(dispatch)
          }
          dispatch(loginSuccess({ token: authToken }))
          return user
        }
        return { totpEnabled: true, authToken }
      })
      .catch((err) => {
        if (err.status && err.status >= 400 && err.status < 500) {
          if (err.response?.errorCode === 'login.locked') {
            dispatch(
              loginFailure({
                status: err.status,
                message: err.response.error,
              }),
            )
            dispatch(
              createNotification({
                'login-error': {
                  type: 'error',
                  message: err.response.error,
                },
              }),
            )
            return err
          }

          dispatch(
            loginFailure({
              status: err.status,
              message: ERROR_GET_AUTH_CLIENT,
            }),
          )
          dispatch(
            createNotification({
              'login-error': {
                type: 'error',
                message: ERROR_GET_AUTH_CLIENT,
              },
            }),
          )
          return err
        }
        dispatch(
          loginFailure({
            status: err.status,
            message: ERROR_GET_AUTH_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'login-error': {
              type: 'error',
              message: ERROR_GET_AUTH_SERVER,
            },
          }),
        )
        return err
      })
  }

export const requestResetPassword =
  ({ item }) =>
  (dispatch) => {
    const { email } = item
    const data = new FormData()
    data.append('email', email)
    dispatch(requestResetPasswordRequest({ email }))
    return request.auth
      .requestResetPassword({
        item: data,
      })
      .then((res) => {
        dispatch(
          createNotification({
            'reset-password-success': {
              type: 'success',
              message: REQUEST_RESET_PASSWORD_SUCCESS,
            },
          }),
        )
        dispatch(requestResetPasswordSuccess())
        return res
      })
      .catch((err) => {
        dispatch(
          createNotification({
            'reset-password-error': {
              type: 'error',
              message: ERROR_REQUEST_RESET_PASSWORD_FAILURE,
            },
          }),
        )
        dispatch(requestResetPasswordFailure())
        return err
      })
  }

export const resetPassword =
  ({ id, key, item }) =>
  (dispatch) => {
    const { password1, password2 } = item
    const data = new FormData()
    data.append('id', id)
    data.append('key', key)
    data.append('password1', password1)
    data.append('password2', password2)
    dispatch(resetPasswordRequest())
    return request.auth
      .resetPassword({
        item: {
          password1,
          password2,
          id,
          key,
        },
      })
      .then((res) => {
        dispatch(resetPasswordSuccess())
        dispatch(
          createNotification({
            'reset-password-success': {
              type: 'success',
              message: RESET_PASSWORD_SUCCESS,
            },
          }),
        )
        return res
      })
      .catch((err) => {
        dispatch(
          createNotification({
            'reset-password-error': {
              type: 'error',
              message: ERROR_RESET_PASSWORD_FAILURE,
            },
          }),
        )
        dispatch(resetPasswordFailure())
        return err
      })
  }

export const emailCheck =
  ({ email }) =>
  (dispatch) => {
    dispatch(emailCheckRequest({ email }))

    return request.auth
      .emailCheck({
        query: {
          email,
        },
      })
      .then((res = {}) => {
        if (!res.available) {
          const err = new Error()
          err.reason = ERROR_AUTH_SIGNUP_EMAIL_FAILURE
          throw err
        }
        dispatch(emailCheckSuccess({ email }))
        return res.available
      })
      .catch((err) => {
        const msg = err.reason || ERROR_SERVER_AUTH_SIGNUP_EMAIL
        dispatch(
          emailCheckFailure({
            email,
            message: msg,
          }),
        )
        dispatch(
          createNotification({
            'signup-error': {
              type: 'error',
              message: msg,
            },
          }),
        )
        return err
      })
  }

export const signup =
  ({ item }) =>
  (dispatch) => {
    const cid = cookies.get('es_cid')
    const ref = cookies.get('es_referer_url')

    dispatch(
      signupRequest({
        ...item,
        marketingCode: cid,
        referrer: ref,
      }),
    )

    const data = {
      verify_token: item.verifyToken,
      email: item.email,
      email_token: item.emailToken,
      password: item.password,
      first_name: item.firstName,
      last_name: item.lastName,
      phone: item.phone,
      country: item.country,
      hear_about_us: '',
      organization: item.organization,
      organization_type: item.organizationType,
      marketing_code: cid,
      referer: ref,
    }

    return emailCheck({
      email: item.email,
    })(dispatch)
      .then((res) => {
        if (res instanceof Error) {
          const err = new Error(res.message)
          err.notification = false
          throw err
        }
        return request.auth.signup({ item: data })
      })
      .then((res) => {
        dispatch(
          signupSuccess({
            ...res,
            signupData: {
              email: item.email,
              username: item.email,
              firstName: item.firstName,
              lastName: item.lastName,
              phoneNumber: item.phone,
              country: item.country,
              organizationType: item.organizationType,
              organization: item.organization,
              hearAboutUs: '',
              referrer: ref,
              marketingCode: cid,
            },
          }),
        )
        dispatch(
          createNotification({
            'signup-success': {
              type: 'success',
              message: SUCCESS_AUTH_SIGNUP,
              disableAutoHide: true,
            },
          }),
        )
        const authToken = res.access
        setToken(authToken, res.validUntil)
        return dispatch(getUser({ token: authToken })).then((user) => {
          // bootstrap user preferences before dispatching the success action
          if (!(user instanceof Error)) {
            getUserPreferences({ user, authToken })(dispatch)
          }
          dispatch(loginSuccess({ token: authToken }))
          return user
        })
      })
      .catch((err) => {
        const responseErr = path(['response', 'error'], err)
        const msg = pathOr(ERROR_AUTH_SIGNUP, [responseErr], SIGNUP_ERROR_MAP)

        dispatch(signupFailure({ message: msg }))

        if (err.notification !== false) {
          dispatch(
            createNotification({
              'signup-error': {
                type: 'error',
                message: msg,
              },
            }),
          )
        }
        return err
      })
  }

export const signUpEmail =
  ({ item, verifyToken }) =>
  (dispatch) => {
    dispatch(signupEmailRequest({ email: item.email, verifyToken }))

    return request.auth
      .signupEmail({
        item: {
          email: item.email,
          verify_token: verifyToken,
        },
      })
      .then((res) => {
        dispatch(signupEmailSuccess())
        return res
      })
      .catch((err) => {
        const responseErr = path(['response', 'error'], err)
        const msg = pathOr(ERROR_AUTH_SIGNUP, [responseErr], SIGNUP_ERROR_MAP)

        dispatch(signupEmailFailure({ message: msg }))

        if (err.notification !== false) {
          dispatch(
            createNotification({
              'signup-error': {
                type: 'error',
                message: msg,
              },
            }),
          )
        }
        return err
      })
  }

export const logout = () => (dispatch) => {
  clearCookies()
  cookies.set('logout', true, { sameSite: 'strict', secure: true })
  localStorage.clear()
  dispatch({
    type: ACTION_AUTH_LOGOUT,
  })
  dispatch({
    type: ACTION_WEBSOCKET_DISCONNECT,
  })
}

export const modSignupData = (data) => ({
  type: ACTION_AUTH_SIGNUP_DATA_MOD,
  value: data,
})

export const activateTrialMode =
  ({ trialMode }) =>
  (dispatch) => {
    dispatch(setTrialMode(trialMode))
  }

export const submitOTP =
  ({ item, token }) =>
  (dispatch) => {
    const { otp } = item
    dispatch(verifyOTPRequest)

    const data = new URLSearchParams()
    data.append('passcode', otp)
    return request.auth
      .verifyOTP({ token, item: data })
      .then((res) => {
        const { access: authToken, validUntil } = JSON.parse(res)
        cookies.remove('otpToken')
        setToken(authToken, validUntil)
        setPageLoadSource('login')
        return dispatch(getUser({ token: authToken })).then((user) => {
          if (user) {
            // bootstrap user preferences before dispatching the success action
            if (!(user instanceof Error)) {
              getUserPreferences({ user, authToken })(dispatch)
            }
            dispatch(loginSuccess({ token: authToken }))
            return user
          }
          return { totpEnabled: true, authToken }
        })
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_AUTH_VERIFY_OTP)
        dispatch(verifyOTPFailure(err))
        dispatch(
          createNotification({
            'verify-OTP-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const submitRecoveryCode =
  ({ item, token }) =>
  (dispatch) => {
    const { recoveryCode } = item
    dispatch(verifyOTPRequest)

    const data = new URLSearchParams()
    data.append('recovery', recoveryCode)
    return request.auth
      .verifyOTP({ token, item: data })
      .then((res) => {
        const { access: authToken, validUntil } = JSON.parse(res)
        cookies.remove('otpToken')
        setToken(authToken, validUntil)
        setPageLoadSource('login')
        return dispatch(getUser({ token: authToken })).then((user) => {
          if (user) {
            if (!(user instanceof Error)) {
              getUserPreferences({ user, authToken })(dispatch)
            }
            dispatch(loginSuccess({ token: authToken }))
            return user
          }
          return { totpEnabled: true, authToken }
        })
      })
      .catch((err) => {
        dispatch(verifyRecoveryCodeFailure(err))
        dispatch(
          createNotification({
            'verify-recovery-code-error': {
              type: 'error',
              message: ERROR_AUTH_VERIFY_RECOVERY_CODE,
            },
          }),
        )
        return err
      })
  }

export const setOTP =
  ({ token }) =>
  (dispatch) => {
    dispatch(setOTPRequest(token))
  }

export const checkInvitation =
  ({ token, invitationToken }) =>
  (dispatch) => {
    dispatch(checkInvitationRequest())

    return request.auth
      .checkInvitation({
        token,
        invitationToken,
      })
      .then((res = {}) => {
        dispatch(checkInvitationSuccess(res))
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_CHECK_INVITATION)
        dispatch(checkInvitationFailure(err))
        dispatch(
          createNotification({
            'check-Invitation-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const acceptInvitation =
  ({ token, invitationToken }) =>
  (dispatch) => {
    dispatch(acceptInvitationRequest())

    const data = new URLSearchParams()
    data.append('invtoken', invitationToken)

    return request.auth
      .acceptInvitation({
        token,
        item: data,
      })
      .then(async (res = {}) => {
        dispatch(
          createNotification({
            'accept-Invitation-success': {
              type: 'success',
              message: 'Invitation successfully accepted.',
            },
          }),
        )
        await dispatch(login({ token })) // login after accepting invitation to get user data
        dispatch(acceptInvitationSuccess(res))
        cookies.remove('redirectURL')
        return res
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_ACCEPT_INVITATION)
        dispatch(acceptInvitationFailure(err))
        dispatch(
          createNotification({
            'accept-Invitation-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const acceptInvitationWithSignup =
  ({ item }) =>
  (dispatch) => {
    dispatch(acceptInvitationWithSignupRequest())

    return request.auth
      .acceptInvitationWithSignup({
        item,
      })
      .then((res = {}) => {
        dispatch(acceptInvitationWithSignupSuccess(res))
        cookies.remove('redirectURL')
        return res
      })
      .then((res) => {
        dispatch(
          createNotification({
            'signup-success': {
              type: 'success',
              message: SUCCESS_AUTH_SIGNUP,
              disableAutoHide: true,
            },
          }),
        )
        const authToken = res.access
        setToken(authToken, res.validUntil)
        setPageLoadSource('login')
        return dispatch(getUser({ token: authToken })).then((user) => {
          // bootstrap user preferences before dispatching the success action
          if (!(user instanceof Error)) {
            getUserPreferences({ user, authToken })(dispatch)
          }
          dispatch(loginSuccess({ token: authToken }))
          return user
        })
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_ACCEPT_INVITATION_WITH_SIGNUP)
        dispatch(acceptInvitationWithSignupFailure(err))
        dispatch(
          createNotification({
            'accept-Invitation-with-signup-error': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }
