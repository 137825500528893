import { Column } from '@/config/types'

export const getPageSize = ({ namespace, defaultValue }: { namespace: string; defaultValue: number | string }) => {
  try {
    if (window.innerWidth > 800) {
      const storedValue = localStorage.getItem(`${namespace}-pageSize`)
      if (storedValue) {
        const parsed = parseInt(storedValue, 10)
        if (!Number.isNaN(parsed)) {
          return parsed
        }
      }
    }
    return defaultValue
  } catch {
    return defaultValue
  }
}

export const setPageSize = ({ namespace, value }: { namespace: string; value: number | string }) => {
  try {
    if (window.innerWidth > 800) {
      localStorage.setItem(`${namespace}-pageSize`, value.toString())
    }
    return undefined
  } catch {
    return undefined
  }
}
export const getContactHeaders = ({ orgId }: { orgId: number }) => {
  try {
    if (window.innerWidth > 800) {
      const storedValue = localStorage.getItem(`contacts-headers-${orgId}`)
      if (storedValue) {
        return JSON.parse(storedValue)
      }
    }
    return []
  } catch {
    return []
  }
}

export const setContactHeaders = ({ orgId, headers }: { orgId: number; headers: Column[] }) => {
  try {
    if (window.innerWidth > 800) {
      localStorage.setItem(`contacts-headers-${orgId}`, JSON.stringify(headers))
    }
    return undefined
  } catch {
    return undefined
  }
}
