import { v1 as uuid } from 'uuid'

import { cannot } from '@/app/helpers'
import { LocalActionType, LocalNonEmptyAction } from '@/app/module/campaigns/types'

/**
 * @returns a default value for a new action of the given type to use when adding a new action
 */
export const generateActionFormValue = (type: LocalActionType): LocalNonEmptyAction => {
  const id = uuid()
  switch (type) {
    case LocalActionType.API:
      return {
        id,
        type,
        makeAPIRequest: {
          headers: [],
          method: 'GET',
          url: '',
          payload: '',
        },
      }

    case LocalActionType.FinishFlow:
      return {
        id,
        type,
        finishFlow: {},
      }

    case LocalActionType.Hangup:
      return {
        id,
        type,
        hangup: {},
      }

    case LocalActionType.Jump:
      return {
        id,
        type,
        jump: {
          nextPart: '',
        },
      }

    case LocalActionType.PauseSubscription:
      return {
        id,
        type,
        setPaused: {
          paused: true,
          current: true,
        },
      }

    case LocalActionType.PlayAudio:
      return {
        id,
        type,
        play: {
          playfile: '',
        },
      }

    case LocalActionType.Replay:
      return {
        id,
        type,
        replayQuestion: {},
      }

    case LocalActionType.Resend:
      return {
        id,
        type,
        resendQuestion: {},
      }

    case LocalActionType.ResumeSubscription: {
      return {
        id,
        type,
        setPaused: {
          paused: false,
          campId: 0,
        },
      }
    }

    case LocalActionType.SendEmail: {
      return {
        id,
        type,
        sendEmail: {
          from: '',
          subject: '',
          message: '',
          toMultiple: [],
        },
      }
    }

    case LocalActionType.SendSMS: {
      return {
        id,
        type,
        sendSMS: {
          text: '',
          senderId: '',
          useCampaignSenderId: '',
        },
      }
    }

    case LocalActionType.SendSMSToPhoneNumbers: {
      return {
        id,
        type,
        sendSMSToPhoneNumbers: {
          text: '',
          senderId: '',
          phoneNumbers: [],
          useCampaignSenderId: '',
        },
      }
    }

    case LocalActionType.SendWhatsApp: {
      return {
        id,
        type,
        sendWhatsapp: {
          text: '',
          registerTemplate: false,
          senderId: '',
          useCampaignSenderId: '',
        },
      }
    }

    case LocalActionType.SubscribeToCampaign: {
      return {
        id,
        type,
        subscribeToCampaign: {
          campaignId: 0,
          campaignName: '',
        },
      }
    }

    case LocalActionType.Topup: {
      return {
        id,
        type,
        topup: {
          maxAmount: '',
          maxAmountTemplated: '',
          desiredAmountTemplated: '',
        },
      }
    }

    case LocalActionType.Transfer: {
      return {
        id,
        type,
        transfer: {
          to: '',
        },
      }
    }

    case LocalActionType.Unsubscribe: {
      return {
        id,
        type,
        unsubscribe: {
          allContactsAreSelected: false,
          campId: '',
          current: true,
        },
      }
    }

    case LocalActionType.UnsubscribeAll: {
      return {
        id,
        type,
        unsubscribeAll: {},
      }
    }

    case LocalActionType.UpdateContact: {
      return {
        id,
        type,
        updateContact: {
          field: '',
          value: '',
        },
      }
    }

    default:
      return cannot(type)
  }
}
