import { Button, Icon } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { ChannelType } from '@/app/module/phonenumbers/types'

type Props = {
  channel: ChannelType
  id: number
  hooksCount: number
}

const ChannelLink: React.FC<Props> = ({ channel, hooksCount, id }) => {
  const history = useHistory()

  const name = getChannelName(channel)

  const handleClick = React.useCallback(() => {
    const link = generateLink(channel, id)
    history.push(link)
  }, [channel, history, id])

  if (channel === ChannelType.Campaign) {
    return (
      <Button startIcon={<Icon>{!hooksCount ? 'add' : 'edit'}</Icon>} onClick={handleClick}>
        {!hooksCount ? 'Add' : 'Edit'} voice campaign
      </Button>
    )
  }

  return (
    <Button onClick={handleClick}>
      {name} ({hooksCount} rule{hooksCount !== 1 ? 's' : ''})
    </Button>
  )
}

const generateLink = (channel: ChannelType, id: number) => `/phonenumbers/${id}/${channel}`

const getChannelName = (channel: ChannelType) => {
  switch (channel) {
    case ChannelType.Campaign:
      return 'Voice campaign'
    case ChannelType.Sms:
      return 'SMS'
    case ChannelType.Voice:
      return 'Voice'
    case ChannelType.WhatsApp:
      return 'WhatsApp'
    default:
      return ''
  }
}

export default React.memo(ChannelLink)
