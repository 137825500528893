import * as Sentry from '@sentry/react'
import { is } from 'ramda'

import { ERROR_SERVER } from '@/app/definitions'

import { clearCookies } from './cookie'

type HTTPErrorProps = {
  status: Response['status']
  statusText: Response['statusText']
  body: Response['body']
}

export class HTTPError extends Error {
  public type: string

  public status: number

  public message: string

  public response: any

  constructor(res: HTTPErrorProps) {
    super()
    this.type = 'http'
    this.status = res.status
    this.message = res.statusText
    this.response = res.body
  }
}

const baseHeaders = {
  'Content-Type': 'application/json',
}

export const headers = (token?: string) =>
  token
    ? {
        headers: {
          ...baseHeaders,
          Authorization: `JWT ${token}`,
        },
      }
    : {
        headers: baseHeaders,
      }

export const errorHandler = () =>
  Promise.resolve({
    ok: false,
    status: 503,
    statusText: ERROR_SERVER,
  })

export const responseHandler = (isJson: boolean, jsonResponse: boolean) => (res: Response) => {
  if (!res.ok && is(Function, res.json)) {
    return res
      .json()
      .then((jsonErr) => {
        Sentry.setContext('error', {
          status: res.status,
          statusText: jsonErr.error || jsonErr.non_field_errors,
          url: res.url,
        })
        Sentry.withScope((scope) => {
          scope.setLevel('error').setTag('401', 'Auth')
        })
        Sentry.captureException(new Error(`${res.status}`))

        if (res.status === 401) {
          clearCookies()
          window.location.reload()
          return
        }
        throw new HTTPError({
          status: res.status,
          statusText: res.statusText,
          body: jsonErr,
        })
      })
      .catch((err) => {
        if (!(err instanceof HTTPError)) {
          // for cases when the API returns an inconsistent non-json response body for a json request
          throw new HTTPError({
            status: res.status,
            statusText: res.statusText,
            body: null,
          })
        } else {
          throw err
        }
      })
  }

  if (!res.ok) {
    throw new HTTPError(res)
  }

  if (!jsonResponse) {
    return res
  }

  if (!isJson) {
    return res.text()
  }

  return res.json()
}

export const memoizeAsync = <T extends object, R>(hasher: (s: T) => string, fn: (s: T) => Promise<R>, timeout = 0) => {
  const cache: Record<string, R> = {}
  return async (reqSpecs: T) => {
    const newId = hasher(reqSpecs)
    if (timeout > 0 && cache[newId]) {
      return cache[newId]
    }
    const res = await fn(reqSpecs)
    if (timeout > 0) {
      cache[newId] = res
      setTimeout(() => {
        delete cache[newId]
      }, timeout)
    }
    return res
  }
}

export const preventParallel = <T, D, R>(hasher: (s: T) => string, fn: (s: T) => (dispatch: D) => Promise<R>) => {
  const load: { id: false | string; req?: Promise<R> } = {
    id: false,
  }
  return (reqSpecs: T) => (dispatch: D) => {
    const newId = hasher(reqSpecs)
    if (load.id && load.id === newId) {
      return load.req
    }
    load.id = newId
    load.req = fn(reqSpecs)(dispatch).then((res) => {
      load.id = false
      return res
    })
    return load.req
  }
}
