import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import React from 'react'

import config from '@/config'

type Props = {
  orgId: number
  role: string
  token: string

  downloadAuditLog: (params: { orgId: number; token: string; from: string; to: string }) => Promise<void>
}

type State = {
  from: moment.Moment | null
  to: moment.Moment | null
}

const initialState = {
  from: moment().subtract(30, 'day').startOf('day'),
  to: moment().endOf('day'),
}

const dateFormat = config.display.format.date

export default function AuditLog(props: Props) {
  const { orgId, role, token, downloadAuditLog } = props
  const [error, setError] = React.useState<string | null>(null)
  const [state, setState] = React.useState<State>(initialState)
  const [downloading, setDownloading] = React.useState(false)

  const download = async () => {
    if (!validate(state, isAuthorized, error)) {
      return
    }
    setDownloading(true)
    try {
      if (!state.from || !state.to) {
        // should not happen since we have validated the state but the compiler does not know that
        return
      }

      await downloadAuditLog({ orgId, token, from: state.from.toISOString(), to: state.to.toISOString() })
    } finally {
      setDownloading(false)
    }
  }

  const isAuthorized = React.useMemo(() => role === 'owner' || role === 'manager', [role])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div
        style={{
          padding: 16,
        }}
      >
        <Typography variant="h5" color="primary" gutterBottom>
          Audit Log
        </Typography>
        <div
          style={{
            display: 'flex',
            marginBottom: '30px',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              paddingRight: '30px',
            }}
          >
            <DatePicker
              label="From"
              format={dateFormat}
              disableFuture
              value={state.from}
              onChange={(date) =>
                setState({
                  ...state,
                  from: date ? date.startOf('day') : null,
                })
              }
              onError={setError}
            />
          </div>
          <div
            style={{
              paddingRight: '30px',
            }}
          >
            <DatePicker
              label="To"
              format={dateFormat}
              disableFuture
              minDate={state.from || undefined}
              maxDate={moment()}
              value={state.to}
              onChange={(date) =>
                setState({
                  ...state,
                  to: date ? date.endOf('day') : null,
                })
              }
              onError={setError}
            />
          </div>
          <Tooltip title={isAuthorized ? 'Download audit log' : 'You are not authorized to access the audit log.'}>
            <span>
              <Button
                disabled={downloading || !validate(state, isAuthorized, error)}
                color="primary"
                variant="contained"
                onClick={() => {
                  download()
                }}
              >
                Download Audit Log
              </Button>
            </span>
          </Tooltip>
        </div>
        <div>
          <Typography variant="h6" color="textSecondary">
            The audit log shows significant activity by users of this organization, for example login or download of
            contacts.
          </Typography>
        </div>
      </div>
    </LocalizationProvider>
  )
}

const validate = (state: State, isAuthorized: boolean, error: string | null) => {
  return isAuthorized && !error && !!state.from && !!state.to && state.from.isValid() && state.to.isValid()
}
