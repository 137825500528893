import { Icon, Typography } from '@mui/material'
import React from 'react'

import IconText from '@/app/component/layout/icon-text'

type Props = {
  registered: boolean
}

const ConnectMetaStatus: React.FC<Props> = ({ registered }) => {
  return (
    <IconText>
      <Icon
        color={registered ? 'success' : 'action'}
        style={{
          fontSize: '1.75rem',
          marginRight: '0.3rem',
        }}
      >
        {registered ? 'task_alt' : 'error'}
      </Icon>
      <Typography variant="body2">{registered ? 'Connected to Meta' : 'Not connected to Meta'}</Typography>
    </IconText>
  )
}

export default ConnectMetaStatus
