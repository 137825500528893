import moment from 'moment'
import React from 'react'

import { DateTime } from '@/app/component/atom/form'
import { noTimezoneDateTimeFormat } from '@/app/service/util/format'

type Props = {
  t0: string
  t0timezone: string
  onChange: (t0: string) => void
}

const EventTimePicker: React.FC<Props> = ({ t0, t0timezone, onChange }) => {
  return (
    <DateTime
      disablePast={false}
      label="Select date and time"
      name="t0-datetime"
      timezone={t0timezone}
      value={t0 || moment().format(noTimezoneDateTimeFormat)}
      onChange={({ value }) => {
        if (!value) {
          return
        }

        onChange(value.format(noTimezoneDateTimeFormat))
      }}
    />
  )
}

export default React.memo(EventTimePicker)
