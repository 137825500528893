import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Button, Menu } from '@mui/material'
import React from 'react'

import SingleChoice from '@/app/component/atom/filters/single-choice'
import { SubscriptionFilter } from '@/app/module/campaigns/types/subscriptions'
import { SelectOption } from '@/app/types'

type Props = {
  subscriptionFilter?: SubscriptionFilter
  onChange: (filter: SubscriptionFilter) => void
}

const filterOptions: SelectOption<SubscriptionFilter>[] = [
  {
    label: 'All contacts',
    value: SubscriptionFilter.All,
  },
  {
    label: 'Subscribed',
    value: SubscriptionFilter.IsSubscribed,
  },
  {
    label: 'Not subscribed',
    value: SubscriptionFilter.IsNotSubscribed,
  },
  {
    label: 'Was subscribed',
    value: SubscriptionFilter.WasSubscribed,
  },
]

const SubscriptionsFilter: React.FC<Props> = ({ subscriptionFilter = SubscriptionFilter.All, onChange }) => {
  const anchorEl = React.useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = React.useState(false)

  const selected = React.useMemo(() => filterOptions.find((o) => o.value === subscriptionFilter), [subscriptionFilter])

  return (
    <div ref={anchorEl}>
      <Button
        endIcon={<ArrowDropDownIcon />}
        startIcon={<FilterListIcon />}
        style={{
          marginRight: '15px',
          paddingLeft: 20,
          paddingRight: 20,
        }}
        variant="outlined"
        onClick={() => setOpen(true)}
      >
        {selected?.label || 'Subscription status'}
      </Button>
      <Menu id="subscriptions-filter-menu" anchorEl={anchorEl.current} open={open} onClose={() => setOpen(false)}>
        <SingleChoice
          name="subscriptions-filter"
          options={filterOptions}
          value={subscriptionFilter}
          onChange={(v) => {
            onChange(v as SubscriptionFilter)
            setOpen(false)
          }}
        />
      </Menu>
    </div>
  )
}

export default SubscriptionsFilter
