import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Conversations from '@/app/module/conversations'
import Notifications from '@/app/module/notifications'
import { FixMeLater } from '@/config/types'

export default function ConversationPage(props: FixMeLater) {
  const { match, location } = props
  const params = new URLSearchParams(location.search)
  const contactId = params.get('contactId') ? parseInt(params.get('contactId') as any, 10) : 0
  return (
    <AuthGuard url={match.url} path={match.path}>
      <Layout title="Conversations - engageSPARK">
        <AuthHeader currentPage={PAGE.CONVERSATIONS.INDEX} />
        {/* @ts-ignore */}
        <Conversations contactId={contactId} />
      </Layout>
      <Notifications />
    </AuthGuard>
  )
}
