import { stringify } from 'qs'

import { ReceiptLines } from '@/app/types'

import config from '.'

const downloadConfig = {
  exports: {
    getPath: ({
      orgId = 0,
      exportId = 0,
      filename = 'filename.ext',
    }: {
      orgId: number
      exportId: number
      filename: string
    }) => `${config.svc.url}/v1/organizations/${orgId}/exports/${exportId}/${filename}`,
  },
  invoices: {
    getPath: ({ orgId, filename, lines }: { orgId: number; filename: string; lines?: ReceiptLines }) =>
      `${config.svc.url}/v1/organizations/${orgId}/invoices/${filename}${lines ? `?${stringify(lines)}` : ''}`,
  },
  receipts: {
    getFileName: (transactionId: number) => `receipt-${transactionId}.pdf`,
    getPath: ({ orgId, transactionId = 0, lines }: { orgId: number; transactionId: number; lines: ReceiptLines }) =>
      `${config.svc.url}/v1/organizations/${orgId}/receipts/${transactionId}/?${stringify(lines)}`,
  },
}

export default downloadConfig
