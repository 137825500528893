/* eslint-disable max-len */
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { isEmpty, map, pathOr } from 'ramda'
import React from 'react'
import SMSHelper from 'smshelper'
import { withStyles } from 'tss-react/mui'

import PhoneNumberField from '@/app/component/atom/form/phoneNumberField'
import Select from '@/app/component/atom/form/select'
import IconText from '@/app/component/layout/icon-text'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { DEFAULT_SENDER_ID_VALUE } from '@/app/definitions'
import SMSLanguageWrapper from '@/app/module/campaigns/component/item/steps/content/language-wrapper'
import { displayMap, Q_ACTIONS, smsMaxLength } from '@/app/module/campaigns/definitions'
import { hasContent } from '@/app/module/campaigns/helpers'
import Message from '@/app/module/logs/component/send/message'
import { DEFAULT_SENDER_ID_MESSAGE } from '@/app/module/logs/definitions'

const styles = () => ({
  actions: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    padding: '24px',
  },
})

const trimmedPhoneNumbers = (phoneNumbers) =>
  map((phoneNumber) => (phoneNumber.charAt(0) === '+' ? phoneNumber.substr(1) : phoneNumber), phoneNumbers)

const unwrapValue = (value) =>
  pathOr(
    {
      text: '',
      phoneNumbers: [],
      senderId: '0',
      useCampaignSenderId: '',
      translations: {},
    },
    ['sendSMSToPhoneNumbers'],
    value,
  )

const SMSToPhoneNumbers = (props) => {
  const {
    text,
    translations,
    phoneNumbers: initialPhoneNumbers,
    senderId: initialSenderId,
    useCampaignSenderId,
  } = unwrapValue(props.value)
  const [phoneNumbers, setPhoneNumbers] = React.useState(initialPhoneNumbers)
  const [message, setMessage] = React.useState({
    text,
    translations,
  })
  const [senderId, setSenderId] = React.useState(initialSenderId)
  const [senderIds, setSenderIds] = React.useState(props.senderIds)
  const { onClose, onSave } = props

  React.useEffect(() => {
    if (!isEmpty(props.defaultSenderId) && useCampaignSenderId !== 'action') {
      setSenderId(props.defaultSenderId.value)
    } else if (initialSenderId === '0') {
      setSenderId(pathOr(DEFAULT_SENDER_ID_VALUE, ['phoneNumber'], props.phoneNumber))
    } else setSenderId(initialSenderId === '' ? DEFAULT_SENDER_ID_VALUE : initialSenderId)
  }, [props.defaultSenderId, initialSenderId, useCampaignSenderId, props.phoneNumber])

  React.useEffect(() => {
    if (!isEmpty(props.defaultSenderId)) {
      setSenderIds([props.defaultSenderId, ...props.senderIds])
    }
  }, [props.senderIds, props.defaultSenderId])

  const { personalizationList = {}, defaultSenderId } = props

  const parts = SMSHelper.parts(message.text)

  const changeHandler = ({ message: updatedMessage }) => {
    setMessage(updatedMessage)
  }

  return (
    <CustomDialog open={true} onClose={onClose}>
      <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>{displayMap.sendSMSToPhoneNumbers.icon}</Icon>
            <Typography variant="h6">{Q_ACTIONS.sendSMSToPhoneNumbers.label}</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '24px' }}>
        <div
          style={{
            width: '80vw',
            minWidth: '300px',
            maxWidth: '100%',
          }}
        >
          <form
            id="send-message-form"
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <CardContent>
              <div
                style={{
                  marginBottom: '15px',
                }}
              >
                <PhoneNumberField
                  personalizationList={personalizationList}
                  value={phoneNumbers}
                  onChange={setPhoneNumbers}
                />
              </div>
              <Select
                id="send-message-sender"
                label="From"
                value={senderId}
                values={senderIds}
                editable={true}
                onChange={({ value }) => {
                  setSenderId(value)
                }}
                info={
                  senderId === DEFAULT_SENDER_ID_VALUE && props.senderIds.length === 1 ? DEFAULT_SENDER_ID_MESSAGE : ''
                }
              />
              <SMSLanguageWrapper
                item={{ message }}
                languages={props.languages}
                defaultLanguage={props.defaultLanguage}
                info="Add your message below"
                onChange={changeHandler}
                type="message"
                content="text"
              >
                {(activeText, onTextChange, activeError) => (
                  <Message
                    autoFocus
                    message={activeText}
                    error={activeError}
                    personalizationList={personalizationList}
                    onChange={onTextChange}
                  />
                )}
              </SMSLanguageWrapper>
            </CardContent>
          </form>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={
            !hasContent(message, 'text') || phoneNumbers.length === 0 || senderId === '0' || parts > smsMaxLength
          }
          onClick={() =>
            onSave({
              sendSMSToPhoneNumbers: {
                senderId:
                  senderId === DEFAULT_SENDER_ID_VALUE || senderId === pathOr('', ['value'], defaultSenderId)
                    ? ''
                    : senderId,
                useCampaignSenderId:
                  senderId === pathOr('', ['value'], defaultSenderId) ? defaultSenderId.value : 'action',
                text: message.text,
                translations: message.translations,
                phoneNumbers: trimmedPhoneNumbers(phoneNumbers),
              },
            })
          }
        >
          Save
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}

export default withStyles(SMSToPhoneNumbers, styles)
