import { SvgIconComponent } from '@mui/icons-material'
import { styled } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'

import CustomTooltip from '@/app/component/atom/tooltip'

type SummaryBoxProps = {
  icon: (props: React.ComponentProps<SvgIconComponent>) => JSX.Element
  label: string
  value: string
  color: React.ComponentProps<SvgIconComponent>['color']
  tooltip?: string
}

export default function SummaryBox({ icon: Icon, label, value, color = 'secondary', tooltip = '' }: SummaryBoxProps) {
  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Container>
              <Icon
                fontSize="large"
                color={color}
                style={{
                  fontSize: '2.5rem',
                  color: 'rgba(0, 0, 0, 0.54)',
                }}
              />
              <CustomTooltip title={tooltip}>
                <Typography
                  variant="h5"
                  color="textSecondary"
                  sx={{
                    textAlign: 'center',
                    padding: (theme) => theme.spacing(2.5),
                  }}
                >
                  {label}
                </Typography>
              </CustomTooltip>
            </Container>
          </Grid>
          <Grid item xs={6}>
            <ValueContainer>
              <Typography
                variant="h5"
                color="primary"
                style={{
                  textAlign: 'right',
                }}
              >
                {value}
              </Typography>
            </ValueContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

const Container = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
}))

const ValueContainer = styled(Container)(() => ({
  justifyContent: 'flex-end',
}))
