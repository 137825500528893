import { z } from 'zod'

import { createTranslations } from '@/app/module/campaigns/types/schemas/common'
import ZTextContent from '@/app/module/campaigns/types/schemas/message-content/text'
import {
  ZWAAudioContent,
  ZWADocumentContent,
  ZWAImageContent,
  ZWAVideoContent,
} from '@/app/module/campaigns/types/schemas/message-content/whatsapp'

import ZBaseAction from './base'

const createSendWAContent = (content: z.ZodObject<any>) =>
  content.extend({
    registerTemplate: z.boolean(),
    senderId: z.string(),
    useCampaignSenderId: z.string(),
    translations: createTranslations(content).optional(),
  })

const ZSendWAAudio = createSendWAContent(ZWAAudioContent)
const ZSendWADocument = createSendWAContent(ZWADocumentContent)
const ZSendWAImage = createSendWAContent(ZWAImageContent)
const ZSendWAText = createSendWAContent(ZTextContent)
const ZSendWAVideo = createSendWAContent(ZWAVideoContent)

const ZSendWhatsappAction = ZBaseAction.extend({
  sendWhatsapp: z.union([ZSendWAAudio, ZSendWADocument, ZSendWAImage, ZSendWAText, ZSendWAVideo]),
})

export default ZSendWhatsappAction
