import { Typography } from '@mui/material'
import React from 'react'

import EditableText from '@/app/component/atom/form/editableText'

type Props = {
  editable: boolean
  index: number
  label: string
  onChange: (newLabel: string) => void
}

const RuleLabel: React.FC<Props> = ({ editable, index, label, onChange }) => {
  const isNew = index === -1
  const ruleLabel = isNew ? label || 'New Rule' : `${index + 1}. ${label || 'Rule'}`

  return (
    <Typography component="div">
      <EditableText
        editable={editable}
        data-testid="rule-label"
        displayText={ruleLabel}
        maxLength={200}
        value={label}
        onChange={onChange}
        onEditClick={(e) => e.stopPropagation()} // Prevents the accordion from toggling when clicking on the text field
        onInputClick={(e) => e.stopPropagation()}
      />
    </Typography>
  )
}

export default RuleLabel
