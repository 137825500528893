import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { MIC_ACCESS_ERROR } from '@/app/definitions'
import { selectToken } from '@/app/module/auth/store/selectors'
import { selectWhatsappSenderIds } from '@/app/module/campaigns/store/selectors'
import { getCustomFields } from '@/app/module/custom-fields/store/actions'
import { getFilesItem } from '@/app/module/files/store/actions'
import {
  clearFilters,
  getSenderIdList,
  reloadMessages,
  saveWhatsappFile,
  sendMessage,
  sendWhatsappMessage,
  setSendMessage,
  setSendWhatsappMessage,
} from '@/app/module/logs/store/actions'
import { selectPersonalizationList, selectSenderIdValues } from '@/app/module/logs/store/selectors'
import { createNotification } from '@/app/module/notifications/store/actions'
import { selectOrgId, selectUserId } from '@/app/module/user/store/selectors'

import Conversations from './components'
import {
  getContactData,
  getContactInfo,
  getContacts,
  readAllContactMessages,
  subscribeLogs,
  unsubscribeLogs,
} from './store/actions'

const selector = ({ auth, user, customFields, logs, conversations }) => ({
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  userId: selectUserId({ user }),
  personalizationList: selectPersonalizationList({ customFields }),
  senderId: pathOr('', ['data', 'sendMessage', 'senderId'], logs),
  smsSenderIds: selectSenderIdValues({ logs }),
  whatsappSenderIds: selectWhatsappSenderIds({ logs }),
  smsMessages: pathOr('', ['data', 'messages'], conversations),
  whatsappMessages: pathOr('', ['data', 'whatsapp'], conversations),
  calls: pathOr('', ['data', 'calls'], conversations),
  contactInfo: pathOr({}, ['data', 'contactInfo'], conversations),
  loading:
    pathOr([], ['data', 'loadingList'], conversations).length > 0 || pathOr([], ['data', 'loading'], conversations),
  conversations: pathOr([], ['data', 'conversations'], conversations),
  initialized: pathOr([], ['data', 'initialized'], conversations),
  nextPage: pathOr([], ['data', 'nextPage'], conversations),
})

const dispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId }) =>
        () =>
          Promise.all([dispatch(getCustomFields({ token, orgId })), dispatch(getSenderIdList({ token, orgId }))]),
      subscribeLogs,
      unsubscribeLogs,
      getContacts,
      reloadMessages,
      setSendMessage,
      getSenderIdList,
      sendMessage,
      getFilesItem,
      getCustomFields,
      sendWhatsappMessage,
      setSendWhatsappMessage,
      clearFilters,
      saveWhatsappFile,
      getContactInfo,
      readAllContactMessages,
      getContactData,
      onMicAccessError: () =>
        dispatch(
          createNotification({
            'mic-access': {
              type: 'error',
              message: MIC_ACCESS_ERROR,
            },
          }),
        ),
    },
    dispatch,
  )

export default connect(selector, dispatcher)(withRouter(Conversations))
