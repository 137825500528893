import WhatsappIcon from '@mui/icons-material/WhatsApp'
import { styled } from '@mui/material'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { find, findIndex, has, path, pathOr, propEq, reduce, replace } from 'ramda'

import { cropText, displayMap as defaultDisplayMap, MESSAGE_TYPES } from '@/app/module/campaigns/definitions'
import { getPartContentPreview } from '@/app/module/campaigns/utils/part-content'
import { apiPz } from '@/app/module/logs/store/selectors'
import { addCurlyBrackets } from '@/app/module/utils/personalization'

const getPersonalizationLabel = (label, { contact, customfields }) =>
  pathOr('', ['label'], find(propEq('value', label), contact)) ||
  pathOr('', ['label'], find(propEq('value', label), customfields))

const whatsappPreview = ({ message: { text, image, audio, video, document }, files }) => {
  let preview = ''
  if (image) {
    preview += `[Image: ${image}] `
  }
  if (audio) {
    preview += `[Voice Note - ${path([audio, 'title'], files) || audio.trim()}]`
  }
  if (video) {
    preview += `[Video: ${video}]`
  }
  if (document) {
    preview += `[Document: ${document}]`
  }
  if (text) {
    preview += text.trim()
  }
  return preview
}

const selectContent = (partIndex, parts) =>
  reduce(
    (accum, type) => {
      const message = pathOr({}, [partIndex], parts)
      if (has(type.key, message)) {
        if (message.label) {
          return `${partIndex + 1}. ${message.label}`
        }
        const text = getPartContentPreview(message)
        return `${partIndex + 1}. ${type.label}: ${text}`
      }
      return accum
    },
    {},
    MESSAGE_TYPES,
  )

const jumpPreview = ({ text: partId, parts }) => selectContent(findIndex(propEq('id', partId), parts), parts)

const updateContactValues = {
  response: 'the exact response',
  message: 'the message received',
  answer: 'the answer',
  transcript: 'the transcript',
  ...reduce((acc, { label, value }) => ({ ...acc, [addCurlyBrackets(value)]: label }), {}, apiPz),
}

const updateContactPreview = ({ text, personalizationList }) => {
  const { field, value, constant } = text
  return `Set "${getPersonalizationLabel(field, personalizationList)}" to ${
    value === 'constant' ? `"${constant}"` : `${updateContactValues[value]}`
  }`
}

const subscribeToCampaignPreview = ({ campaignId, campaignName }) => campaignName || `Campaign ${campaignId}`

const topupPreview = ({ maxAmount, maxAmountTemplated, desiredAmountTemplated }, personalizationList) => {
  if (maxAmountTemplated) {
    return getPersonalizationLabel(replace(/{|}/g, '', maxAmountTemplated), personalizationList)
  }
  if (Number(desiredAmountTemplated) > 0) {
    return `${Number(desiredAmountTemplated).toFixed(2)}, or up to ${Number(maxAmount).toFixed(2)}`
  }
  return `${Number(maxAmount).toFixed(2)} (max)`
}

const previewMap = {
  sms: ({ text }) => (text ? text.trim() : 'No text found for default language.'),
  sendSMSToPhoneNumbers: ({ text }) => (text ? text.trim() : 'No text found for default language.'),
  api: ({ text }) => text.trim(),
  voice: ({ text, files }) => path([text, 'title'], files) || text?.trim() || 'No audio file for default language',
  jump: jumpPreview,
  topup: ({ text, personalizationList }) => topupPreview(text, personalizationList),
  updateContact: ({ text, personalizationList }) => updateContactPreview({ text, personalizationList }),
  sendEmail: ({ text }) => `Send Email to ${text.trim()}`,
  transfer: ({ text }) => `Transfer Call to ${text}`,
  subscribeToCampaign: ({ text }) => subscribeToCampaignPreview(text),
  sendWhatsapp: ({ text: message, files }) => whatsappPreview({ message, files }),
  pauseSubscription: () => 'Pause Campaign for Participant',
  resumeSubscription: ({ text }) => `Campaign ${text}`,
}

const CampaignContentQuestionPreview = ({
  action = {},
  displayMap = defaultDisplayMap,
  parts = [],
  personalizationList = {},
  onEdit = () => {},
  files = {},
}) => {
  const displaySpecs = path([action.type], displayMap)
  if (!displaySpecs) {
    return <></>
  }
  let text = ''
  if (action.type === 'sendEmail') {
    text = ''
    if (action[action.type]?.toMultiple?.length) {
      if (action[action.type].toMultiple.length > 1) {
        text = `${action[action.type].toMultiple.length} recipients`
      } else {
        text = `${action[action.type].toMultiple[0]}`
      }
    } else {
      text = pathOr('', displaySpecs.path, action)
    }
  } else if (action.type === 'api') {
    text = pathOr('', displaySpecs.url.path, action)
  } else if (action.type === 'voice') {
    text = pathOr('', ['play', 'say'], action) || pathOr('', ['play', 'playfile'], action)
  } else if (displaySpecs.isNumber) {
    text = pathOr('', displaySpecs.path, action).toFixed(2)
  } else text = pathOr('', displaySpecs.path, action)

  const isTTS = action.type === 'voice' && !!pathOr('', ['play', 'say'], action)

  return (
    <Container onClick={() => onEdit()}>
      {displaySpecs.icon === 'whatsapp' ? (
        <WhatsappIcon />
      ) : (
        <Icon
          size="small"
          style={{
            color: 'rgba(0, 0, 0, 0.54)',
            width: '30px',
            textAlign: 'center',
            marginRight: '2px',
          }}
        >
          {!isTTS ? displaySpecs.icon : 'text_fields'}
        </Icon>
      )}
      <Typography className="campaign-preview-text" variant="subtitle1" color="textSecondary">
        {cropText({
          text: previewMap[action.type]({ text, parts, personalizationList, files }),
          limit: 50,
          firstCutOff: 25,
          secondCutOff: 20,
        })}
      </Typography>
    </Container>
  )
}

const Container = styled('div')(({ theme }) => ({
  borderBottom: '1px dotted rgba(0, 0, 0, 0.54)',
  cursor: 'pointer',
  display: 'flex',
  width: '100%',
  padding: theme.spacing(0.5, 1),
}))

export default CampaignContentQuestionPreview
