import WhatsappIcon from '@mui/icons-material/WhatsApp'
import Avatar from '@mui/material/Avatar'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'

import PlanBlock from './plan-block'

export const iconMatch = {
  whatsappsurvey: <WhatsappIcon />,
  drip: <Icon>line_weight</Icon>,
  ivr: <Icon>volume_up</Icon>,
  smssurvey: <Icon>sms</Icon>,
}

const useStyles = makeStyles()({
  shortList: {
    maxHeight: '250px',
    overflowY: 'scroll',
  },
})

export default function ActiveCampaigns({ campaigns, history, activeCampaign, setActiveCampaign }) {
  const { values = {}, ordered = [], isLoading } = campaigns
  const { classes } = useStyles()
  return (
    <PlanBlock
      isLoading={isLoading}
      title="Active Campaigns"
      action={<Link href="/campaigns">Go to Campaigns page</Link>}
    >
      <List dense={true} className={classes.shortList}>
        {ordered.map((campaign) => (
          <ListItemButton
            selected={activeCampaign.id === campaign}
            key={campaign}
            onClick={() => {
              if (!values[campaign].launched) {
                history.push(`/campaigns/edit/${campaign}`)
              }
              setActiveCampaign({
                id: campaign,
                type: values[campaign].template,
                launched: values[campaign].launched,
                name: values[campaign].name,
              })
            }}
          >
            <ListItemAvatar>
              <Avatar
                variant="rounded"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.54)',
                }}
              >
                {iconMatch[values[campaign].template]}
              </Avatar>
            </ListItemAvatar>
            <div className="main-cont">
              <div className="top-cont">
                <Typography>{values[campaign].name}</Typography>
              </div>
              <div
                className="bottom-cont"
                style={{
                  display: 'flex',
                  flex: 1,
                  width: '100%',
                }}
              >
                <div
                  style={{
                    paddingRight: '5px',
                  }}
                >
                  <Typography color="textSecondary" variant="body2">
                    {campaign.status}
                  </Typography>
                </div>
                <div>
                  <Tooltip
                    title={
                      <Typography>
                        Total Runs: {values[campaign].runs}
                        <br />
                        Total Successful Runs: {values[campaign].runsDone - values[campaign].runsError}
                        <br />
                        Total Failed Runs: {values[campaign].runsError}
                        <br />
                      </Typography>
                    }
                  >
                    <Typography color="textSecondary" variant="body2">
                      ({values[campaign].runsDone - values[campaign].runsError}/ {values[campaign].runs})
                    </Typography>
                  </Tooltip>
                </div>
              </div>
            </div>
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="chart"
                onClick={() => {
                  if (!values[campaign].launched) {
                    history.push(`/campaigns/edit/${campaign}`)
                  }
                  setActiveCampaign({
                    id: campaign,
                    type: values[campaign].template,
                    launched: values[campaign].launched,
                    name: values[campaign].name,
                  })
                }}
              >
                <Icon
                  style={{
                    transform: activeCampaign.id === campaign ? 'rotate(0)' : 'rotate(180deg)',
                    transitionDuration: '0.4s',
                    transitionProperty: 'transform',
                  }}
                  color={activeCampaign.id === campaign ? 'primary' : 'inherit'}
                >
                  pie_chart
                </Icon>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItemButton>
        ))}
      </List>
    </PlanBlock>
  )
}
