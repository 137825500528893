import React from 'react'
import { FieldValues, Path, useFormContext } from 'react-hook-form'

import { cannot } from '@/app/helpers'

import { RHFAutocomplete } from './components/rhf-autocomplete'
import { RHFCheckbox } from './components/rhf-checkbox'
import RHFConditions from './components/rhf-conditions'
import { RHFDatePicker } from './components/rhf-date-picker'
import { RHFDateTimePicker } from './components/rhf-date-time-picker'
import { RHFPhoneField } from './components/rhf-phone-field'
import { RHFRadioGroup } from './components/rhf-radio-group'
import { RHFSelect } from './components/rhf-select'
import { RHFSlider } from './components/rhf-slider'
import { RHFSwitch } from './components/rhf-switch'
import { RHFTextField } from './components/rhf-text-field'
import { RHFToggleButtonGroup } from './components/rhf-toggle-button-group'

export type FormFieldProps<T extends FieldValues> =
  | {
      type: 'hidden'
      fieldProps: {
        name: Path<T>
      }
    }
  | {
      type: 'text' | 'number' | 'audio' | 'password' | 'email' | 'url'
      fieldProps: React.ComponentProps<typeof RHFTextField<T>>
    }
  | {
      type: 'tel'
      fieldProps: React.ComponentProps<typeof RHFPhoneField<T>>
    }
  | {
      type: 'autocomplete'
      fieldProps: React.ComponentProps<typeof RHFAutocomplete<T>>
    }
  | {
      type: 'date'
      fieldProps: React.ComponentProps<typeof RHFDatePicker<T>>
    }
  | {
      type: 'datetime'
      fieldProps: React.ComponentProps<typeof RHFDateTimePicker<T>>
    }
  | {
      type: 'checkbox'
      fieldProps: React.ComponentProps<typeof RHFCheckbox<T>>
    }
  | {
      type: 'radio'
      fieldProps: React.ComponentProps<typeof RHFRadioGroup<T>>
    }
  | {
      type: 'select'
      fieldProps: React.ComponentProps<typeof RHFSelect<T>>
    }
  | {
      type: 'switch'
      fieldProps: React.ComponentProps<typeof RHFSwitch<T>>
    }
  | {
      type: 'slider'
      fieldProps: React.ComponentProps<typeof RHFSlider<T>>
    }
  | {
      type: 'toggle'
      fieldProps: React.ComponentProps<typeof RHFToggleButtonGroup<T>>
    }
  | {
      type: 'conditions'
      fieldProps: React.ComponentProps<typeof RHFConditions<T>>
    }

export function FormField<T extends FieldValues>(props: FormFieldProps<T>) {
  const { register } = useFormContext()

  switch (props.type) {
    case 'hidden':
      return <input type="hidden" {...register(props.fieldProps.name)} />

    case 'text':
    case 'password':
    case 'email':
    case 'number':
    case 'audio':
    case 'url':
      return <RHFTextField<T> type={props.type} {...props.fieldProps} />
    case 'tel':
      return <RHFPhoneField<T> {...props.fieldProps} />
    case 'autocomplete':
      return <RHFAutocomplete<T> {...props.fieldProps} />
    case 'date':
      return <RHFDatePicker<T> {...props.fieldProps} />
    case 'datetime':
      return <RHFDateTimePicker<T> {...props.fieldProps} />
    case 'checkbox':
      return <RHFCheckbox<T> {...props.fieldProps} />
    case 'radio':
      return <RHFRadioGroup<T> {...props.fieldProps} />
    case 'select':
      return <RHFSelect<T> {...props.fieldProps} />
    case 'slider':
      return <RHFSlider<T> {...props.fieldProps} />
    case 'switch':
      return <RHFSwitch<T> {...props.fieldProps} />
    case 'toggle':
      return <RHFToggleButtonGroup<T> {...props.fieldProps} />
    case 'conditions':
      return <RHFConditions<T> {...props.fieldProps} />

    default:
      cannot(props)
  }
}
