import { ContactGroup } from '@/app/module/contacts/types'
import { ConditionFunc } from '@/app/types'

export type Groups = {
  data?: Record<number, Pick<ContactGroup, 'name' | 'createdAt' | 'numberOfContacts'>>
  ordered?: number[]
}

export type Operands = {
  title: string
  data: Array<{
    label: string
    value: ConditionFunc
    component: string
  }>
}

export type OperandsList = Operands[]

export const isOperandsList = (operands: any): operands is OperandsList => {
  if (!operands) return false
  if (!Array.isArray(operands)) return false
  if (operands[0]?.title === undefined) return false
  if (!operands[0].data) return false
  if (!Array.isArray(operands[0].data)) return false
  if (!operands[0].data[0].label) return false
  if (!operands[0].data[0].value) return false
  if (!operands[0].data[0].component) return false
  return true
}

export const operands: OperandsList = [
  {
    title: 'Regular Operators',
    data: [
      {
        label: 'equals',
        value: '==',
        component: 'TextField',
      },
      {
        label: 'does not equal',
        value: '!=',
        component: 'TextField',
      },
      {
        label: 'is greater than',
        value: '>',
        component: 'TextField',
      },
      {
        label: 'is greater than or equal to',
        value: '>=',
        component: 'TextField',
      },
      {
        label: 'is less than',
        value: '<',
        component: 'TextField',
      },
      {
        label: 'is less than or equal to',
        value: '<=',
        component: 'TextField',
      },
      {
        label: 'contains',
        value: 'contain',
        component: 'TextField',
      },
      {
        label: 'does not contain',
        value: 'not_contain',
        component: 'TextField',
      },
      {
        label: 'is empty',
        value: 'empty',
        component: 'Fragment',
      },
      {
        label: 'is not empty',
        value: 'not_empty',
        component: 'Fragment',
      },
      {
        label: 'starts with',
        value: 'startsWith',
        component: 'TextField',
      },
      {
        label: 'does not start with',
        value: 'notStartsWith',
        component: 'TextField',
      },
      {
        label: 'is before',
        value: 'before',
        component: 'DateTime',
      },
      {
        label: 'is after',
        value: 'after',
        component: 'DateTime',
      },
      {
        label: 'equals any of',
        value: 'in',
        component: 'List',
      },
      {
        label: 'does not equal any of',
        value: 'notIn',
        component: 'List',
      },
      {
        label: 'starts with any of',
        value: 'anyStartsWith',
        component: 'List',
      },
      {
        label: 'does not start with any of',
        value: 'noneStartsWith',
        component: 'List',
      },
    ],
  },
  {
    title: 'Randomize Operators',
    data: [
      {
        label: 'mod 2 equals',
        value: 'mod2_equal',
        component: 'TextField',
      },
      {
        label: 'mod 3 equals',
        value: 'mod3_equal',
        component: 'TextField',
      },
      {
        label: 'mod 4 equals',
        value: 'mod4_equal',
        component: 'TextField',
      },
      {
        label: 'mod 5 equals',
        value: 'mod5_equal',
        component: 'TextField',
      },
    ],
  },
]
