import React, { PureComponent } from 'react'
import { DropzoneRef } from 'react-dropzone'

import Uploader from '@/app/component/atom/upload'

import AudioWrapper from './wrapper'

type Props = {
  id: string
  loading: boolean
  uploadContent: React.ReactNode
  uploadRef: React.RefObject<DropzoneRef>

  onUpload: (file: File) => void
}

export default class AudioUploader extends PureComponent<Props> {
  render() {
    const { id, loading, uploadContent, uploadRef, onUpload } = this.props

    return (
      <AudioWrapper border={false} loading={loading}>
        <div style={{ height: '100%' }}>
          <Uploader
            accept={acceptList}
            extensions={extensionList}
            namespace={`campaign-${id}`}
            uploadContent={uploadContent}
            uploadRef={uploadRef}
            onAccept={({ file }: { file: File }) => {
              onUpload(file)
            }}
          />
        </div>
      </AudioWrapper>
    )
  }
}

export const acceptList: Record<string, string[]> = {
  'audio/mpeg': ['.mp3'],
  'audio/wav': ['.wav'],
  'audio/ogg': ['.ogg'],
  'audio/x-m4a': ['.m4a'],
  'audio/aac': ['.aac'],
  'audio/opus': ['.opus'],
  'audio/x-ms-wma': ['.wma'],
  'audio/mp4': ['.mp4'],
  'audio/webm': ['.webm'],
}

export const extensionList = ['wav', 'mp3', 'ogg', 'm4a', 'aac', 'opus', 'wma', 'mp4']
