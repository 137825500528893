import { styled } from '@mui/material/styles'
import React from 'react'

const Container = styled('div')({
  position: 'sticky',
  width: '100%',
  maxWidth: '100%',
  left: '0',
})

const Content = styled('div')({
  width: '100%',
  margin: 'auto',
  padding: '0 1rem',
})

type ContentLayoutProps = {
  children: React.ReactNode
}

export default function ContentLayout({ children }: ContentLayoutProps) {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  )
}
