import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { reduce, sort } from 'ramda'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import TablePagination from '@/app/component/atom/table-pagination'
import { FixMeLater, FunctionType, TableColumn } from '@/config/types'

import ColumnActions from './column-actions'

const CELL_WIDTH = 200

const PAGE_SIZE_VALUES = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '30',
    value: 30,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
]

type ContactDataType = any

type Props = {
  data: ContactDataType[]
  columns: TableColumn[]
  height?: string | number
  width?: string | number
  editAction?: FixMeLater
  noData: JSX.Element
  total: number
  selectAll?: JSX.Element
  style?: FixMeLater
  page?: number
  onPageChange?: FunctionType
  selection?: FixMeLater
  tableActions: FixMeLater
  pageSize?: number
  onPageSizeChange?: FunctionType
  rowAction: FunctionType
}

const calculateLeftOfColumn = (sticky: string | undefined, index: number) => {
  if (index === 0) {
    return {
      left: 0,
    }
  }
  if (sticky === 'left') {
    return {
      left: `${40 + (index - 1) * CELL_WIDTH}px`,
    }
  }
  if (sticky === 'right') {
    return {
      right: 0,
    }
  }
  return {
    left: 'auto',
  }
}

const prepColumnsForRendering = (columns: TableColumn[]): TableColumn[] =>
  columns.map((col: TableColumn, index: number) => ({
    ...col,
    style: calculateLeftOfColumn(col.sticky, index),
  }))

const sortTable = (tableColumns: TableColumn[]): TableColumn[] =>
  sort((a: TableColumn, b: TableColumn) => {
    if (!a.sticky && !b.sticky) {
      return 0
    }
    if (a.sticky === b.sticky) {
      return 0
    }
    if (a.sticky === 'left') {
      return -1
    }
    if (b.sticky === 'left') {
      return 1
    }
    if (a.sticky === 'right') {
      return 1
    }
    if (b.sticky === 'right') {
      return -1
    }
    return 0
  }, tableColumns)

export default function PowerTable(props: Props) {
  const {
    data,
    columns,
    editAction,
    noData,
    total,
    height,
    selectAll,
    width,
    style = {},
    page = 1,
    onPageChange,
    selection,
    tableActions,
    pageSize = 10,
    onPageSizeChange,
    rowAction,
  } = props
  const [memoedColumns, setMemoedColumns] = React.useState<TableColumn[]>([])

  const { classes } = useStyles({ columnCount: columns.length, rowCount: data.length })

  React.useEffect(() => {
    setMemoedColumns(prepColumnsForRendering(sortTable(columns)))
  }, [columns])
  const stickyCount = React.useMemo(
    () => reduce((accum, col) => (col.sticky === 'left' ? accum + 1 : accum), 0, memoedColumns),
    [memoedColumns],
  )
  return (
    <>
      {data.length > 0 && memoedColumns.length > 0 ? (
        <div style={{ height, width, ...(style && { ...style }) }}>
          <div className={classes.tableGrid}>
            {memoedColumns.map((column, index) => (
              <div
                key={`${column.accessor}-${index}`}
                title={column.header}
                className={`${column.sticky ? classes.stickyHeaderColumn : ''} ${classes.stickyHeader} ${
                  classes.tableCell
                } ${index === stickyCount - 1 ? classes.lastStickyCol : ''} ${
                  column.sticky === 'right' ? classes.rightStickyCol : ''
                } ${index > 0 ? classes.dataCell : ''}`}
                style={calculateLeftOfColumn(column.sticky, index)}
              >
                <Typography className={classes.headerText} variant="body1" color="textSecondary">
                  {column.header}
                </Typography>
                {index > 0 && index < columns.length && (
                  <ColumnActions classes={classes} column={column} editAction={editAction} />
                )}
                {index === 0 && selectAll}
                {index === columns.length - 1 && tableActions}
              </div>
            ))}
            {data.map((row, rowIndex) => {
              const id = row.id || row.subId

              return (
                <div className={classes.rowWrapper} key={id}>
                  {memoedColumns.map((column, index) => (
                    <div
                      key={`${id}-${index}`}
                      title={column.cell ? '' : row[column.accessor]}
                      className={`${column.sticky ? classes.stickyColumn : ''} ${classes.tableCell} ${
                        rowIndex % 2 === 0 ? classes.evenRow : ''
                      }
                      ${index === stickyCount - 1 ? classes.lastStickyCol : ''} ${
                        column.sticky === 'right' ? classes.rightStickyCol : ''
                      } ${index > 0 ? classes.dataCell : ''}`}
                      style={column.style}
                      onClick={() => index > 0 && index < memoedColumns.length && rowAction(id)}
                    >
                      {index === 0 && selection ? (
                        <Checkbox
                          name="select-item-table"
                          checked={selection.isSelected(row[column.accessor])}
                          onChange={() => {
                            if (!selection.isSelected(row[column.accessor])) {
                              selection.add([row[column.accessor]])
                              return
                            }
                            selection.remove([row[column.accessor]])
                          }}
                          size="small"
                        />
                      ) : (
                        <>
                          {column.sticky === 'right' ? (
                            <div>{column.cell(row[column.accessor])}</div>
                          ) : (
                            <Typography component="div" className={classes.cellText} variant="body1">
                              {column.cell ? column.cell(row[column.accessor]) : row[column.accessor]}
                            </Typography>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )
            })}
          </div>
          <div className={classes.footer}>
            {onPageChange && (
              <TablePagination
                namespace="Contacts"
                page={page}
                size={pageSize}
                total={total}
                pageSizeValues={PAGE_SIZE_VALUES}
                onPageSizeChange={(value) => {
                  onPageSizeChange(value)
                  onPageChange(1)
                }}
                onPageChange={(newPage) => {
                  onPageChange(newPage)
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <>{noData}</>
      )}
    </>
  )
}

const useStyles = makeStyles<{ columnCount: number; rowCount: number }, 'powerTableColumnActions'>()(
  (_theme, props, classes) => ({
    tableGrid: {
      display: 'grid',
      gridTemplateColumns: `40px repeat(${props.columnCount - 1}, minmax(${CELL_WIDTH}px, auto))`,
      gridTemplateRows: `repeat(${props.rowCount + 1}, minmax(auto, 40px))`,
      overflow: 'scroll',
      height: '100%',
      width: '100%',
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        border: '2px solid white',
        backgroundColor: 'rgba(0, 0, 0, .5)',
      },
      '&::-webkit-scrollbar:horizontal': {
        height: '11px',
      },
    },
    tableCell: {
      borderBottom: '1px solid rgb(224, 224, 224)',
      background: '#fff',
      justifyContent: 'flex-start',
      alignItems: 'center',
      display: 'flex',
    },
    cellText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: 'rgba(0, 0, 0, 0.66)',
      maxWidth: '100%',
    },
    headerText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    dataCell: {
      padding: '30px 15px',
    },
    stickyColumn: {
      position: 'sticky',
      zIndex: 10,
      left: 0,
    },
    stickyHeader: {
      position: 'sticky',
      top: 0,
      zIndex: 10,
      lineHeight: 1.4,
      boxShadow: '0px 1px 1px rgba(224, 224, 224, 1)',
      '&:hover': {
        [`& .${classes.powerTableColumnActions}`]: {
          opacity: 1,
        },
      },
    },
    stickyHeaderColumn: {
      zIndex: 20,
    },
    lastStickyCol: {
      borderRight: '1px solid rgb(224, 224, 224)',
    },
    rightStickyCol: {
      borderLeft: '1px solid rgb(224, 224, 224)',
      display: 'flex',
      justifyContent: 'center',
    },
    powerTableColumnActions: {
      display: 'inline-block',
      paddingLeft: '2px',
      paddingTop: '2px',
      transition: 'opacity 0.3s',
      minWidth: '40px',
    },
    powerTableColumnActionsIcons: {
      fontSize: '0.8rem',
      paddingLeft: '2px',
      cursor: 'pointer',
    },
    footer: {
      position: 'sticky',
      bottom: 0,
      right: 0,
      zIndex: 30,
      background: '#fff',
      boxShadow: '0px -1px 1px rgba(224, 224, 224, 1)',
      display: 'flex',
      justifyContent: 'center',
      alignSelf: 'center',
      padding: '10px',
      paddingRight: '60px',
    },
    evenRow: {
      backgroundColor: '#fafafa',
    },
    rowWrapper: {
      display: 'contents',
      cursor: 'pointer',
      '&:hover': {
        '& div': {
          backgroundColor: '#f0f0f0',
        },
      },
    },
  }),
)
