import React from 'react'

import ActionsBox from '@/app/module/campaigns/component/actions/actions-box'
import { DRAGTYPE, SMS_ACTION_DEFAULT_SENDERID } from '@/app/module/campaigns/definitions'
import { LocalAction, LocalWhatsAppButtonItem } from '@/app/module/campaigns/types'
import { ActionOption } from '@/app/module/campaigns/utils/actions/options'

import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import ChoiceContainer from './question/choice/choice-container'
import WhatsappButtonChoice from './question/choice/whatsapp-button-choice'

type Props = ActionProps &
  ApiProps &
  SnippetProps & {
    actionOptions: ActionOption[]
    buttonIndex: number
    isCloseDisabled?: boolean
    value: LocalWhatsAppButtonItem

    onChange: (buttonInde: number, value: LocalWhatsAppButtonItem) => void
    onClose: (buttonIndex: number) => void
  }

const WhatsappButtonItem: React.FC<Props> = ({ buttonIndex, onChange, onClose, ...props }) => {
  const handleChange = React.useCallback(
    (value: Partial<LocalWhatsAppButtonItem>) => onChange(buttonIndex, { ...props.value, ...value }),
    [buttonIndex, props.value, onChange],
  )
  const handleClose = React.useCallback(() => onClose(buttonIndex), [buttonIndex, onClose])

  const handleActionChange = React.useCallback(
    (actionsList: LocalAction[]) =>
      handleChange({
        actions: actionsList,
      }),
    [handleChange],
  )
  const handleLabelChange = React.useCallback(
    (label: string) => {
      handleChange({
        label,
      })
    },
    [handleChange],
  )
  const handleButtonChange = React.useCallback((title: string) => handleChange({ title }), [handleChange])

  return (
    <ActionsBox
      {...props}
      actions={props.value.actions || []}
      defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.actionsOnly}
      dragContext={`${DRAGTYPE}-choice-${props.index}`}
      hasTranscribe={false}
      label={props.value.label || ''}
      transcribeEnabled={false}
      updateContactConfig={updateContactConfig}
      onChange={handleActionChange}
      onClose={handleClose}
      onLabelChange={handleLabelChange}
    >
      <ChoiceContainer label="Button">
        <WhatsappButtonChoice buttonIndex={buttonIndex} title={props.value.title} onChange={handleButtonChange} />
      </ChoiceContainer>
    </ActionsBox>
  )
}

const updateContactConfig = {
  invalid: false,
  openResponse: false,
  question: true,
}

export default WhatsappButtonItem

export type { Props as WhatsappButtonItemProps }
