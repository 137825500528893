import { isValidPhoneNumber } from 'libphonenumber-js'
import validateEmail from 'validator/lib/isEmail'
import validateEmpty from 'validator/lib/isEmpty'

export const ERRORS = {
  REQUIRED: 'Required field',
  EMAIL: 'Must be a valid email address',
  PHONE: 'Must be a valid phone number',
  LENGTH: (max: number) => `This field supports only up to ${max} number of characters`,
  URL: 'Must be a valid url',
}

const urlRegX = new RegExp(
  '^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[-;&a-z\\d%_.~+={}]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
  'i',
)

// allows {{contact.id}} in url
const pzUrlRegX = new RegExp(
  '^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+\\{\\}]*)*' + // port and path
    '(\\?[-;&a-z\\d%_.~+={}]*)?' + // query string
    '(\\#[-a-z\\d_{}]*)?$', // fragment locator
  'i',
)

export const hasRequiredError = (value: string = '') => (!validateEmpty(value) ? '' : ERRORS.REQUIRED)

export const hasEmailError = (value: string) => (validateEmail(value) ? '' : ERRORS.EMAIL)

export const hasPhoneError = (value: string) => (value === '' || isValidPhoneNumber(`+${value}`) ? '' : ERRORS.PHONE)

export const hasMaxError =
  (max: number, message: string = ERRORS.LENGTH(max)) =>
  (value: string) =>
    value.length <= max ? '' : message

export const MIN_PASSWORD_LENGTH = 15

export const hasPasswordError = (value: string) => {
  const { length } = [...value] // spread to split string into unicode code points
  if (length < MIN_PASSWORD_LENGTH) {
    return `Password must be at least ${MIN_PASSWORD_LENGTH} characters long.`
  }

  return ''
}

export const hasURLError = (value: string = '') => (urlRegX.test(value) ? '' : ERRORS.URL)

export const hasPzURLError = (value: string = '') => (pzUrlRegX.test(value) ? '' : ERRORS.URL)

type Fields = Record<
  string,
  {
    value: string
    error: string
  }
>

type ValidationFunction = (value: string, fields?: Fields) => string

export default (...args: ValidationFunction[]) =>
  (value: string, fields?: Fields): string =>
    args.reduce((acc: string, fn: ValidationFunction) => (acc.length ? acc : fn(value, fields)), '')
