import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'

import { Option } from '@/app/component/form/types'

type Props<T extends FieldValues> = {
  name: Path<T>
  options?: Option[]
  label?: string
  required?: boolean
} & Pick<React.ComponentProps<typeof Autocomplete>, 'disabled' | 'multiple' | 'autoFocus' | 'freeSolo' | 'renderOption'>

export function RHFAutocomplete<T extends FieldValues>({ name, options = [], label, ...props }: Props<T>) {
  const { control } = useFormContext<T>()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
        <Autocomplete
          {...props}
          options={options || []}
          fullWidth
          value={value || ''}
          isOptionEqualToValue={(option: Option, newValue: Option) => {
            return option.value === newValue.value
          }}
          // @ts-ignore: TS went bonkers here, don't know why it thinks newValue is not this type
          onChange={(_, newValue: Option | string | null) => onChange(getOptionValue(newValue))}
          // if freeSolo is enabled, should capture input value
          onInputChange={props.freeSolo ? (_, newInputValue) => onChange(newInputValue) : undefined}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              type="text"
              size="small"
              sx={{ mb: 2, pt: 0 }}
              disabled={props.disabled}
              fullWidth
              error={!!error}
              inputRef={ref}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                ...params.inputProps,
                'aria-label': name,
              }}
              helperText={error?.message}
              label={label}
            />
          )}
        />
      )}
    />
  )
}

const getOptionValue = (option: Option | string | null) => {
  if (!option) {
    return ''
  }
  if (typeof option === 'string') {
    return option
  }
  return option.value
}
