import { styled, TextFieldProps } from '@mui/material'
import React from 'react'

import { PersonalizationType } from '@/app/module/campaigns/types'

import { Input } from './form'
import PzMenu, { PzMenuRef } from './pz-menu'

type Props = {
  'data-testid'?: string
  label?: string
  name: string
  personalizationList: PersonalizationType
  type?: TextFieldProps['type']
  value: string
  onChange: (value: string) => void
}

const PzInput: React.FC<Props> = ({ name, personalizationList, value, onChange, ...props }) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const menuRef = React.useRef<PzMenuRef | null>(null)
  const [selectionStart, setSelectionStart] = React.useState(0)

  const updateSelectionStart = () => {
    if (!inputRef.current || typeof inputRef.current.selectionStart !== 'number') {
      return
    }

    setSelectionStart(inputRef.current.selectionStart)
  }

  const resetSelectionPosition = (updatedSelectionPosition: number) => {
    setTimeout(() => {
      if (!inputRef.current) {
        return
      }

      inputRef.current.setSelectionRange(updatedSelectionPosition, updatedSelectionPosition)
    }, 0)
  }

  return (
    <Container>
      <Input
        {...props}
        inputRef={inputRef}
        inputProps={{
          style: {
            paddingRight: '42px',
          },
        }}
        name={`${name}-input`}
        value={value}
        onBlur={updateSelectionStart}
        onChange={({ value: inputValue }) => onChange(inputValue)}
      />
      <MenuContainer>
        <PzMenu
          id={`${name}-pz-menu`}
          containerSx={{
            marginBottom: '-5px',
          }}
          personalizationList={personalizationList}
          ref={menuRef}
          variant="text"
          onSelect={(item) => {
            const toBeAdded = `{{${item.value}}}`
            const newMessage = `${value.slice(0, selectionStart)}${toBeAdded}${value.slice(selectionStart)}`

            onChange(newMessage)

            const newSelectionStart = (inputRef.current?.selectionStart || selectionStart) + toBeAdded.length
            resetSelectionPosition(newSelectionStart)
            setSelectionStart(newSelectionStart)
          }}
        />
      </MenuContainer>
    </Container>
  )
}

const Container = styled('div')(() => ({
  marginBottom: '-0.5rem', // to cancel out FormControl margin
  position: 'relative',
}))

const MenuContainer = styled('div')(() => ({
  position: 'absolute',
  right: '2px',
  top: '4px',
  zIndex: 999,
  backgroundColor: 'white',
  borderRadius: '50%',
}))

export default React.memo(PzInput)
