import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'

import 'brace/mode/json'
import 'brace/theme/tomorrow'

import { find, join, pathOr, propEq } from 'ramda'
import React, { Component } from 'react'
import AceEditor from 'react-ace'
import { withStyles } from 'tss-react/mui'

import { DynamicInputs, Input, Select } from '@/app/component/atom/form'
import PzMenu from '@/app/component/atom/pz-menu'
import Loading from '@/app/component/guard/loading'
import IconText from '@/app/component/layout/icon-text'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { displayMap, Q_ACTIONS } from '@/app/module/campaigns/definitions'
import validate, { hasRequiredError, hasURLError } from '@/app/service/validate'

const styles = () => ({
  chip: {
    height: '16px',
    fontSize: '12px',
    lineHeight: '12px',
  },
  deleteButton: {
    color: '#ff5252',
    marginLeft: '-10px',
  },
})
class CampaignContentQuestionDialogAPI extends Component {
  constructor(props) {
    super(props)
    this.URLInput = React.createRef()
  }

  state = {
    method: pathOr('GET', [displayMap.api.path, 'method'], this.props.value),
    url: pathOr('', [displayMap.api.path, 'url'], this.props.value),
    payload: pathOr('', [displayMap.api.path, 'payload'], this.props.value),
    error: {
      url: false,
      payload: false,
      headers: pathOr([], [displayMap.api.path, 'headers'], this.props.value).map(() => ({
        key: false,
        value: false,
      })),
    },
    focus: {
      url: false,
      payload: false,
      headers: false,
    },
    pzMenuAnchor: null,
    URLPzMenuAnchor: null,
    selection: [0, 0],
    selectionStart: [0, 0],
    selectionEnd: [0, 0],
    timeout: null,
    headers: pathOr([], [displayMap.api.path, 'headers'], this.props.value),
  }

  apiMethods = [
    {
      label: 'GET',
      value: 'GET',
      disabled: false,
    },
    {
      label: 'POST',
      value: 'POST',
    },
  ]

  togglePzMenu = ({ currentTarget = null } = {}) => {
    this.setState({
      pzMenuAnchor: currentTarget,
    })
  }

  toggleURLPzMenu = ({ currentTarget = null } = {}) => {
    this.setState({
      URLPzMenuAnchor: currentTarget,
    })
  }

  changeHandler = (payload) => {
    this.setState({
      pzMenuAnchor: null,
      payload,
    })
  }

  setCursorPosition = (selectionStart, selectionEnd) => {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout)
    }
    this.setState({
      timeout: setTimeout(() => {
        this.setState((state) => ({
          timeout: clearTimeout(state.timeout),
          selectionStart,
          selectionEnd,
        }))
      }, 100),
    })
  }

  checkHeader = () => {
    if (this.state.method === 'POST' && this.state.headers.length < 1) {
      const { headers, error } = this.state
      headers.push({ key: 'Content-Type', value: 'application/json' })
      error.headers.push({
        key: false,
        value: false,
      })
      this.setState({
        headers,
        error,
      })
    }
  }

  addRow = () => {
    const { headers, error } = this.state
    headers.push({
      key: '',
      value: '',
    })
    error.headers.push({
      key: true,
      value: true,
    })
    this.setState({
      headers,
      error,
    })
  }

  render() {
    const classes = withStyles.getClasses(this.props)
    const { onSave, onClose, onDelete = () => {}, loading = false, personalizationList = {} } = this.props

    const isHeadersValid =
      !!find(propEq('key', true), this.state.error.headers) || !!find(propEq('value', true), this.state.error.headers)
    this.checkHeader()
    return (
      <Loading isLoading={loading}>
        <CustomDialog open={true} onClose={onClose}>
          <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <IconText>
                <Icon>{displayMap.api.icon}</Icon>
                <Typography variant="h6">{Q_ACTIONS.api.label}</Typography>
              </IconText>
              <IconButton onClick={onClose}>
                <Icon>close</Icon>
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent style={{ paddingTop: '16px' }}>
            <div
              style={{
                width: '70vw',
                minWidth: '300px',
                maxWidth: '100%',
              }}
            >
              <Select
                label="Method"
                name="api-request-action-select-method"
                value={this.state.method}
                values={this.apiMethods}
                onChange={({ value }) => {
                  this.setState({
                    method: value,
                  })
                }}
              />
              <Input
                label="API URL"
                id="api-request-action-url"
                inputRef={(URLInput) => {
                  this.URLInput = URLInput
                }}
                placeholder="https://example.com/"
                value={this.state.url}
                error={this.state.error.url}
                onChange={({ value }) => {
                  this.setState({
                    url: value,
                    error: {
                      ...this.state.error,
                      url: false,
                    },
                  })
                }}
                onBlur={({ value }) => {
                  const error = validate(hasRequiredError, hasURLError)(value)
                  if (error) {
                    this.setState({
                      error: {
                        ...this.state.error,
                        url: error,
                      },
                    })
                  }
                }}
              />
              {this.props.webhook && (
                <Typography component="div" variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                  Note: <Chip className={classes.chip} color="primary" label="to" />,{' '}
                  <Chip label="from" className={classes.chip} color="primary" />, and{' '}
                  <Chip label="message" className={classes.chip} color="primary" /> parameters will be added to your
                  webhook URL, e.g., https://www.example.com/?to=12345&from=12125551234&message=hello
                </Typography>
              )}
              <React.Fragment>
                <div style={{ display: 'flex', marginTop: '5px' }}>
                  <PzMenu
                    id="api-request-action-url"
                    color="primary"
                    containerSx={{
                      marginRight: '0.5rem',
                    }}
                    personalizationList={personalizationList}
                    variant="outlined"
                    onSelect={(item) => {
                      const URLSelectionStart = this.URLInput.selectionStart
                      let { url } = this.state
                      url = [url.slice(0, URLSelectionStart), `{{${item.value}}}`, url.slice(URLSelectionStart)].join(
                        '',
                      )
                      this.setState({
                        URLPzMenuAnchor: null,
                        url,
                      })
                      setTimeout(() => {
                        this.URLInput.focus()
                        this.URLInput.selectionStart = URLSelectionStart + item.value.length + 4
                      }, 500)
                    }}
                  />
                </div>
              </React.Fragment>
            </div>
            {this.state.method === 'POST' && (
              <React.Fragment>
                <div
                  id="api-request-action-post-body-cont"
                  style={{
                    width: '70vw',
                    minWidth: '300px',
                    maxWidth: '100%',
                    marginTop: '10px',
                  }}
                >
                  <InputLabel
                    error={this.state.error.payload}
                    focused={this.state.focus.payload}
                    style={{
                      marginBottom: '6px',
                    }}
                  >
                    Body
                  </InputLabel>
                  <AceEditor
                    placeholder="Add JSON Body"
                    mode="json"
                    theme="tomorrow"
                    name="api-request-action-post-body-textarea"
                    onValidate={(ev) => {
                      this.setState({
                        error: {
                          ...this.state.error,
                          payload: ev.length > 0,
                        },
                      })
                    }}
                    onChange={(value) => {
                      this.setState({
                        payload: value,
                      })
                    }}
                    onCursorChange={(selection) => {
                      this.setCursorPosition(
                        [selection.getSelectionAnchor().row, selection.getSelectionAnchor().column],
                        [selection.getSelectionLead().row, selection.getSelectionLead().column],
                      )
                    }}
                    onFocus={() => {
                      this.setState({
                        focus: {
                          error: false,
                          payload: true,
                          headers: false,
                        },
                      })
                    }}
                    onBlur={() => {
                      this.setState({
                        focus: {
                          ...this.state.focus,
                          payload: false,
                        },
                      })
                    }}
                    height={'80px'}
                    width={'100%'}
                    fontSize={12}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={false}
                    value={this.state.payload}
                    style={{
                      borderBottom: 'solid',
                      borderBottomWidth: !this.state.error.payload ? '1px' : '2px',
                      borderBottomColor: !this.state.error.payload ? 'rgb(32, 89, 120)' : '#ff5252',
                      marginTop: '2px',
                    }}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2,
                    }}
                  />
                </div>
                <div style={{ display: 'flex', marginTop: '5px' }}>
                  <PzMenu
                    id="api-request-action-body"
                    color="primary"
                    containerSx={{
                      marginRight: '0.5rem',
                    }}
                    personalizationList={personalizationList}
                    variant="outlined"
                    onSelect={(item) => {
                      const { selectionStart, selectionEnd } = this.state
                      const newSelectionIndexColumn = Math.max(selectionStart[1], selectionEnd[1])
                      const newSelectionIndexRow = Math.min(selectionStart[0], selectionEnd[0])
                      const payloadRows = this.state.payload.split(/\r\n|\r|\n/)
                      payloadRows[newSelectionIndexRow] = [
                        payloadRows[newSelectionIndexRow].slice(0, newSelectionIndexColumn),
                        `{{${item.value}}}`,
                        payloadRows[newSelectionIndexRow].slice(newSelectionIndexColumn),
                      ].join('')
                      const payload = join('\n', payloadRows)
                      this.changeHandler(payload)
                    }}
                  />
                </div>
              </React.Fragment>
            )}
            <div
              style={{
                marginTop: '20px',
              }}
            >
              <InputLabel error={isHeadersValid} focused={this.state.focus.headers}>
                Headers
              </InputLabel>
              {this.state.headers.length === 0 && (
                <IconButton onClick={this.addRow}>
                  <Icon>add_circle</Icon>
                </IconButton>
              )}
              <DynamicInputs
                firstRowLock={this.state.method === 'POST'}
                values={this.state.headers}
                fields={['key', 'value']}
                onFocus={() => {
                  this.setState({
                    focus: {
                      error: false,
                      payload: false,
                      headers: true,
                    },
                  })
                }}
                onBlur={() => {
                  this.setState({
                    focus: {
                      ...this.state.focus,
                    },
                  })
                }}
                onChange={({ position, field, value }) => {
                  const { headers, error } = this.state
                  headers[position][field] = value
                  error.headers[position][field] = value === ''
                  this.setState({
                    headers,
                    error,
                  })
                }}
                removeRow={({ position }) => {
                  const { headers, error } = this.state
                  headers.splice(position, 1)
                  error.headers.splice(position, 1)
                  this.setState({
                    headers,
                    error,
                  })
                }}
                errors={this.state.error.headers}
                addRow={this.addRow}
              />
            </div>
          </DialogContent>
          <DialogActions>
            {this.props.deleteEnabled && (
              <div
                style={{
                  width: '100%',
                }}
              >
                <Button className={classes.deleteButton} onClick={onDelete} disabled={loading} size="small">
                  Remove Webhook
                </Button>
              </div>
            )}
            <Button
              variant="contained"
              color="primary"
              // eslint-disable-next-line max-len
              disabled={
                this.state.url.length === 0 ||
                !!this.state.error.payload ||
                !!this.state.error.url ||
                isHeadersValid ||
                loading
              }
              onClick={() =>
                onSave({
                  // TODO
                  [displayMap.api.path]: {
                    method: this.state.method,
                    url: this.state.url,
                    payload: this.state.method === 'POST' ? this.state.payload : '',
                    headers: this.state.headers,
                  },
                })
              }
            >
              Save
            </Button>
          </DialogActions>
        </CustomDialog>
      </Loading>
    )
  }
}

export default withStyles(CampaignContentQuestionDialogAPI, styles)
