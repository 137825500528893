import { styled, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'

import { Radio } from '@/app/component/atom/form'
import { mapPZBy } from '@/app/module/campaigns/component/helpers'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { removeCurlyBrackets } from '@/app/module/utils/personalization'
import { noTimezoneDateTimeFormat } from '@/app/service/util/format'
import { SelectOption } from '@/app/types'

import EventTimeAdvancedInput from './event-time-advanced-input'
import EventTimePicker from './event-time-picker'
import EventTimePzSelect from './event-time-pz-select'

type Props = {
  personalizationList: PersonalizationType
  t0: string
  t0timezone: string
  onChange: (t0: string, t0timezone: string) => void
}

enum T0Type {
  Same = 'same',
  Personalized = 'personalized',
  Advanced = 'advanced',
}

const t0TypeOptions: SelectOption<T0Type>[] = [
  {
    label: 'Personalized',
    value: T0Type.Personalized,
  },
  {
    label: 'Same for all',
    value: T0Type.Same,
  },
  {
    label: 'Advanced',
    value: T0Type.Advanced,
  },
]

const getIntialT0Type = (t0: string, hasPzOnly: boolean): T0Type => {
  if (hasPzOnly || !t0) {
    return T0Type.Personalized
  }

  if (moment(t0, noTimezoneDateTimeFormat, true).isValid()) {
    return T0Type.Same
  }

  return T0Type.Advanced
}

const EventTimeInput: React.FC<Props> = ({ personalizationList, t0, t0timezone, onChange }) => {
  const hasPzOnly = React.useMemo(() => {
    const pzMap = mapPZBy(personalizationList, 'value')
    const withoutBrackets = removeCurlyBrackets(t0)

    return !!pzMap[withoutBrackets]
  }, [personalizationList, t0])

  const [t0Type, setT0Type] = React.useState(getIntialT0Type(t0, hasPzOnly))

  React.useEffect(() => {
    // if t0 is empty and t0Type is Same, initialize t0 with current time to avoid empty t0 the first time
    if (t0Type === T0Type.Same && !t0) {
      onChange(moment().format(noTimezoneDateTimeFormat), t0timezone)
    }
  }, [t0, t0Type, t0timezone, onChange])

  const handleT0Change = React.useCallback((value: string) => onChange(value, t0timezone), [t0timezone, onChange])

  const renderT0Input = () => {
    switch (t0Type) {
      case T0Type.Personalized:
        return <EventTimePzSelect personalizationList={personalizationList} t0={t0} onChange={handleT0Change} />
      case T0Type.Advanced:
        return <EventTimeAdvancedInput personalizationList={personalizationList} t0={t0} onChange={handleT0Change} />
      default:
        return <EventTimePicker t0={t0} t0timezone={t0timezone} onChange={handleT0Change} />
    }
  }

  const renderHelpText = () => {
    switch (t0Type) {
      case T0Type.Same:
        return (
          <Typography color="textSecondary" variant="caption">
            All contacts share the same event time.
            <br />
            An example might be a reminder to go vote on election day, or to attend a single workshop—where everyone has
            the same date.
          </Typography>
        )

      case T0Type.Advanced:
        return (
          <Typography color="textSecondary" variant="caption">
            Personalize the event time using fragments and/or multiple custom fields. For example, you might store only
            the date in a custom field and select "09:00" as a fixed time for all. If “12345” is your custom field ID,
            then you could write: <Code>{'{{customfields.12345}}'} 09:00</Code>. You can add seconds, if needed, e.g.:{' '}
            <Code>{'{{customfields.12345}}'} 18:05:30</Code>. If you omit the time and only provide the date, we will
            assume midnight. A more technical format <Code>2006-01-23T16:04:05Z</Code> is also possible.
            <br />
            <Typography color="primary" variant="caption" sx={{ fontWeight: 'bold' }}>
              <LightIcon>💡</LightIcon>
              The end result for each contact must be a valid event date in one of the formats mentioned above.
              Otherwise the subscription will fail.
            </Typography>
            <br />
            Please contact customer support if you need help.
          </Typography>
        )
      default:
        return (
          <>
            <Typography color="textSecondary" variant="caption">
              Each contact has their individual event time.
              <br />
              An example might be the due date in a maternal health campaign, which is different for every mother.
              Another example could be a series of weekend workshops, where participants are assigned to different
              weekends.
            </Typography>
            <br />
            <Typography color="textSecondary" variant="caption">
              To personalize the event time, select the contact field below that will store the event time.
              <br />
              <Typography color="primary" variant="caption" sx={{ fontWeight: 'bold' }}>
                <LightIcon>💡</LightIcon>
                You must then ensure that every contact has a valid event date and time stored in that field—for example{' '}
                <Code>2024-03-17 09:00</Code>. Also, please be sure to select the correct timezone below.
              </Typography>
            </Typography>
          </>
        )
    }
  }

  return (
    <Container>
      <Radio
        name="t0-type"
        row
        value={t0Type}
        values={t0TypeOptions}
        onChange={({ value }) => {
          setT0Type(value as T0Type)
          if (value === T0Type.Same) {
            onChange(moment().format(noTimezoneDateTimeFormat), t0timezone) // initialize t0 with current time
          } else if (value === T0Type.Personalized && !hasPzOnly) {
            onChange('', t0timezone) // clear t0 if switching to personalization and t0 is not a single personalization
          }
        }}
      />
      {renderHelpText()}
      {renderT0Input()}
    </Container>
  )
}

const Container = styled('div')(() => ({
  marginTop: '1rem',
}))

const LightIcon = styled('span')(() => ({
  fontSize: 14,
}))

const Code = styled('code')(({ theme }) => ({
  backgroundColor: theme.palette.highlight.light,
}))

export default React.memo(EventTimeInput)
