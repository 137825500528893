import { z } from 'zod'

import ZBaseAction from './base'

const ZJumpAction = ZBaseAction.extend({
  jump: z.object({
    nextPart: z.string(),
  }),
})

export default ZJumpAction
