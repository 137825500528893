import { Typography } from '@mui/material'
import { append, remove, update } from 'ramda'
import React from 'react'
import { v1 as uuid } from 'uuid'

import { CampaignAddButton } from '@/app/module/campaigns/component/helpers'
import { useCampaignDebouncedState } from '@/app/module/campaigns/component/hooks/use-campaign-debounced-state'
import {
  CampaignTemplateType,
  LocalWhatsAppButtonItem,
  LocalWhatsAppButtons,
  PartFamily,
  TWhatsAppButtons,
} from '@/app/module/campaigns/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { emptyAction, unwrapActions, wrapActions } from '@/app/module/campaigns/utils/actions/wrap'

import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import WhatsappButtonItem from './whatsapp-button-item'

type Props = {
  actionProps: ActionProps
  apiProps: ApiProps
  campaignType: CampaignTemplateType
  onButtons: TWhatsAppButtons
  snippetProps: SnippetProps

  onChange: (value: TWhatsAppButtons) => void
}

const ResponseWhatsappButtons: React.FC<Props> = ({
  actionProps,
  apiProps,
  campaignType,
  onButtons,
  snippetProps,
  onChange,
}) => {
  const initialState = React.useMemo(() => fromOnButtons(onButtons), [onButtons])
  const handleChange = React.useCallback((value: LocalWhatsAppButtons) => onChange(toOnButtons(value)), [onChange])
  const [state, setState] = useCampaignDebouncedState(initialState, handleChange)

  const addOptionDisabled = state.buttons.length >= 3
  const buttonActions = React.useMemo(
    () =>
      getActionOptionsForCampaign({
        campaignType,
        partType: PartFamily.WhatsApp,
        actionNamespace: ActionContext.OnButtons,
      }),
    [campaignType],
  )

  const handleButtonItemChange = React.useCallback(
    (buttonIndex: number, value: LocalWhatsAppButtonItem) =>
      setState((s) => ({
        ...s,
        buttons: update(buttonIndex, value, s.buttons),
      })),
    [setState],
  )
  const handleButtonItemClose = React.useCallback(
    (buttonIndex: number) => setState((s) => ({ ...s, buttons: remove(buttonIndex, 1, s.buttons) })),
    [setState],
  )
  const handleButtonItemAdd = React.useCallback(() => {
    if (addOptionDisabled) {
      return
    }

    setState((s) => ({
      ...s,
      buttons: append(
        {
          id: uuid(),
          actions: [emptyAction],
          label: '',
          title: '',
        },
        s.buttons,
      ),
    }))
  }, [addOptionDisabled, setState])

  return (
    <div className="campaign-whatsapp-buttons-tab">
      <Typography>WhatsApp Buttons</Typography>
      <div>
        <Typography color="textSecondary" variant="caption" gutterBottom>
          Specify up to three buttons that the contact can select and the corresponding actions we should take for each.
          Do not include emojis or special characters if you register this message as a template.
        </Typography>

        {state.buttons.map((button, i) => (
          <WhatsappButtonItem
            {...actionProps}
            {...apiProps}
            {...snippetProps}
            actionOptions={buttonActions}
            buttonIndex={i}
            isCloseDisabled={state.buttons?.length === 1}
            key={i}
            value={button}
            onChange={handleButtonItemChange}
            onClose={handleButtonItemClose}
          />
        ))}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CampaignAddButton
            disabled={addOptionDisabled}
            icon="add"
            text="Add button"
            tooltip={
              addOptionDisabled
                ? 'You can only specify up to 3 buttons. Use WhatsApp List to set up to 10 answer choices.'
                : 'Add button'
            }
            onClick={handleButtonItemAdd}
          />
        </div>
      </div>
    </div>
  )
}

const fromOnButtons = (onButtons: TWhatsAppButtons): LocalWhatsAppButtons => ({
  ...onButtons,
  buttons: onButtons.buttons.map((button) => ({
    ...button,
    actions: unwrapActions(button.actions || []),
  })),
})

const toOnButtons = (onButtons: LocalWhatsAppButtons): TWhatsAppButtons => ({
  ...onButtons,
  buttons: onButtons.buttons.map((button) => ({
    ...button,
    actions: wrapActions(button.actions || []),
  })),
})

export default ResponseWhatsappButtons

export type { Props as ResponseWhatsappButtonsProps }
