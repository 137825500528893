import { Icon, IconButton, ListItem, styled, TextField } from '@mui/material'
import { path } from 'ramda'
import React, { CSSProperties } from 'react'
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { FieldError, useFormContext } from 'react-hook-form'

import { UserStatusesFormValues } from './types'

type Props = {
  dragDisabled?: boolean
  id: string // unique id
  index: number

  onBlur: (index: number) => void
  onRemove: (index: number) => void
}

const UserStatusFormItem: React.FC<Props> = ({ dragDisabled, id, index, onBlur, onRemove }) => {
  const handleBlur = React.useCallback(() => {
    onBlur(index)
  }, [index, onBlur])
  const handleRemove = React.useCallback(() => {
    onRemove(index)
  }, [index, onRemove])

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={dragDisabled}>
      {(provided, snapshot) => (
        <FormListItem
          dragDisabled={dragDisabled}
          index={index}
          provided={provided}
          snapshot={snapshot}
          onBlur={handleBlur}
          onRemove={handleRemove}
        />
      )}
    </Draggable>
  )
}

type FormListItemProps = {
  dragDisabled?: boolean
  index: number
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot

  onBlur: () => void
  onRemove: () => void
}

const FormListItem = React.memo(
  ({ dragDisabled, index, provided, snapshot, onBlur: propsOnBlur, onRemove }: FormListItemProps) => {
    const { formState, getValues, register } = useFormContext<UserStatusesFormValues>()
    const error = path<FieldError>(['userStatuses', index, 'status'], formState.errors)

    const dragStyle = React.useMemo(() => {
      if (dragDisabled) {
        return {
          dragCursor: 'not-allowed',
        }
      }
      return {
        dragCursor: snapshot.isDragging ? 'grabbing' : 'grab',
      }
    }, [dragDisabled, snapshot.isDragging])

    const { onBlur, ...field } = React.useMemo(
      () =>
        register(`userStatuses.${index}.status`, {
          validate: (value) => validateStatus(index, value, getValues),
        }),
      [index, getValues, register],
    )

    const handleBlur = React.useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        propsOnBlur()
        return onBlur(event)
      },
      [onBlur, propsOnBlur],
    )

    return (
      <StyledListItem {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
        <Icon color={dragDisabled ? 'disabled' : 'action'} style={dragStyle as CSSProperties}>
          drag_indicator
        </Icon>
        <StyledTextField
          {...field}
          error={!!error}
          fullWidth
          helperText={error?.message}
          label={`Status ${index + 1}`}
          size="small"
          type="text"
          onBlur={handleBlur}
        />
        <IconButton size="small" onClick={onRemove}>
          <Icon>delete</Icon>
        </IconButton>
      </StyledListItem>
    )
  },
)

const StyledListItem = styled(ListItem)({
  padding: '8px 0',
})

const StyledTextField = styled(TextField)({
  '&.MuiFormControl-root': {
    marginBottom: 0,
  },
})

export const validateStatus = (index: number, value: string, getValues: () => UserStatusesFormValues) => {
  const trimmed = value.trim()
  if (!trimmed) {
    return 'User status must not be empty'
  }
  if (trimmed.length > 100) {
    return 'User status must not exceed 100 characters'
  }

  const values = getValues()
  const duplicated = values.userStatuses.some((s, i) => s.status.trim() === trimmed && i !== index)
  if (duplicated) {
    return 'User status must be unique'
  }

  return undefined
}

export default React.memo(UserStatusFormItem)
