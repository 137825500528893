import auth from '@/app/module/auth/store/state'
import campaigns from '@/app/module/campaigns/store/state'
import cati from '@/app/module/cati/store/state'
import contacts from '@/app/module/contacts/store/state'
import conversations from '@/app/module/conversations/store/state'
import customFields from '@/app/module/custom-fields/store/state'
import dashboard from '@/app/module/dashboard/store/state'
import demos from '@/app/module/demos/store/state'
import exportsState from '@/app/module/exports/store/state'
import files from '@/app/module/files/store/state'
import groups from '@/app/module/groups/store/state'
import logs from '@/app/module/logs/store/state'
import notifications from '@/app/module/notifications/store/state'
import payments from '@/app/module/payments/store/state'
import phoneNumbers from '@/app/module/phonenumbers/store/state'
import plans from '@/app/module/plans/store/state'
import segments from '@/app/module/segments/store/state'
import user from '@/app/module/user/store/state'
import utils from '@/app/module/utils/store/state'

import { esApi } from './api'

export const defaultState = {
  auth,
  user,
  contacts,
  customFields,
  demos,
  logs,
  conversations,
  phoneNumbers,
  notifications,
  utils,
  campaigns,
  groups,
  segments,
  payments,
  files,
  dashboard,
  plans,
  cati,
  exports: exportsState,
  [esApi.reducerPath]: {},
}
