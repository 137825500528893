import { compose, filter, indexBy, sort, union } from 'ramda'

import {
  ACTION_DELETE_EXPORT_FAILURE,
  ACTION_DELETE_EXPORT_REQUEST,
  ACTION_DELETE_EXPORT_SUCCESS,
  ACTION_GET_EXPORTS_FAILURE,
  ACTION_GET_EXPORTS_REQUEST,
  ACTION_GET_EXPORTS_SUCCESS,
} from '@/app/module/exports/definitions'
import { Export } from '@/app/module/exports/types'
import { createReducer as baseCreateReducer } from '@/app/service/util'
import { CreateReducer } from '@/app/service/util/types-store'

import defaultState from './state'

const createReducer = baseCreateReducer as CreateReducer

export default createReducer(defaultState, {
  [ACTION_GET_EXPORTS_REQUEST]: (state, { value }) => ({
    ...state,
    loading: true,
    data: {
      ...state.data,
      exports: {
        ...state.data.exports,
        error: defaultState.error,
        query: value,
      },
    },
  }),
  [ACTION_GET_EXPORTS_SUCCESS]: (state, { value }) => {
    const incomingExportsMap = indexBy<Export>(({ id }) => `${id}`, value.exports)
    const newExports = compose<Export[], Export[], Export[], Export[]>(
      sort((a, b) => b.id - a.id),
      union(value.exports),
      filter((exp: Export) => !incomingExportsMap[`${exp.id}`]),
    )(state.data.exports.list)
    const hasMore = value.total !== undefined ? newExports.length < value.total : state.data.exports.hasMore
    return {
      ...state,
      loading: false,
      data: {
        ...state.data,
        exports: {
          ...state.data.exports,
          list: newExports,
          hasMore,
        },
      },
    }
  },
  [ACTION_GET_EXPORTS_FAILURE]: (state, { value }) => ({
    ...state,
    loading: false,
    data: {
      ...state.data,
      error: value,
      exports: defaultState.data.exports,
    },
  }),
  [ACTION_DELETE_EXPORT_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [ACTION_DELETE_EXPORT_SUCCESS]: (state, { value }) => ({
    ...state,
    loading: false,
    data: {
      ...state.data,
      exports: {
        ...state.data.exports,
        list: state.data.exports.list.filter((item) => item.id !== value),
      },
    },
  }),
  [ACTION_DELETE_EXPORT_FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
})
