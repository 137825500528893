import { Typography } from '@mui/material'
import React from 'react'

import { CampaignAddButton } from '@/app/module/campaigns/component/helpers'
import { useCampaignDebouncedState } from '@/app/module/campaigns/component/hooks/use-campaign-debounced-state'
import { CampaignTemplateType, LocalOnAnswerType, OnAnswerType } from '@/app/module/campaigns/types'
import { emptyAction, unwrapActions, wrapActions } from '@/app/module/campaigns/utils/actions/wrap'

import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import ResponseVoiceSpecificItem from './response-voice-specific-item'
import { emptyRanges, emptyReplies } from './types/multimessage/definitions'

type Props = {
  actionProps: ActionProps
  apiProps: ApiProps
  campaignType: CampaignTemplateType
  id: string
  onAnswer: OnAnswerType[]
  snippetProps: SnippetProps

  onChange: (value: OnAnswerType[]) => void
}

const ResponseVoiceSpecific: React.FC<Props> = ({
  actionProps,
  apiProps,
  campaignType,
  id,
  onAnswer,
  snippetProps,
  onChange,
}) => {
  const initialState = React.useMemo(() => fromOnAnswer(onAnswer), [onAnswer])
  const handleChange = React.useCallback((value: LocalOnAnswerType[]) => onChange(toOnAnswer(value)), [onChange])
  const [state, setState] = useCampaignDebouncedState(initialState, handleChange)

  const handleAnswerAdd = React.useCallback(() => {
    setState((s) => [
      ...s,
      {
        replies: [],
        ranges: [],
        actions: [emptyAction],
        label: '',
      },
    ])
  }, [setState])
  const handleAnswerChange = React.useCallback(
    ({ ranges, replies, actions, label }: Partial<LocalOnAnswerType>, index: number) => {
      setState((s) =>
        s.map((item, i) => {
          if (i !== index) {
            return item
          }
          return {
            ...item,
            replies: replies || item.replies || [],
            ranges: ranges || item.ranges || [],
            actions: actions || item.actions || [emptyAction],
            label: typeof label === 'string' ? label : item.label || '',
          }
        }),
      )
    },
    [setState],
  )
  const handleAnswerClose = React.useCallback(
    (index: number) => {
      setState((s) => s.filter((_, i) => i !== index))
    },
    [setState],
  )

  const isCloseDisabled = state.length === 1

  return (
    <div>
      <Typography>Choices</Typography>
      <div>
        <Typography color="textSecondary" gutterBottom variant="caption">
          Specify the valid keypresses that the contact can press to answer the question and what actions we should take
          for each choice.
        </Typography>
        {state.map(({ ranges = emptyRanges, replies = emptyReplies, actions, label = '' }, i: number) => (
          <ResponseVoiceSpecificItem
            {...actionProps}
            {...apiProps}
            {...snippetProps}
            actions={actions}
            id={id}
            index={i}
            isCloseDisabled={isCloseDisabled}
            key={i}
            label={label}
            questionIndex={actionProps.index}
            ranges={ranges}
            replies={replies}
            type={campaignType}
            onChange={handleAnswerChange}
            onClose={handleAnswerClose}
          />
        ))}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CampaignAddButton tooltip="Add choice" onClick={handleAnswerAdd} text="Add choice" icon="add" />
        </div>
      </div>
    </div>
  )
}

const fromOnAnswer = (onAnswer: OnAnswerType[]): LocalOnAnswerType[] =>
  onAnswer.map((answer) => ({
    ...answer,
    actions: unwrapActions(answer.actions || []),
  }))

const toOnAnswer = (onAnswer: LocalOnAnswerType[]): OnAnswerType[] =>
  onAnswer.map((answer) => ({
    ...answer,
    actions: wrapActions(answer.actions),
  }))

export default ResponseVoiceSpecific

export type { Props as ResponseVoiceSpecificProps }
