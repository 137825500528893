import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { isEmpty, pathOr } from 'ramda'
import React from 'react'
import SMSHelper from 'smshelper'

import Select from '@/app/component/atom/form/select'
import IconText from '@/app/component/layout/icon-text'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { DEFAULT_SENDER_ID_VALUE } from '@/app/definitions'
import SMSLanguageWrapper from '@/app/module/campaigns/component/item/steps/content/language-wrapper'
import { DEFAULT_SENDER_ID_MESSAGE, displayMap, smsMaxLength } from '@/app/module/campaigns/definitions'
import { hasContent } from '@/app/module/campaigns/helpers'
import { decodeText } from '@/app/module/campaigns/store/selectors'
import Message from '@/app/module/logs/component/send/message'

const unwrapValue = (value) =>
  pathOr(
    {
      text: '',
      senderId: '0',
      useCampaignSenderId: '',
      translations: {},
    },
    ['sendSMS'],
    value,
  )

const CampaignContentQuestionDialogSMS = (props) => {
  const { text, senderId: initialSenderId, useCampaignSenderId, translations } = unwrapValue(props.value)
  const [message, setMessage] = React.useState({
    text,
    translations,
  })
  const [senderId, setSenderId] = React.useState(initialSenderId)
  const [senderIds, setSenderIds] = React.useState(props.senderIds)

  React.useEffect(() => {
    if (!isEmpty(props.defaultSenderId) && useCampaignSenderId !== 'action') {
      setSenderId(props.defaultSenderId.value)
    } else if (initialSenderId === '0') {
      setSenderId(pathOr(DEFAULT_SENDER_ID_VALUE, ['phoneNumber'], props.phoneNumber))
    } else setSenderId(initialSenderId === '' ? DEFAULT_SENDER_ID_VALUE : initialSenderId)
  }, [props.defaultSenderId, initialSenderId, useCampaignSenderId, props.phoneNumber])

  React.useEffect(() => {
    if (!isEmpty(props.defaultSenderId)) {
      setSenderIds([props.defaultSenderId, ...props.senderIds])
    }
  }, [props.senderIds, props.defaultSenderId])

  const { personalizationList, onSave, onClose, defaultSenderId } = props

  const parts = SMSHelper.parts(message.text)

  const changeHandler = ({ message: updatedMessage }) => {
    setMessage(updatedMessage)
  }

  return (
    <CustomDialog open={true} onClose={onClose}>
      <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>{displayMap.sms.icon}</Icon>
            <Typography variant="h6">Send SMS to contact</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '24px' }}>
        <div
          style={{
            width: '70vw',
            minWidth: '300px',
            maxWidth: '100%',
          }}
        >
          <SMSLanguageWrapper
            item={{ message }}
            languages={props.languages}
            defaultLanguage={props.defaultLanguage}
            info="Add your message below"
            onChange={changeHandler}
            type="message"
            content="text"
          >
            {(activeText, onTextChange, activeError) => (
              <Message
                autoFocus
                message={activeText}
                error={activeError}
                personalizationList={personalizationList}
                onChange={onTextChange}
              />
            )}
          </SMSLanguageWrapper>
          <div
            style={{
              marginTop: '10px',
            }}
          >
            <Select
              id="send-message-sender"
              label="Sender ID"
              value={senderId}
              values={senderIds}
              editable={true}
              className="send-message-sender"
              onChange={({ value }) => {
                setSenderId(value)
              }}
              info={
                senderId === DEFAULT_SENDER_ID_VALUE && senderIds.length === 1
                  ? DEFAULT_SENDER_ID_MESSAGE
                  : 'Select what should appear on the recipients phones as whom this SMS Message is from (e.g., MyOrg or 12125551212)'
              }
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          id="send-sms-action-save"
          disabled={!hasContent(message, 'text') || senderId === '0' || senderId === undefined || parts > smsMaxLength}
          onClick={() =>
            onSave({
              sendSMS: {
                language: 'en',
                text: decodeText(message.text),
                translations: message.translations,
                senderId:
                  senderId === DEFAULT_SENDER_ID_VALUE || senderId === pathOr('', ['value'], defaultSenderId)
                    ? ''
                    : senderId,
                useCampaignSenderId:
                  senderId === pathOr('', ['value'], defaultSenderId) ? defaultSenderId.value : 'action',
              },
            })
          }
        >
          Save
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}

export default CampaignContentQuestionDialogSMS
