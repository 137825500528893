import { Box, List, Typography } from '@mui/material'
import React from 'react'

import NavigatorItem from './navigator-item'

type TNavigatorItem<Path extends string = string> = {
  icon?: string
  label: string
  path: Path
}

type Props<Path extends string = string> = {
  activePath: Path
  id: string
  items: TNavigatorItem<Path>[]
  title: string

  onItemClick: (path: Path) => void
}

function Navigator<Path extends string = string>({ activePath, id, items, title, onItemClick }: Props<Path>) {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Typography color="primary" px={2} py={2} variant="h6">
        {title}
      </Typography>
      <List id={`${id}-navigator`} sx={{ flex: 1 }}>
        {items.map((item) => (
          <NavigatorItem
            icon={item.icon}
            isActive={activePath === item.path}
            key={item.path}
            label={item.label}
            path={item.path}
            onClick={onItemClick}
          />
        ))}
      </List>
    </Box>
  )
}

export default Navigator
export type { Props as NavigatorProps, TNavigatorItem }
