import {
  LocalOnInvalidReply,
  LocalOnRetriesExhausted,
  LocalOnTimeout,
  OnInvalidReply,
  OnRetriesExhausted,
  OnTimeout,
} from '@/app/module/campaigns/types'

import { fromFailure, fromInvalid, fromTimeout, toFailure, toInvalid, toTimeout } from './response-configs-utils'

export type WhatsappConfigs = {
  onInvalidReply?: OnInvalidReply
  onTimeout: OnTimeout
  retries: number
  onRetriesExhausted: OnRetriesExhausted
}

export type WhatsappConfigsState = {
  onInvalidReply?: LocalOnInvalidReply
  onTimeout: LocalOnTimeout
  retries: number
  onRetriesExhausted: LocalOnRetriesExhausted
}

export const fromConfigs = (configs: WhatsappConfigs): WhatsappConfigsState => ({
  onInvalidReply: configs.onInvalidReply ? fromInvalid(configs.onInvalidReply) : undefined,
  onTimeout: fromTimeout(configs.onTimeout),
  retries: configs.retries,
  onRetriesExhausted: fromFailure(configs.onRetriesExhausted),
})

export const toConfigs = (state: WhatsappConfigsState): WhatsappConfigs => ({
  onInvalidReply: state.onInvalidReply ? toInvalid(state.onInvalidReply) : undefined,
  onTimeout: toTimeout(state.onTimeout),
  retries: state.retries,
  onRetriesExhausted: toFailure(state.onRetriesExhausted),
})
