import { Link, Typography } from '@mui/material'
import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import CardLayout from '@/app/component/layout/card'
import ErrorLayout from '@/app/component/layout/error'
import Modal from '@/app/component/layout/modal'
import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Contacts, { UploadContacts } from '@/app/module/contacts'
import { updateItem } from '@/app/module/contacts/store/actions'
import { CustomFieldsItem } from '@/app/module/custom-fields'
import { CreateGroup, CreateSegment, UpdateGroup, UpdateSegment } from '@/app/module/groups'
import { getGroupsItem } from '@/app/module/groups/store/actions'
import Notifications from '@/app/module/notifications'
import { getSegmentsItem } from '@/app/module/segments/store/actions'
import config from '@/config'

const GROUP_TYPE = pathOr({}, ['modules', 'groups', 'type'], config)
const SEGMENT_TYPE = pathOr({}, ['modules', 'segments', 'type'], config)

const TYPES = [GROUP_TYPE.name, SEGMENT_TYPE.name]

const onExitItem =
  ({ history, clearItem, page = 1, activeFilter, search }) =>
  (itemId) => {
    clearItem()
    let url = `/contacts/?page=${page}`
    if (activeFilter) {
      url += `&filterType=${activeFilter.name}&filterId=${activeFilter.value}`
    } else if (search) {
      const encodedSearchTerm = encodeURIComponent(search)
      url += `&search=${encodedSearchTerm}`
    }
    if (itemId && typeof itemId === 'number') {
      url += `&addToGroupContacts=${itemId}`
    }
    history.push(url)
  }

function ContactsPage({ name, data, routes, match, history, clearItem, clearGroupsItem, clearSegmentsItem, location }) {
  const params = new URLSearchParams(location.search)
  const page =
    params.get('page') && params.get('page') !== '0'
      ? parseInt(params.get('page'), 10)
      : parseInt(match.params.page, 10) || pathOr(1, ['page'], data)
  const activeFilter =
    params.get('filterType') && params.get('filterId')
      ? {
          name: params.get('filterType'),
          value: params.get('filterId'),
        }
      : undefined
  const addToGroupContacts = params.get('addToGroupContacts') ? params.get('addToGroupContacts') : ''
  const search = params.get('search') ? params.get('search') : ''

  if (params.get('filterType') && !TYPES.find((type) => type === params.get('filterType'))) {
    return (
      <Layout title="Error - engageSPARK">
        <AuthHeader currentPage={PAGE.CONTACTS.FILTER} />
        <CardLayout>
          <ErrorLayout status={404}>
            <Typography>{`The filter "${match.params.filterType}" does not exist.`}</Typography>
            <Typography>
              We can help find your way back <Link to="/">home</Link>
            </Typography>
          </ErrorLayout>
        </CardLayout>
      </Layout>
    )
  }

  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <Layout disableScrolling title="Contacts - engageSPARK">
        <AuthHeader currentPage={name} />
        <Contacts
          history={history}
          page={page}
          pageSearchTerm={search}
          activeFilter={activeFilter}
          addToGroupContacts={addToGroupContacts}
        />
        <Modal
          title="Upload contacts"
          isOpen={name === PAGE.CONTACTS.UPLOAD}
          onClose={() => history.push(`/contacts/page/${page}`)}
        >
          <UploadContacts history={history} onExit={() => history.push(`/contacts/page/${page}`)} />
        </Modal>
        <Modal
          title="Add group"
          isOpen={name === PAGE.GROUPS.ADD}
          onClose={onExitItem({ history, clearItem: clearGroupsItem, page })}
        >
          <CreateGroup onExit={onExitItem({ history, clearItem: clearGroupsItem, page })} />
        </Modal>
        <Modal
          title="Edit group"
          isOpen={name === PAGE.GROUPS.EDIT}
          onClose={onExitItem({ history, clearItem: clearGroupsItem, page })}
        >
          <UpdateGroup
            groupId={match.params.groupsId}
            onExit={onExitItem({ history, clearItem: clearGroupsItem, page })}
          />
        </Modal>
        <Modal
          title="Add segment"
          isOpen={name === PAGE.SEGMENTS.ADD}
          onClose={onExitItem({ history, clearItem: clearSegmentsItem, page })}
        >
          <CreateSegment onExit={onExitItem({ history, clearItem: clearSegmentsItem, page })} />
        </Modal>
        <Modal
          title="Edit segment"
          isOpen={name === PAGE.SEGMENTS.EDIT}
          onClose={onExitItem({ history, clearItem: clearSegmentsItem, page })}
        >
          <UpdateSegment
            segmentId={match.params.segmentsId}
            onExit={onExitItem({ history, clearItem: clearSegmentsItem, page })}
          />
        </Modal>
        <Modal
          title="Add custom fields"
          isOpen={name === PAGE.CUSTOMFIELDS.ADD}
          onClose={onExitItem({ history, clearItem, page })}
        >
          <CustomFieldsItem history={history} onExit={onExitItem({ history, clearItem, page })} />
        </Modal>
      </Layout>
      <Notifications />
    </AuthGuard>
  )
}

export default connect(
  ({ contacts }) => ({
    data: contacts.list.data,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        clearItem: () => () => {
          dispatch(updateItem({}))
        },
        clearSegmentsItem: () => () => {
          dispatch(getSegmentsItem({}))
        },
        clearGroupsItem: () => () => {
          dispatch(getGroupsItem({}))
        },
      },
      dispatch,
    ),
)(ContactsPage)
