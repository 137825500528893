import { z } from 'zod'

import ZAction from '@/app/module/campaigns/types/schemas/actions/all-actions'

const ZAnswerRange = z.object({
  max: z.number(),
  min: z.number(),
})

const ZAnswer = z.object({
  actions: z.array(ZAction).optional(),
  label: z.string().optional(),
  ranges: z.array(ZAnswerRange).optional(),
  replies: z.array(z.string()).optional(),
  reply: z.string().optional(),
})

const ZOnAnswer = z.array(ZAnswer)

export default ZOnAnswer
