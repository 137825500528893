import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import React from 'react'

import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import { TableHeader, TableSpecs } from '@/app/types'
import { FixMeLater } from '@/config/types'

import AuthenticationVerification from './authentication-verification'
import PasswordReset from './layout/password-reset'
import RecoveryCodes from './recovery-codes'

type Props = {
  passwordResetHeaders: TableHeader[]
  passwordResetLoading: boolean
  recoveryCodes: string[]
  recoveryCodesLoading: boolean
  specs: TableSpecs
  token: string
  totpEnabled: boolean
  totpLoading: boolean
  totpQRCode: string
  totpSecret: string

  disableTotp: (payload: { token: string }) => Promise<void>
  enableTotp: (payload: { token: string; passcode: string; secret: string }) => Promise<{ recoveryCodes?: string[] }>
  getRecoveryCodes: (payload: { token: string }) => Promise<void>
  initTotp: (payload: { token: string }) => Promise<{ png?: string; secret?: string }>
  resetRecoveryCodes: (payload: { token: string }) => Promise<void>
  removeRecoveryCodes: () => void
  savePassword: (payload: { token: string; item: FixMeLater }) => Promise<void>
}

export default function Security(props: Props) {
  const {
    passwordResetLoading,
    passwordResetHeaders,
    savePassword,
    token,
    specs,
    initTotp,
    totpQRCode,
    enableTotp,
    totpSecret,
    totpEnabled,
    disableTotp,
    recoveryCodes,
    resetRecoveryCodes,
    getRecoveryCodes,
    removeRecoveryCodes,
  } = props

  const [isDisableModalOpen, setIsDisableModalOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isResetModalOpen, setIsResetModalOpen] = React.useState(false)
  const [isTotpModalOpen, setIsTotpModalOpen] = React.useState(false)

  const handleInitTotp = async () => {
    if (isLoading) {
      return
    }
    try {
      setIsLoading(true)
      const res = await initTotp({ token })
      if (res instanceof Error || !res.secret || !res.png) {
        return
      }
      setIsTotpModalOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDisableTotp = async () => {
    if (isLoading) {
      return
    }
    try {
      setIsLoading(true)
      await disableTotp({ token })
    } finally {
      setIsDisableModalOpen(false)
      setIsLoading(false)
    }
  }

  const handleResetRecoveryCodes = async () => {
    if (isLoading) {
      return
    }
    try {
      setIsLoading(true)
      await resetRecoveryCodes({ token })
    } finally {
      setIsResetModalOpen(false)
      setIsLoading(false)
    }
  }

  return (
    <div>
      {isDisableModalOpen && (
        <ConfirmationDialog
          disabled={isLoading}
          text="Are you sure you want to disable two-factor authentication?"
          onClose={() => setIsDisableModalOpen(false)}
          onConfirm={handleDisableTotp}
          isOpen={isDisableModalOpen}
          deleteButtonText="Disable"
          title="Disable two-factor authentication"
        />
      )}
      {isResetModalOpen && (
        <ConfirmationDialog
          disabled={isLoading}
          text="Are you sure you want to reset your recovery codes?"
          onClose={() => setIsResetModalOpen(false)}
          onConfirm={handleResetRecoveryCodes}
          isOpen={isResetModalOpen}
          deleteButtonText="Reset"
          title="Reset recovery codes"
        />
      )}
      {isTotpModalOpen && (
        <AuthenticationVerification
          token={token}
          totpQRCode={totpQRCode}
          totpSecret={totpSecret}
          enableTotp={enableTotp}
          removeRecoveryCodes={removeRecoveryCodes}
          setIsTotpModalOpen={setIsTotpModalOpen}
        />
      )}
      <CardContent>
        <Section>
          <SectionTitle>Password Reset</SectionTitle>
          <SectionBody>
            <PasswordReset
              loading={passwordResetLoading}
              config={specs}
              headers={passwordResetHeaders}
              values={{
                currentPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
              }}
              onSubmit={({ item }: any) =>
                savePassword({
                  token,
                  item,
                })
              }
            />
          </SectionBody>
        </Section>
        <Section>
          <SectionTitle>Two-factor Authentication</SectionTitle>
          <SectionBody>
            <Icon>{`lock${totpEnabled ? '' : '_open'}`}</Icon>
            <div
              style={{
                marginBottom: '10px',
              }}
            >
              <Typography variant="h6" id="totp-status-text">
                Two-factor authentication is {totpEnabled ? 'enabled' : 'not enabled yet'}.
              </Typography>
            </div>
            {totpEnabled ? (
              <>
                <Button onClick={() => setIsDisableModalOpen(true)} variant="outlined" color="primary">
                  Disable two-factor authentication
                </Button>
              </>
            ) : (
              <>
                <Button disabled={isLoading} onClick={handleInitTotp} variant="contained" color="primary">
                  Enable two-factor authentication
                </Button>
                <div
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <Typography variant="caption" color="textSecondary">
                    Two-factor authentication adds an additional layer of security to your account by requiring more
                    than just a password to sign in.
                  </Typography>
                </div>
              </>
            )}
          </SectionBody>
        </Section>
        {totpEnabled && (
          <Section>
            <SectionTitle>Recovery Codes</SectionTitle>
            <SectionBody
              style={{
                textAlign: 'left',
              }}
            >
              <div
                style={{
                  marginBottom: '10px',
                }}
              >
                <Typography variant="h6">
                  Recovery codes can be used to access your account in the event you lose access to your device and
                  cannot generate two-factor authentication codes.
                </Typography>
              </div>
              {recoveryCodes.length > 0 ? (
                <Button
                  onClick={() => removeRecoveryCodes()}
                  variant="contained"
                  color="primary"
                  style={{
                    marginRight: '15px',
                  }}
                >
                  Hide your recovery codes
                </Button>
              ) : (
                <Button
                  onClick={() => getRecoveryCodes({ token })}
                  variant="contained"
                  color="primary"
                  style={{
                    marginRight: '15px',
                  }}
                >
                  View your recovery codes
                </Button>
              )}
              <Button onClick={() => setIsResetModalOpen(true)} variant="outlined" color="primary">
                Reset your recovery codes
              </Button>
              {recoveryCodes.length > 0 && (
                <>
                  <RecoveryCodes recoveryCodes={recoveryCodes} />
                </>
              )}
            </SectionBody>
          </Section>
        )}
      </CardContent>
    </div>
  )
}

const SectionTitle = ({ children }: { children: string }) => (
  <div
    style={{
      marginBottom: '15px',
    }}
  >
    <Typography variant="h5">{children}</Typography>
    <Divider />
  </div>
)

const Section = ({ children }: any) => (
  <div
    style={{
      marginBottom: '30px',
    }}
  >
    {children}
  </div>
)

const SectionBody = ({ children, style }: any) => (
  <div
    style={{
      textAlign: 'center',
      marginBottom: '10px',
      paddingTop: '15px',
      ...style,
    }}
  >
    {children}
  </div>
)
