import { Card, CardContent, CardHeader, Grid, styled, Typography } from '@mui/material'
import React from 'react'

import { useItemContext } from '@/app/module/campaigns/component/item-provider'
import { CampaignTemplateType } from '@/app/module/campaigns/types'

import UserStatusForm from './user-status-form'

type Props = {}

const CatiSettings: React.FC<Props> = () => {
  const { item, setItem } = useItemContext()

  const handleUserStatusesChange = React.useCallback(
    (userStatuses: string[]) => {
      if (!item || item.type !== CampaignTemplateType.CATI || !setItem) {
        return
      }

      setItem({
        ...item,
        userStatuses,
      })
    },
    [item, setItem],
  )

  const userStatues = item?.userStatuses || []

  return (
    <StyledCard>
      <StyledCardHeader title="Custom Survey Statuses" />
      <StyledCardContent>
        <Typography>
          Custom statuses help you understand where a survey is or why it ended. When saving CATI surveys, agents will
          be required to pick one of these statuses. Leave empty to disable.
        </Typography>
        <Grid container>
          <Grid item md={6} xs={12}>
            <FormContainer>
              <UserStatusForm userStatuses={userStatues} onChange={handleUserStatusesChange} />
            </FormContainer>
          </Grid>
        </Grid>
      </StyledCardContent>
    </StyledCard>
  )
}

const StyledCard = styled(Card)(() => ({
  marginBottom: '2rem',
}))

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
}))

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
})

const FormContainer = styled('div')({
  marginTop: '1rem',
})

export default CatiSettings
