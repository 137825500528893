import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Icon from '@mui/material/Icon'
import { pathOr } from 'ramda'
import { Component } from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { withStyles } from 'tss-react/mui'

import Loading from '@/app/component/guard/loading'
import createHydrated from '@/app/component/wrapper/hydratable'

import SignupAccount from './signup/account'
import SignupProfile from './signup/profile'

const styles = (theme) => ({
  wrap: {
    maxWidth: '400px',
    margin: 'auto',
    marginTop: 'calc(60px + 5%)',
    marginBottom: '3rem',
    overflow: 'visible',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2rem',
  },
  icon: {
    margin: 'auto',
    fontSize: '4rem !important',
    color: pathOr('', ['palette', 'primary', 'main'], theme),
  },
  button: {
    alignSelf: 'flex-end',
  },
})

class Signup extends Component {
  state = {
    step: 0,
    isCaptchaDisplayed: false,
  }

  steps = {
    0: {
      name: 'profile',
      Content: (props) => (
        <SignupProfile
          data={props.item}
          specs={props.specs.profile}
          onSubmit={(data) => {
            props.modSignupData(data)
            this.setState({
              step: 1,
            })
          }}
        />
      ),
    },
    1: {
      name: 'account',
      Content: (props) => (
        <SignupAccount
          data={props.item}
          email={props.email}
          specs={props.specs.account}
          onBack={() => {
            this.setState({
              step: 0,
            })
          }}
          onSubmit={(data) => {
            props.modSignupData(data)
            props.emailCheck({ email: data.email }).then((res) => {
              if (!(res instanceof Error)) {
                this.setState({ isCaptchaDisplayed: true })
              }
            })
          }}
        />
      ),
    },
  }

  // eslint-disable-next-line
  componentDidMount() {
    if (window.grecaptcha) {
      try {
        document.querySelector('.grecaptcha-badge').parentNode.style.display = 'block'
        // eslint-disable-next-line
      } catch (e) {}
    }
  }

  // eslint-disable-next-line
  componentWillUnmount() {
    try {
      document.querySelector('.grecaptcha-badge').parentNode.style.display = 'none'
      // eslint-disable-next-line
    } catch (e) {}
  }

  onStepBack = () => {
    this.setState({
      tab: Math.min(0, this.state.tab - 1),
    })
  }

  onCaptchaComplete = (captchaToken) => {
    const { email, emailToken, item, submit, history } = this.props

    this.setState({ isCaptchaDisplayed: false })
    submit({
      item: {
        ...item,
        email,
        emailToken,
        verifyToken: captchaToken,
      },
    }).then((res) => {
      if (!(res instanceof Error)) {
        history.push('/')
      }
    })
  }

  render() {
    const classes = withStyles.getClasses(this.props)
    const { loading } = this.props

    const { Content } = this.steps[this.state.step] || {}

    return (
      <Loading isLoading={loading || this.state.isCaptchaDisplayed}>
        <Card className={classes.wrap}>
          <CardContent className={classes.content}>
            <Icon className={classes.icon}>person</Icon>
          </CardContent>
          <Content {...this.props} />
          {this.state.isCaptchaDisplayed && (
            <div>
              <GoogleReCaptcha onVerify={(verifyToken) => this.onCaptchaComplete(verifyToken)} />
            </div>
          )}
        </Card>
      </Loading>
    )
  }
}

export default createHydrated(withStyles(Signup, styles))
