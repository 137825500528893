import { capitalize } from '@mui/material'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { CreateContactApiArg } from '@/__generated/es-api'
import { ContactValues } from '@/app/module/contacts/types'
import { createNotification } from '@/app/module/notifications/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { trimPhoneNumberPlus } from '@/app/service/util/format'
import { esApi, getErrorMessage } from '@/app/store/api'
import { useAppDispatch } from '@/app/store/redux-hooks'

export const useCreateContact = () => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const [create, res] = esApi.endpoints.createContact.useMutation()

  const createContact = useCallback(
    async (payload: ContactValues) => {
      const { firstName, lastName, fullPhoneNumber, language, ...rest } = payload
      const newContact: CreateContactApiArg['body'] = {
        firstName,
        lastName,
        fullPhoneNumber: fullPhoneNumber ? trimPhoneNumberPlus(fullPhoneNumber) : '',
        language,
      }
      const customFields = new Map<string, string>()
      Object.entries(rest).forEach(([key, value]) => {
        if (key.startsWith('customFields')) {
          const id = key.replace('customFields', '')
          customFields.set(id, value as string)
        }
      })
      newContact.customFields = Object.fromEntries(customFields)
      try {
        const created = await create({
          orgId: orgId.toString(),
          body: newContact,
        }).unwrap()
        dispatch(
          createNotification({
            'create-contact': {
              type: 'success',
              message: 'Contact saved successfully!',
            },
          }),
        )
        return created
      } catch (err) {
        const errorMessage = capitalize(getErrorMessage(err, 'Failed to save contact'))
        dispatch(
          createNotification({
            'create-contact-failure': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return undefined
      }
    },
    [orgId, create, dispatch],
  )

  return [createContact, res] as const
}
