import { Accept } from 'react-dropzone'

export const imageAcceptList: Accept = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
}

export const documentAcceptList: Accept = {
  'application/pdf': ['.pdf'],
}

export const voiceAcceptList: Accept = {
  'audio/mp3': ['.mp3'],
  'audio/wav': ['.wav'],
  'audio/ogg': ['.ogg'],
  'audio/webm': ['.webm'],
}

export const videoAcceptList: Accept = {
  'video/mp4': ['.mp4'],
}

export const imageExtensionList = ['jpg', 'jpeg', 'png']
export const documentExtensionList = ['pdf']
export const voiceExtensionList = ['wav', 'mp3', 'ogg']
export const videoExtensionList = ['mp4']
