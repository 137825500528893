import { useSelector } from 'react-redux'

import { selectOrgId } from '@/app/module/user/store/selectors'
import { esApi } from '@/app/store/api'

export const usePhoneNumbers = () => {
  const orgId = useSelector(selectOrgId)
  const res = esApi.endpoints.getOrgDids.useQuery({
    orgId: orgId.toString(),
  })

  return res
}
