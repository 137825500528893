import { green, red } from '@mui/material/colors'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Tooltip from '@/app/component/atom/tooltip'

export default function Legend() {
  return (
    <Grid container>
      <Grid item md={1}></Grid>
      <Grid item md={3}>
        <Tooltip title="For SMS Messages, this means that we successfully sent the message to the telco. For Voice Calls, this means that the contact picked up the call.">
          <div>
            <div
              style={{
                backgroundColor: green.A700,
                height: '10px',
                width: '10px',
                display: 'inline-block',
                borderRadius: '50%',
                paddingRight: '5px',
              }}
            ></div>
            <div
              style={{
                display: 'inline-block',
                paddingLeft: '5px',
              }}
            >
              <Typography>Contacts Engaged</Typography>
            </div>
          </div>
        </Tooltip>
      </Grid>
      <Grid item md={4}>
        <Tooltip title="For SMS Messages, this means that the telco said that the message was not delivered to the Contact's phone; please note that these telco notifications are often unreliable. For Voice Calls, this means that we called the contact, but the Contact did not pick up the phone.">
          <div>
            <div
              style={{
                backgroundColor: '#2f80ac',
                height: '10px',
                width: '10px',
                display: 'inline-block',
                borderRadius: '50%',
                paddingRight: '5px',
              }}
            ></div>
            <div
              style={{
                display: 'inline-block',
                paddingLeft: '5px',
              }}
            >
              <Typography>Contacts Not Engaged</Typography>
            </div>
          </div>
        </Tooltip>
      </Grid>
      <Grid item md={2}>
        <Tooltip title="For SMS Messages, this means that we were unable to send the message to the telco because of an error. For Voice Calls, this means that we did not try calling the Contact because of an error. Some common errors are that your account ran out of funds, the phone number was invalid, or there was a telco issue.">
          <div>
            <div
              style={{
                backgroundColor: red.A200,
                height: '10px',
                width: '10px',
                display: 'inline-block',
                borderRadius: '50%',
                paddingRight: '5px',
              }}
            ></div>
            <div
              style={{
                display: 'inline-block',
                paddingLeft: '5px',
              }}
            >
              <Typography>Error</Typography>
            </div>
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  )
}
