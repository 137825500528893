import { getSpeechLanguages, getTranscribeLanguages } from '@/app/module/utils/languages'
import { countries, esCountries, languages, transcribeLangs, voices } from '@/config/resources'

export default {
  languageList: {
    loading: false,
    data: languages,
    error: {},
  },
  countriesList: {
    loading: false,
    data: esCountries,
    error: {},
  },
  countriesTimezonesList: {
    loading: false,
    data: countries,
    error: {},
  },
  voiceList: {
    loading: false,
    data: voices,
    error: {},
  },
  speechLanguagesList: {
    data: getSpeechLanguages(transcribeLangs),
  },
  transcribeLanguageList: {
    data: getTranscribeLanguages(transcribeLangs),
  },
}
