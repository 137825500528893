import { FormControl, styled } from '@mui/material'
import Input from '@mui/material/Input'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import React from 'react'

import InputField from '@/app/component/atom/form/input'
import { getNumberedInputError } from '@/app/module/campaigns/helpers'
import { TimeUnit } from '@/app/module/campaigns/types'

import ErrorText from './error-text'

type Props = {
  min?: number
  name: string
  unit?: string
  value?: number
  values?: { value: string; label: string }[]
  onChange: (value: { value: number; unit: string }) => void
}

const QuestionTimerInput: React.FC<Props> = ({
  min = 1,
  name,
  unit = TimeUnit.Minute,
  value = 1,
  values = [],
  onChange,
}) => {
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [localUnit, setLocalUnit] = React.useState(unit || TimeUnit.Minute)
  const [localValue, setLocalValue] = React.useState(value.toString())

  React.useEffect(() => {
    setError(getNumberedInputError(localValue, min, undefined))
  }, [localValue, min])

  const handleNumberChange = React.useCallback(
    ({ value: newValue }: { value: string }) => {
      // the parent component has a debounced onChange, so new value won't reach this component after a while, that's why we need to make it uncontrolled
      const numberValue = Math.max(min, Number(newValue))
      setLocalValue(numberValue.toString())
      onChange({ value: numberValue, unit: localUnit || TimeUnit.Minute })
    },
    [localUnit, min, onChange],
  )

  const handleUnitChange = React.useCallback(
    (e: SelectChangeEvent<string>) => {
      setLocalUnit(e.target.value)
      onChange({ value: Number(localValue), unit: e.target.value })
    },
    [localValue, onChange],
  )

  return (
    <Container>
      <NumberContainer>
        <InputField
          data-testid={`${name}-input`}
          InputProps={{
            endAdornment: (
              <div>
                <FormControl>
                  <Select
                    data-testid={`${name}-unit`}
                    variant="standard"
                    style={{ height: '21px' }}
                    disableUnderline
                    value={localUnit}
                    onChange={handleUnitChange}
                    input={<Input disableUnderline={true} />}
                    renderValue={(selectedValue) => (
                      <Typography color="textSecondary" variant="caption" component="span">
                        {`${selectedValue}${value > 1 ? 's' : ''}`}
                      </Typography>
                    )}
                  >
                    {values.map(({ value: itemValue, label: itemName }) => (
                      <MenuItem key={itemValue} value={itemValue}>
                        {itemName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ),
          }}
          error={error ? true : undefined}
          name="timeout-number-input"
          inputProps={{
            min,
            style: {
              padding: '2px 6px',
            },
          }}
          type="number"
          value={localValue}
          variant="outlined"
          onChange={handleNumberChange}
        />
      </NumberContainer>

      <div style={{ width: 4 }} />
      {error && <ErrorText>{error}</ErrorText>}
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
})

const NumberContainer = styled('div')({
  width: '120px',

  '& .MuiFormControl-root > div': {
    marginBottom: '0 !important',
  },

  '& .MuiInputBase-root': {
    padding: '0',
  },
})

export default QuestionTimerInput
