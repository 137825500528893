import { Button, Icon, Menu, MenuItem, Typography } from '@mui/material'
import React from 'react'

import IconText from '@/app/component/layout/icon-text'
import { SelectOption } from '@/app/types'

export type ActionMenuOption = SelectOption<string> & {
  disabled: boolean
  icon: string
  onSelect: () => void
}

type Props = {
  buttonLabel: string
  buttonStyle?: React.CSSProperties
  disabled: boolean
  open: boolean
  options: ActionMenuOption[]
  onClose: () => void
  onOpen: () => void
}

const ActionMenu: React.FC<Props> = ({ buttonLabel, buttonStyle, disabled, open, options, onClose, onOpen }) => {
  const anchorElRef = React.useRef<HTMLButtonElement>(null)

  return (
    <>
      <Button
        className="subscription-action-menu-btn"
        disabled={disabled}
        ref={anchorElRef}
        style={buttonStyle}
        variant="outlined"
        onClick={onOpen}
      >
        <IconText>
          <Icon>keyboard_arrow_down</Icon>
          <Typography>{buttonLabel}</Typography>
        </IconText>
      </Button>
      <Menu anchorEl={anchorElRef.current} open={open} onClose={onClose}>
        {options.map((option, index) => (
          <MenuItem
            className="subscription-action-menu-item"
            key={index}
            disabled={option.disabled}
            onClick={() => {
              onClose()
              option.onSelect()
            }}
          >
            <IconText>
              <Icon>{option.icon}</Icon>
              <Typography>{option.label}</Typography>
            </IconText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default ActionMenu
