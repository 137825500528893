import {
  LocalOnInvalidReply,
  LocalOnRetriesExhausted,
  LocalOnTimeout,
  OnInvalidReply,
  OnRetriesExhausted,
  OnTimeout,
  RepeatQuestion,
  SpeechSettings,
} from '@/app/module/campaigns/types'
import { unwrapActions, wrapActions } from '@/app/module/campaigns/utils/actions/wrap'

export const isFailureEnabled = (failure: OnRetriesExhausted) => !!(failure.enabled || failure.actions?.length)

export const isInvalidEnabled = (invalid: OnInvalidReply) => !!(invalid.enabled || invalid.actions?.length)

export const isRepeatQuestionEnabled = (repeatQuestion: RepeatQuestion) =>
  !!(repeatQuestion.enabled || repeatQuestion.reply)

export const isTimeoutEnabled = (timeout: OnTimeout, speechSetting?: SpeechSettings) =>
  !!(
    timeout.enabled ||
    timeout.actions?.length ||
    timeout.timeoutSeconds ||
    speechSetting?.timeoutSeconds ||
    !!timeout.timeout
  )

export const fromRepeatQuestion = (repeatQuestion: RepeatQuestion): RepeatQuestion => ({
  ...repeatQuestion,
  enabled: isRepeatQuestionEnabled(repeatQuestion),
})

export const fromFailure = (failure: OnRetriesExhausted): LocalOnRetriesExhausted => ({
  ...failure,
  actions: unwrapActions(failure.actions || []),
  enabled: isFailureEnabled(failure),
})

export const toFailure = ({ enabled, ...failure }: LocalOnRetriesExhausted): OnRetriesExhausted => ({
  ...failure,
  enabled,
  actions: enabled ? wrapActions(failure.actions || []) : [],
})

export const fromInvalid = (invalid: OnInvalidReply): LocalOnInvalidReply => ({
  ...invalid,
  actions: unwrapActions(invalid.actions || []),
  enabled: isInvalidEnabled(invalid),
})

export const toInvalid = ({ enabled, ...invalid }: LocalOnInvalidReply): OnInvalidReply => ({
  ...invalid,
  enabled,
  actions: enabled ? wrapActions(invalid.actions || []) : [],
})

export const fromTimeout = (timeout: OnTimeout, speechSettings?: SpeechSettings): LocalOnTimeout => {
  if (!speechSettings) {
    return {
      ...timeout,
      enabled: isTimeoutEnabled(timeout),
      actions: unwrapActions(timeout.actions || []),
    }
  }
  return {
    ...timeout,
    enabled: isTimeoutEnabled(timeout, speechSettings),
    actions: unwrapActions(timeout.actions || []),
    timeoutSeconds: speechSettings.timeoutSeconds,
  }
}

export const toTimeout = ({ enabled, ...timeout }: LocalOnTimeout): OnTimeout => ({
  ...timeout,
  enabled,
  actions: enabled ? wrapActions(timeout.actions || []) : [],
})

export const toRepeatQuestion = ({ enabled, ...repeatQuestion }: RepeatQuestion): RepeatQuestion => ({
  ...repeatQuestion,
  enabled,
  reply: enabled ? repeatQuestion.reply : '',
})
