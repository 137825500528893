import { map, path, pathOr } from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { getCampaignItem } from '@/app/module/campaigns/store/actions'
import { toggleCallDialog } from '@/app/module/user/store/actions'
import { selectOrgId, selectUserId, selectUserTimezone } from '@/app/module/user/store/selectors'
import config from '@/config'

import Item from './components/item'
import Subs from './components/subs'
import {
  closeCATISurvey,
  getCATIItem,
  getCATIStep,
  getOrgSubs,
  getSubDetails,
  navigateOrgSubs,
  setCATISubsPageSize,
  setSubStatus,
} from './store/actions'
import { selectHeaders, selectSubsContactValues } from './store/selectors'

export const pageSizeValues = compose(
  map((val) => ({ label: val, value: val })),
  path(['modules', 'cati', 'pageSize', 'values']),
)(config)

const pageSizeDefault = path(['modules', 'cati', 'pageSize', 'default'], config)

const catiItemSelector = ({ auth, cati, user }) => ({
  token: path(['login', 'data', 'token'], auth),
  orgId: selectOrgId({ user }),
  campaign: pathOr({}, ['item', 'data', 'campaign'], cati),
  contact: pathOr({}, ['item', 'data', 'contact'], cati),
  done: pathOr(false, ['item', 'data', 'done'], cati),
  loading: cati.item.loading,
  error: cati.item.error,
  data: cati.item.data,
})

const catiItemDispatcher = (dispatch) =>
  bindActionCreators(
    {
      getCATIItem,
      getCATIStep,
      getCampaignItem,
      toggleCallDialog,
      getSubDetails,
      setSubStatus,
      closeCATISurvey,
    },
    dispatch,
  )

export const CATIItem = connect(catiItemSelector, catiItemDispatcher)(Item)

const catiSubsSelector = ({ auth, user, cati, utils }) => ({
  token: path(['login', 'data', 'token'], auth),
  orgId: selectOrgId({ user }),
  userId: selectUserId({ user }),
  loading: !cati.subs.initialized || cati.subs.loading,
  values: selectSubsContactValues({ cati }),
  headers: selectHeaders(),
  total: pathOr(0, ['subs', 'data', 'total'], cati),
  pageSizeValues,
  size: pathOr(pageSizeDefault, ['subs', 'data', 'size'], cati),
  timezone: selectUserTimezone({ user, utils }),
})

const catiSubsDispatcher = (dispatch) =>
  bindActionCreators(
    {
      getOrgSubs,
      setPageSize: setCATISubsPageSize,
      navigate: navigateOrgSubs,
    },
    dispatch,
  )

export const CATISubs = connect(catiSubsSelector, catiSubsDispatcher)(Subs)
