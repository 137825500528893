import {
  changeActionsLang,
  changeSpeechSettingsLang,
  LanguageConfig,
  processAudio,
  processText,
  processWhatsapp,
} from '@/app/module/campaigns/language-helpers'
import {
  ActionsPart,
  APIPart,
  CATIMessagePart,
  CATIQuestionPart,
  ConditionActions,
  OnAnswerType,
  OnInvalidReply,
  OnRetriesExhausted,
  OnTimeout,
  OpenEnded,
  PartTypes,
  SMSMessagePart,
  SMSQuestionPart,
  Spoken,
  TopupPart,
  VoiceMessagePart,
  VoiceQuestionPart,
  WhatsAppMessagePart,
  WhatsAppQuestionPart,
} from '@/app/module/campaigns/types'

export function changeOnAnswerLang(onAnswer: OnAnswerType[], languageConfig: LanguageConfig): OnAnswerType[] {
  return onAnswer.map((answer) => changeActionsLang(answer, languageConfig))
}

export function changeOpenEndedActionsLang(openEnded: OpenEnded, languageConfig: LanguageConfig): OpenEnded {
  const processedActions = openEnded.actions
    ? changeActionsLang({ actions: openEnded.actions }, languageConfig).actions
    : undefined

  const processedCategories = openEnded.categories
    ? openEnded.categories.map((category) => ({
        ...category,
        actions: category.actions ? changeActionsLang({ actions: category.actions }, languageConfig).actions : [],
      }))
    : undefined

  return {
    ...openEnded,
    actions: processedActions,
    categories: processedCategories,
  }
}

export function changeSpokenLang(spoken: Spoken, languageConfig: LanguageConfig): Spoken {
  return changeActionsLang(spoken, languageConfig) as Spoken
}

export function changeOnInvalidReplyLang(
  onInvalidReply: OnInvalidReply,
  languageConfig: LanguageConfig,
): OnInvalidReply {
  return changeActionsLang(onInvalidReply, languageConfig)
}

export function changeOnTimeoutLang(onTimeout: OnTimeout, languageConfig: LanguageConfig): OnTimeout {
  return changeActionsLang(onTimeout, languageConfig)
}

export function changeOnRetriesExhaustedLang(
  onRetriesExhausted: OnRetriesExhausted,
  languageConfig: LanguageConfig,
): OnRetriesExhausted {
  return changeActionsLang(onRetriesExhausted, languageConfig)
}

export function changeCATIMessagePartLang(part: CATIMessagePart, languageConfig: LanguageConfig): CATIMessagePart {
  return {
    ...part,
    [PartTypes.CATIMessage]: {
      ...part[PartTypes.CATIMessage],
      message: processText(part[PartTypes.CATIMessage].message, languageConfig),
    },
  }
}

export function changeCATIQuestionPartLang(part: CATIQuestionPart, languageConfig: LanguageConfig): CATIQuestionPart {
  const processedPart = {
    ...part,
    [PartTypes.CATIQuestion]: {
      ...part[PartTypes.CATIQuestion],
      message: processText(part[PartTypes.CATIQuestion].message, languageConfig),
      onAnswer: changeOnAnswerLang(part[PartTypes.CATIQuestion].onAnswer, languageConfig),
    },
  }

  if (part[PartTypes.CATIQuestion].openEnded) {
    processedPart[PartTypes.CATIQuestion].openEnded = changeOpenEndedActionsLang(
      part[PartTypes.CATIQuestion].openEnded,
      languageConfig,
    )
  }

  return processedPart
}

export function changeSMSMessagePartLang(part: SMSMessagePart, languageConfig: LanguageConfig): SMSMessagePart {
  return {
    ...part,
    [PartTypes.SMSMessage]: {
      ...part[PartTypes.SMSMessage],
      message: processText(part[PartTypes.SMSMessage].message, languageConfig),
    },
  }
}

export function changeSMSQuestionPartLang(part: SMSQuestionPart, languageConfig: LanguageConfig): SMSQuestionPart {
  const processedPart = {
    ...part,
    [PartTypes.SMSQuestion]: {
      ...part[PartTypes.SMSQuestion],
      message: processText(part[PartTypes.SMSQuestion].message, languageConfig),
      onAnswer: changeOnAnswerLang(part[PartTypes.SMSQuestion].onAnswer, languageConfig),
      onInvalidReply: changeOnInvalidReplyLang(part[PartTypes.SMSQuestion].onInvalidReply, languageConfig),
      onRetriesExhausted: changeOnRetriesExhaustedLang(part[PartTypes.SMSQuestion].onRetriesExhausted, languageConfig),
      onTimeout: changeOnTimeoutLang(part[PartTypes.SMSQuestion].onTimeout, languageConfig),
    },
  }

  if (part[PartTypes.SMSQuestion].openEnded) {
    processedPart[PartTypes.SMSQuestion].openEnded = changeOpenEndedActionsLang(
      part[PartTypes.SMSQuestion].openEnded,
      languageConfig,
    )
  }

  return processedPart
}

export function changeVoiceMessagePartLang(part: VoiceMessagePart, languageConfig: LanguageConfig): VoiceMessagePart {
  return {
    ...part,
    [PartTypes.VoiceMessage]: {
      ...part[PartTypes.VoiceMessage],
      audio: processAudio(part[PartTypes.VoiceMessage].audio, languageConfig),
    },
  }
}

export function changeVoiceQuestionPartLang(
  part: VoiceQuestionPart,
  languageConfig: LanguageConfig,
): VoiceQuestionPart {
  const partWithSpeechSettings = changeSpeechSettingsLang(part, languageConfig) as VoiceQuestionPart

  const processedPart = {
    ...partWithSpeechSettings,
    [PartTypes.VoiceQuestion]: {
      ...partWithSpeechSettings[PartTypes.VoiceQuestion],
      audio: processAudio(partWithSpeechSettings[PartTypes.VoiceQuestion].audio, languageConfig),
      onAnswer: changeOnAnswerLang(partWithSpeechSettings[PartTypes.VoiceQuestion].onAnswer, languageConfig),
      onInvalidReply: changeOnInvalidReplyLang(
        partWithSpeechSettings[PartTypes.VoiceQuestion].onInvalidReply,
        languageConfig,
      ),
      onRetriesExhausted: changeOnRetriesExhaustedLang(
        partWithSpeechSettings[PartTypes.VoiceQuestion].onRetriesExhausted,
        languageConfig,
      ),
      onTimeout: changeOnTimeoutLang(partWithSpeechSettings[PartTypes.VoiceQuestion].onTimeout, languageConfig),
    },
  }

  if (partWithSpeechSettings[PartTypes.VoiceQuestion].spoken) {
    processedPart[PartTypes.VoiceQuestion].spoken = changeSpokenLang(
      partWithSpeechSettings[PartTypes.VoiceQuestion].spoken,
      languageConfig,
    )
  }

  if (partWithSpeechSettings[PartTypes.VoiceQuestion].processingReply) {
    processedPart[PartTypes.VoiceQuestion].processingReply = processAudio(
      partWithSpeechSettings[PartTypes.VoiceQuestion].processingReply,
      languageConfig,
    )
  }

  return processedPart
}

export function changeWhatsAppMessagePartLang(
  part: WhatsAppMessagePart,
  languageConfig: LanguageConfig,
): WhatsAppMessagePart {
  return {
    ...part,
    [PartTypes.WhatsAppMessage]: {
      ...part[PartTypes.WhatsAppMessage],
      message: processWhatsapp(part[PartTypes.WhatsAppMessage].message, languageConfig),
    },
  }
}

export function changeWhatsAppQuestionPartLang(
  part: WhatsAppQuestionPart,
  languageConfig: LanguageConfig,
): WhatsAppQuestionPart {
  const processedPart = {
    ...part,
    [PartTypes.WhatsAppQuestion]: {
      ...part[PartTypes.WhatsAppQuestion],
      message: processWhatsapp(part[PartTypes.WhatsAppQuestion].message, languageConfig),
      onAnswer: changeOnAnswerLang(part[PartTypes.WhatsAppQuestion].onAnswer, languageConfig),
      onInvalidReply: changeOnInvalidReplyLang(part[PartTypes.WhatsAppQuestion].onInvalidReply, languageConfig),
      onRetriesExhausted: changeOnRetriesExhaustedLang(
        part[PartTypes.WhatsAppQuestion].onRetriesExhausted,
        languageConfig,
      ),
      onTimeout: changeOnTimeoutLang(part[PartTypes.WhatsAppQuestion].onTimeout, languageConfig),
    },
  }

  if (part[PartTypes.WhatsAppQuestion].openEnded) {
    processedPart[PartTypes.WhatsAppQuestion].openEnded = changeOpenEndedActionsLang(
      part[PartTypes.WhatsAppQuestion].openEnded,
      languageConfig,
    )
  }

  if (part[PartTypes.WhatsAppQuestion].onButtons?.buttons) {
    const processedButtons = part[PartTypes.WhatsAppQuestion].onButtons.buttons.map((button) => ({
      ...button,
      actions: button.actions ? changeActionsLang({ actions: button.actions }, languageConfig).actions || [] : [],
    }))

    processedPart[PartTypes.WhatsAppQuestion].onButtons = {
      buttons: processedButtons,
    }
  }

  if (part[PartTypes.WhatsAppQuestion].onList?.items) {
    const processedItems = part[PartTypes.WhatsAppQuestion].onList.items.map((listItem) => ({
      ...listItem,
      actions: listItem.actions ? changeActionsLang({ actions: listItem.actions }, languageConfig).actions || [] : [],
    }))

    processedPart[PartTypes.WhatsAppQuestion].onList = {
      ...part[PartTypes.WhatsAppQuestion].onList,
      items: processedItems,
    }
  }

  return processedPart
}

export function changeActionsPartLang(part: ActionsPart, languageConfig: LanguageConfig): ActionsPart {
  const processedActions = part[PartTypes.ActionsOnly].actions.map((action) => {
    const processed = changeActionsLang({ actions: [action] }, languageConfig)
    return processed.actions?.[0] || action
  })

  return {
    ...part,
    [PartTypes.ActionsOnly]: {
      ...part[PartTypes.ActionsOnly],
      actions: processedActions,
    },
  }
}

export function changeAPIPartLang(part: APIPart, languageConfig: LanguageConfig): APIPart {
  return {
    ...part,
    [PartTypes.ApiCall]: {
      ...part[PartTypes.ApiCall],
      onSuccess: changeActionsLang({ actions: part[PartTypes.ApiCall].onSuccess }, languageConfig).actions,
      onError: changeActionsLang({ actions: part[PartTypes.ApiCall].onError }, languageConfig).actions,
    },
  }
}

export function changeTopupPartLang(part: TopupPart, languageConfig: LanguageConfig): TopupPart {
  const onSuccess = part[PartTypes.Topup].onSuccess
    ? changeActionsLang({ actions: part[PartTypes.Topup].onSuccess }, languageConfig).actions
    : undefined

  const onError = part[PartTypes.Topup].onError
    ? changeActionsLang({ actions: part[PartTypes.Topup].onError }, languageConfig).actions
    : undefined

  return {
    ...part,
    [PartTypes.Topup]: {
      ...part[PartTypes.Topup],
      onSuccess,
      onError,
    },
  }
}

export function changeConditionActionsLang(actions: ConditionActions[], languageConfig: LanguageConfig) {
  if (!actions) return []

  return actions.map((action) => ({
    ...action,
    ...changeActionsLang({ actions: action.actions || [] }, languageConfig),
  }))
}
