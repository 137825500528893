import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { MAX_AMOUNT, MAX_THRESHOLD, MIN_AMOUNT, MIN_THRESHOLD } from '@/app/module/payments/definitions'

const useStyles = makeStyles()(() => ({
  autoRecharge: {
    margin: '10px 0px 5px',
  },
  closeButton: {
    float: 'right',
  },
}))

export default function AutoRecharge({ autoRecharge: initAutoRecharge = {}, onClose, onSuccess }) {
  const { classes } = useStyles()
  const [autoRecharge, setAutoRecharge] = React.useState(false)
  const [autoRechargeAmount, setAutoRechargeAmount] = React.useState(initAutoRecharge.autoRechargeAmount)
  const [autoRechargeThreshold, setAutoRechargeThreshold] = React.useState(initAutoRecharge.autoRechargeThreshold)

  React.useEffect(() => {
    setAutoRecharge(initAutoRecharge.autoRecharge)
    setAutoRechargeAmount(initAutoRecharge.autoRechargeAmount || MIN_AMOUNT)
    setAutoRechargeThreshold(initAutoRecharge.autoRechargeThreshold || MIN_THRESHOLD)
  }, [initAutoRecharge])

  return (
    <CustomDialog open={true} onClose={onClose} aria-labelledby="auto-recharge-title" fullWidth={true}>
      <DialogTitle id="auto-recharge-title">
        <span>Auto Recharge</span>
        <IconButton className={classes.closeButton} variant="contained" onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" color="textSecondary">
          Set automatic recharge amounts for your organization balance
        </Typography>
        <FormControl variant="standard" component="fieldset">
          <FormGroup aria-label="position" row className={classes.autoRecharge}>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={autoRecharge} onChange={() => setAutoRecharge(!autoRecharge)} />
              }
              label="Auto Recharge"
            />
          </FormGroup>
        </FormControl>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div>
              <Typography color="textSecondary" variant="subtitle1" style={{ marginBottom: '10px' }}>
                Recharge at least:
              </Typography>
              <TextField
                style={{ marginBottom: '10px' }}
                disabled={!autoRecharge}
                fullWidth
                error={autoRechargeAmount < MIN_AMOUNT || autoRechargeAmount > MAX_AMOUNT}
                variant="outlined"
                label="Amount"
                id="auto-recharge-amount"
                value={autoRechargeAmount}
                onChange={(e) => setAutoRechargeAmount(e.currentTarget.value)}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                placeholder={20}
                size="small"
                type="Number"
                inputProps={{
                  min: MIN_AMOUNT,
                  max: MAX_AMOUNT,
                }}
              />
              <Typography color="textSecondary" variant="caption">
                Minimum Amount ${MIN_AMOUNT.toFixed(2)}
                <br />
                Maximum Amount ${MAX_AMOUNT.toFixed(2)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <Typography color="textSecondary" variant="subtitle1" style={{ marginBottom: '10px' }}>
                When balance falls below:
              </Typography>
              <TextField
                style={{ marginBottom: '10px' }}
                disabled={!autoRecharge}
                fullWidth
                error={autoRechargeThreshold < MIN_THRESHOLD || autoRechargeThreshold > MAX_THRESHOLD}
                variant="outlined"
                label="Threshold"
                id="auto-recharge-threshold"
                value={autoRechargeThreshold}
                onChange={(e) => setAutoRechargeThreshold(e.currentTarget.value)}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                placeholder={10}
                size="small"
                type="Number"
                inputProps={{
                  min: MIN_THRESHOLD,
                  max: MAX_THRESHOLD,
                }}
              />
              <Typography color="textSecondary" variant="caption">
                Minimum Amount ${MIN_THRESHOLD.toFixed(2)}
                <br />
                Maximum Amount ${MAX_THRESHOLD.toFixed(2)}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={
            autoRecharge &&
            (autoRechargeAmount < MIN_AMOUNT ||
              autoRechargeAmount > MAX_AMOUNT ||
              autoRechargeThreshold < MIN_THRESHOLD ||
              autoRechargeThreshold > MAX_THRESHOLD)
          }
          onClick={() => {
            onSuccess({
              autoRecharge,
              autoRechargeThreshold: parseFloat(autoRechargeThreshold),
              autoRechargeAmount: parseFloat(autoRechargeAmount),
            })
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}
