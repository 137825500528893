import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import React from 'react'
import { DropzoneRef } from 'react-dropzone'

import Uploader from '@/app/component/atom/upload'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { FileUploadType } from '@/app/types'

import { Caption, DocumentPlaceholder, EditOverlay, RelativeContainer, UploadContainer } from './common'
import { documentAcceptList, documentExtensionList } from './media-types'

type Props = {
  document: string
  enableMediaCaption: boolean
  files: Record<string, any>
  personalizationList: PersonalizationType
  text: string

  changeHandler: (value: { document?: string; text?: string }) => void
  saveHandler: (file: FileUploadType) => Promise<void>
}

const DocumentMessage: React.FC<Props> = ({
  document,
  enableMediaCaption,
  files,
  personalizationList,
  text,

  changeHandler,
  saveHandler,
}) => {
  const documentUploadRef = React.useRef<DropzoneRef>(null)

  return (
    <>
      <UploadContainer style={{ display: document ? 'none' : 'flex' }}>
        <RelativeContainer>
          <Uploader
            accept={documentAcceptList}
            currentFile={document}
            extensions={documentExtensionList}
            files={files}
            namespace="whatsapp-document"
            uploadRef={documentUploadRef}
            onAccept={({ file }: { file: File }) => {
              saveHandler({
                file,
                filename: file.name,
                type: 'document',
              })
            }}
          />
        </RelativeContainer>
      </UploadContainer>
      <UploadContainer
        style={{ display: document ? 'flex' : 'none' }}
        onClick={() => documentUploadRef.current?.open()}
      >
        <RelativeContainer>
          <DocumentPlaceholder alt="WhatsApp PDF" src="/static/pdf-icon.png" />
          <div style={{ textAlign: 'center' }}>
            <Typography>{document ? document.split('/').pop() : ''}</Typography>
          </div>
          <EditOverlay>
            <Button variant="contained" size="small" color="secondary" startIcon={<Icon>edit</Icon>}>
              Edit
            </Button>
          </EditOverlay>
        </RelativeContainer>
      </UploadContainer>
      <Caption
        enableMediaCaption={enableMediaCaption}
        personalizationList={personalizationList}
        text={text}
        onChange={(updatedValue) => {
          changeHandler({
            text: updatedValue,
          })
        }}
      />
    </>
  )
}

export default DocumentMessage
export type { Props as DocumentMessageProps }
