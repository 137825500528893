import MUICheckbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Icon from '@mui/material/Icon'
import React from 'react'

import { ChangeEvent } from '@/app/types'

import FormControl from './form-control'

type Props = {
  checkedIcon?: string
  disabledText?: string
  editable: boolean
  error?: string
  name: string
  label: React.ReactNode
  value: string
  onChange: (e: ChangeEvent<string>) => void
}

const Checkbox: React.FC<Props> = ({
  disabledText,
  name,
  label,
  value,
  editable,
  error = '',
  checkedIcon = 'check_box',
  onChange,
}) => (
  <FormControl error={error} info={!editable ? disabledText : undefined}>
    <div>
      <FormControlLabel
        name={`${name}-display`}
        label={label}
        disabled={!editable}
        control={
          <MUICheckbox
            color="primary"
            value={value}
            checked={value === 'true'}
            checkedIcon={checkedIcon && <Icon>{checkedIcon}</Icon>}
            onChange={(e) => {
              e.persist?.()
              onChange({
                name,
                value: e.target.checked.toString(),
              })
            }}
          />
        }
      />
      <FormHelperText>{error}</FormHelperText>
    </div>
  </FormControl>
)

export default Checkbox
