import { v1 as uuid } from 'uuid'

import { cannot } from '@/app/helpers'
import {
  defaultOnAnswerCATINoActions,
  defaultOnAnswerTextNoActions,
  defaultOnAnswerVoiceNoActions,
} from '@/app/module/campaigns/component/item/steps/content/types/multimessage/content'
import { getCampaignType } from '@/app/module/campaigns/helpers'
import ReminderCampaignClass from '@/app/module/campaigns/models/classes/reminder-campaign.class'
import {
  ActionMessage,
  APIMessage,
  CampaignTemplateType,
  CATIMessage,
  CATIQuestion,
  DelayState,
  OnRetriesExhausted,
  PartFamily,
  ReminderActionMessage,
  ReminderMessage,
  ReminderSMSMessage,
  ReminderSMSQuestion,
  SMSMessage,
  SMSQuestion,
  TimeUnit,
  Message as TMessage,
  TopupMessage,
  VoiceMessage,
  VoiceQuestion,
  WhatsAppMessage,
  WhatsAppQuestion,
} from '@/app/module/campaigns/types'

const getEmptyDelta = (timezone: string, nextIndex: number): DelayState => ({
  country: '',
  dayOfWeek: '',
  daysOfWeek: {},
  time: '',
  timezone,
  timezones: [],
  unit: !nextIndex ? TimeUnit.Immediate : TimeUnit.Day,
  value: !nextIndex ? 0 : 1,
  valueError: '',
})

export const getDefaultFailureAction = (campaignType: CampaignTemplateType): OnRetriesExhausted => {
  switch (campaignType) {
    case CampaignTemplateType.CATI:
    case CampaignTemplateType.Drip:
    case CampaignTemplateType.Reminder:
    case CampaignTemplateType.SMSBlast:
      return {
        actions: [],
      }

    case CampaignTemplateType.SMSSurvey:
    case CampaignTemplateType.WhatsAppSurvey: {
      return {
        actions: [
          {
            id: uuid(),
            finishFlow: {},
          },
        ],
      }
    }

    case CampaignTemplateType.VoiceBlast:
    case CampaignTemplateType.VoiceSurvey: {
      return {
        actions: [
          {
            id: uuid(),
            hangup: {},
          },
        ],
      }
    }

    default:
      return cannot(campaignType)
  }
}

export function getEmptyCatiMessage(campaignType: CampaignTemplateType): CATIMessage {
  if (campaignType === CampaignTemplateType.CATI) {
    return {
      id: uuid(),
      changed: false,
      type: PartFamily.CATI,
      hasQuestion: false,
      message: {
        text: '',
      },
    }
  }
  throw new Error(`Invalid campaign type. ${campaignType} doesn't support CATI messages`)
}

export function getEmptySMSMessage(
  campaignType: CampaignTemplateType,
  timezone: string,
  nextIndex: number,
): SMSMessage | ReminderSMSMessage {
  switch (campaignType) {
    case CampaignTemplateType.SMSBlast:
    case CampaignTemplateType.SMSSurvey: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.SMS,
        hasQuestion: false,
        message: {
          text: '',
        },
      }
    }
    case CampaignTemplateType.Drip: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.SMS,
        hasQuestion: false,
        message: {
          text: '',
        },
        delta: getEmptyDelta(timezone, nextIndex),
      }
    }
    case CampaignTemplateType.Reminder: {
      return ReminderCampaignClass.createNewMessage({
        contentType: 'sms-m',
        timezone,
      })
    }
    default: {
      throw new Error(`Invalid campaign type. ${getCampaignType({ type: campaignType })} doesn't support SMS messages`)
    }
  }
}

export function getEmptyCATIMessage(campaignType: CampaignTemplateType): CATIMessage {
  switch (campaignType) {
    case CampaignTemplateType.CATI: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.CATI,
        hasQuestion: false,
        message: {
          text: '',
        },
      }
    }
    default: {
      throw new Error(`Invalid campaign type. ${getCampaignType({ type: campaignType })} doesn't support CATI messages`)
    }
  }
}

export function getEmptySMSQuestion(
  campaignType: CampaignTemplateType,
  timezone: string,
  nextIndex: number,
): SMSQuestion | ReminderSMSQuestion {
  switch (campaignType) {
    case CampaignTemplateType.SMSSurvey: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.SMS,
        hasQuestion: true,
        useAi: false,
        onAnswer: defaultOnAnswerTextNoActions,
        retries: 1,
        onTimeout: {
          actions: [],
        },
        onInvalidReply: {
          actions: [],
        },
        onRetriesExhausted: getDefaultFailureAction(campaignType),
        openEnded: undefined,
        message: {
          text: '',
        },
      }
    }
    case CampaignTemplateType.Drip: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.SMS,
        hasQuestion: true,
        useAi: false,
        onAnswer: defaultOnAnswerTextNoActions,
        retries: 1,
        onTimeout: {
          actions: [],
        },
        onInvalidReply: {
          actions: [],
        },
        onRetriesExhausted: getDefaultFailureAction(campaignType),
        openEnded: undefined,
        message: {
          text: '',
        },
        delta: getEmptyDelta(timezone, nextIndex),
      }
    }
    case CampaignTemplateType.Reminder: {
      return ReminderCampaignClass.createNewMessage({
        contentType: 'sms-q',
        timezone,
      })
    }
    default: {
      throw new Error(`Invalid campaign type. ${getCampaignType({ type: campaignType })} doesn't support SMS questions`)
    }
  }
}

export function getEmptyCATIQuestion(campaignType: CampaignTemplateType): CATIQuestion {
  switch (campaignType) {
    case CampaignTemplateType.CATI: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.CATI,
        hasQuestion: true,
        onAnswer: defaultOnAnswerCATINoActions,
        message: {
          text: '',
        },
      }
    }
    default: {
      throw new Error(
        `Invalid campaign type. ${getCampaignType({ type: campaignType })} doesn't support CATI questions`,
      )
    }
  }
}

export function getEmptyVoiceMessage(
  campaignType: CampaignTemplateType,
  timezone: string,
  nextIndex: number,
): VoiceMessage {
  switch (campaignType) {
    case CampaignTemplateType.VoiceBlast:
    case CampaignTemplateType.VoiceSurvey: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.Voice,
        hasQuestion: false,
        audio: {},
      }
    }
    case CampaignTemplateType.Drip: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.Voice,
        hasQuestion: false,
        audio: {},
        delta: getEmptyDelta(timezone, nextIndex),
      }
    }
    default: {
      throw new Error(
        `Invalid campaign type. ${getCampaignType({ type: campaignType })} doesn't support voice messages`,
      )
    }
  }
}

export function getEmptyVoiceQuestion(
  campaignType: CampaignTemplateType,
  timezone: string,
  nextIndex: number,
): VoiceQuestion {
  switch (campaignType) {
    case CampaignTemplateType.VoiceBlast:
    case CampaignTemplateType.VoiceSurvey: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.Voice,
        hasQuestion: true,
        onAnswer: defaultOnAnswerVoiceNoActions,
        keypressAfterAudio: false,
        repeatQuestion: {
          reply: '',
        },
        retries: 1,
        onTimeout: {
          actions: [],
        },
        onInvalidReply: {
          actions: [],
        },
        onRetriesExhausted: getDefaultFailureAction(campaignType),
        audio: {},
        useAi: false,
      }
    }
    case CampaignTemplateType.Drip: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.Voice,
        hasQuestion: true,
        onAnswer: defaultOnAnswerVoiceNoActions,
        keypressAfterAudio: false,
        repeatQuestion: {
          reply: '',
        },
        retries: 1,
        onTimeout: {
          actions: [],
        },
        onInvalidReply: {
          actions: [],
        },
        onRetriesExhausted: getDefaultFailureAction(campaignType),
        audio: {},
        delta: getEmptyDelta(timezone, nextIndex),
        useAi: false,
      }
    }
    default: {
      throw new Error(
        `Invalid campaign type. ${getCampaignType({ type: campaignType })} doesn't support Voice questions`,
      )
    }
  }
}

export function getEmptyWhatsAppMessage(
  campaignType: CampaignTemplateType,
  timezone: string,
  nextIndex: number,
): WhatsAppMessage {
  switch (campaignType) {
    case CampaignTemplateType.WhatsAppSurvey: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.WhatsApp,
        hasQuestion: false,
        message: {
          text: '',
        },
      }
    }
    case CampaignTemplateType.Drip: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.WhatsApp,
        hasQuestion: false,
        message: {
          text: '',
        },
        delta: getEmptyDelta(timezone, nextIndex),
      }
    }
    default: {
      throw new Error(
        `Invalid campaign type. ${getCampaignType({ type: campaignType })} doesn't support Whatsapp messages`,
      )
    }
  }
}

export function getEmptyWhatsAppQuestion(
  campaignType: CampaignTemplateType,
  timezone: string,
  nextIndex: number,
): WhatsAppQuestion {
  switch (campaignType) {
    case CampaignTemplateType.WhatsAppSurvey: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.WhatsApp,
        hasQuestion: true,
        useAi: false,
        transcribe: false,
        onAnswer: [],
        onButtons: {
          buttons: [
            {
              id: uuid(),
              actions: [],
              label: '',
              title: 'yes',
            },
            {
              id: uuid(),
              actions: [],
              label: '',
              title: 'no',
            },
          ],
        },
        retries: 1,
        onTimeout: {
          actions: [],
        },
        onInvalidReply: {
          actions: [],
        },
        onRetriesExhausted: getDefaultFailureAction(campaignType),
        openEnded: undefined,
        message: {
          text: '',
        },
      }
    }
    case CampaignTemplateType.Drip: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.WhatsApp,
        hasQuestion: true,
        useAi: false,
        transcribe: false,
        onAnswer: [],
        onButtons: {
          buttons: [
            {
              id: uuid(),
              actions: [],
              label: '',
              title: 'yes',
            },
            {
              id: uuid(),
              actions: [],
              label: '',
              title: 'no',
            },
          ],
        },
        retries: 1,
        onTimeout: {
          actions: [],
        },
        onInvalidReply: {
          actions: [],
        },
        onRetriesExhausted: getDefaultFailureAction(campaignType),
        openEnded: undefined,
        message: {
          text: '',
        },
        delta: getEmptyDelta(timezone, nextIndex),
      }
    }
    default: {
      throw new Error(
        `Invalid campaign type. ${getCampaignType({ type: campaignType })} doesn't support Whatsapp questions`,
      )
    }
  }
}

export function getEmptyActionsMessage(
  campaignType: CampaignTemplateType,
  timezone: string,
  nextIndex: number,
): ActionMessage | ReminderActionMessage {
  switch (campaignType) {
    case CampaignTemplateType.CATI:
    case CampaignTemplateType.SMSSurvey:
    case CampaignTemplateType.VoiceBlast:
    case CampaignTemplateType.VoiceSurvey:
    case CampaignTemplateType.WhatsAppSurvey: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.Action,
        hasQuestion: true,
        actionsOnly: {
          actions: [],
        },
      }
    }
    case CampaignTemplateType.Drip: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.Action,
        hasQuestion: true,
        actionsOnly: {
          actions: [],
        },
        delta: getEmptyDelta(timezone, nextIndex),
      }
    }
    case CampaignTemplateType.Reminder: {
      return ReminderCampaignClass.createNewMessage({
        contentType: 'actions-only',
        timezone,
      })
    }
    default: {
      throw new Error(`Invalid campaign type. ${getCampaignType({ type: campaignType })} doesn't support empty actions`)
    }
  }
}

export function getEmptyTopupMessage(
  campaignType: CampaignTemplateType,
  timezone: string,
  nextIndex: number,
): TopupMessage {
  switch (campaignType) {
    case CampaignTemplateType.Drip: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.Topup,
        hasQuestion: true,
        topup: {},
        delta: getEmptyDelta(timezone, nextIndex),
      }
    }
    default: {
      throw new Error(`Invalid campaign type. ${getCampaignType({ type: campaignType })} doesn't support topups`)
    }
  }
}

export function getEmptyAPIMessage(
  campaignType: CampaignTemplateType,
  timezone: string,
  nextIndex: number,
): APIMessage {
  switch (campaignType) {
    case CampaignTemplateType.SMSSurvey:
    case CampaignTemplateType.WhatsAppSurvey: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.API,
        hasQuestion: true,
        apiCall: {
          method: 'GET',
          url: '',
        },
      }
    }
    case CampaignTemplateType.Drip: {
      return {
        id: uuid(),
        changed: false,
        type: PartFamily.API,
        hasQuestion: true,
        apiCall: {
          method: 'GET',
          url: '',
        },
        delta: getEmptyDelta(timezone, nextIndex),
      }
    }
    default: {
      throw new Error(`Invalid campaign type. ${getCampaignType({ type: campaignType })} doesn't support API messages`)
    }
  }
}

export function getEmptyMessage(
  campaignType: CampaignTemplateType,
  messageType: string,
  timezone: string,
  nextIndex: number,
): TMessage | ReminderMessage {
  switch (messageType) {
    case 'sms-m':
      return getEmptySMSMessage(campaignType, timezone, nextIndex)
    case 'sms-q':
      return getEmptySMSQuestion(campaignType, timezone, nextIndex)
    case 'cati-m':
      return getEmptyCATIMessage(campaignType)
    case 'cati-q':
      return getEmptyCATIQuestion(campaignType)
    case 'voice-m':
      return getEmptyVoiceMessage(campaignType, timezone, nextIndex)
    case 'voice-q':
      return getEmptyVoiceQuestion(campaignType, timezone, nextIndex)
    case 'whatsapp-m':
      return getEmptyWhatsAppMessage(campaignType, timezone, nextIndex)
    case 'whatsapp-q':
      return getEmptyWhatsAppQuestion(campaignType, timezone, nextIndex)
    case 'action-q':
      return getEmptyActionsMessage(campaignType, timezone, nextIndex)
    case 'topup-q':
      return getEmptyTopupMessage(campaignType, timezone, nextIndex)
    case 'api-q':
      return getEmptyAPIMessage(campaignType, timezone, nextIndex)
    default: {
      throw new Error(`Unsupported message type: ${messageType}.`)
    }
  }
}
