import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'
import { propOr } from 'ramda'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import ExternalLink from '@/app/component/atom/external-link'
import IconText from '@/app/component/layout/icon-text'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { Payment } from '@/app/module/payments'

import Benefits from './benefits'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  planLabel: {
    color: theme.palette.primary.main,
  },
  planInformationContainer: {},
  planInformationRow: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgb(245, 245, 245)',
    boxShadow: '0px 0px 0px 1px rgba(224, 224, 224, 1)',
  },
  planTitle: {
    alignSelf: 'center',
    paddingRight: '100px',
  },
  planDetails: {},
  planPriceRow: {
    textAlign: 'right',
    paddingTop: '15px',
  },
  totalRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  totalLabel: {
    marginRight: '15px',
    alignSelf: 'center',
  },
  oldPrice: {
    textDecoration: 'line-through',
    marginRight: '3px',
    color: '#ef6c00',
    fontSize: '18px',
    display: 'inline-block',
  },
  toolbarContainer: {},
  cycleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  chargeTextContainer: {
    display: 'flex',
  },
}))

const getSteps = () => ['Select Plan', 'Charge Balance', 'Confirm']

export default function SubscribePlan(props) {
  const { planConfirmation, onClose, onConfirm, cards, activeOrg } = props
  const { title, text, plan, cycle } = planConfirmation
  const { classes } = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [termsAccepted, setTermsAccepted] = React.useState(false)
  const steps = getSteps()
  const price = cycle ? plan.priceYearly : plan.priceMonthly
  const oldPrice = plan.priceMonthly * 12
  const isBalanceEnough = propOr(0, 'balance', activeOrg) >= price
  const hasCard = cards.length > 0
  const minPayment = price - propOr(0, 'balance', activeOrg)

  const handleNext = () => setActiveStep(activeStep + 1)

  if (isBalanceEnough && activeStep === 1) {
    setActiveStep(2)
  }

  return (
    <CustomDialog onClose={onClose} fullWidth maxWidth="lg" id="delete-dialog" open={true}>
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>card_membership</Icon>
            <Typography variant="h6">{title}</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <StepContainer>
            {activeStep === 0 && (
              <div className={classes.planInformationContainer}>
                <div className={classes.planInformationRow}>
                  <div className={classes.planTitle}>
                    <Typography variant="h5">
                      Selected Plan:{' '}
                      <strong>
                        <span className={classes.planLabel}>{plan.label}</span>
                      </strong>
                    </Typography>
                  </div>
                  <div className={classes.planDetails}>
                    <Benefits plan={plan} />
                  </div>
                </div>
                <div className={classes.planPriceRow}>
                  {cycle && <div className={classes.oldPrice}>${oldPrice.toFixed(0)}</div>}
                  <div className={classes.totalRow}>
                    <Typography variant="h5" className={classes.totalLabel}>
                      Total Due:
                    </Typography>
                    <div>
                      <Typography component="div" variant="h5">
                        <div className={classes.planLabel}>
                          <strong>${price}</strong>
                        </div>
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.toolbarContainer}>
                    <div className={classes.cycleContainer}>
                      <Typography variant="caption" color="textSecondary">
                        (billed {cycle ? 'yearly' : 'monthly'})
                      </Typography>
                    </div>
                    <div className={classes.chargeTextContainer}>
                      <FormControlLabel
                        control={<Checkbox checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} />}
                        label={
                          <Typography color="textSecondary" variant="subtitle1" style={{ textAlign: 'left' }}>
                            I understand that Text messages, voice minutes, virtual phone numbers, airtime topups, and
                            other usage fees are charged{' '}
                            <span className={classes.underLinedText}>
                              <strong>in addition</strong>
                            </span>{' '}
                            to Subscription Plan fees. For more information{' '}
                            <ExternalLink href="https://www.engagespark.com/pricing" target="_blank">
                              click here
                            </ExternalLink>
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeStep === 1 && (
              <>
                <Typography variant="h6" style={{ textAlign: 'left', marginBottom: '15px' }}>
                  {hasCard
                    ? 'Please add enough balance to subscribe to your new plan'
                    : 'Please add a credit card and charge it to subscribe to your new plan'}
                </Typography>
                <Payment amount={price} minimum={minPayment.toFixed(0)} hideAutoRechange={true} />
              </>
            )}
            {activeStep === 2 && (
              <Typography component="div" variant="h4" color="textSecondary">
                {text}
              </Typography>
            )}
          </StepContainer>
        </div>
      </DialogContent>
      {activeStep !== 1 && (
        <DialogActions>
          <Button variant="outlined" id="plan-modal-cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="step-forwaard"
            disabled={activeStep === 0 && !termsAccepted}
            onClick={activeStep === steps.length - 1 ? onConfirm : handleNext}
          >
            {activeStep === steps.length - 1 ? 'Confirm' : `Continue with ${cycle ? 'yearly' : 'monthly'} billing`}
          </Button>
        </DialogActions>
      )}
    </CustomDialog>
  )
}

const StepContainer = ({ style, children }) => (
  <div
    style={{
      ...style,
      padding: '15px 30px',
      textAlign: 'center',
    }}
  >
    {children}
  </div>
)
