import Alert from '@mui/material/Alert'
import React from 'react'

import { formatDate } from '@/app/service/util'

type Props = {
  end: string
}

const CanceledPlanWarning: React.FC<Props> = ({ end }) => (
  <div style={{ margin: 16 }}>
    <Alert severity="warning">
      Your current plan is set to expire on <b>{formatDate(end)}</b>, and will not be renewed. Your account will
      automatically be <b>closed</b> after 14 days. <b>Closed</b> means that EVERYTHING IN YOUR ACCOUNT WILL BE
      PERMANENTLY DELETED. Please subscribe to a new plan to keep your account open, or reach out to support if you have
      questions.
    </Alert>
  </div>
)

export default CanceledPlanWarning
