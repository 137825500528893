import React from 'react'
import { useSearchParams } from 'react-router-dom-v5-compat'

import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Notifications from '@/app/module/notifications'
import AnalyticsContainer from '@/app/module/reports/components/analytics.container'
import { OrgReportFunction } from '@/app/module/reports/types'
import { MatchProps } from '@/app/types'

type Props = {
  match: MatchProps
}

const Analytics: React.FC<Props> = ({ match }) => {
  const [searchParams] = useSearchParams()
  const type = searchParams.get('type')
  const initialType = React.useMemo(() => {
    if (!type || ![OrgReportFunction.Spend, OrgReportFunction.Deliverability].includes(type as OrgReportFunction)) {
      return OrgReportFunction.Spend
    }

    return type as OrgReportFunction
  }, [type])

  return (
    <AuthGuard url={match.url} path={match.path}>
      <Layout title="Analytics - engageSPARK">
        <AuthHeader currentPage={PAGE.ANALYTICS.INDEX} />
        <AnalyticsContainer initialType={initialType} />
      </Layout>
      <Notifications />
    </AuthGuard>
  )
}

export default Analytics
