import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import React from 'react'

type Props = {
  children?: React.ReactNode
  closeOnBackdropClick?: boolean
  id?: string

  onClose?: () => void
} & Omit<MuiDialogProps, 'onClose'>

const Dialog: React.FC<Props> = ({ children, closeOnBackdropClick = false, id, open, onClose, ...rest }) => {
  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (closeOnBackdropClick || reason !== 'backdropClick') {
      onClose?.()
    }
  }

  return (
    <MuiDialog id={id} open={open} onClose={handleClose} {...rest}>
      {children}
    </MuiDialog>
  )
}

export default Dialog
export type { Props as DialogProps }
