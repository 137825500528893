import Chip from '@mui/material/Chip'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { equals } from 'ramda'
import React, { Component } from 'react'

import { SelectOption } from '@/app/types'

import FormControl from './form-control'
import SelectMultipleItem from './select-multiple-item'

type Props = {
  editable?: boolean
  error?: string
  id?: string
  label?: string
  message?: string
  name: string
  value: string[]
  values: SelectOption<string>[]

  onChange?: (payload: { name: string; value: string[] }) => void
}

class SelectFieldMulti extends Component<Props> {
  state = {
    isOpen: false,
  }

  shouldComponentUpdate(nextProps: Props) {
    const { label, value, editable, error, message, values } = this.props

    return (
      label !== nextProps.label ||
      value.join(',') !== nextProps.value.join(',') ||
      editable !== nextProps.editable ||
      error !== nextProps.error ||
      message !== nextProps.message ||
      !equals(values, nextProps.values)
    )
  }

  render() {
    const { name, label, value, values = [], editable, error, message, onChange, id = '' } = this.props
    return (
      <FormControl error={error} info={message}>
        <InputLabel htmlFor="select-multi">{label}</InputLabel>
        <Select
          MenuProps={{
            sx: {
              '& .MuiPaper-root': {
                maxHeight: '300px',
                height: '40%',
              },
            },
          }}
          id={id}
          input={<Input name={name} readOnly={!editable} />}
          multiple
          value={value}
          variant="standard"
          onChange={(e) => {
            if (!Array.isArray(e.target.value)) {
              return
            }
            onChange?.({
              name,
              value: e.target.value,
            })
          }}
          renderValue={(selected) =>
            values
              .filter((val) => selected.includes(val.value))
              .map(({ value: tagValue, label: tagLabel }) => <Chip key={tagValue} label={tagLabel} />)
          }
        >
          {values.map(({ value: val, label: valName }) => (
            <SelectMultipleItem checked={value.includes(val)} key={val} label={valName} value={val} />
          ))}
        </Select>
      </FormControl>
    )
  }
}

export default SelectFieldMulti
