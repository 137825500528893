import { styled, Typography } from '@mui/material'
import React from 'react'

import { OrgReportFilter } from '@/app/module/reports/types'
import { CountryTimezone, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'

import OrganizationReportCampaignsFilter from './organization-report-campaigns-filter'
import OrganizationReportDateFilter from './organization-report-date-filter'

type Props = {
  campaignOptions: SelectOption<string>[]
  campaignsLoading: boolean
  countriesTimezones: CountryTimezone[]
  countryCode: string
  disabled?: boolean
  filter: OrgReportFilter
  timezone: string
  timezones: TimezoneWithCountryCodeType
  onCampaignsSearch: (search: string) => void
  onChange?: (filter: OrgReportFilter) => void
}

const OrganizationReportFilter: React.FC<Props> = ({
  campaignOptions,
  campaignsLoading,
  countriesTimezones,
  countryCode,
  disabled,
  filter,
  timezone,
  timezones,
  onCampaignsSearch,
  onChange,
}) => {
  const handleChange = (value: Partial<OrgReportFilter>) => {
    if (!onChange || disabled) {
      return
    }
    onChange({
      ...filter,
      ...value,
    })
  }

  return (
    <div>
      <Typography variant="subtitle1">Filter by:</Typography>
      <Content>
        <ul>
          <li>
            <OrganizationReportCampaignsFilter
              disabled={disabled}
              loading={campaignsLoading}
              options={campaignOptions}
              value={filter.campaignIds || []}
              onCampaignsSearch={onCampaignsSearch}
              onSave={(campaignIds) => handleChange({ campaignIds })}
            />
          </li>
          <li>
            <OrganizationReportDateFilter
              countriesTimezones={countriesTimezones}
              countryCode={countryCode}
              disabled={disabled}
              timezone={timezone}
              timezones={timezones}
              value={filter.date}
              onSave={(date) => handleChange({ date })}
            />
          </li>
        </ul>
      </Content>
    </div>
  )
}

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))

export default OrganizationReportFilter
