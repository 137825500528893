import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { ReactNode } from 'react'

import IconText from '@/app/component/layout/icon-text'

import { DialogTitleContent } from './dialog-title-content'

type Props = {
  icon?: string
  title: ReactNode
  onClose: () => void
}

const DialogHeader: React.FC<Props> = ({ title, onClose, icon }) => (
  <DialogTitle>
    <DialogTitleContent>
      <IconText>
        {icon && <Icon>{icon}</Icon>}
        <Typography variant="h6">{title}</Typography>
      </IconText>
      <IconButton onClick={onClose} aria-label="close">
        <Icon>close</Icon>
      </IconButton>
    </DialogTitleContent>
  </DialogTitle>
)

export default DialogHeader
export type { Props as DialogHeaderProps }
