import React from 'react'

import { useCampaignDebouncedState } from '@/app/module/campaigns/component/hooks/use-campaign-debounced-state'
import { CAMPAIGN_RETRIES } from '@/app/module/campaigns/definitions'
import {
  CampaignTemplateType,
  LocalOnInvalidReply,
  LocalOnRetriesExhausted,
  LocalOnTimeout,
} from '@/app/module/campaigns/types'

import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import { ConfigDivider } from './response-configs-components'
import { fromConfigs, toConfigs, WhatsappConfigs, WhatsappConfigsState } from './response-whatsapp-configs-utils'
import ResponseWhatsappFailure from './response-whatsapp-failure'
import ResponseWhatsappInvalid from './response-whatsapp-invalid'
import ResponseWhatsappRetries from './response-whatsapp-retries'
import ResponseWhatsappTimeout from './response-whatsapp-timeout'

type Props = {
  actionProps: ActionProps
  apiProps: ApiProps
  campaignType: CampaignTemplateType
  configs: WhatsappConfigs
  id: string
  snippetProps: SnippetProps

  onChange: (value: WhatsappConfigs) => void
}

const ResponseWhatsappConfigs: React.FC<Props> = ({
  actionProps,
  apiProps,
  campaignType,
  configs,
  id,
  snippetProps,
  onChange,
}) => {
  const initialState = React.useMemo(() => fromConfigs(configs), [configs])
  const handleChange = React.useCallback((value: WhatsappConfigsState) => onChange(toConfigs(value)), [onChange])
  const [state, setState] = useCampaignDebouncedState(initialState, handleChange)

  const handleInvalidChange = React.useCallback(
    (value: Partial<LocalOnInvalidReply>) =>
      setState((s) => {
        if (!s.onInvalidReply) {
          return s
        }

        return { ...s, onInvalidReply: { ...s.onInvalidReply, ...value } }
      }),
    [setState],
  )
  const handleTimeoutChange = React.useCallback(
    (value: Partial<LocalOnTimeout>) =>
      setState((s) => {
        const shouldUpdateRetries = 'enabled' in value
        if (!shouldUpdateRetries) {
          return { ...s, onTimeout: { ...s.onTimeout, ...value } }
        }

        const defaultRetries = typeof s.retries === 'number' ? s.retries : CAMPAIGN_RETRIES
        return {
          ...s,
          onTimeout: { ...s.onTimeout, ...value },
          retries: value.enabled || s.onInvalidReply?.enabled ? defaultRetries : 0,
        }
      }),
    [setState],
  )
  const handleRetriesChange = React.useCallback(
    ({ value }: { value: string }) => setState((s) => ({ ...s, retries: Number(value) })),
    [setState],
  )
  const handleFailureChange = React.useCallback(
    (value: Partial<LocalOnRetriesExhausted>) =>
      setState((s) => ({ ...s, onRetriesExhausted: { ...s.onRetriesExhausted, ...value } })),
    [setState],
  )

  const retriesEnabled = !!(state.onInvalidReply?.enabled || state.onTimeout.enabled)

  return (
    <>
      {!!state.onInvalidReply && (
        <>
          <ConfigDivider />
          <ResponseWhatsappInvalid
            actionProps={actionProps}
            apiProps={apiProps}
            campaignType={campaignType}
            id={id}
            info="Specify what we should do if the contact's reply is not one of the choices. Afterwards, we will wait for the contact to reply with a valid choice—until they run out of chances."
            invalid={state.onInvalidReply}
            snippetProps={snippetProps}
            onChange={handleInvalidChange}
          />
        </>
      )}
      <ConfigDivider />
      <ResponseWhatsappTimeout
        actionProps={actionProps}
        apiProps={apiProps}
        campaignType={campaignType}
        id={id}
        info="Specify what we should do if the contact does not reply within a period of time that you specify. Afterwards, we will wait for the contact to reply—until they run out of chances."
        inputBeforeText="Take the above actions if the contact does not reply to this question after: "
        snippetProps={snippetProps}
        timeout={state.onTimeout}
        onChange={handleTimeoutChange}
      />
      <ConfigDivider />
      <ResponseWhatsappRetries retries={state.retries} retriesEnabled={retriesEnabled} onChange={handleRetriesChange} />
      <ConfigDivider />
      <ResponseWhatsappFailure
        actionProps={actionProps}
        apiProps={apiProps}
        campaignType={campaignType}
        failure={state.onRetriesExhausted}
        id={id}
        info="Specify what we should do after the contact runs out of chances."
        snippetProps={snippetProps}
        onChange={handleFailureChange}
      />
    </>
  )
}

export default React.memo(ResponseWhatsappConfigs)

export type { Props as ResponseWhatsappConfigsProps }
