import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { UpdateOrgPlanApiArg } from '@/__generated/es-api'
import { selectToken } from '@/app/module/auth/store/selectors'
import { createNotification } from '@/app/module/notifications/store/actions'
import { getPlan } from '@/app/module/plans/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { esApi, getErrorMessage } from '@/app/store/api'
import { useAppDispatch } from '@/app/store/redux-hooks'

export const useUpdateSubscription = () => {
  const orgId = useSelector(selectOrgId)
  const token = useSelector(selectToken)
  const dispatch = useAppDispatch()
  const [update, res] = esApi.endpoints.updateOrgPlan.useMutation()

  const updateSubscription = useCallback(
    async (body: UpdateOrgPlanApiArg['body']) => {
      try {
        const result = await update({
          orgId: orgId.toString(),
          body,
        }).unwrap()

        dispatch(
          createNotification({
            'update-subscription': {
              type: 'success',
              message: `Successfully updated subscription plan.`,
            },
          }),
        )
        dispatch(getPlan({ token, orgId }))

        return result
      } catch (error) {
        dispatch(
          createNotification({
            'update-subscription': {
              type: 'error',
              message: getErrorMessage(error, 'Failed to update subscription plan. Please try again.'),
            },
          }),
        )
        return false
      }
    },
    [orgId, token, dispatch, update],
  )

  return [updateSubscription, res] as const
}
