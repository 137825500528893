import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import { append, remove } from 'ramda'
import React from 'react'

import DripDelay from '@/app/module/campaigns/component/item/steps/content/types/multimessage/delay'
import { IndexedCallSchedule } from '@/app/module/campaigns/types'
import { CountryTimezone, TimezoneWithCountryCodeType } from '@/app/types'

type Props = {
  countriesTimezones: CountryTimezone[]
  countryCode: string
  schedule: IndexedCallSchedule[]
  timezone?: string
  timezones: TimezoneWithCountryCodeType

  onChange: (value: IndexedCallSchedule[]) => void
}

const CustomSchedule: React.FC<Props> = ({
  countriesTimezones,
  countryCode,
  schedule,
  timezone,
  timezones,
  onChange,
}) => (
  <div
    style={{
      paddingBottom: '30px',
      paddingTop: '15px',
    }}
  >
    {schedule.map((delay: IndexedCallSchedule, i) => (
      <div
        key={`${delay.index}`}
        className="schedule-row"
        id={`schedule-entry-${delay.index}`}
        style={{
          display: 'flex',
          paddingBottom: '15px',
          marginBottom: '15px',
          ...(schedule.length > 1 && {
            borderBottom: '1px dotted #A0A0A0',
          }),
        }}
      >
        {i === 0 ? (
          <div
            style={{
              padding: '20px',
            }}
          ></div>
        ) : (
          <div
            className="delete-schedule-row-btn"
            onClick={() => {
              onChange(remove(i, 1, schedule))
            }}
            style={{
              padding: '10px',
              cursor: 'pointer',
              color: 'rgba(0, 0, 0, 0.54)',
            }}
          >
            <Icon>delete</Icon>
          </div>
        )}
        <div
          style={{
            width: '100%',
            marginLeft: '15px',
          }}
        >
          <DripDelay
            item={{ delta: delay }}
            isFirst={i === 0}
            published={false}
            changeHandler={(value: { delta: any }) => {
              const updatedSchedule = [...schedule]
              updatedSchedule[i] = value.delta
              onChange(updatedSchedule)
            }}
            timezone={timezone}
            timezones={timezones}
            countriesTimezones={countriesTimezones}
            countryCode={countryCode}
            setLoading={() => {}}
            message="Try again after"
            helpText={`if the ${i === 0 ? 'initial' : 'previous retry'} call was not answered`}
            schedule
          />
        </div>
      </div>
    ))}
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button
        className="add-another-schedule-entry"
        variant="contained"
        color="primary"
        onClick={() => {
          const newScheduleEntry = {
            ...schedule[schedule.length - 1],
            index: schedule[schedule.length - 1].index + 1,
          }
          onChange(append(newScheduleEntry, schedule))
        }}
      >
        Add another retry attempt
      </Button>
    </div>
  </div>
)

export default CustomSchedule
