import { Card, CardContent, styled } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { esApi } from '@/__generated/es-api'
import Loading from '@/app/component/guard/loading'
import { ERROR_EXPORT_DOWNLOAD_SERVER } from '@/app/module/campaigns/definitions'
import { ZGetOrgReportFileResponse } from '@/app/module/campaigns/types/schemas/reports/org-report'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_GET_REPORT } from '@/app/module/reports/definitions'
import { OrgReportQuery, OrgReportResult } from '@/app/module/reports/types'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { useRequirePermission } from '@/app/module/user/store/ts/hooks'
import { selectUserLoading } from '@/app/module/user/store/ts/selectors'
import download from '@/app/service/util/download'
import { captureError } from '@/app/service/util/error'
import { useAppDispatch } from '@/app/store/redux-hooks'

import NoReportPermission from './no-report-permission'
import ReportFileFilterContainer from './report-file-filter.container'
import ReportInfo from './report-info'
import ReportTable from './report-table'

type Props = {}

type Params = {
  exportId?: string
}

const ReportFileContainer: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const hasPermission = useRequirePermission('downloadReport')
  const orgId = useSelector(selectOrgId)
  const userLoading = useSelector(selectUserLoading)
  const { exportId } = useParams<Params>()
  const [isDownloading, setIsDownloading] = React.useState(false)
  const [reportData, setReportData] = React.useState<OrgReportResult>()
  const [reportQuery, setReportQuery] = React.useState<OrgReportQuery>()

  const params = new URLSearchParams(location.search)
  const filename = params.get('filename')
  const numberExportId = Number(exportId)

  const [getExport, getExportRes] = esApi.endpoints.getExport.useLazyQuery()
  const [getExportsFile, getExportFileRes] = esApi.endpoints.getExportsFile.useLazyQuery()

  const getFile = React.useCallback(
    async (_orgId: string, _exportId: number, _filename: string) => {
      try {
        await Promise.all([
          getExport({ orgId: _orgId, exportId: _exportId }).unwrap(),
          getExportsFile({ orgId: _orgId, exportId: _exportId, filename: _filename }).unwrap(),
        ])
      } catch (error) {
        dispatch(
          createNotification({
            'report-file-error': {
              type: 'error',
              message: ERROR_GET_REPORT,
            },
          }),
        )
        captureError(error)
        history.push('/analytics')
      }
    },
    [history, dispatch, getExport, getExportsFile],
  )

  React.useEffect(() => {
    if (!orgId || Number.isNaN(numberExportId) || !filename) {
      return
    }

    getFile(String(orgId), numberExportId, filename)
  }, [filename, numberExportId, orgId, getFile])

  React.useEffect(() => {
    if (getExportFileRes.data) {
      try {
        const validated = ZGetOrgReportFileResponse.parse(getExportFileRes.data)
        setReportData(validated.result)
        setReportQuery(validated.query)
      } catch (error) {
        dispatch(
          createNotification({
            'report-file-error': {
              type: 'error',
              message: ERROR_GET_REPORT,
            },
          }),
        )
        captureError(error)
        history.push('/analytics')
      }
    }
  }, [getExportFileRes.data, dispatch, history])

  const reportInfo = getExportRes.data?.export
  const isLoading = userLoading || getExportRes.isLoading || getExportFileRes.isLoading || isDownloading

  const handleDownloadClick = React.useCallback(async () => {
    if (!reportInfo) {
      // should not happen but compiler complains
      return
    }
    const excelFile = reportInfo.files?.find(
      (file) => file.contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    )

    if (!excelFile?.filename) {
      // again, compiler complains
      return
    }

    try {
      setIsDownloading(true)
      await download.exports(orgId, numberExportId, excelFile.filename)
    } catch (error) {
      dispatch(
        createNotification({
          'export-download-error': {
            type: 'error',
            message: ERROR_EXPORT_DOWNLOAD_SERVER,
          },
        }),
      )
      captureError(error)
    } finally {
      setIsDownloading(false)
    }
  }, [numberExportId, orgId, reportInfo, dispatch])

  const handleCreateClick = React.useCallback(() => {
    if (!reportInfo) {
      return
    }

    if (!reportQuery) {
      history.push(`/analytics?type=${reportInfo.function}`)
      return
    }
    history.push(`/analytics?type=${reportInfo.function}&query=${encodeURIComponent(JSON.stringify(reportQuery))}`)
  }, [history, reportInfo, reportQuery])

  const reportLoaded = reportInfo && reportData && reportQuery

  return (
    <Loading isLoading={isLoading}>
      {!userLoading && !hasPermission && <NoReportPermission />}

      {hasPermission && (
        <Card>
          {reportLoaded && (
            <ReportInfo data={reportInfo} onCreateClick={handleCreateClick} onDownloadClick={handleDownloadClick} />
          )}
          <CardContent>
            {reportLoaded && (
              <>
                <FilterContainer>
                  <ReportFileFilterContainer filter={reportQuery.filter} />
                </FilterContainer>
                <ReportTable data={reportData} />
              </>
            )}
          </CardContent>
        </Card>
      )}
    </Loading>
  )
}

const FilterContainer = styled('div')(({ theme }) => ({
  minWidth: '400px',
  paddingBottom: theme.spacing(2),
}))

export default ReportFileContainer
