import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { append, remove } from 'ramda'
import React from 'react'

import ConditionGroup from '@/app/component/conditions/condition-group'
import { Groups, OperandsList } from '@/app/component/conditions/definitions'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { Condition } from '@/app/types'

const defaultData: Condition[] = [['and', ['', '', '']]]

type Props = {
  error?: boolean
  groups?: Groups
  operands: OperandsList
  personalizationList: PersonalizationType
  segments?: Groups
  title?: string
  value: string
  onBlur: (event: { name: string; value: string }) => void
  onChange: (event: { name: string; value: string }) => void
}

const Conditions: React.FC<Props> = ({
  error = false,
  groups,
  operands,
  personalizationList,
  segments,
  title,
  value,
  onChange,
  onBlur,
}) => {
  const data = React.useMemo(() => {
    if (!value) {
      return defaultData
    }
    try {
      return JSON.parse(value) as Condition[]
    } catch {
      return defaultData
    }
  }, [value])

  const submitChange = (item: { name: string; value: string }) => {
    onChange(item)
    onBlur(item)
  }

  return (
    <div
      style={{
        marginTop: '12px',
        width: '100%',
      }}
    >
      {title && <Typography variant="h6">{title}</Typography>}
      <div
        style={{
          width: '100%',
        }}
      >
        {data.map((conditionRow, rowIndex: number) => (
          <div
            key={`conditions-${rowIndex}`}
            style={{
              marginBottom: 18,
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                marginBottom: '10px',
              }}
            >
              {rowIndex === 0 ? (
                <Typography variant="subtitle2">
                  Add contacts to this segment if the following conditions apply
                </Typography>
              ) : (
                <Typography variant="subtitle2">
                  <strong style={{ color: '#2f80ac' }}>OR</strong> if the following conditions apply:
                </Typography>
              )}
            </div>
            <ConditionGroup
              error={!!error}
              groupedOperators={true}
              conditionRow={conditionRow as Condition[]}
              operands={operands}
              personalizationList={personalizationList}
              groups={groups}
              segments={segments}
              key={`row${rowIndex}`}
              isFirst={rowIndex === 0}
              isLast={rowIndex === data.length - 1}
              rowIndex={rowIndex}
              onAddConditionGroup={() => {
                submitChange({
                  name: 'conditions',
                  value: JSON.stringify(append(['and', ['', '', '']], data)),
                })
              }}
              onChangeCondition={({ updatedConditionGroup }: { updatedConditionGroup: Condition }) => {
                if (updatedConditionGroup.length === 1) {
                  submitChange({
                    name: 'conditions',
                    value: JSON.stringify(remove(rowIndex, 1, data)),
                  })
                } else {
                  const newData = [...data]
                  newData[rowIndex] = updatedConditionGroup
                  submitChange({
                    name: 'conditions',
                    value: JSON.stringify(newData),
                  })
                }
              }}
            />
            <Divider />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Conditions
