import { Box } from '@mui/material'
import React from 'react'

import { Form } from '@/app/component/form'
import SubmitButton from '@/app/component/form/submit-button'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { operands } from '@/app/module/groups/definitions'
import { useCreateGroup } from '@/app/module/groups/hooks/use-create-group'

import { FormContainer } from './common'
import { fields, segmentSchema } from './specs'
import { toConditions } from './utils'

type Props = {
  orgId: number
  personalizationList: PersonalizationType
  token: string

  getSegments: (payload: { token: string; orgId: number }) => void
  onExit: () => void
}

const CreateSegmentForm: React.FC<Props> = ({ orgId, personalizationList, token, getSegments, onExit }) => {
  const [createGroup, { isLoading }] = useCreateGroup()

  const handleSubmit = React.useCallback(
    async (values: { name: string; conditions: string }) => {
      const success = await createGroup({
        conditions: toConditions(values.conditions),
        type: 'SMART',
        name: values.name,
        description: '',
        noAutodelete: false,
      })
      if (!success) {
        return
      }
      getSegments({ token, orgId }) // FIXME: this should be removed when we move getSegments to generated API
      onExit()
    },
    [orgId, token, createGroup, getSegments, onExit],
  )

  const segmentFields = React.useMemo(
    () =>
      ({
        ...fields,
        conditions: {
          fieldProps: { label: 'Clauses', name: 'conditions', operands, personalizationList },
          type: 'conditions',
        },
      }) as const,
    [personalizationList],
  )

  return (
    <FormContainer>
      <Form
        defaultValues={defaultValues}
        fields={segmentFields}
        name="create-segment"
        schema={segmentSchema}
        shouldReset
        onSubmit={handleSubmit}
        renderActions={({ isDirty, isValid }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
            <SubmitButton isDirty={isDirty} isSubmitting={isLoading} isValid={isValid}>
              Save
            </SubmitButton>
          </Box>
        )}
      />
    </FormContainer>
  )
}

const defaultValues = {
  name: '',
  conditions: JSON.stringify([['and', ['', '', '']]]),
}

export default CreateSegmentForm
