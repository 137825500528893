import { compose, filter, pathOr } from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'

import { useDebouncedFn } from '@/app/helpers'
import { PersonalizationType, SinglePersonalizationType } from '@/app/module/campaigns/types'
import { getGroups, resetGroupsQuery } from '@/app/module/groups/store/actions'
import { selectGroupsPageValues } from '@/app/module/groups/store/selectors'
import { selectPersonalizationList } from '@/app/module/logs/store/selectors'
import { selectPlan } from '@/app/module/plans/store/selectors'
import { OrgPlan } from '@/app/module/plans/types'
import { getSegments, resetSegmentsQuery } from '@/app/module/segments/store/actions'
import { selectSegmentsPageValues } from '@/app/module/segments/store/selectors'
import { updateUser } from '@/app/module/user/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { UserType } from '@/app/module/user/types'
import { useAppDispatch } from '@/app/store/redux-hooks'

import UserAccessModal from './user-access'

type Props = {
  data: UserType

  onClose: () => void
}

const usePzList = () => {
  const pzList = useSelector(selectPersonalizationList)

  return React.useMemo(
    () => ({
      ...pzList,
      contact: compose<PersonalizationType, SinglePersonalizationType[], SinglePersonalizationType[]>(
        filter<SinglePersonalizationType>((v) => v.value !== 'contact.id'),
        pathOr([], ['contact']),
      )(pzList),
    }),
    [pzList],
  )
}

/**
 * Container (interacts with redux store) for UserAccessModal
 */
const UserAccessContainer: React.FC<Props> = ({ data, onClose }) => {
  const contactGroups = useSelector(selectGroupsPageValues)
  const contactSegments = useSelector(selectSegmentsPageValues)
  const loading = useSelector(pathOr(false, ['user', 'users', 'loading']))
  const groupsLoading = useSelector(pathOr(false, ['groups', 'list', 'loading']))
  const orgId = useSelector(selectOrgId)
  const personalizationList = usePzList()
  const plan = useSelector((state: any) => selectPlan(state.plans) as OrgPlan)
  const token = useSelector(pathOr('', ['auth', 'login', 'data', 'token']))

  // We are getting the most recent 1000 groups and 1000 segments from the API since it's the limit
  const groups = React.useMemo(() => [...contactGroups, ...contactSegments], [contactGroups, contactSegments])

  const dispatch = useAppDispatch()

  const handleSave = React.useCallback(
    async (item: UserType) => {
      try {
        await dispatch(
          updateUser({
            item,
            orgId,
            token,
            userId: item.id,
          }),
        )
      } finally {
        onClose()
      }
    },
    [orgId, token, dispatch, onClose],
  )

  const searchGroups = React.useCallback(
    (search: string) =>
      Promise.all([
        dispatch(
          getGroups({
            token,
            orgId,
            query: {
              page: 1,
              search,
              size: 1000,
            },
          }),
        ),
        dispatch(
          getSegments({
            token,
            orgId,
            query: {
              page: 1,
              search,
              size: 1000,
            },
          }),
        ),
      ]),
    [orgId, token, dispatch],
  )

  React.useEffect(
    () => () => {
      dispatch(resetGroupsQuery())
      dispatch(resetSegmentsQuery())
    },
    [dispatch],
  )

  const handleGroupsSearch = useDebouncedFn(searchGroups)

  return (
    <UserAccessModal
      data={data}
      groups={groups}
      groupsLoading={!loading && groupsLoading}
      loading={loading}
      personalizationList={personalizationList}
      plan={plan}
      onClose={onClose}
      onGroupsSearch={handleGroupsSearch}
      onSave={handleSave}
    />
  )
}

export default UserAccessContainer
