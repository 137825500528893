import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { List, ListRowProps } from 'react-virtualized'
import { makeStyles } from 'tss-react/mui'

import Loading from '@/app/component/guard/loading'
import { Export } from '@/app/module/exports/types'
import { useDimensions } from '@/app/service/util/dimensions'

import ExportRow from './export-row'

type Props = {
  data: Export[]
  error: any
  hasMore: boolean
  loading: boolean

  onBottomReached: () => void
  onDelete: (exportId: number) => Promise<void>
  onFileDownload: (exportId: number, filename: string) => Promise<void>
}

type ScrollEvent = {
  clientHeight: number
  scrollHeight: number
  scrollTop: number
}

const ROW_HEIGHT = 100
const ROW_UNTIL_LOAD = 3

const ExportList: React.FC<Props> = ({ data, error, loading, onBottomReached, onDelete, onFileDownload }) => {
  const { classes } = useStyles()

  const { windowHeight, windowWidth } = useDimensions()

  const onScroll = React.useCallback(
    ({ clientHeight, scrollHeight, scrollTop }: ScrollEvent) => {
      const bottomThreshold = scrollHeight - clientHeight - ROW_HEIGHT * ROW_UNTIL_LOAD
      if (scrollTop > bottomThreshold) {
        onBottomReached()
      }
    },
    [onBottomReached],
  )

  const renderRow = ({ index, key, style }: ListRowProps) => {
    const item = data[index]

    if (!item) {
      return null
    }

    return (
      <div key={key} style={style}>
        <ExportRow index={index} item={item} onDelete={onDelete} onFileDownload={onFileDownload} />
      </div>
    )
  }

  return (
    <div id="export-list" data-list-total={data.length}>
      <Loading error={error} isLoading={loading} testId="export-list-loading">
        <Paper>
          <Grid container className={classes.header}>
            <Grid item xs={5} padding={2}>
              <Typography variant="subtitle1">File Name</Typography>
            </Grid>
            <Grid item xs={2} padding={2}>
              <Typography variant="subtitle1">Type</Typography>
            </Grid>
            <Grid item xs={2} padding={2}>
              <Typography variant="subtitle1">Created</Typography>
            </Grid>
            <Grid item xs={2} padding={2}>
              <Typography variant="subtitle1">Status</Typography>
            </Grid>
            <Grid item xs={1} padding={2}>
              <Typography variant="subtitle1">Actions</Typography>
            </Grid>
          </Grid>
          {!!data.length && (
            <List
              className="export-list"
              height={windowHeight - 64 * 2}
              width={windowWidth}
              style={{
                paddingTop: '16px',
                outline: 'none',
              }}
              rowCount={data.length + (loading ? 1 : 0)}
              rowHeight={ROW_HEIGHT}
              rowRenderer={renderRow}
              onScroll={onScroll}
            />
          )}
          {!data.length && !loading && (
            <Typography variant="h6" color="textSecondary" align="center">
              No exports were found.
            </Typography>
          )}
        </Paper>
      </Loading>
    </div>
  )
}

const useStyles = makeStyles()((theme) => ({
  header: {
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[1],
  },
}))

export default ExportList
