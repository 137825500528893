import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import { CampaignSummary } from '@/app/module/campaigns'
import { updateItem } from '@/app/module/campaigns/store/actions'
import { navigateContacts } from '@/app/module/contacts/store/actions'
import { navigateGroups } from '@/app/module/groups/store/actions'
import Notifications from '@/app/module/notifications'
import { navigateSegments } from '@/app/module/segments/store/actions'

function CampaignsSummaryPage({ name, routes, match, history }) {
  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <Layout title="Campaigns - engageSPARK">
        <AuthHeader currentPage={PAGE.CAMPAIGNS.INDEX} />
        <CampaignSummary history={history} itemId={match.params.campaignsId} step={match.params.step} />
        <Notifications />
      </Layout>
    </AuthGuard>
  )
}

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      clearItem: () => () => {
        dispatch(updateItem())
        dispatch(navigateContacts({}))
        dispatch(navigateGroups({}))
        dispatch(navigateSegments({}))
      },
    },
    dispatch,
  ),
)(CampaignsSummaryPage)
