import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { PostOrgDidApiArg } from '@/__generated/es-api'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_UPDATE_PHONENUMBER_SERVER } from '@/app/module/phonenumbers/definitions'
import { invalidatePhoneNumbersCache } from '@/app/module/phonenumbers/store/utils'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { esApi } from '@/app/store/api'
import { useAppDispatch } from '@/app/store/redux-hooks'

export const useUpatePhoneNumber = () => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const [update, res] = esApi.endpoints.postOrgDid.useMutation()

  const updatePhoneNumber = useCallback(
    async (didId: number, payload: PostOrgDidApiArg['body']) => {
      try {
        await update({
          orgId: orgId.toString(),
          didId: didId.toString(),
          body: payload,
        }).unwrap()
        invalidatePhoneNumbersCache(dispatch)
        dispatch(
          createNotification({
            'update-phone-number': {
              type: 'success',
              message: 'Phone number updated successfully.',
            },
          }),
        )
        return true
      } catch {
        dispatch(
          createNotification({
            'update-phone-number': {
              type: 'error',
              message: ERROR_UPDATE_PHONENUMBER_SERVER,
            },
          }),
        )
        return false
      }
    },
    [orgId, dispatch, update],
  )

  return [updatePhoneNumber, res] as const
}
