import {
  Icon,
  List,
  ListItemIcon,
  ListItemText,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  styled,
  Typography,
} from '@mui/material'
import React from 'react'

import { TWhatsappButtonsContent } from '@/app/types'

type Props = {
  content: TWhatsappButtonsContent
}

const WhatsappButtons: React.FC<Props> = ({ content }) => (
  <Container>
    <Typography sx={{ padding: (theme) => theme.spacing(0, 1.25) }}>{content.message}</Typography>
    <List>
      {content.buttons.map((item, index) => (
        <ListItem data-testid={`whatsapp-button-item-${index}`} key={index} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Icon>reply</Icon>
            </ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  </Container>
)

const Container = styled('div')(() => ({
  marginLeft: -10,
  marginRight: -10,
}))

const ListItem = styled(MuiListItem)(() => ({
  '&:last-of-type .MuiListItemButton-root': {
    borderBottom: '1px solid #E0E0E0',
  },
}))

const ListItemButton = styled(MuiListItemButton)(() => ({
  borderTop: '1px solid #E0E0E0',
  justifyContent: 'center',

  '&:first-of-type': {
    borderTop: '1px solid #E0E0E0',
  },

  '& .MuiListItemIcon-root': {
    marginRight: 4,
    minWidth: 'auto',
  },

  '& .MuiListItemText-root': {
    flex: '0 0 auto',
    textAlign: 'center',
  },
}))

export default WhatsappButtons
