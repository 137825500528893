import { pathOr } from 'ramda'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ERROR_EXPORT_DOWNLOAD_SERVER } from '@/app/module/campaigns/definitions'
import { deleteExport, getExports } from '@/app/module/exports/store/actions'
import {
  selectExportsError,
  selectExportsHasMore,
  selectExportsList,
  selectExportsLoading,
} from '@/app/module/exports/store/selectors'
import { createNotification } from '@/app/module/notifications/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import download from '@/app/service/util/download'

import ExportList from './export-list'

type Props = {}

type Query = {
  page?: number
  size?: number
}

const ExportListContainer: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const orgId = useSelector(selectOrgId)
  const token = useSelector(pathOr('', ['auth', 'login', 'data', 'token']))
  const error = useSelector(selectExportsError)
  const exports = useSelector(selectExportsList)
  const hasMore = useSelector(selectExportsHasMore)
  const loading = useSelector(selectExportsLoading)

  const [downloading, setDownloading] = React.useState(false)
  const [query, setQuery] = React.useState<Query>({
    page: 1,
    size: 10,
  })

  React.useEffect(() => {
    if (!orgId || !token) {
      return
    }

    getExports({ orgId, query, token })(dispatch)
  }, [orgId, query, token, dispatch])

  const handleBottomReached = React.useCallback(() => {
    if (!hasMore || loading) {
      return
    }

    setQuery((s) => ({
      ...s,
      page: (s.page || 0) + 1,
    }))
  }, [hasMore, loading])
  const handleDelete = React.useCallback(
    (exportId: number) => deleteExport({ orgId, exportId, token })(dispatch),
    [orgId, token, dispatch],
  )
  const handleFileDownload = React.useCallback(
    async (exportId: number, filename: string) => {
      try {
        setDownloading(true)
        await download.exports(orgId, exportId, filename)
      } catch {
        dispatch(
          createNotification({
            'export-download-error': {
              type: 'error',
              message: ERROR_EXPORT_DOWNLOAD_SERVER,
            },
          }),
        )
      } finally {
        setDownloading(false)
      }
    },
    [orgId, dispatch],
  )

  return (
    <ExportList
      data={exports}
      error={error}
      hasMore={hasMore}
      loading={downloading || loading}
      onBottomReached={handleBottomReached}
      onDelete={handleDelete}
      onFileDownload={handleFileDownload}
    />
  )
}

export default ExportListContainer
