import { z } from 'zod'

import { GROUPS_DEFAULT_FIELD_CHART_LIMIT } from '@/app/module/contacts/definitions'
import { Condition } from '@/app/types'

export const fields = {
  name: {
    fieldProps: { label: 'Name', name: 'name', required: true },
    type: 'text',
  },
} as const

export const schema = z.object({
  name: z
    .string()
    .min(1)
    .max(GROUPS_DEFAULT_FIELD_CHART_LIMIT, {
      message: `Name cannot be longer than ${GROUPS_DEFAULT_FIELD_CHART_LIMIT} characters`,
    }),
})

export const validateCondition = (condition: Condition) => {
  if (!condition[0]) {
    return false
  }
  for (let i = 1; i < condition.length; i += 1) {
    const elem = condition[i]
    if (Array.isArray(elem) && !validateCondition(elem)) {
      return false
    }
    if (typeof elem === 'string' && i !== 2 && !elem) {
      return false
    }
  }
  return true
}

export const validateConditions = (conditions: Condition[]) =>
  conditions.reduce((acc, condition) => acc && validateCondition(condition), true)

export const validateConditionsValue = (value: string) => {
  if (!value) {
    return false
  }
  try {
    const parsedValue = JSON.parse(value) as Condition[]
    return validateConditions(parsedValue)
  } catch {
    return false
  }
}

export const segmentSchema = schema.extend({
  conditions: z.string().refine(validateConditionsValue),
})
