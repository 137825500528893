import { uniq } from 'ramda'

import {
  Action,
  AnswerCategory,
  APIMessage,
  CampaignTemplateType,
  CATIQuestion,
  LocalAction,
  LocalActionType,
  OnAnswerType,
  OpenEnded,
  PartFamily,
  SMSQuestion,
} from '@/app/module/campaigns/types'
import { getValidActionsForCampaignType } from '@/app/module/campaigns/utils/actions/list'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { unwrapActions, wrapActions } from '@/app/module/campaigns/utils/actions/wrap'

type ValidationResult =
  | {
      action: LocalAction
      error: null
    }
  | {
      action: null
      error: string
    }

function validateAction(action: LocalAction, partIds: string[], supportedTypes: LocalActionType[]): ValidationResult {
  if (!action.type) {
    // empty actions objects are appended to the end of the list by the UI, ignoring these
    return {
      action,
      error: null,
    }
  }
  const isIncluded = supportedTypes.includes(action.type)

  if (!isIncluded) {
    return {
      action: null,
      error: `Action type "${action.type}" is not supported in this campaign, these action types have been removed.`,
    }
  }

  if (action.type === LocalActionType.Jump) {
    const isValid = !!partIds.find((id) => {
      return id === action.jump.nextPart
    })

    if (!isValid) {
      return {
        action: null,
        error: `We have found jump actions which are referencing inexistent targets, these actions have been removed.`,
      }
    }
  }

  return {
    action,
    error: null,
  }
}

type FilteredActions<T = Action> = {
  actions: T[]
  errors: string[]
}

function filterActions(
  partType: PartFamily,
  campaignType: CampaignTemplateType,
  partIds: string[],
  savedActions: Action[],
  namespace:
    | ActionContext.OnAnswer
    | ActionContext.OnTimeout
    | ActionContext.OnInvalid
    | ActionContext.OnFailure
    | ActionContext.OpenEnded
    | ActionContext.OpenEndedCategory
    | ActionContext.ApiSuccess
    | ActionContext.ApiFailure,
): { actions: Action[]; errors: string[] } {
  const baseActions = getValidActionsForCampaignType({
    campaignType,
    partType,
    actionNamespace: namespace,
  })
  const unwappedActions = unwrapActions(savedActions)

  return unwappedActions.reduce<FilteredActions>(
    (acc, item) => {
      const { action, error } = validateAction(item, partIds, baseActions)
      const wrappedActions = action ? wrapActions([action]) : []

      return {
        actions: acc.actions.concat(wrappedActions),
        errors: error ? uniq([...acc.errors, error]) : acc.errors,
      }
    },
    {
      actions: [],
      errors: [],
    },
  )
}

type FilteredOpenEndedCategories = {
  errors: string[]
  categories: AnswerCategory[]
}

function filterOpenEnded(
  partType: PartFamily,
  campaignType: CampaignTemplateType,
  partIds: string[],
  source: OpenEnded | undefined,
) {
  const openEnded: OpenEnded = {}
  const errors = []

  if (!source) {
    return {
      filteredOpenEnded: undefined,
      openEndedErrors: [],
    }
  }

  if (source.actions) {
    const { actions, errors: openEndedErrors } = filterActions(
      partType,
      campaignType,
      partIds,
      source.actions,
      ActionContext.OpenEnded,
    )

    openEnded.actions = actions
    errors.push(...openEndedErrors)
  }

  if (source.categories) {
    const filteredOpenEndedCategories = source.categories.reduce<FilteredOpenEndedCategories>(
      (acc, category) => {
        const { actions, errors: openEndedCategoryErrors } = filterActions(
          partType,
          campaignType,
          partIds,
          category.actions || [],
          ActionContext.OpenEndedCategory,
        )
        acc.errors.push(...openEndedCategoryErrors)
        acc.categories.push({
          ...category,
          actions,
        })
        return acc
      },
      {
        categories: [],
        errors: [],
      },
    )

    openEnded.categories = filteredOpenEndedCategories.categories
    errors.push(...filteredOpenEndedCategories.errors)
  }

  return { filteredOpenEnded: openEnded, openEndedErrors: errors }
}

type FilteredAnswers = {
  errors: string[]
  answers: OnAnswerType[]
}

export function filterSMSQuestionActions<T extends SMSQuestion>(
  message: T,
  campaignType: CampaignTemplateType,
  nextIndex: number,
  partIds: string[],
  onNotify: (errors: string[]) => void,
): T {
  const { onAnswer = [], onTimeout, onInvalidReply, onRetriesExhausted, openEnded, ...props } = message
  const nextIds = partIds.slice(nextIndex)

  const filteredOnAnswer = onAnswer.reduce<FilteredAnswers>(
    (acc, answer) => {
      const { actions, errors } = filterActions(
        PartFamily.SMS,
        campaignType,
        nextIds,
        answer.actions || [],
        ActionContext.OnAnswer,
      )
      acc.errors.push(...errors)
      acc.answers.push({
        ...answer,
        actions,
      })
      return acc
    },
    {
      answers: [],
      errors: [],
    },
  )

  const filteredTimeoutActions = filterActions(
    PartFamily.SMS,
    campaignType,
    nextIds,
    onTimeout.actions || [],
    ActionContext.OnTimeout,
  )
  const filteredInvalidReplyActions = filterActions(
    PartFamily.SMS,
    campaignType,
    nextIds,
    onInvalidReply.actions || [],
    ActionContext.OnInvalid,
  )
  const filteredRetriesExhaustedActions = filterActions(
    PartFamily.SMS,
    campaignType,
    nextIds,
    onRetriesExhausted.actions || [],
    ActionContext.OnFailure,
  )
  const { filteredOpenEnded, openEndedErrors } = filterOpenEnded(PartFamily.SMS, campaignType, nextIds, openEnded)

  const errors = filteredOnAnswer.errors.concat(
    filteredTimeoutActions.errors,
    filteredInvalidReplyActions.errors,
    filteredRetriesExhaustedActions.errors,
    openEndedErrors,
  )

  if (errors.length) {
    onNotify(errors)
  }

  return {
    ...props,
    openEnded: filteredOpenEnded,
    onAnswer: filteredOnAnswer.answers,
    onTimeout: {
      ...onTimeout,
      actions: filteredTimeoutActions.actions,
    },
    onInvalidReply: {
      ...onInvalidReply,
      actions: filteredInvalidReplyActions.actions,
    },
    onRetriesExhausted: {
      ...onRetriesExhausted,
      actions: filteredRetriesExhaustedActions.actions,
    },
  } as T
}

export function filterApiActions<T extends APIMessage>(
  message: T,
  campaignType: CampaignTemplateType,
  nextIndex: number,
  partIds: string[],
  onNotify: (errors: string[]) => void,
): T {
  const { apiCall, ...props } = message
  const nextIds = partIds.slice(nextIndex)

  const filteredSuccessActions = filterActions(
    PartFamily.API,
    campaignType,
    nextIds,
    apiCall.onSuccess || [],
    ActionContext.ApiSuccess,
  )

  const filteredErrorActions = filterActions(
    PartFamily.API,
    campaignType,
    nextIds,
    apiCall.onError || [],
    ActionContext.ApiFailure,
  )

  const errors = filteredSuccessActions.errors.concat(filteredErrorActions.errors)

  if (errors.length) {
    onNotify(errors)
  }

  return {
    ...props,
    apiCall: {
      ...apiCall,
      onSuccess: filteredSuccessActions.actions,
      onError: filteredErrorActions.actions,
    },
  } as T
}

export function filterCATIQuestionActions(
  message: CATIQuestion,
  campaignType: CampaignTemplateType,
  nextIndex: number,
  partIds: string[],
  onNotify: (errors: string[]) => void,
): CATIQuestion {
  const { onAnswer = [], openEnded, ...props } = message
  const nextIds = partIds.slice(nextIndex)

  const filteredOnAnswer = onAnswer.reduce<FilteredAnswers>(
    (acc, answer) => {
      const { actions, errors } = filterActions(
        PartFamily.CATI,
        campaignType,
        nextIds,
        answer.actions || [],
        ActionContext.OnAnswer,
      )
      acc.errors.push(...errors)
      acc.answers.push({
        ...answer,
        actions,
      })
      return acc
    },
    {
      answers: [],
      errors: [],
    },
  )

  const { filteredOpenEnded, openEndedErrors } = filterOpenEnded(PartFamily.CATI, campaignType, nextIds, openEnded)

  const errors = filteredOnAnswer.errors.concat(openEndedErrors)

  if (errors.length) {
    onNotify(errors)
  }

  return {
    ...props,
    openEnded: filteredOpenEnded,
    onAnswer: filteredOnAnswer.answers,
  }
}
