import { backdropClasses } from '@mui/material'
import { green, red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { renderTimeViewClock } from '@mui/x-date-pickers'
import { compose, find, pathEq, pathOr } from 'ramda'

import config from './index'

const fontFamily = compose(
  pathOr('', ['family']),
  find(pathEq(['name'], 'font-main')),
  pathOr([], ['vendor', 'css']),
)(config)

const renderClock = (props: Parameters<typeof renderTimeViewClock>[0]) =>
  renderTimeViewClock({
    ...props,
    views: ['hours'],
  })

export default createTheme({
  typography: {
    htmlFontSize: 16,
    fontFamily,
    body1: {
      fontSize: '0.875rem',
    },
  },
  palette: {
    primary: {
      main: '#2f80ac',
      light: '#5899bc',
      dark: '#205978',
    },
    secondary: {
      main: '#ef6c00',
      light: '#f28933',
      dark: '#a74b00',
    },
    success: {
      main: green.A700,
    },
    error: {
      main: red.A200,
    },
    highlight: {
      light: 'rgba(0, 0, 0, 0.06)',
    },
    background: {
      default: '#fafafa',
    },
    border: {
      main: 'rgb(224, 224, 224)',
    },
  },
  shadows: [
    'none',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    '0px 0px 0px 1px rgba(224, 224, 224, 1)',
  ],
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        style: {},
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '& tr:last-child td': {
            border: 'none',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        startIcon: {
          '& .MuiIcon-root': {
            fontSize: '1.5rem',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '8px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',

          '@media (min-width: 600px)': {
            minWidth: '160px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '24px 16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px 20px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '16px 20px',
          justifyContent: 'flex-end',
          '& button:not(:last-child)': {
            marginRight: '0.5rem',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          [`&:has(div.${backdropClasses.root}[style*="opacity: 0"])`]: {
            // to fix an issue with the backdrop not being removed and blocks the view
            display: 'none',
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderTop: '1px solid rgba(224, 224, 224, 1)',
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '48px',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 16px',
        },
        head: {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          backgroundColor: '#fff',
          border: '2px solid currentColor',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    // @ts-ignore
    MuiDesktopTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderClock,
          minutes: renderClock,
          seconds: renderClock,
        },
      },
    },
    MuiDesktopDateTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderClock,
          minutes: renderClock,
          seconds: renderClock,
        },
      },
    },
  },
})
