import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Logs from '@/app/module/logs'
import Notifications from '@/app/module/notifications'

export default function LogsPage(props) {
  const { name, routes, match } = props
  const params = new URLSearchParams(props.location.search)
  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <Layout title="Logs - engageSPARK">
        <AuthHeader currentPage={PAGE.LOGS.INDEX} />
        <Logs
          search={params.get('search') ? decodeURIComponent(params.get('search')) : ''}
          type={params.get('type')}
          status={params.get('status')}
          tab={params.get('tab')}
          to={params.get('to')}
          from={params.get('from')}
        />
      </Layout>
      <Notifications />
    </AuthGuard>
  )
}
