import { Button, ButtonProps } from '@mui/material'
import React from 'react'

import Span from '@/app/component/atom/span'
import CustomTooltip from '@/app/component/atom/tooltip'

type Props = ButtonProps & {
  isDirty: boolean
  isSubmitting: boolean
  isValid: boolean
  tooltipDirty?: React.ReactNode
  tooltipSubmitting?: React.ReactNode
  tooltipValid?: React.ReactNode
}

const SubmitButton: React.FC<Props> = ({
  disabled: buttonDisabled,
  isDirty,
  isSubmitting,
  isValid,
  tooltipDirty = 'No changes have been made',
  tooltipSubmitting = 'Saving changes',
  tooltipValid = 'There are invalid fields, please address them before saving',
  ...props
}) => {
  const disabled = buttonDisabled || !isDirty || !isValid
  const tooltip = React.useMemo(() => {
    if (isSubmitting) {
      return tooltipSubmitting
    }
    if (!isDirty) {
      return tooltipDirty
    }
    if (!isValid) {
      return tooltipValid
    }
    return ''
  }, [isDirty, isValid, isSubmitting, tooltipDirty, tooltipSubmitting, tooltipValid])

  return (
    <CustomTooltip title={tooltip}>
      <Span data-testid="submit-button">
        <Button
          color="primary"
          disabled={disabled}
          loading={isSubmitting}
          type="submit"
          variant="contained"
          {...props}
        />
      </Span>
    </CustomTooltip>
  )
}

export default SubmitButton
export type { Props as SubmitButtonProps }
