import { has, omit } from 'ramda'

import { DEFAULT_SENDER_ID_VALUE } from '@/app/definitions'
import { cannot } from '@/app/helpers'
import { LanguageType } from '@/app/module/campaigns/helpers'
import { changeActionsLang, processText } from '@/app/module/campaigns/language-helpers'
import isCampaignClass from '@/app/module/campaigns/models/guard'
import CampaignInterface from '@/app/module/campaigns/models/interfaces/campaign.interface'
import { isActionsOnly, isSMSMessage, isSMSQuestion } from '@/app/module/campaigns/models/message-guard'
import {
  createNewReminderMessage,
  duplicateReminderMessage,
} from '@/app/module/campaigns/models/utils/reminder-message'
import {
  processActionForSaving,
  processActionsInLists,
  processActionsInObjects,
} from '@/app/module/campaigns/store/selectors'
import {
  BackendCampaignTemplate,
  CallSchedule,
  CallWindow,
  CampaignTemplateType,
  Launch,
  PartFamily,
  PartTypes,
  ReminderDefaultArgs,
  ReminderMessage,
  ReminderPart,
} from '@/app/module/campaigns/types'
import { ReminderCampaignDTO } from '@/app/module/campaigns/types/dtos'
import { ZReminderCampaignDTO } from '@/app/module/campaigns/types/schemas/campaigns/reminder'
import { processOpenEndedActions } from '@/app/module/campaigns/utils/process-actions'
import { generateCampaignName } from '@/app/module/campaigns/utils/transform'
import { formatDateTime } from '@/app/service/util'
import { captureError } from '@/app/service/util/error'
import { FilesType, SelectOption } from '@/app/types'

export default class ReminderCampaignClass implements CampaignInterface<ReminderCampaignDTO> {
  aborted: boolean = false

  abortedAt?: string

  amd: boolean = false

  archived: boolean = false

  autoLaunchAt?: string

  callAgainCustom: CallSchedule[] = []

  callAgainMax: number = 0

  callWindow: CallWindow = {}

  contacts: {
    allContactsAreSelected: boolean
    excludedContactIds: number[]
    includedContactIds: number[]
    includedGroupIds: number[]
    search: string
  } = {
    allContactsAreSelected: false,
    excludedContactIds: [],
    includedContactIds: [],
    includedGroupIds: [],
    search: '',
  }

  createdAt?: string

  files: FilesType = {}

  id?: number

  launch: Launch = {
    immediate: true,
  }

  launchOpen: boolean = true

  launched: boolean = false

  launchedAt?: string

  name: string = ''

  open: boolean = true

  published: boolean = false

  reconnectMax: number = 0

  reconnectOnProgress: boolean = false

  runs: number = 0

  runsDone: number = 0

  runsError: number = 0

  runsInProgress: number = 0

  runsPaused: number = 0

  runsWithFailures: number = 0

  readonly template: BackendCampaignTemplate.Reminder = BackendCampaignTemplate.Reminder

  uiStore: Record<string, any> = {}

  userStatuses: string[] | null = []

  variables: ReminderCampaignDTO['variables'] = {
    defaultArgs: {
      t0: '',
      t0timezone: '',
    },
    parts: [],
    senderId: '',
    senderIdQuestions: '',
    senderIdReplies: '',
  }

  version: number = 0

  // UI model properties
  readonly defaultMessageType = PartFamily.SMS

  readonly supportedMessageTypes: SelectOption<string>[] = [
    {
      label: 'SMS Message',
      value: 'sms-m',
    },
    {
      label: 'SMS Question',
      value: 'sms-q',
    },
    {
      label: 'Actions Only',
      value: 'action-q',
    },
  ]

  readonly type = CampaignTemplateType.Reminder

  constructor(dto?: unknown) {
    if (dto) {
      this.fromDTO(dto)
    } else {
      this.name = generateCampaignName(this)
    }
  }

  fromDTO(dto: unknown): void {
    const validated = ZReminderCampaignDTO.parse(dto)

    this.aborted = validated.aborted
    this.abortedAt = validated.abortedAt
    this.amd = validated.amd
    this.archived = validated.archived
    this.autoLaunchAt = validated.autoLaunchAt
    this.contacts = validated.contacts
    this.createdAt = validated.createdAt
    this.files = validated.files
    this.id = validated.id
    this.launch = validated.launch
    this.launchOpen = validated.launchOpen
    this.launched = validated.launched
    this.launchedAt = validated.launchedAt
    this.name = validated.name
    this.open = validated.open
    this.published = validated.published
    this.runs = validated.runs
    this.runsDone = validated.runsDone
    this.runsError = validated.runsError
    this.runsInProgress = validated.runsInProgress
    this.runsPaused = validated.runsPaused
    this.runsWithFailures = validated.runsWithFailures
    this.uiStore = validated.uiStore
    this.userStatuses = validated.userStatuses || []
    this.variables = validated.variables
  }

  toDTO() {
    const { template, variables } = this
    const { defaultSenderId, senderId, senderIdQuestions, senderIdReplies } = variables
    const name = generateCampaignName(this)
    return {
      ...omit(
        [
          'createNewMessage',
          'defaultMessageType',
          'duplicateMessage',
          'fromMessages',
          'getPart',
          'supportedMessageTypes',
          'toMessage',
          'template',
          'transformMessage',
          'type',
        ],
        this,
      ),
      template,
      name,
      published: this.published || false,
      launchOpen: this.launchOpen === undefined ? true : this.launchOpen,
      launch:
        !this.launch || this.launch.immediate
          ? {
              immediate: true,
            }
          : {
              ...this.launch,
              at: this.launch.at ? formatDateTime(this.launch.at, this.launch.timezone || '') : undefined,
            },
      variables: {
        ...this.variables,
        defaultSenderId,
        senderId: senderId === DEFAULT_SENDER_ID_VALUE ? '' : senderId,
        senderIdQuestions,
        senderIdReplies,
        ...this.cleanupActionsInVariables(),
      },
    }
  }

  cleanupActionsInVariables() {
    const { senderId, senderIdQuestions } = this.variables

    const parts = this.variables.parts.map((vPart) => {
      const part = this.getPart(vPart)
      if (has(PartTypes.ActionsOnly, part)) {
        return {
          ...part,
          [PartTypes.ActionsOnly]: processActionsInObjects(
            part[PartTypes.ActionsOnly],
            { senderId, senderIdQuestions },
            processActionForSaving,
          ),
        }
      }
      if (has(PartTypes.SMSQuestion, part)) {
        const openEnded = processActionsInObjects(
          part[PartTypes.SMSQuestion].openEnded,
          { senderId, senderIdQuestions },
          processActionForSaving,
        )
        if (openEnded?.categories) {
          openEnded.categories = processActionsInLists(
            openEnded.categories,
            { senderId, senderIdQuestions },
            processActionForSaving,
          )
        }

        return {
          ...part,
          [PartTypes.SMSQuestion]: {
            ...part[PartTypes.SMSQuestion],
            onAnswer: processActionsInLists(
              part[PartTypes.SMSQuestion].onAnswer,
              { senderId, senderIdQuestions },
              processActionForSaving,
            ),
            onInvalidReply: processActionsInObjects(
              part[PartTypes.SMSQuestion].onInvalidReply,
              { senderId, senderIdQuestions },
              processActionForSaving,
            ),
            onRetriesExhausted: processActionsInObjects(
              part[PartTypes.SMSQuestion].onRetriesExhausted,
              { senderId, senderIdQuestions },
              processActionForSaving,
            ),
            onTimeout: processActionsInObjects(
              part[PartTypes.SMSQuestion].onTimeout,
              { senderId, senderIdQuestions },
              processActionForSaving,
            ),
            openEnded: processOpenEndedActions(
              part[PartTypes.SMSQuestion].openEnded,
              { senderId, senderIdQuestions },
              processActionForSaving,
            ),
          },
        }
      }

      return part
    })

    return {
      parts,
    }
  }

  getPart = (part: ReminderPart): ReminderPart => {
    const { id, label, offset, when } = part

    if (has(PartTypes.ActionsOnly, part)) {
      return {
        id,
        label,
        offset,
        when,
        [PartTypes.ActionsOnly]: part[PartTypes.ActionsOnly],
      }
    }

    if (has(PartTypes.SMSMessage, part)) {
      return {
        id,
        label,
        offset,
        when,
        [PartTypes.SMSMessage]: part[PartTypes.SMSMessage],
      }
    }

    if (has(PartTypes.SMSQuestion, part)) {
      return {
        id,
        label,
        offset,
        when,
        [PartTypes.SMSQuestion]: part[PartTypes.SMSQuestion],
      }
    }

    return part
  }

  /**
   * Change the language of the campaign and update the parts and their actions with the new language
   * This is a local operation, so we don't need to call getPart
   */
  changeLanguage(newLanguages: LanguageType[], newDefaultLanguage: string) {
    const { languages, defaultLanguage } = this.uiStore
    const languageConfig = { languages, newLanguages, defaultLanguage, newDefaultLanguage }

    const parts = this.variables.parts.map((part) => {
      if (has(PartTypes.ActionsOnly, part)) {
        return {
          ...part,
          [PartTypes.ActionsOnly]: processActionsInObjects(
            part[PartTypes.ActionsOnly],
            languageConfig,
            changeActionsLang,
          ),
        }
      }
      if (has(PartTypes.SMSMessage, part)) {
        return {
          ...part,
          [PartTypes.SMSMessage]: {
            message: processText(part[PartTypes.SMSMessage].message, languageConfig),
          },
        }
      }
      if (has(PartTypes.SMSQuestion, part)) {
        const openEnded = processActionsInObjects(
          part[PartTypes.SMSQuestion].openEnded,
          languageConfig,
          changeActionsLang,
        )
        if (openEnded?.categories) {
          openEnded.categories = processActionsInLists(
            part[PartTypes.SMSQuestion].onAnswer,
            languageConfig,
            changeActionsLang,
          )
        }
        return {
          ...part,
          [PartTypes.SMSQuestion]: {
            ...part[PartTypes.SMSQuestion],
            message: processText(part[PartTypes.SMSQuestion].message, languageConfig),
            onAnswer: processActionsInLists(part[PartTypes.SMSQuestion].onAnswer, languageConfig, changeActionsLang),
            onInvalidReply: processActionsInObjects(
              part[PartTypes.SMSQuestion].onInvalidReply,
              languageConfig,
              changeActionsLang,
            ),
            onRetriesExhausted: processActionsInObjects(
              part[PartTypes.SMSQuestion].onRetriesExhausted,
              languageConfig,
              changeActionsLang,
            ),
            onTimeout: processActionsInObjects(
              part[PartTypes.SMSQuestion].onTimeout,
              languageConfig,
              changeActionsLang,
            ),
            openEnded: processOpenEndedActions(
              part[PartTypes.SMSQuestion].openEnded,
              languageConfig,
              changeActionsLang,
            ),
          },
        }
      }

      return part
    })

    return this.update({
      uiStore: {
        ...this.uiStore,
        languages: newLanguages,
        defaultLanguage: newDefaultLanguage,
      },
      variables: { ...this.variables, parts },
    })
  }

  /**
   * Change defaultArgs
   */
  updateDefaultArgs(data: ReminderDefaultArgs) {
    return this.update({
      variables: {
        ...this.variables,
        defaultArgs: {
          ...this.variables.defaultArgs,
          ...data,
        },
      },
    })
  }

  toMessage = (part: ReminderPart): ReminderMessage => {
    const baseMessage = {
      id: part.id,
      changed: false,
      label: part.label,
      offset: part.offset,
      when: part.when,
    }

    if (has(PartTypes.ActionsOnly, part)) {
      return {
        ...baseMessage,
        hasQuestion: true,
        type: PartFamily.Action,
        [PartTypes.ActionsOnly]: part[PartTypes.ActionsOnly],
      }
    }

    if (has(PartTypes.SMSMessage, part)) {
      return {
        ...baseMessage,
        ...part[PartTypes.SMSMessage],
        hasQuestion: false,
        type: PartFamily.SMS,
      }
    }

    if (has(PartTypes.SMSQuestion, part)) {
      return {
        ...baseMessage,
        ...part[PartTypes.SMSQuestion],
        hasQuestion: true,
        type: PartFamily.SMS,
        onAnswer: part[PartTypes.SMSQuestion].onAnswer || [],
        onInvalidReply: {
          actions: part[PartTypes.SMSQuestion].onInvalidReply?.actions || [],
        },
        onRetriesExhausted: {
          actions: part[PartTypes.SMSQuestion].onRetriesExhausted?.actions || [],
        },
        onTimeout: {
          actions: part[PartTypes.SMSQuestion].onTimeout?.actions || [],
          timeout: part[PartTypes.SMSQuestion].onTimeout?.timeout,
          timeoutSeconds: part[PartTypes.SMSQuestion].onTimeout?.timeoutSeconds,
        },
        openEnded: part[PartTypes.SMSQuestion].openEnded || undefined,
      }
    }

    return cannot(part)
  }

  /**
   * Returns a list of messages indexed by the partId for multimessage component
   */
  toMessages() {
    return this.variables.parts.reduce<Record<string, ReminderMessage>>((acc, part) => {
      const message = this.toMessage(this.getPart(part))

      return {
        ...acc,
        [message.id]: message,
      }
    }, {})
  }

  /**
   * Returns an array of parts based on the messages provided
   */
  fromMessages = (messages: Record<string, ReminderMessage>, orderedIds: string[]) =>
    orderedIds.map((id) => {
      const { label, offset, ...rest } = messages[id]

      if (isActionsOnly(rest)) {
        return {
          id,
          label,
          offset,
          when: rest.when,
          [PartTypes.ActionsOnly]: rest[PartTypes.ActionsOnly],
        }
      }

      if (isSMSMessage(rest)) {
        return {
          id,
          label,
          offset,
          when: rest.when,
          [PartTypes.SMSMessage]: {
            message: rest.message,
          },
        }
      }

      if (isSMSQuestion(rest)) {
        return {
          id,
          label,
          offset,
          when: rest.when,
          [PartTypes.SMSQuestion]: {
            message: rest.message,
            onAnswer: rest.onAnswer || [],
            onInvalidReply: {
              actions: rest.onInvalidReply?.actions || [],
            },
            onRetriesExhausted: {
              actions: rest.onRetriesExhausted?.actions || [],
            },
            onTimeout: {
              actions: rest.onTimeout?.actions || [],
              timeout: rest.onTimeout?.timeout,
              timeoutSeconds: rest.onTimeout?.timeoutSeconds,
            },
            openEnded: rest.openEnded || undefined,
            retries: rest.retries,
            useAi: rest.useAi,
          },
        }
      }

      return cannot(rest as never)
    })

  /**
   * Create a new message based on the previous message, or default message if none is provided
   */
  static createNewMessage = createNewReminderMessage

  /**
   * Duplicate a message
   */
  static duplicateMessage = duplicateReminderMessage

  update(data: Partial<CampaignInterface<ReminderCampaignDTO>>) {
    const classInstance = new ReminderCampaignClass()
    Object.assign(classInstance, this)
    Object.assign(classInstance, data)
    return classInstance
  }
}

export const createReminderInstance = (campaignDto?: unknown) => {
  try {
    return new ReminderCampaignClass(campaignDto)
  } catch (error) {
    captureError(error)
    return undefined
  }
}

export const isReminderCampaign = (obj: any): obj is ReminderCampaignClass =>
  isCampaignClass(obj) && obj.template === BackendCampaignTemplate.Reminder
