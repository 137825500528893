import moment from 'moment'
import { Mp3MediaRecorder } from 'mp3-mediarecorder'
import { Component } from 'react'

import AudioAnalyser from './analyser'
import AudioCounter from './counter'
import Mp3RecorderWorker from './worker?worker'
import AudioWrapper from './wrapper'

type Props = {
  isRecording: boolean
  converterPath: string
  onFinish: (file: File) => Promise<void>
  onDisabled: () => void
}

type State = {
  current: moment.Duration
  recorder: Mp3MediaRecorder | null
}

export default class AudioRecorder extends Component<Props, State> {
  private analyser: AnalyserNode | undefined

  state: State = {
    current: moment.duration(0, 'seconds'),
    recorder: null,
  }

  componentDidMount(): void {
    if (this.props.isRecording) {
      this.startRecording()
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (!prevProps.isRecording && this.props.isRecording) {
      this.startRecording()
    }
    if (prevProps.isRecording && !this.props.isRecording) {
      this.stopRecording()
    }
  }

  startRecording(): void {
    const { onFinish, onDisabled } = this.props

    const context = new AudioContext()
    this.analyser = context.createAnalyser()

    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false,
      })
      .then((stream) => {
        if (!this.analyser) {
          return
        }

        let blob: Blob
        const ref = setInterval(() => {
          this.setState((state) => ({
            current: state.current.add(1, 'seconds'),
          }))
        }, 1000)

        const src = context.createMediaStreamSource(stream)
        src.connect(this.analyser)

        const recorder = new Mp3MediaRecorder(stream, { worker: new Mp3RecorderWorker() })
        recorder.ondataavailable = (event: BlobEvent) => {
          blob = event.data
        }

        recorder.onstop = () => {
          stream.getTracks().forEach((track) => track.stop())
          src.disconnect()
          onFinish(blob as File)
          clearInterval(ref)
          this.setState({
            current: moment.duration(0, 'seconds'),
          })
        }

        recorder.start()

        this.setState({
          recorder,
        })
      })
      .catch(() => {
        onDisabled()
      })
  }

  stopRecording(): void {
    if (this.state.recorder) {
      this.state.recorder.stop()
    }
    if (this.analyser) {
      this.analyser.disconnect()
    }
  }

  render() {
    const { isRecording } = this.props

    return (
      <AudioWrapper>
        <div data-testid="recorder-container">
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%',
            }}
          >
            <AudioAnalyser isPlaying={!!this.state.recorder && isRecording} analyser={this.analyser} />
          </div>
        </div>
        <AudioCounter current={this.state.current.format('mm:ss', { trim: false })} />
      </AudioWrapper>
    )
  }
}
