import { z } from 'zod'

import { ReceiptLines } from '@/app/types'

export const linesSchema = z.object({
  line1: z.string().optional(),
  line2: z.string().optional(),
  line3: z.string().optional(),
  line4: z.string().optional(),
  line5: z.string().optional(),
})

export const linesDefaultValues: ReceiptLines = {
  line1: '',
  line2: '',
  line3: '',
  line4: '',
  line5: '',
}

export const linesFields = {
  line1: { fieldProps: { label: 'Line 1', name: 'line1' }, type: 'text' },
  line2: { fieldProps: { label: 'Line 2', name: 'line2' }, type: 'text' },
  line3: { fieldProps: { label: 'Line 3', name: 'line3' }, type: 'text' },
  line4: { fieldProps: { label: 'Line 4', name: 'line4' }, type: 'text' },
  line5: { fieldProps: { label: 'Line 5', name: 'line5' }, type: 'text' },
} as const
