import { Icon, styled, Typography } from '@mui/material'
import React from 'react'

type Props = {
  document: string
  onClick: () => void
}

const WhatsappDocument: React.FC<Props> = ({ document, onClick }) => (
  <Container onClick={onClick}>
    <Icon color="action">insert_drive_file</Icon>
    <Document>{document}</Document>
  </Container>
)

const Container = styled('div')(() => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
}))

const Document = styled(Typography)(({ theme }) => ({
  color: theme.palette.action.active,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '20px',
  marginLeft: theme.spacing(),
  overflow: 'hidden',
  textAlign: 'left',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
}))

export default WhatsappDocument
