import React, { PropsWithChildren } from 'react'

import Loading from '@/app/component/guard/loading'
import { noop } from '@/app/definitions'
import Notifications from '@/app/module/notifications/component'
import { useRecaptchaLoaded } from '@/app/module/utils/use-recaptcha'

type Props = PropsWithChildren<{
  loadTimeout?: number
}>

const RECAPTCHA_TIMEOUT = 30000

const RecaptchaGuard: React.FC<Props> = ({ children, loadTimeout = RECAPTCHA_TIMEOUT }) => {
  const isRecaptchaLoaded = useRecaptchaLoaded()
  const [isLoading, setIsLoading] = React.useState(true)
  const [timedOut, setTimedOut] = React.useState(false)

  React.useEffect(() => {
    if (isRecaptchaLoaded) {
      setIsLoading(false)
      setTimedOut(false)
      return noop
    }

    // if not loaded, set a timeout to show an error message
    const timeout = setTimeout(() => {
      setTimedOut(true)
    }, loadTimeout)

    return () => {
      clearTimeout(timeout)
    }
  }, [isRecaptchaLoaded, loadTimeout])

  if (isRecaptchaLoaded) {
    return children
  }

  return (
    <Loading isLoading={isLoading} testId="recaptcha-loading">
      {children}
      {timedOut && (
        <Notifications
          data={{
            'recaptcha-timeout': {
              type: 'error',
              disableAutoHide: true,
              message:
                'We could not load the Google Recaptcha, a technology that helps prevent automated signups. Please reload the page. If the problem persists, please check any plugins that might block the captcha from working.',
            },
          }}
          createNotification={noop}
        />
      )}
    </Loading>
  )
}

export default RecaptchaGuard
