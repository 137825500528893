import { styled, Typography } from '@mui/material'
import React from 'react'

import { useDebouncedState, useDeepState } from '@/app/helpers'
import { canTranscribe } from '@/app/module/campaigns/ai-helpers'
import ActionsBox from '@/app/module/campaigns/component/actions/actions-box'
import { CAMPAIGN_CHANGE_DELAY, DRAGTYPE, SMS_ACTION_DEFAULT_SENDERID } from '@/app/module/campaigns/definitions'
import { CampaignTemplateType, ContentAudio, LocalAction, PartFamily, SpokenType } from '@/app/module/campaigns/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'

import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import ResponseVoiceSpokenConfig from './response-voice-spoken-config'
import { fromSpokenValue, SpokenValue, SpokenValueState, toSpokenValue } from './response-voice-spoken-utils'
import TranscribeToggle from './transcribe-toggle'
import VoiceProcessing from './voice-processing'

type Props = {
  actionProps: ActionProps
  apiProps: ApiProps
  campaignType: CampaignTemplateType
  id: string
  snippetProps: SnippetProps
  spoken: SpokenValue

  onChange: (value: SpokenValue) => void
}

const ResponseVoiceSpoken: React.FC<Props> = ({
  actionProps,
  apiProps,
  campaignType,
  id,
  snippetProps,
  spoken,
  onChange,
}) => {
  const initialState = React.useMemo(() => fromSpokenValue(spoken), [spoken])
  const [state, setState] = useDeepState<SpokenValueState>(initialState)
  const handleChange = React.useCallback((value: SpokenValueState) => onChange(toSpokenValue(value)), [onChange])

  useDebouncedState(state, handleChange, CAMPAIGN_CHANGE_DELAY)

  const answerActions = React.useMemo(
    () =>
      getActionOptionsForCampaign({
        campaignType,
        partType: PartFamily.Voice,
        actionNamespace: ActionContext.OnAnswer,
      }),
    [campaignType],
  )

  const handleTranscribeChange = React.useCallback(
    (transcribe: boolean) => setState((s) => ({ ...s, spoken: { ...s.spoken, transcribe } })),
    [setState],
  )
  const handleProcessingReplyChange = React.useCallback(
    (value?: ContentAudio) => setState((s) => ({ ...s, processingReply: value })),
    [setState],
  )
  const handleActionsChange = React.useCallback(
    (actions: LocalAction[]) => setState((s) => ({ ...s, spoken: { ...s.spoken, actions } })),
    [setState],
  )
  const handleConfigChange = React.useCallback(
    (config: Partial<SpokenType>) => setState((s) => ({ ...s, spoken: { ...s.spoken, ...config } })),
    [setState],
  )

  const hasTranscribe = canTranscribe(campaignType)

  return (
    <div className="campaign-spoken-tab-content">
      <Typography>Spoken Response</Typography>
      <div>
        <Typography color="textSecondary" variant="caption" gutterBottom>
          Specify what actions we should take after recording
        </Typography>
        <ToggleContainer>
          <TranscribeToggle
            disabled={!hasTranscribe}
            transcribe={state.spoken.transcribe}
            onChange={handleTranscribeChange}
          />
          {state.spoken.transcribe && (
            <VoiceProcessing
              defaultLanguage={actionProps.defaultLanguage}
              id={id}
              languages={actionProps.languages}
              processingReply={state.processingReply}
              getVoiceProps={actionProps.getVoiceProps}
              onChange={handleProcessingReplyChange}
            />
          )}
        </ToggleContainer>
        <ActionsBox
          {...actionProps}
          {...apiProps}
          {...snippetProps}
          actionOptions={answerActions}
          actions={state.spoken.actions}
          defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.voiceQuestion}
          dragContext={`${DRAGTYPE}-choice-spoken`}
          hasTranscribe={hasTranscribe}
          isCloseDisabled
          spoken={state.spoken}
          transcribeEnabled={state.spoken.transcribe}
          updateContactConfig={updateContactConfig}
          voiceId={`${id}-spoken`}
          onChange={handleActionsChange}
        >
          <ResponseVoiceSpokenConfig spoken={state.spoken} onChange={handleConfigChange} />
        </ActionsBox>
      </div>
    </div>
  )
}

const updateContactConfig = {
  invalid: false,
  openResponse: false,
  question: false,
}

const ToggleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

export default ResponseVoiceSpoken

export type { Props as ResponseVoiceSpokenProps }
