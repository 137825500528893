import WhatsappIcon from '@mui/icons-material/WhatsApp'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import React from 'react'

import WhatsApp from '@/app/component/atom/whatsapp'
import { DEFAULT_SENDER_ID_VALUE } from '@/app/definitions'
import { ContentWhatsApp, PersonalizationType } from '@/app/module/campaigns/types'
import { SenderIdType } from '@/app/module/conversations/types'
import Message from '@/app/module/logs/component/send/message'
import { FileType } from '@/app/types'
import { FixMeLater } from '@/config/types'

type Props = {
  personalizationList: PersonalizationType
  whatsappSenderIds: []
  smsSenderIds: []
  contactId: number
  sendMessage: FixMeLater
  sendWhatsappMessage: FixMeLater
  getFile: FixMeLater
  saveFile: FixMeLater
  onMicAccessError: FixMeLater
  orgId: number
  token: string
  sendRef: any
  disabled: boolean
  setScrolledHistory: FixMeLater
}

const SendMessageCont = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  gridArea: 'sendMessage',
  width: '100%',
  bottom: 0,
  borderTop: `1px solid ${theme.palette.border.main}`,
}))

const SendMessageInnerCont = styled('div')({
  padding: '0px 24px 5px',
  display: 'flex',
  alignItems: 'flex-start',
  paddingRight: '92px',

  '& .send-message-info': {
    marginBottom: 0,
  },
})

const SendMessageToolbar = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  borderBottom: `1px solid ${theme.palette.border.main}`,
  padding: '5px 24px',
  backgroundColor: theme.palette.highlight.light,
  height: '59px',
}))

const SendMessageToolbarSelectWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  paddingRight: '10px',
  alignItems: 'center',
})

const SendCont = styled('div')({
  textAlign: 'center',
  display: 'flex',
})

const SendMessageTextBox = styled('div')({
  width: '100%',
  padding: '10px 10px 0 0',
})

export default function Send({
  personalizationList,
  smsSenderIds,
  whatsappSenderIds,
  sendWhatsappMessage,
  saveFile,
  getFile,
  onMicAccessError,
  sendRef,
  disabled,
  setScrolledHistory,
  contactId,
  sendMessage,
  token,
  orgId,
}: Props) {
  const [loading, setLoading] = React.useState(false)
  const [messageType, setMessageType] = React.useState('sms')
  const [selectedSenderId, setSelectedSenderId] = React.useState('-none-selected-')
  const [message, setMessage] = React.useState('')
  const senderIds = messageType === 'sms' ? smsSenderIds : whatsappSenderIds

  const sendSMSHandler = () => {
    const contactIds = [contactId]

    setLoading(true)

    sendMessage({
      senderId: selectedSenderId === DEFAULT_SENDER_ID_VALUE ? '' : selectedSenderId,
      isAllContacts: false,
      contactIds,
      excludeIds: [],
      message,
      includedGroupIds: [],
      search: '',
      token,
      orgId,
    })
      .then(() => {
        setLoading(false)
        setMessage('')
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const [image, setImage] = React.useState('')
  const [audio, setAudio] = React.useState('')
  const [video, setVideo] = React.useState('')
  const [document, setDocument] = React.useState('')
  const [playId, setPlaying] = React.useState('')
  const [files, setFiles] = React.useState({})

  React.useEffect(() => {
    setSelectedSenderId('-none-selected-')
    setMessage('')
    setImage('')
    setAudio('')
    setVideo('')
    setDocument('')
    setFiles({})
  }, [messageType])

  const sendWhatsappHandler = () => {
    const contactIds = [contactId]
    setLoading(true)
    sendWhatsappMessage({
      message,
      image,
      audio,
      video,
      document,
      senderId: selectedSenderId === DEFAULT_SENDER_ID_VALUE ? '' : selectedSenderId,
      isAllContacts: false,
      contactIds,
      excludeIds: [],
      includedGroupIds: [],
      search: '',
      token,
      orgId,
    })
      .then(() => {
        setLoading(false)
        setMessage('')
        setImage('')
        setAudio('')
        setVideo('')
        setDocument('')
        setFiles({})
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const sendHandler = () => {
    setScrolledHistory(false)
    if (messageType === 'sms') {
      sendSMSHandler()
    } else {
      sendWhatsappHandler()
    }
  }

  const saveFileHandler = (file: FixMeLater) =>
    saveFile(file).then((res: FixMeLater) => {
      if (!(res instanceof Error)) {
        if (file.type === 'image') {
          setImage(res.filename)
        } else if (file.type === 'video') {
          setVideo(res.filename)
        } else if (file.type === 'document') {
          setDocument(res.filename)
        }
      }
    })

  const saveAudioHandler = (file: { file: FixMeLater; filename: string; filter: 'string' }) =>
    saveFile(file).then((res: any) => {
      if (!(res instanceof Error)) {
        setAudio(res.filename)
      }
      return res
    })

  const removeMedia = () => {
    setImage('')
    setAudio('')
    setVideo('')
    setDocument('')
  }

  const handleGetFile = React.useCallback(
    (item: string) => getFile(item).then((file: FileType) => setFiles({ [item]: file })),
    [getFile],
  )
  const handleWhatsAppChange = React.useCallback(
    ({ text: updatedText }: ContentWhatsApp) => setMessage(updatedText || ''),
    [],
  )

  return (
    <SendMessageCont ref={sendRef}>
      <SendMessageToolbar>
        {!disabled && (
          <>
            <SendMessageToolbarSelectWrapper>
              <ToggleButtonGroup
                value={messageType}
                exclusive
                onChange={(e: React.BaseSyntheticEvent, value: string) => {
                  if (value) {
                    setSelectedSenderId('-none-selected-')
                    setMessageType(value)
                  }
                }}
                aria-label="text alignment"
              >
                <ToggleButton value="sms" aria-label="sms">
                  <Icon>sms</Icon>
                </ToggleButton>
                <ToggleButton value="whatsapp" aria-label="whatsapp">
                  <WhatsappIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </SendMessageToolbarSelectWrapper>
            <SendCont>
              <SendMessageToolbarSelectWrapper>
                <Select
                  color="primary"
                  variant="outlined"
                  value={selectedSenderId}
                  onChange={(e) => {
                    setSelectedSenderId(e.target.value)
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        marginTop: '-30px',
                      },
                    },
                  }}
                >
                  <MenuItem value="-none-selected-" disabled>
                    Select a Sender
                  </MenuItem>
                  {senderIds.map((senderId: SenderIdType) => (
                    <MenuItem key={senderId.value} value={senderId.value}>
                      {senderId.label}
                    </MenuItem>
                  ))}
                </Select>
              </SendMessageToolbarSelectWrapper>
              <Button
                size="large"
                color="primary"
                variant="contained"
                disabled={
                  loading ||
                  selectedSenderId === '-none-selected-' ||
                  !(message || audio || image || video || document) ||
                  disabled
                }
                onClick={() => sendHandler()}
              >
                {loading ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : <Icon>send</Icon>}
              </Button>
            </SendCont>
          </>
        )}
      </SendMessageToolbar>
      <SendMessageInnerCont>
        {!disabled && (
          <SendMessageTextBox>
            {messageType === 'whatsapp' ? (
              <WhatsApp
                audio={audio}
                canRegisterTemplate={false}
                defaultLanguage=""
                document={document}
                editable={!disabled}
                enableMediaCaption
                files={files}
                image={image}
                info=""
                item={{
                  message: {
                    text: message,
                    image,
                    audio,
                    video,
                    document,
                  },
                }}
                label="message"
                loading={loading}
                personalizationList={personalizationList}
                playId={playId}
                video={video}
                changeHandler={handleWhatsAppChange}
                getFile={handleGetFile}
                onMicAccessError={onMicAccessError}
                saveFileHandler={saveAudioHandler}
                saveHandler={saveFileHandler}
                setPlaying={setPlaying}
                removeMedia={removeMedia}
              />
            ) : (
              <Message
                message={message}
                error={''}
                personalizationList={personalizationList}
                onChange={({ message: updatedMessage }: any) => {
                  setMessage(updatedMessage)
                }}
              />
            )}
          </SendMessageTextBox>
        )}
      </SendMessageInnerCont>
    </SendMessageCont>
  )
}
