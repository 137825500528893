import { intersection } from 'ramda'
import React from 'react'

import { SUPPORTED_LIBRARY_MESSAGE_TYPES } from '@/app/module/campaigns/component/item/steps/content/types/multimessage/message-snippet'
import { getMessageContentType } from '@/app/module/campaigns/helpers'
import { MessageSnippetType, SnippetType } from '@/app/module/campaigns/types'
import { SelectOption } from '@/app/types'

type UseSnippetsArgs = {
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  supportedMessageTypes: SelectOption<string>[]
}

export function useMessageSnippets({ snippets, supportedMessageTypes }: UseSnippetsArgs) {
  const supportedTypes = React.useMemo(() => supportedMessageTypes.map((o) => o.value), [supportedMessageTypes])

  const snippetsData = React.useMemo(() => snippets?.data || [], [snippets?.data])
  const messageSnippets = React.useMemo(() => {
    return (
      snippetsData.filter((item): item is MessageSnippetType => {
        if (item.category === 'message') {
          const type = getMessageContentType(item.snippet)
          return supportedTypes.includes(type) && SUPPORTED_LIBRARY_MESSAGE_TYPES.includes(type)
        }
        return false
      }) || []
    )
  }, [snippetsData, supportedTypes])

  const isSupported = React.useMemo(() => {
    return intersection(snippetsData, messageSnippets).length > 0
  }, [snippetsData, messageSnippets])

  return {
    messageSnippets,
    isSupported,
  }
}
