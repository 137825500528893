import React from 'react'

import DeleteDialog from '@/app/component/atom/delete-dialog'
import { useDeleteHook } from '@/app/module/phonenumbers/hooks/use-delete-hook'
import { ChannelType } from '@/app/module/phonenumbers/types'

type Props = {
  deleteId: number
  didId: number
  type: ChannelType.Sms | ChannelType.Voice | ChannelType.WhatsApp

  onClose: () => void
}

const DeleteHookDialog: React.FC<Props> = ({ deleteId, didId, type, onClose }) => {
  const [deleteHook, { isLoading }] = useDeleteHook(type)

  const handleDelete = React.useCallback(async () => {
    if (!deleteId || isLoading) {
      return
    }
    const success = await deleteHook(didId, deleteId)
    if (success) {
      onClose()
    }
  }, [deleteId, didId, isLoading, deleteHook, onClose])

  return (
    <DeleteDialog
      disabled={isLoading}
      isOpen
      text="Are you sure you want to delete the selected rule?"
      onClose={onClose}
      onConfirm={handleDelete}
    />
  )
}

export default DeleteHookDialog
