import { v4 as uuid } from 'uuid'

import { getMessageContentType } from '@/app/module/campaigns/helpers'
import {
  APIMessage,
  CampaignTemplateType,
  CATIMessage,
  CATIQuestion,
  PartFamily,
  ReminderMessage,
  ReminderSMSMessage,
  ReminderSMSQuestion,
  SMSMessage,
  SMSQuestion,
  Message as TMessage,
} from '@/app/module/campaigns/types'

import { getEmptyDelta } from './empty-delta'
import { filterApiActions, filterCATIQuestionActions, filterSMSQuestionActions } from './library-actions'
import { isValidPart } from './valid-parts'

export function getLibrarySMSMessage(
  campaignType: CampaignTemplateType,
  data: SMSMessage | ReminderSMSMessage,
  nextIndex: number,
  timezone: string,
): SMSMessage | ReminderSMSMessage | CATIMessage {
  const { message, label, when, offset } = data

  const baseMessage: SMSMessage = {
    id: uuid(),
    changed: false,
    type: PartFamily.SMS,
    hasQuestion: false,
    when,
    label,
    message,
  }

  switch (campaignType) {
    case CampaignTemplateType.Drip: {
      const delta = 'delta' in data ? data.delta : getEmptyDelta(timezone, nextIndex)
      // NOTE: add the delta only for drip campaigns
      return {
        ...baseMessage,
        delta,
      }
    }
    case CampaignTemplateType.Reminder: {
      // NOTE: add the offset only for reminder campaigns
      return {
        ...baseMessage,
        offset: offset || {
          unit: 'minute',
          value: 0,
        },
      }
    }
    case CampaignTemplateType.SMSBlast:
    case CampaignTemplateType.SMSSurvey:
    case CampaignTemplateType.VoiceBlast:
    case CampaignTemplateType.VoiceSurvey:
    case CampaignTemplateType.WhatsAppSurvey: {
      return baseMessage
    }
    case CampaignTemplateType.CATI: {
      return {
        ...baseMessage,
        type: PartFamily.CATI,
      }
    }
    default: {
      throw new Error('Invalid campaign type.')
    }
  }
}

export function getLibrarySMSQuestion(
  campaignType: CampaignTemplateType,
  data: SMSQuestion | ReminderSMSQuestion,
  messageIds: string[],
  nextIndex: number,
  timezone: string,
  onNotify: OnNotify,
): SMSQuestion | ReminderSMSQuestion {
  const {
    when,
    offset,
    label,
    useAi,
    onAnswer,
    retries,
    onTimeout,
    onInvalidReply,
    onRetriesExhausted,
    openEnded,
    message,
  } = data

  const baseQuestion: SMSQuestion = filterSMSQuestionActions(
    {
      id: uuid(),
      changed: false,
      type: PartFamily.SMS,
      hasQuestion: true,
      useAi,
      retries,
      openEnded,
      message,
      label,
      when,
      onAnswer,
      onTimeout,
      onInvalidReply,
      onRetriesExhausted,
    },
    campaignType,
    nextIndex,
    messageIds,
    onNotify,
  )

  switch (campaignType) {
    case CampaignTemplateType.Drip: {
      const delta = 'delta' in data ? data.delta : getEmptyDelta(timezone, nextIndex)
      // NOTE: add the delta only for drip campaigns
      return {
        ...baseQuestion,
        delta,
      }
    }
    case CampaignTemplateType.Reminder: {
      // NOTE: add the offset only for reminder campaigns
      return {
        ...baseQuestion,
        offset: offset || {
          unit: 'minute',
          value: 0,
        },
      }
    }
    case CampaignTemplateType.SMSBlast:
    case CampaignTemplateType.SMSSurvey:
    case CampaignTemplateType.VoiceBlast:
    case CampaignTemplateType.VoiceSurvey:
    case CampaignTemplateType.WhatsAppSurvey: {
      return baseQuestion
    }
    default: {
      throw new Error('Invalid campaign type.')
    }
  }
}

function getLibraryAPIMessage(
  campaignType: CampaignTemplateType,
  data: APIMessage,
  messageIds: string[],
  nextIndex: number,
  timezone: string,
  onNotify: (errors: string[]) => void,
) {
  const { apiCall, label, when } = data

  const baseMessage: APIMessage = filterApiActions(
    {
      id: uuid(),
      label,
      changed: false,
      type: PartFamily.API,
      hasQuestion: true,
      when,
      apiCall,
    },
    campaignType,
    nextIndex,
    messageIds,
    onNotify,
  )

  switch (campaignType) {
    case CampaignTemplateType.Drip: {
      const delta = 'delta' in data ? data.delta : getEmptyDelta(timezone, nextIndex)
      // NOTE: add the delta only for drip campaigns
      return {
        ...baseMessage,
        delta,
      }
    }
    case CampaignTemplateType.SMSSurvey:
    case CampaignTemplateType.WhatsAppSurvey: {
      return baseMessage
    }
    default: {
      throw new Error('Invalid campaign type.')
    }
  }
}

export function getLibraryCATIMessage(data: CATIMessage): CATIMessage {
  const { message, label, when } = data

  return {
    id: uuid(),
    changed: false,
    type: PartFamily.CATI,
    hasQuestion: false,
    when,
    label,
    message,
  }
}

export function getLibraryCATIQuestion(
  data: CATIQuestion,
  messageIds: string[],
  nextIndex: number,
  onNotify: OnNotify,
): CATIQuestion {
  const baseMessage = filterCATIQuestionActions(data, CampaignTemplateType.CATI, nextIndex, messageIds, onNotify)

  return {
    ...baseMessage,
    id: uuid(),
    changed: false,
    type: PartFamily.CATI,
    hasQuestion: true,
  }
}

type OnNotify = (errors: string[]) => void

type GetLibraryMessageProps = {
  campaignType: CampaignTemplateType
  insertionIndex: number
  timezone: string
  data: TMessage | ReminderMessage
  messageIds: string[]
  onNotify: OnNotify
}

export function getLibraryMessage({
  campaignType,
  insertionIndex,
  timezone,
  data,
  messageIds,
  onNotify,
}: GetLibraryMessageProps): TMessage | ReminderMessage | null {
  const messageType = getMessageContentType(data)
  if (!isValidPart(campaignType, messageType)) {
    return null
  }
  switch (messageType) {
    case 'sms-m':
      return getLibrarySMSMessage(campaignType, data as SMSMessage, insertionIndex, timezone)
    case 'sms-q':
      return getLibrarySMSQuestion(campaignType, data as SMSQuestion, messageIds, insertionIndex, timezone, onNotify)
    case 'api-q':
      return getLibraryAPIMessage(campaignType, data as APIMessage, messageIds, insertionIndex, timezone, onNotify)
    case 'cati-m':
      return getLibraryCATIMessage(data as CATIMessage)
    case 'cati-q':
      return getLibraryCATIQuestion(data as CATIQuestion, messageIds, insertionIndex, onNotify)
    default: {
      return null
    }
  }
}
