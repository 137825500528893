import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'

import Conditions from '@/app/component/atom/form/conditions'
import { Groups, OperandsList } from '@/app/component/conditions/definitions'
import { PersonalizationType } from '@/app/module/campaigns/types'

type Props<T extends FieldValues> = {
  groups?: Groups
  label?: string
  name: Path<T>
  operands: OperandsList
  personalizationList: PersonalizationType
  segments?: Groups
}

export default function RHFConditions<T extends FieldValues>({
  groups,
  label,
  name,
  operands,
  personalizationList,
  segments,
}: Props<T>) {
  const { control } = useFormContext<T>()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Conditions
          error={!!fieldState.error}
          groups={groups}
          operands={operands}
          personalizationList={personalizationList}
          segments={segments}
          title={label}
          value={field.value}
          onBlur={() => field.onBlur()}
          onChange={(event) => field.onChange(event.value)}
        />
      )}
    />
  )
}
