import { is, path, pathOr } from 'ramda'

import {
  ACTION_CONTACTS_NAVIGATE,
  ACTION_CONTACTS_PAGE_SIZE,
  ACTION_DELETE_CONTACTS_FAILURE,
  ACTION_DELETE_CONTACTS_ITEM_FAILURE,
  ACTION_DELETE_CONTACTS_ITEM_REQUEST,
  ACTION_DELETE_CONTACTS_ITEM_SUCCESS,
  ACTION_DELETE_CONTACTS_REQUEST,
  ACTION_DELETE_CONTACTS_SUCCESS,
  ACTION_DOWNLOAD_CONTACTS_FAILURE,
  ACTION_DOWNLOAD_CONTACTS_REQUEST,
  ACTION_DOWNLOAD_CONTACTS_SUCCESS,
  ACTION_DOWNLOAD_INVALID_CONTACTS_FAILURE,
  ACTION_DOWNLOAD_INVALID_CONTACTS_REQUEST,
  ACTION_DOWNLOAD_INVALID_CONTACTS_SUCCESS,
  ACTION_GET_CONTACTS_FAILURE,
  ACTION_GET_CONTACTS_ITEM_FAILURE,
  ACTION_GET_CONTACTS_ITEM_REQUEST,
  ACTION_GET_CONTACTS_ITEM_SUCCESS,
  ACTION_GET_CONTACTS_REQUEST,
  ACTION_GET_CONTACTS_SUCCESS,
  ACTION_RESET_CONTACTS,
  ACTION_SAVE_CONTACTS_ITEM_FAILURE,
  ACTION_SAVE_CONTACTS_ITEM_REQUEST,
  ACTION_SAVE_CONTACTS_ITEM_SUCCESS,
  ACTION_SAVE_TABLE_HEADERS,
  ACTION_UPDATE_CONTACTS_ITEM,
  ACTION_UPLOAD_CONFIRM_CONTACTS_FAILURE,
  ACTION_UPLOAD_CONFIRM_CONTACTS_REQUEST,
  ACTION_UPLOAD_CONFIRM_CONTACTS_SUCCESS,
  ACTION_UPLOAD_CONTACTS_FAILURE,
  ACTION_UPLOAD_CONTACTS_REQUEST,
  ACTION_UPLOAD_CONTACTS_SUCCESS,
  ERROR_DELETE_CONTACTS_ITEM_SERVER,
  ERROR_DELETE_CONTACTS_SERVER,
  ERROR_DOWNLOAD_INVALID_SERVER,
  ERROR_DOWNLOAD_SERVER,
  ERROR_GET_CONTACTS_ITEM_SERVER,
  ERROR_GET_CONTACTS_SERVER,
  ERROR_SAVE_CONTACTS_ITEM_SERVER,
  ERROR_UPLOAD_CONTACTS_CONFIRM_SERVER,
  ERROR_UPLOAD_CONTACTS_EMPTY,
  ERROR_UPLOAD_CONTACTS_SERVER,
  ERROR_UPLOAD_CONTACTS_STATUS_SERVER,
} from '@/app/module/contacts/definitions'
import { createNotification } from '@/app/module/notifications/store/actions'
import request from '@/app/module/request'
import { getContactHeaders, getPageSize, setContactHeaders, setPageSize } from '@/app/service/storage'
import { createAsyncActions, preventParallel, toQuery } from '@/app/service/util'
import { processErrors } from '@/app/service/util/errorCodes'
import config from '@/config'
import http from '@/config/http'

import { capitalize } from './selectors'
import { processBulkUploadResult } from './utils'

const {
  request: getContactsRequest,
  success: getContactsSuccess,
  failure: getContactsFailure,
} = createAsyncActions({
  request: ACTION_GET_CONTACTS_REQUEST,
  success: ACTION_GET_CONTACTS_SUCCESS,
  failure: ACTION_GET_CONTACTS_FAILURE,
})

const {
  request: getContactsItemRequest,
  success: getContactsItemSuccess,
  failure: getContactsItemFailure,
} = createAsyncActions({
  request: ACTION_GET_CONTACTS_ITEM_REQUEST,
  success: ACTION_GET_CONTACTS_ITEM_SUCCESS,
  failure: ACTION_GET_CONTACTS_ITEM_FAILURE,
})

const {
  request: saveContactsItemRequest,
  success: saveContactsItemSuccess,
  failure: saveContactsItemFailure,
} = createAsyncActions({
  request: ACTION_SAVE_CONTACTS_ITEM_REQUEST,
  success: ACTION_SAVE_CONTACTS_ITEM_SUCCESS,
  failure: ACTION_SAVE_CONTACTS_ITEM_FAILURE,
})

const {
  request: deleteContactsItemRequest,
  success: deleteContactsItemSuccess,
  failure: deleteContactsItemFailure,
} = createAsyncActions({
  request: ACTION_DELETE_CONTACTS_ITEM_REQUEST,
  success: ACTION_DELETE_CONTACTS_ITEM_SUCCESS,
  failure: ACTION_DELETE_CONTACTS_ITEM_FAILURE,
})

const {
  request: deleteContactsRequest,
  success: deleteContactsSuccess,
  failure: deleteContactsFailure,
} = createAsyncActions({
  request: ACTION_DELETE_CONTACTS_REQUEST,
  success: ACTION_DELETE_CONTACTS_SUCCESS,
  failure: ACTION_DELETE_CONTACTS_FAILURE,
})

const {
  request: uploadContactsRequest,
  success: uploadContactsSuccess,
  failure: uploadContactsFailure,
} = createAsyncActions({
  request: ACTION_UPLOAD_CONTACTS_REQUEST,
  success: ACTION_UPLOAD_CONTACTS_SUCCESS,
  failure: ACTION_UPLOAD_CONTACTS_FAILURE,
})

const {
  request: uploadConfirmContactsRequest,
  success: uploadConfirmContactsSuccess,
  failure: uploadConfirmContactsFailure,
} = createAsyncActions({
  request: ACTION_UPLOAD_CONFIRM_CONTACTS_REQUEST,
  success: ACTION_UPLOAD_CONFIRM_CONTACTS_SUCCESS,
  failure: ACTION_UPLOAD_CONFIRM_CONTACTS_FAILURE,
})

const {
  request: downloadContactsRequest,
  success: downloadContactsSuccess,
  failure: downloadContactsFailure,
} = createAsyncActions({
  request: ACTION_DOWNLOAD_CONTACTS_REQUEST,
  success: ACTION_DOWNLOAD_CONTACTS_SUCCESS,
  failure: ACTION_DOWNLOAD_CONTACTS_FAILURE,
})

const {
  request: downloadInvalidFileRequest,
  success: downloadInvalidFileSuccess,
  failure: downloadInvalidFileFailure,
} = createAsyncActions({
  request: ACTION_DOWNLOAD_INVALID_CONTACTS_REQUEST,
  success: ACTION_DOWNLOAD_INVALID_CONTACTS_SUCCESS,
  failure: ACTION_DOWNLOAD_INVALID_CONTACTS_FAILURE,
})

export {
  deleteContactsFailure,
  deleteContactsItemFailure,
  deleteContactsItemRequest,
  deleteContactsItemSuccess,
  deleteContactsRequest,
  deleteContactsSuccess,
  getContactsFailure,
  getContactsItemFailure,
  getContactsItemRequest,
  getContactsItemSuccess,
  getContactsRequest,
  getContactsSuccess,
  saveContactsItemFailure,
  saveContactsItemRequest,
  saveContactsItemSuccess,
  uploadConfirmContactsFailure,
  uploadConfirmContactsRequest,
  uploadConfirmContactsSuccess,
  uploadContactsFailure,
  uploadContactsRequest,
  uploadContactsSuccess,
}

export const defaultPageSize = path(['modules', 'contacts', 'pageSize', 'default'], config)

export const getContacts = preventParallel(
  ({ orgId, query }) => `${orgId}-${toQuery(query)}`,
  ({ token, orgId, query = {} }) =>
    (dispatch) => {
      dispatch(getContactsRequest(query))
      const { page = 1, size, searchTerm = '' } = query
      return request.contacts
        .get({ token, orgId, query })
        .then(({ contacts = [], total = 0, orgTotal = 0 } = {}) => {
          dispatch(
            getContactsSuccess({
              values: searchTerm ? contacts.slice(0, size || defaultPageSize) : contacts,
              page: parseInt(page, 10),
              size: size || defaultPageSize,
              total,
              orgTotal,
              searchTerm,
              filter: false,
            }),
          )
          return contacts
        })
        .catch((err) => {
          dispatch(
            getContactsFailure({
              status: err.status,
              message: ERROR_GET_CONTACTS_SERVER,
            }),
          )
          return err
        })
    },
)

export const getContactsItem =
  ({ token, orgId, itemId, campaignId = 0 }) =>
  (dispatch) => {
    const query = {
      subscriptionStatus: campaignId || undefined,
    }

    dispatch(getContactsItemRequest({ id: itemId, query }))

    return request.contacts
      .getItem({ token, orgId, itemId, query })
      .then((data = {}) => {
        dispatch(getContactsItemSuccess(data))
        return data
      })
      .catch((err) => {
        dispatch(
          getContactsItemFailure({
            id: itemId,
            status: err.status,
            message: ERROR_GET_CONTACTS_ITEM_SERVER,
          }),
        )
        return err
      })
  }

export const saveContactsItem =
  ({ token, orgId, item }) =>
  (dispatch) => {
    dispatch(saveContactsItemRequest({ item }))
    const { createdAt, id = '' } = item
    const actionType = id ? 'saveItem' : 'addItem'
    return request.contacts[actionType]({ token, orgId, itemId: id, item })
      .then((data = {}) => {
        const savedItem = {
          ...data,
          createdAt,
        }
        dispatch(saveContactsItemSuccess(savedItem))
        dispatch(
          createNotification({
            'save-contact': {
              type: 'success',
              message: 'Contact saved successfully!',
            },
          }),
        )
        return savedItem
      })
      .catch((err) => {
        if (err.response && err.response instanceof Object) {
          dispatch(
            saveContactsItemFailure({
              id,
              fields: Object.keys(err.response).reduce(
                (acc, key) => ({
                  ...acc,
                  [key]: is(Array, err.response[key]) ? err.response[key].join('. ') : err.response[key],
                }),
                {},
              ),
            }),
          )
        }
        dispatch(
          createNotification({
            [`update-contact-${item.id}`]: {
              type: 'error',
              message: `${ERROR_SAVE_CONTACTS_ITEM_SERVER} ${capitalize(
                pathOr(ERROR_SAVE_CONTACTS_ITEM_SERVER, ['error'], err.response),
              )}`,
            },
          }),
        )
        return err
      })
  }

export const deleteContactsItem =
  ({ token, orgId, itemId }) =>
  (dispatch) => {
    dispatch(deleteContactsItemRequest({ id: itemId }))

    return request.contacts
      .deleteItem({ token, orgId, itemId })
      .then(() => {
        dispatch(deleteContactsItemSuccess({ id: itemId }))
        return itemId
      })
      .catch((err) => {
        dispatch(
          createNotification({
            [`delete-contact-${itemId}`]: {
              type: 'error',
              message: ERROR_DELETE_CONTACTS_ITEM_SERVER,
            },
          }),
        )
        return err
      })
  }

export const deleteContacts =
  ({ token, orgId, itemIds }) =>
  (dispatch) => {
    dispatch(deleteContactsRequest({ ids: itemIds }))

    return request.contacts
      .delete({
        token,
        orgId,
        item: {
          contacts: {
            ...itemIds,
          },
        },
      })
      .then(() => {
        dispatch(deleteContactsSuccess({ ids: itemIds }))
        return itemIds
      })
      .catch((err) => {
        dispatch(deleteContactsFailure({ ids: itemIds }))
        dispatch(
          createNotification({
            'delete-contacts': {
              type: 'error',
              message: ERROR_DELETE_CONTACTS_SERVER,
            },
          }),
        )
        return err
      })
  }

export const uploadContacts =
  ({ token, orgId, file }) =>
  (dispatch) => {
    dispatch(uploadContactsRequest({ file }))

    return request.contacts
      .upload({ token, orgId, file })
      .then((data = {}) => {
        if (!data.titlesFromFile || !data.titlesFromFile.length || !data.sampleData || !data.sampleData.length) {
          dispatch(
            createNotification({
              'empty-contacts': {
                type: 'error',
                message: ERROR_UPLOAD_CONTACTS_EMPTY,
              },
            }),
          )

          throw new Error(ERROR_UPLOAD_CONTACTS_EMPTY)
        } else {
          dispatch(uploadContactsSuccess(data))
        }

        return data
      })
      .catch((err) => {
        dispatch(
          uploadContactsFailure({
            status: err.status,
            message: processErrors(err, ERROR_UPLOAD_CONTACTS_SERVER),
          }),
        )
        return err
      })
  }

export const confirmUpload =
  ({ token, orgId, item }) =>
  (dispatch) => {
    dispatch(uploadConfirmContactsRequest(item))

    return request.contacts
      .uploadConfirm({ token, orgId, item })
      .then(() => {
        dispatch(uploadConfirmContactsSuccess(item))
        return item
      })
      .catch((err) => {
        dispatch(
          uploadConfirmContactsFailure({
            status: err.status,
            message: ERROR_UPLOAD_CONTACTS_CONFIRM_SERVER,
          }),
        )
        return err
      })
  }

export const getUploadsInvalid =
  ({ token, orgId, uploadKey }) =>
  (dispatch) => {
    dispatch(downloadInvalidFileRequest({ uploadKey }))

    const dlPath = http.contacts.getInvalidFile.getSignablePath({ orgId })
    const data = {
      paths: [dlPath],
    }

    return request.auth
      .sign({ token, item: data })
      .then((res) => {
        dispatch(downloadInvalidFileSuccess({ uploadKey, urlToken: res[dlPath] }))
        const url = http.contacts.getInvalidFile.getPath({
          uploadKey,
          signedPath: dlPath,
          urlToken: res[dlPath],
        })
        window.location.href = url
      })
      .catch((err) => {
        dispatch(
          downloadInvalidFileFailure({
            status: err.status,
            message: err.message,
          }),
        )
        dispatch(
          createNotification({
            'download-invalid-contacts': {
              type: 'error',
              message: ERROR_DOWNLOAD_INVALID_SERVER,
            },
          }),
        )
        return err
      })
  }

export const downloadContacts =
  ({ token, orgId, ext, query }) =>
  (dispatch) => {
    dispatch(downloadContactsRequest({ ext, query }))

    const dlPath = http.contacts.download.getSignablePath({ orgId, ext })
    const data = {
      paths: [dlPath],
    }

    return request.auth
      .sign({ token, item: data })
      .then((res) => {
        dispatch(downloadContactsSuccess({ ext, query, urlToken: res[dlPath] }))
        const url = http.contacts.download.getPath({
          query,
          signedPath: dlPath,
          urlToken: res[dlPath],
        })
        window.location.href = url
      })
      .catch((err) => {
        dispatch(
          downloadContactsFailure({
            status: err.status,
            message: err.message,
          }),
        )
        dispatch(
          createNotification({
            'download-invalid-contacts': {
              type: 'error',
              message: ERROR_DOWNLOAD_SERVER,
            },
          }),
        )
        return err
      })
  }

export const getUploadStatus =
  ({ token, orgId, uploadKey, query = {} }) =>
  (dispatch) => {
    const poll = () =>
      request.contacts
        .getUploadStatus({ token, orgId, uploadKey })
        .then((res) => {
          processBulkUploadResult({ token, orgId, uploadKey, query }, res, dispatch)
        })
        .catch((err) => {
          if (err.status === 404) {
            setTimeout(poll, 5000)
            return undefined
          }
          dispatch(
            createNotification({
              [`upload-${uploadKey}`]: {
                type: 'error',
                message: ERROR_UPLOAD_CONTACTS_STATUS_SERVER,
              },
            }),
          )
          return err
        })

    return poll()
  }

export const updateItem = (newItem) => ({
  type: ACTION_UPDATE_CONTACTS_ITEM,
  value: newItem,
})

export const navigateContacts = ({
  history,
  page = 1,
  filter = false,
  searchTerm = false,
  contactId = '',
  add = false,
  subscriptionFilter,
  subscriptionStatus,
} = {}) => {
  if (history) {
    let url = '/contacts/'
    if (contactId) {
      url += `edit/${contactId}`
    } else if (add) {
      url += 'add'
    }
    if (filter) {
      url += `?filterType=${filter.name}&filterId=${filter.value}&page=${page}`
      if (searchTerm) {
        const encodedSearchTerm = encodeURIComponent(searchTerm)
        url += `&search=${encodedSearchTerm}`
      }
    } else if (searchTerm) {
      const encodedSearchTerm = encodeURIComponent(searchTerm)
      url += `?search=${encodedSearchTerm}&page=${page}`
    } else {
      url += `?page=${page}`
    }
    history.push(url)
  }

  return {
    type: ACTION_CONTACTS_NAVIGATE,
    value: {
      page,
      searchTerm,
      filter,
      subscriptionFilter,
      subscriptionStatus,
    },
  }
}

export const getContactsPageSize = () => (dispatch) => {
  const size = getPageSize({ defaultValue: defaultPageSize, namespace: 'contacts' })

  return dispatch({
    type: ACTION_CONTACTS_PAGE_SIZE,
    value: size,
  })
}

export const setContactsPageSize =
  (size = defaultPageSize) =>
  (dispatch) => {
    setPageSize({ namespace: 'contacts', value: size })

    return dispatch({
      type: ACTION_CONTACTS_PAGE_SIZE,
      value: size,
    })
  }

export const resetContatcs = () => ({
  type: ACTION_RESET_CONTACTS,
})

export const saveContactsTableHeaders = (orgId, headers) => (dispatch) => {
  setContactHeaders({ orgId, headers })

  return dispatch({
    type: ACTION_SAVE_TABLE_HEADERS,
    value: headers,
  })
}

export const getContactsTableHeaders = (orgId) => (dispatch) => {
  const headers = getContactHeaders({ orgId })

  return dispatch({
    type: ACTION_SAVE_TABLE_HEADERS,
    value: headers,
  })
}
