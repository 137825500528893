import { find, keys, pathOr, propEq, reduce } from 'ramda'

import { CustomFieldType } from './types'

type ContactCustomFields = Record<string, string>
type CustomFields = Omit<CustomFieldType, 'value'>[]

export type AggCFPayload =
  | {
      contact?: {
        customFields?: ContactCustomFields
      } | null
      customFields?: CustomFields | null
    }
  | undefined
  | null

export const aggregateCustomFields = (payload: AggCFPayload) => {
  const contactCustomFields = pathOr<ContactCustomFields>({}, ['contact', 'customFields'], payload || {})
  const customFields = pathOr<CustomFields>([], ['customFields'], payload || {})

  return reduce(
    (accum: any, fieldKey: string) => {
      const found = find(propEq('id', parseInt(fieldKey, 10)), customFields)
      if (found) {
        accum.push({
          ...found,
          value: contactCustomFields[fieldKey],
        })
      }
      return accum
    },
    [],
    keys(contactCustomFields) as string[],
  )
}
