import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { selectToken } from '@/app/module/auth/store/selectors'
import { selectContactsItemConfig } from '@/app/module/contacts/store/selectors'
import { selectOrgId, selectUserId } from '@/app/module/user/store/selectors'

import Demos from './component'
import { getDemos, selectDemo } from './store/actions'

const selector = ({ auth, user, demos, utils }) => ({
  error: pathOr([], ['list', 'error'], demos),
  isLoading: pathOr([], ['list', 'loading'], demos),
  demos: pathOr([], ['list', 'data', 'demos'], demos),
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  userId: selectUserId({ user }),
  profile: pathOr({}, ['details', 'data'], user),
  specs: selectContactsItemConfig({ contacts: [], user, customFields: [], utils }),
  hydrateProps: ['token', 'orgId'],
})

const dispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId, query }) =>
        () =>
          Promise.all([dispatch(getDemos({ token, orgId, query }))]),
      selectDemo,
      getDemos,
    },
    dispatch,
  )

export default connect(selector, dispatcher)(withRouter(Demos))
