import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import React from 'react'

import { WhatsAppTab } from '@/app/component/atom/whatsapp/helpers'
import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import { MESSAGE_PERSONALIZATION } from '@/app/module/campaigns/definitions'
import { CampaignTemplateType, TWhatsAppButtons, TWhatsAppList, WhatsAppQuestion } from '@/app/module/campaigns/types'

import { AnswerType, QuestionTextProps } from './question-text-props'
import ResponseTextOpenEnded from './response-text-open-ended'
import ResponseSpecific from './response-text-specific'
import ResponseWhatsappButtons from './response-whatsapp-buttons'
import ResponseWhatsappConfigs from './response-whatsapp-configs'
import { WhatsappConfigs } from './response-whatsapp-configs-utils'
import ResponseWhatsappList from './response-whatsapp-list'
import ResponseWhatsappTabs from './response-whatsapp-tabs'
import {
  backToProps,
  checkTabSwitchConfirmation,
  fromProps,
  generateContent,
  State,
  TabSwitchConfirmation,
} from './response-whatsapp-utils'

type Props = QuestionTextProps & {
  item: WhatsAppQuestion
  type: CampaignTemplateType // campaign type
  /**
   * The whatsapp content tab
   */
  tab: WhatsAppTab

  changeHandler: (item: WhatsAppQuestion) => void
}

export default function ResponseWhatsapp(props: Props) {
  const {
    item,
    id,
    personalizationList,
    senderIds,
    whatsappSenderIds,
    getCampaigns,
    orgId,
    token,
    campaignIds,
    getImageProps,
    getVoiceProps,
    files = {},
    timezone,
    timezones,
    countryCode,
    countriesTimezones,
    getSnippets,
    saveSnippet,
    snippets,
    languages,
    defaultLanguage,
    tab,
    transcribe,
    useAi,
    changeHandler,
  } = props

  const [state, setState] = React.useState(fromProps(item))
  const [nextTabConfirmation, setNextTabConfirmation] = React.useState<TabSwitchConfirmation | undefined>(undefined)

  React.useEffect(() => {
    setState(fromProps(item))
  }, [item])

  const textPersonalization = React.useMemo(
    () => ({
      ...MESSAGE_PERSONALIZATION,
      ...personalizationList,
    }),
    [personalizationList],
  )

  const handleChange = (newItem: Partial<State>) => {
    const newState = {
      ...state,
      ...newItem,
    }

    const returnToProps = backToProps(newState)

    changeHandler({
      ...item,
      ...returnToProps,
    })

    setState(newState)
  }

  const actionProps = React.useMemo(
    () => ({
      campaignIds,
      countriesTimezones,
      countryCode,
      defaultLanguage,
      files,
      index: props.index,
      languages,
      personalizationList: textPersonalization,
      senderIds,
      timezone,
      timezones,
      transcribeEnabled: item.transcribe,
      whatsappSenderIds,
      getCampaigns,
      getImageProps,
      getVoiceProps,
    }),
    [
      campaignIds,
      countriesTimezones,
      countryCode,
      defaultLanguage,
      files,
      item.transcribe,
      languages,
      props.index,
      senderIds,
      textPersonalization,
      timezone,
      timezones,
      whatsappSenderIds,
      getCampaigns,
      getImageProps,
      getVoiceProps,
    ],
  )
  const apiProps = React.useMemo(() => ({ orgId, token }), [orgId, token])
  const snippetProps = React.useMemo(
    () => ({ snippets, getSnippets, saveSnippet }),
    [snippets, getSnippets, saveSnippet],
  )

  const handleWAListChange = React.useCallback(
    (value: TWhatsAppList) =>
      changeHandler({
        ...item,
        onList: value,
      }),
    [item, changeHandler],
  )
  const handleWAButtonsChange = React.useCallback(
    (value: TWhatsAppButtons) => changeHandler({ ...item, onButtons: value }),
    [item, changeHandler],
  )
  const handleConfigsChange = React.useCallback(
    (value: WhatsappConfigs) => changeHandler({ ...item, ...value }),
    [item, changeHandler],
  )

  const configs = React.useMemo(() => {
    if (state.answerType === AnswerType.OpenEnded) {
      return {
        onRetriesExhausted: item.onRetriesExhausted,
        onTimeout: item.onTimeout,
        retries: item.retries,
      }
    }

    return {
      onInvalidReply: item.onInvalidReply,
      onRetriesExhausted: item.onRetriesExhausted,
      onTimeout: item.onTimeout,
      retries: item.retries,
    }
  }, [item.onInvalidReply, item.onRetriesExhausted, item.onTimeout, item.retries, state.answerType])

  return (
    <div
      className="text-question-block"
      style={{
        marginTop: '2rem',
      }}
    >
      {nextTabConfirmation && (
        <ConfirmationDialog
          deleteButtonText="Confirm"
          icon="announcement"
          isOpen
          text={nextTabConfirmation.text}
          title={nextTabConfirmation.title}
          onClose={() => setNextTabConfirmation(undefined)}
          onConfirm={() => {
            if (!nextTabConfirmation) {
              return
            }
            const nextTab = nextTabConfirmation.tab
            handleChange({
              ...generateContent(nextTab),
              answerType: nextTab,
            })
            setNextTabConfirmation(undefined)
          }}
        />
      )}
      <Divider
        style={{
          margin: '1rem -24px',
        }}
      />
      <ResponseWhatsappTabs
        answerType={state.answerType}
        tab={tab}
        onChange={(val) => {
          const confirmation = checkTabSwitchConfirmation(state, val)
          if (confirmation) {
            setNextTabConfirmation(confirmation)
          } else {
            handleChange({
              ...generateContent(val),
              answerType: val,
            })
          }
        }}
      />
      <Divider
        style={{
          margin: '-1px -24px 1rem',
        }}
      />
      {state.answerType === AnswerType.OpenEnded && state.openEnded && (
        <ResponseTextOpenEnded
          actionProps={actionProps}
          apiProps={apiProps}
          campaignType={props.type}
          id={id}
          openEnded={state.openEnded}
          partType={item.type}
          snippetProps={snippetProps}
          transcribe={transcribe || false}
          onChange={handleChange}
        />
      )}
      {state.answerType === AnswerType.Text && (
        <ResponseSpecific
          actionProps={actionProps}
          apiProps={apiProps}
          campaignType={props.type}
          id={id}
          onAnswer={state.onAnswer}
          partType={item.type}
          snippetProps={snippetProps}
          transcribe={transcribe || false}
          useAi={useAi}
          onChange={handleChange}
        />
      )}
      {state.answerType === AnswerType.WhatsAppList && 'onList' in item && item.onList && (
        <ResponseWhatsappList
          actionProps={actionProps}
          apiProps={apiProps}
          campaignType={props.type}
          onList={item.onList}
          snippetProps={snippetProps}
          onChange={handleWAListChange}
        />
      )}
      {state.answerType === AnswerType.WhatsAppButtons && 'onButtons' in item && item.onButtons && (
        <ResponseWhatsappButtons
          actionProps={actionProps}
          apiProps={apiProps}
          campaignType={props.type}
          onButtons={item.onButtons}
          snippetProps={snippetProps}
          onChange={handleWAButtonsChange}
        />
      )}
      <Divider
        style={{
          margin: '1rem -24px',
        }}
      />
      <div style={{ display: 'flex' }}>
        <Typography color="secondary" component="div" variant="subtitle1" gutterBottom={true}>
          <b>Important:</b> If there's no reply within 24 hours, the question will be marked as done, and the survey
          will proceed to the next question (or execute "Failure" actions you specify below). Adjust the timeout in the
          "Timeout / no reply" section below.
        </Typography>
      </div>
      <ResponseWhatsappConfigs
        actionProps={actionProps}
        apiProps={apiProps}
        campaignType={props.type}
        configs={configs}
        id={id}
        snippetProps={snippetProps}
        onChange={handleConfigsChange}
      />
    </div>
  )
}
