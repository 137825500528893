import { styled, Typography } from '@mui/material'
import { omit } from 'ramda'
import React from 'react'

import { useDebouncedState, useDeepState } from '@/app/helpers'
import { CampaignAddButton } from '@/app/module/campaigns/component/helpers'
import { CAMPAIGN_CHANGE_DELAY } from '@/app/module/campaigns/definitions'
import {
  CampaignTemplateType,
  ContentAudio,
  Language,
  LocalOnAnswerType,
  SpeechSettings,
} from '@/app/module/campaigns/types'
import { emptyAction } from '@/app/module/campaigns/utils/actions/wrap'

import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import ResponseVoiceSpeechItem from './response-voice-speech-item'
import ResponseVoiceSpeechLanguageSelect from './response-voice-speech-language-select'
import { fromSpeechValue, SpeechValue, SpeechValueState, toSpeechValue } from './response-voice-speech-utils'
import SpeechTranscribeSettings from './speech-transcribe-settings'
import SpeechTranscribeToggle from './speech-transcribe-toggle'
import VoiceProcessing from './voice-processing'

type Props = {
  actionProps: ActionProps
  apiProps: ApiProps
  campaignType: CampaignTemplateType
  id: string
  snippetProps: SnippetProps
  speech: SpeechValue

  onChange: (value: SpeechValue) => void
}

const ResponseVoiceSpeech: React.FC<Props> = ({
  actionProps,
  apiProps,
  campaignType,
  id,
  snippetProps,
  speech,
  onChange,
}) => {
  const speechLanguages = React.useMemo<Language[]>(
    () =>
      actionProps.languages.reduce((acc: Language[], l) => {
        if (!l.speechLanguage) {
          return acc
        }

        return [
          ...acc,
          {
            name: l.speechLanguage.name,
            value: l.speechLanguage.value,
          },
        ]
      }, []),
    [actionProps.languages],
  )
  const defaultSpeechLang = speech.speechSettings.languages?.['']
  const [activeSpeechLang, setActiveSpeechLang] = React.useState<string | undefined>(defaultSpeechLang)
  React.useEffect(() => {
    // set active speech language as the default one if it's not set or the value is not in the speech language list
    setActiveSpeechLang((s) => {
      if (!s || !speechLanguages.find((l) => l.value === s)) {
        return defaultSpeechLang
      }

      return s
    })
  }, [defaultSpeechLang, speechLanguages])

  const initialState = React.useMemo(() => fromSpeechValue(speech), [speech])
  const [state, setState] = useDeepState<SpeechValueState>(initialState)
  const handleChange = React.useCallback((s: SpeechValueState) => onChange(toSpeechValue(s)), [onChange])

  useDebouncedState(state, handleChange, CAMPAIGN_CHANGE_DELAY)

  const handleAnswerAdd = React.useCallback(() => {
    setState((s) => ({
      ...s,
      onAnswer: [
        ...s.onAnswer,
        {
          speechReplies: speechLanguages.reduce(
            (acc, language) => ({
              ...acc,
              [language.value]: [],
            }),
            {},
          ),
          actions: [emptyAction],
          label: '',
        },
      ],
    }))
  }, [speechLanguages, setState])
  const handleAnswerChange = React.useCallback(
    ({ replies, actions, label }: Partial<LocalOnAnswerType>, speechLang: string, index: number) => {
      if (!activeSpeechLang) {
        return
      }
      setState((s) => ({
        ...s,
        onAnswer: s.onAnswer.map((item, i) => {
          if (i !== index) {
            return item
          }
          return {
            ...item,
            speechReplies: {
              ...item.speechReplies,
              [activeSpeechLang]: replies || item?.speechReplies?.[activeSpeechLang] || [],
            },
            actions: actions || item.actions || [emptyAction],
            label: typeof label === 'string' ? label : item.label || '',
          }
        }),
      }))
    },
    [activeSpeechLang, setState],
  )
  const handleAnswerClose = React.useCallback(
    (index: number) => {
      setState((s) => ({
        ...s,
        onAnswer: s.onAnswer.filter((_, i) => i !== index),
      }))
    },
    [setState],
  )
  const handleUseAiChange = React.useCallback(
    (value: boolean) => {
      setState((s) => ({
        ...s,
        speechSettings: value
          ? {
              ...s.speechSettings,
              maxSeconds: 10,
              stopKey: '#',
              silence: 3,
            }
          : omit(['maxSeconds', 'stopKey', 'silence'], s.speechSettings),
        useAi: value,
      }))
    },
    [setState],
  )
  const handleProcessingReplyChange = React.useCallback(
    (value?: ContentAudio) => {
      setState((s) => ({
        ...s,
        processingReply: value,
      }))
    },
    [setState],
  )
  const handleTranscribeSettingsChange = React.useCallback(
    (speechSettings: Partial<SpeechSettings>) => {
      setState((s) => ({
        ...s,
        speechSettings: {
          ...s.speechSettings,
          ...speechSettings,
        },
      }))
    },
    [setState],
  )

  const isCloseDisabled = state.onAnswer.length === 1

  return (
    <div>
      <ChoiceHeader>
        <Typography>Choices</Typography>
        {activeSpeechLang && defaultSpeechLang && !!speechLanguages.length && (
          <ResponseVoiceSpeechLanguageSelect
            activeSpeechLang={activeSpeechLang}
            defaultSpeechLang={defaultSpeechLang}
            speechLanguages={speechLanguages}
            onChange={setActiveSpeechLang}
          />
        )}
      </ChoiceHeader>
      {activeSpeechLang && (
        <div className="campaign-speech-choices">
          <Typography color="textSecondary" variant="caption" gutterBottom={true}>
            Specify the words that the contact can say to answer the question and what actions we should take for each
            choice.
          </Typography>
          <ChoiceHeader>
            <SpeechTranscribeToggle useAi={state.useAi} onChange={handleUseAiChange} />
            {state.useAi && (
              <VoiceProcessing
                defaultLanguage={actionProps.defaultLanguage}
                id={id}
                languages={actionProps.languages}
                processingReply={state.processingReply}
                getVoiceProps={actionProps.getVoiceProps}
                onChange={handleProcessingReplyChange}
              />
            )}
          </ChoiceHeader>
          {state.useAi && (
            <SpeechTranscribeSettings speechSettings={state.speechSettings} onChange={handleTranscribeSettingsChange} />
          )}
          {state.onAnswer.map(({ speechReplies = {}, actions, label = '' }, i) => (
            <ResponseVoiceSpeechItem
              {...actionProps}
              {...apiProps}
              {...snippetProps}
              actions={actions}
              activeSpeechLang={activeSpeechLang}
              hasTranscribe={false}
              id={id}
              index={i}
              isCloseDisabled={isCloseDisabled}
              key={i}
              label={label}
              questionIndex={actionProps.index}
              replies={activeSpeechLang ? speechReplies[activeSpeechLang] || [] : []}
              transcribeEnabled={false}
              type={campaignType}
              onChange={handleAnswerChange}
              onClose={handleAnswerClose}
            />
          ))}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CampaignAddButton tooltip="Add choice" onClick={handleAnswerAdd} text="Add choice" icon="add" />
          </div>
        </div>
      )}
    </div>
  )
}

const ChoiceHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

export default ResponseVoiceSpeech

export type { Props as ResponseVoiceSpeechProps }
