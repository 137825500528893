import { Box, Grid2, styled, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

import { selectActiveOrg } from '@/app/module/user/store/selectors'
import { Organization } from '@/app/types'

import AutoDeletion from './auto-deletion'

type Props = {}

const PrivacyData: React.FC<Props> = () => {
  const activeOrg = useSelector(selectActiveOrg) as Organization | undefined

  const canManageOrganization = activeOrg?.role === 'owner' || activeOrg?.role === 'manager'

  if (!canManageOrganization) {
    return null
  }

  return (
    <Container>
      <Typography variant="h5" color="primary" gutterBottom>
        Privacy & Data
      </Typography>

      {!!activeOrg && (
        <Grid2 container>
          <Grid2 size={{ xs: 12, sm: 8 }}>
            <Typography variant="body1" paragraph>
              Manage privacy settings and data retention policies for your organization.
            </Typography>

            <Box mb={4}>
              <Typography variant="h6" gutterBottom>
                Data Retention
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                Configure how long data is stored in your organization account before being automatically removed.
              </Typography>

              <AutoDeletion orgId={activeOrg.orgId} />
            </Box>
          </Grid2>
        </Grid2>
      )}
    </Container>
  )
}

const Container = styled('div')({
  padding: 15,
})

export default PrivacyData
export type { Props as PrivacyDataProps }
