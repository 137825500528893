import { DialogTitle, Icon, IconButton, styled, Typography } from '@mui/material'
import React from 'react'

import IconText from '@/app/component/layout/icon-text'

type Props = {
  icon?: string
  title: React.ReactNode
  onClose: () => void
}

const DialogHeader: React.FC<Props> = ({ icon, title, onClose }) => {
  return (
    <StyledDialogTitle>
      <TitleContainer>
        <IconText>
          <Icon>{icon}</Icon>
          <Typography variant="h6">{title}</Typography>
        </IconText>
        <IconButton onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </TitleContainer>
    </StyledDialogTitle>
  )
}

const StyledDialogTitle = styled(DialogTitle)({
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
})

const TitleContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

export default DialogHeader
