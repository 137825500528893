import ACTION_SET_UTIL_DEFAULT_TZ from '@/app/module/utils/definitions'
import { createReducer } from '@/app/service/util'

import defaultState from './state'

export default createReducer(defaultState, {
  [ACTION_SET_UTIL_DEFAULT_TZ]: (state, { value }) => ({
    ...state,
    timezone: value,
  }),
})
