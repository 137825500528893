import { isEmpty, path, pathOr, pathSatisfies } from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Guard from '@/app/component/guard/auth'
import { CATI_USER_ROLE } from '@/app/module/cati/definitions'
import { selectPlan } from '@/app/module/plans/store/selectors'
import { switchOrganization } from '@/app/module/user/store/actions'
import {
  selectActiveOrg,
  selectOrgId,
  selectUserOrganizations,
  selectUserRole,
} from '@/app/module/user/store/selectors'

import Header from './component/header'
import Invitation from './component/invitation'
import Login from './component/login'
import RequestResetPassword from './component/request-reset-password'
import ResetPassword from './component/reset-password'
import Signup from './component/signup'
import SignupEmail from './component/signup-email'
import VerifyOTP from './component/verify-otp'
import VerifyRecoveryCode from './component/verify-recovery-code'
import {
  acceptInvitation,
  acceptInvitationWithSignup,
  checkInvitation,
  emailCheck,
  login,
  loginSuccess,
  logout,
  modSignupData,
  requestResetPassword,
  requestResetPasswordSuccess,
  resetPassword,
  resetPasswordSuccess,
  signup,
  signUpEmail,
  submitOTP,
  submitRecoveryCode,
  verifyOTPSuccess,
  verifyRecoveryCodeSuccess,
} from './store/actions'
import { selectAccountConfig, selectInvitationProfileConfig, selectProfileConfig } from './store/selectors'

const loginSelector = ({ auth, user }) => ({
  error: {
    ...auth.login.error,
    ...user.details.error,
  },
  loading: auth.login.loading || user.details.loading,
  role: selectUserRole({ user }),
})

const loginDispatcher = (dispatch) =>
  bindActionCreators(
    {
      submit: login,
      logout,
      clearLoginError: () => loginSuccess({}),
    },
    dispatch,
  )

export const LoginForm = connect(loginSelector, loginDispatcher)(Login)

const requestResetPasswordSelector = ({ auth, user }) => ({
  error: {
    ...auth.requestResetPassword.error,
    ...user.details.error,
  },
  loading: auth.requestResetPassword.loading || user.details.loading,
  role: selectUserRole({ user }),
})

const requestResetPasswordDispatcher = (dispatch) =>
  bindActionCreators(
    {
      submit: requestResetPassword,
      logout,
      clearRequestResetPasswordError: () => requestResetPasswordSuccess({}),
    },
    dispatch,
  )

export const RequestResetPasswordForm = connect(
  requestResetPasswordSelector,
  requestResetPasswordDispatcher,
)(RequestResetPassword)

const resetPasswordSelector = ({ auth, user }) => ({
  error: {
    ...auth.resetPassword.error,
    ...user.details.error,
  },
  loading: auth.resetPassword.loading || user.details.loading,
  role: selectUserRole({ user }),
})

const resetPasswordDispatcher = (dispatch) =>
  bindActionCreators(
    {
      submit: resetPassword,
      logout,
      clearResetPasswordError: () => resetPasswordSuccess({}),
    },
    dispatch,
  )

export const ResetPasswordForm = connect(resetPasswordSelector, resetPasswordDispatcher)(ResetPassword)

const signupSelector = ({ auth, user, utils }) => ({
  loading: auth.signup.loading || auth.emailCheck.loading || auth.login.loading || user.details.loading,
  error: {
    ...auth.signup.error,
    ...auth.emailCheck.error,
    ...user.details.error,
  },
  item: auth.signup.data,
  hydrateProps: ['id', 'verifyKey'],
  specs: {
    account: selectAccountConfig({ utils }),
    profile: selectProfileConfig({ utils }),
  },
})

const signupDispatcher = (dispatch) =>
  bindActionCreators(
    {
      submit: signup,
      emailCheck,
      modSignupData,
      clearSignupError: () => () => dispatch(modSignupData({})),
    },
    dispatch,
  )

export const SignupForm = connect(signupSelector, signupDispatcher)(Signup)

const signupEmailSelector = ({ auth }) => ({
  loading: auth.signupEmail.loading,
})

const signupEmailDispatcher = (dispatch) =>
  bindActionCreators(
    {
      onSubmit: signUpEmail,
    },
    dispatch,
  )

export const SignupEmailForm = connect(signupEmailSelector, signupEmailDispatcher)(SignupEmail)

const guardSelector = ({ auth, user, plans }) => ({
  activeOrg: selectActiveOrg({ user }),
  error: user.details.error,
  loading:
    pathOr(false, ['login', 'loading'], auth) ||
    pathOr(false, ['details', 'loading'], user) ||
    pathOr(false, ['item', 'loading'], plans),
  isAuthorized: pathSatisfies((token) => !!token, ['login', 'data', 'token'], auth) && !isEmpty(user.details.data),
  isCATIUser: selectUserRole({ user }) === CATI_USER_ROLE,
  trialMode: pathOr(false, ['trialMode'], auth),
  planId: path(['item', 'data', 'planId'], plans),
  role: selectUserRole({ user }),
})

export const AuthGuard = connect(guardSelector)(Guard)

const headerSelector = ({ auth, user, plans, payments }) => ({
  token: path(['login', 'data', 'token'], auth),
  orgId: selectOrgId({ user }),
  activeOrg: selectActiveOrg({ user }),
  balance: pathOr({}, ['autoRecharge', 'data'], payments),
  email: path(['details', 'data', 'username'], user),
  organizations: selectUserOrganizations({ user }),
  trialMode: pathOr(false, ['trialMode'], auth),
  planId: path(['item', 'data', 'planId'], plans),
  plan: selectPlan(plans),
  role: selectUserRole({ user }),
})

const headerDispatcher = (dispatch) =>
  bindActionCreators(
    {
      logout,
      switchOrganization,
    },
    dispatch,
  )

export const AuthHeader = connect(headerSelector, headerDispatcher)(Header)

const verifyOTPSelector = ({ auth }) => ({
  token: path(['otp', 'data', 'token'], auth),
  error: {
    ...auth.otp.error,
  },
  loading: auth.otp.loading,
})

const verifyOTPDispatcher = (dispatch) =>
  bindActionCreators(
    {
      submit: submitOTP,
      logout,
      clearVerifyOTPError: () => verifyOTPSuccess({}),
    },
    dispatch,
  )

export const VerifyOTPForm = connect(verifyOTPSelector, verifyOTPDispatcher)(VerifyOTP)

const verifyRecoveryCodeSelector = ({ auth }) => ({
  token: path(['otp', 'data', 'token'], auth),
  error: {
    ...auth.recoveryCode.error,
  },
  loading: auth.recoveryCode.loading,
})

const verifyRecoveryCodeDispatcher = (dispatch) =>
  bindActionCreators(
    {
      submit: submitRecoveryCode,
      logout,
      clearRecoveryCodeError: () => verifyRecoveryCodeSuccess({}),
    },
    dispatch,
  )

export const VerifyRecoveryCodeForm = connect(
  verifyRecoveryCodeSelector,
  verifyRecoveryCodeDispatcher,
)(VerifyRecoveryCode)

const checkInvitationSelector = ({ auth, utils }) => ({
  token: path(['login', 'data', 'token'], auth),
  initialized: !path(['login', 'loading'], auth),
  loading: auth.invitation.loading,
  specs: {
    profile: selectInvitationProfileConfig({ utils }),
    account: selectAccountConfig({ utils }),
  },
})

const checkInvitationDispatcher = (dispatch) =>
  bindActionCreators(
    {
      checkInvitation,
      acceptInvitation,
      acceptInvitationWithSignup,
      logout,
    },
    dispatch,
  )

export const CheckInvitation = connect(checkInvitationSelector, checkInvitationDispatcher)(Invitation)
