import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { concat, filter, find, keys, propEq, where } from 'ramda'
import React from 'react'

import SearchableSelect from '@/app/component/atom/form/searchable-select'
import ListSelect from '@/app/component/atom/list-select'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { PersonalizationType } from '@/app/module/campaigns/types'
import { CampaignListItem } from '@/app/module/campaigns/types/list'
import { CountryTimezone, SingleTimezoneType, TimezoneWithCountryCodeType } from '@/app/types'

type Props = {
  campaign: CampaignListItem
  countriesTimezones: CountryTimezone[]
  isOpen: boolean
  personalizationList: PersonalizationType
  timezone: string
  timezones: TimezoneWithCountryCodeType

  onClose: () => void
  onConfirm: (payload: { timezone: string; fields: string[]; labels: boolean }) => void
}

const defaultFields = ['contact.firstName', 'contact.lastName', 'contact.fullPhoneNumber']

const DownloadReportDialog: React.FC<Props> = ({
  campaign,
  countriesTimezones = [],
  isOpen,
  personalizationList = {},
  timezone: initialTimezone,
  timezones: initialTimezones = {},
  onClose,
  onConfirm,
}) => {
  const tempCountryCode = keys(
    filter(where({ tzs: find(propEq('timezone', initialTimezone)) }))(initialTimezones),
  )[0] as string

  const [country, setCountry] = React.useState(tempCountryCode)
  const [timezone, setTimezone] = React.useState(initialTimezone)
  const [timezones, setTimezones] = React.useState<SingleTimezoneType[]>([])
  const [selectedFields, setSelectedFields] = React.useState(defaultFields)
  const [useLabels, setUseLabels] = React.useState(true)

  React.useEffect(() => {
    const tzs = initialTimezones[country]?.tzs || []
    setTimezones(tzs)
    setTimezone((s) => {
      if (!tzs.length) {
        return ''
      }

      if (!find(propEq('timezone', s), tzs)) {
        return tzs[0].timezone
      }

      return s
    })
  }, [country, initialTimezones])

  const { contact = [], customfields = [] } = personalizationList
  const fields = React.useMemo(
    () => concat(contact, customfields).filter((field) => field.value !== 'contact.id'),
    [contact, customfields],
  )

  return (
    <CustomDialog id="download-campaign-dialog" open={!!isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography component="div" variant="h6">
          Configure the report for {campaign.name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div>
          <FormControlLabel
            control={<Checkbox onChange={() => setUseLabels(!useLabels)} checked={useLabels} />}
            label="Show labels as answers instead of responses"
          />
        </div>
        <div>
          <Typography variant="subtitle1" color="textSecondary">
            What time zone do you want the dates and times in the report to be in?
          </Typography>
          <div
            style={{
              marginTop: '5px',
            }}
          >
            <SearchableSelect
              formControlProps={{
                margin: 'none',
              }}
              labelId="report-country-timezone-label"
              id="report-country-timezone-select"
              value={country}
              error={!country}
              label="Select Country"
              keyPropFn={(option) => option.value}
              valuePropFn={(option) => option.label}
              options={countriesTimezones}
              onChange={(selectedId) => {
                setCountry(selectedId)
              }}
              showAll
            />
          </div>
          <div>
            <SearchableSelect
              formControlProps={{
                margin: 'none',
              }}
              labelId="report-timezone-label"
              id="report-timezone-select"
              value={timezone}
              error={!timezone}
              label="Select Timezone"
              keyPropFn={(option) => option.timezone}
              valuePropFn={(option) => `${option.city} (${option.offset})`}
              onChange={(selectedId) => {
                setTimezone(selectedId)
              }}
              options={timezones}
              showAll
            />
          </div>
          <div
            style={{
              marginTop: '15px',
            }}
          >
            <Typography variant="subtitle1" color="textSecondary">
              Which contact fields do you want to include in the report?
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Contact ID is always included.
            </Typography>
            <div
              style={{
                marginTop: '5px',
              }}
            >
              <Paper sx={{ padding: (theme) => theme.spacing(0, 2) }}>
                <ListSelect
                  name="contact-fields"
                  options={fields}
                  selectedOptions={selectedFields}
                  onChange={setSelectedFields}
                />
              </Paper>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button id="downloadcampaigns-cancel" variant="contained" onClick={onClose}>
          Close
        </Button>
        <Button
          id="downloadcampaigns-confirm"
          variant="contained"
          color="primary"
          disabled={!timezone}
          onClick={() => onConfirm({ timezone, fields: selectedFields, labels: useLabels })}
        >
          Download the campaign report
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}

export default DownloadReportDialog
