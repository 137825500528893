import { Button, ButtonProps, Icon, Typography } from '@mui/material'
import React from 'react'

import IconText from '@/app/component/layout/icon-text'

type Props = {
  color?: ButtonProps['color']
  disabled: boolean
  icon: string
  label: string
  style?: React.CSSProperties
  onClick: () => void
}

const ActionButton: React.FC<Props> = ({ color, disabled, icon, label, style, onClick }) => {
  return (
    <Button
      className="subscription-action-button"
      color={color}
      disabled={disabled}
      style={style}
      variant="outlined"
      onClick={onClick}
    >
      <IconText>
        <Icon>{icon}</Icon>
        <Typography>{label}</Typography>
      </IconText>
    </Button>
  )
}

export default ActionButton
