import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionActions from '@mui/material/AccordionActions'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { pathOr } from 'ramda'
import React from 'react'

import { Radio } from '@/app/component/atom/form'
import Conditions from '@/app/component/conditions'
import { MESSAGE_PERSONALIZATION } from '@/app/module/campaigns/definitions'
import {
  CampaignQuery,
  FilesState,
  PersonalizationType,
  SenderIdOption,
  SnippetType,
} from '@/app/module/campaigns/types'
import {
  addConditionText,
  conditionText,
  generateSummaryText,
  transformConditions,
} from '@/app/module/phonenumbers/helpers'
import { ChannelType, InboundHook, PhoneNumber } from '@/app/module/phonenumbers/types'
import {
  Condition,
  CountryTimezone,
  FileType,
  FileUploadType,
  SelectOption,
  TimezoneWithCountryCodeType,
} from '@/app/types'

import HookAction from './hook-action'
import RuleLabel from './rule-label'

const labelMap: Record<string, string> = {
  sms: 'an SMS on this number with the conditions above',
  voice: 'a voice call on this number',
  whatsapp: 'a WhatsApp message on this number with the conditions above',
}

const generateRadioLabels = ({ noCondition }: { noCondition: boolean }): Record<string, SelectOption<string>[]> => ({
  sms: [
    {
      label: `every SMS${!noCondition ? conditionText : ''}`,
      value: 'false',
    },
    {
      label: `every unexpected SMS${!noCondition ? conditionText : ''}`,
      value: 'true',
    },
  ],
  whatsapp: [
    {
      label: `every WhatsApp message${!noCondition ? conditionText : ''}`,
      value: 'false',
    },
    {
      label: `every unexpected WhatsApp message${!noCondition ? conditionText : ''}`,
      value: 'true',
    },
  ],
})

export type HookProps = {
  campaignIds: SelectOption<number>[]
  countryCode: string
  countriesTimezones: CountryTimezone[]
  expanded: number
  hook: InboundHook
  files: FilesState
  index: number
  orgId: number
  personalizationList: PersonalizationType
  phoneNumber: PhoneNumber
  senderIds: SenderIdOption[]
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  timezone: string
  timezones: TimezoneWithCountryCodeType
  token: string
  type: ChannelType

  getCampaigns: (payload: { token: string; orgId: number; query: CampaignQuery }) => Promise<void>
  getFile: (payload: { file: string; msgId: string; orgId: number; token: string }) => Promise<FileType>
  getSnippets: (payload: { token: string; orgId: number }) => Promise<void>
  onChange?: (hookId: number) => void
  onError: (payload: { message: string }) => void
  onMicAccessError: () => void
  saveFile: (payload: { item: FileUploadType; msgId: string; orgId: number; token: string }) => Promise<FileType>
  saveHook: (hook: InboundHook) => Promise<void>
  saveSnippet: (payload: {
    orgId: number
    token: string
    item: Pick<SnippetType, 'category' | 'snippet'>
  }) => Promise<SnippetType>
  setDeleteId: (value: number) => void
}

function Hook(props: HookProps) {
  const [hook, setHook] = React.useState(props.hook)
  const [conditions, setConditions] = React.useState<Condition | null>(null)
  const [showConditionsModal, setShowCondiitionsModal] = React.useState(false)
  const unexpected = pathOr(false, ['conditions', 'unexpected'], hook)
  const hasNoconditions = !conditions
  React.useEffect(() => {
    setHook(props.hook)
    if (props.hook) {
      const transformedConditions = transformConditions(pathOr(null, ['conditions', 'conditions'], props.hook))
      setConditions(transformedConditions)
    }
  }, [props.hook])

  const {
    campaignIds,
    countryCode,
    countriesTimezones,
    expanded,
    files,
    index: i,
    orgId,
    personalizationList,
    phoneNumber,
    senderIds,
    snippets,
    timezone,
    timezones,
    token,
    type = 'sms',

    getFile,
    getSnippets,
    getCampaigns,
    onChange,
    onError,
    onMicAccessError,
    saveFile,
    saveHook,
    saveSnippet,
    setDeleteId,
  } = props
  const [loading, setLoading] = React.useState(false)

  const hookId = hook.id
  const isNewRule = i === -1
  const open = isNewRule || expanded === hookId

  const canHaveMultipleRules = type === ChannelType.Sms || type === ChannelType.WhatsApp

  const handleRuleSave = async () => {
    if (pathOr(0, ['actions', 'length'], hook) > 0) {
      setLoading(true)
      try {
        await saveHook({
          ...hook,
          conditions: {
            unexpected: pathOr(false, ['conditions', 'unexpected'], hook),
            conditions,
          },
        })
      } finally {
        setLoading(false)
      }
    } else if (canHaveMultipleRules) {
      onError({
        message: 'Rule not saved. Please add at least one action.',
      })
    } else {
      setDeleteId(hook.id)
    }
  }

  return (
    <StyledAccordion key={hookId} expanded={open} onChange={(_, isExpanded) => onChange?.(isExpanded ? hookId : -1)}>
      <StyledAccordionSummary expandIcon={isNewRule ? null : <ExpandMoreIcon />}>
        <div>
          {canHaveMultipleRules && (
            <RuleLabel
              editable={open}
              index={i}
              label={hook.label}
              onChange={(newLabel) => setHook((s) => ({ ...s, label: newLabel }))}
            />
          )}
          <SecondaryHeader className="inbound-hook-summary">
            {generateSummaryText(hook.actions.length, unexpected, type, hasNoconditions)}
          </SecondaryHeader>
        </div>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Grid container>
          <div style={{ width: '100%' }}>
            {canHaveMultipleRules && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div
                    style={{
                      marginBottom: '-0.5rem',
                      marginLeft: '5px',
                      width: '100%',
                      paddingRight: '10px',
                    }}
                  >
                    <Typography variant="caption" color="textSecondary">
                      An <strong>unexpected {type === 'sms' ? 'SMS' : 'WhatsApp message'}</strong> is one that is
                      received when there is no question from a campaign waiting for a reply from the contact.
                    </Typography>
                    <Radio
                      name="unexpected"
                      row={true}
                      value={`${pathOr(false, ['conditions', 'unexpected'], hook)}`}
                      values={generateRadioLabels({ noCondition: hasNoconditions })[type]}
                      onChange={({ value }) => {
                        setHook({
                          ...hook,
                          conditions: {
                            ...hook.conditions,
                            unexpected: value === 'true',
                          },
                        })
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '100%' }}>
                    {hasNoconditions && (
                      <Typography
                        sx={{ flex: isNewRule ? 1 : 'unset', paddingRight: '10px', textAlign: 'right' }}
                        variant="caption"
                        color="textSecondary"
                      >
                        {addConditionText}
                      </Typography>
                    )}
                    <Button
                      className="add-edit-conditions-btn"
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => setShowCondiitionsModal(true)}
                    >
                      {hasNoconditions ? 'Add' : 'Edit'} conditions
                    </Button>
                  </div>
                </div>
                {showConditionsModal && (
                  <Conditions
                    personalizationList={{
                      ...MESSAGE_PERSONALIZATION,
                      ...personalizationList,
                    }}
                    groups={{}}
                    segments={{}}
                    onSaveConditions={(item: { when: Condition }) => {
                      setConditions(pathOr(null, ['when'], item))
                      setShowCondiitionsModal(false)
                    }}
                    onClose={() => {
                      setShowCondiitionsModal(false)
                    }}
                    item={[]}
                    title="Run the actions"
                    data={conditions || ['or', ['and', ['', '', '']]]}
                  />
                )}
              </>
            )}
            <HookAction
              files={files}
              saveFile={saveFile}
              getFile={getFile}
              title={`Add/ Edit actions to run upon receiving ${labelMap[type]}`}
              item={{
                actions: hook.actions,
              }}
              type={type}
              personalizationList={personalizationList}
              senderIds={senderIds}
              phoneNumber={phoneNumber}
              getCampaigns={getCampaigns}
              orgId={orgId}
              token={token}
              campaignIds={campaignIds}
              timezones={timezones}
              timezone={timezone}
              countriesTimezones={countriesTimezones}
              countryCode={countryCode}
              getSnippets={getSnippets}
              snippets={snippets}
              saveSnippet={saveSnippet}
              changeHandler={(actions) => {
                setHook({
                  ...hook,
                  actions,
                })
              }}
              onMicAccessError={onMicAccessError}
            />
          </div>
        </Grid>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        {!isNewRule && canHaveMultipleRules && (
          <div
            style={{
              width: '100%',
            }}
          >
            <DeleteButton
              size="small"
              onClick={() => {
                setDeleteId(hook.id)
              }}
            >
              Remove Rule
            </DeleteButton>
          </div>
        )}
        <Button disabled={loading} size="medium" color="primary" variant="contained" onClick={handleRuleSave}>
          Save
        </Button>
      </AccordionActions>
    </StyledAccordion>
  )
}

const StyledAccordion = styled(Accordion)({
  '&::before': {
    opacity: 0,
  },
  borderRadius: '2px',
  marginBottom: '15px',
  transform: 'translate3d(0, 0, 0)',
})

const StyledAccordionSummary = styled(AccordionSummary)({
  backgroundColor: 'rgb(245, 245, 245)',
  padding: '6px 24px 6px 24px',
})

const SecondaryHeader = styled(Typography)({
  fontSize: 15,
  flexBasis: '90%',
  color: 'rgba(0, 0, 0, 0.54)',
})

const DeleteButton = styled(Button)({
  color: '#ff5252',
})

export default Hook
