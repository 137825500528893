import { z } from 'zod'

import { ZHeader, ZHTTPMethod } from '@/app/module/campaigns/types/schemas/common'

import ZBaseAction from './base'

const ZAPIAction = ZBaseAction.extend({
  makeAPIRequest: z.object({
    headers: z.array(ZHeader),
    method: ZHTTPMethod.extract(['GET', 'POST']),
    payload: z.string(),
    url: z.string(),
  }),
})

export default ZAPIAction
