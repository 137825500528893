import { pathOr } from 'ramda'

import { getPageSize } from '@/app/service/storage'
import { createAsyncState } from '@/app/service/util'
import config from '@/config'

const pageSize = getPageSize({
  namespace: 'messages',
  defaultValue: pathOr(25, ['modules', 'messages', 'pageSize', 'default'], config),
})

export default createAsyncState({
  data: {
    messages: [],
    calls: [],
    whatsapp: [],
    topups: [],
    pageSize,
    totalCount: 0,
    pageInfo: {
      endCursor: '',
      hasNextPage: false,
    },
    filters: {
      type: '',
      status: '',
    },
    search: '',
    sendMessage: {
      recipient: '',
      contactId: '',
      senderId: '',
    },
    senderIds: [],
  },
})
