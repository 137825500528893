import { z } from 'zod'

import ZBaseAction from './base'

const ZBaseUnsubscribe = z.object({
  allContactsAreSelected: z.boolean(),
})

const ZUnsubscribeCamp = ZBaseUnsubscribe.extend({
  campId: z.string(),
})

const ZUnsubscribeCurrent = ZBaseUnsubscribe.extend({
  current: z.boolean(),
})

const ZUnsubscribeAction = ZBaseAction.extend({
  unsubscribe: z.union([ZUnsubscribeCamp, ZUnsubscribeCurrent]),
})

export default ZUnsubscribeAction
