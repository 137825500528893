import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React from 'react'

import Loading from '@/app/component/guard/loading'
import { logProps, tabMap } from '@/app/module/logs/helpers'

import MessageList from './list'

export default function Log(props: logProps) {
  const {
    downloadMessages,
    downloadCalls,
    downloadWhatsapp,
    downloadTopups,
    getMessages,
    getCalls,
    getWhatsapp,
    getTopups,
    reloadMessages,
    reloadCalls,
    reloadWhatsapp,
    reloadTopups,
    messages,
    calls,
    whatsapp,
    topups,
    tab = 'messages',
    history,
    clearFilters,
  } = props

  const prevOrgRef = React.useRef<number | null>(null)

  React.useEffect(() => {
    if (prevOrgRef.current && prevOrgRef.current !== props.orgId) {
      history.push('/logs')
    }
    prevOrgRef.current = props.orgId
  }, [history, props.orgId])

  const tabProps: tabMap = {
    messages: {
      downloadData: downloadMessages,
      getData: getMessages,
      reloadData: reloadMessages,
      data: messages,
    },
    calls: {
      downloadData: downloadCalls,
      getData: getCalls,
      reloadData: reloadCalls,
      data: calls,
    },
    whatsapp: {
      downloadData: downloadWhatsapp,
      getData: getWhatsapp,
      reloadData: reloadWhatsapp,
      data: whatsapp,
    },
    topups: {
      downloadData: downloadTopups,
      getData: getTopups,
      reloadData: reloadTopups,
      data: topups,
    },
  }

  const unknownTab = !tab || (tab !== 'messages' && tab !== 'calls' && tab !== 'whatsapp' && tab !== 'topups')

  React.useEffect(() => {
    if (unknownTab) {
      history.push('/logs?tab=messages')
    }
  }, [history, unknownTab])

  return (
    <>
      <Paper>
        <Tabs
          variant="fullWidth"
          value={unknownTab ? 'messages' : tab}
          onChange={(_: React.ChangeEvent<{}>, value: string) => {
            clearFilters()
            history.push(`/logs?tab=${value}`)
          }}
        >
          <Tab label="SMS" value="messages" />
          <Tab label="Calls" value="calls" />
          <Tab label="WhatsApp" value="whatsapp" />
          <Tab label="Topups" value="topups" />
        </Tabs>
      </Paper>
      <Loading isLoading={unknownTab}>{!unknownTab && <MessageList {...props} {...tabProps[tab]} tab={tab} />}</Loading>
    </>
  )
}
