import { z } from 'zod'

import ZEndCampaignAction from './end-campaign'
import { ZHangupAction } from './hangup'
import ZJumpAction from './jump'
import ZAPIAction from './make-api-request'
import ZPlayAudioAction from './play-audio'
import ZReplayQuestionAction from './replay-question'
import ZResendQuestionAction from './resend-question'
import ZSendEmailAction from './send-email'
import ZSendSMSAction from './send-sms'
import ZSendSMSPhoneNumberAction from './send-sms-phone-number'
import ZSendWhatsappAction from './send-whatsapp'
import ZSetPausedSubscriptionAction from './set-paused-subscription'
import ZSubscribeAction from './subscribe'
import ZTopupAction from './topup'
import ZTransferAction from './transfer'
import ZUnsubscribeAction from './unsubscribe'
import ZUnsubscribeAllAction from './unsubscribe-all'
import ZUpdateContactAction from './update-contact'

const ZAction = z.union([
  ZAPIAction,
  ZEndCampaignAction,
  ZHangupAction,
  ZJumpAction,
  ZPlayAudioAction,
  ZReplayQuestionAction,
  ZResendQuestionAction,
  ZSendEmailAction,
  ZSendSMSAction,
  ZSendSMSPhoneNumberAction,
  ZSendWhatsappAction,
  ZSetPausedSubscriptionAction,
  ZSubscribeAction,
  ZTopupAction,
  ZTransferAction,
  ZUnsubscribeAction,
  ZUnsubscribeAllAction,
  ZUpdateContactAction,
])

export type APIAction = z.infer<typeof ZAPIAction>
export type EndCampaignAction = z.infer<typeof ZEndCampaignAction>
export type HangupAction = z.infer<typeof ZHangupAction>
export type JumpAction = z.infer<typeof ZJumpAction>
export type MakeAPIRequestAction = z.infer<typeof ZAPIAction>
export type PlayAudioAction = z.infer<typeof ZPlayAudioAction>
export type ReplayQuestionAction = z.infer<typeof ZReplayQuestionAction>
export type ResendQuestionAction = z.infer<typeof ZResendQuestionAction>
export type SendEmailAction = z.infer<typeof ZSendEmailAction>
export type SendSMSAction = z.infer<typeof ZSendSMSAction>
export type SendSMSPhoneNumberAction = z.infer<typeof ZSendSMSPhoneNumberAction>
export type SendWhatsappAction = z.infer<typeof ZSendWhatsappAction>
export type SetPausedSubscriptionAction = z.infer<typeof ZSetPausedSubscriptionAction>
export type SubscribeAction = z.infer<typeof ZSubscribeAction>
export type TopupAction = z.infer<typeof ZTopupAction>
export type TransferAction = z.infer<typeof ZTransferAction>
export type UnsubscribeAction = z.infer<typeof ZUnsubscribeAction>
export type UnsubscribeAllAction = z.infer<typeof ZUnsubscribeAllAction>
export type UpdateContactAction = z.infer<typeof ZUpdateContactAction>

export type Action = z.infer<typeof ZAction>

export default ZAction
