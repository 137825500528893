import { Icon, styled, Tooltip, Typography } from '@mui/material'
import React from 'react'

import { Input, Select, Time } from '@/app/component/atom/form'
import TimezonePicker from '@/app/component/atom/timezone-picker'
import { getTimePrefixLabel } from '@/app/module/campaigns/component/item/steps/content/ui-label-helpers'
import { Offset, PartFamily, TimeUnit } from '@/app/module/campaigns/types'
import { daysOfTheWeek, daysOfTheWeekOrder } from '@/app/service/util/schedule'
import { CountryTimezone, TimezoneWithCountryCodeType } from '@/app/types'

type Props = {
  countriesTimezones: CountryTimezone[]
  countryCode: string
  messageType: PartFamily
  offset: Offset
  published: boolean
  timezone: string
  timezones: TimezoneWithCountryCodeType
  onChange: (offset: Offset) => void
}

const ReminderOffset: React.FC<Props> = ({
  countriesTimezones,
  countryCode,
  messageType,
  offset,
  published,
  timezone,
  timezones,
  onChange,
}) => {
  const offsetValue = React.useMemo(() => Math.abs(offset.value), [offset.value])
  const timeOrder = React.useMemo(() => (offset.value < 0 ? TimeOrder.Before : TimeOrder.After), [offset.value])

  const timePrefix = getTimePrefixLabel(messageType)

  const handleChange = (partial: Partial<Offset>) => onChange({ ...offset, ...partial })

  const renderBefore = () => (
    <div>
      <Text variant="caption">
        {timePrefix}
        {published && (
          <div
            style={{
              float: 'right',
            }}
          >
            <Tooltip title="Changing this offset will not immediately affect current subscriptions. See the support article for details.">
              <Icon color="error">info</Icon>
            </Tooltip>
          </div>
        )}
      </Text>
    </div>
  )

  const renderUnit = () => (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <Icon style={{ margin: '0.5rem 0.5rem 0 0', color: 'rgba(0, 0, 0, 0.54)' }}>timer</Icon>
      <div style={{ width: '3rem' }}>
        <Input
          data-testid="offset-value"
          inputProps={{
            min: 0,
          }}
          label=""
          name="offset-value"
          type="number"
          value={offsetValue.toString()}
          onChange={({ value }: { value: string }) =>
            handleChange({
              value: toOffsetValue(parseInt(value, 10), timeOrder),
            })
          }
        />
      </div>
      <div style={{ width: '6rem' }}>
        <Select
          data-testid="offset-unit"
          value={offset.unit}
          values={unitList}
          name="offset-unit"
          onChange={({ value }) =>
            handleChange({
              unit: value,
            })
          }
        />
      </div>
    </div>
  )
  const renderAfter = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ width: '4rem' }}>
        <Select
          data-testid="offset-order"
          editable={!!offset.value}
          value={offset.value < 0 ? TimeOrder.Before : TimeOrder.After}
          values={timeOrderList}
          name="offset-order"
          onChange={({ value }) =>
            handleChange({
              value: toOffsetValue(offsetValue, value),
            })
          }
        />
      </div>
      <Text variant="caption" style={{ marginBottom: '0.5rem' }}>
        the event time.
      </Text>
    </div>
  )

  const renderSpecific = () => {
    const hasSpecific = offset.unit === TimeUnit.Day || offset.unit === TimeUnit.Week
    if (!hasSpecific) {
      return null
    }
    const schedule = offset.time ? Schedule.Specific : Schedule.Same

    return (
      <div>
        <div>
          <Text variant="caption">at this time of the day</Text>
          <Select
            data-testid="offset-schedule"
            value={schedule}
            name="offset-schedule"
            values={scheduleList}
            onChange={({ value }: { value: Schedule }) => {
              if (value === Schedule.Same) {
                onChange({
                  unit: offset.unit,
                  value: offset.value,
                })
              } else {
                handleChange({
                  time: '09:00',
                  timezone,
                  ...(offset.unit === TimeUnit.Week && {
                    daysOfWeek: defaultDaysOfWeek,
                  }),
                })
              }
            }}
          />
        </div>
        {schedule === Schedule.Specific && (
          <Row>
            {offset.unit === TimeUnit.Week && (
              <div style={{ width: '6rem' }}>
                <Text variant="caption">Days</Text>
                <Select
                  name="offset-days-of-week"
                  data-testid="offset-days-of-week"
                  multiple={true}
                  value={offset.daysOfWeek ? Object.keys(offset.daysOfWeek) : []}
                  values={daysOfTheWeek}
                  onChange={({ value = [] }: { value: string[] }) => {
                    const sortedValue = value.sort((a, b) => daysOfTheWeekOrder[a] - daysOfTheWeekOrder[b])

                    handleChange({
                      daysOfWeek: sortedValue.reduce((obj, v) => ({ ...obj, [v]: true }), {}),
                    })
                  }}
                />
              </div>
            )}
            <div style={{ width: '4rem' }}>
              <Text variant="caption">Time</Text>
              <Time
                name="offset-time"
                value={offset.time}
                onChange={({ value }) =>
                  handleChange({
                    time: value,
                  })
                }
              />
            </div>
            <div style={{ width: '18rem' }}>
              <TimezonePicker
                countriesTimezones={countriesTimezones}
                countryCode={countryCode}
                name="offset"
                timezone={offset.timezone || timezone}
                timezones={timezones}
                onChange={(tz) => {
                  handleChange({
                    timezone: tz,
                  })
                }}
              />
            </div>
          </Row>
        )}
      </div>
    )
  }

  return (
    <div className="campaign-item-content-offset">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {renderBefore()}
          {renderUnit()}
          {renderAfter()}
        </div>
        {renderSpecific()}
      </div>
    </div>
  )
}

const toOffsetValue = (value: number, timeOrder: TimeOrder) => value * (timeOrder === TimeOrder.Before ? -1 : 1)

const Text = styled(Typography)(() => ({
  color: 'rgba(0, 0, 0, 0.54)',
}))

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(),
}))

enum TimeOrder {
  Before = 'before',
  After = 'after',
}

enum Schedule {
  Same = 'same',
  Specific = 'specific',
}

const timeOrderList = [
  {
    label: 'before',
    value: TimeOrder.Before,
  },
  {
    label: 'after',
    value: TimeOrder.After,
  },
]
const unitList = [
  {
    label: 'seconds',
    value: TimeUnit.Second,
  },
  {
    label: 'minutes',
    value: TimeUnit.Minute,
  },
  {
    label: 'hours',
    value: TimeUnit.Hour,
  },
  {
    label: 'days',
    value: TimeUnit.Day,
  },
  {
    label: 'weeks',
    value: TimeUnit.Week,
  },
]
const scheduleList = [
  {
    label: 'same as time of event',
    value: Schedule.Same,
  },
  {
    label: 'specific time of the day',
    value: Schedule.Specific,
  },
]

const defaultDaysOfWeek: Offset['daysOfWeek'] = {
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
}

export default ReminderOffset
