import { green, red, yellow } from '@mui/material/colors'
import { pathOr } from 'ramda'

import { formatDT, numberWithCommas } from '@/app/service/util/format'

export const selectStats = ({ billing }) => ({
  start: formatDT(pathOr('', ['data', 'start'], billing)),
  end: formatDT(pathOr('', ['data', 'end'], billing)),
  smsSent: numberWithCommas(pathOr(0, ['data', 'smsStats', 'totalEgress'], billing)),
  smsReceived: numberWithCommas(pathOr(0, ['data', 'smsStats', 'totalIngress'], billing)),
  voiceSent: numberWithCommas(pathOr(0, ['data', 'voiceStats', 'totalEgress'], billing)),
  voiceReceived: numberWithCommas(pathOr(0, ['data', 'voiceStats', 'totalIngress'], billing)),
  whatsappSent: numberWithCommas(pathOr(0, ['data', 'whatsappStats', 'totalEgress'], billing)),
  whatsappReceived: numberWithCommas(pathOr(0, ['data', 'whatsappStats', 'totalIngress'], billing)),
})

export const usageLevel = (usageContacts, maxContacts) => {
  if (maxContacts === 'unlimited') {
    return green.A700
  }
  const parsedUsage = parseFloat(usageContacts.replace(/,/g, ''))
  const parsedMax = maxContacts
  if (parsedUsage < 0.5 * parsedMax || maxContacts === 0) {
    return green.A700
  }
  if (parsedUsage < 0.8 * parsedMax) {
    return yellow[800]
  }
  return red.A700
}
