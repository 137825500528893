import { FormControlLabel, FormHelperText, Icon, styled, Switch, Typography } from '@mui/material'
import React from 'react'

import CustomTooltip from '@/app/component/atom/tooltip'
import IconText from '@/app/component/layout/icon-text'

import DeleteProtectionConfirmDialog from './delete-protection-confirm-dialog'

type Props = {
  checked: boolean
  isGroup: boolean

  onChange: (value: boolean) => void
}

const contactTooltip = (checked: boolean) => (
  <Typography>
    {checked && (
      <>
        This contact is <b>Protected</b> and will <b>NOT</b> be automatically deleted.
      </>
    )}
    {!checked && (
      <>
        This contact is <b>NOT protected</b> and may be automatically deleted.
      </>
    )}
    <br />
    <br />
    Contacts may be automatically deleted if they have not been updated or used for a number of days. This is
    configurable in the organization settings. By default, no automatic deletion happens unless you enable it.
  </Typography>
)

const groupTooltip = (checked: boolean) => (
  <Typography>
    {checked && (
      <>
        The contacts in this group are <b>Protected</b> and will <b>NOT</b> be automatically deleted.
      </>
    )}
    {!checked && (
      <>
        The contacts in this group are <b>NOT protected</b> and may be automatically deleted.
      </>
    )}
    <br />
    <br />
    Contacts may be automatically deleted if they have not been updated or used for a number of days. This is
    configurable in the organization settings. By default, no automatic deletion happens unless you enable it.
  </Typography>
)

const DeleteProtectionToggle: React.FC<Props> = ({ checked, isGroup, onChange }) => {
  const [disabling, setDisabling] = React.useState(false)

  const handleToggle = React.useCallback(
    (value: boolean) => {
      if (value) {
        onChange(value)
      } else {
        setDisabling(true)
      }
    },
    [onChange],
  )

  const tooltip = isGroup ? groupTooltip(checked) : contactTooltip(checked)

  return (
    <Container>
      <FormControlLabel
        className="delete-protection-toggle"
        control={<Switch checked={checked} onChange={(e) => handleToggle(e.target.checked)} />}
        label="Auto-delete protection"
      />
      <IconText>
        <StyledHelperText>
          Would you like to exempt {isGroup ? 'the contacts in this group' : 'this contact'} from automatic deletion?
        </StyledHelperText>
        <CustomTooltip title={tooltip}>
          <StyledIcon color="action" fontSize="small">
            info
          </StyledIcon>
        </CustomTooltip>
      </IconText>
      <DeleteProtectionConfirmDialog
        isGroup={isGroup}
        open={disabling}
        onClose={() => setDisabling(false)}
        onConfirm={() => {
          setDisabling(false)
          onChange(false)
        }}
      />
    </Container>
  )
}

const Container = styled('div')({
  marginBottom: '1rem',
})

const StyledHelperText = styled(FormHelperText)({
  display: 'flex',
  alignItems: 'center',
})

const StyledIcon = styled(Icon)({
  cursor: 'pointer',
  marginLeft: '0.25rem',
})

export default DeleteProtectionToggle
