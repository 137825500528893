import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { createNotification } from '@/app/module/notifications/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { esApi } from '@/app/store/api'
import { useAppDispatch } from '@/app/store/redux-hooks'

export const useGetAutoDeletePreview = (days?: number, skip = false) => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const result = esApi.useGetOrgAutodeleteQuery(
    {
      orgId: orgId.toString(),
      days,
    },
    {
      skip: !orgId || skip,
    },
  )

  useEffect(() => {
    if (!result.error) {
      return
    }

    dispatch(
      createNotification({
        'auto-delete-preview': {
          type: 'error',
          message: 'Failed to load auto-deletion preview. Please try again.',
        },
      }),
    )
  }, [result.error, dispatch])

  return result
}
