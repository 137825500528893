import { BoxProps, styled } from '@mui/material'
import React, { PropsWithChildren } from 'react'

import Loader from '@/app/component/atom/loader'

type Props = PropsWithChildren<{
  border?: boolean
  loading?: boolean
}>

const AudioWrapper: React.FC<Props> = ({ border = true, children, loading }) => (
  <Container border={border}>
    {children}
    {loading && <Loader />}
  </Container>
)

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'border',
})<Omit<BoxProps, 'border'> & { border: boolean }>(({ border }) => ({
  background: '#fafafa',
  borderRadius: '4px',
  boxShadow: border ? '0px 0px 0px 1px rgba(224, 224, 224, 1)' : 'none',
  height: '75px',
  position: 'relative',
  margin: '12px 0',
}))

export default AudioWrapper
