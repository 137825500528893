/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from 'react'

import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import CreateCampaignsContainer from '@/app/module/campaigns/component/item/create-campaigns.container'
import Notifications from '@/app/module/notifications'
import { MatchProps } from '@/app/types'

type Props = {
  match: MatchProps
}

const CampaignsCreatePage: React.FC<Props> = ({ match }) => (
  <AuthGuard url={match.url} path={match.path}>
    <Layout title="Campaigns - engageSPARK">
      <AuthHeader currentPage={PAGE.CAMPAIGNS.INDEX} />
      <CreateCampaignsContainer />
      <Notifications />
    </Layout>
  </AuthGuard>
)

export default CampaignsCreatePage
