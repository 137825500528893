import { Box, styled } from '@mui/material'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import InputAdornment from '@mui/material/InputAdornment'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import MUITableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { find, pathOr, propEq } from 'ramda'
import React from 'react'

import { Checkbox } from '@/app/component/atom/form'
import SearchBar from '@/app/component/atom/search'
import Tooltip from '@/app/component/atom/tooltip'
import IconText from '@/app/component/layout/icon-text'
import TableLayout from '@/app/component/layout/table'
import TableCell from '@/app/component/layout/table/cell'
import TableRow from '@/app/component/layout/table/row'
import TableToolbar from '@/app/component/layout/table/toolbar'
import { useDeepState } from '@/app/helpers'
import { DelayState, LegacyCampaignItem, SubscriptionContactType } from '@/app/module/campaigns/types'
import { SubscriptionFilter, SubscriptionQuery } from '@/app/module/campaigns/types/subscriptions'
import { processContactLabel } from '@/app/module/cati/helpers'
import { ChangeEvent, CountryTimezone, TableHeader, TablePagination, TableSpecs, Timezones } from '@/app/types'

import ConfirmationDialog from './confirmation-dialog'
import DelayDialog from './delay-dialog'
import FieldStatic from './field'
import SubscriptionsFilter from './filter'
import { ConfirmationDialogState, DelaySubscriptionDialogState, getSubscriptionStatus } from './helpers'
import RowActions from './row-actions'

type Props = {
  Field: React.ComponentType
  campaign: LegacyCampaignItem
  countriesTimezones: CountryTimezone[]
  countryCode: string
  data: SubscriptionContactType[]
  emptyMessageDefault: string
  headers: TableHeader[]
  loading: boolean
  loadingItems?: number[]
  ordered: number[]
  orgId: number
  pagination: TablePagination
  query: SubscriptionQuery
  search: {
    term?: string
    onSubmit: (x: string) => void
  }
  searchLabel: string
  specs: TableSpecs
  root: string
  timezone: string
  timezones: Timezones
  token: string
  getData: (payload: { token: string; orgId: number; query: SubscriptionQuery }) => Promise<void>
  navigate: (query: SubscriptionQuery) => void
  onResume: (payload: { subId: number }) => Promise<void>
  onSubscribe: (payload: {
    allowResubscribe?: boolean
    contactId?: number
    delay?: DelayState | {}
    groupId?: number
    startAt?: string
    t0?: string
    t0timezone?: string
  }) => Promise<void>
  onUnsubscribe: (payload: { includedContactIds?: number[]; includedGroupIds?: number[] }) => Promise<void>
}

const defaultConfirmationDialog: ConfirmationDialogState = {
  type: '',
  isOpen: false,
  action: () => {},
}
const defaultDelaySubscriptionDialog: DelaySubscriptionDialogState = {
  type: '',
  isOpen: false,
  group: false,
}

const SmallGroupIcon = ({ fontSize = 'small' }: { fontSize?: 'inherit' | 'large' | 'medium' | 'small' }) => (
  <Icon
    fontSize={fontSize}
    style={{
      verticalAlign: 'middle',
      marginRight: '2px',
      marginLeft: '1px',
    }}
  >
    group
  </Icon>
)

const CampaignManageSubscriptionsTable: React.FC<Props> = (props) => {
  const {
    loading,
    loadingItems = [],
    data,
    campaign,
    specs,
    headers,
    ordered,
    pagination,
    search,
    searchLabel,
    emptyMessageDefault,
    Field = FieldStatic,
    root,
    timezone,
    timezones,
    countriesTimezones,
    countryCode,
    token,
    orgId,
    getData,
    navigate,
    onResume,
    onSubscribe,
    onUnsubscribe,
  } = props

  const [query] = useDeepState(props.query)

  React.useEffect(() => {
    if (token && orgId && root === 'contacts') {
      getData({
        token,
        orgId,
        query,
      })
    }
  }, [orgId, query, root, token, getData])

  const [confirmationDialog, setConfirmationDialog] = React.useState(defaultConfirmationDialog)
  const [delaySubscriptionDialog, setDelaySubscriptionDialog] = React.useState(defaultDelaySubscriptionDialog)
  const [activeGroupId, setActiveGroupId] = React.useState<number>(0)
  const [resubscribe, setResubscribe] = React.useState(false)
  const [confirmValue, setConfirmValue] = React.useState('')

  const handleSubscriptionFilterChange = (filter: SubscriptionFilter) => {
    navigate({
      ...query,
      subscriptionFilter: filter,
    })
  }

  const modalDataMap = {
    subscribe: {
      icon: 'input',
      title: (
        <>
          Subscribe {pathOr('', [activeGroupId, 'name'], data)} (<SmallGroupIcon />
          {pathOr('', [activeGroupId, 'numberOfContacts'], data)})
        </>
      ),
      text: (
        <>
          <Typography>
            {root === 'segments' ? (
              <>
                All contacts currently in this segment will be subscribed to the campaign. If contacts are later added
                to the segment, they will not automatically be subscribed to the campaign. You will need to subscribe
                the segment again to subscribe those new contacts.
                <br />
                <br />
                <strong>
                  Contacts previously subscribed to this campaign will not be subscribed again unless you check the box
                  below.
                </strong>
                <br />
                <br />
              </>
            ) : (
              <>
                All contacts currently in this group will be subscribed to the campaign. If you later add contacts to
                the group, they will not automatically be subscribed to the campaign; you will need to subscribe the
                group again to subscribe those new contacts.
                <br />
                <br />
                <strong>
                  Contacts previously subscribed to this campaign will not be subscribed again unless you check the box
                  below.
                </strong>
                <br />
                <br />
              </>
            )}
          </Typography>
          <Checkbox
            label={
              root === 'segments'
                ? 'Subscribe all contacts in this segment, even those who have been previously subscribed.'
                : 'Subscribe all contacts in this group, even those who have been previously subscribed.'
            }
            name="resubscribe"
            value={resubscribe.toString()}
            editable={true}
            onChange={(e: ChangeEvent<string>) => setResubscribe(e.value === 'true')}
          />
          <Typography>
            <strong>
              To confirm you want to subscribe "{pathOr('', [activeGroupId, 'name'], data)}" (<SmallGroupIcon />
              {pathOr('', [activeGroupId, 'numberOfContacts'], data)}), please type "
              {pathOr('', [activeGroupId, 'numberOfContacts'], data)}", then press Confirm
            </strong>
          </Typography>
          <div
            style={{
              marginTop: '10px',
            }}
          >
            <TextField
              placeholder={root === 'segments' ? 'Confirm Segment Size' : 'Confirm Group Size'}
              size="small"
              fullWidth={true}
              variant="outlined"
              value={confirmValue}
              className="subscription-contacts-confirm-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>group</Icon>
                  </InputAdornment>
                ),
              }}
              error={confirmValue !== `${pathOr('', [activeGroupId, 'numberOfContacts'], data)}`}
              onChange={(e) => setConfirmValue(e.currentTarget.value)}
            />
          </div>
        </>
      ),
    },
    unsubscribe: {
      icon: 'remove_circle',
      title: (
        <>
          Unsubscribe {pathOr('', [activeGroupId, 'name'], data)} (<SmallGroupIcon />
          {pathOr('', [activeGroupId, 'numberOfContacts'], data)})
        </>
      ),
      text: (
        <Typography>
          {root === 'segments' ? (
            <>
              All contacts currently in this segment who are subscribed to this campaign will be unsubscribed from the
              campaign.
              <br />
              <br />
              <strong>
                Contacts not currently in this segment will not be unsubscribed, even if they were in this segment
                previously.
              </strong>
            </>
          ) : (
            <>
              All contacts currently in this group who are subscribed to this campaign will be unsubscribed from the
              campaign.
              <br />
              <br />
              <strong>
                Contacts not currently in this group will not be unsubscribed, even if they were in this group
                previously.
              </strong>
            </>
          )}
        </Typography>
      ),
    },
  }

  const subscribeGroup = ({ allowResubscribe, groupId }: { allowResubscribe: boolean; groupId: number }) => {
    const { delay = {}, startAt, t0, t0timezone } = confirmationDialog
    setConfirmationDialog({
      ...confirmationDialog,
      isOpen: false,
      delay: {},
      startAt: '',
    })
    setResubscribe(false)
    onSubscribe({
      groupId,
      allowResubscribe,
      delay,
      startAt,
      t0,
      t0timezone,
    })
  }

  const unsubscribeGroup = (groupId: number) => {
    setConfirmationDialog({
      ...confirmationDialog,
      isOpen: false,
    })
    onUnsubscribe({
      includedGroupIds: [groupId],
    })
  }

  const generateModalTitle = ({ group }: { group?: boolean }) => {
    let name = ''
    let numberOfContacts = ''
    if (group) {
      numberOfContacts = pathOr('', [activeGroupId, 'numberOfContacts'], data)
      name = pathOr('', [activeGroupId, 'name'], data)
    } else {
      const contact = find(propEq('id', delaySubscriptionDialog.id), data)
      if (contact) {
        if (contact.firstName || contact.lastName || contact.fullPhoneNumber) {
          name = `${processContactLabel(contact)}${contact.fullPhoneNumber ? ` (${contact.fullPhoneNumber})` : ''}`
        } else {
          name = `Contact ID ${contact.id} (Contact details hidden)}`
        }
      }
    }
    return (
      <>
        Subscribe {name}{' '}
        {group ? (
          <>
            <SmallGroupIcon />
            {numberOfContacts}
          </>
        ) : (
          ''
        )}
      </>
    )
  }
  return (
    <div>
      <ConfirmationDialog
        disabled={
          confirmationDialog.type === 'subscribe' &&
          confirmValue !== `${pathOr('', [activeGroupId, 'numberOfContacts'], data)}`
        }
        text={pathOr('', [confirmationDialog.type, 'text'], modalDataMap)}
        isOpen={confirmationDialog.isOpen}
        onClose={() => {
          setResubscribe(false)
          setConfirmationDialog(defaultConfirmationDialog)
        }}
        deleteButtonText="Confirm"
        cancelButtonText="Cancel"
        hideCancel={true}
        icon={pathOr('', [confirmationDialog.type, 'icon'], modalDataMap)}
        onConfirm={() => {
          if (confirmationDialog.type === 'subscribe') {
            subscribeGroup({
              allowResubscribe: resubscribe,
              groupId: activeGroupId,
            })
          } else {
            unsubscribeGroup(activeGroupId)
          }
          setConfirmValue('')
        }}
        title={pathOr('', [confirmationDialog.type, 'title'], modalDataMap)}
      />
      {delaySubscriptionDialog.isOpen && (
        <DelayDialog
          campaign={campaign}
          title={generateModalTitle({ group: delaySubscriptionDialog.group })}
          onConfirm={({ delay, startAt, t0, t0timezone }) => {
            setDelaySubscriptionDialog(defaultDelaySubscriptionDialog)
            if (delaySubscriptionDialog.group) {
              setConfirmationDialog((s) => ({
                ...s,
                type: delaySubscriptionDialog.type,
                isOpen: true,
                id: activeGroupId,
                delay,
                startAt,
                t0,
                t0timezone,
              }))
            } else {
              onSubscribe({ contactId: delaySubscriptionDialog.id, delay, startAt, t0, t0timezone })
            }
          }}
          onClose={() => {
            setDelaySubscriptionDialog(defaultDelaySubscriptionDialog)
          }}
          isOpen={true}
          timezone={timezone}
          countryCode={countryCode}
          timezones={timezones}
          countriesTimezones={countriesTimezones}
        />
      )}
      <TableToolbar>
        {search && (
          <SearchBar
            namespace="campaign-manage-subscriptions"
            label={searchLabel}
            disabled={loading}
            searchTerm={search.term}
            onSubmit={(searchTerm) => search.onSubmit(searchTerm)}
          />
        )}
        <Box sx={{ paddingRight: 1, width: 0 }} />
        {root === 'contacts' && (
          <SubscriptionsFilter
            subscriptionFilter={query.subscriptionFilter}
            onChange={handleSubscriptionFilterChange}
          />
        )}
      </TableToolbar>
      <TableLayout
        loading={loading}
        pageSize={ordered.length}
        pagination={pagination}
        emptyMessage={query.searchTerm ? `No results for "${query.searchTerm}".` : emptyMessageDefault}
      >
        <Table>
          <TableHead>
            <TableRow>
              <MUITableCell />
              {headers.map(({ title }, i) => (
                <TableCell key={i} index={i}>
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ordered.map((itemId, itemIndex) => {
              const rowKey = root === 'contacts' ? itemIndex : itemId
              const subStats = data[rowKey]?.subscription
              const { activeId = 0 } = subStats || {}
              const subStatus = getSubscriptionStatus(subStats)

              const hideTooltip = campaign.open || !!activeId
              const actionDisabled = !campaign.open && !activeId

              return (
                <TableRow key={itemId} isLoading={loadingItems.indexOf(itemId) > -1}>
                  <MUITableCell>
                    <Tooltip
                      open={hideTooltip ? false : undefined}
                      title={!campaign.open && 'Campaign has to be opened to be able to subscribe contacts.'}
                    >
                      <div>
                        <RowActions
                          actionDisabled={actionDisabled}
                          activeGroupId={activeGroupId}
                          activeId={activeId}
                          contactId={itemId}
                          root={root}
                          subStatus={subStatus}
                          onResume={onResume}
                          onUnsubscribe={onUnsubscribe}
                          setActiveGroupId={setActiveGroupId}
                          setConfirmationDialog={setConfirmationDialog}
                          setDelaySubscriptionDialog={setDelaySubscriptionDialog}
                        />
                      </div>
                    </Tooltip>
                  </MUITableCell>
                  {headers.map(({ fields, icon }, index) => (
                    <StyledTableCell key={index} index={index}>
                      <IconText>
                        {icon && <Icon>{icon}</Icon>}
                        {fields.map((key, i) => {
                          const field = pathOr('', [rowKey, key], data)
                          return (
                            <Field
                              key={key}
                              name={key}
                              field={i > 0 ? ` ${field}` : field}
                              specs={specs[key]}
                              title={field}
                            />
                          )
                        })}
                      </IconText>
                    </StyledTableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableLayout>
      <Divider />
    </div>
  )
}

const StyledTableCell = styled(TableCell)(() => ({
  '& .MuiTypography-root': {
    maxWidth: '30vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export default CampaignManageSubscriptionsTable
