import { LanguageConfig } from '@/app/module/campaigns/language-helpers'
import { processActionsInLists, processActionsInObjects } from '@/app/module/campaigns/store/selectors'
import { OpenEnded } from '@/app/module/campaigns/types'
import { Action } from '@/app/module/campaigns/types/schemas/actions/all-actions'

type SenderIdConfig = {
  senderId: string
  senderIdQuestions: string
  senderIdWhatsapp?: string
}

type Actions = {
  actions?: Action[]
}

export function processOpenEndedActions(
  openEnded: OpenEnded | null | undefined,
  languages: LanguageConfig,
  processFn: (actions: Actions, languages: LanguageConfig) => Actions,
): OpenEnded | undefined
export function processOpenEndedActions(
  openEnded: OpenEnded | null | undefined,
  senderIdConfig: SenderIdConfig,
  processFn: (action: Actions, senderIdConfig: SenderIdConfig) => Actions,
): OpenEnded | undefined
export function processOpenEndedActions(
  openEnded: OpenEnded | null | undefined,
  config: LanguageConfig | SenderIdConfig,
  processFn:
    | ((action: Actions, config: SenderIdConfig) => Actions)
    | ((actions: Actions, config: LanguageConfig) => Actions),
): OpenEnded | undefined {
  if (!openEnded) {
    return undefined
  }
  const processed = processActionsInObjects(openEnded, config, processFn)
  if (openEnded.categories?.length) {
    processed.categories = processActionsInLists(processed.categories, config, processFn)
  }
  return processed
}
