import React from 'react'

import { WhatsAppTab } from '@/app/component/atom/whatsapp/helpers'
import CampaignContentAPI from '@/app/module/campaigns/component/item/steps/content/api'
import CampaignContentSMS from '@/app/module/campaigns/component/item/steps/content/sms'
import CampaignContentTopup from '@/app/module/campaigns/component/item/steps/content/topup'
import CampaignContentVoice from '@/app/module/campaigns/component/item/steps/content/voice'
import CampaignContentWhatsapp from '@/app/module/campaigns/component/item/steps/content/whatsapp'
import { BaseLanguageType } from '@/app/module/campaigns/helpers'
import {
  CampaignTemplateType,
  Language,
  LegacyCampaignItem,
  PartFamily,
  PersonalizationType,
  ReminderMessage,
  Message as TMessage,
} from '@/app/module/campaigns/types'
import { FilesType, FileType, FileUploadType } from '@/app/types'

import InvalidMessage from './invalid-message'

type BaseProps = {
  defaultLanguage: string
  defaultUpload: number
  files: {
    data: FilesType
    loadingItems: string[]
  }
  hasQuestion?: boolean
  id: string
  languages: Language[]
  loading: boolean
  personalizationList: PersonalizationType
  voiceList: BaseLanguageType[]
  voicePlayingId: string

  getFile: (id: string, file: string) => Promise<FileType>
  onChange: (item: Partial<TMessage>, changed?: boolean) => void
  onClose: (id: string) => void
  onMicAccessError: () => void
  saveFile: (id: string, file: FileUploadType) => Promise<FileType>
  setDefaultUpload: (defaultUpload: number) => void
  setPlayingId: (playId?: string) => void
  setWhatsappTab: (tab: WhatsAppTab) => void
}

type CampaignProps = BaseProps & {
  campaignType: LegacyCampaignItem['type']
  message: TMessage
}

type ReminderProps = BaseProps & {
  campaignType: CampaignTemplateType.Reminder
  message: ReminderMessage
}

type Props = CampaignProps | ReminderProps

const MessageContent: React.FC<Props> = ({
  campaignType,
  defaultLanguage,
  defaultUpload,
  hasQuestion,
  files,
  id,
  languages,
  loading,
  message,
  personalizationList,
  voiceList,
  voicePlayingId,
  getFile,
  onChange,
  onClose,
  onMicAccessError,
  saveFile,
  setDefaultUpload,
  setPlayingId,
  setWhatsappTab,
}) => {
  const handleGetFile = React.useCallback((file: string) => getFile(id, file), [id, getFile])
  const handleSaveFile = React.useCallback((file: FileUploadType) => saveFile(id, file), [id, saveFile])

  switch (message.type) {
    case PartFamily.API: {
      return (
        <CampaignContentAPI
          info="Make an HTTP request, wait for the result, and then take actions. Redirects are being followed, and some statuses are retried."
          item={message}
          personalizationList={personalizationList}
          changeHandler={onChange}
        />
      )
    }

    case PartFamily.Action: {
      return null
    }

    case PartFamily.CATI:
    case PartFamily.SMS: {
      const info = hasQuestion
        ? 'Include the choices in your question, e.g. "What is your gender? Reply 1 for female, 2 for male"'
        : 'Add your message'
      const label = hasQuestion ? 'Question' : 'Message'
      return (
        <CampaignContentSMS
          defaultLanguage={defaultLanguage}
          info={info}
          label={label}
          languages={languages}
          personalizationList={personalizationList}
          item={message}
          changeHandler={onChange}
        />
      )
    }

    case PartFamily.Topup: {
      return <CampaignContentTopup item={message} personalizationList={personalizationList} changeHandler={onChange} />
    }

    case PartFamily.Voice: {
      const info = hasQuestion
        ? 'Include the answer choices in your question, e.g. “What is your gender? Press the number 1 on your phone for female, press the number 2 for male."'
        : ''
      return (
        <CampaignContentVoice
          defaultLanguage={defaultLanguage}
          defaultUpload={defaultUpload}
          enableTTS
          enableTranscription
          files={files.data || emptyObject}
          filterType={campaignType}
          info={info}
          item={message}
          languages={languages}
          loading={loading}
          personalizationList={personalizationList}
          playId={voicePlayingId}
          voiceList={voiceList}
          changeHandler={onChange}
          getFile={handleGetFile}
          onMicAccessError={onMicAccessError}
          saveFileHandler={handleSaveFile}
          setDefaultUpload={setDefaultUpload}
          setPlaying={setPlayingId}
        />
      )
    }

    case PartFamily.WhatsApp: {
      const info = hasQuestion
        ? 'Include the choices in your question, e.g. "What is your gender? Reply 1 for female, 2 for male" - unless you are using WhatsApp List or Buttons.'
        : ''
      const label = hasQuestion ? 'Question' : 'Message'

      return (
        <CampaignContentWhatsapp
          defaultLanguage={defaultLanguage}
          defaultUpload={defaultUpload}
          files={files.data || emptyObject}
          filterType={campaignType}
          info={info}
          item={message}
          label={label}
          loading={loading}
          personalizationList={personalizationList}
          playId={voicePlayingId}
          changeHandler={onChange}
          getFile={handleGetFile}
          onMicAccessError={onMicAccessError}
          onTabChange={setWhatsappTab}
          saveFileHandler={handleSaveFile}
          setDefaultUpload={setDefaultUpload}
          setPlaying={setPlayingId}
        />
      )
    }

    default:
      return <InvalidMessage messageId={id} onRemove={onClose} />
  }
}

const emptyObject = {}

export default React.memo(MessageContent)
