import AddIcon from '@mui/icons-material/Add'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import FilterListIcon from '@mui/icons-material/FilterList'
import RefreshIcon from '@mui/icons-material/Refresh'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

import MultipleChoice from '@/app/component/atom/filters/multiple-choice'
import SingleChoice from '@/app/component/atom/filters/single-choice'
import Search from '@/app/component/atom/search'
import Tooltip from '@/app/component/atom/tooltip'
import ContentLayout from '@/app/component/layout/content'
import TableToolbar from '@/app/component/layout/table/toolbar'
import { CAMPAIGN_STATUS_MAP, TEMPLATE_TYPES, TEMPLATES_TO_TITLE } from '@/app/module/campaigns/definitions'
import { CampaignQuery } from '@/app/module/campaigns/types'

type Props = {
  filters: CampaignQuery
  loading: boolean
  page: number

  onCreateCampaignsClick: () => void
  onLoad: (filters: any) => void
  onSetFilters: (payload: { filters: CampaignQuery }) => void
}

const CampaignsTableToolbar: React.FC<Props> = ({ filters, loading, onCreateCampaignsClick, onLoad, onSetFilters }) => {
  const { classes } = useStyles()
  const [menus, setMenus] = useState<Record<string, Element | undefined>>({})
  const [searchValue, setSearchValue] = useState<string>(filters.search || '')
  const [statusValue, setStatusValue] = useState<string>(getStatusFromFilters(filters) || 'all')
  const [templateValue, setTemplateValue] = useState<string[]>((filters.templates || '').split(','))

  const toggleMenu = useCallback((menu: string, element?: Element) => {
    setMenus((s) => ({
      ...s,
      [menu]: element,
    }))
  }, [])

  const statusOptions = useMemo(
    () => [
      {
        label: 'All',
        value: 'all',
      },
      ...statuses.map((status) => ({
        label: status,
        value: status,
      })),
    ],
    [],
  )
  const templateOptions = useMemo(
    () =>
      TEMPLATE_TYPES.map((template) => ({
        label: TEMPLATES_TO_TITLE[template],
        value: template,
      })),
    [],
  )

  return (
    <ContentLayout>
      <TableToolbar>
        <div className={classes.container}>
          <div>
            <Search
              disabled={loading}
              label="Search"
              namespace="campaigns"
              tooltipTitle="Search by name"
              searchTerm={searchValue}
              onSubmit={(value: string) => {
                setSearchValue(value)
                onSetFilters({
                  filters: {
                    ...filters,
                    search: value,
                  },
                })
              }}
            />
          </div>
          <Button
            variant="outlined"
            style={{
              marginRight: '15px',
              paddingLeft: 20,
              paddingRight: 20,
            }}
            onClick={(e) => toggleMenu('templateMenu', e.currentTarget)}
            endIcon={<ArrowDropDownIcon />}
            startIcon={<FilterListIcon />}
          >
            Types
          </Button>
          <Button
            variant="outlined"
            style={{
              marginRight: '15px',
              paddingLeft: 20,
              paddingRight: 20,
            }}
            onClick={(e) => toggleMenu('statusMenu', e.currentTarget)}
            endIcon={<ArrowDropDownIcon />}
            startIcon={<FilterListIcon />}
          >
            Status
          </Button>
          <Button
            variant="outlined"
            style={{
              marginRight: '15px',
              paddingLeft: 20,
              paddingRight: 20,
            }}
            onClick={(e) => toggleMenu('archivedMenu', e.currentTarget)}
            endIcon={<ArrowDropDownIcon />}
            startIcon={<FilterListIcon />}
          >
            Archived
          </Button>
          <Button color="primary" startIcon={<AddIcon />} variant="contained" onClick={onCreateCampaignsClick}>
            New campaign
          </Button>

          <Menu
            id="campaign-template-menu"
            open={!!menus.templateMenu}
            onClose={() => toggleMenu('templateMenu')}
            anchorEl={menus.templateMenu}
          >
            <MultipleChoice
              options={templateOptions}
              value={templateValue}
              onChange={(v) => {
                setTemplateValue(v)

                onSetFilters({
                  filters: {
                    ...filters,
                    templates: v.length ? v.join(',') : 'none',
                  },
                })
              }}
            />
          </Menu>
          <Menu
            id="campaign-status-menu"
            open={!!menus.statusMenu}
            onClose={() => toggleMenu('statusMenu')}
            anchorEl={menus.statusMenu}
          >
            <SingleChoice
              name="status-filters"
              options={statusOptions}
              value={statusValue}
              onChange={(v) => {
                setStatusValue(v)
                const newFilters = campaignStatusMap[v]

                if (!newFilters) {
                  onSetFilters({
                    filters: {
                      archived: filters.archived,
                      open: filters.open,
                      page: filters.page,
                      size: filters.size,
                      templates: filters.templates,
                      search: filters.search,
                    },
                  })
                } else {
                  onSetFilters({
                    filters: {
                      archived: filters.archived,
                      open: filters.open,
                      page: filters.page,
                      size: filters.size,
                      templates: filters.templates,
                      search: filters.search,
                      ...(newFilters as any),
                    },
                  })
                }
              }}
            />
          </Menu>
          <Menu
            id="campaign-archived-menu"
            open={!!menus.archivedMenu}
            onClose={() => toggleMenu('archivedMenu')}
            anchorEl={menus.archivedMenu}
          >
            <div>
              <MenuItem className={classes.menuItem} disableGutters>
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={filters.archived !== 'false'}
                      onChange={(e) => {
                        onSetFilters({
                          filters: {
                            ...filters,
                            archived: e.target.checked ? 'true' : 'false',
                          },
                        })
                      }}
                      value="archived"
                      color="primary"
                    />
                  }
                  label="Include archived"
                />
              </MenuItem>
            </div>
          </Menu>
        </div>
        <div>
          <Tooltip title="Reload campaigns">
            <IconButton onClick={() => onLoad({})}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
      </TableToolbar>
    </ContentLayout>
  )
}

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    margin: 0,
    padding: '6px 16px 6px 5px',
    width: '100%',
  },
  menuItem: {
    padding: 0,
  },
  newBadge: {
    '& .MuiBadge-badge': {
      top: '10px',
      right: '-20px',
    },
  },
  statusChip: {
    marginRight: 2,
  },
})

const campaignStatusMap: Record<string, Partial<CampaignQuery>> = CAMPAIGN_STATUS_MAP

const statuses = ['Draft', 'Launched', 'Scheduled', 'Stopped']

const getStatusFromFilters = (filters: CampaignQuery) => {
  for (let i = 0; i < statuses.length; i += 1) {
    const status = statuses[i]
    const filterMap = campaignStatusMap[status]

    const keys = Object.keys(filterMap) as Array<keyof CampaignQuery>

    let matched = true

    for (let j = 0; j < keys.length; j += 1) {
      const key = keys[j]
      if (filters[key] !== filterMap[key]) {
        matched = false
        break
      }
    }
    if (matched) {
      return status
    }
  }

  return ''
}

export default CampaignsTableToolbar
