import { map, pathOr } from 'ramda'

import { OrgSubscriptionType } from '@/app/module/cati/types'
import { formatName } from '@/app/service/util/format'
import { Column } from '@/config/types'

import defaultState from './state'

export const getCatiLoading = (state: typeof defaultState) => state.item.loading

export const selectSubsContactValues = ({ cati }: any) =>
  map(
    (sub: OrgSubscriptionType) => ({
      ...sub,
      contactName: formatName(sub.contactFirstName, sub.contactLastName),
      action: {
        subId: sub.subId,
        campaignId: sub.campaignId,
      },
    }),
    pathOr([], ['subs', 'data', 'values'], cati),
  )

export const selectHeaders = (): Column[] => [
  {
    label: 'Name',
    key: 'contactName',
    visible: true,
  },
  {
    label: 'Phone number',
    key: 'contactFullPhoneNumber',
    display: 'phone',
    visible: true,
  },
  {
    label: 'Language',
    key: 'contactLanguage',
    visible: true,
  },
  {
    label: 'Campaign',
    key: 'campaignName',
    visible: true,
  },
  {
    label: 'Added',
    key: 'start',
    display: 'datetime',
    visible: true,
  },
  {
    label: 'Status',
    key: 'userStatus',
    visible: true,
  },
]
