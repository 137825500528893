import Typography from '@mui/material/Typography'
import React from 'react'

import EditableText from '@/app/component/atom/form/editableText'
import { FunctionType } from '@/config/types'

type Props = {
  label: string
  changeHandler: FunctionType
}

export default function ChoiceLabel(props: Props) {
  const [text, setText] = React.useState(props.label)
  const { changeHandler } = props
  return (
    <Typography
      component="div"
      color="primary"
      style={{
        height: '32px',
        fontSize: '0.9rem',
      }}
    >
      <EditableText
        clickableLabel={!text}
        data-testid="choice-label"
        padding="0px"
        maxLength={30}
        displayText={text || 'Add a label'}
        value={text}
        textInputStyle={{
          fontSize: '14px',
        }}
        onChange={(newLabel: string) => {
          setText(newLabel)
          changeHandler({
            label: newLabel,
          })
        }}
      />
    </Typography>
  )
}
