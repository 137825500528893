import { z } from 'zod'

import ZBaseAction from './base'

const ZUpdateContactAction = ZBaseAction.extend({
  updateContact: z.object({
    field: z.string(),
    value: z.string(),
    constant: z.string().optional(),
  }),
})

export default ZUpdateContactAction
