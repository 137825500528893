import {
  ByRoleMatcher,
  ByRoleOptions,
  EventType,
  fireEvent,
  MatcherOptions,
  screen,
  waitFor,
  waitForOptions,
} from '@testing-library/dom'

import { sleep } from '@/test-utils'

// eslint-disable-next-line no-console
export const safeWaitFor = (...args: Parameters<typeof waitFor>) => waitFor(...args).catch(console.error)

export const byLabelTextAction = (
  event: EventType,
  labelText: string,
  selectorOptions?: MatcherOptions,
  eventOptions?: Record<string, unknown>,
) => fireEvent[event]?.(screen.getByLabelText(labelText, selectorOptions), eventOptions)

export const byTestIdAction = (
  event: EventType,
  testId: string,
  selectorOptions?: MatcherOptions,
  eventOptions?: Record<string, unknown>,
) => fireEvent[event]?.(screen.getByTestId(testId, selectorOptions), eventOptions)

export const byTextAction = (
  event: EventType,
  text: string,
  selectorOptions?: MatcherOptions,
  eventOptions?: Record<string, unknown>,
) => fireEvent[event]?.(screen.getByText(text, selectorOptions), eventOptions)

export const byRoleAction = (
  event: EventType,
  role: ByRoleMatcher,
  selectorOptions?: ByRoleOptions,
  eventOptions?: Record<string, unknown>,
) => fireEvent[event]?.(screen.getByRole(role, selectorOptions), eventOptions)

export const byNthRoleAction = (
  event: EventType,
  role: ByRoleMatcher,
  index: number,
  selectorOptions?: ByRoleOptions,
  eventOptions?: Record<string, unknown>,
) => fireEvent[event]?.(screen.getAllByRole(role, selectorOptions)[index], eventOptions)

export const byRoleWaitAndAction = async (
  event: EventType,
  role: ByRoleMatcher,
  selectorOptions?: ByRoleOptions,
  waitOptions: waitForOptions = { timeout: 5000 },
) => {
  await safeWaitFor(() => screen.getByRole(role, selectorOptions), waitOptions)
  byRoleAction(event, role, selectorOptions)
}

export const byElementAction = async (event: EventType, element: HTMLElement, eventOptions?: Record<string, unknown>) =>
  fireEvent[event]?.(element, eventOptions)

export const openAutocomplete = (input: HTMLElement) => {
  fireEvent.focus(input)
  fireEvent.keyDown(input, { key: 'ArrowDown' })
}

const isElement = (obj: unknown): obj is HTMLElement => {
  let localObj = obj
  if (typeof localObj !== 'object') {
    return false
  }
  let prototypeStr: string
  let prototype: unknown
  do {
    prototype = Object.getPrototypeOf(localObj)
    // to work in iframe
    prototypeStr = Object.prototype.toString.call(prototype)
    // '[object Document]' is used to detect document
    if (prototypeStr === '[object Element]' || prototypeStr === '[object Document]') {
      return true
    }
    localObj = prototype
    // null is the terminal of object
  } while (prototype !== null)
  return false
}

const getElementClientCenter = (element: HTMLElement) => {
  const { left, top, width, height } = element.getBoundingClientRect()
  return {
    x: left + width / 2,
    y: top + height / 2,
  }
}

export type Coord = {
  x: number
  y: number
}

const getCoords = (charlie?: Coord | HTMLElement) => (isElement(charlie) ? getElementClientCenter(charlie) : charlie)

export type DragOptions = {
  to?: Coord | HTMLElement
  delta?: Coord
  steps?: number
  duration?: number
}

export const dragDrop = async (element: HTMLElement, { to: inTo, delta, steps = 20, duration = 500 }: DragOptions) => {
  const from = getElementClientCenter(element)
  const to = delta
    ? {
        x: from.x + delta.x,
        y: from.y + delta.y,
      }
    : getCoords(inTo)

  if (!to) {
    return
  }

  const step = {
    x: (to.x - from.x) / steps,
    y: (to.y - from.y) / steps,
  }

  const current = {
    clientX: from.x,
    clientY: from.y,
  }

  fireEvent.mouseEnter(element, current)
  fireEvent.mouseOver(element, current)
  fireEvent.mouseMove(element, current)
  fireEvent.mouseDown(element, current)
  for (let i = 0; i < steps; i += 1) {
    current.clientX += step.x
    current.clientY += step.y
    // eslint-disable-next-line no-await-in-loop
    await sleep(duration / steps)
    fireEvent.mouseMove(element, current)
  }
  fireEvent.mouseUp(element, current)
}
