import React from 'react'

import WarningText from '@/app/component/atom/warning-text'
import { openIntercom } from '@/app/service/intercom/utils'

export const getDisabledCreditCardReason = (
  hasRequiredRoles: boolean,
  hasRequiredPermissions: boolean,
): React.ReactNode => {
  if (!hasRequiredRoles) {
    return "You don't have permission to access credit cards. Contact your organization's manager or owner to gain access."
  }

  if (!hasRequiredPermissions) {
    return (
      <WarningText>
        Credit card payments are currently not enabled for your account. Please&nbsp;
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            openIntercom('show')
          }}
        >
          contact customer support
        </a>
        .
      </WarningText>
    )
  }

  return ''
}

export const getDisabledAutoRechargeReason = (
  hasRequiredRoles: boolean,
  hasRequiredPermissions: boolean,
): React.ReactNode => {
  if (!hasRequiredRoles) {
    return "You don't have permission to access auto-recharge. Contact your organization's manager or owner to gain access."
  }

  if (!hasRequiredPermissions) {
    return (
      <WarningText>
        Credit card payments are currently not enabled for your account.
        <br />
        Please{' '}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            openIntercom('show')
          }}
        >
          contact customer support
        </a>
        .
      </WarningText>
    )
  }

  return ''
}
