import { pathOr } from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'

import { useItemContext } from '@/app/module/campaigns/component/item-provider'
import { LanguageType } from '@/app/module/campaigns/helpers'
import { hasSpeechLanguage } from '@/app/module/campaigns/language-helpers'
import { changeLanguage } from '@/app/module/campaigns/languages/change-language'
import { isReminderCampaign } from '@/app/module/campaigns/models/classes/reminder-campaign.class'
import { CampaignTemplateType } from '@/app/module/campaigns/types'
import { selectLanguages, selectSpeechLanguages } from '@/app/module/utils/store/selectors'

import LanguageConfiguration from './language-configuration'

type Props = {}

const LanguagesContainer: React.FC<Props> = () => {
  const { item, setItem } = useItemContext()
  const languageList = useSelector(selectLanguages)
  const speechLanguageList = useSelector(selectSpeechLanguages)

  const languages: LanguageType[] = React.useMemo(() => pathOr([], ['uiStore', 'languages'], item), [item])
  const defaultLanguage: string = React.useMemo(() => pathOr('', ['uiStore', 'defaultLanguage'], item), [item])
  const speechEnabled = React.useMemo(() => hasSpeechLanguage(languages), [languages])

  const handleLanguagesChange = React.useCallback(
    (updatedLanguages: LanguageType[], updatedDefaultLanguage: string) => {
      if (!item || !setItem) {
        // should never happen but just in case
        return
      }

      // SMS Blast campaigns do not support multiple languages
      if (item.type === CampaignTemplateType.SMSBlast) {
        return
      }

      if (isReminderCampaign(item)) {
        const newItem = item.changeLanguage(updatedLanguages, updatedDefaultLanguage)
        setItem(newItem)

        return
      }

      const newItem = changeLanguage(item, updatedLanguages, updatedDefaultLanguage)
      setItem({
        ...newItem,
        uiStore: {
          ...newItem.uiStore,
          languages: updatedLanguages,
          defaultLanguage: updatedDefaultLanguage,
        },
      })
    },
    [item, setItem],
  )

  return (
    <LanguageConfiguration
      defaultLanguage={defaultLanguage}
      languages={languages}
      languageList={languageList}
      speechEnabled={speechEnabled}
      speechLanguageList={speechLanguageList}
      speechSupported={item?.type === 'ivr'}
      onSave={handleLanguagesChange}
    />
  )
}

export default LanguagesContainer
