import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { selectToken } from '@/app/module/auth/store/selectors'
import { selectPersonalizationList } from '@/app/module/logs/store/selectors'
import { getSegments } from '@/app/module/segments/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'

import CreateGroupForm from './component/form/create-group-form'
import CreateSegmentForm from './component/form/create-segment-form'
import UpdateGroupForm from './component/form/update-group-form'
import UpdateSegmentForm from './component/form/update-segment-form'
import { getGroups } from './store/actions'
import { selectGroupQuery } from './store/selectors'

const groupSelector = ({ auth, groups, user }) => ({
  orgId: selectOrgId({ user }),
  query: selectGroupQuery({ groups }),
  token: selectToken({ auth }),
})

const groupDispatcher = (dispatch) => ({
  getGroups: ({ token, orgId, query }) => dispatch(getGroups({ token, orgId, query })),
})

export const CreateGroup = connect(groupSelector, groupDispatcher)(CreateGroupForm)

export const UpdateGroup = connect(groupSelector, groupDispatcher)(UpdateGroupForm)

const SegmentsItemSelector = ({ auth, customFields, user }) => ({
  orgId: selectOrgId({ user }),
  personalizationList: selectPersonalizationList({ customFields }),
  token: selectToken({ auth }),
})

const segementsItemDispatcher = (dispatch) =>
  bindActionCreators(
    {
      getSegments,
    },
    dispatch,
  )

export const CreateSegment = connect(SegmentsItemSelector, segementsItemDispatcher)(CreateSegmentForm)

export const UpdateSegment = connect(SegmentsItemSelector, segementsItemDispatcher)(UpdateSegmentForm)
