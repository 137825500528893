import { GlobalStyles } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { makeStyles } from 'tss-react/mui'

import RecaptchaGuard from '@/app/component/guard/recaptcha'

type Props = PropsWithChildren<{
  title?: string
  waitRecaptcha?: boolean
}>

/**
 * The default layout, will set the title of the page and apply some global styles.
 * Because it doesn't hide google recaptcha, any page that uses this layout will have a recaptcha badge.
 */
const RecaptchaLayout: React.FC<Props> = ({ title = 'engageSPARK', children, waitRecaptcha = true }) => {
  const { classes } = useStyles()

  React.useEffect(() => {
    window.document.title = title
  }, [title])

  if (waitRecaptcha) {
    return (
      <div className={classes.page}>
        <RecaptchaGuard>
          <GlobalStyles styles={globalStyles} />
          {children}
        </RecaptchaGuard>
      </div>
    )
  }

  return (
    <div className={classes.page}>
      <GlobalStyles styles={globalStyles} />
      {children}
    </div>
  )
}

const useStyles = makeStyles()(() => ({
  page: {
    minWidth: '100%',
    overflow: 'hidden',
  },
}))

const globalStyles = {
  html: {
    height: '100%',
    width: '100%',
  },
  body: {
    height: '100%',
    overflowX: 'auto',
    boxSizing: 'content-box',
  },
  'body *': {
    boxSizing: 'border-box',
  },
  form: {
    marginBottom: 0,
  },
}

export default RecaptchaLayout
