import { Button } from '@mui/material'
import React from 'react'

import { Plan } from '@/app/module/plans/types'

import PurchaseUnavailableDialog from './purchase-unavailable-dialog'

export enum PlanAction {
  Downgrade = 'downgrade',
  Subscribe = 'subscribe',
  Upgrade = 'upgrade',
  UpgradeToYearly = 'upgrade_to_yearly',
}

type Props = {
  chargeYearly: boolean
  currentPlanBilling?: 'monthly' | 'yearly'
  currentPlanIndex: number
  isOwner: boolean
  plan: Plan
  planIndex: number

  checkPurchaseAvailable: (amount: number) => boolean
  onClick: (plan: Plan, action: PlanAction) => void
}

const SubscribeButton: React.FC<Props> = ({
  chargeYearly,
  currentPlanBilling,
  currentPlanIndex,
  isOwner,
  plan,
  planIndex,
  checkPurchaseAvailable,
  onClick,
}) => {
  const [purchaseUnavailableOpen, setPurchaseUnavailableOpen] = React.useState(false)

  const isCurrPlanYearly = currentPlanBilling === 'yearly'

  const action = React.useMemo(() => {
    if (currentPlanIndex === -1) {
      return PlanAction.Subscribe
    }
    if (currentPlanIndex === planIndex) {
      return PlanAction.UpgradeToYearly
    }
    if (currentPlanIndex > planIndex) {
      return PlanAction.Downgrade
    }
    return PlanAction.Upgrade
  }, [currentPlanIndex, planIndex])

  const buttonLabel = React.useMemo(() => {
    if (isCurrPlanYearly) {
      return 'Contact Support'
    }

    switch (action) {
      case PlanAction.Downgrade:
        return 'Downgrade Plan'

      case PlanAction.Subscribe:
        return 'Get Started!'

      case PlanAction.UpgradeToYearly:
        return 'Upgrade to yearly'

      default:
        return 'Upgrade Plan'
    }
  }, [action, isCurrPlanYearly])

  const handleClick = React.useCallback(() => {
    const available = checkPurchaseAvailable(Number(chargeYearly ? plan.priceYearly : plan.priceMonthly))
    setPurchaseUnavailableOpen(!available)
    if (!available) {
      return
    }
    onClick(plan, action)
  }, [action, chargeYearly, plan, checkPurchaseAvailable, onClick])

  const button = React.useMemo(() => {
    switch (action) {
      case PlanAction.Downgrade:
        return (
          <Button disabled={isCurrPlanYearly || !isOwner} onClick={handleClick} fullWidth variant="outlined">
            {buttonLabel}
          </Button>
        )

      case PlanAction.Subscribe:
        return (
          <Button
            disabled={!isOwner || isCurrPlanYearly}
            onClick={handleClick}
            fullWidth
            variant="contained"
            color="primary"
          >
            {buttonLabel}
          </Button>
        )

      case PlanAction.UpgradeToYearly:
        return (
          <Button
            disabled={isCurrPlanYearly || !isOwner || !chargeYearly}
            onClick={handleClick}
            fullWidth
            variant="outlined"
            color="secondary"
          >
            {buttonLabel}
          </Button>
        )

      default:
        return (
          <Button
            disabled={isCurrPlanYearly || !isOwner}
            onClick={handleClick}
            fullWidth
            variant={isCurrPlanYearly ? 'outlined' : 'contained'}
            color="primary"
          >
            {buttonLabel}
          </Button>
        )
    }
  }, [action, buttonLabel, chargeYearly, isCurrPlanYearly, isOwner, handleClick])

  const handleDialogClose = React.useCallback(() => setPurchaseUnavailableOpen(false), [])

  return (
    <>
      {button}
      {purchaseUnavailableOpen && <PurchaseUnavailableDialog onClose={handleDialogClose} />}
    </>
  )
}

export default SubscribeButton

export type { Props as SubscribeButtonProps }
