import { styled, Typography } from '@mui/material'
import React from 'react'

import { Checkbox } from '@/app/component/atom/form'
import ActionsBox from '@/app/module/campaigns/component/actions/actions-box'
import { DRAGTYPE, SMS_ACTION_DEFAULT_SENDERID, TIMEOUT_DEFAULT } from '@/app/module/campaigns/definitions'
import { CampaignTemplateType, LocalAction, LocalOnTimeout, PartFamily } from '@/app/module/campaigns/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { emptyAction } from '@/app/module/campaigns/utils/actions/wrap'
import { ChangeEvent } from '@/app/types'

import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import QuestionNumberInput from './question/number'

type Props = {
  actionProps: ActionProps
  apiProps: ApiProps
  campaignType: CampaignTemplateType
  hideTimeoutSeconds?: boolean
  id: string
  info: string
  inputAfterText: string
  inputBeforeText: string
  snippetProps: SnippetProps
  timeout: LocalOnTimeout

  onChange: (value: Partial<LocalOnTimeout>) => void
}

const ResponseVoiceTimeout: React.FC<Props> = ({
  actionProps,
  apiProps,
  campaignType,
  hideTimeoutSeconds,
  id,
  info,
  inputAfterText,
  inputBeforeText,
  snippetProps,
  timeout,
  onChange,
}) => {
  const actionOptions = React.useMemo(
    () =>
      getActionOptionsForCampaign({
        campaignType,
        partType: PartFamily.Voice,
        actionNamespace: ActionContext.OnTimeout,
      }),
    [campaignType],
  )

  const handleToggle = React.useCallback(
    ({ value }: ChangeEvent<string>) => {
      const isEnabled = value === 'true'
      onChange({
        actions: isEnabled ? [emptyAction] : [],
        enabled: isEnabled,
        timeoutSeconds: isEnabled ? TIMEOUT_DEFAULT : undefined,
      })
    },
    [onChange],
  )
  const handleActionsChange = React.useCallback(
    (actions: LocalAction[]) =>
      onChange({
        actions,
      }),
    [onChange],
  )
  const handleTimeoutChange = React.useCallback(
    ({ value }: Pick<ChangeEvent<string>, 'value'>) =>
      onChange({
        timeoutSeconds: Number(value),
      }),
    [onChange],
  )

  return (
    <div className="campaign-question-timeout-box">
      <Checkbox
        name="timeout-no-reply"
        label="Timeout / no reply"
        value={(timeout.enabled || false).toString()}
        editable
        onChange={handleToggle}
      />
      <ActionsBoxContainer>
        <Typography color="textSecondary" variant="caption" gutterBottom>
          {info}
        </Typography>
        {timeout.enabled && (
          <ActionsBox
            {...actionProps}
            {...apiProps}
            {...snippetProps}
            hasTranscribe={false}
            actionOptions={actionOptions}
            actions={timeout.actions}
            defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.voiceQuestion}
            dragContext={`${DRAGTYPE}-timeout`}
            info={
              !hideTimeoutSeconds ? (
                <Typography color="textSecondary" component="div" gutterBottom variant="caption">
                  <QuestionNumberInput
                    afterText={inputAfterText}
                    beforeText={inputBeforeText}
                    data-testid="timeout-input"
                    min={0}
                    value={(timeout.timeoutSeconds || TIMEOUT_DEFAULT).toString()}
                    onChange={handleTimeoutChange}
                  />
                </Typography>
              ) : undefined
            }
            transcribeEnabled={false}
            updateContactConfig={updateContactConfig}
            voiceId={`${id}-timeout`}
            onChange={handleActionsChange}
          />
        )}
      </ActionsBoxContainer>
    </div>
  )
}

const updateContactConfig = {
  invalid: false,
  question: false,
  openResponse: false,
}

const ActionsBoxContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(3),
}))

export default React.memo(ResponseVoiceTimeout)
