import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import {
  CampaignTemplateType,
  MessageSnippetType,
  ReminderMessage,
  Message as TMessage,
} from '@/app/module/campaigns/types'
import { createNotification } from '@/app/module/notifications/store/actions'

import { MessageSnippet } from './message-snippet'
import { getLibraryMessage } from './utils/library-message'

const StyledCardContent = styled(CardContent)({
  maxHeight: '500px',
  overflowY: 'auto',
})

type MessageLibraryProps = {
  campaignType: CampaignTemplateType
  insertionIndex: number
  timezone: string
  snippets: MessageSnippetType[]
  messageIds: string[]
  onSelect: (data: TMessage | ReminderMessage) => void
}

export function MessageLibrary({
  campaignType,
  insertionIndex,
  timezone,
  snippets,
  messageIds,
  onSelect,
}: MessageLibraryProps) {
  const dispatch = useDispatch()
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      message: '',
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onNotify = (_errors: string[]) => {
    // @TODO: specify error details. currently there is no way to do either:
    // 1. dispatching multiple notifications just stacks on top of each other, which is annoying IMHO, sometimes it's a lot of clicks'
    // 2. show one error with the formatted list - line breaks don't work here, and passing in JSX to redux seems dumb
    dispatch(
      createNotification({
        libraryActionsError: {
          message: `We found actions in this message which are not valid in the current campaign's context. We removed those actions to make this message compatible with this campaign.`,
          type: 'warning',
        },
      }),
    )
  }

  const onSubmit = handleSubmit(({ message }) => {
    const data = snippets.find((item) => String(item.id) === message)
    if (data) {
      const snippet = getLibraryMessage({
        campaignType,
        data: data.snippet,
        messageIds,
        insertionIndex,
        timezone,
        onNotify,
      })
      if (snippet) {
        onSelect(snippet)
      }
    }
  })

  return (
    <form onSubmit={onSubmit}>
      <StyledCardContent>
        <Controller
          name="message"
          control={control}
          render={({ field }) => (
            <RadioGroup value={field.value} onChange={field.onChange}>
              {snippets.map((data) => (
                <Box key={data.id} sx={{ width: '100%' }}>
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    value={data.id}
                    control={<Radio />}
                    label={<MessageSnippet item={data.snippet} />}
                    componentsProps={{
                      typography: {
                        style: {
                          flex: 1,
                          maxWidth: '100%',
                        },
                      },
                    }}
                  />
                </Box>
              ))}
            </RadioGroup>
          )}
        />
      </StyledCardContent>
      <CardActions>
        <Button type="submit" disabled={!formState.isDirty} variant="contained">
          Use message
        </Button>
      </CardActions>
    </form>
  )
}
