import { BackendFile } from '@/app/types'

import { OrgReportFilter, OrgReportMimeType } from './types'

export const isOrgReportFile = (file: BackendFile) => file.contentType === OrgReportMimeType.Spend

export const filterOrgReportFiles = (files: BackendFile[]) =>
  files.filter((file) => file.contentType !== OrgReportMimeType.Spend)

export const getOrgReportFile = (files: BackendFile[]) => files.find(isOrgReportFile)

export const hasFilter = (filter?: OrgReportFilter) => {
  return filter?.campaignIds?.length || filter?.date?.from || filter?.date?.until
}
