import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { isEmpty, pathOr } from 'ramda'
import { makeStyles } from 'tss-react/mui'

import { filterUnchecked } from './actions'

const getCellValue = (field, samples, columns) => {
  const index =
    pathOr(false, ['fieldsToColumns', field], columns) || pathOr(false, ['customFieldsToColumns', field], columns)

  return index !== false ? samples[index] : ''
}

function ContactsUploadReviewContent({ specs, configs, statuses }) {
  const { classes } = useStyles()
  const columns = filterUnchecked(configs, specs, statuses)
  return (
    <div>
      <Typography className={classes.title}>Please review and confirm your upload</Typography>
      <div className={classes.wrap}>
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(specs).map((field) => (
                <TableCell key={field}>{specs[field].label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(columns) &&
              columns.sampleData.map((samples, i) => (
                <TableRow key={i}>
                  {Object.keys(specs).map((field) => (
                    <TableCell key={field}>{getCellValue(field, samples, columns)}</TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

const useStyles = makeStyles()(() => ({
  wrap: {
    width: '100%',
    overflow: 'scroll',
  },
  title: {
    textAlign: 'center',
    margin: '2rem 0 4rem',
  },
}))

export default ContactsUploadReviewContent
