import { FormLabel, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Icon from '@mui/material/Icon'
import { pathOr } from 'ramda'
import React from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { makeStyles } from 'tss-react/mui'

import { Input } from '@/app/component/atom/form'
import Loading from '@/app/component/guard/loading'
import Fields from '@/app/component/wrapper/fields'
import { SignupEmailResponse } from '@/app/module/auth/types'
import validate, { hasEmailError, hasRequiredError } from '@/app/service/validate'

type Props = {
  loading: boolean

  onSubmit: (payload: { item: SignupEmailInfo; verifyToken: string }) => Promise<SignupEmailResponse | Error>
}

type SignupEmailInfo = {
  email: string
}

const SignupEmail: React.FC<Props> = ({ loading, onSubmit }) => {
  const { classes } = useStyles()

  const [success, setSuccess] = React.useState(false)
  const [verifyRecaptcha, setVerifyRecaptcha] = React.useState(false)
  const [signupInfo, setSignupInfo] = React.useState<SignupEmailInfo>({ email: '' })

  const submitting = React.useRef<boolean | null>(false)

  const handleSubmit = async (verifyToken: string) => {
    if (submitting.current || success) {
      return
    }

    submitting.current = true
    setVerifyRecaptcha(false)
    const res = await onSubmit({
      item: signupInfo,
      verifyToken,
    })
    if (!(res instanceof Error)) {
      setSuccess(true)
    }
    submitting.current = false
  }

  if (success) {
    return (
      <Card className={classes.wrap}>
        <CardContent className={classes.content}>
          <Icon className={classes.icon}>email_circle</Icon>
          <Typography
            align="center"
            sx={{
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            Thank you! We have sent you an email. <br />
            Please verify your email address by clicking on the link inside.
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <Loading isLoading={loading}>
      <Fields
        fields={{
          email: '',
        }}
        validation={{
          email: validate(hasRequiredError, hasEmailError),
        }}
        onSubmit={({ item }: { item: SignupEmailInfo }) => {
          setVerifyRecaptcha(true)
          setSignupInfo(item)
        }}
      >
        {({ fields, changed, hasErrors, submitHandler, fieldChangeHandler, fieldBlurHandler }: any) => (
          <form
            noValidate
            onSubmit={(e) => {
              e.persist?.()
              e.preventDefault()
              submitHandler()
            }}
          >
            <Card className={classes.wrap}>
              <CardContent className={classes.content}>
                <Icon className={classes.icon}>person</Icon>
                <FormLabel
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                >
                  Please enter your email to sign up.
                </FormLabel>
                <Input
                  name="email"
                  type="email"
                  label="Email"
                  editable={true}
                  value={fields.email.value}
                  error={fields.email.error}
                  onChange={({ name, value }: { name: string; value: string }) => {
                    fieldChangeHandler({ name, value })
                  }}
                  onBlur={fieldBlurHandler}
                />
              </CardContent>
              <CardActions style={{ justifyContent: 'flex-end', paddingBottom: '1rem' }}>
                <Button
                  type="submit"
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={!changed || loading || hasErrors}
                >
                  Sign up
                </Button>
              </CardActions>
            </Card>
          </form>
        )}
      </Fields>
      {verifyRecaptcha && (
        <GoogleReCaptcha
          onVerify={(recaptchaToken) => {
            handleSubmit(recaptchaToken)
          }}
        />
      )}
    </Loading>
  )
}

const useStyles = makeStyles()((theme) => ({
  wrap: {
    maxWidth: '400px',
    margin: 'auto',
    marginTop: 'calc(60px + 5%)',
    marginBottom: '3rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2rem',
  },
  error: {
    height: '60px',
    background: pathOr('', ['palette', 'error', 'main'], theme),
    '& p': {
      color: pathOr('', ['palette', 'error', 'contrastText'], theme),
    },
  },
  icon: {
    margin: 'auto',
    fontSize: '4rem !important',
    color: pathOr('', ['palette', 'primary', 'main'], theme),
  },
  button: {
    alignSelf: 'flex-end',
  },
}))

export default SignupEmail
