import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Loading from '@/app/component/guard/loading'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_EXPORT_SMS_REPORT, EXPORT_SMS_REPORT_PENDING } from '@/app/module/user/definitions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { useRequirePermission } from '@/app/module/user/store/ts/hooks'
import { selectUserLoading } from '@/app/module/user/store/ts/selectors'
import { esApi } from '@/app/store/api'

import NoReportPermission from './no-report-permission'
import SmsReport from './sms-report'

type Props = {}

const SMSReportContainer: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const orgId = useSelector(selectOrgId)
  const hasPermission = useRequirePermission('downloadReport')
  const userLoading = useSelector(selectUserLoading)

  const [createReport, { isLoading }] = esApi.endpoints.createOrgReport.useMutation()
  const handleCreate = async (month: string) => {
    try {
      await createReport({ orgId: String(orgId), body: { function: 'smsreport', month } }).unwrap()
      dispatch(
        createNotification({
          'export-sms-report-pending': {
            type: 'success',
            message: EXPORT_SMS_REPORT_PENDING,
          },
        }),
      )
      return true
    } catch {
      dispatch(
        createNotification({
          'export-sms-report-error': {
            type: 'error',
            message: ERROR_EXPORT_SMS_REPORT,
          },
        }),
      )
      return false
    }
  }

  return (
    <Loading isLoading={userLoading}>
      {!userLoading && !hasPermission && <NoReportPermission />}
      {hasPermission && <SmsReport isLoading={isLoading} onCreate={handleCreate} />}
    </Loading>
  )
}

export default SMSReportContainer
