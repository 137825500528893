import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import Typography from '@mui/material/Typography'

import IconText from '@/app/component/layout/icon-text'

export default function SelectAllNotification({ total, isOpen, onClose }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpen}
      onClose={onClose}
      autoHideDuration={3000}
    >
      <SnackbarContent
        message={
          <IconText>
            <Icon color="primary">check_circle</Icon>
            <Typography color="inherit">
              {total !== 1 ? `All ${total} items have been selected` : `All ${total} item has been selected`}
            </Typography>
          </IconText>
        }
        action={[
          <IconButton key="notification-close" color="inherit" onClick={onClose}>
            <Icon color="inherit">close</Icon>
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
}
