import { createSelector } from 'reselect'

import { LanguageType } from '@/app/module/campaigns/helpers'
import { RootState } from '@/app/store'

export const selectTranscribeLanguages = createSelector(
  (s: RootState) => s.utils.transcribeLanguageList.data,
  (transcribeLanguages: LanguageType[]) =>
    transcribeLanguages.map((language) => ({
      label: language.name,
      value: language.value,
    })),
)
