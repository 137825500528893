import CallButtonIcon from '@mui/icons-material/Call'
import CallEndButtonIcon from '@mui/icons-material/CallEnd'
import {
  DialogContent,
  DialogTitle,
  FormControl,
  Icon,
  IconButton,
  NativeSelect,
  Paper,
  PaperProps,
  Typography,
} from '@mui/material'
import React from 'react'
import Draggable from 'react-draggable'
import { makeStyles } from 'tss-react/mui'
import { v1 as uuid } from 'uuid'

import IconText from '@/app/component/layout/icon-text'
import { DEFAULT_SENDER_ID_VALUE } from '@/app/definitions'
import { Verto, VertoCall } from '@/app/module/utils/verto'
import config from '@/config'

const useStyle = makeStyles()({
  callButton: {
    color: '#fff',
  },
  acceptCallButton: {
    backgroundColor: '#53DB53',
    '&:hover': {
      backgroundColor: '#53DB53',
      opacity: 0.7,
    },
  },
  hangupCallButton: {
    backgroundColor: '#F93B3C',
    '&:hover': {
      backgroundColor: '#F93B3C',
      opacity: 0.7,
    },
  },
  dialogTitle: {
    cursor: 'move',
    height: '55px',
    padding: '10px 15px',
    backgroundColor: '#FAFAFA',
    '& h2': {
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  shortendLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '150px',
  },
})

type CallerIdType = {
  value: string
  label: string
}

enum CallStateType {
  Idle = 'IDLE',
  Initialized = 'INITIALIZED',
  Ringing = 'RINGING',
  Incall = 'INCALL',
  HangingUp = 'HANGING_UP',
  CallEnded = 'CALL_ENDED',
}

type Props = {
  callerIds: CallerIdType[]
  contactId: string
  contactName: string
  orgId: number
  token: string

  onClose: () => void
}

const callStateMap: { [key: string]: string } = {
  IDLE: 'Ready',
  INITIALIZED: 'Calling...',
  RINGING: 'Calling...',
  INCALL: 'In call',
  HANGING_UP: 'Hanging up...',
  CALL_ENDED: 'Call ended',
}

const socketUrl = config.webRTC.url
const login = config.webRTC.username
const passwd = config.webRTC.password

const systemDefaultCallerId = {
  label: 'System Default',
  value: '',
}

export default function CallDialog(props: Props) {
  const { token, orgId, callerIds = [], onClose, contactId, contactName } = props

  const [call, setCall] = React.useState<VertoCall | undefined>(undefined)
  const [callState, setCallState] = React.useState<CallStateType>(CallStateType.Idle)

  const { classes } = useStyle()

  const [callerId, setCallerId] = React.useState<CallerIdType | undefined>(systemDefaultCallerId)
  const [loading, setLoading] = React.useState(false)
  const [stream, setStream] = React.useState<MediaStream | undefined>(undefined)
  const [verto, setVerto] = React.useState<Verto | undefined>(undefined)
  const [vertoState, setVertoState] = React.useState<'connecting' | 'connected'>('connecting')

  const options = {
    callID: uuid(),
    userVariables: {
      esOrgID: `${orgId}`,
      esTokenAPI: '',
      esTokenJWT: token,
    },
  }

  React.useEffect(() => {
    let v: Verto | undefined

    const connect = async (vertoInstance: Verto): Promise<void> => {
      setVertoState('connecting')
      try {
        await vertoInstance.login()
        setVertoState('connected')
        return Promise.resolve()
      } catch {
        setVertoState('connecting')
        return connect(vertoInstance) // retry until login success
      }
    }

    const loadVerto = async () => {
      v = new Verto({
        rtcConfig: {},
        transportConfig: {
          login,
          passwd,
          socketUrl,
        },
        ice_timeout: 5000,
      })
      await connect(v)
      setVerto(v)
    }

    loadVerto()

    return () => {
      if (v) {
        v.logout()
      }
    }
  }, [])

  const closeTracks = React.useCallback((s?: MediaStream) => {
    if (!s) {
      return
    }
    s.getTracks().forEach((track) => {
      track.stop()
    })
    setStream(undefined)
  }, [])

  React.useEffect(() => {
    if (call) {
      call.subscribeEvent('initialized', () => {
        setCallState(CallStateType.Initialized)
      })

      call.subscribeEvent('answer', () => {
        setCallState(CallStateType.Ringing)
      })

      call.subscribeEvent('display', () => {
        setCallState(CallStateType.Incall)
      })

      call.subscribeEvent('hangup', () => {
        setCallState(CallStateType.HangingUp)
      })

      call.subscribeEvent('bye', () => {
        call.unsubscribeEvent('initialized')
        call.unsubscribeEvent('answer')
        call.unsubscribeEvent('display')
        call.unsubscribeEvent('hangup')
        call.unsubscribeEvent('bye')
        call.unsubscribeEvent('track')
        setCall(undefined)
        closeTracks(stream)

        setCallState(CallStateType.CallEnded)
      })

      call.subscribeEvent('track', (track) => {
        if (track.kind !== 'audio') return
        const localStream = new MediaStream()
        localStream.addTrack(track)
        const el = document.getElementById('audio') as HTMLVideoElement
        el.srcObject = localStream
      })
    }
  }, [call, stream, closeTracks, setCall, setCallState])

  const isUnmounting = React.useRef(false)
  React.useEffect(
    () => () => {
      isUnmounting.current = true
    },
    [],
  )

  React.useEffect(
    () => () => {
      // if there's a call when unmounting, clear the call event listener and set call to undefined
      if (call && isUnmounting.current) {
        call.unsubscribeEvent('initialized')
        call.unsubscribeEvent('answer')
        call.unsubscribeEvent('display')
        call.unsubscribeEvent('hangup')
        call.unsubscribeEvent('bye')
        call.unsubscribeEvent('track')
        setCall(undefined)
        setCallState(CallStateType.Idle)
      }
    },
    [call, setCall, setCallState],
  )

  React.useEffect(() => {
    if (callState === CallStateType.CallEnded) {
      setTimeout(() => {
        setCallState(CallStateType.Idle)
      }, 1000)
    }
  }, [callState, setCallState])

  const callContact = async () => {
    if (loading || !verto) {
      return
    }
    setLoading(true)
    try {
      const localStream = await navigator.mediaDevices.getUserMedia({ audio: true })
      setStream(localStream)
      const tempCall = verto.call(localStream.getTracks(), `escontact:${contactId}`, {
        ...options,
        caller_id_name: callerId?.label,
        caller_id_number: callerId?.value,
      })
      setCall(tempCall)
      setCallState(CallStateType.Ringing)
    } finally {
      setLoading(false)
    }
  }

  const hangup = () => {
    if (!call) {
      return
    }

    call.hangup()
    closeTracks(stream)
  }

  React.useEffect(() => () => closeTracks(stream), [stream, closeTracks])

  return (
    <FloatingCallBox>
      <DialogTitle className={classes.dialogTitle} id="call-dialog-title">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <IconText>
              <Icon>call</Icon>
              <Typography className={classes.shortendLabel} variant="h6">
                Call {contactName}
              </Typography>
            </IconText>
            <IconText>
              <Typography variant="caption">
                <i>{vertoState === 'connected' ? 'Connected' : 'Connecting'}</i>
              </Typography>
              <Icon color={vertoState === 'connected' ? 'success' : 'warning'}>circle</Icon>
            </IconText>
          </div>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <audio id="audio" autoPlay={true} style={{ display: 'none' }}></audio>
        <div
          style={{
            padding: '5px 0px 10px',
            textAlign: 'center',
          }}
        >
          <Typography id="call-dialog-call-state" variant="h6">
            {callStateMap[callState]}
          </Typography>
        </div>
        <div
          style={{
            padding: '15px 0px',
          }}
        >
          <FormControl variant="outlined" fullWidth size="small">
            <NativeSelect
              fullWidth
              id="select-caller-id"
              value={callerId?.value ?? DEFAULT_SENDER_ID_VALUE}
              onChange={(e) => {
                if (e.target.value === DEFAULT_SENDER_ID_VALUE) {
                  setCallerId({
                    label: 'System Default',
                    value: '',
                  })
                } else {
                  setCallerId(callerIds.find((cid) => cid.value === e.target.value))
                }
              }}
            >
              <option key="system-default" value="-1">
                System Default
              </option>
              {callerIds.map(({ value: callerIdPhonenumber, label }: CallerIdType) => (
                <option key={`${label}-${callerIdPhonenumber}`} value={callerIdPhonenumber}>
                  {label}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </div>
        <div
          style={{
            padding: '15px 0px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              id="call-dialog-call-btn"
              className={`${classes.callButton} ${classes.acceptCallButton}`}
              disabled={!canCall(callState, loading, call)}
              onClick={callContact}
            >
              <CallButtonIcon />
            </IconButton>
            <IconButton
              className={`${classes.callButton} ${classes.hangupCallButton}`}
              disabled={!canEnd(callState, call)}
              onClick={hangup}
            >
              <CallEndButtonIcon />
            </IconButton>
          </div>
        </div>
      </DialogContent>
    </FloatingCallBox>
  )
}

const canCall = (callState: CallStateType, loading: boolean, call?: VertoCall) =>
  !call && !loading && callState === CallStateType.Idle
const canEnd = (callState: CallStateType, call?: VertoCall) =>
  !!call && [CallStateType.Ringing, CallStateType.Initialized, CallStateType.Incall].includes(callState)

const FloatingCallBox = (props: PaperProps) => (
  <Draggable handle="#call-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <div
      id="call-dialog-cont"
      style={{
        position: 'absolute',
        bottom: '60px',
        right: '60px',
        zIndex: 99999,
      }}
    >
      <Paper {...props} />
    </div>
  </Draggable>
)
