import { EventTracker, Palette } from '@devexpress/dx-react-chart'
import { Chart, PieSeries, Tooltip } from '@devexpress/dx-react-chart-material-ui'
import { green, red } from '@mui/material/colors'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { pathOr } from 'ramda'

import Legend from './legend'

export default function OverviewPieChart(props) {
  const { title = 'Message Delivery Overview', data, size = '350' } = props

  const ChartTooltip = ({ text, targetItem, ...restProps }) => {
    const legendLabel = `${pathOr(text, [targetItem.point, 'legendLabel'], data)} (${pathOr(
      text,
      [targetItem.point, 'value'],
      data,
    )})`
    return <Tooltip.Content {...restProps} text={legendLabel} targetItem={targetItem} />
  }

  const scheme = [green.A700, '#2f80ac', red.A200]

  return (
    <Paper>
      <Typography
        variant="h5"
        color="textSecondary"
        style={{
          textAlign: 'center',
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        {title}
      </Typography>
      <div>
        <Chart
          width={size}
          height={size}
          style={{
            margin: 'auto',
          }}
          data={data}
          rotated
        >
          <Palette scheme={scheme} />
          <PieSeries valueField="value" argumentField="legendLabel" />
          <EventTracker />
          <Tooltip contentComponent={ChartTooltip} />
          <Legend />
        </Chart>
      </div>
    </Paper>
  )
}
