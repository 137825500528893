import { applySpec, compose, identity, ifElse, map, path, pathEq, pathOr } from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { MIC_ACCESS_ERROR } from '@/app/definitions'
import { selectToken } from '@/app/module/auth/store/selectors'
import { selectWhatsappSenderIds } from '@/app/module/campaigns/store/selectors'
import { getCustomFields } from '@/app/module/custom-fields/store/actions'
import { getFilesItem } from '@/app/module/files/store/actions'
import { createNotification } from '@/app/module/notifications/store/actions'
import { selectOrgId, selectUserId, selectUserTimezone } from '@/app/module/user/store/selectors'
import config from '@/config'

import Logs from './component'
import { MESSAGE_INBOUND } from './definitions'
import {
  clearFilters,
  downloadCalls,
  downloadMessages,
  downloadTopups,
  downloadWhatsapp,
  getCalls,
  getMessages,
  getSenderIdList,
  getTopups,
  getWhatsapp,
  navigateLogs,
  reloadCalls,
  reloadMessages,
  reloadTopups,
  reloadWhatsapp,
  saveWhatsappFile,
  sendMessage,
  sendWhatsappMessage,
  setFilters,
  setMessagesPageSize,
  setSendMessage,
  setSendWhatsappMessage,
} from './store/actions'
import { selectPersonalizationList, selectSenderIdValues } from './store/selectors'

const pageSizeDefault = path(['modules', 'messages', 'pageSize', 'default'], config)
const pageSizeValues = compose(
  map((val) => ({ name: val, value: val })),
  path(['modules', 'messages', 'pageSize', 'values']),
)(config)

const selector = ({ auth, user, logs, customFields, utils }) => ({
  error: logs.error,
  messages: pathOr('', ['data', 'messages'], logs),
  calls: pathOr('', ['data', 'calls'], logs),
  whatsapp: pathOr('', ['data', 'whatsapp'], logs),
  topups: pathOr('', ['data', 'topups'], logs),
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  personalizationList: selectPersonalizationList({ customFields }),
  userId: selectUserId({ user }),
  totalCount: pathOr(0, ['data', 'totalCount'], logs),
  pageSize: pathOr(pageSizeDefault, ['data', 'pageSize'], logs),
  endCursor: pathOr('', ['data', 'pageInfo', 'endCursor'], logs),
  hasNextPage: pathOr('', ['data', 'pageInfo', 'hasNextPage'], logs),
  recipients: compose(
    (obj) => [obj],
    applySpec({ label: identity, value: identity }),
    ifElse(
      pathEq(MESSAGE_INBOUND, ['data', 'direction']),
      pathOr('', ['data', 'sendMessage', 'sender']),
      pathOr('', ['data', 'sendMessage', 'recipient']),
    ),
  )(logs),
  contactIds: [pathOr('', ['data', 'sendMessage', 'contactId'], logs)],
  senderId: pathOr('', ['data', 'sendMessage', 'senderId'], logs),
  senderIds: selectSenderIdValues({ logs }),
  whatsappSenderIds: selectWhatsappSenderIds({ logs }),
  totalRecipients: 1,
  pageSizeValues,
  timezone: selectUserTimezone({ user, utils }),
})

const dispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId, query }) =>
        () =>
          Promise.all([
            dispatch(getMessages({ token, orgId, query })),
            dispatch(getCustomFields({ token, orgId })),
            dispatch(setFilters(query)),
          ]),
      setPageSize: setMessagesPageSize,
      getMessages,
      getCalls,
      getWhatsapp,
      getTopups,
      reloadMessages,
      reloadCalls,
      reloadWhatsapp,
      reloadTopups,
      setSendMessage,
      getSenderIdList,
      sendMessage,
      downloadMessages,
      downloadCalls,
      downloadWhatsapp,
      downloadTopups,
      navigateLogs,
      getFilesItem,
      getCustomFields,
      sendWhatsappMessage,
      setSendWhatsappMessage,
      clearFilters,
      saveWhatsappFile,
      onMicAccessError: () =>
        dispatch(
          createNotification({
            'mic-access': {
              type: 'error',
              message: MIC_ACCESS_ERROR,
            },
          }),
        ),
    },
    dispatch,
  )

export default connect(selector, dispatcher)(withRouter(Logs))
