import moment from 'moment'
import { z, ZodSchema } from 'zod'

import { Condition } from '@/app/types'

import { BackendCampaignTemplate } from '..'

export const createSelectOption = <Option extends z.ZodTypeAny>(option: Option) =>
  z.object({
    label: z.string(),
    value: option,
  })

export const createTranslations = <Object extends z.ZodTypeAny>(object: Object) => z.record(z.string(), object)

export const ZCountryTimezone = createSelectOption(z.string())

const TIME_UNITS = ['second', 'minute', 'hour', 'day', 'week', 'month', 'at', 'immediate', ''] as const

export const ZTimeUnit = z.enum(TIME_UNITS)

export const ZDaysOfWeek = z.record(z.string(), z.boolean())

export const ZDateString = z.string().refine((value) => value && moment(value).isValid(), {
  message: 'Invalid date',
})

export const ZDelta = z.object({
  at: ZDateString.optional(),
  country: z.string().optional(),
  dayOfWeek: z.string().optional(),
  daysOfWeek: ZDaysOfWeek.optional(),
  relative: z.string().optional(),
  time: z.string().optional(),
  timezone: z.string().optional(),
  timezones: z.array(ZCountryTimezone).optional(),
  unit: ZTimeUnit,
  value: z.number(),
  valueError: z.string().optional(),
})

export const ZOffset = ZDelta.pick({
  at: true,
  daysOfWeek: true,
  time: true,
  timezone: true,
  unit: true,
  value: true,
})

export const ZCallSchedule = ZDelta.partial()

export const ZCallWindowItem = z.object({
  start: z.string(),
  end: z.string(),
})

export const ZCallWindowDay = z.array(ZCallWindowItem)

export const ZCallWindowSlots = z.object({
  monday: ZCallWindowDay.nullable().optional(),
  tuesday: ZCallWindowDay.nullable().optional(),
  wednesday: ZCallWindowDay.nullable().optional(),
  thursday: ZCallWindowDay.nullable().optional(),
  friday: ZCallWindowDay.nullable().optional(),
  saturday: ZCallWindowDay.nullable().optional(),
  sunday: ZCallWindowDay.nullable().optional(),
})

export const ZCallWindow = ZCallWindowSlots.extend({
  timezone: z.string().optional(),
})

export const ZFileType = z.object({
  category: z.string(),
  filename: z.string(),
  title: z.string(),
  thumb: z.string().optional(),
  url: z.string(),
})

export const ZFileUpload = z.object({
  file: z.instanceof(File),
  filename: z.string(),
  filter: z.string().optional(),
  type: z.string(),
})

export const ZFilesType = z.record(ZFileType)

export const ZLaunch = z.object({
  immediate: z.boolean().optional(),
  at: ZDateString.optional(),
  timezone: z.string().optional(),
})

export const ZCampContacts = z.object({
  allContactsAreSelected: z.boolean(),
  excludedContactIds: z.array(z.number()),
  includedContactIds: z.array(z.number()),
  includedGroupIds: z.array(z.number()),
  search: z.string(),
})

export const ZUIStore = z.record(z.string(), z.any())

export const ZUserStatuses = z.array(z.string())

export const ZBackendCampaignTemplate = z.nativeEnum(BackendCampaignTemplate)

export const ZConditionFunc = z.union([
  z.literal('and'),
  z.literal('or'),
  z.literal('=='),
  z.literal('!='),
  z.literal('>'),
  z.literal('<'),
  z.literal('>='),
  z.literal('<='),
  z.literal('in'),
  z.literal('notIn'),
  z.literal('contain'),
  z.literal('not_contain'),
  z.literal('empty'),
  z.literal('not_empty'),
  z.literal('startsWith'),
  z.literal('notStartsWith'),
  z.literal('anyStartsWith'),
  z.literal('noneStartsWith'),
  z.literal('before'),
  z.literal('after'),
  z.literal('mod2_equal'),
  z.literal('mod3_equal'),
  z.literal('mod4_equal'),
  z.literal('mod5_equal'),
  z.literal(''),
])

export const ZCondition: ZodSchema<Condition> = z.lazy(() =>
  z.tuple([ZConditionFunc]).rest(z.union([z.string(), z.number(), ZCondition])),
)

export const HTTP_METHODS = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'] as const

export const ZHTTPMethod = z.enum(HTTP_METHODS)

export const ZHeader = z.object({
  key: z.string(),
  value: z.string(),
})
