import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'

import IconText from '@/app/component/layout/icon-text'
import { FunctionType } from '@/config/types'

type Props = {
  text: ReactNode
  isOpen: boolean
  onClose: FunctionType
  onConfirm: FunctionType
  hideDeleteButton?: boolean
  deleteButtonText?: string
  cancelButtonText?: string
  icon?: string
  title: ReactNode
  disabled?: boolean
  hideCancel?: boolean
  id?: string
  loading?: boolean
}

export default function ConfirmationDialog({
  text,
  isOpen,
  onClose,
  onConfirm,
  hideDeleteButton = false,
  deleteButtonText = 'Delete',
  cancelButtonText = 'Cancel',
  icon = 'warning',
  title = 'Warning',
  disabled = false,
  hideCancel = false,
  id = 'delete-dialog',
  loading = false,
}: Props) {
  return (
    <Dialog id={id} open={!!isOpen}>
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>{icon}</Icon>
            <Typography variant="h6">{title}</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component={typeof text !== 'string' ? 'div' : 'p'}>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {!hideCancel && (
          <Button variant="outlined" id="confirmation-modal-cancel-btn" onClick={onClose}>
            {cancelButtonText}
          </Button>
        )}
        {!hideDeleteButton && (
          <Button
            variant="contained"
            color="primary"
            className="confirmation-modal-confirm-btn"
            onClick={onConfirm}
            disabled={disabled}
            loading={loading}
          >
            {deleteButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
