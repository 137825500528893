import { Button, InputAdornment, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment, { Moment } from 'moment'
import { pathOr } from 'ramda'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { FunctionType } from '@/config/types'

type Props = {
  groupedFields: GroupedFieldType[]
  onSubmit: SubmitHandler<any>
  submitLabel: string
  loading: boolean
}

type DisplayMap = {
  [key: string]: FunctionType
}

export type FieldType = {
  label: string
  key: string
  required: boolean
  defaultValue: string | number
  type: string
  rules?: any
  placeholder?: string
}

export type GroupedFieldType = {
  groupLabel: string
  groupFields: FieldType[]
}

const displayMap: DisplayMap = {
  text: ({ placeholder, ...rest }: any) => (
    <TextField
      helperText
      variant="outlined"
      type="text"
      placeholder={placeholder}
      InputLabelProps={{
        ...(placeholder && {
          shrink: true,
        }),
      }}
      {...rest}
    />
  ),
  number: ({ ...rest }) => <TextField variant="outlined" type="number" {...rest} />,
  currency: ({ ...rest }) => (
    <TextField
      variant="outlined"
      type="number"
      {...rest}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
    />
  ),
  date: ({ value, onChange, ...props }: any) => (
    <DatePicker
      {...props}
      textField={{ variant: 'outlined' }}
      format="YYYY/MM/DD"
      value={value ? moment(value) : null}
      onChange={(v: Moment | null) => onChange(v ? v.toISOString() : v)}
    />
  ),
}

export default function HookForm({ groupedFields, onSubmit, submitLabel = 'Submit', loading = false }: Props) {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm({
    mode: 'onChange',
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {groupedFields.map(({ groupLabel, groupFields: fields }, i) => (
        <div key={i}>
          <div
            style={{
              marginBottom: '15px',
            }}
          >
            <Typography variant="h6" color="textSecondary">
              {groupLabel}
            </Typography>
          </div>
          {fields.map(({ key, type, label, defaultValue, required, rules, placeholder }) => {
            const Content = pathOr(() => '', [type], displayMap) as any
            return (
              <div key={key}>
                <Controller
                  name={key}
                  control={control}
                  defaultValue={defaultValue}
                  rules={{
                    required,
                    ...rules,
                  }}
                  render={({ field }) => (
                    <div
                      style={{
                        marginBottom: '20px',
                      }}
                    >
                      <Content
                        {...field}
                        fullWidth
                        label={label}
                        defaultValue={defaultValue}
                        required={required}
                        error={errors[key]}
                        placeholder={placeholder}
                      />
                    </div>
                  )}
                />
              </div>
            )
          })}
        </div>
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button variant="contained" color="primary" type="submit" disabled={!isValid || loading}>
          {submitLabel}
        </Button>
      </div>
    </form>
  )
}
