import React from 'react'

import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'

type Props = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const UseCategoriesDialog: React.FC<Props> = ({ open, onClose, onConfirm }) => (
  <ConfirmationDialog
    id="use-categories-dialog"
    deleteButtonText="Enable AI"
    isOpen={open}
    title="AI enhanced answer categorization"
    icon="assistant"
    text={
      <>
        <br />
        <span>
          Use Artificial Intelligence to determine the category an answer falls under. You will see the category in the
          downloadable report.
        </span>
        <br />
        <span>
          For example, for the question “What is your favorite time of day?”, the categories might be: morning,
          afternoon, and evening. The answer “when I eat breakfast” might be categorized as “morning”.
        </span>
        <br />
        <br />
        <span>
          <b>NOTE:</b> This means we will send data to a large language model like ChatGPT. The ONLY thing we send is
          the question as you typed it above, the possible categories, and the reply as the contact sends it—nothing
          else.
        </span>
        <br />
        <br />
        <span>
          In particular, we will NOT send the phone number, first or last name or any other contact data—unless it's
          included in the question or the reply.
        </span>
        <br />
        <br />
        <b>
          By enabling this feature you confirm that you understand this and consent to the data sharing as described
          above.
        </b>
      </>
    }
    onClose={onClose}
    onConfirm={onConfirm}
  />
)

export default UseCategoriesDialog
