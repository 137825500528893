import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { isEmpty, pathOr } from 'ramda'
import { Component } from 'react'

import Message from '@/app/module/campaigns/component/item/steps/content/sms'

export default class CampaignBlastContent extends Component {
  state = {
    message: pathOr({}, ['variables', 'message'], this.props.item),
  }

  unmounting = false

  static validate({ item }) {
    return !isEmpty(pathOr({}, ['variables', 'message'], item))
  }

  componentWillUnmount() {
    this.unmounting = true
  }

  changeHandler = (value = {}) => {
    this.update(({ message }) => ({
      ...message,
      message: value.message,
    }))
  }

  update(fn) {
    const { item, onChange } = this.props
    const { message = {} } = fn(this.state)

    if (this.unmounting) {
      onChange({
        ...item,
        variables: {
          ...item.variables,
          message,
        },
      })
    } else {
      this.setState(
        {
          message,
        },
        () => {
          onChange({
            ...item,
            variables: {
              ...item.variables,
              message: this.state.message,
            },
          })
        },
      )
    }
  }

  render() {
    const {
      messages: { personalizationList = {} },
    } = this.props

    return (
      <div>
        <div key={0}>
          <Card style={{ margin: '0' }}>
            <CardContent>
              <Toolbar
                style={{
                  margin: '-24px -24px 12px',
                  backgroundColor: '#f5f5f5',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>Specify what SMS you want to send</Typography>
              </Toolbar>
              <Message
                key={0}
                index={0}
                item={{ message: this.state.message }}
                personalizationList={personalizationList}
                changeHandler={this.changeHandler}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    )
  }
}
