import { Button, Card, CardContent, Icon, IconButton, Tooltip, Typography } from '@mui/material'
import { omit } from 'ramda'
import React from 'react'

import Loading from '@/app/component/guard/loading'
import { useCreateHook } from '@/app/module/phonenumbers/hooks/use-create-hook'
import { useGetPhoneNumber } from '@/app/module/phonenumbers/hooks/use-get-phonenumber'
import { useUpdateHook } from '@/app/module/phonenumbers/hooks/use-update-hook'
import { ChannelType, InboundHook, PhoneNumber } from '@/app/module/phonenumbers/types'

import AddRuleDialog from './add-rule-dialog'
import DeleteHookDialog from './delete-hook-dialog'
import Hook, { HookProps } from './hook'
import { FooterContainer, Root, TabDivider, TabTitle, TabToolbar } from './phone-number-tab-components'

type Props = Omit<HookProps, 'expanded' | 'hook' | 'index' | 'phoneNumber' | 'type' | 'saveHook' | 'setDeleteId'> & {
  phoneNumberId: number
}

const WhatsappHooksTab: React.FC<Props> = ({ phoneNumberId, ...props }) => {
  const [createHook, { isLoading: isCreating }] = useCreateHook(ChannelType.WhatsApp)
  const [updateHook, { isLoading: isUpdating }] = useUpdateHook(ChannelType.WhatsApp)
  const { data, refetch } = useGetPhoneNumber(phoneNumberId)
  const phoneNumber = data as PhoneNumber | undefined
  const hooks = React.useMemo(() => {
    if (!phoneNumber || !phoneNumber.whatsappHooks) {
      return []
    }
    return [...phoneNumber.whatsappHooks].sort((a, b) => a.id - b.id)
  }, [phoneNumber]) as InboundHook[]

  const [expanded, setExpanded] = React.useState<number>(hooks.length ? hooks[0].id : 0)
  const [addRuleOpen, setAddRuleOpen] = React.useState(false)
  const [deleteId, setDeleteId] = React.useState<number | undefined>()

  React.useEffect(
    () =>
      setExpanded((s) => {
        // if there are no hooks, return 0
        if (!hooks.length) {
          return 0
        }
        // if the current expanded hook is not in the list of hooks, return the first hook
        if (!s || !hooks.find((h) => h.id === s)) {
          return hooks[0].id
        }
        // otherwise return the current expanded hook
        return s
      }),
    [hooks],
  )

  const openAddRuleDialog = () => setAddRuleOpen(true)
  const closeAddRuleDialog = () => setAddRuleOpen(false)
  const closeDeleteDialog = () => setDeleteId(undefined)

  return (
    <Loading isLoading={isCreating || isUpdating}>
      <CardContent>
        <TabToolbar>
          <TabTitle variant="h6" color="textSecondary">
            Incoming WhatsApp Rules
          </TabTitle>
          <div>
            <Tooltip id="reload-hooks" title="Reload Rules">
              <IconButton onClick={refetch}>
                <Icon>refresh</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </TabToolbar>
        {!!phoneNumber && (
          <Root>
            {!hooks.length && (
              <Card>
                <CardContent>
                  <Typography color="textSecondary" variant="h6">
                    Add rules here to let us know what to do with incoming WhatsApp messages. For example,
                    <ul
                      style={{
                        marginTop: '5px',
                      }}
                    >
                      <li>
                        configure keywords for your contacts to text in WhatsApp to opt into one of your WhatsApp
                        campaigns
                      </li>
                      <li>configure keywords for your contacts to text in WhatsApp to opt out of your campaigns</li>
                      <li>configure a webhook to be notified about every incoming WhatsApp message</li>
                    </ul>
                  </Typography>
                </CardContent>
              </Card>
            )}
            {hooks.map((hook, i) => {
              const hookId = hook.id
              return (
                <Hook
                  {...props}
                  expanded={expanded}
                  hook={hook}
                  files={{
                    data: phoneNumber.files,
                    loadingItems: [],
                  }}
                  index={i}
                  key={hookId}
                  phoneNumber={phoneNumber}
                  type={ChannelType.WhatsApp}
                  onChange={setExpanded}
                  saveHook={(updatedHook) => updateHook(phoneNumberId, hookId, updatedHook)}
                  setDeleteId={setDeleteId}
                />
              )
            })}
          </Root>
        )}
        <TabDivider />
        {addRuleOpen && !!phoneNumber && (
          <AddRuleDialog
            {...props}
            phoneNumber={phoneNumber}
            expanded={expanded}
            index={-1}
            type={ChannelType.WhatsApp}
            onClose={closeAddRuleDialog}
            saveHook={async (newHook) => {
              const created = await createHook(phoneNumberId, omit(['id'], newHook))
              if (created) {
                closeAddRuleDialog()
                setExpanded(created)
              }
            }}
            setDeleteId={setDeleteId}
          />
        )}

        <FooterContainer>
          <Button variant="contained" color="primary" onClick={openAddRuleDialog}>
            Add a new Rule
          </Button>
        </FooterContainer>
      </CardContent>

      {!!deleteId && (
        <DeleteHookDialog
          deleteId={deleteId}
          didId={phoneNumberId}
          type={ChannelType.WhatsApp}
          onClose={closeDeleteDialog}
        />
      )}
    </Loading>
  )
}

export default WhatsappHooksTab
