import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'

import Modal from '@/app/component/layout/modal'
import { NO_LIBRARY_MESSAGES_TOOLTIP, RUNNING_CAMPAIGNS_DISABLED_TOOLTIP } from '@/app/module/campaigns/definitions'
import {
  CampaignTemplateType,
  MessageSnippetType,
  ReminderMessage,
  Message as TMessage,
} from '@/app/module/campaigns/types'

import { MessageLibrary } from './message-library'

type LibraryTooltipConditions = {
  published: boolean
  index: number
  total: number
  snippetsCount: number
}

function getLibraryButtonTooltip({ published, total, index, snippetsCount }: LibraryTooltipConditions) {
  if (published && index < total - 1) {
    return RUNNING_CAMPAIGNS_DISABLED_TOOLTIP
  }
  return snippetsCount > 0 ? undefined : NO_LIBRARY_MESSAGES_TOOLTIP
}

type AddFromLibraryButtonProps = {
  campaignType: CampaignTemplateType
  messageSnippets: MessageSnippetType[]
  messageIds: string[]
  timezone: string
  index?: number
  published?: boolean
  total?: number
  style?: React.CSSProperties
  onMessageCreate: (snippet: TMessage | ReminderMessage) => void
}

export function AddFromLibraryButton({
  campaignType,
  messageSnippets,
  messageIds,
  index = 0,
  total = 0,
  published = false,
  style,
  timezone,
  onMessageCreate,
}: AddFromLibraryButtonProps) {
  const [isSnippetModalOpen, setSnippetModalOpen] = React.useState(false)

  return (
    <>
      <Tooltip title={getLibraryButtonTooltip({ published, total, index, snippetsCount: messageSnippets.length })}>
        <span>
          <Button
            id={`add-from-library-button-${index}`}
            disabled={(published && index < total - 1) || messageSnippets.length === 0}
            variant="contained"
            color="primary"
            style={style}
            startIcon={<Icon>library_add</Icon>}
            onClick={() => setSnippetModalOpen(true)}
          >
            Add from library
          </Button>
        </span>
      </Tooltip>
      <Modal
        title="Add from library"
        icon="library_add"
        isOpen={isSnippetModalOpen}
        onClose={() => setSnippetModalOpen(false)}
      >
        <MessageLibrary
          campaignType={campaignType}
          snippets={messageSnippets}
          messageIds={messageIds}
          insertionIndex={index + 1}
          timezone={timezone}
          onSelect={(snippet) => {
            onMessageCreate(snippet)
            setSnippetModalOpen(false)
          }}
        />
      </Modal>
    </>
  )
}
