import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { SchemaInboundHook } from '@/__generated/es-api'
import { cannot } from '@/app/helpers'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_UPDATE_PHONENUMBER_HOOK_SERVER } from '@/app/module/phonenumbers/definitions'
import { invalidatePhoneNumbersCache } from '@/app/module/phonenumbers/store/utils'
import { ChannelType, InboundHook } from '@/app/module/phonenumbers/types'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { esApi } from '@/app/store/api'
import { useAppDispatch } from '@/app/store/redux-hooks'

/**
 * Update an inbound hook of a phone number
 */
export const useUpdateHook = (type: ChannelType.Sms | ChannelType.Voice | ChannelType.WhatsApp) => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const [updateSMSHook, updateSMSHookRes] = esApi.endpoints.updateOrgDidHook.useMutation()
  const [updateVoiceHook, updateVoiceHookRes] = esApi.endpoints.updateOrgDidCallHook.useMutation()
  const [updateWaHook, updateWaHookRes] = esApi.endpoints.updateOrgDidWaHook.useMutation()

  const method = useMemo(() => {
    switch (type) {
      case ChannelType.Sms:
        return updateSMSHook
      case ChannelType.Voice:
        return updateVoiceHook
      case ChannelType.WhatsApp:
        return updateWaHook
      default:
        return cannot(type)
    }
  }, [type, updateVoiceHook, updateSMSHook, updateWaHook])

  const res = useMemo(() => {
    switch (type) {
      case ChannelType.Sms:
        return updateSMSHookRes
      case ChannelType.Voice:
        return updateVoiceHookRes
      case ChannelType.WhatsApp:
        return updateWaHookRes
      default:
        return cannot(type)
    }
  }, [type, updateVoiceHookRes, updateSMSHookRes, updateWaHookRes])

  const updateHook = useCallback(
    async (didId: number, hookId: number, payload: InboundHook) => {
      try {
        await method({
          orgId: orgId.toString(),
          didId: didId.toString(),
          hookId: hookId.toString(),
          schemaInboundHook: payload as SchemaInboundHook,
        }).unwrap()
        dispatch(
          createNotification({
            'update-hook': {
              type: 'success',
              message: 'Rule updated successfully.',
            },
          }),
        )
        invalidatePhoneNumbersCache(dispatch)
      } catch {
        dispatch(
          createNotification({
            'update-hook': {
              type: 'error',
              message: ERROR_UPDATE_PHONENUMBER_HOOK_SERVER,
            },
          }),
        )
      }
    },
    [orgId, dispatch, method],
  )

  return [updateHook, res] as const
}
