import { Icon, ListItemText } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { find, propEq } from 'ramda'
import React from 'react'

import IconText from '@/app/component/layout/icon-text'
import { reduceIndexed } from '@/app/module/campaigns/helpers'

import FormControl from './form-control'

type Value = {
  icon?: string | (() => React.ReactNode)
  value: string
  label: string
  description?: string
  disabled?: boolean
}

type SelectFieldProps<T extends string | string[]> = {
  'data-testid'?: string
  className?: string
  id?: string
  name: string
  label?: string
  value?: T
  values: Value[]
  editable?: boolean
  error?: string
  info?: string
  onChange: (data: { name: string; value: T }) => void
  onOpen?: () => void
  onClose?: () => void
  InputProps?: Partial<React.ComponentProps<typeof Input>>
  fullWidth?: boolean
  style?: React.CSSProperties
  selectStyle?: React.CSSProperties
  selectItemStyle?: React.CSSProperties
  variant?: React.ComponentProps<typeof FormControl>['variant']
  disabledText?: string
  multiple?: T extends string[] ? true : false | undefined
  firstChoice?: (data: { selectItemStyle?: React.CSSProperties }) => React.ReactNode
}

function SelectField<T extends string | string[]>({
  className,
  'data-testid': dataTestId,
  id,
  name,
  label,
  value,
  values = [],
  editable = true,
  error,
  info,
  onChange = () => {},
  onOpen = () => {},
  onClose = () => {},
  InputProps = {},
  fullWidth = true,
  style,
  selectStyle,
  selectItemStyle,
  variant = 'standard',
  disabledText,
  multiple = false,
  firstChoice,
}: SelectFieldProps<T>) {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <FormControl error={error} fullWidth={fullWidth} style={style} variant={variant}>
      {label !== undefined && (
        <InputLabel id={`${id}-label`} htmlFor={`select-${name}`}>
          {label}
        </InputLabel>
      )}
      <Select<typeof value>
        className={className}
        variant="standard"
        data-testid={dataTestId}
        id={id}
        style={selectStyle}
        error={!!error}
        value={value}
        open={isOpen}
        multiple={multiple}
        aria-labelledby={id ? `${id}-label` : undefined}
        SelectDisplayProps={{
          'aria-label': name,
        }}
        onOpen={() => {
          setIsOpen(true)
          onOpen()
        }}
        onClose={() => {
          setIsOpen(false)
          onClose()
        }}
        onChange={(e) => {
          onChange({
            name,
            value: e.target.value as T,
          })
        }}
        MenuProps={{
          style: {
            maxHeight: 'calc(100% - 131px)',
          },
        }}
        sx={{
          '& .MuiListItemText-multiline .MuiListItemText-primary ': {
            color: (theme) => theme.palette.action.active,
            fontWeight: 400,
            marginBottom: 0,
            marginTop: 0,
          },
          '& .MuiListItemText-multiline .MuiListItemText-secondary ': {
            display: 'none',
          },
        }}
        input={<Input name={name} id={`select-${name}`} readOnly={!editable} {...InputProps} style={selectItemStyle} />}
        {...(multiple && {
          renderValue: (selected) => {
            const renderedChoice = reduceIndexed<React.ReactNode, string>(
              (accum, elem, index) => {
                const found = find(propEq('value', elem), values)
                if (found) {
                  return `${accum}${index > 0 ? ', ' : ''}${found.label}`
                }
                return accum
              },
              '',
              selected as string[],
            )
            return renderedChoice
          },
        })}
      >
        {firstChoice && firstChoice({ selectItemStyle })}
        {values
          .filter((item) => !!item.label)
          .map(({ icon, value: itemValue, label: itemName, description, disabled: itemDisabled }) => (
            <MenuItem
              disabled={typeof itemDisabled !== 'undefined' ? itemDisabled : false}
              divider={!!description}
              key={`select-value-${name}-${itemValue}`}
              style={selectItemStyle}
              value={itemValue}
            >
              {multiple && <Checkbox checked={!!value && value.indexOf(itemValue) > -1} />}
              {!description && <span className="select-item-text">{itemName}</span>}
              {!!description && (
                <ListItemText
                  primaryTypographyProps={{
                    sx: {
                      fontWeight: description ? 600 : 400,
                    },
                  }}
                  primary={
                    <IconText>
                      {icon && typeof icon === 'string' && (
                        <Icon
                          color="action"
                          style={{
                            fontSize: 16,
                          }}
                        >
                          {icon}
                        </Icon>
                      )}
                      {icon && typeof icon !== 'string' && icon()}
                      <span className="select-item-text">{itemName}</span>
                    </IconText>
                  }
                  secondary={description}
                />
              )}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{disabledText || info}</FormHelperText>
    </FormControl>
  )
}

export default SelectField
