import { pathOr } from 'ramda'

import {
  ACTION_ADD_CUSTOM_FIELDS_FAILURE,
  ACTION_ADD_CUSTOM_FIELDS_REQUEST,
  ACTION_ADD_CUSTOM_FIELDS_SUCCESS,
  ACTION_DELETE_CUSTOM_FIELDS_FAILURE,
  ACTION_DELETE_CUSTOM_FIELDS_REQUEST,
  ACTION_DELETE_CUSTOM_FIELDS_SUCCESS,
  ACTION_GET_CUSTOM_FIELDS_FAILURE,
  ACTION_GET_CUSTOM_FIELDS_REQUEST,
  ACTION_GET_CUSTOM_FIELDS_SUCCESS,
  ACTION_MOD_CUSTOM_FIELDS_FAILURE,
  ACTION_MOD_CUSTOM_FIELDS_REQUEST,
  ACTION_MOD_CUSTOM_FIELDS_SUCCESS,
  ERROR_DELETE_CUSTOM_FIELDS_SERVER,
  ERROR_GET_CUSTOM_FIELDS_SERVER,
  ERROR_SAVE_CUSTOM_FIELDS_SERVER,
} from '@/app/module/custom-fields/definitions'
import { createNotification } from '@/app/module/notifications/store/actions'
import request from '@/app/module/request'
import { createAsyncActions, preventParallel } from '@/app/service/util'

const {
  request: getCustomFieldsRequest,
  success: getCustomFieldsSuccess,
  failure: getCustomFieldsFailure,
} = createAsyncActions({
  request: ACTION_GET_CUSTOM_FIELDS_REQUEST,
  success: ACTION_GET_CUSTOM_FIELDS_SUCCESS,
  failure: ACTION_GET_CUSTOM_FIELDS_FAILURE,
})

const {
  request: addCustomFieldsRequest,
  success: addCustomFieldsSuccess,
  failure: addCustomFieldsFailure,
} = createAsyncActions({
  request: ACTION_ADD_CUSTOM_FIELDS_REQUEST,
  success: ACTION_ADD_CUSTOM_FIELDS_SUCCESS,
  failure: ACTION_ADD_CUSTOM_FIELDS_FAILURE,
})

const {
  request: modCustomFieldsRequest,
  success: modCustomFieldsSuccess,
  failure: modCustomFieldsFailure,
} = createAsyncActions({
  request: ACTION_MOD_CUSTOM_FIELDS_REQUEST,
  success: ACTION_MOD_CUSTOM_FIELDS_SUCCESS,
  failure: ACTION_MOD_CUSTOM_FIELDS_FAILURE,
})

const {
  request: deleteCustomFieldsRequest,
  success: deleteCustomFieldsSuccess,
  failure: deleteCustomFieldsFailure,
} = createAsyncActions({
  request: ACTION_DELETE_CUSTOM_FIELDS_REQUEST,
  success: ACTION_DELETE_CUSTOM_FIELDS_SUCCESS,
  failure: ACTION_DELETE_CUSTOM_FIELDS_FAILURE,
})

export {
  addCustomFieldsFailure,
  addCustomFieldsRequest,
  addCustomFieldsSuccess,
  deleteCustomFieldsFailure,
  deleteCustomFieldsRequest,
  deleteCustomFieldsSuccess,
  getCustomFieldsFailure,
  getCustomFieldsRequest,
  getCustomFieldsSuccess,
  modCustomFieldsFailure,
  modCustomFieldsRequest,
  modCustomFieldsSuccess,
}

export const getCustomFields = preventParallel(
  ({ orgId }) => orgId,
  ({ token, orgId }) =>
    (dispatch) => {
      dispatch(getCustomFieldsRequest())

      return request.customFields
        .get({ token, orgId })
        .then(({ customfields = [] } = {}) => {
          dispatch(getCustomFieldsSuccess(customfields))
          return customfields
        })
        .catch((err) => {
          dispatch(
            getCustomFieldsFailure({
              status: err.status,
              message: ERROR_GET_CUSTOM_FIELDS_SERVER,
            }),
          )
          return err
        })
    },
)

export const addCustomFieldsItem =
  ({ token, orgId, item }) =>
  (dispatch) => {
    dispatch(addCustomFieldsRequest(item))

    return request.customFields
      .addItem({ token, orgId, item })
      .then((data = {}) => {
        dispatch(addCustomFieldsSuccess(data))
        dispatch(
          createNotification({
            'add-field': {
              type: 'success',
              message: `Successfully created ${item.names.length} custom field${item.names.length > 1 ? 's' : ''}`,
            },
          }),
        )
        dispatch(getCustomFields({ token, orgId }))
        return data
      })
      .catch((err) => {
        dispatch(addCustomFieldsFailure())
        dispatch(
          createNotification({
            [`add-customfield-${item.id}`]: {
              type: 'error',
              message: `${ERROR_SAVE_CUSTOM_FIELDS_SERVER} ${pathOr('', ['response', 'error'], err)}`,
            },
          }),
        )
        return err
      })
  }

export const saveCustomFieldsItem =
  ({ token, orgId, item, itemId }) =>
  (dispatch) => {
    dispatch(modCustomFieldsRequest(item))

    return request.customFields
      .saveItem({ token, orgId, item, itemId })
      .then((data = {}) => {
        dispatch(modCustomFieldsSuccess(data))
        dispatch(
          createNotification({
            'save-field': {
              type: 'success',
              message: `Custom field "${item.name}" was successfully saved`,
            },
          }),
        )
        return data
      })
      .catch((err) => {
        dispatch(modCustomFieldsFailure())
        dispatch(
          createNotification({
            [`save-customfield-${item.id}`]: {
              type: 'error',
              message: `${ERROR_SAVE_CUSTOM_FIELDS_SERVER} ${pathOr('', ['response', 'error'], err)}`,
            },
          }),
        )
        return err
      })
  }

export const deleteCustomFieldsItem =
  ({ token, orgId, itemId }) =>
  (dispatch) => {
    dispatch(deleteCustomFieldsRequest({}))

    return request.customFields
      .deleteItem({ token, orgId, itemId })
      .then((data = {}) => {
        dispatch(deleteCustomFieldsSuccess({ itemId }))
        dispatch(
          createNotification({
            'save-field': {
              type: 'success',
              message: 'Custom field was successfully deleted',
            },
          }),
        )
        return data
      })
      .catch((err) => {
        dispatch(deleteCustomFieldsFailure(err))
        dispatch(
          createNotification({
            [`delete-customfield-${itemId}`]: {
              type: 'error',
              message: `${ERROR_DELETE_CUSTOM_FIELDS_SERVER} ${pathOr('', ['response', 'error'], err)}`,
            },
          }),
        )
        return err
      })
  }
