import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Dashboard from '@/app/module/dashboard'
import Notifications from '@/app/module/notifications'

export default function MessagesPage({ name, routes, match }) {
  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <Layout title="Dashboard - engageSPARK">
        <AuthHeader currentPage={PAGE.DASHBOARD.INDEX} />
        <Dashboard />
      </Layout>
      <Notifications />
    </AuthGuard>
  )
}
