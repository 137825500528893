import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import Notifications from './component'
import { createNotification } from './store/actions'
import { NotificationsState } from './types'

const selector = ({ notifications }: { notifications: NotificationsState }) => ({
  data: pathOr({}, ['data'], notifications),
})

const dispatcher = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createNotification,
    },
    dispatch,
  )

export default connect(selector, dispatcher)(Notifications)
