import { HTTPMethod } from './types-api'

export const bodyEnabledMethods = ['POST', 'PUT', 'PATCH']

export const hasBody = (method: HTTPMethod) => bodyEnabledMethods.includes(method)

export const validateJSON = (value = '') => {
  try {
    JSON.parse(value)
    return true
  } catch {
    return false
  }
}
