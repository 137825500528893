import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import React from 'react'

import { useDebouncedFn } from '@/app/helpers'
import { DRAGTYPE, SMS_ACTION_DEFAULT_SENDERID } from '@/app/module/campaigns/definitions'
import {
  APIMessage,
  CampaignQuery,
  CampaignTemplateType,
  ImageProps,
  Language,
  LocalAction,
  PartFamily,
  PersonalizationType,
  SenderIdOption,
  SnippetType,
  VoiceProps,
} from '@/app/module/campaigns/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { unwrapActions, wrapActions } from '@/app/module/campaigns/utils/actions/wrap'
import { CountryTimezone, FilesType, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'

import QuestionBox from './question'
import QuestionNumberInput from './question/number'

type Props = {
  campaignIds: SelectOption<number>[]
  countriesTimezones: CountryTimezone[]
  countryCode: string
  defaultLanguage: string
  files: FilesType
  id: string
  index: number
  item: APIMessage
  orgId: number
  personalizationList: PersonalizationType
  senderIds: SenderIdOption[]
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  timezone: string
  timezones: TimezoneWithCountryCodeType
  token: string
  type: CampaignTemplateType
  whatsappSenderIds: SenderIdOption[]
  languages: Language[]

  changeHandler: (item: Partial<APIMessage>, changed?: boolean) => void
  getCampaigns: (payload: { orgId: number; token: string; query: CampaignQuery }) => Promise<void>
  getImageProps: (qid: string) => ImageProps
  getSnippets: (payload: { orgId: number; token: string }) => Promise<void>
  getVoiceProps: (qid: string) => VoiceProps
  saveSnippet: (payload: {
    orgId: number
    token: string
    item: Pick<SnippetType, 'category' | 'snippet'>
  }) => Promise<SnippetType>
}

type Actions = {
  onSuccess: LocalAction[]
  onError: LocalAction[]
  retries: number
}

const CampaignContentQuestionAPI: React.FC<Props> = ({
  campaignIds,
  countriesTimezones,
  countryCode,
  defaultLanguage,
  files,
  id,
  index,
  item,
  languages,
  orgId,
  personalizationList,
  senderIds,
  snippets,
  timezone,
  timezones,
  token,
  type,
  whatsappSenderIds,
  changeHandler,
  getCampaigns,
  getImageProps,
  getSnippets,
  getVoiceProps,
  saveSnippet,
}) => {
  const itemRef = React.useRef<Partial<APIMessage> | null>(null)
  const changeHandlerRef = React.useRef(changeHandler)

  const debouncedChangeHandler = useDebouncedFn((values: Partial<APIMessage>, changed?: boolean) => {
    changeHandler(values, changed)
    itemRef.current = null
  }, 500)

  const [state, setState] = React.useState<Actions>(actionsFromProps(item.apiCall))

  React.useEffect(() => {
    setState(actionsFromProps(item.apiCall))
  }, [item.apiCall])

  React.useEffect(
    () => () => {
      if (itemRef.current) {
        changeHandlerRef.current(itemRef.current)
      }
    },
    [],
  )

  const successActions = React.useMemo(
    () =>
      getActionOptionsForCampaign({
        campaignType: type,
        partType: PartFamily.API,
        actionNamespace: ActionContext.ApiSuccess,
      }),
    [type],
  )
  const failureActions = React.useMemo(
    () =>
      getActionOptionsForCampaign({
        campaignType: type,
        partType: PartFamily.API,
        actionNamespace: ActionContext.ApiFailure,
      }),
    [type],
  )

  const setActions = (value: Partial<Actions>, debouncingChange = false) => {
    setState({
      ...state,
      ...value,
    })

    const newItem = {
      ...item,
      apiCall: {
        ...item.apiCall,
        ...actionsToProps({
          ...state,
          ...value,
        }),
      },
    }
    if (!debouncingChange) {
      changeHandler(newItem)
    } else {
      itemRef.current = newItem
      debouncedChangeHandler(newItem)
    }
  }

  const handleSuccessChange = (value: { actionsList: LocalAction[] }) => {
    setActions({
      onSuccess: value.actionsList,
    })
  }
  const handleErrorChange = (value: { actionsList: LocalAction[] }) => {
    setActions({
      onError: value.actionsList,
    })
  }
  const handleRetriesChange = (value: { value: string }) => {
    setActions(
      {
        retries: Number(value.value),
      },
      true,
    )
  }

  return (
    <div className="api-actions">
      <Divider
        style={{
          margin: '0rem -24px 1rem',
        }}
      />
      <div className="api-success-actions">
        <Typography>API Request Success Actions</Typography>
        <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          Specify what actions we should take if the API request succeeds. We consider it successful, if the HTTP
          response had a status code between 200 and 299. The response is available as personalization variables. For
          example, you can store the response body in a custom field or send it in an email.
        </Typography>
        <div>
          <QuestionBox
            actionsList={state.onSuccess}
            actionsValues={successActions}
            campaignIds={campaignIds}
            countriesTimezones={countriesTimezones}
            countryCode={countryCode}
            defaultLanguage={defaultLanguage}
            defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.actionsOnly}
            dragContext={`${DRAGTYPE}-apis`}
            files={files}
            imageId={`${id}-api`}
            index={index}
            languages={languages}
            orgId={orgId}
            personalizationList={personalizationList}
            question={true}
            senderIds={senderIds}
            snippets={snippets}
            timezone={timezone}
            timezones={timezones}
            token={token}
            type="api"
            voiceId={`${id}-api`}
            whatsappSenderIds={whatsappSenderIds}
            changeHandler={handleSuccessChange}
            getCampaigns={getCampaigns}
            getImageProps={getImageProps}
            getSnippets={getSnippets}
            getVoiceProps={getVoiceProps}
            saveSnippet={saveSnippet}
          />
        </div>
      </div>
      <div className="api-error-actions">
        <Typography>API Request Failure Actions</Typography>
        <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          Specify what actions we should take if the API request fails. We consider it failed, if the status code is in
          the 4xx or 5xx ranges. The response is available as personalization variables. For example, you can store the
          status code of the response in a custom field or send it in an email.
        </Typography>
        <div>
          <QuestionBox
            actionsList={state.onError}
            actionsValues={failureActions}
            campaignIds={campaignIds}
            countriesTimezones={countriesTimezones}
            countryCode={countryCode}
            defaultLanguage={defaultLanguage}
            defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.actionsOnly}
            dragContext={`${DRAGTYPE}-apis`}
            files={files}
            imageId={`${id}-api`}
            index={index}
            languages={languages}
            orgId={orgId}
            personalizationList={personalizationList}
            question={true}
            senderIds={senderIds}
            snippets={snippets}
            timezone={timezone}
            timezones={timezones}
            token={token}
            type="api"
            voiceId={`${id}-api`}
            whatsappSenderIds={whatsappSenderIds}
            changeHandler={handleErrorChange}
            getCampaigns={getCampaigns}
            getImageProps={getImageProps}
            getSnippets={getSnippets}
            getVoiceProps={getVoiceProps}
            saveSnippet={saveSnippet}
          />
        </div>
      </div>
      <div>
        <Typography>API Request Retries</Typography>
        <Typography color="textSecondary" component="div" gutterBottom={true} variant="caption">
          <QuestionNumberInput
            beforeText="Specify the number of times that we should retry the API request if it fails"
            data-testid="retries"
            min={0}
            value={state.retries.toString()}
            onChange={handleRetriesChange}
          />
        </Typography>
      </div>
    </div>
  )
}

export const actionsFromProps = ({ onSuccess, onError, retries = 5 }: APIMessage['apiCall']) => ({
  onSuccess: unwrapActions(onSuccess || []),
  onError: unwrapActions(onError || []),
  retries,
})

export const actionsToProps = ({ onSuccess, onError, retries }: Actions) => ({
  onSuccess: wrapActions(onSuccess || []),
  onError: wrapActions(onError || []),
  retries,
})

export default CampaignContentQuestionAPI
