import { green } from '@mui/material/colors'
import Fade from '@mui/material/Fade'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import Typography from '@mui/material/Typography'
import { omit } from 'ramda'
import React from 'react'
import processString from 'react-process-string'

import IconText from '@/app/component/layout/icon-text'
import { NotificationLink, NotificationsData } from '@/app/module/notifications/types'

import LinearProgressWithTimer from './linearProgressWithTimer'

type Props = {
  data: NotificationsData
  createNotification: (data: NotificationsData) => void
}

const iconMap = {
  success: <Icon style={{ color: green.A700 }}>check_circle</Icon>,
  error: <Icon color="error">error</Icon>,
  warning: <Icon color="error">warning</Icon>,
}

const DEFAULT_AUTO_HIDE_DURATION = 10000

const Notifications: React.FC<Props> = ({ data, createNotification }) => (
  <div>
    {Object.keys(data).map((key) => {
      const autoHideDuration = data[key].autoHide || DEFAULT_AUTO_HIDE_DURATION
      const { links } = data[key]
      return (
        <Snackbar
          key={key}
          open={true}
          TransitionComponent={Fade}
          autoHideDuration={data[key].type === 'success' && !data[key].disableAutoHide ? autoHideDuration : null}
          transitionDuration={500}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => {
            createNotification(omit([key], data))
          }}
          ClickAwayListenerProps={{
            onClickAway: () => {},
          }}
        >
          <SnackbarContent
            message={
              <>
                <IconText>
                  {iconMap[data[key].type] && iconMap[data[key].type]}
                  <Typography color="inherit">
                    {!links
                      ? data[key].message
                      : processString(
                          links.map((link: NotificationLink) => ({
                            regex: new RegExp(link.name),
                            fn: (i: string) => (
                              <React.Fragment key={i}>
                                <br />
                                <Typography
                                  color="secondary"
                                  component="a"
                                  href={link.href}
                                  onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                    if (typeof link.onClick === 'function') {
                                      link.onClick(e)
                                    }
                                  }}
                                  style={{ display: 'inline', cursor: 'pointer', margin: '0' }}
                                >
                                  {link.title}
                                </Typography>
                              </React.Fragment>
                            ),
                          })),
                        )(data[key].message)}
                  </Typography>
                </IconText>
                {data[key].type === 'success' && !data[key].disableAutoHide && (
                  <>
                    <Typography style={{ color: '#d1d1d1' }} variant="caption">
                      This notification will auto dismiss in {autoHideDuration / 1000} seconds
                    </Typography>
                    <LinearProgressWithTimer autoHideDuration={autoHideDuration} />
                  </>
                )}
              </>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => createNotification(omit([key], data))}
              >
                <Icon>close</Icon>
              </IconButton>,
            ]}
          ></SnackbarContent>
        </Snackbar>
      )
    })}
  </div>
)

export default Notifications
