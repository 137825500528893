import { indexBy } from 'ramda'

import { apiPz } from '@/app/module/logs/store/selectors'
import { addCurlyBrackets } from '@/app/module/utils/personalization'

type UpdateContact = {
  field: string
  value: string
  constant?: string
}

const apiPzMap = indexBy((pz) => addCurlyBrackets(pz.value), apiPz)

export const fromUpdateContact = (updateContact: UpdateContact) => {
  const { field, value, constant = '' } = updateContact
  if (value === 'constant' && apiPzMap[constant]) {
    return {
      field,
      value: constant,
      constant: '',
    }
  }

  return updateContact
}

export const toUpdateContact = (updateContact: UpdateContact) => {
  const { field, value = '' } = updateContact
  if (apiPzMap[value]) {
    return {
      field,
      value: 'constant',
      constant: value,
    }
  }

  return updateContact
}
