import { red } from '@mui/material/colors'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'
import SMSHelper from 'smshelper'

import IconText from '@/app/component/layout/icon-text'
import { smsMaxLength } from '@/app/module/campaigns/definitions'

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1rem',
})

const TextContainer = styled('div')({
  color: 'rgba(0, 0, 0, 0.54)',
})

const SmsContainer = styled('div')({
  color: red.A700,
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  zIndex: 99,
})

const ErrorContainer = styled('div')({
  border: `1px solid ${red.A700}`,
  padding: '5px',
  background: 'rgb(245, 245, 245)',
  maxWidth: '90%',
  position: 'relative',
})

type InputSMSProps = {
  name?: string
  message?: string
  messageCountable?: string
  children: React.ReactNode
  hideInfo?: boolean
}

function InputSMS({ name = '', message = '', messageCountable, children, hideInfo = false }: InputSMSProps) {
  const countable = messageCountable || message

  const [maxSMSWarning, setMaxSMSWarning] = React.useState(true)

  const stats = {
    count: SMSHelper.count(countable),
    parts: countable ? SMSHelper.parts(countable) : 0,
    isUnicode: SMSHelper.detectEncoding(message) === 'UTF16',
  }

  return (
    <div style={{ position: 'relative' }}>
      {children}
      {!hideInfo && (
        <Container className={`${name}-message-info`}>
          <TextContainer>
            {stats.isUnicode && (
              <IconText>
                <Icon>info</Icon>
                <Typography color="inherit">
                  This message contains Unicode characters, which increases the cost.{' '}
                  <Link target="_blank" href="https://www.engagespark.com/support/sms-features/#SMSlength">
                    Learn more <Icon style={{ fontSize: '13px' }}>launch</Icon>
                  </Link>
                </Typography>
              </IconText>
            )}
          </TextContainer>
          <SmsContainer>
            {stats.parts > smsMaxLength && maxSMSWarning && (
              <ErrorContainer>
                <IconButton sx={{ float: 'right' }} onClick={() => setMaxSMSWarning(false)}>
                  <Icon fontSize="small">close</Icon>
                </IconButton>
                <Typography variant="h6">
                  This message is too long. Check out this{' '}
                  <Link target="_blank" href="https://www.engagespark.com/support/sms-features/#SMSlength">
                    support article
                  </Link>{' '}
                  for more information
                </Typography>
              </ErrorContainer>
            )}
          </SmsContainer>
          <Typography
            sx={{
              color: stats.parts > smsMaxLength ? 'red.A700' : 'rgba(0, 0, 0, 0.54)',
            }}
          >
            {stats.count} used [{stats.parts}/{smsMaxLength} SMS]
          </Typography>
        </Container>
      )}
    </div>
  )
}

export default InputSMS
