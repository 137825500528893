import { z } from 'zod'

import { createTranslations } from '@/app/module/campaigns/types/schemas/common'
import ZTextContent from '@/app/module/campaigns/types/schemas/message-content/text'

import ZBasePart from './base'

const ZSMSMessagePart = ZBasePart.extend({
  smsMessage: z.object({
    message: ZTextContent.extend({
      translations: createTranslations(ZTextContent).optional(),
    }),
  }),
})

export default ZSMSMessagePart
