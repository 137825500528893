import React from 'react'

import { WhatsAppTab } from '@/app/component/atom/whatsapp/helpers'
import CampaignContentQuestionAction from '@/app/module/campaigns/component/item/steps/content/actions-only'
import CampaignContentQuestionAPI from '@/app/module/campaigns/component/item/steps/content/api-actions'
import CampaignContentQuestionText from '@/app/module/campaigns/component/item/steps/content/question-text'
import CampaignContentQuestionVoice from '@/app/module/campaigns/component/item/steps/content/question-voice'
import ResponseWhatsapp from '@/app/module/campaigns/component/item/steps/content/response-whatsapp'
import CampaignContentQuestionTopup from '@/app/module/campaigns/component/item/steps/content/topup-actions'
import { BaseLanguageType } from '@/app/module/campaigns/helpers'
import {
  CampaignQuery,
  CampaignTemplateType,
  ImageProps,
  Language,
  LegacyCampaignItem,
  PartFamily,
  PersonalizationType,
  ReminderMessage,
  SenderIdOption,
  SnippetType,
  Message as TMessage,
  VoiceProps,
} from '@/app/module/campaigns/types'
import {
  CountryTimezone,
  FilesType,
  FileType,
  FileUploadType,
  SelectOption,
  TimezoneWithCountryCodeType,
} from '@/app/types'

type BaseProps = {
  apiPersonalizationList: PersonalizationType
  campaignIds: SelectOption<number>[]
  countriesTimezones: CountryTimezone[]
  countryCode: string
  defaultLanguage: string
  defaultUpload: number
  files: {
    data: FilesType
    loadingItems: string[]
  }
  id: string
  index: number
  languages: Language[]
  orgId: number
  personalizationList: PersonalizationType
  senderIds: SenderIdOption[]
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  timezone: string
  timezones: TimezoneWithCountryCodeType
  token: string
  total: number
  voiceList: BaseLanguageType[]
  voicePlayingId: string
  whatsappSenderIds: SenderIdOption[]
  whatsappTab: WhatsAppTab

  getCampaigns: (payload: { token: string; orgId: number; query: CampaignQuery }) => Promise<void>
  getFile: (id: string, file: string) => Promise<FileType>
  getSnippets: (payload: { orgId: number; token: string }) => Promise<void>
  onChange: (item: Partial<TMessage>, changed?: boolean) => void
  onMicAccessError: () => void
  saveFile: (id: string, file: FileUploadType) => Promise<FileType>
  saveSnippet: (payload: {
    orgId: number
    token: string
    item: Pick<SnippetType, 'category' | 'snippet'>
  }) => Promise<SnippetType>
  setDefaultUpload: (defaultUpload: number) => void
  setLoading: (loading: boolean) => void
  setPlayingId: (playId?: string) => void
}

type CampaignProps = BaseProps & {
  campaignType: LegacyCampaignItem['type']
  message: TMessage
}

type ReminderProps = BaseProps & {
  campaignType: CampaignTemplateType.Reminder
  message: ReminderMessage
}

type Props = CampaignProps | ReminderProps

const MessageResponse: React.FC<Props> = ({
  campaignIds,
  campaignType,
  countriesTimezones,
  countryCode,
  defaultLanguage,
  defaultUpload,
  files,
  id,
  index,
  languages,
  message,
  orgId,
  personalizationList,
  senderIds,
  snippets,
  timezone,
  timezones,
  token,
  voiceList,
  voicePlayingId,
  whatsappSenderIds,
  whatsappTab,
  getCampaigns,
  getFile,
  getSnippets,
  onChange,
  onMicAccessError,
  saveFile,
  saveSnippet,
  setDefaultUpload,
  setPlayingId,
}) => {
  const handleGetFile = React.useCallback((file: string) => getFile(id, file), [id, getFile])
  const handleSaveFile = React.useCallback((file: FileUploadType) => saveFile(id, file), [id, saveFile])

  const getImageProps = React.useCallback(
    (qid: string): ImageProps => ({
      id: qid,
      loading: files.loadingItems.indexOf(qid) > -1,
      getFile: handleGetFile,
      saveFileHandler: handleSaveFile,
    }),
    [files.loadingItems, handleGetFile, handleSaveFile],
  )
  const getVoiceProps = React.useCallback(
    (qid: string): VoiceProps => ({
      id: qid,
      defaultUpload,
      files: files.data || {},
      filterType: campaignType,
      loading: files.loadingItems.indexOf(qid) > -1,
      personalizationList,
      playId: voicePlayingId,
      voiceList,
      getFile: handleGetFile,
      saveFileHandler: handleSaveFile,
      setDefaultUpload,
      setPlaying: setPlayingId,
      onMicAccessError,
    }),
    [
      campaignType,
      defaultUpload,
      files.data,
      files.loadingItems,
      personalizationList,
      voiceList,
      voicePlayingId,
      handleGetFile,
      handleSaveFile,
      onMicAccessError,
      setDefaultUpload,
      setPlayingId,
    ],
  )

  const commonProps = React.useMemo(
    () => ({
      campaignIds,
      countriesTimezones,
      countryCode,
      defaultLanguage,
      files: files.data || emptyObject,
      id,
      languages,
      index,
      orgId,
      personalizationList,
      senderIds,
      snippets,
      timezone,
      timezones,
      token,
      type: campaignType,
      whatsappSenderIds,
      changeHandler: onChange,
      getCampaigns,
      getImageProps,
      getSnippets,
      getVoiceProps,
      saveSnippet,
    }),
    [
      campaignIds,
      campaignType,
      countriesTimezones,
      countryCode,
      defaultLanguage,
      files.data,
      id,
      index,
      languages,
      orgId,
      personalizationList,
      senderIds,
      snippets,
      timezone,
      timezones,
      token,
      whatsappSenderIds,
      getCampaigns,
      getImageProps,
      getSnippets,
      getVoiceProps,
      onChange,
      saveSnippet,
    ],
  )

  if (!message.hasQuestion) {
    return null
  }

  switch (message.type) {
    case PartFamily.API:
      return <CampaignContentQuestionAPI {...commonProps} item={message} />

    case PartFamily.Action:
      return (
        <CampaignContentQuestionAction
          {...commonProps}
          item={message}
          title={
            'An “Actions only” message allows you to perform actions without having to send a SMS or making a call'
          }
        />
      )

    case PartFamily.CATI:
      return <CampaignContentQuestionText {...commonProps} item={message} questionType={PartFamily.SMS} useAi={false} />

    case PartFamily.SMS:
      return (
        <CampaignContentQuestionText
          {...commonProps}
          item={message}
          questionType={PartFamily.SMS}
          useAi={message.useAi}
        />
      )

    case PartFamily.Topup:
      return <CampaignContentQuestionTopup {...commonProps} item={message} />

    case PartFamily.Voice:
      return <CampaignContentQuestionVoice {...commonProps} item={message} />

    case PartFamily.WhatsApp:
      return (
        <ResponseWhatsapp
          {...commonProps}
          item={message}
          useAi={message.useAi}
          transcribe={message.transcribe}
          tab={whatsappTab}
        />
      )

    default:
      return null
  }
}

const emptyObject = {}

export default React.memo(MessageResponse)
