import TextField from '@mui/material/TextField'
import { TimePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import React from 'react'

import { noop } from '@/app/definitions'

import FormControl from './form-control'
import Input from './input'

const timeFormat = 'hh:mm A'
const valueFormat = 'HH:mm'

const getTimeValue = (valStr: string) => {
  const date = moment()
  if (valStr) {
    const splitVal = valStr
      .toString()
      .split(':')
      .map((part) => parseInt(part, 10))
    date.hours(splitVal[0])
    date.minutes(splitVal[1])
  }

  return date
}

type TimeFieldProps = Partial<React.ComponentProps<typeof Input>> & {
  name: string
  label?: string
  editable?: boolean
  value?: string
  error?: string
  info?: string
  onChange: (data: { name: string; value: string }) => void
  onOpen?: () => void
  onClose?: () => void
}

export default function TimeField({
  name = '',
  label = '',
  editable = true,
  value = '',
  error = '',
  info = '',
  onChange,
  onOpen = () => {},
  onClose = () => {},
  ...rest
}: TimeFieldProps) {
  const ref = React.useRef(null)
  const [open, setOpen] = React.useState(false)

  const timeValue = getTimeValue(value)
  if (!editable) {
    return <Input type="text" name={name} label={label} editable={false} value={value} {...rest} />
  }
  return (
    <FormControl error={error} info={info}>
      <div ref={ref}>
        <TimePicker
          format={timeFormat}
          label={label}
          open={open}
          value={timeValue}
          slots={{
            textField: (props) => (
              <TextField
                {...props}
                InputProps={{
                  ...props.InputProps,
                  endAdornment: null,
                }}
                style={{ width: '100%' }}
                variant="standard"
                onChange={noop}
                onClick={() => setOpen(true)}
              />
            ),
          }}
          slotProps={{
            popper: {
              anchorEl: ref.current,
            },
          }}
          onChange={(nextValue) => {
            onChange({
              name,
              value: nextValue ? nextValue.format(valueFormat) : '',
            })
          }}
          onClose={() => {
            setOpen(false)
            onClose?.()
          }}
          onOpen={onOpen}
        />
      </div>
    </FormControl>
  )
}
