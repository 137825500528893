import { Box, Dialog, DialogContent, DialogTitle, Icon, IconButton } from '@mui/material'
import React from 'react'

import Form from '@/app/component/layout/form'
import { APIKey, UpdateAPIKeyPayload } from '@/app/module/user/types'
import { FormHeader, FormSpecs } from '@/app/types'

type Props = {
  apiKey: APIKey
  loading: boolean

  onClose: () => void
  onSubmit: (apiKey: APIKey, values: UpdateAPIKeyPayload) => Promise<void>
}

const headers: FormHeader[] = [
  {
    fields: ['name'],
    title: 'Name',
  },
  {
    fields: ['expires'],
    title: 'Expires',
  },
  {
    fields: ['active'],
    title: 'Active',
  },
]
const specs: FormSpecs = {
  name: {
    editable: true,
    label: 'Name',
    type: 'text',
    variant: 'standard',
  },
  expires: {
    editable: true,
    label: 'Expires',
    type: 'datetime',
    variant: 'standard',
  },
  active: {
    editable: true,
    label: 'Enabled',
    type: 'checkbox',
    variant: 'standard',
  },
}

type UpdateAPIKeyValues = {
  active?: string
  expires?: string
  name?: string
}

const ApiKeyModal: React.FC<Props> = ({ apiKey, loading, onClose, onSubmit }) => {
  const state = React.useMemo<UpdateAPIKeyValues>(
    () => ({
      active: apiKey.active ? 'true' : 'false',
      expires: apiKey.expires,
      name: apiKey.name,
    }),
    [apiKey],
  )

  const handleSubmit = React.useCallback(
    async ({ item }: { item: UpdateAPIKeyValues }) => {
      await onSubmit(apiKey, {
        ...item,
        active: item.active === 'true',
      })
      onClose()
    },
    [apiKey, onClose, onSubmit],
  )

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>Manage PAT Details</span>
          <IconButton data-testid="close-button" onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Form
          id="update-api-key"
          config={specs}
          headers={headers}
          loading={loading}
          submitLabel="Save"
          values={state}
          onSubmit={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  )
}

export default ApiKeyModal
