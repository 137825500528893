import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

const Title = styled(Typography)({
  marginTop: '2rem',
  marginBottom: '3rem',
})

type PageTitleProps = {
  children: React.ReactNode
}

export default function PageTitle({ children }: PageTitleProps) {
  return (
    <Title variant="h6" color="textSecondary">
      {children}
    </Title>
  )
}
