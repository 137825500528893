import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import React from 'react'

import SelectMultiple from '@/app/component/atom/form/select-multiple'
import Loading from '@/app/component/guard/loading'
import { FunctionType } from '@/config/types'

type Props = {
  contactIds: string[]
  excludeIds: string[]
  isAllContacts: boolean
  includedGroupIds: string[]
  search: string
  groupIds: string[]
  totalRecipients: number
  groups: { label: string; value: string; numberOfContacts: number }[]
  recipients: { label: string; value: string }[]
  addToGroup: FunctionType
  closeHandler: FunctionType
  getGroups: FunctionType
  token: string
  orgId: number
  activeFilter: { name: string; value: string } | null
}

export default function AddToGroup(props: Props) {
  const {
    contactIds,
    excludeIds,
    isAllContacts,
    includedGroupIds,
    search,
    totalRecipients,
    groups,
    recipients,
    closeHandler,
    addToGroup,
    getGroups,
    token,
    orgId,
    activeFilter,
  } = props

  const [loading, setLoading] = React.useState(false)
  const [groupIds, setGroupIds] = React.useState<string[]>([])

  React.useEffect(() => {
    if (orgId && token) {
      getGroups({
        token,
        orgId,
      })
    }
  }, [orgId, token, getGroups])

  React.useEffect(() => {
    if (activeFilter?.name === 'groups') {
      setGroupIds([activeFilter.value])
    }
  }, [activeFilter])

  const recipientsDisplay = [
    {
      label: `${totalRecipients} selected contact${totalRecipients === 1 ? '' : 's'}`,
      value: 'more',
    },
  ]

  const addToGroupHandler = () => {
    setLoading(true)

    addToGroup({
      groupIds: groupIds.map((groupId) => Number(groupId)),
      isAllContacts,
      contactIds,
      excludeIds,
      includedGroupIds,
      search,
    }).finally(() => {
      setLoading(false)
      closeHandler()
    })
  }

  const selectMultipleGroupsHandler = ({ value }: { value: string[] }) => {
    setGroupIds(value)
  }

  return (
    <Loading isLoading={loading}>
      <form
        id="add-to-form"
        onSubmit={(e) => {
          e.preventDefault()
          if (!loading || totalRecipients) {
            addToGroupHandler()
          }
        }}
      >
        <CardContent>
          <SelectMultiple
            id="add-to-group-contacts"
            label="Add"
            name="contacts"
            value={recipientsDisplay.map(({ value }) => value)}
            values={recipientsDisplay}
            editable={false}
            message={`Please select the groups you want to add the ${totalRecipients} selected contact${
              totalRecipients === 1 ? '' : 's'
            } to`}
          />
          <SelectMultiple
            id="add-to-group-groups"
            label="Group"
            name="groups"
            value={groupIds}
            values={groups}
            editable={true}
            onChange={selectMultipleGroupsHandler}
          />
        </CardContent>
        <CardActions>
          <Grid container direction="row" alignItems="center" justifyContent="flex-end">
            <Button
              id="add-to-group-action"
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading || !groupIds.length || !recipients.length}
            >
              Add To Group
            </Button>
          </Grid>
        </CardActions>
      </form>
    </Loading>
  )
}
