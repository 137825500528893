import MoneyIcon from '@mui/icons-material/MonetizationOn'
import PeopleIcon from '@mui/icons-material/People'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { find, isEmpty, pathOr, propEq, reduce } from 'ramda'
import React, { useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'

import SummaryBox from '@/app/component/atom/reports/summaryBox'
import { mapIndexed } from '@/app/module/campaigns/helpers'
import { selectContent } from '@/app/module/campaigns/utils/part-content'

import ContentOverview from './components/contentOverview'
import CostOverviewTable from './components/cost-overview-table'
import MessageOverviewTable from './components/message-overview-table'
import OverviewChart from './components/overviewBarChart'
import OverviewPieChart from './components/overviewPieChart'
import TopupOverviewTable from './components/topup-overview-table'
import VoiceOverviewTable from './components/voice-overview-table'
import WhatsappOverviewTable from './components/whatsapp-overview-table'
import { dataStructure, processPartsData } from './helpers'

const chartData = {
  ivr: (reportData) => {
    const { overallVoiceStats = [] } = reportData
    return reduce(
      (contentAccum, { label, data }) => [
        ...contentAccum,
        {
          value: data(overallVoiceStats),
          legendLabel: label,
        },
      ],
      [],
      find(propEq('key', 'voice'), dataStructure).content,
    )
  },
  cati: (reportData) => {
    const { overallVoiceStats = [] } = reportData
    return reduce(
      (contentAccum, { label, data }) => [
        ...contentAccum,
        {
          value: data(overallVoiceStats),
          legendLabel: label,
        },
      ],
      [],
      find(propEq('key', 'voice'), dataStructure).content,
    )
  },
  drip: (reportData) => processPartsData(reportData.partStats),
  multidrip: (reportData) => processPartsData(reportData.partStats),
  smssurvey: (reportData) => processPartsData(reportData.partStats),
  whatsappsurvey: (reportData) => processPartsData(reportData.partStats),
  smsblast: (reportData) => {
    const { overallMessageStats = [] } = reportData
    return reduce(
      (contentAccum, { label, data }) => [
        ...contentAccum,
        {
          value: data(overallMessageStats),
          legendLabel: label,
        },
      ],
      [],
      find(propEq('key', 'message'), dataStructure).content,
    )
  },
}

export const processDataForChart = (reportData) => chartData.multidrip(reportData)

export const processDataForPieChart = (reportData, type) => chartData[type](reportData)

const processContentForSummary = ({ itemSummary, item }) =>
  mapIndexed((message, index) => selectContent(message, index, itemSummary), pathOr([], ['variables', 'parts'], item))

export const processSummary = (itemSummary, type) =>
  type !== 'ivr' && type !== 'smsblast' ? processDataForChart(itemSummary) : processDataForPieChart(itemSummary, type)

export default function OverviewTab(props) {
  const type = pathOr('', ['item', 'type'], props)
  const content = processContentForSummary(props)
  const { classes } = useStyles()

  const chart = useMemo(() => {
    if (!type || typeof chartData[type] !== 'function') {
      return []
    }

    return chartData[type](props.itemSummary)
  }, [props.itemSummary, type])

  const { item, itemSummary } = props

  if (!item || isEmpty(item) || !itemSummary || isEmpty(itemSummary)) {
    return null
  }

  const renderGridItems = () => {
    const statsArray = [
      {
        stats: itemSummary.overallMessageStats,
        component: MessageOverviewTable,
      },
      {
        stats: itemSummary.overallVoiceStats,
        component: VoiceOverviewTable,
      },
      {
        stats: itemSummary.overallWhatsappStats,
        component: WhatsappOverviewTable,
      },
      {
        stats: itemSummary.overallAirtimeStats,
        component: TopupOverviewTable,
      },
    ].filter(({ stats }) => stats.total)

    if (statsArray.length) {
      // add cost overview table to the beginning if there are any usage stats
      statsArray.unshift({
        stats: itemSummary,
        component: CostOverviewTable,
      })
    }

    return statsArray.map(({ stats, component: Component }, index) => (
      <Grid key={index} item {...getGridWidth(index, statsArray.length)}>
        <Component data={stats} />
      </Grid>
    ))
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ marginBottom: 3 }}>
        <Grid item sm={6} xs={12}>
          <SummaryBox icon={MoneyIcon} label="Total Cost" value={itemSummary.costHuman} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SummaryBox icon={PeopleIcon} label="Subscriptions" value={item.runs} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: 3 }}>
        {renderGridItems()}
      </Grid>

      <Grid className={classes.row} container spacing={2}>
        <Grid item md={6} xs={12}>
          <div>
            {type !== 'ivr' && type !== 'smsblast' ? (
              <OverviewChart data={chart} />
            ) : (
              <Paper>
                <OverviewPieChart data={chart} />
              </Paper>
            )}
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <ContentOverview data={content} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const getGridWidth = (index, itemsCount) => {
  // if the count is even, then we want to have 2 items per row
  if (itemsCount % 2 === 0) {
    return {
      sm: 6,
      xs: 12,
    }
  }

  // if the count is odd, then we want to have 2 items per row except for the last item
  return {
    sm: index !== itemsCount - 1 ? 6 : 12,
    xs: 12,
  }
}

const useStyles = makeStyles()((theme) => ({
  row: {
    marginBottom: theme.spacing(3),
  },
}))
