import { Box } from '@mui/material'
import React, { useEffect } from 'react'

import WhatsappMessage from '@/app/component/atom/whatsapp'
import { WhatsAppTab } from '@/app/component/atom/whatsapp/helpers'
import { useDebouncedFn } from '@/app/helpers'
import { CAMPAIGN_CHANGE_DELAY } from '@/app/module/campaigns/definitions'
import {
  ContentWhatsApp,
  PersonalizationType,
  VoiceProps,
  WhatsAppMessage,
  WhatsAppQuestion,
} from '@/app/module/campaigns/types'
import { wrapActions } from '@/app/module/campaigns/utils/actions/wrap'
import { getRegisterTemplateDisabledReason } from '@/app/module/campaigns/utils/whatsapp'
import { FileType, FileUploadType } from '@/app/types'

import { defaultOnAnswerText } from './types/multimessage/content'
import WhatsappTemplateCheckbox from './whatsapp-template-checkbox'

type MessageType = WhatsAppMessage | WhatsAppQuestion

type Props = Pick<
  VoiceProps,
  | 'defaultUpload'
  | 'files'
  | 'filterType'
  | 'loading'
  | 'playId'
  | 'getFile'
  | 'onMicAccessError'
  | 'saveFileHandler'
  | 'setDefaultUpload'
  | 'setPlaying'
> & {
  defaultLanguage: string
  info: string
  item: MessageType
  label: string
  personalizationList: PersonalizationType

  changeHandler: (item: MessageType) => void
  onTabChange?: (tab: WhatsAppTab) => void
  saveFileHandler: (file: FileUploadType) => Promise<FileType | Error>
}

type MediaContent =
  | {
      document: string
    }
  | {
      image: string
    }
  | {
      video: string
    }

const CampaignContentWhatsapp: React.FC<Props> = (props) => {
  const { item, changeHandler: propChangeHandler, onTabChange } = props

  const [mediaContent, setMediaContent] = React.useState<MediaContent | undefined>(undefined)

  const changeHandler = React.useCallback(
    (newItem: MessageType) => {
      if (!newItem.message.text || !newItem.message.video) {
        return propChangeHandler(newItem)
      }

      return propChangeHandler({
        ...newItem,
        message: {
          ...newItem.message,
          registerTemplate: true, // enforce register template if both video & caption are present
        },
      })
    },
    [propChangeHandler],
  )

  useEffect(() => {
    if (!hasList(item) || !item.message.registerTemplate) {
      return
    }

    changeHandler({
      ...item,
      message: {
        ...item.message,
        registerTemplate: false,
      },
    })
  }, [item, changeHandler])

  useEffect(() => {
    if (!mediaContent) {
      return
    }

    changeHandler({
      ...item,
      message: {
        ...item.message,
        ...mediaContent,
        audio: null,
      },
    })
    setMediaContent(undefined)
  }, [item, mediaContent, changeHandler])

  const saveHandler = async (file: FileUploadType) => {
    const { saveFileHandler } = props

    try {
      const res = await saveFileHandler(file)
      let uploadedContent: MediaContent | undefined

      if (file.type === 'video') {
        uploadedContent = { video: res.filename }
      } else if (file.type === 'image') {
        uploadedContent = { image: res.filename }
      } else if (file.type === 'document') {
        uploadedContent = { document: res.filename }
      }

      if (uploadedContent) {
        setMediaContent(uploadedContent)
      }
    } catch {
      // Ignore
    }
  }

  const removeMedia = () =>
    changeHandler({
      ...item,
      message: {
        ...item.message,
        image: '',
        audio: '',
        video: '',
        document: '',
      },
    })

  const hasWAButtons = hasButtons(item)
  const hasWAList = hasList(item)

  const itemRef = React.useRef<MessageType | null>(null)
  const changeHandlerRef = React.useRef(changeHandler)

  React.useEffect(
    () => () => {
      if (itemRef.current) {
        changeHandlerRef.current?.(itemRef.current)
      }
    },
    [],
  )

  const change = React.useCallback(
    (newItem: MessageType) => {
      changeHandler(newItem)
      itemRef.current = null
    },
    [changeHandler],
  )
  const debouncedHandleChange = useDebouncedFn(change, CAMPAIGN_CHANGE_DELAY)
  const handleChange = React.useCallback(
    (updatedValue: ContentWhatsApp) => {
      const newItem = {
        ...item,
        message: {
          ...item.message,
          ...updatedValue,
        },
      }
      itemRef.current = newItem
      debouncedHandleChange(newItem)
    },
    [item, debouncedHandleChange],
  )

  const handleTabChange = React.useCallback(
    (tab: WhatsAppTab) => {
      if (onTabChange) {
        onTabChange(tab)
      }

      if ((!hasWAButtons && !hasWAList) || tab === 'text' || !item.hasQuestion) {
        return
      }

      changeHandler({
        ...item,
        onAnswer: defaultOnAnswerText.map((answer) => ({
          ...answer,
          actions: wrapActions(answer.actions),
        })),
        onButtons: undefined,
        onList: undefined,
      })
    },
    [hasWAButtons, hasWAList, item, changeHandler, onTabChange],
  )

  const disabledText = getRegisterTemplateDisabledReason(item)

  return (
    <div>
      <div>
        <WhatsappMessage
          {...props}
          {...props.item.message}
          autoFocus={props.item.autoFocus}
          canRegisterTemplate
          enableMediaCaption
          hasButtons={hasWAButtons}
          hasList={hasWAList}
          changeHandler={handleChange}
          onTabChange={handleTabChange}
          saveHandler={saveHandler}
          removeMedia={removeMedia}
        />
        <Box sx={{ marginTop: 2 }}>
          <WhatsappTemplateCheckbox
            disabledText={disabledText}
            registerTemplate={!!props.item.message?.registerTemplate}
            onChange={(value) =>
              changeHandler({
                ...props.item,
                message: {
                  ...props.item.message,
                  registerTemplate: value,
                },
              })
            }
          />
        </Box>
      </div>
    </div>
  )
}

const hasButtons = (item: MessageType) => {
  if (!item.hasQuestion) {
    return false
  }
  return !!item.onButtons
}
const hasList = (item: MessageType) => {
  if (!item.hasQuestion) {
    return false
  }
  return !!item.onList
}

export default CampaignContentWhatsapp
