/* eslint-disable max-len */
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { find, omit, pathOr, propEq } from 'ramda'
import React from 'react'
import { withStyles } from 'tss-react/mui'

import { Checkbox } from '@/app/component/atom/form'
import SearchableSelect from '@/app/component/atom/form/searchable-select'
import Loading from '@/app/component/guard/loading'
import IconText from '@/app/component/layout/icon-text'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import Delay from '@/app/module/campaigns/component/item/manage/subscriptions/delay'
import { displayMap, Q_ACTIONS } from '@/app/module/campaigns/definitions'
import { selectTimezones } from '@/app/module/campaigns/store/selectors'

const styles = () => ({
  actions: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    padding: '24px',
  },
})

const unwrapValue = (value) =>
  pathOr(
    {
      campaignId: '',
      campaignName: '',
      allowResubscribe: false,
      delay: {},
    },
    ['subscribeToCampaign'],
    value,
  )

export const getCampaignName = ({ campaignId, data }) => {
  const foundCampaign = find(propEq('value', campaignId), data)
  if (foundCampaign) {
    return foundCampaign.label
  }
  return ''
}

const SubscribeToCampaign = (props) => {
  const { orgId, token, getCampaigns, campaignIds, timezone, countryCode, timezones, countriesTimezones } = props

  const {
    campaignId: initialCampaignId,
    campaignName,
    allowResubscribe: initialAllowResubscribe,
    delay: initialDelay = {},
  } = unwrapValue(props.value)
  const [campaignId, setCampaignId] = React.useState(initialCampaignId)
  const country = initialDelay.country || countryCode
  const defaultDelay = {
    unit: 'immediate',
    value: 0,
    dayOfWeek: '',
    daysOfWeek: {},
    time: '',
    timezone,
    timezones: selectTimezones(pathOr([], [country, 'tzs'], timezones)),
    country,
    at: null,
    valueError: '',
    ...initialDelay,
  }
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState(campaignIds)
  const [delay, setDelay] = React.useState(defaultDelay)

  const [allowResubscribe, setAllowResubscribe] = React.useState(initialAllowResubscribe)
  const { onClose, onSave } = props

  React.useEffect(() => {
    setLoading(true)
    getCampaigns({
      orgId,
      token,
      query: {
        page: 1,
        size: 100,
        aborted: 'false',
        archived: 'false',
      },
    }).then(() => setLoading(false))
  }, [orgId, token, getCampaigns])

  React.useEffect(() => {
    if (campaignId && campaignIds.length > 0 && !find(propEq('value', campaignId), campaignIds)) {
      setData([
        {
          label: campaignName || `Campaign ${campaignId}`,
          value: campaignId,
        },
        ...campaignIds,
      ])
    } else {
      setData(campaignIds)
    }
  }, [campaignIds, campaignName, campaignId])

  return (
    <CustomDialog open={true} onClose={onClose}>
      <Loading isLoading={loading}>
        <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <IconText>
              <Icon>{displayMap.subscribeToCampaign.icon}</Icon>
              <Typography variant="h6">{Q_ACTIONS.subscribeToCampaign.label}</Typography>
            </IconText>
            <IconButton onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ paddingTop: '0px' }}>
          <div
            style={{
              width: '80vw',
              minWidth: '300px',
              maxWidth: '100%',
            }}
          >
            <form
              id="send-message-form"
              onSubmit={(e) => {
                e.preventDefault()
              }}
            >
              <CardContent>
                <SubscribeSection className="subscribe-contact-campaign-select">
                  <SearchableSelect
                    formControlProps={{
                      style: {
                        margin: 'none',
                        width: '100%',
                      },
                    }}
                    hideRemoveSelection={true}
                    labelId="select-campaign-label"
                    value={campaignId}
                    keyPropFn={(option) => option.value}
                    valuePropFn={(option) => option.label}
                    onChange={(selectedId) => {
                      setCampaignId(selectedId)
                    }}
                    id="select-campaign"
                    label="Select Campaign"
                    options={data}
                    helperText="Only the 100 most recent campaigns are shown."
                    showAll
                  />
                </SubscribeSection>
                <SectionDivider />
                <SubscribeSection className="subscribe-contact-delay">
                  <Typography color="primary" variant="subtitle2">
                    When should the subscription start?
                  </Typography>
                  <Delay
                    isFirst={true}
                    published={false}
                    item={delay}
                    changeHandler={setDelay}
                    timezones={timezones}
                    countriesTimezones={countriesTimezones}
                    setLoading={() => {}}
                    type="message"
                    text=""
                  />
                </SubscribeSection>
                <SectionDivider />
                <SubscribeSection className="subscribe-contact-resubscribe">
                  <Typography color="primary" variant="subtitle2">
                    Resubscription
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    Once a contact is subscribed to the selected campaign, they will not be subscribed again by this
                    action, unless you check the box below.
                  </Typography>
                  <div
                    style={{
                      marginTop: '15px',
                    }}
                  >
                    <Checkbox
                      label="Allow resubscribe (allow a contact to be subscribed to the selected campaign again by this action)"
                      value={allowResubscribe ? 'true' : 'false'}
                      editable={true}
                      onChange={({ value }) => {
                        setAllowResubscribe(value === 'true')
                      }}
                    />
                  </div>
                </SubscribeSection>
              </CardContent>
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={!campaignId}
            onClick={() =>
              onSave({
                subscribeToCampaign: {
                  campaignId,
                  campaignName: getCampaignName({ campaignId, data }),
                  allowResubscribe,
                  ...(delay.unit !== 'immediate' && {
                    delay: omit(['timezones', 'valueError'], delay),
                  }),
                },
              })
            }
          >
            Save
          </Button>
        </DialogActions>
      </Loading>
    </CustomDialog>
  )
}

export default withStyles(SubscribeToCampaign, styles)

const SubscribeSection = ({ children, className }) => <div className={className}>{children}</div>

const SectionDivider = () => (
  <Divider
    style={{
      marginLeft: '-45px',
      marginRight: '-45px',
      marginTop: '15px',
      marginBottom: '15px',
    }}
  />
)
