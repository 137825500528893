import { Card, CardContent, CardHeader, Grid, Icon, Typography } from '@mui/material'
import { find, map, path, pathOr, propEq } from 'ramda'
import React from 'react'

import ErrorMessage from '@/app/component/atom/error-message'
import { Select } from '@/app/component/atom/form'
import { DEFAULT_SENDER_ID_NAME, DEFAULT_SENDER_ID_VALUE } from '@/app/definitions'
import { SENDER_ID_MAP, SENDER_ID_TYPES, TYPES } from '@/app/module/campaigns/definitions'
import { getMessageTypesCount } from '@/app/module/campaigns/message-type-helpers'
import { checkIfCallerIsValid, selectSenderIdsByType } from '@/app/module/campaigns/store/selectors'
import {
  CampaignTemplateType,
  MessagesState,
  MultimessageItem,
  SenderIdOption,
  SenderIdTypes,
} from '@/app/module/campaigns/types'

import SenderIdSelect from './sender-id-select'

type Props = {
  item: MultimessageItem
  messages: MessagesState
  onChange: (item: Partial<MultimessageItem>) => void
}

const PhoneNumber: React.FC<Props> = ({ item, messages, onChange }) => {
  const totalMessageTypes = React.useMemo(() => {
    const messageTypes = getMessageTypesCount(item)
    if (item.type === 'whatsappsurvey' && !messageTypes.length) {
      messageTypes.push({
        count: 1,
        label: 'WhatsApp Number',
        name: 'whatsapp',
        type: 'whatsapp',
      })
    }

    return messageTypes
  }, [item])

  const campaignType = pathOr<string>('', ['type'], item)
  const senderIdInfo = pathOr('', [campaignType.toUpperCase(), 'senderIdInfo'], TYPES)

  const newMessages: MessagesState & {
    defaultSenderId: SenderIdOption[]
  } = {
    ...messages,
    defaultSenderId: [
      {
        label: DEFAULT_SENDER_ID_NAME,
        value: DEFAULT_SENDER_ID_VALUE,
        type: 'sender',
      },
    ],
  }

  const smsSenderIds = pathOr([], ['senderIdsForDrip', 'sms'], newMessages)
  const whatsappSenderIds = pathOr([], ['senderIdsForDrip', 'whatsapp'], newMessages)
  const showSenderIdReplies = find(
    propEq('sender', pathOr('', ['variables', SenderIdTypes.SenderIdQuestions], item)),
    smsSenderIds,
  )

  return (
    <Card style={{ marginBottom: '2rem' }}>
      <CardHeader title="Sender / Caller IDs" style={{ padding: '12px 24px' }} />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {totalMessageTypes.length > 0 ? (
              <Typography variant="body1" gutterBottom={true}>
                {senderIdInfo}
              </Typography>
            ) : (
              <Typography variant="body1" gutterBottom={true}>
                Your campaign does not make calls and it does not send any SMS or WhatsApp messages, so you do not need
                to configure anything here. In case you meant to send messages or make calls in this campaign, please go
                back to the Content step and add them. Otherwise, continue to the next step.
              </Typography>
            )}
          </Grid>
        </Grid>
        <React.Fragment>
          {totalMessageTypes.map((messageType, i) => (
            <Grid container spacing={1} key={messageType.type}>
              <Grid item xs={12}>
                {SENDER_ID_MAP[messageType.type]?.map((type, j) => {
                  const hasSenderId = type !== SenderIdTypes.SenderIdReplies || showSenderIdReplies
                  const needsSenderId = !(
                    (type === SenderIdTypes.SenderIdQuestions && !smsSenderIds.length) ||
                    (type === SenderIdTypes.SenderIdWhatsapp && !whatsappSenderIds.length)
                  )
                  return (
                    <Grid container spacing={1} key={`${messageType.type}-${type}-${j}`}>
                      {type === SenderIdTypes.SenderIdReplies && showSenderIdReplies && (
                        <Grid item xs={5}>
                          <Typography variant="body1" gutterBottom={false} style={{ padding: '6px 10 7px' }}>
                            {`${pathOr('', [type, 'label'], SENDER_ID_TYPES)}`}
                          </Typography>
                        </Grid>
                      )}
                      {type !== SenderIdTypes.SenderIdReplies && (
                        <Grid item xs={5}>
                          <Typography variant="body1" gutterBottom={false} style={{ padding: '6px 0 7px' }}>
                            <b>{messageType.label}</b>
                          </Typography>
                        </Grid>
                      )}

                      {hasSenderId && !needsSenderId && (
                        <Grid item xs={7}>
                          <Typography variant="caption" gutterBottom={false} style={{ padding: '6px 10 7px' }}>
                            {noSenderIdMessage[type]}
                          </Typography>
                        </Grid>
                      )}
                      {hasSenderId && needsSenderId && type === SenderIdTypes.CallerIds && (
                        <Grid item xs={4}>
                          <Select
                            className="campaign-item-senderid"
                            error={checkIfCallerIsValid(item) ? '' : 'You must select a caller ID.'}
                            id="campaign-item-callerId"
                            data-testid={`callerId-select-${i}-${j}`}
                            name={`callerId-select-${i}-${j}`}
                            label="Select Caller ID"
                            editable={true}
                            info="If you select more than one caller ID, we will randomly rotate the caller ID we use for each call."
                            value={getCallerIdsValue(item)}
                            values={selectSenderIdsByType({
                              messageType,
                              newMessages,
                              type,
                            })}
                            multiple={true}
                            onChange={({ value }: { value: string[] }) => {
                              /**
                               * WORKAROUND: have to do this to get around a compiler error since onChange param type for item is a union type
                               * typescript doesn't know if the param passed to onChange is a DripCampaign or IVRCampaign if we do it like this:
                               * if (item.type === CampaignTemplateType.Drip || item.type === CampaignTemplateType.IVR) {
                               *   onChange({...})
                               * }
                               */
                              switch (item.type) {
                                case CampaignTemplateType.Drip:
                                  onChange({
                                    ...item,
                                    variables: {
                                      ...item.variables,
                                      callerIds: value,
                                      ...(value.length === 0 && {
                                        callerId: '',
                                      }),
                                    },
                                  })
                                  break

                                case CampaignTemplateType.VoiceSurvey:
                                  onChange({
                                    ...item,
                                    variables: {
                                      ...item.variables,
                                      callerIds: value,
                                      ...(value.length === 0 && {
                                        callerId: '',
                                      }),
                                    },
                                  })
                                  break

                                default:
                                  break
                              }
                            }}
                          />
                        </Grid>
                      )}
                      {hasSenderId && needsSenderId && type !== SenderIdTypes.CallerIds && (
                        <SenderIdSelect
                          index={j}
                          item={item}
                          messageIndex={i}
                          messageType={messageType}
                          messages={newMessages}
                          type={type}
                          onChange={onChange}
                        />
                      )}
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          ))}
        </React.Fragment>
      </CardContent>
    </Card>
  )
}

const noSenderIdMessage = {
  senderIdQuestions: (
    <>
      SMS Questions need to have a virtual phone number that your contacts can reply to. But you currently do not have
      one. <br />
      <ErrorMessage>
        <Icon fontSize="small">warning</Icon>Please contact support to order one.
      </ErrorMessage>
    </>
  ),
  senderIdWhatsapp: (
    <>
      WhatsApp Messages & Questions need to have a virtual phone number. But you currently do not have one. <br />
      <ErrorMessage>
        <Icon fontSize="small">warning</Icon>Please contact support to order one.
      </ErrorMessage>
    </>
  ),
}

const convertBackToDefault = (list: string[] = []) =>
  map((listItem) => (listItem === '' ? DEFAULT_SENDER_ID_VALUE : listItem), list)
const getCallerIdsValue = (item: MultimessageItem) => {
  const callerIds = pathOr([], ['variables', SenderIdTypes.CallerIds], item)

  if (callerIds.length > 0) {
    return convertBackToDefault(callerIds)
  }
  const callerId = path(['variables', SenderIdTypes.CallerId], item)
  if (typeof callerId === 'string') {
    return convertBackToDefault([callerId])
  }
  return []
}

export default PhoneNumber
