import {
  ACTION_CALLS_PAGE_SIZE,
  ACTION_CLEAR_CALLS,
  ACTION_CLEAR_MESSAGES,
  ACTION_CLEAR_TOPUPS,
  ACTION_CLEAR_WHATSAPP,
  ACTION_DOWNLOAD_CALLS_FAILURE,
  ACTION_DOWNLOAD_CALLS_REQUEST,
  ACTION_DOWNLOAD_CALLS_SUCCESS,
  ACTION_DOWNLOAD_MESSAGES_FAILURE,
  ACTION_DOWNLOAD_MESSAGES_REQUEST,
  ACTION_DOWNLOAD_MESSAGES_SUCCESS,
  ACTION_DOWNLOAD_TOPUPS_FAILURE,
  ACTION_DOWNLOAD_TOPUPS_REQUEST,
  ACTION_DOWNLOAD_TOPUPS_SUCCESS,
  ACTION_DOWNLOAD_WHATSAPP_FAILURE,
  ACTION_DOWNLOAD_WHATSAPP_REQUEST,
  ACTION_DOWNLOAD_WHATSAPP_SUCCESS,
  ACTION_GET_CALLS_FAILURE,
  ACTION_GET_CALLS_FILTER,
  ACTION_GET_CALLS_REQUEST,
  ACTION_GET_CALLS_SUCCESS,
  ACTION_GET_MESSAGES_FAILURE,
  ACTION_GET_MESSAGES_FILTER,
  ACTION_GET_MESSAGES_REQUEST,
  ACTION_GET_MESSAGES_SUCCESS,
  ACTION_GET_SENDER_IDS_FAILURE,
  ACTION_GET_SENDER_IDS_REQUEST,
  ACTION_GET_SENDER_IDS_SUCCESS,
  ACTION_GET_TOPUPS_FAILURE,
  ACTION_GET_TOPUPS_FILTER,
  ACTION_GET_TOPUPS_REQUEST,
  ACTION_GET_TOPUPS_SUCCESS,
  ACTION_GET_WHATSAPP_FAILURE,
  ACTION_GET_WHATSAPP_FILTER,
  ACTION_GET_WHATSAPP_REQUEST,
  ACTION_GET_WHATSAPP_SUCCESS,
  ACTION_LOGS_NAVIGATE,
  ACTION_LOGS_PAGE_SIZE,
  ACTION_SAVE_WHATSAPP_FILE_FAILURE,
  ACTION_SAVE_WHATSAPP_FILE_REQUEST,
  ACTION_SAVE_WHATSAPP_FILE_SUCCESS,
  ACTION_SEND_MESSAGE_FAILURE,
  ACTION_SEND_MESSAGE_REQUEST,
  ACTION_SEND_MESSAGE_SUCCESS,
  ACTION_SET_SEND_MESSAGE,
  ACTION_TOPUPS_PAGE_SIZE,
  ACTION_WHATSAPP_PAGE_SIZE,
} from '@/app/module/logs/definitions'
import { createReducer } from '@/app/service/util'

import defaultState from './state'

export default createReducer(defaultState, {
  [ACTION_GET_MESSAGES_REQUEST]: (state) => ({
    ...state,
    loading: true,
    error: defaultState.error,
  }),
  [ACTION_GET_MESSAGES_SUCCESS]: (state, { value }) => ({
    ...state,
    loading: false,
    error: defaultState.error,
    data: {
      ...state.data,
      messages: [...state.data.messages, ...(value.messages || [])],
      totalCount: value.totalCount,
      pageInfo: {
        endCursor: value.nextPage,
        hasNextPage: value.hasNextPage,
      },
    },
  }),
  [ACTION_GET_MESSAGES_FAILURE]: (state, { value }) => ({
    ...state,
    loading: false,
    error: value,
    data: {
      ...state.data,
      messages: defaultState.data.messages,
      totalCount: defaultState.data.totalCount,
      pageInfo: defaultState.data.pageInfo,
    },
  }),

  // download messages
  [ACTION_DOWNLOAD_MESSAGES_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [ACTION_DOWNLOAD_MESSAGES_SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [ACTION_DOWNLOAD_MESSAGES_FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),

  [ACTION_CLEAR_MESSAGES]: (state) => ({
    ...state,
    error: defaultState.error,
    data: {
      ...state.data,
      messages: defaultState.data.messages,
      totalCount: defaultState.data.totalCount,
      pageInfo: defaultState.data.pageInfo,
    },
  }),
  [ACTION_GET_MESSAGES_FILTER]: (state, { value }) => ({
    ...state,
    data: {
      ...state.data,
      filters: {
        ...state.data.filters,
        ...value,
      },
    },
  }),
  [ACTION_SET_SEND_MESSAGE]: (state, { value }) => ({
    ...state,
    data: {
      ...state.data,
      sendMessage: {
        ...state.data.sendMessage,
        ...value,
      },
    },
  }),
  [ACTION_SEND_MESSAGE_REQUEST]: (state) => ({
    ...state,
    loading: true,
    error: defaultState.error,
  }),
  [ACTION_SEND_MESSAGE_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    error: defaultState.error,
  }),
  [ACTION_SEND_MESSAGE_FAILURE]: (state, { value }) => ({
    ...state,
    loading: false,
    error: value,
  }),
  [ACTION_GET_SENDER_IDS_REQUEST]: (state) => ({
    ...state,
    loading: true,
    error: defaultState.error,
  }),
  [ACTION_GET_SENDER_IDS_SUCCESS]: (state, { value }) => ({
    ...state,
    loading: false,
    error: defaultState.error,
    data: {
      ...state.data,
      senderIds: value,
    },
  }),
  [ACTION_GET_SENDER_IDS_FAILURE]: (state, { value }) => ({
    ...state,
    loading: false,
    error: value,
    data: {
      ...state.data,
      senderIds: defaultState.data.senderIds,
    },
  }),
  [ACTION_LOGS_PAGE_SIZE]: (state, { value }) => ({
    ...state,
    data: {
      ...state.data,
      pageSize: value || state.data.pageSize,
    },
  }),
  [ACTION_LOGS_NAVIGATE]: (state, { value }) => ({
    ...state,
    error: defaultState.error,
    data: {
      ...state.data,
      messages: defaultState.data.messages,
      totalCount: defaultState.data.totalCount,
      pageInfo: defaultState.data.pageInfo,
      ...value,
    },
  }),
  [ACTION_GET_CALLS_REQUEST]: (state) => ({
    ...state,
    loading: true,
    error: defaultState.error,
  }),
  [ACTION_GET_CALLS_SUCCESS]: (state, { value }) => ({
    ...state,
    loading: false,
    error: defaultState.error,
    data: {
      ...state.data,
      calls: [...state.data.calls, ...(value.calls || [])],
      totalCount: value.totalCount,
      pageInfo: {
        endCursor: value.nextPage,
        hasNextPage: value.hasNextPage,
      },
    },
  }),
  [ACTION_GET_CALLS_FAILURE]: (state, { value }) => ({
    ...state,
    loading: false,
    error: value,
    data: {
      ...state.data,
      calls: defaultState.data.calls,
      totalCount: defaultState.data.totalCount,
      pageInfo: defaultState.data.pageInfo,
    },
  }),

  // download call log
  [ACTION_DOWNLOAD_CALLS_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [ACTION_DOWNLOAD_CALLS_SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [ACTION_DOWNLOAD_CALLS_FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [ACTION_CLEAR_CALLS]: (state) => ({
    ...state,
    error: defaultState.error,
    data: {
      ...state.data,
      calls: defaultState.data.calls,
      totalCount: defaultState.data.totalCount,
      pageInfo: defaultState.data.pageInfo,
    },
  }),
  [ACTION_GET_CALLS_FILTER]: (state, { value }) => ({
    ...state,
    data: {
      ...state.data,
      filters: {
        ...state.data.filters,
        ...value,
      },
    },
  }),
  [ACTION_CALLS_PAGE_SIZE]: (state, { value }) => ({
    ...state,
    data: {
      ...state.data,
      pageSize: value || state.data.pageSize,
    },
  }),
  [ACTION_GET_WHATSAPP_REQUEST]: (state) => ({
    ...state,
    loading: true,
    error: defaultState.error,
  }),
  [ACTION_GET_WHATSAPP_SUCCESS]: (state, { value }) => ({
    ...state,
    loading: false,
    error: defaultState.error,
    data: {
      ...state.data,
      whatsapp: [...state.data.whatsapp, ...(value.whatsapp || [])],
      totalCount: value.totalCount,
      pageInfo: {
        endCursor: value.nextPage,
        hasNextPage: value.hasNextPage,
      },
    },
  }),
  [ACTION_GET_WHATSAPP_FAILURE]: (state, { value }) => ({
    ...state,
    loading: false,
    error: value,
    data: {
      ...state.data,
      whatsapp: defaultState.data.whatsapp,
      totalCount: defaultState.data.totalCount,
      pageInfo: defaultState.data.pageInfo,
    },
  }),

  // download whatsapp log
  [ACTION_DOWNLOAD_WHATSAPP_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [ACTION_DOWNLOAD_WHATSAPP_SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [ACTION_DOWNLOAD_WHATSAPP_FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [ACTION_CLEAR_WHATSAPP]: (state) => ({
    ...state,
    error: defaultState.error,
    data: {
      ...state.data,
      whatsapp: defaultState.data.whatsapp,
      totalCount: defaultState.data.totalCount,
      pageInfo: defaultState.data.pageInfo,
    },
  }),
  [ACTION_GET_WHATSAPP_FILTER]: (state, { value }) => ({
    ...state,
    data: {
      ...state.data,
      filters: {
        ...state.data.filters,
        ...value,
      },
    },
  }),
  [ACTION_WHATSAPP_PAGE_SIZE]: (state, { value }) => ({
    ...state,
    data: {
      ...state.data,
      pageSize: value || state.data.pageSize,
    },
  }),
  [ACTION_SAVE_WHATSAPP_FILE_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [ACTION_SAVE_WHATSAPP_FILE_SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [ACTION_SAVE_WHATSAPP_FILE_FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  // Topups
  [ACTION_GET_TOPUPS_REQUEST]: (state) => ({
    ...state,
    loading: true,
    error: defaultState.error,
  }),
  [ACTION_GET_TOPUPS_SUCCESS]: (state, { value }) => ({
    ...state,
    loading: false,
    error: defaultState.error,
    data: {
      ...state.data,
      topups: [...state.data.topups, ...(value.topups || [])],
      totalCount: value.totalCount,
      pageInfo: {
        endCursor: value.nextPage,
        hasNextPage: value.hasNextPage,
      },
    },
  }),
  [ACTION_GET_TOPUPS_FAILURE]: (state, { value }) => ({
    ...state,
    loading: false,
    error: value,
    data: {
      ...state.data,
      topups: defaultState.data.topups,
      totalCount: defaultState.data.totalCount,
      pageInfo: defaultState.data.pageInfo,
    },
  }),

  // download topups log
  [ACTION_DOWNLOAD_TOPUPS_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [ACTION_DOWNLOAD_TOPUPS_SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [ACTION_DOWNLOAD_TOPUPS_FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [ACTION_CLEAR_TOPUPS]: (state) => ({
    ...state,
    error: defaultState.error,
    data: {
      ...state.data,
      topups: defaultState.data.topups,
      totalCount: defaultState.data.totalCount,
      pageInfo: defaultState.data.pageInfo,
    },
  }),
  [ACTION_GET_TOPUPS_FILTER]: (state, { value }) => ({
    ...state,
    data: {
      ...state.data,
      filters: {
        ...state.data.filters,
        ...value,
      },
    },
  }),
  [ACTION_TOPUPS_PAGE_SIZE]: (state, { value }) => ({
    ...state,
    data: {
      ...state.data,
      pageSize: value || state.data.pageSize,
    },
  }),
})
