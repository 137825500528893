import { CountryCode } from 'libphonenumber-js'

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })
export const getCountryName = (code: string) => {
  try {
    return regionNames.of(code)
  } catch {
    return ''
  }
}

export const isCountryCode = (code: string): code is CountryCode => !!getCountryName(code)
