import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { path, pathOr } from 'ramda'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import Phone from '@/app/component/atom/form/phone'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import createHydrated from '@/app/component/wrapper/hydratable'
import PlanBlock from '@/app/module/dashboard/component/plan-block'

import DemoRow from './item'

const trimFirstPlus = (phonenumber) => {
  if (phonenumber.charAt(0) === '+') {
    return phonenumber.substring(1)
  }
  return phonenumber
}

function Demos(props) {
  const { classes } = useStyles()
  const { profile, demos = [], specs = {}, selectDemo, token, isLoading } = props

  const countries = pathOr([], ['fullPhoneNumber', 'countries'], specs)

  const [selectedDemo, setSelectedDemo] = React.useState(null)
  const [firstname, setFirstname] = React.useState(pathOr('', ['firstName'], profile))
  const [phonenumber, setPhonenumber] = React.useState(trimFirstPlus(pathOr('', ['phone'], profile)))

  React.useEffect(() => {
    setFirstname(pathOr('', ['firstName'], profile))
    setPhonenumber(trimFirstPlus(pathOr('', ['phone'], profile)))
  }, [profile])

  return (
    <PlanBlock isLoading={isLoading} title="Try one of the demos below!">
      <List dense>
        {demos.map((item, index) => (
          <React.Fragment key={path(['id'], item)}>
            <DemoRow item={item} selectedDemo={selectedDemo} setSelected={setSelectedDemo} />
            {index < demos.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
      {selectedDemo && (
        <CustomDialog id="select-demo-dialog" open={!!selectedDemo} onClose={() => setSelectedDemo(null)}>
          <DialogTitle>
            <Typography variant="h6">Demo</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid item md={12} className={classes.leftGrid}>
              <form className={classes.root} noValidate autoComplete="off">
                <Typography>To start the demo please confirm your first name and phone number</Typography>
                <FormControl variant="standard" fullWidth className={classes.margin}>
                  <InputLabel htmlFor="demo-firstname">Firstname</InputLabel>
                  <Input
                    id="demo-firstname"
                    value={firstname}
                    onChange={(e) => {
                      setFirstname(e.currentTarget.value)
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <Icon>person</Icon>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl variant="standard" fullWidth className={classes.margin}>
                  <Phone
                    label="Phone number"
                    countries={countries}
                    value={phonenumber}
                    onChange={({ value }) => {
                      setPhonenumber(value)
                    }}
                  />
                </FormControl>
                <Grid className={classes.margin} container spacing={2}>
                  <Grid item md={6}>
                    <Button
                      variant="contained"
                      className={classes.formButton}
                      onClick={() => {
                        setSelectedDemo(null)
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item md={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.formButton}
                      onClick={() => {
                        selectDemo({
                          itemId: path(['id'], selectedDemo),
                          token,
                          item: {
                            firstName: firstname,
                            fullPhonenumber: phonenumber,
                          },
                        }).then(() => {
                          setSelectedDemo(null)
                        })
                      }}
                    >
                      Try it out now!
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </DialogContent>
        </CustomDialog>
      )}
    </PlanBlock>
  )
}

const useStyles = makeStyles()(() => ({
  leftGrid: {},
  margin: {
    marginTop: '20px',
  },
  formButton: {
    width: '100%',
  },
}))

export default createHydrated(Demos)
