import { z } from 'zod'

import { createTranslations } from '@/app/module/campaigns/types/schemas/common'
import ZTextContent from '@/app/module/campaigns/types/schemas/message-content/text'
import ZOnAnswer from '@/app/module/campaigns/types/schemas/response/on-answer'
import ZOnInvalidReply from '@/app/module/campaigns/types/schemas/response/on-invalid-reply'
import ZOnRetriesExhausted from '@/app/module/campaigns/types/schemas/response/on-retries-exhausted'
import ZOnTimeout from '@/app/module/campaigns/types/schemas/response/on-timeout'
import ZOpenEnded from '@/app/module/campaigns/types/schemas/response/open-ended'

import ZBasePart from './base'

const ZSMSQuestionPart = ZBasePart.extend({
  smsQuestion: z.object({
    message: ZTextContent.extend({
      translations: createTranslations(ZTextContent).optional(),
    }),
    onAnswer: ZOnAnswer.nullable().optional(),
    onInvalidReply: ZOnInvalidReply,
    onRetriesExhausted: ZOnRetriesExhausted,
    onTimeout: ZOnTimeout,
    openEnded: ZOpenEnded.nullable().optional(),
    questionId: z.string().optional(),
    retries: z.number(),
    stateId: z.string().optional(),
    useAi: z.boolean(),
  }),
})

export default ZSMSQuestionPart
