import {
  ActionsOnly,
  AnswerCategory,
  ConditionActions,
  ContentAPI,
  ContentTopup,
  OnAnswerType,
  OnInvalidReply,
  OnRetriesExhausted,
  OpenEnded as OpenEndedActions,
  Spoken,
  OnTimeout as TimeoutActions,
  TWhatsAppButtonItem,
  TWhatsAppButtons,
  TWhatsAppList,
  TWhatsAppListItem,
} from '@/app/module/campaigns/types'

export enum ActionContext {
  Default = 'default',
  OnAnswer = 'answer',
  OnInvalid = 'invalid',
  OnTimeout = 'timeout',
  OnFailure = 'failure',
  OpenEnded = 'openEnded',
  OpenEndedCategory = 'openEndedCategory',
  OnButtons = 'onButtons',
  OnList = 'onList',
  TopupSuccess = 'topupSuccess',
  TopupFailure = 'topupFailure',
  ApiSuccess = 'apiSuccess',
  ApiFailure = 'apiFailure',
  CallAnswered = 'callAnswered',
  CallNotAnswered = 'callNotAnswered',
  CallError = 'callError',
  Inbound = 'inbound',
  Reconnect = 'reconnect',
  OnTransferFailed = 'transferFailed',
  ActionOnly = 'actionsOnly',
}

export type ActionsData = {
  actionsOnly?: ActionsOnly
  onAnswer?: OnAnswerType[]
  onInvalidReply?: OnInvalidReply
  onRetriesExhausted?: OnRetriesExhausted
  onTimeout?: TimeoutActions
  openEnded?: OpenEndedActions
  spoken?: Spoken
  onButtons?: TWhatsAppButtons
  onList?: TWhatsAppList
  topup?: ContentTopup
  apiCall?: ContentAPI
  callResultActions?: ConditionActions[]
}

export type WithActionsObject =
  | ActionsOnly
  | OnInvalidReply
  | OnRetriesExhausted
  | TimeoutActions
  | OpenEndedActions
  | OnAnswerType
  | TWhatsAppButtonItem
  | TWhatsAppListItem
  | AnswerCategory
  | ConditionActions
