import { find, has, path, pathOr, propEq } from 'ramda'

import { cannot } from '@/app/helpers'

import {
  getActions,
  getPartType,
  isContentAudio,
  isContentText,
  nestedActions,
} from './component/item/steps/content/types/multimessage/content'
import { hasContent, SenderIdType } from './helpers'
import {
  CampaignItem,
  CampaignTemplateType,
  MultimessageItem,
  PartFamily,
  PartTypes,
  PartVoiceMessage,
  PartVoiceQuestion,
  PartWhatsAppMessage,
  PartWhatsAppQuestion,
  SenderIdTypes,
} from './types'
import { Action } from './types/schemas/actions/all-actions'

export type MessageTypeCount = {
  count: number
  label: string
  name: string
  type: string
}

export const hasContentVoice = (item: PartVoiceMessage | PartVoiceQuestion) => {
  return hasContent(item.audio, 'playfile') || hasContent(item.audio, 'say') || hasContent(item.audio, 'voice')
}

export const hasContentWhatsapp = (item: PartWhatsAppMessage | PartWhatsAppQuestion) => {
  return (
    hasContent(item.message, 'text') ||
    hasContent(item.message, 'document') ||
    hasContent(item.message, 'image') ||
    hasContent(item.message, 'audio') ||
    hasContent(item.message, 'video')
  )
}

export const countMessageTypesInActions = (actions: Action[], totalCounts: TypeCountMap): TypeCountMap => {
  const typeCounts: TypeCountMap = {
    ...totalCounts,
  }
  actions.forEach((action) => {
    if (has('sendSMS', action)) {
      typeCounts.smsMessage += 1
    } else if (has('sendSMSToPhoneNumbers', action)) {
      typeCounts.smsMessage += 1
    } else if (has('sendWhatsapp', action)) {
      typeCounts.whatsapp += 1
    } else if (has('play', action)) {
      typeCounts.voiceCall += 1
    }
  })
  return typeCounts
}

type TypeCountMap = {
  smsMessage: number
  smsQuestion: number
  voiceCall: number
  whatsapp: number
}

export const getMessageTypesCount = (item?: MultimessageItem): MessageTypeCount[] => {
  if (!item?.variables) {
    return []
  }

  let typeCountsMap: TypeCountMap = {
    smsMessage: 0,
    smsQuestion: 0,
    voiceCall: 0,
    whatsapp: 0,
  }
  const { type, variables } = item

  if (variables) {
    let allActions: Action[] = []

    if (type === CampaignTemplateType.SMSSurvey || type === CampaignTemplateType.VoiceSurvey) {
      const { welcome } = variables
      if (welcome) {
        if (isContentText(welcome)) {
          typeCountsMap.smsMessage += 1
        } else if (isContentAudio(welcome)) {
          typeCountsMap.voiceCall += 1
        } else {
          cannot(welcome)
        }
      }
    }

    if (type === CampaignTemplateType.VoiceSurvey) {
      const { callResultActions, reconnectActions } = variables
      if (callResultActions?.length) {
        allActions = allActions.concat(nestedActions(callResultActions))
      }
      if (reconnectActions?.length) {
        allActions = allActions.concat(nestedActions(reconnectActions))
      }
    }

    const { parts } = variables

    parts.forEach((part) => {
      // Get all actions in part
      const allPartActions = getActions(part)
      allActions = allActions.concat(allPartActions)

      const partType = getPartType(part)

      switch (partType) {
        case PartTypes.SMSMessage:
          if (has(partType, part) && hasContent(part.smsMessage.message, 'text')) {
            // have to include the has partType check since compiler complains
            typeCountsMap.smsMessage += 1
          }
          return

        case PartTypes.SMSQuestion:
          if (has(partType, part) && hasContent(part.smsQuestion.message, 'text')) {
            typeCountsMap.smsQuestion += 1
          }
          return

        case PartTypes.VoiceMessage:
          if (has(partType, part) && hasContentVoice(part.voiceMessage)) {
            typeCountsMap.voiceCall += 1
          }
          return

        case PartTypes.VoiceQuestion:
          if (has(partType, part) && hasContentVoice(part.voiceQuestion)) {
            typeCountsMap.voiceCall += 1
          }
          return

        case PartTypes.WhatsAppMessage:
          if (has(partType, part) && hasContentWhatsapp(part.whatsappMessage)) {
            typeCountsMap.whatsapp += 1
          }
          return

        case PartTypes.WhatsAppQuestion:
          if (has(partType, part) && hasContentWhatsapp(part.whatsappQuestion)) {
            typeCountsMap.whatsapp += 1
          }
          return

        case PartTypes.ActionsOnly:
        case PartTypes.ApiCall:
        case PartTypes.CATIMessage:
        case PartTypes.CATIQuestion:
        case PartTypes.Topup:
          /*
           * ignore because only these types are being counted: SMSMessage, SMSQuestion, VoiceMessage, VoiceQuestion, WhatsAppMessage, WhatsAppQuestion
           * since they are the only ones that require sender/caller id
           */
          return

        default:
          cannot(partType)
      }
    })

    typeCountsMap = countMessageTypesInActions(allActions, typeCountsMap)
  }

  const typeCounts = [
    {
      type: 'smsMessage',
      count: typeCountsMap.smsMessage,
      label: 'SMS Messages',
      name: PartFamily.SMS,
    },
    {
      type: 'smsQuestion',
      count: typeCountsMap.smsQuestion,
      label: 'SMS Questions',
      name: PartFamily.SMS,
    },
    {
      type: 'voiceCall',
      count: typeCountsMap.voiceCall,
      label: 'Voice Calls',
      name: PartFamily.Voice,
    },
    {
      type: 'whatsapp',
      count: typeCountsMap.whatsapp,
      label: 'WhatsApp Number',
      name: PartFamily.WhatsApp,
    },
  ]

  return typeCounts.filter((typeCount) => typeCount.count > 0)
}

export const areSenderIdsSet = ({
  item,
  availableSenderIds,
}: {
  item: CampaignItem
  availableSenderIds: Record<string, SenderIdType[]>
}) => {
  const { variables, type } = item
  const defaultSenderId = pathOr(false, ['defaultSenderId'], variables)
  const senderId = pathOr('', [SenderIdTypes.SenderId], variables)

  const senderIdSet = defaultSenderId || !!senderId

  if (type === 'smsblast') {
    return senderIdSet
  }

  const count = getMessageTypesCount(item)
  if (find(propEq('type', 'smsMessage'), count) && !senderIdSet) {
    return false
  }

  if (find(propEq('type', 'whatsapp'), count) && pathOr('', [SenderIdTypes.SenderIdWhatsapp], variables) === '') {
    return false
  }

  const callerId = path([SenderIdTypes.CallerId], variables)
  if (
    find(propEq('type', 'voiceCall'), count) &&
    pathOr([], [SenderIdTypes.CallerIds], variables).length === 0 &&
    (callerId === undefined || callerId === null)
  ) {
    return false
  }
  if (!find(propEq('type', 'smsQuestion'), count)) {
    return true
  }

  const senderIdQuestions = pathOr('', [SenderIdTypes.SenderIdQuestions], variables)
  if (find(propEq('sender', senderIdQuestions), pathOr([], ['sms'], availableSenderIds))) {
    return !!pathOr('', [SenderIdTypes.SenderIdReplies], variables)
  }
  return !!senderIdQuestions
}

export const hasVoice = (messageTypes: MessageTypeCount[]) => find(propEq('type', 'voiceCall'), messageTypes)
