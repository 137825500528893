import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Drawer, IconButton, Theme } from '@mui/material'
import { equals, move } from 'ramda'
import React, { CSSProperties } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { makeStyles } from 'tss-react/mui'

import CustomTooltip from '@/app/component/atom/tooltip'
import { Part } from '@/app/module/campaigns/types'

import CampaignNavigator from './campaign-navigator'

type Props = {
  activePart: string
  canReorder: boolean
  open: boolean
  parts: Part[]

  onClose: () => void
  onOpen: () => void
  onPartClick: (partId: string) => void
  onPartReorder: (fromIndex: number, toIndex: number) => void
}

export const CAMPAIGN_DRAWER_WIDTH = 300
export const CAMPAIGN_DRAWER_WIDTH_COLLAPSED = 20

const CampaignDrawer: React.FC<Props> = ({
  activePart,
  canReorder,
  open,
  parts,
  onClose,
  onOpen,
  onPartClick,
  onPartReorder,
}) => {
  const [localParts, setLocalParts] = React.useState<Part[]>(parts)
  const { classes, cx } = useStyle()

  React.useEffect(() => {
    setLocalParts((s) => {
      if (equals(s, parts)) {
        return s
      }
      return parts
    })
  }, [parts])

  const handleDragEnd = React.useCallback(
    (result: DropResult) => {
      if (typeof result.destination?.index !== 'number') {
        return
      }
      // do the reordering locally first, then call the callback so that the drawer don't have to wait for the parent to update
      setLocalParts((s) => {
        if (typeof result.destination?.index !== 'number' || result.source.index === result.destination.index) {
          return s
        }

        const fromIndex = result.source.index
        const toIndex = result.destination.index
        return move(fromIndex, toIndex, s)
      })

      onPartReorder(result.source.index, result.destination.index)
    },
    [onPartReorder],
  )

  return (
    <Drawer
      anchor="right"
      className={cx(classes.container, {
        [classes.containerCollapsed]: !open,
      })}
      id="campaign-drawer"
      open={open}
      variant="permanent"
    >
      {open && (
        <>
          <Box className={classes.header}>
            <IconButton onClick={onClose}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
          <Box className={classes.navigator}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <CampaignNavigator
                activePart={activePart}
                canReorder={canReorder}
                parts={localParts}
                onPartClick={onPartClick}
              />
            </DragDropContext>
          </Box>
        </>
      )}
      <CustomTooltip title="Open content navigator">
        <Box
          className={cx(classes.headerOpenCont, {
            [classes.headerOpenContCollapsed]: !open,
          })}
        >
          <IconButton className={classes.headerOpenBtn} onClick={onOpen}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
      </CustomTooltip>
    </Drawer>
  )
}

const openedMixin = (theme: Theme): CSSProperties => ({
  width: CAMPAIGN_DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
})

const closedMixin = (theme: Theme): CSSProperties => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
})

const openedButtonMixin = (theme: Theme): CSSProperties => ({
  opacity: 0,
  right: `${CAMPAIGN_DRAWER_WIDTH}px`,
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
})

const closedButtonMixin = (theme: Theme): CSSProperties => ({
  opacity: 1,
  right: `${CAMPAIGN_DRAWER_WIDTH_COLLAPSED}px`,
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
})

const useStyle = makeStyles()((theme: Theme) => ({
  container: {
    flexShrink: 0,
    overflowX: 'hidden',
    width: CAMPAIGN_DRAWER_WIDTH,
    ...openedMixin(theme),

    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      height: '100vh',
      paddingTop: '64px',
      width: CAMPAIGN_DRAWER_WIDTH,
      ...openedMixin(theme),
      display: 'flex',
      flexDirection: 'column',
    },
  },
  containerCollapsed: {
    width: CAMPAIGN_DRAWER_WIDTH_COLLAPSED,
    ...closedMixin(theme),

    '& .MuiDrawer-paper': {
      width: CAMPAIGN_DRAWER_WIDTH_COLLAPSED,
    },
    ...closedMixin(theme),
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    height: '64px',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
  },
  headerOpenBtn: {
    height: 24,
    width: 24,
    border: `1px solid ${theme.palette.divider}`,
  },
  headerOpenCont: {
    position: 'fixed',
    top: '84px',
    backgroundColor: theme.palette.highlight.light,
    borderRadius: '50%',
    transform: 'translateX(50%)',
    ...openedButtonMixin(theme),
  },
  headerOpenContCollapsed: {
    ...closedButtonMixin(theme),
  },
  navigator: {
    flex: 1,
  },
}))

export default CampaignDrawer
