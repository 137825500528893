import WhatsappIcon from '@mui/icons-material/WhatsApp'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import Tooltip from '@/app/component/atom/tooltip'
import WhatsappButtonsDialog from '@/app/component/atom/whatsapp/whatsapp-buttons-dialog'
import WhatsappListDialog from '@/app/component/atom/whatsapp/whatsapp-list-dialog'
import { cannot } from '@/app/helpers'
import { extractWhatsappContentFromPart } from '@/app/module/conversations/whatsapp-content'
import { formatCost } from '@/app/module/logs/helpers'
import { isInbound } from '@/app/module/logs/utils'
import { dateTime } from '@/app/service/util'
import { downloadFile } from '@/app/service/util/download'
import { WhatsAppLog } from '@/app/types/log'
import { FixMeLater } from '@/config/types'

import ContactPhoneDisplay from './contact-phone-display'

type Props = {
  contactDeleted: boolean
  isLoadingRow: boolean
  orgId: number
  partId: string
  sender: string
  successT: string
  token: string
  videoFiles: string[]
  windowWidth: number
  part: WhatsAppLog

  getFilesItem: (payload: { name: string; orgId: number; token: string }) => Promise<{ name: string; url: string }>
  openSendWhatsappMessageModalHandler: () => void
  setSendWhatsappMessage: (payload: { contactId: number; recipient: string; senderId: number }) => void
  viewContact: (contactId: number) => void
}

const WhatsappRow: React.FC<Props> = (props: Props) => {
  const { contactDeleted, isLoadingRow, orgId, part, token, getFilesItem, viewContact } = props
  const { contactId, created } = part
  const { classes } = useStyle()

  const [downloading, setDownloading] = React.useState(false)
  const [buttonsOpen, setButtonsOpen] = React.useState(false)
  const [listOpen, setListOpen] = React.useState(false)

  const handleSendWhatsappMessage = (recipient: string, senderId: number) => (event: any) => {
    event.preventDefault()
    const { setSendWhatsappMessage } = props
    setSendWhatsappMessage({ recipient, contactId, senderId })
    props.openSendWhatsappMessageModalHandler()
  }

  const downloadAttachment = async (url: string, file: string) => {
    setDownloading(true)
    try {
      await downloadFile(url, file, true)
    } finally {
      setDownloading(false)
    }
  }

  const isInboundType = isInbound(props)
  const contactNumber = getContactNumber(part)
  const messageStatus = getMessageStatus(part)
  const messageTime = dateTime(created)

  const handleButtonsClose = () => setButtonsOpen(false)
  const handleListClose = () => setListOpen(false)

  const messageContent = React.useMemo(() => extractWhatsappContentFromPart(part), [part])

  const renderMessage = (message: string) => (
    <Typography className={classes.truncate} variant="subtitle1" color="textPrimary">
      {message}
    </Typography>
  )

  const renderContent = () => {
    const { type, content } = messageContent

    const hasFiles = 'files' in content && !!content.files.length

    switch (type) {
      case 'message':
        return renderMessage(content.message)

      case 'image':
        return (
          <>
            {renderMessage(content.message)}
            {hasFiles && (
              <Typography className={classes.truncate} variant="subtitle1" color="textPrimary">
                Image Attachments:&nbsp;&nbsp;
                {content.files.map((file, i) => (
                  <Link
                    href="#"
                    key={i}
                    onClick={() => {
                      if (!downloading) {
                        getFilesItem({ token, orgId, name: file }).then((res: FixMeLater) => {
                          downloadAttachment(res.url, file)
                        })
                      }
                    }}
                  >
                    {file}
                  </Link>
                ))}
              </Typography>
            )}
          </>
        )

      case 'audio':
        return (
          <>
            {renderMessage(content.message)}
            {hasFiles && (
              <Typography className={classes.truncate} variant="subtitle1" color="textPrimary">
                Audio Attachments:&nbsp;&nbsp;
                {content.files.map((file, i) => (
                  <Link
                    href="#"
                    key={i}
                    onClick={() => {
                      if (!downloading) {
                        getFilesItem({ token, orgId, name: file }).then((res: FixMeLater) => {
                          downloadAttachment(res.url, file)
                        })
                      }
                    }}
                  >
                    {file}
                  </Link>
                ))}
              </Typography>
            )}
          </>
        )

      case 'video':
        return (
          <>
            {renderMessage(content.message)}
            {hasFiles && (
              <Typography className={classes.truncate} variant="subtitle1" color="textPrimary">
                Video Attachments:&nbsp;&nbsp;
                {content.files.map((file, i) => (
                  <Link
                    href="#"
                    key={i}
                    onClick={() => {
                      if (!downloading) {
                        getFilesItem({ token, orgId, name: file }).then((res: FixMeLater) => {
                          downloadAttachment(res.url, file)
                        })
                      }
                    }}
                  >
                    {file}
                  </Link>
                ))}
              </Typography>
            )}
          </>
        )

      case 'document':
        return (
          <>
            {renderMessage(content.message)}
            {hasFiles && (
              <Typography className={classes.truncate} variant="subtitle1" color="textPrimary">
                Document Attachments:&nbsp;&nbsp;
                {content.files.map((file, i) => (
                  <Link
                    href="#"
                    key={i}
                    onClick={() => {
                      if (!downloading) {
                        getFilesItem({ token, orgId, name: file }).then((res: FixMeLater) => {
                          downloadAttachment(res.url, file)
                        })
                      }
                    }}
                  >
                    {file}
                  </Link>
                ))}
              </Typography>
            )}
          </>
        )

      case 'buttons':
        return (
          <div className={classes.listContainer}>
            <Button variant="contained" onClick={() => setButtonsOpen(true)}>
              View Buttons
            </Button>
            <WhatsappButtonsDialog
              closeAfterTransition
              content={content}
              open={buttonsOpen}
              onClick={handleButtonsClose}
              onClose={handleButtonsClose}
            />
          </div>
        )

      case 'list':
        return (
          <div className={classes.listContainer}>
            <Typography className={classes.truncate} variant="subtitle1" color="textPrimary">
              Button:&nbsp;&nbsp;
            </Typography>
            <Button variant="contained" onClick={() => setListOpen(true)}>
              {content.button}
            </Button>
            <WhatsappListDialog
              closeAfterTransition
              list={content}
              open={listOpen}
              onClick={handleListClose}
              onClose={handleListClose}
            />
          </div>
        )

      default:
        return cannot(type)
    }
  }

  return (
    <Card className={classes.card}>
      {isLoadingRow ? (
        <CardContent>
          <Grid container spacing={8}>
            <Grid item xs={12} className={classes.rowLoader}>
              <CircularProgress color="primary" size={50} />
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={5} md={4}>
              <ContactPhoneDisplay
                contactDeleted={contactDeleted}
                contactId={contactId}
                direction={isInboundType ? 'inbound' : 'outbound'}
                phoneNumber={contactNumber}
              />
            </Grid>
            <Grid className={classes.dataCell} item xs={4} sm={2} md={2}>
              <Typography color="textSecondary" align="center">
                <span>{messageStatus}</span>
              </Typography>
              <Typography color="primary" align="center" variant="body1">
                <b>({formatCost(part.charged)})</b>
              </Typography>
            </Grid>
            <Grid className={classes.dataCell} item xs={3} sm={2} md={3}>
              <Typography color="textSecondary" align="center">
                <Tooltip title={'Date and Time'}>
                  <span>&nbsp;{messageTime}</span>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid className={classes.dataCell} item xs={3} sm={2} md={2}>
              <Typography color="textSecondary" align="center">
                <Tooltip title={'From/To'}>
                  <span>{getWhatsappSenderID(part)}</span>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={2} sm={1} md={1}>
              <Tooltip title={'Send WhatsApp'}>
                <IconButton
                  className="whatsapp-send-message-btn"
                  aria-label="Send WhatsApp"
                  onClick={handleSendWhatsappMessage(contactNumber, contactId)}
                >
                  <WhatsappIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={'View Contact'}>
                <IconButton
                  aria-label="View Contact"
                  onClick={() => {
                    viewContact(contactId)
                  }}
                >
                  <Icon>person</Icon>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {renderContent()}
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  )
}

const useStyle = makeStyles()({
  card: {
    height: 'calc(100% - 20px)',
    padding: '5px',
  },
  messageType: {
    paddingTop: '3px',
  },
  dataCell: {
    paddingTop: '8px',
  },
  popover: {
    position: 'absolute',
    marginRight: '20px',
    top: '50px',
    left: '27px',
    zIndex: 1,
  },
  cardContent: {
    padding: '15px',
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rowLoader: {
    textAlign: 'center',
  },
  listContainer: {
    alignItems: 'center',
    display: 'flex',
  },
})

const getMessageStatus = ({ direction, error, done, read, deliveredToPhone }: WhatsAppLog): string => {
  if (direction === 'ingress') {
    if (done) {
      return 'Received'
    }
    return 'Not received'
  }

  if (read) {
    return 'Read'
  }
  if (deliveredToPhone === 'in progress') {
    return 'In Progress'
  }
  if (deliveredToPhone === 'sent') {
    return 'Sent'
  }
  if (deliveredToPhone === 'confirmed') {
    return 'Delivered'
  }
  if (deliveredToPhone === 'failed') {
    return `Not Delivered ${error}`
  }
  // deliveredToPhone == ""
  return `Not Sent ${error}`
}

const getContactNumber = ({ sender, recipient, direction }: WhatsAppLog): string => {
  if (direction === 'ingress') {
    return sender
  }
  return recipient
}

const getWhatsappSenderID = ({ sender, recipient, direction }: WhatsAppLog): string => {
  if (direction === 'ingress') {
    return recipient
  }
  return sender
}

export default WhatsappRow
