import { Button } from '@mui/material'
import React from 'react'

import CustomTooltip from '@/app/component/atom/tooltip'
import { createNotification } from '@/app/module/notifications/store/actions'
import { useEmbbedSignupWhatsapp } from '@/app/module/phonenumbers/hooks/use-embbed-signup-whatsapp'
import { useRegisterWhatsappSenderId } from '@/app/module/phonenumbers/hooks/use-register-whatsapp-did'
import { captureError } from '@/app/service/util/error'
import { useAppDispatch } from '@/app/store/redux-hooks'

import ConnectMetaLoadingDialog from './connect-meta-loading-dialog'
import ConnectMetaProfileDialog from './connect-meta-profile-dialog'

type Props = {
  didId: number
  phoneNumber: string
  /**
   * If whatsappMetaOtp is true, it means that Meta embedded sign up will handle the whole flow.
   * We don't need to ask for the profile name nor send it to the backend.
   */
  whatsappMetaOtp: boolean
}

const ConnectMetaButton: React.FC<Props> = ({ didId, phoneNumber, whatsappMetaOtp }) => {
  if (!whatsappMetaOtp) {
    return <ConnectMetaButtonNoOtp didId={didId} />
  }

  return <ConnectMetaButtonOtp didId={didId} phoneNumber={phoneNumber} />
}

/**
 * whatsappMetaOtp === true
 */
const ConnectMetaButtonOtp: React.FC<{ didId: number; phoneNumber: string }> = ({ didId, phoneNumber }) => {
  const dispatch = useAppDispatch()
  const [popupOpen, setPopupOpen] = React.useState(false)
  const [
    signup,
    cleanup,
    {
      sdkStatus: { failed, loaded },
    },
  ] = useEmbbedSignupWhatsapp(true, phoneNumber)
  const [register] = useRegisterWhatsappSenderId()

  if (!loaded) {
    return (
      <Button color="primary" loading variant="contained">
        Connect with Meta
      </Button>
    )
  }

  if (failed) {
    return (
      <CustomTooltip title="Failed to load Facebook SDK. This may be caused by browser ad blockers or tracking prevention settings. Please disable any ad blockers or adjust your browser's privacy settings and try again.">
        <span>
          <Button color="primary" disabled variant="contained">
            Connect with Meta
          </Button>
        </span>
      </CustomTooltip>
    )
  }

  const connect = async () => {
    try {
      setPopupOpen(true)
      const wId = await signup()
      await register(didId, wId)
      dispatch(
        createNotification({
          'register-wa-did': {
            type: 'success',
            message:
              'Your WhatsApp Sender ID has been successfully registered with Meta. Please allow some time for the approval process to be completed.',
          },
        }),
      )
    } catch (error) {
      captureError(error)
      dispatch(
        createNotification({
          'register-wa-did': {
            type: 'error',
            disableAutoHide: true,
            message:
              'We were unable to complete the final step of setting up your WhatsApp Sender ID. Please try again later or contact support if the issue persists.',
          },
        }),
      )
    } finally {
      setPopupOpen(false)
      cleanup()
    }
  }

  return (
    <>
      <Button color="primary" variant="contained" onClick={connect}>
        Connect with Meta
      </Button>
      {popupOpen && <ConnectMetaLoadingDialog />}
    </>
  )
}

// whatsappMetaOtp === false
const ConnectMetaButtonNoOtp: React.FC<{ didId: number }> = ({ didId }) => {
  const dispatch = useAppDispatch()
  const [popupOpen, setPopupOpen] = React.useState(false)
  const [profileOpen, setProfileOpen] = React.useState(false)
  const [wabaId, setWabaId] = React.useState<string | undefined>()
  const [
    signup,
    cleanup,
    {
      sdkStatus: { failed, loaded },
    },
  ] = useEmbbedSignupWhatsapp(false)

  if (!loaded) {
    return (
      <Button color="primary" loading variant="contained">
        Connect with Meta
      </Button>
    )
  }

  if (failed) {
    return (
      <CustomTooltip title="Failed to load Facebook SDK. This may be caused by browser ad blockers or tracking prevention settings. Please disable any ad blockers or adjust your browser's privacy settings and try again.">
        <span>
          <Button color="primary" disabled variant="contained">
            Connect with Meta
          </Button>
        </span>
      </CustomTooltip>
    )
  }

  const connect = async () => {
    try {
      setPopupOpen(true)
      const wId = await signup()
      setWabaId(wId)
      setProfileOpen(true)
    } catch (error) {
      captureError(error)
      dispatch(
        createNotification({
          'register-wa-did': {
            type: 'error',
            disableAutoHide: true,
            message: `Failed to register WhatsApp Sender ID with Meta, please try again later.`,
          },
        }),
      )
    } finally {
      setPopupOpen(false)
      cleanup()
    }
  }

  return (
    <>
      <Button color="primary" variant="contained" onClick={connect}>
        {!wabaId ? 'Connect with Meta' : 'Register WhatsApp Sender ID'}
      </Button>
      {popupOpen && <ConnectMetaLoadingDialog />}
      {!!wabaId && (
        <ConnectMetaProfileDialog
          didId={didId}
          open={profileOpen}
          wabaId={wabaId}
          onClose={() => setProfileOpen(false)}
        />
      )}
    </>
  )
}

export default ConnectMetaButton
