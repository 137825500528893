import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'

import { clearCookies } from '@/app/service/util/cookie'
import config from '@/config'

const createBaseQuery = ({ baseUrl }: { baseUrl: string }) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers) {
      const token = Cookies.get('authToken')
      headers.set('Content-Type', 'application/json')

      if (token) {
        headers.set('Authorization', `JWT ${token}`)
      }

      return headers
    },
    responseHandler(res) {
      if (res.status === 401) {
        clearCookies()
        window.location.reload()
      }

      return res.json()
    },
  })

// initialize an empty api service that we'll inject endpoints into later as needed
export const esClient = createApi({
  baseQuery: createBaseQuery({ baseUrl: config.svc.url }),
  endpoints: () => ({}),
})
