import { BoxProps, Button, Icon, styled } from '@mui/material'
import React from 'react'

import AudioPlayer from '@/app/component/atom/audio/player'
import AudioRecorder from '@/app/component/atom/audio/recorder'
import AudioUploader from '@/app/component/atom/audio/uploader'
import CustomTooltip from '@/app/component/atom/tooltip'
import UploadButton from '@/app/component/atom/upload/upload-button'
import { FileType } from '@/app/types'
import config from '@/config'

import ContentAudioTranscript from './content-audio-transcript'

type Props = {
  audio?: string
  audioFile?: FileType
  defaultUpload?: number
  downloading: boolean
  enableTTS: boolean
  enableTranscription: boolean
  id: string
  loading: boolean
  playId: string
  recording: boolean
  transcription: string
  uploading: boolean
  uploadRef: React.RefObject<any>

  onDelete: () => void
  onDownload: () => void
  onPlayToggle: (isPlaying: boolean) => void
  onRecordClick: () => void
  onRecordDisabled: () => void
  onRecordFinish: (file: File) => Promise<void>
  onRecordStart: () => void
  onStop: () => void
  onTranscriptChange: (transcription: string) => void
  onUpload: (file: File) => Promise<void>
  onUploadStart: (currentFilter: string) => void
  setDefaultUpload?: (val: number) => void
}

const ContentAudio: React.FC<Props> = ({
  audio,
  audioFile,
  defaultUpload,
  downloading,
  enableTTS,
  enableTranscription,
  id,
  loading,
  playId,
  recording,
  transcription,
  uploading,
  uploadRef,
  onDelete,
  onDownload,
  onPlayToggle,
  onRecordClick,
  onRecordDisabled,
  onRecordFinish,
  onRecordStart,
  onStop,
  onTranscriptChange,
  onUpload,
  onUploadStart,
  setDefaultUpload,
}) => {
  const isLoading = loading || downloading || uploading

  return (
    <div data-testid="campaign-content-audio">
      <div>
        <Hidden hidden={!!audio || recording}>
          <AudioUploader
            id="audio-uploader"
            loading={isLoading}
            uploadContent={
              <UploadButton
                defaultUpload={defaultUpload}
                setDefaultUpload={setDefaultUpload}
                startRecording={onRecordStart}
                startUpload={onUploadStart}
              />
            }
            uploadRef={uploadRef}
            onUpload={onUpload}
          />
        </Hidden>
        <Hidden hidden={!audio || recording}>
          <AudioPlayer
            id={id}
            playId={playId}
            loading={isLoading}
            name={audioFile?.title || audio || ''}
            source={{
              name: audioFile?.title || audio || '',
              url: audioFile?.url || '',
            }}
            onPlayToggle={onPlayToggle}
            onStop={onStop}
          />
        </Hidden>
        <Hidden hidden={!recording}>
          <AudioRecorder
            converterPath={config.mp3convert.url}
            isRecording={recording}
            onDisabled={onRecordDisabled}
            onFinish={onRecordFinish}
          />
        </Hidden>
        {enableTranscription && audioFile && (
          <ContentAudioTranscript
            file={audioFile}
            messageId={id}
            transcription={transcription}
            onChange={onTranscriptChange}
          />
        )}
      </div>
      <ButtonsContainer>
        <ButtonContainer>
          <CustomTooltip title={recording ? 'Finish recording' : 'Record'}>
            <div>
              <StyledButton
                color="primary"
                data-testid="voice-record-button"
                disabled={isLoading}
                variant="outlined"
                onClick={onRecordClick}
              >
                <Icon>{recording ? 'stop' : 'mic'}</Icon>
              </StyledButton>
            </div>
          </CustomTooltip>
        </ButtonContainer>
        <ButtonContainer>
          <CustomTooltip title="Download audio file">
            <div>
              <StyledButton
                className="voice-download-button"
                color="primary"
                data-testid="voice-download-button"
                disabled={isLoading || recording || !audio}
                variant="outlined"
                onClick={onDownload}
              >
                <Icon>cloud_download</Icon>
              </StyledButton>
            </div>
          </CustomTooltip>
        </ButtonContainer>
        <ButtonContainer>
          <CustomTooltip title="Remove audio">
            <div>
              <StyledButton
                className="voice-delete-button"
                color="primary"
                data-testid="voice-delete-button"
                disabled={isLoading || recording || !audio}
                variant="outlined"
                onClick={onDelete}
              >
                <Icon>delete</Icon>
              </StyledButton>
            </div>
          </CustomTooltip>
        </ButtonContainer>
        {!enableTTS && (
          <ButtonContainer>
            <CustomTooltip title="Text to speech - coming soon">
              <div>
                <StyledButton color="primary" variant="outlined" disabled>
                  <Icon>text_fields</Icon>
                </StyledButton>
              </div>
            </CustomTooltip>
          </ButtonContainer>
        )}
        {audio && !recording && (
          <FloatRight>
            <UploadButton
              setDefaultUpload={setDefaultUpload}
              defaultUpload={defaultUpload}
              options={reuploadOptions}
              startRecording={onRecordStart}
              startUpload={onUploadStart}
            />
          </FloatRight>
        )}
      </ButtonsContainer>
    </div>
  )
}

const reuploadOptions = [
  {
    label: 'Replace Audio (optimize volume)',
    tooltip:
      "We'll improve the volume, and clean up some technical things so your file sounds better. (Please listen to the optimized version after upload to make sure you are happy with it.)",
    filter: 'optimizeVolume',
  },
  {
    label: 'Replace Audio (optimize volume & trim silence)',
    tooltip:
      "We'll remove unnecessary silences, improve the volume, and clean up some technical things so your file sounds better. (Please listen to the optimized version after upload to make sure you are happy with it.)",
    filter: 'optimizeAndTrim',
  },
  {
    label: 'Replace Audio (as is)',
    tooltip:
      "We won't make any changes to your audio file. We recommend against this option unless you have optimized the files outside the platform.",
    filter: 'asIs',
  },
  {
    label: 'Record Audio to Replace',
    tooltip: '',
    filter: 'record',
  },
]

const StyledButton = styled(Button)({
  height: '40px',
  width: '40px',
  borderRadius: '50%',
  minWidth: '0',
})

const ButtonsContainer = styled('div')({
  display: 'flex',
  position: 'relative',
})

const ButtonContainer = styled('div')({
  marginRight: '8px',
})

const FloatRight = styled('div')({
  position: 'absolute',
  right: '0',
})

const Hidden = styled('div')<
  BoxProps & {
    hidden: boolean
  }
>(({ hidden }) => ({
  display: hidden ? 'none' : 'initial',
}))

export default ContentAudio
