import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

import IconText from '@/app/component/layout/icon-text'

const StyledModal = styled(Modal)({
  justifyContent: 'center',
  alignItems: 'flex-start',
  overflowY: 'scroll',
})

const StyledCardContent = styled(CardContent)({
  paddingRight: '3.5rem',
})

const StyledCard = styled(Card)({
  maxWidth: '1120px',
  width: 'calc(100% - 4rem)',
  margin: '2rem auto',
  overflow: 'visible',

  '&:active, &:focus': {
    outline: 'none',
  },
})

const ButtonContainer = styled('div')({
  maxWidth: '1120px',
  width: '100%',
  position: 'relative',
})

const CloseIconButton = styled(IconButton)({
  position: 'absolute',
  top: '1rem',
  right: '0.5rem',
  zIndex: '2',
})

type ModalLayoutProps = {
  title?: string
  icon?: string
  onClose: () => void
  isOpen?: boolean
  children: React.ReactNode
  id?: string
  closeOnBackdropClick?: boolean
}

export default function ModalLayout({
  title = '',
  icon,
  onClose,
  isOpen = true,
  children,
  id = '',
  closeOnBackdropClick = false,
}: ModalLayoutProps) {
  return (
    <StyledModal
      id={id}
      className="modal-layout"
      disableAutoFocus={true}
      slotProps={{
        backdrop: {
          sx: {
            width: 'calc(100% - 10px)',
          },
        },
      }}
      open={isOpen}
      onClose={(_e, reason) => {
        if (closeOnBackdropClick || reason !== 'backdropClick') {
          onClose?.()
        }
      }}
      disableEnforceFocus
      aria-modal="true"
    >
      <StyledCard>
        <ButtonContainer>
          <CloseIconButton id="modal-close-btn" onClick={() => onClose?.()}>
            <Icon>cancel</Icon>
          </CloseIconButton>
        </ButtonContainer>
        {(title || icon) && (
          <StyledCardContent>
            <IconText>
              {icon && <Icon>{icon}</Icon>}
              {!icon && <span />}
              <Typography variant="h6">{title}</Typography>
            </IconText>
          </StyledCardContent>
        )}
        {(title || icon) && <Divider />}
        {children}
      </StyledCard>
    </StyledModal>
  )
}
