import { Typography } from '@mui/material'
import { append, remove } from 'ramda'
import React from 'react'

import { CampaignAddButton } from '@/app/module/campaigns/component/helpers'
import { LocalAnswerCategory } from '@/app/module/campaigns/types'
import { ActionOption } from '@/app/module/campaigns/utils/actions/options'
import { emptyAction } from '@/app/module/campaigns/utils/actions/wrap'

import { ActionProps, ApiProps, SnippetProps } from './part-config-props'
import UseCategoriesInput from './use-categories-input'

type Props = {
  actionOptions: ActionOption[]
  actionProps: ActionProps
  apiProps: ApiProps
  categories: LocalAnswerCategory[]
  id: string
  snippetProps: SnippetProps

  onChange: (categories: LocalAnswerCategory[]) => void
}

const UseCategoriesInputs: React.FC<Props> = ({
  actionOptions,
  actionProps,
  apiProps,
  categories,
  id,
  snippetProps,
  onChange,
}) => {
  const handleUpdate = React.useCallback(
    (category: Partial<LocalAnswerCategory>, categoryIndex: number) => {
      onChange(updateCategory(categories, categoryIndex, category))
    },
    [categories, onChange],
  )
  const handleRemove = React.useCallback(
    (categoryIndex: number) => {
      onChange(remove(categoryIndex, 1, categories))
    },
    [categories, onChange],
  )

  return (
    <div className="categories-inputs">
      <Typography>Categorize the answer with AI</Typography>
      <div>
        <Typography color="textSecondary" variant="caption" gutterBottom={true}>
          Specify the possible options to categorize the answer. Tip: Add "other" as a catch-all.
        </Typography>
        {categories.map((category, index) => (
          <UseCategoriesInput
            actionOptions={actionOptions}
            actionProps={actionProps}
            apiProps={apiProps}
            category={category}
            categoryIndex={index}
            id={id}
            isCloseDisabled={categories.length === 1}
            key={index}
            snippetProps={snippetProps}
            onChange={handleUpdate}
            onRemove={handleRemove}
          />
        ))}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CampaignAddButton
            tooltip="Add category"
            onClick={() => onChange(append({ category: '', actions: [emptyAction] }, categories))}
            text="Add category"
            icon="add"
          />
        </div>
      </div>
    </div>
  )
}

const updateCategory = (categories: LocalAnswerCategory[], index: number, newCategory: Partial<LocalAnswerCategory>) =>
  categories.map((category, i) => (i === index ? { ...category, ...newCategory } : category))

export default React.memo(UseCategoriesInputs)
