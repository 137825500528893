import { styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'
import React from 'react'

const StyledRow = styled(TableRow, {
  shouldForwardProp: (prop) => !['isLoading'].includes(prop as string),
})(({ isLoading }: { isLoading?: boolean }) =>
  isLoading
    ? {
        opacity: '0.45',
        cursor: 'auto',
        pointerEvents: 'none',
      }
    : {},
)

type TableRowLayoutProps = {
  isLoading?: boolean
  children: React.ReactNode
  className?: string
}

export default function TableRowLayout({ isLoading, children, className }: TableRowLayoutProps) {
  return (
    <StyledRow isLoading={isLoading} className={className}>
      {children}
    </StyledRow>
  )
}
