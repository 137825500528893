import * as Sentry from '@sentry/react'

import 'flag-icons/css/flag-icons.css'

import cookies from 'js-cookie'
import moment from 'moment'

import 'moment-timezone'

import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'

import Loader from './app/component/atom/loader'
import App from './app/index'
import { activateTrialMode, login, setOTP } from './app/module/auth/store/actions'
import { createNotification } from './app/module/notifications/store/actions'
import setDefaultTimezone from './app/module/utils/store/actions'
import routes from './app/page/_routes'
import ErrorPage from './app/page/error'
import Wrapper from './app/service/render/wrapper'
import { findTz } from './app/service/util/timezone'
import getStore, { RootState } from './app/store'
import config from './config'
import theme from './config/theme'

window.AudioContext = window.AudioContext || (window as any).webkitAudioContext

const sheets = {
  theme,
}

const getClientStore = (state: RootState) => {
  const store = getStore(state)

  const token = cookies.get('authToken')
  const otpToken = cookies.get('otpToken')
  const trialMode = cookies.get('trialMode')

  window.addEventListener('offline', () => {
    store.dispatch(
      createNotification({
        offline: {
          type: 'warning',
          message: 'Looks like you are offline.',
        },
      }),
    )
  })

  window.addEventListener('online', () => {
    store.dispatch(createNotification({}))
  })

  const rawTz = moment.tz.guess()
  const tz = findTz(rawTz)?.name || rawTz
  store.dispatch(setDefaultTimezone(tz))

  if (token) {
    store.dispatch(login({ token, verifyToken: '' }))
  }

  if (!token && otpToken) {
    store.dispatch(setOTP({ token: otpToken }))
  }

  if (trialMode) {
    store.dispatch(activateTrialMode({ trialMode: trialMode === 'true' }))
  }

  return store
}

if (config.sentry.dsn && ['production', 'staging'].includes(config.env.app)) {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.env.app,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    maxValueLength: 2000,
    release: import.meta.env.VITE_VERSION,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}

const container = document.getElementById('root')

const root = createRoot(container!)

root.render(
  <App store={getClientStore({} as RootState)} sheets={sheets}>
    <BrowserRouter>
      <CompatRouter>
        <Wrapper routes={routes} Loader={Loader} ErrorPage={() => <ErrorPage isAuthorized={false} />} />
      </CompatRouter>
    </BrowserRouter>
  </App>,
)
