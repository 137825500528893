import { Grid } from '@mui/material'
import { find, pathOr, propEq } from 'ramda'
import React from 'react'

import { Select } from '@/app/component/atom/form'
import { DEFAULT_SENDER_ID_VALUE } from '@/app/definitions'
import { MessageTypeCount } from '@/app/module/campaigns/message-type-helpers'
import { selectSenderIdsByType } from '@/app/module/campaigns/store/selectors'
import {
  CampaignTemplateType,
  MessagesState,
  MultimessageItem,
  SenderIdOption,
  SenderIdTypes,
} from '@/app/module/campaigns/types'

type Props = {
  index: number
  item: MultimessageItem
  messageIndex: number
  messageType: MessageTypeCount
  messages: MessagesState & {
    defaultSenderId: SenderIdOption[]
  }
  type: SenderIdTypes
  onChange: (item: Partial<MultimessageItem>) => void
}

const senderIDInfo = "Contact support to register your own branded sender ID so SMS come from your organization's name"

const SenderIdSelect: React.FC<Props> = ({ index, item, messageIndex, messageType, messages, type, onChange }) => {
  const foundSenderIdReplies = React.useMemo(() => {
    if (!(SenderIdTypes.SenderIdQuestions in item.variables)) {
      return undefined
    }

    const senderIdQuestions = item.variables[SenderIdTypes.SenderIdQuestions]
    const smsSenderIds = messages.senderIdsForDrip?.sms || []

    return smsSenderIds.find((sid) => sid.sender === senderIdQuestions)
  }, [item, messages.senderIdsForDrip?.sms])

  const isSenderIdMessages = type === SenderIdTypes.SenderId
  const senderId = React.useMemo(() => {
    const currentSenderId = pathOr('', [type], item.variables)
    if (!isSenderIdMessages) {
      return currentSenderId
    }
    if ('defaultSenderId' in item.variables && item.variables.defaultSenderId) {
      return DEFAULT_SENDER_ID_VALUE
    }
    return currentSenderId
  }, [isSenderIdMessages, item.variables, type])
  const senderIdOptions = React.useMemo(
    () =>
      selectSenderIdsByType({
        messageType,
        newMessages: messages,
        type,
      }),
    [messageType, messages, type],
  )
  const isSenderIdValid = validateSenderId({
    type,
    value: senderId,
    showSenderIdReplies: !!foundSenderIdReplies,
  })

  return (
    <Grid item xs={4}>
      <Select
        className="campaign-item-senderid"
        data-testid={`senderId-select-${messageIndex}-${index}`}
        editable
        error={isSenderIdValid ? '' : 'You must select a sender ID.'}
        id="campaign-item-senderid"
        info={senderIdOptions.length === 1 ? senderIDInfo : undefined}
        label="Select Sender ID"
        name={`senderId-select-${messageIndex}-${index}`}
        value={senderId}
        values={senderIdOptions}
        onChange={async ({ value }: { value: string | string[] }) => {
          const emptySenderIdReplies =
            messageType.type === 'smsQuestion' &&
            !index &&
            !find(propEq('sender', value), pathOr([], ['senderIdsForDrip', 'sms'], messages))

          const isDefaultSenderId = isSenderIdMessages && value === DEFAULT_SENDER_ID_VALUE

          switch (item.type) {
            case CampaignTemplateType.CATI:
              onChange({
                ...item,
                variables: {
                  ...item.variables,
                  defaultSenderId: isDefaultSenderId,
                  [type]: isDefaultSenderId ? '' : value,
                },
              })
              break

            case CampaignTemplateType.Drip:
              onChange({
                ...item,
                variables: {
                  ...item.variables,
                  defaultSenderId: isDefaultSenderId,
                  [type]: isDefaultSenderId ? '' : value,
                  ...(emptySenderIdReplies && {
                    [SenderIdTypes.SenderIdReplies]: '',
                  }),
                },
              })
              break

            case CampaignTemplateType.Reminder:
              onChange({
                ...item,
                variables: {
                  ...item.variables,
                  defaultSenderId: isDefaultSenderId,
                  [type]: isDefaultSenderId ? '' : value,
                  ...(emptySenderIdReplies && {
                    [SenderIdTypes.SenderIdReplies]: '',
                  }),
                },
              })
              break

            case CampaignTemplateType.SMSSurvey:
              onChange({
                ...item,
                variables: {
                  ...item.variables,
                  defaultSenderId: isDefaultSenderId,
                  [type]: isDefaultSenderId ? '' : value,
                  ...(emptySenderIdReplies && {
                    [SenderIdTypes.SenderIdReplies]: '',
                  }),
                },
              })
              break

            case CampaignTemplateType.VoiceSurvey:
              onChange({
                ...item,
                variables: {
                  ...item.variables,
                  defaultSenderId: isDefaultSenderId,
                  [type]: isDefaultSenderId ? '' : value,
                },
              })
              break

            case CampaignTemplateType.WhatsAppSurvey:
              onChange({
                ...item,
                variables: {
                  ...item.variables,
                  defaultSenderId: isDefaultSenderId,
                  [type]: isDefaultSenderId ? '' : value,
                },
              })
              break

            default:
              break
          }
        }}
      />
    </Grid>
  )
}

const validateSenderId = ({
  type,
  value,
  showSenderIdReplies,
}: {
  showSenderIdReplies: boolean
  type: SenderIdTypes
  value: string
}) => {
  const isValueEmpty = ['', DEFAULT_SENDER_ID_VALUE].includes(value)
  return !(
    (isValueEmpty ? (type === 'senderIdQuestions' && !showSenderIdReplies) || type === 'senderIdReplies' : false) ||
    (type === 'senderIdWhatsapp' && isValueEmpty)
  )
}

export default SenderIdSelect
