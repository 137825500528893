import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { CreateOrgGroupApiArg } from '@/__generated/es-api'
import { ERROR_SAVE_GROUPS_ITEM_SERVER } from '@/app/module/groups/definitions'
import { createNotification } from '@/app/module/notifications/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { esApi, getErrorMessage } from '@/app/store/api'
import { useAppDispatch } from '@/app/store/redux-hooks'

export const useCreateGroup = () => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const [create, res] = esApi.endpoints.createOrgGroup.useMutation()

  const createGroup = useCallback(
    async (payload: CreateOrgGroupApiArg['body']) => {
      try {
        await create({
          orgId: orgId.toString(),
          body: payload,
        }).unwrap()
        dispatch(
          createNotification({
            'create-group-success': {
              type: 'success',
              message: 'Group has been saved successfully',
            },
          }),
        )
        return true
      } catch (error) {
        dispatch(
          createNotification({
            'create-group-failure': {
              type: 'error',
              message: getErrorMessage(error, ERROR_SAVE_GROUPS_ITEM_SERVER),
            },
          }),
        )
        return false
      }
    },
    [orgId, create, dispatch],
  )

  return [createGroup, res] as const
}
