import { Button, Typography } from '@mui/material'
import React from 'react'

import ErrorLayout from '@/app/component/layout/error'

type Props = {
  messageId: string
  onRemove: (id: string) => void
}

const InvalidMessage: React.FC<Props> = ({ messageId, onRemove }) => {
  return (
    <ErrorLayout
      style={{
        maxWidth: '100%',
      }}
    >
      <div style={{ marginBottom: '1rem', textAlign: 'center' }}>
        <div>
          <Typography gutterBottom={true}>
            The content type is invalid or not supported. Please remove it and add a valid one.
          </Typography>
          <Button color="primary" variant="contained" onClick={() => onRemove(messageId)}>
            Remove invalid content
          </Button>
        </div>
      </div>
    </ErrorLayout>
  )
}

export default InvalidMessage
