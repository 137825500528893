import { FileCopy } from '@mui/icons-material'
import { Box, IconButton, styled } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { path, pathOr } from 'ramda'
import React from 'react'

import EditableText from '@/app/component/atom/form/editableText'
import Tooltip from '@/app/component/atom/tooltip'
import { WhatsAppTab } from '@/app/component/atom/whatsapp/helpers'
import Conditions from '@/app/component/conditions'
import { Groups } from '@/app/component/conditions/definitions'
import { RUNNING_CAMPAIGNS_DISABLED_TOOLTIP, TYPES } from '@/app/module/campaigns/definitions'
import { BaseLanguageType } from '@/app/module/campaigns/helpers'
import { getMessagePlaceholderLabel } from '@/app/module/campaigns/label-helpers'
import { processConditions } from '@/app/module/campaigns/store/selectors'
import {
  CampaignQuery,
  CampaignTemplateType,
  ConditionActions,
  Language,
  LegacyCampaignItem,
  Offset,
  PersonalizationType,
  ReminderMessage,
  SenderIdOption,
  SnippetType,
  Message as TMessage,
} from '@/app/module/campaigns/types'
import { countActionsInCallResult } from '@/app/module/campaigns/utils/actions/list'
import { NotificationsData } from '@/app/module/notifications/types'
import {
  CountryTimezone,
  FilesType,
  FileType,
  FileUploadType,
  SelectOption,
  TimezoneWithCountryCodeType,
} from '@/app/types'

import Accordion from './components/accordion'
import CallResultActions from './components/call-result-actions'
import Delay from './delay'
import { isNewMessage } from './helpers'
import { LibrarySaveButton } from './library-save-button'
import { MessageActions } from './message-actions'
import MessageContent from './message-content'
import MessageResponse from './message-response'
import ReminderOffset from './reminder-offset'

type BaseProps = {
  apiPersonalizationList: PersonalizationType
  campaignIds: SelectOption<number>[]
  messageIds: string[]
  conditions: number
  countriesTimezones: CountryTimezone[]
  countryCode: string
  defaultLanguage: string
  defaultUpload: number
  files: {
    data: FilesType
    loadingItems: string[]
  }
  groups: Groups
  hasQuestion?: boolean
  id: string
  index: number
  languages: Language[]
  loading: boolean
  messageRef?: React.Ref<HTMLDivElement>
  newItems: string[]
  orgId: number
  page: number
  pageSize: number
  personalizationList: PersonalizationType
  published: boolean
  row: number
  segments: Groups
  senderIds: SenderIdOption[]
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  supportedMessageTypes: SelectOption<string>[]
  timezone: string
  timezones: TimezoneWithCountryCodeType
  token: string
  total: number
  voiceList: BaseLanguageType[]
  voicePlayingId: string
  whatsappSenderIds: SenderIdOption[]

  createNotification: (data: NotificationsData) => void
  getCampaigns: (payload: { token: string; orgId: number; query: CampaignQuery }) => Promise<void>
  getFile: (id: string, file: string) => Promise<FileType>
  getSnippets: (payload: { token: string; orgId: number }) => Promise<void>
  onChange: (item: Partial<TMessage>, changed?: boolean) => void
  onClose: (id: string) => void
  onMessageCreate: (index: number, row: number, message: TMessage | ReminderMessage) => void
  onMessageDuplicate: (id: string, row: number) => void
  onMicAccessError: () => void
  onSaveConditions: (item: TMessage) => void
  saveFile: (id: string, file: FileUploadType) => Promise<FileType>
  saveSnippet: (payload: {
    orgId: number
    token: string
    item: Partial<SnippetType> & Pick<SnippetType, 'category' | 'snippet'>
  }) => Promise<SnippetType>
  setConditions: (conditions: number | null) => void
  setDefaultUpload: (defaultUpload: number) => void
  setLoading: (loading: boolean) => void
  setPlayingId: (playId?: string) => void
}

type CampaignProps = BaseProps & {
  campaignType: LegacyCampaignItem['type']
  message: TMessage
}

type ReminderProps = BaseProps & {
  campaignType: CampaignTemplateType.Reminder
  message: ReminderMessage
}

type Props = CampaignProps | ReminderProps

type State = {
  whatsappTab: WhatsAppTab
}

const defaultArray: any[] = []

class MessageWrapper extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      whatsappTab: WhatsAppTab.Text,
    }
  }

  setWhatsappTab = (tab: WhatsAppTab) => this.setState({ whatsappTab: tab })

  defaultObject = {}

  defaultFunc = () => this.defaultObject

  callResultActionChangeHandler = ({ value }: { value: ConditionActions[] }) =>
    this.props.onChange({
      ...this.props.message,
      callResultActions: value,
    })

  getFileHandler = (file: string) => this.props.getFile(this.props.id, file)

  saveFileHandler = (item: FileUploadType) => this.props.saveFile(this.props.id, item)

  getImagesProps = (qid: string) => ({
    id: qid,
    loading: this.props.files.loadingItems.indexOf(qid) > -1,
    source: this.props.files.data,
    getFile: this.getFileHandler,
    saveFileHandler: this.saveFileHandler,
  })

  getVoiceProps = (qid: string, enableTTS = true) => ({
    id: qid,
    defaultUpload: this.props.defaultUpload,
    enableTTS,
    files: pathOr(this.defaultObject, ['files', 'data'], this.props),
    filterType: this.props.campaignType,
    loading: this.props.files.loadingItems.indexOf(qid) > -1,
    personalizationList: this.props.personalizationList,
    playId: this.props.voicePlayingId,
    voiceList: this.props.voiceList,
    getFile: this.getFileHandler,
    saveFileHandler: this.saveFileHandler,
    setDefaultUpload: this.props.setDefaultUpload,
    setPlaying: this.props.setPlayingId,
    onMicAccessError: this.props.onMicAccessError,
  })

  handleLabelChange = (value: string) => {
    const { message, onChange } = this.props
    onChange(
      {
        ...message,
        label: value,
      },
      true,
    )
  }

  render() {
    const {
      campaignIds,
      messageIds,
      campaignType,
      conditions,
      countriesTimezones,
      countryCode,
      groups,
      hasQuestion,
      id,
      index,
      message,
      newItems,
      orgId,
      personalizationList,
      published,
      messageRef,
      row,
      segments,
      senderIds,
      snippets,
      supportedMessageTypes,
      timezone,
      timezones,
      token,
      total,
      getCampaigns,
      getSnippets,
      onChange,
      onClose,
      onMessageCreate,
      onMessageDuplicate,
      onSaveConditions,
      saveSnippet,
      setConditions,
      setLoading,
    } = this.props
    const { type, label } = message

    const { label: placeHolderLabel, conditionsTitle } = getMessagePlaceholderLabel(type, hasQuestion)
    let callResultActionsCount = 0
    const callResultActionsPath = path<string[]>(
      ['callResultActionsPath'],
      TYPES[campaignType.toUpperCase() as keyof typeof TYPES],
    )
    const callResultActions = !callResultActionsPath
      ? defaultArray
      : pathOr(defaultArray, callResultActionsPath, message)

    if (campaignType === 'ivr' || campaignType === 'drip') {
      callResultActionsCount = countActionsInCallResult(callResultActions)
    }
    const condition = path(['when'], message) ? 'sometimes' : 'always'

    return (
      <div
        key={id}
        id={`message-${message.id}`}
        data-message-id={message.id}
        ref={messageRef}
        className="message-block"
      >
        <Card style={{ marginBottom: '3rem' }}>
          {conditions === index && (
            <Conditions
              groups={groups || this.defaultObject}
              segments={segments || this.defaultObject}
              data={processConditions(pathOr(['or', ['and', ['', '', '']]], ['when'], message))}
              personalizationList={personalizationList}
              title={conditionsTitle}
              item={message}
              onSaveConditions={onSaveConditions}
              onClose={() => setConditions(null)}
            />
          )}
          <CardContent>
            <Toolbar
              style={{
                margin: '-24px -24px 0px',
                backgroundColor: '#f5f5f5',
                justifyContent: 'space-between',
              }}
            >
              <Box id={`copy-${index}`} sx={{ marginRight: 2 }}>
                <Tooltip title="Copy Message ID">
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(message.id).then(() => {
                        this.props.createNotification({
                          'copy-part-id': {
                            type: 'success',
                            message: 'Message ID copied!',
                          },
                        })
                      })
                    }}
                    edge="end"
                  >
                    <FileCopy />
                  </IconButton>
                </Tooltip>
                <Box id={`message-${index}-id`} sx={{ display: 'none' }}>
                  {message.id}
                </Box>
              </Box>
              <div
                style={{
                  width: '100%',
                }}
              >
                <div
                  style={{
                    marginTop: '7px',
                    marginBottom: '5px',
                    width: '100%',
                  }}
                >
                  <Typography
                    id={`message-label-${index}`}
                    component="div"
                    variant="subtitle1"
                    color="primary"
                    style={{
                      height: '22px',
                      width: '100%',
                    }}
                  >
                    <EditableText
                      data-testid={`message-label-${index}`}
                      displayText={`${index + 1}. ${label || placeHolderLabel}`}
                      maxLength={50}
                      value={label || placeHolderLabel}
                      onChange={this.handleLabelChange}
                    />
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <Typography
                    color="textSecondary"
                    data-testid={`conditions-${index}`}
                    variant="subtitle2"
                    style={{
                      paddingTop: 4,
                    }}
                  >
                    {conditionsTitle}{' '}
                    <b>
                      <i>{condition}</i>.
                    </b>
                  </Typography>
                  <Button
                    color="primary"
                    data-testid={`open-conditions-${index}`}
                    onClick={() => setConditions(index)}
                    size="small"
                  >
                    (click here to {condition === 'always' ? 'set' : 'edit'} conditions)
                  </Button>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LibrarySaveButton
                  index={index}
                  snippets={snippets?.data || []}
                  isLoading={snippets?.loading}
                  message={message}
                  onClick={async () => {
                    await saveSnippet({
                      orgId,
                      token,
                      item: { category: 'message', snippet: message },
                    })
                    await getSnippets({ token, orgId })
                  }}
                />
                <div style={{ marginLeft: '12px' }}>
                  <Tooltip
                    title={selectDeleteTooltip({
                      published,
                      newMessage: isNewMessage({ newItems, id }),
                    })}
                  >
                    <div>
                      <Button
                        id={`delete-message-${index}`}
                        data-testid={`delete-message-${index}`}
                        disabled={published && !isNewMessage({ newItems, id })}
                        style={{
                          minWidth: '0',
                          width: '40px',
                          height: '40px',
                          zIndex: '2',
                          borderRadius: '50%',
                        }}
                        onClick={() => onClose(id)}
                      >
                        <Icon>close</Icon>
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </Toolbar>
            <MessageContent {...this.props} setWhatsappTab={this.setWhatsappTab} />
            <MessageResponse {...this.props} whatsappTab={this.state.whatsappTab} />
          </CardContent>
          {campaignType === 'drip' && (
            <React.Fragment>
              <Divider />
              <CardContent>
                <Delay
                  isFirst={index === 0}
                  published={published}
                  item={message}
                  message={type === 'api' ? 'make this API request' : 'send this message'}
                  changeHandler={onChange}
                  timezones={timezones}
                  timezone={timezone}
                  countriesTimezones={countriesTimezones}
                  countryCode={countryCode}
                  setLoading={setLoading}
                  type="message"
                />
              </CardContent>
              {message.type === 'voice' && (
                <Accordion
                  className="call-result-actions-accordion"
                  display={callResultActionsCount > 0}
                  placeholder="Call Result Actions"
                  title="Click here to specify what actions we should take if the contact does/ doesn't answer the call"
                >
                  <CallResultActions
                    getVoiceProps={this.getVoiceProps}
                    changeHandler={this.callResultActionChangeHandler}
                    campaignType={campaignType}
                    personalizationList={personalizationList}
                    senderIds={senderIds}
                    getCampaigns={getCampaigns}
                    orgId={orgId}
                    token={token}
                    campaignIds={campaignIds}
                    actions={callResultActions}
                    getSnippets={getSnippets}
                    saveSnippet={saveSnippet}
                    snippets={snippets}
                    timezones={timezones}
                    timezone={timezone}
                    countriesTimezones={countriesTimezones}
                    countryCode={countryCode}
                  />
                </Accordion>
              )}
            </React.Fragment>
          )}
          {campaignType === CampaignTemplateType.Reminder && (
            <>
              <Divider />
              <CardContent>
                <ReminderOffset
                  countriesTimezones={countriesTimezones}
                  countryCode={countryCode}
                  messageType={message.type}
                  offset={message.offset}
                  published={published}
                  timezone={timezone}
                  timezones={timezones}
                  onChange={(offset: Offset) =>
                    onChange({
                      ...message,
                      offset,
                    })
                  }
                />
              </CardContent>
            </>
          )}
          <CardActions
            style={{
              backgroundColor: '#f5f5f5',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {(campaignType === 'ivr' || campaignType === 'smssurvey' || campaignType === 'whatsappsurvey') && (
                <InfoContainer>
                  <Typography color="textSecondary" variant="body1">
                    {hasQuestion &&
                      "After this question, we'll ask the next question unless you add an action that tells us to do something else."}
                    {!hasQuestion && "After this message, we'll ask the next question."}
                  </Typography>
                </InfoContainer>
              )}
              <MessageActions
                index={index}
                campaignType={campaignType}
                total={total}
                published={published}
                snippets={snippets}
                supportedMessageTypes={supportedMessageTypes}
                messageIds={messageIds}
                timezone={timezone}
                onMessageCreate={(msg) => onMessageCreate(index + 1, row, msg)}
                onMessageDuplicate={() => onMessageDuplicate(id, row)}
              />
            </div>
          </CardActions>
        </Card>
      </div>
    )
  }
}

const selectDeleteTooltip = ({ published, newMessage }: any) => {
  if (published && !newMessage) {
    return RUNNING_CAMPAIGNS_DISABLED_TOOLTIP
  }
  return 'Delete message'
}

const InfoContainer = styled('div')({
  maxWidth: '420px',
})

export default MessageWrapper
