import WhatsappIcon from '@mui/icons-material/WhatsApp'

import { cannot } from '@/app/helpers'
import { Q_ACTIONS_DESCRIPTIONS } from '@/app/module/campaigns/definitions'
import { LocalActionType } from '@/app/module/campaigns/types'
import { SelectOption } from '@/app/types'

import { ActionListContext, getValidActionsForCampaignType } from './list'
import { ActionContext } from './types'

export type BaseActionOption = SelectOption<LocalActionType> & {
  icon: ActionIcon
}

export type ActionOption = BaseActionOption & { description: string; icon: ActionIcon }

export function getActionOptionsForCampaign(ctx: ActionListContext): ActionOption[] {
  const options = getValidActionsForCampaignType(ctx)
  return addDescriptionToActions(getActionOptions(options), ctx.actionNamespace)
}

export const getActionDescription = (action: BaseActionOption, context: ActionContext = ActionContext.Default) => {
  const actionDescriptions = Q_ACTIONS_DESCRIPTIONS as Record<string, Record<string, string>>
  const desc = actionDescriptions?.[action.value]?.[context]
  return desc || actionDescriptions?.[action.value]?.default || ''
}

export const addDescriptionToActions = (
  actions: Array<BaseActionOption>,
  context: ActionContext = ActionContext.Default,
) =>
  actions.map((action) => ({
    ...action,
    description: getActionDescription(action, context),
  }))

type ActionIcon = string | (() => JSX.Element)

export const getActionOptions = (actionTypes: LocalActionType[]): Array<BaseActionOption> =>
  actionTypes.map((type) => {
    switch (type) {
      case LocalActionType.API:
        return {
          label: 'Make API request (webhook)',
          value: LocalActionType.API,
          icon: 'settings_remote',
        }
      case LocalActionType.FinishFlow:
        return {
          label: 'End campaign for contact',
          value: LocalActionType.FinishFlow,
          icon: 'stop',
        }
      case LocalActionType.Hangup:
        return {
          label: 'Hangup',
          value: LocalActionType.Hangup,
          icon: 'call_end',
        }
      case LocalActionType.Jump:
        return {
          label: 'Jump to question',
          value: LocalActionType.Jump,
          icon: 'account_tree',
        }
      case LocalActionType.PauseSubscription:
        return {
          label: 'Pause subscription',
          value: LocalActionType.PauseSubscription,
          icon: 'pause',
        }
      case LocalActionType.Replay:
        return {
          label: 'Replay the question',
          value: LocalActionType.Replay,
          icon: 'replay_circle_filled',
        }
      case LocalActionType.Resend:
        return {
          label: 'Resend the question',
          value: LocalActionType.Resend,
          icon: 'send',
        }
      case LocalActionType.ResumeSubscription:
        return {
          label: 'Resume subscription',
          value: LocalActionType.ResumeSubscription,
          icon: 'play_arrow',
        }
      case LocalActionType.SendEmail:
        return {
          label: 'Send Email',
          value: LocalActionType.SendEmail,
          icon: 'email',
        }
      case LocalActionType.SendSMS:
        return {
          label: 'Send SMS to contact',
          value: LocalActionType.SendSMS,
          icon: 'sms',
        }
      case LocalActionType.SendSMSToPhoneNumbers:
        return {
          label: 'Send SMS to someone else',
          value: LocalActionType.SendSMSToPhoneNumbers,
          icon: 'send_to_mobile',
        }
      case LocalActionType.SendWhatsApp:
        return {
          label: 'Send WhatsApp Message',
          value: LocalActionType.SendWhatsApp,
          icon: () => <WhatsappIcon sx={{ width: '16px', height: '16px', color: 'text.secondary' }} />,
        }
      case LocalActionType.SubscribeToCampaign:
        return {
          label: 'Subscribe contact to campaign',
          value: LocalActionType.SubscribeToCampaign,
          icon: 'input',
        }
      case LocalActionType.Topup:
        return {
          label: 'Send Airtime Topup to contact',
          value: LocalActionType.Topup,
          icon: 'account_balance_wallet',
        }
      case LocalActionType.Transfer:
        return {
          label: 'Transfer call to someone',
          value: LocalActionType.Transfer,
          icon: 'phone',
        }
      case LocalActionType.Unsubscribe:
        return {
          label: 'Unsubscribe contact from campaign',
          value: LocalActionType.Unsubscribe,
          icon: 'unsubscribe',
        }
      case LocalActionType.UnsubscribeAll:
        return {
          label: 'Unsubscribe contact from all campaigns',
          value: LocalActionType.UnsubscribeAll,
          icon: 'unsubscribe',
        }
      case LocalActionType.UpdateContact:
        return {
          label: 'Update contact',
          value: LocalActionType.UpdateContact,
          icon: 'contacts',
        }
      case LocalActionType.PlayAudio:
        return {
          label: 'Play audio message',
          value: LocalActionType.PlayAudio,
          icon: 'audiotrack',
        }

      default:
        return cannot(type)
    }
  })
