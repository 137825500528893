import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { find, pathOr, propEq } from 'ramda'
import { Component } from 'react'
import { withStyles } from 'tss-react/mui'

import Loading from '@/app/component/guard/loading'
import { formatPhoneNumber } from '@/app/service/util'

const styles = () => ({
  actions: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    padding: '24px',
  },
})

class RemoveFromGroup extends Component {
  state = {
    loading: true,
  }

  async componentDidMount() {
    return this.props.hydrate().then(() => {
      this.setState({
        loading: false,
      })
    })
  }

  removeFromGroupHandler = () => {
    const { contactIds, excludeIds, isAllContacts, selectedGroup, includedGroupIds, search } = this.props

    this.setState({
      loading: true,
    })

    this.props
      .removeFromGroup({
        groupId: selectedGroup,
        isAllContacts,
        contactIds,
        excludeIds,
        includedGroupIds,
        search,
      })
      .then(() => {
        this.setState({
          loading: false,
        })
        this.props.closeHandler()
      })
      .catch(() => {
        this.setState({
          loading: false,
        })
        this.props.closeHandler()
      })
  }

  render() {
    const { recipients, totalRecipients, selectedGroup, groups } = this.props
    const groupName = pathOr('', ['label'], find(propEq('value', selectedGroup), groups))
    const contactsString = totalRecipients === 1 ? 'contact' : 'contacts'
    const recipientsDisplay = recipients.slice(0, 4).map(({ label, value }) => ({
      label: formatPhoneNumber(`+${label}`),
      value,
    }))

    if (totalRecipients > 4) {
      recipientsDisplay.push({
        label: '...',
        value: 'more',
      })
    }

    return (
      <Loading isLoading={this.state.loading}>
        <form
          id="remove-from-form"
          onSubmit={(e) => {
            e.preventDefault()
            if (!this.state.loading || this.props.recipients.length) {
              this.removeFromGroupHandler()
            }
          }}
        >
          <CardContent>
            <Typography>{`Are you sure you want to remove ${totalRecipients} ${contactsString} from ${groupName}?`}</Typography>
          </CardContent>
          <CardActions>
            <Grid container direction="row" alignItems="center" justifyContent="flex-end">
              <Button
                id="remove-from-group-action"
                type="submit"
                variant="contained"
                color="secondary"
                autoFocus={true}
              >
                Remove
              </Button>
            </Grid>
          </CardActions>
        </form>
      </Loading>
    )
  }
}

export default withStyles(RemoveFromGroup, styles)
