/* eslint-disable import/no-duplicates */
import ace, { Ace } from 'ace-builds'

import 'ace-builds/src-noconflict/ace'
import 'ace-builds/src-noconflict/ext-error_marker'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-spellcheck'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/theme-tomorrow'

// eslint-disable-next-line import/no-unresolved
import workerJSONUrl from 'ace-builds/src-noconflict/worker-json?url'
import { detect } from 'detect-browser'
import React from 'react'
import AceEditor from 'react-ace'
import ReactAce from 'react-ace/lib/ace'

import FormControl from './form-control'

import './input-code.css'

ace.config.setModuleUrl('ace/mode/json_worker', workerJSONUrl)

type Props = {
  disabledText?: string
  editable?: boolean
  error?: string
  height?: string
  info?: React.ReactNode
  inputLanguage?: string
  placeholder?: string
  readOnly?: boolean
  value: string
  width?: string
  name: string
  onBlur?: (value?: string) => void
  onChange: (value?: string) => void
  onCursorChange?: (selection: Ace.Selection) => void
  onFocus?: () => void
  onValidate?: (markers: Ace.Annotation[]) => void
}

const browser = detect()
const hasScrollMargin = browser?.name === 'firefox'

const InputCode = React.forwardRef<ReactAce, Props>(
  (
    {
      disabledText,
      editable = true,
      error,
      height = '80px',
      info,
      inputLanguage = 'text',
      name,
      placeholder,
      readOnly,
      value,
      width = '100%',
      onBlur,
      onChange,
      onCursorChange,
      onFocus,
      onValidate,
    },
    ref,
  ) => (
    <FormControl error={error} info={!editable || readOnly ? disabledText : info}>
      <AceEditor
        fontSize={12}
        height={height}
        highlightActiveLine={true}
        name={name}
        mode={inputLanguage}
        placeholder={placeholder}
        ref={ref}
        scrollMargin={hasScrollMargin ? [15, 15, 15, 15] : undefined}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
        showGutter={true}
        showPrintMargin={false}
        style={{
          borderBottom: 'solid',
          borderBottomWidth: !error ? '1px' : '2px',
          borderBottomColor: !error ? 'rgb(32, 89, 120)' : '#ff5252',
          marginTop: '2px',
          paddingBottom: 15,
        }}
        theme="tomorrow"
        value={value}
        width={width}
        onBlur={onBlur}
        onChange={onChange}
        onCursorChange={onCursorChange}
        onFocus={onFocus}
        onValidate={onValidate}
      />
    </FormControl>
  ),
)

export default InputCode
