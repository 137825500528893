/* eslint-disable no-console */
import { captureException } from '@sentry/react'
import { ZodError } from 'zod'

import config from '@/config'

export type UnexpectedError = {
  ok: false
  status: number
  statusText: string
}

export type HTTPRequestResult<T = any> = T | UnexpectedError

export const isFailed = (data: HTTPRequestResult): boolean => data instanceof Error || ('ok' in data && !data.ok)

export const captureError = (error: unknown, extra?: Record<string, unknown>) => {
  const hasSentry = config.sentry.dsn && ['production', 'staging'].includes(config.env.app)

  if (!hasSentry) {
    console.error(error)
    if (error instanceof ZodError) {
      console.error(error.message) // to show full zod error message in console
    }
    if (extra) {
      console.error('Error extra:', extra)
    }
  } else {
    captureException(error, { extra })
  }
}
