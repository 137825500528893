import { formatNumber, parseNumber } from 'libphonenumber-js'
import moment from 'moment-timezone'
import { pathOr } from 'ramda'

import { ROLES } from '@/app/definitions'
import config from '@/config'

const dateTimeDisplayFormat = pathOr('', ['display', 'format', 'dateTime'], config)
const dateDisplayFormat = pathOr('', ['display', 'format', 'date'], config)

export const trimPhoneNumberPlus = (number: string) => (number.startsWith('+') ? number.slice(1) : number)

export const formatPhoneNumber = (number: string) => formatNumber(number, 'INTERNATIONAL')

export const parsePhoneNumber = (value: string) => {
  try {
    const result = parseNumber(value.startsWith('+') ? value : `+${value}`, { extended: true })
    return {
      country: 'country' in result && result.country && result.valid ? result.country : '',
      phone: value,
    }
  } catch {
    return {
      country: '',
      phone: value,
    }
  }
}
// window.moment = moment
export const receiveDateTimeFormat = 'YYYY-MM-DDTHH:mm:ssZ'
export const noTimezoneDateTimeFormat = 'YYYY-MM-DD HH:mm:ss'

export const formatDT = (str: string, tz?: string, format: string = dateDisplayFormat) => {
  if (!str) return ''
  if (str === 'now') {
    return tz ? moment.tz(moment(), tz).format(format) : moment().format(format)
  }
  return tz
    ? moment.tz(str, receiveDateTimeFormat, tz).format(format)
    : moment(str, receiveDateTimeFormat).format(format)
}

export const formatDateTime = (str: string, tz?: string) => formatDT(str, tz, dateTimeDisplayFormat)
export const formatDate = (str: string, tz?: string) => formatDT(str, tz, dateDisplayFormat)

export const getMonthName = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const dateTime = (timestamp: string) => {
  if (!timestamp.length) return ''

  const currentYear = moment().year().toString()
  const momentDate = moment(timestamp)
  return momentDate.format(momentDate.format('YYYY') === currentYear ? 'DD MMM HH:mm' : 'DD MMM YYYY HH:mm')
}

export const numberWithCommas = (x: number | string) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const financialNumber = (x: number, decimals = 2) => numberWithCommas(x.toFixed(decimals))

export const currencyFormat = (x: string, currency = '$', decimals = 2) =>
  `${currency}${financialNumber(Number(x), decimals)}`

export const formatRole = (role: string) => {
  switch (role) {
    case ROLES.OWNER[0]:
      return 'Owner'
    case ROLES.MANAGER[0]:
      return 'Manager'
    case ROLES.COLLABORATOR[0]:
      return 'Collaborator'
    case ROLES.REPORT_VIEWER[0]:
      return 'Report Viewer'
    case ROLES.CUSTOMER_SERVICE[0]:
      return 'Customer Support'
    case ROLES.CATI_AGENT[0]:
      return 'CATI Agent'
    default:
      return '' // for edge cases like non-existing roles and unknown role types.
  }
}

export const formatName = (firstName?: string | null, lastName?: string | null) => {
  const joinedName = (firstName || '').concat(' ', lastName || '')
  return joinedName
}

export const formatBooleanVal = (val: null | undefined | boolean | string) => {
  if (val === null || val === '') return ''
  return val ? 'YES' : 'NO'
}

export const formatPercentage = (percent: number) => `${percent.toFixed(2)}%`

export const getPercentage = (num: number, total: number) => formatPercentage(total ? (num / total) * 100 : 0)

const formatDurationPart = (part: number, unit: 'y' | 'm' | 'd' | 'h' | 'm' | 's') => (part ? `${part}${unit}` : '')

export const formatDuration = (seconds: number) => {
  const duration = moment.duration(seconds, 'seconds')
  const formatted = [
    formatDurationPart(duration.years(), 'y'),
    formatDurationPart(duration.months(), 'm'),
    formatDurationPart(duration.days(), 'd'),
    formatDurationPart(duration.hours(), 'h'),
    formatDurationPart(duration.minutes(), 'm'),
    formatDurationPart(duration.seconds(), 's'),
  ]
    .filter(Boolean)
    .join(' ')

  return formatted || '0s'
}

export const pluralize = (count: number, singular: string, plural: string) => (count === 1 ? singular : plural)
