import { EventTracker, HoverState, Stack, ValueScale } from '@devexpress/dx-react-chart'
import { ArgumentAxis, BarSeries, Chart, Tooltip, ValueAxis } from '@devexpress/dx-react-chart-material-ui'
import { green, red } from '@mui/material/colors'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { pathOr } from 'ramda'

import Legend from './legend'

const tooltipMap = {
  'Contacts Engaged': 'engaged',
  'Contacts Not Engaged': 'notEngaged',
  Error: 'error',
}

export default function OverviewChart(props) {
  const { data, size = 350 } = props

  const ChartTooltip = ({ text, targetItem, ...restProps }) => {
    const tooltipLabel = pathOr(text, [targetItem.point, tooltipMap[targetItem.series]], data)
    return <Tooltip.Content {...restProps} text={`${tooltipLabel} ${targetItem.series}`} targetItem={targetItem} />
  }

  return (
    <Paper>
      <Typography
        variant="h5"
        color="textSecondary"
        style={{
          textAlign: 'center',
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        Message Delivery Overview
      </Typography>
      <Legend />
      <div
        style={{
          maxHeight: `${size}px`,
          overflowY: 'scroll',
        }}
      >
        <Chart
          height={data.length * 40 < size ? size : data.length * 40 + 100}
          style={{
            padding: '30px',
          }}
          data={data}
          rotated
        >
          <ValueScale name="name" />

          <ArgumentAxis />
          <ValueAxis
            tickFormat={() => (tick) => {
              if (tick % 1 === 0) {
                return tick
              }
              return undefined
            }}
            scaleName="name"
          />
          <BarSeries
            name="Contacts Engaged"
            valueField="engaged"
            argumentField="name"
            scaleName="name"
            color={green.A700}
          />
          <BarSeries
            name="Contacts Not Engaged"
            valueField="notEngaged"
            argumentField="name"
            scaleName="name"
            color="#2f80ac"
          />
          <BarSeries name="Error" valueField="error" argumentField="name" scaleName="name" color={red.A200} />
          <Stack stacks={[{ series: ['Contacts Engaged', 'Contacts Not Engaged', 'Error'] }]} />
          <EventTracker />
          <HoverState />
          <Tooltip contentComponent={ChartTooltip} />
        </Chart>
      </div>
    </Paper>
  )
}
