import { find, propEq } from 'ramda'

import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Notifications from '@/app/module/notifications'
import { PhoneNumberItem } from '@/app/module/phonenumbers'
import { tabNames } from '@/app/module/phonenumbers/component/item'
import NotFoundPage from '@/app/page/not-found'

function CampaignsItemPage({ name, routes, match }) {
  if (!find(propEq('value', match.params.tabName), tabNames)) {
    return <NotFoundPage />
  }
  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <Layout title="Phone Number - engageSPARK">
        <AuthHeader currentPage={PAGE.PHONENUMBERS.ITEM} />
        <PhoneNumberItem phoneNumberId={match.params.phoneNumberId} tabName={match.params.tabName} />
        <Notifications />
      </Layout>
    </AuthGuard>
  )
}

export default CampaignsItemPage
