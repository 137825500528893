import { z } from 'zod'

import ZBaseAction from './base'

export const ZPauseSubscription = z.object({
  current: z.literal(true),
  paused: z.literal(true),
})

export const ZResumeSubscription = z.object({
  campId: z.number(),
  paused: z.literal(false),
})

const ZSetPausedSubscriptionAction = ZBaseAction.extend({
  setPaused: z.union([ZPauseSubscription, ZResumeSubscription]),
})

export default ZSetPausedSubscriptionAction
