import { styled } from '@mui/material'

import { getCountryName } from '@/app/service/util/country'

type Props = {
  code?: string
  width?: string | number
}

export default function Flag({ code = '', width }: Props) {
  return <I className={`fi fi-${code.toLowerCase()}`} width={width} title={getCountryName(code)} />
}

const I = styled('i', {
  shouldForwardProp: (prop) => prop !== 'width',
})<Pick<Props, 'width'>>(({ width }) => ({
  '&.fi': {
    width,
  },
}))
