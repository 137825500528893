/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from 'react'
import { connect } from 'react-redux'
import { Prompt } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import CloseDialog from '@/app/component/atom/delete-dialog'
import Layout from '@/app/component/layout/page'
import { PAGE } from '@/app/definitions'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import { CampaignsItem } from '@/app/module/campaigns'
import { STEP_MAP } from '@/app/module/campaigns/component/item'
import { URLS_TO_TYPE } from '@/app/module/campaigns/definitions'
import { updateItem } from '@/app/module/campaigns/store/actions'
import { navigateContacts } from '@/app/module/contacts/store/actions'
import { navigateGroups } from '@/app/module/groups/store/actions'
import Notifications from '@/app/module/notifications'
import { navigateSegments } from '@/app/module/segments/store/actions'

const handleUnload = (ev) => {
  ev.preventDefault()
  return (ev.returnValue = 'You have unsaved changes, are you sure you want to leave?')
}

const safePaths = STEP_MAP

function CampaignsItemPage({ name, routes, match, history, clearItem }) {
  const [didChange, setDidChange] = React.useState(false)
  const [lastLocation, setLastLocation] = React.useState(null)
  const [showCloseDialog, setShowCloseDialog] = React.useState(false)
  const [confirmNavigation, setConfirmNavigation] = React.useState(false)
  history.listen(() => {
    window.removeEventListener('beforeunload', handleUnload)
  })

  const itemId = match.params.campaignsId
  const type = URLS_TO_TYPE[match.params.campaignType]

  React.useEffect(() => {
    if (itemId || type) {
      return
    }
    history.push('/campaigns/create')
  }, [history, itemId, type])

  React.useEffect(() => {
    if (didChange) {
      window.addEventListener('beforeunload', handleUnload)
    } else {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [didChange])

  React.useEffect(() => {
    if (confirmNavigation && !showCloseDialog) {
      if (lastLocation) {
        setDidChange(false)
        history.push(lastLocation.pathname)
        setConfirmNavigation(false)
      }
    }
  }, [confirmNavigation, history, lastLocation, showCloseDialog])

  const onFinish = React.useCallback(() => {
    setDidChange(false)
    setShowCloseDialog(false)
    setConfirmNavigation(true)
    clearItem()
    setTimeout(() => {
      history.push('/campaigns?page=1')
    }, 100)
  }, [history, clearItem])

  const onSave = React.useCallback(
    ({ id, tab }) => {
      setDidChange(false)
      setShowCloseDialog(false)
      history.push(`/campaigns/edit/${id}/${tab}`)
    },
    [history],
  )

  const handleBlockedNavigation = (nextLocation) => {
    const nextpath = nextLocation.pathname.substring(nextLocation.pathname.lastIndexOf('/') + 1)
    if (didChange && !confirmNavigation && !safePaths.includes(nextpath)) {
      setLastLocation(nextLocation)
      setShowCloseDialog(true)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    setShowCloseDialog(false)
    setConfirmNavigation(true)
  }

  const showCampaignItem = !!(itemId || type)

  return (
    <AuthGuard routes={routes} url={match.url} name={name} path={match.path}>
      <Layout disableScrolling title="Campaigns - engageSPARK">
        <AuthHeader currentPage={PAGE.CAMPAIGNS.INDEX} />
        <Prompt when={didChange} message={handleBlockedNavigation} />
        {showCloseDialog && (
          <CloseDialog
            isOpen={true}
            onClose={() => setShowCloseDialog(false)}
            title="Unsaved changes"
            text="You may have unsaved changes. If you made changes, please return to the campaign and click Save at the bottom of the page. Otherwise, you will lose any changes you made."
            deleteButtonText="Discard and exit"
            cancelButtonText="Return to campaign"
            onConfirm={handleConfirmNavigationClick}
          />
        )}
        {showCampaignItem && (
          <CampaignsItem
            history={history}
            type={type}
            itemId={itemId}
            step={match.params.step}
            onFinish={onFinish}
            onSave={onSave}
            onUpdate={setDidChange}
          />
        )}
        <Notifications />
      </Layout>
    </AuthGuard>
  )
}

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      clearItem: () => () => {
        dispatch(updateItem())
        dispatch(navigateContacts({}))
        dispatch(navigateGroups({}))
        dispatch(navigateSegments({}))
      },
    },
    dispatch,
  ),
)(CampaignsItemPage)
