import { Alert, Box, Button, DialogContent, FormControlLabel, Switch, Typography } from '@mui/material'
import React, { useState } from 'react'
import { z } from 'zod'

import Dialog from '@/app/component/atom/dialog'
import DialogHeader from '@/app/component/atom/dialog-header'
import { Form } from '@/app/component/form'
import SubmitButton from '@/app/component/form/submit-button'

import AutoDeletionPreview from './auto-deletion-preview'

type Props = {
  initialDays: number
  isEnabled: boolean
  isLoading: boolean
  open: boolean
  onClose: () => void
  onSave: (enabled: boolean, days: number) => void
}

const AutoDeletionDialog: React.FC<Props> = ({
  initialDays,
  isEnabled: initialIsEnabled,
  isLoading,
  open,
  onClose,
  onSave,
}) => {
  const [isEnabled, setIsEnabled] = useState(initialIsEnabled)
  const [daysValue, setDaysValue] = useState(initialDays)
  const defaultValues = React.useMemo(() => ({ days: initialDays }), [initialDays])

  React.useEffect(() => {
    if (open) {
      setIsEnabled(initialIsEnabled)
      setDaysValue(initialDays)
    }
  }, [initialIsEnabled, initialDays, open])

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEnabled(event.target.checked)
  }

  const handleDaysBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10)
    if (!Number.isNaN(value) && value > 0) {
      setDaysValue(value)
    }
  }

  const handleSubmit = React.useCallback(
    (values: AutoDeletionFormValues) => {
      onSave(isEnabled, values.days)
    },
    [onSave, isEnabled],
  )

  const fields = {
    days: {
      fieldProps: {
        label: 'Number of days to wait before auto-deleting a contact:',
        min: 1,
        name: 'days',
        type: 'number',
        disabled: !isEnabled,
        onBlur: handleDaysBlur,
      },
      type: 'text',
    },
  } as const

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogHeader title="Configure auto-deletion" onClose={onClose} />
      <DialogContent>
        <Typography sx={{ mt: 1, mb: 2 }}>
          You can automatically delete contacts that haven't been used or updated for a number of days. We consider a
          contact "used" when it receives or sends an SMS, Call, or WhatsApp message, or receives Airtime Topup.
        </Typography>

        <FormControlLabel
          control={<Switch checked={isEnabled} onChange={handleToggleChange} />}
          label="Enable auto-deletion"
          sx={{ mb: 2 }}
        />

        <Alert severity="info" sx={{ mb: 2 }}>
          <Typography variant="subtitle2" fontWeight="bold">
            Exemptions
          </Typography>
          You can protect individual contacts from auto-deletion, by editing a contact and toggling on "auto-delete
          protection". You can also edit groups and enable protection there to protect all contacts in that group.
        </Alert>

        <Form
          id="auto-deletion-form"
          defaultValues={defaultValues}
          fields={fields}
          name="auto-deletion"
          schema={schema}
          shouldReset
          onSubmit={handleSubmit}
          renderActions={({ isDirty, isValid }) => (
            <>
              <AutoDeletionPreview days={daysValue} isEnabled={isEnabled} />
              <Box display="flex" gap={1} justifyContent="flex-end" marginTop={2}>
                <Button onClick={onClose}>Cancel</Button>
                <SubmitButton
                  isDirty={isDirty || isEnabled !== initialIsEnabled}
                  isSubmitting={isLoading}
                  isValid={isValid}
                >
                  {initialIsEnabled ? 'Save' : 'Enable Auto-deletion'}
                </SubmitButton>
              </Box>
            </>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}

const schema = z.object({
  days: z.number().int().positive('Number of days must be greater than 0'),
})

export type AutoDeletionFormValues = z.infer<typeof schema>

export default AutoDeletionDialog
export type { Props as AutoDeletionDialogProps }
