import createCache from '@emotion/cache'
import { CacheProvider, css, Global } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { useEffect } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Provider } from 'react-redux'
import { TssCacheProvider } from 'tss-react'

import config from '@/config'

import CallDialogContainer from './module/auth/component/call-dialog.container'
import { TourProvider } from './module/tour/tour.context'
import { setupIntercom } from './service/intercom/setup'

momentDurationFormatSetup(moment)

const cache = createCache({ key: 'mui', prepend: true })
const ttsCache = createCache({ key: 'tts' })

export default function App({ store, sheets = {}, children }) {
  if (window.Cypress) {
    window.store = store
  }

  useEffect(() => {
    setupIntercom(config.intercom.appId)
  }, [])

  // GoogleReCaptchaProvider was moved to the top level to avoid errors from within the library while navigating between captcha and non-captcha pages
  // Ref: https://github.com/t49tran/react-google-recaptcha-v3/issues/182#issuecomment-1878544771
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={config.recaptcha.key}
      scriptProps={{
        async: true,
      }}
    >
      <CacheProvider value={cache}>
        <TssCacheProvider value={ttsCache}>
          <Provider store={store}>
            <ThemeProvider theme={sheets.theme}>
              <Global
                styles={css`
                  /* cyrillic-ext */
                  @font-face {
                    font-family: 'Source Sans Pro';
                    font-style: normal;
                    font-weight: 400;
                    src: url(/static/fonts/sourcesanspro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lqDY.woff2) format('woff2');
                    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
                  }
                  /* cyrillic */
                  @font-face {
                    font-family: 'Source Sans Pro';
                    font-style: normal;
                    font-weight: 400;
                    src: url(/static/fonts/sourcesanspro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qPK7lqDY.woff2) format('woff2');
                    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
                  }
                  /* greek-ext */
                  @font-face {
                    font-family: 'Source Sans Pro';
                    font-style: normal;
                    font-weight: 400;
                    src: url(/static/fonts/sourcesanspro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNK7lqDY.woff2) format('woff2');
                    unicode-range: U+1F00-1FFF;
                  }
                  /* greek */
                  @font-face {
                    font-family: 'Source Sans Pro';
                    font-style: normal;
                    font-weight: 400;
                    src: url(/static/fonts/sourcesanspro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qO67lqDY.woff2) format('woff2');
                    unicode-range: U+0370-03FF;
                  }
                  /* vietnamese */
                  @font-face {
                    font-family: 'Source Sans Pro';
                    font-style: normal;
                    font-weight: 400;
                    src: url(/static/fonts/sourcesanspro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qN67lqDY.woff2) format('woff2');
                    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
                      U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
                  }
                  /* latin-ext */
                  @font-face {
                    font-family: 'Source Sans Pro';
                    font-style: normal;
                    font-weight: 400;
                    src: url(/static/fonts/sourcesanspro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lqDY.woff2) format('woff2');
                    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB,
                      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                  }
                  /* latin */
                  @font-face {
                    font-family: 'Source Sans Pro';
                    font-style: normal;
                    font-weight: 400;
                    src: url(/static/fonts/sourcesanspro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2) format('woff2');
                    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308,
                      U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                  }

                  @font-face {
                    font-family: 'Material Icons';
                    font-style: normal;
                    font-weight: normal;
                    src: url(/static/fonts/materialicons.woff2) format('woff2');
                  }

                  .material-icons {
                    font-family: 'Material Icons';
                    font-weight: normal;
                    font-style: normal;
                    font-size: 24px;
                    line-height: 1;
                    letter-spacing: normal;
                    text-transform: none;
                    display: inline-block;
                    white-space: nowrap;
                    word-wrap: normal;
                    direction: ltr;
                    -webkit-font-smoothing: antialiased;
                  }
                `}
              ></Global>
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TourProvider>
                  {children}
                  <CallDialogContainer />
                </TourProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </Provider>
        </TssCacheProvider>
      </CacheProvider>
    </GoogleReCaptchaProvider>
  )
}
