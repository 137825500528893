import { Box, Button, styled, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'

import { OrgPlan } from '@/app/module/plans/types'
import { openIntercom } from '@/app/service/intercom/utils'
import { formatDate } from '@/app/service/util'
import { numberWithCommas, pluralize } from '@/app/service/util/format'

import ChangeSubscription from './change-subscription'

type Props = {
  activePlan: OrgPlan
  isOwner: boolean
}

const ActiveSubscription: React.FC<Props> = ({ activePlan, isOwner }) => {
  return (
    <Grid>
      <div>
        <Typography color="textSecondary" variant="caption" gutterBottom>
          Active plan
        </Typography>
        <Typography color="primary" data-testid="plan-label" variant="h5" gutterBottom>
          {activePlan.label}
        </Typography>
        {isOwner && <ChangeSubscription activePlan={activePlan} />}
      </div>
      <div>
        <Typography color="textSecondary" variant="caption" gutterBottom>
          Billing cycle
        </Typography>
        <Typography
          color="primary"
          data-testid="plan-billing"
          sx={{
            textTransform: 'capitalize',
          }}
          variant="h5"
          gutterBottom
        >
          {activePlan.billing}
        </Typography>
        {isOwner && activePlan.billing === 'monthly' && (
          <>
            {!activePlan.canceled && (
              <Box alignItems="center" display="flex" gap={1}>
                <Typography variant="body2">Want to switch to annual billing?</Typography>
                <Button color="primary" size="small" variant="contained" onClick={() => openIntercom('show')}>
                  Contact support
                </Button>
              </Box>
            )}
            {activePlan.canceled && (
              <Typography variant="body2">
                To change billing cycle, please first cancel the expiration of the current plan.
              </Typography>
            )}
          </>
        )}
      </div>
      <div>
        <Typography color="textSecondary" variant="caption" gutterBottom>
          Plan details
        </Typography>
        <Typography gutterBottom>
          <Typography color="primary" data-testid="plan-contacts" component="span">
            <b>{numberWithCommas(activePlan.storedContactsMax)}</b>
          </Typography>{' '}
          contacts included
        </Typography>
        <Typography gutterBottom>
          <Typography
            color="primary"
            component="span"
            data-testid="plan-countries"
            sx={{
              textTransform: 'capitalize',
            }}
          >
            <b>{activePlan.countriesIncluded}</b>
          </Typography>{' '}
          {activePlan.countriesIncluded !== 'global' &&
            `${pluralize(Number(activePlan.countriesIncluded), 'country', 'countries')} included `}
          (SMS and Voice calls)
        </Typography>
        <Typography gutterBottom>
          <Typography color="primary" component="span" data-testid="plan-users">
            <b>{activePlan.usersIncluded}</b>
          </Typography>{' '}
          {pluralize(activePlan.usersIncluded, 'user', 'users')} included
        </Typography>
      </div>
      <div>
        <Typography color="textSecondary" variant="caption" gutterBottom>
          Billing date
        </Typography>
        {!activePlan.canceled && (
          <>
            <Typography>Your plan renews on</Typography>
            <Typography color="primary" data-testid="plan-next" variant="h5" gutterBottom>
              {formatDate(activePlan.nextBillingDate)}
            </Typography>
          </>
        )}
        {activePlan.canceled && !!activePlan.end && (
          <>
            {moment().isBefore(activePlan.end) ? (
              <>
                <Typography>Your plan has been canceled and will expire on</Typography>
                <Typography color="primary" data-testid="plan-end" variant="h6">
                  {formatDate(activePlan.end)}
                </Typography>
              </>
            ) : (
              <>
                <Typography>Your plan expired on</Typography>
                <Typography color="primary" data-testid="plan-end" variant="h6">
                  {formatDate(activePlan.end)}
                </Typography>
              </>
            )}
          </>
        )}
      </div>
    </Grid>
  )
}

const Grid = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  overflow: 'hidden',

  '& > div': {
    padding: theme.spacing(2),

    '&:nth-of-type(odd)': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
}))

export default ActiveSubscription
