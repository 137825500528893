import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { compose, find, path, pathEq, pathOr } from 'ramda'
import { Component } from 'react'

import config from '@/config'

import PaymentForm from './form'

const { stripe: stripeConfig } = config

const fontsUrl = compose(path(['url']), find(pathEq(['name'], 'font-main')), pathOr([], ['vendor', 'css']))(config)

let stripePromise = null

export default class Payment extends Component {
  constructor(props) {
    super(props)
    if (!stripePromise) {
      loadStripe.setLoadParameters({ advancedFraudSignals: false })
      stripePromise = loadStripe(stripeConfig.key)
    }
  }

  render() {
    return (
      <Elements fonts={[{ cssSrc: fontsUrl }]} stripe={stripePromise}>
        <ElementsConsumer>
          {({ stripe, elements }) => <PaymentForm {...this.props} stripe={stripe} elements={elements} />}
        </ElementsConsumer>
      </Elements>
    )
  }
}
