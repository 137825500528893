import { Button, Icon, IconButton, InputAdornment, styled, TextField } from '@mui/material'
import React from 'react'

import PageTitle from '@/app/component/layout/page-title'
import { PhoneNumber } from '@/app/module/phonenumbers/types'

type Props = {
  phoneNumber: PhoneNumber

  onSubmit: (name: string) => Promise<boolean>
}

const PhoneNumberTitle: React.FC<Props> = ({ phoneNumber, onSubmit }) => {
  const [name, setName] = React.useState(phoneNumber.name)
  const [titleEditable, setTitleEditable] = React.useState(false)

  const handleSubmit = async () => {
    const success = await onSubmit(name)
    if (success) {
      setTitleEditable(false)
    }
  }

  if (!titleEditable) {
    return (
      <PageTitle>
        Configure Rules for{' '}
        <span className="phonenumber-title">
          "{name}" ({phoneNumber.phoneNumber})
        </span>
        <div
          style={{
            display: 'inline-block',
            marginLeft: '25px',
          }}
        >
          <Button size="small" onClick={() => setTitleEditable(true)} color="primary" variant="contained">
            <EditIcon fontSize="small">edit</EditIcon> Edit Name
          </Button>
        </div>
      </PageTitle>
    )
  }

  return (
    <PageTitle>
      <TitleField
        variant="standard"
        autoFocus
        id="outlined-helperText"
        margin="normal"
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            handleSubmit()
          }
        }}
        InputProps={{
          readOnly: !titleEditable,
          endAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={handleSubmit} edge="start">
                <Icon>check</Icon>
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(event) => {
          setName(event.target.value)
        }}
        value={name}
      />
    </PageTitle>
  )
}

const TitleField = styled(TextField)({
  width: '60%',
  margin: 0,

  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '1.25rem',
  fontWeight: '500',
  lineHeight: '1.6',
  padding: 0,
})

const EditIcon = styled(Icon)({
  fontSize: '16px',
  marginRight: '5px',
})

export default PhoneNumberTitle
