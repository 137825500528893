import { ACTION_NOTIFICATIONS_CREATE } from '@/app/module/notifications/definitions'
import { NotificationsData, NotificationsState } from '@/app/module/notifications/types'
import { createReducer } from '@/app/service/util'

import defaultState from './state'

export default createReducer(defaultState, {
  [ACTION_NOTIFICATIONS_CREATE]: (state: NotificationsState, { value }: { value: NotificationsData }) => ({
    ...state,
    data: value,
  }),
})
