import React from 'react'

import Voice from '@/app/module/campaigns/component/item/steps/content/voice'
import { ContentWhatsApp, VoiceProps } from '@/app/module/campaigns/types'

type Props = Omit<VoiceProps, 'id'> & {
  audio: string | null
  defaultLanguage: string
  info: string

  changeHandler: (value: ContentWhatsApp) => void
  removeMedia: () => void
}

const AudioMessage: React.FC<Props> = ({
  audio,

  changeHandler,
  removeMedia,
  ...restProps
}) => {
  return (
    <Voice
      {...restProps}
      enableTranscription={false}
      enableTTS={false}
      item={{
        id: 'whatsapp-audio',
        audio: {
          playfile: audio || '',
          translations: {},
        },
        changed: false,
      }}
      changeHandler={({ audio: updatedAudio }) => {
        if (!updatedAudio) {
          removeMedia()
        }
        changeHandler({
          text: null,
          image: null,
          video: null,
          document: null,
          audio: updatedAudio ? updatedAudio.playfile : '',
        })
      }}
    />
  )
}

export default AudioMessage
export type { Props as AudioMessageProps }
