import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import React from 'react'

import QuestionBox from '@/app/module/campaigns/component/item/steps/content/question'
import { DRAGTYPE } from '@/app/module/campaigns/definitions'
import {
  CampaignQuery,
  FilesState,
  LocalAction,
  LocalActionType,
  PersonalizationType,
  SenderIdOption,
  SnippetType,
} from '@/app/module/campaigns/types'
import { Action } from '@/app/module/campaigns/types/schemas/actions/all-actions'
import { addDescriptionToActions, getActionOptions } from '@/app/module/campaigns/utils/actions/options'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { unwrapActions, wrapActions } from '@/app/module/campaigns/utils/actions/wrap'
import { PhoneNumber } from '@/app/module/phonenumbers/types'
import { CountryTimezone, FileType, FileUploadType, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'

type Props = {
  campaignIds: SelectOption<number>[]
  countriesTimezones: CountryTimezone[]
  countryCode: string
  files: FilesState
  item: {
    actions: Action[]
  }
  orgId: number
  personalizationList: PersonalizationType
  phoneNumber: PhoneNumber
  senderIds: SenderIdOption[]
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  timezone: string
  timezones: TimezoneWithCountryCodeType
  title: string
  token: string
  type: string

  changeHandler: (actionsList: Action[]) => void
  getCampaigns: (payload: { token: string; orgId: number; query: CampaignQuery }) => Promise<void>
  getFile: (payload: { file: string; msgId: string; orgId: number; token: string }) => Promise<FileType>
  getSnippets: (payload: { token: string; orgId: number }) => Promise<void>
  onMicAccessError: () => void
  saveFile: (payload: { item: FileUploadType; msgId: string; orgId: number; token: string }) => Promise<FileType>
  saveSnippet: (payload: {
    orgId: number
    token: string
    item: Pick<SnippetType, 'category' | 'snippet'>
  }) => Promise<SnippetType>
}

const HookAction: React.FC<Props> = (props) => {
  const [voicePlayingId, setVoicePlayingId] = React.useState(null)

  const actions = React.useMemo(() => unwrapActions(props.item.actions), [props.item.actions])

  const getFileHandler = (msgId: string, file: string) => {
    const { token, orgId, getFile } = props

    return getFile({
      token,
      orgId,
      msgId,
      file,
    })
  }

  const saveFileHandler = (msgId: string, payload: FileUploadType) => {
    const { token, orgId, saveFile } = props

    return saveFile({
      token,
      orgId,
      msgId,
      item: payload,
    })
  }

  const getImageProps = (qid: string) => ({
    id: qid,
    loading: props.files.loadingItems.indexOf(qid) > -1,
    source: props.files.data[qid],
    getFile: (file: string) => getFileHandler(qid, file),
    saveFileHandler: (item: FileUploadType) => saveFileHandler(qid, item),
  })

  const getVoiceProps = (qid: string) => ({
    id: qid,
    loading: props.files.loadingItems.indexOf(qid) > -1,
    files: props.files.data,
    getFile: (file: string) => getFileHandler(qid, file),
    saveFileHandler: (item: FileUploadType) => saveFileHandler(qid, item),
    onMicAccessError: props.onMicAccessError,
    playId: voicePlayingId,
    filterType: 'whaatsapp',
    setPlaying: (val = null) => {
      setVoicePlayingId(val)
    },
  })

  const {
    personalizationList,
    title,
    changeHandler,
    senderIds,
    phoneNumber,
    getCampaigns,
    orgId,
    token,
    campaignIds,
    type = 'sms',
    files,
    timezone,
    timezones,
    countryCode,
    countriesTimezones,
    getSnippets,
    snippets,
    saveSnippet,
  } = props
  const filteredActionValues = actionValues(type)
  return (
    <div>
      <Divider
        style={{
          margin: '0rem -16px 1rem',
        }}
      />
      <div>
        <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          {title}
        </Typography>
        <div>
          <QuestionBox
            dragContext={`${DRAGTYPE}-actions`}
            actionsList={actions}
            actionsValues={addDescriptionToActions(filteredActionValues, ActionContext.Inbound)}
            type={type}
            changeHandler={({ actionsList }: { actionsList: LocalAction[] }) => changeHandler(wrapActions(actionsList))}
            personalizationList={{
              ...{
                message: [
                  ...(type === 'sms' || type === 'whatsapp'
                    ? [
                        {
                          label: 'Message Text',
                          value: 'message.text',
                        },
                      ]
                    : []),
                  {
                    label: `${type === 'sms' || type === 'whatsapp' ? 'Message' : 'Voice'} Virtual Number`,
                    value: 'message.virtualNumber',
                  },
                ],
              },
              ...personalizationList,
            }}
            senderIds={senderIds}
            phoneNumber={phoneNumber}
            defaultSenderId={{}}
            getCampaigns={getCampaigns}
            orgId={orgId}
            token={token}
            campaignIds={campaignIds}
            getImageProps={getImageProps}
            getVoiceProps={getVoiceProps}
            files={files.data}
            timezones={timezones}
            timezone={timezone}
            countriesTimezones={countriesTimezones}
            countryCode={countryCode}
            getSnippets={getSnippets}
            snippets={snippets}
            saveSnippet={saveSnippet}
          />
        </div>
      </div>
    </div>
  )
}

const actionValues = (type: string) =>
  getActionOptions([
    ...(type === 'whatsapp' ? [LocalActionType.SendWhatsApp] : []),
    LocalActionType.SubscribeToCampaign,
    LocalActionType.ResumeSubscription,
    LocalActionType.UpdateContact,
    LocalActionType.SendSMS,
    LocalActionType.SendSMSToPhoneNumbers,
    LocalActionType.SendEmail,
    LocalActionType.API,
    LocalActionType.UnsubscribeAll,
  ])

export default HookAction
