import { Card, CardContent, CardHeader, Link, styled, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import React from 'react'

import SubmitButton from '@/app/component/form/submit-button'

type Props = {
  isLoading: boolean

  onCreate: (date: string) => Promise<boolean>
}

const MIN_DATE = moment('2022-01-01T00:00:00Z')

const SmsReport: React.FC<Props> = ({ isLoading, onCreate }) => {
  const [date, setDate] = React.useState<moment.Moment>(moment())
  const [lastSubmitted, setLastSubmitted] = React.useState<moment.Moment | null>(null)

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault()

    if (!date) {
      return
    }

    const success = await onCreate(date.format('YYYY-MM'))
    if (success) {
      setLastSubmitted(date)
    }
  }

  const isBeforeMinDate = date && date.isBefore(MIN_DATE)
  const isAfterNow = date && date.isAfter(moment())
  const isSameAsLastSubmitted = !!lastSubmitted && lastSubmitted.isSame(date)

  const disabled = !date || isBeforeMinDate || isAfterNow || isSameAsLastSubmitted

  return (
    <Card>
      <CardHeader title="SMS Deliverability Report" />
      <CardContent>
        <Typography variant="subtitle1" gutterBottom>
          Create a report showing daily SMS delivery rates per country for any month. Access the report in{' '}
          <Link href="/exports">Exports</Link>
        </Typography>
        <Divider />
        <FormContainer onSubmit={handleSubmit}>
          <div>
            <DesktopDatePicker
              className="sms-report-date-picker"
              disableFuture
              format="MM/YYYY"
              label="Month and Year"
              minDate={MIN_DATE}
              value={date}
              views={['year', 'month']}
              onChange={(v) => v && setDate(v)}
            />
          </div>
          <Divider />
          <SubmitButton disabled={disabled} isDirty isSubmitting={isLoading} isValid>
            Generate
          </SubmitButton>
        </FormContainer>
      </CardContent>
    </Card>
  )
}

const Divider = styled('div')({
  height: '20px',
  width: '100%',
})

const FormContainer = styled('form')({
  display: 'block',
})

export default SmsReport
