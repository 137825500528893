import { Grid, Typography } from '@mui/material'
import React from 'react'

import { LocalAction, LocalActionType, LocalNonEmptyAction, PersonalizationType } from '@/app/module/campaigns/types'
import { getDisabledIndexesFrom } from '@/app/module/campaigns/utils/actions/disable-rules'
import { ActionOption } from '@/app/module/campaigns/utils/actions/options'
import { FilesType } from '@/app/types'

import ActionListItem from './action-list-item'

type Props = {
  actionOptions: ActionOption[]
  actions: LocalAction[]
  dragContext: string
  files: FilesType
  personalizationList: PersonalizationType

  onActionAddToLibrary: (action: LocalNonEmptyAction) => Promise<void>
  onActionChange: (value: LocalActionType, index: number) => void
  onActionMove: (from: number, to: number) => void
  onActionRemove: (index: number) => void
}

const ActionList: React.FC<Props> = ({
  actionOptions,
  actions,
  dragContext,
  files,
  personalizationList,
  onActionAddToLibrary,
  onActionChange,
  onActionMove,
  onActionRemove,
}) => {
  const disabledIndexesFrom: Record<string, number> = React.useMemo(() => getDisabledIndexesFrom(actions), [actions])

  return (
    <Grid container>
      <Grid
        item
        style={{
          marginTop: '20px',
        }}
        xs={2}
      >
        <Typography>List of Actions</Typography>
      </Grid>
      <Grid id="campaign-actions-list" className="campaign-actions-list" item xs={10}>
        {actions.map((action, index) => (
          <ActionListItem
            action={action}
            actionOptions={actionOptions}
            dragContext={dragContext}
            disabledIndexesFrom={disabledIndexesFrom}
            files={files}
            index={index}
            isLast={index === actions.length - 1}
            key={index}
            personalizationList={personalizationList}
            onAddToLibrary={onActionAddToLibrary}
            onChange={onActionChange}
            onMove={onActionMove}
            onRemove={onActionRemove}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default ActionList
