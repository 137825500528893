import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { propOr } from 'ramda'
import React, { useCallback, useMemo, useState } from 'react'

import { EmailAddressField } from '@/app/component/atom/form'
import EmailTextField from '@/app/component/atom/form/emailTextField'
import IconText from '@/app/component/layout/icon-text'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { mapPZBy } from '@/app/module/campaigns/component/helpers'
import { displayMap, Q_ACTIONS } from '@/app/module/campaigns/definitions'
import { transformFromPersonalization, transformToPersonalization } from '@/app/module/campaigns/pz-helpers'
import {
  LocalSendEmailAction,
  PersonalizationType,
  SendEmailData,
  SinglePersonalizationType,
} from '@/app/module/campaigns/types'

type Props = {
  personalizationList: PersonalizationType
  value: LocalSendEmailAction
  onClose: () => void
  onSave: (value: Partial<LocalSendEmailAction>) => void
}

const SendEmail: React.FC<Props> = ({ personalizationList = {}, value: { sendEmail }, onClose, onSave }) => {
  const pzMapByLabel = useMemo(() => mapPZBy(personalizationList, 'label'), [personalizationList])
  const pzMapByValue = useMemo(() => mapPZBy(personalizationList, 'value'), [personalizationList])
  const pzMapByConvertedValue = useMemo(() => {
    const keys = Object.keys(personalizationList)

    return keys.reduce(
      (map: Record<string, SinglePersonalizationType>, k: string) => ({
        ...map,
        ...personalizationList[k].reduce((obj, pzItem) => {
          const key = pzItem.label
          return {
            ...obj,
            [key]: pzItem,
          }
        }, {}),
      }),
      {},
    )
  }, [personalizationList])

  const [changed, setChanged] = useState(false)
  const [subject, setSubject] = useState(transformFromPersonalization(propOr('', 'subject')(sendEmail), pzMapByValue))
  const [message, setMessage] = useState(transformFromPersonalization(propOr('', 'message')(sendEmail), pzMapByValue))
  const [toMultiple, setToMultiple] = useState(getDefaultToMultipleValue(pzMapByValue, sendEmail))

  const handleEmailAddressesChange = useCallback((val: string[]) => {
    setToMultiple(val)
    setChanged(true)
  }, [])

  return (
    <CustomDialog open={true} onClose={onClose}>
      <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <Icon>{displayMap.sendEmail.icon}</Icon>
            <Typography variant="h6">{Q_ACTIONS.sendEmail.label}</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '24px' }}>
        <div
          style={{
            width: '80vw',
            minWidth: '300px',
            maxWidth: '100%',
          }}
        >
          <form
            id="send-email-form"
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <CardContent>
              <EmailAddressField
                label="Recipient email addresses"
                personalizationList={personalizationList}
                value={toMultiple}
                onChange={handleEmailAddressesChange}
              />
              <EmailTextField
                personalizationList={personalizationList}
                helperText="The following text will be included in the subject of the mail. You may use personalization to include for example the sender of the SMS."
                name="subject"
                required
                error={changed && !subject ? 'Subject is required' : ''}
                id="email-subject"
                type="text"
                label="Subject"
                value={subject}
                onChange={(val) => setSubject(val)}
              />
              <EmailTextField
                personalizationList={personalizationList}
                helperText="The following text will be included in the text message of the email. You may use personalization to include for example the sender or the entire SMS text in the email."
                name="message"
                multiline
                rows={6}
                id="email-message"
                type="text"
                label="Message"
                value={message}
                onChange={(val) => setMessage(val)}
              />
            </CardContent>
          </form>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!toMultiple.length || !subject}
          onClick={() =>
            onSave({
              sendEmail: {
                toMultiple: toMultiple.map((to: string) => transformToPersonalization(to, pzMapByLabel)),
                from: 'notifications@app.engagespark.com',
                message: transformToPersonalization(message, pzMapByConvertedValue),
                subject: transformToPersonalization(subject, pzMapByConvertedValue),
              },
            })
          }
        >
          Save
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}

const getDefaultToMultipleValue = (pzMap: Record<string, SinglePersonalizationType>, sendEmail?: SendEmailData) => {
  if (!sendEmail?.toMultiple) {
    return sendEmail?.to ? [transformFromPersonalization(sendEmail.to, pzMap)] : []
  }
  return sendEmail.toMultiple.map((item: string) => transformFromPersonalization(item, pzMap))
}

export default SendEmail
