import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import React from 'react'

import Tooltip from '@/app/component/atom/tooltip'
import IconText from '@/app/component/layout/icon-text'

type ActionMenuItemProps = {
  disabled?: boolean
  icon: string
  label: string
  tooltip?: string
  onClick: () => void
}

const ActionMenuItem = React.forwardRef<HTMLLIElement, ActionMenuItemProps>(
  ({ disabled = false, icon, label, tooltip = '', onClick }, ref) => (
    <Tooltip title={tooltip}>
      <div>
        <MenuItem disabled={disabled} ref={ref} onClick={onClick}>
          <IconText>
            <Icon>{icon}</Icon>
            <Typography>{label}</Typography>
          </IconText>
        </MenuItem>
      </div>
    </Tooltip>
  ),
)

type TableRowActionsProps = {
  config: Array<{
    icon: string
    label: string
    disabled?: boolean
    onClick: () => void
    tooltip?: string
  }>
}

const TableRowActions = ({ config }: TableRowActionsProps) => {
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLButtonElement | null>(null)

  return (
    <div
      style={{
        marginLeft: 'auto',
        marginRight: '34px',
      }}
    >
      <div>
        <Button
          variant="outlined"
          size="small"
          onClick={(e) => setMenuAnchor(e.currentTarget)}
          startIcon={<Icon>keyboard_arrow_down</Icon>}
        >
          Actions
        </Button>
      </div>
      <Menu
        className="campaigns-table-row-actions"
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        style={{ marginBottom: '96px' }}
      >
        {config.map(({ icon, label, disabled = false, onClick, tooltip }, i) => (
          <ActionMenuItem
            key={i}
            disabled={!menuAnchor || disabled}
            icon={icon}
            label={label}
            tooltip={tooltip}
            onClick={() => {
              if (menuAnchor) {
                onClick()
              }
              setMenuAnchor(null)
            }}
          />
        ))}
      </Menu>
    </div>
  )
}

export default TableRowActions
