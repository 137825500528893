import { createNotification } from '@/app/module/notifications/store/actions'
import {
  ACTION_AUTO_RECHARGE_CARD_FAILURE,
  ACTION_AUTO_RECHARGE_CARD_REQUEST,
  ACTION_AUTO_RECHARGE_CARD_SUCCESS,
  ACTION_CHARGE_CARD_FAILURE,
  ACTION_CHARGE_CARD_REQUEST,
  ACTION_CHARGE_CARD_SUCCESS,
  ACTION_DELETE_CARD_FAILURE,
  ACTION_DELETE_CARD_REQUEST,
  ACTION_DELETE_CARD_SUCCESS,
  ACTION_GENERATE_PROFORMA_INVOICE_FAILURE,
  ACTION_GENERATE_PROFORMA_INVOICE_REQUEST,
  ACTION_GENERATE_PROFORMA_INVOICE_SUCCESS,
  ACTION_GET_BALANCE_FAILURE,
  ACTION_GET_BALANCE_REQUEST,
  ACTION_GET_BALANCE_SUCCESS,
  ACTION_GET_CARDS_FAILURE,
  ACTION_GET_CARDS_REQUEST,
  ACTION_GET_CARDS_SUCCESS,
  ACTION_SAVE_CARD_FAILURE,
  ACTION_SAVE_CARD_REQUEST,
  ACTION_SAVE_CARD_SUCCESS,
  ACTION_TRANSFER_FUNDS_FAILURE,
  ACTION_TRANSFER_FUNDS_REQUEST,
  ACTION_TRANSFER_FUNDS_SUCCESS,
  ERROR_AUTO_RECHARGE_CARD_SERVER,
  ERROR_CHARGE_CARD_SERVER,
  ERROR_CHARGE_UNDER_REVIEW,
  ERROR_DELETE_CARD_SERVER,
  ERROR_GENERATE_PROFORMA_INVOICE,
  ERROR_GET_BALANCE_SERVER,
  ERROR_GET_CARDS_SERVER,
  ERROR_SAVE_CARD_SERVER,
  ERROR_TRANSFER_FUNDS,
  SUCCESS_CHARGE_CARD_SERVER,
  SUCCESS_DELETE_CARD_SERVER,
  SUCCESS_SAVE_CARD_SERVER,
  SUCCESS_TRANSFER_FUNDS,
  UNDER_REVIEW_CHARGE_CARD_SERVER,
} from '@/app/module/payments/definitions'
import request from '@/app/module/request'
import { getUser } from '@/app/module/user/store/actions'
import { selectOrgs } from '@/app/module/user/store/selectors'
import { createAsyncActions } from '@/app/service/util'
import { processErrors } from '@/app/service/util/errorCodes'

const {
  request: getCardsRequest,
  success: getCardsSuccess,
  failure: getCardsFailure,
} = createAsyncActions({
  request: ACTION_GET_CARDS_REQUEST,
  success: ACTION_GET_CARDS_SUCCESS,
  failure: ACTION_GET_CARDS_FAILURE,
})

const {
  request: saveCardItemRequest,
  success: saveCardItemSuccess,
  failure: saveCardItemFailure,
} = createAsyncActions({
  request: ACTION_SAVE_CARD_REQUEST,
  success: ACTION_SAVE_CARD_SUCCESS,
  failure: ACTION_SAVE_CARD_FAILURE,
})

const {
  request: chargeCardItemRequest,
  success: chargeCardItemSuccess,
  failure: chargeCardItemFailure,
} = createAsyncActions({
  request: ACTION_CHARGE_CARD_REQUEST,
  success: ACTION_CHARGE_CARD_SUCCESS,
  failure: ACTION_CHARGE_CARD_FAILURE,
})

const {
  request: deleteCardItemRequest,
  success: deleteCardItemSuccess,
  failure: deleteCardItemFailure,
} = createAsyncActions({
  request: ACTION_DELETE_CARD_REQUEST,
  success: ACTION_DELETE_CARD_SUCCESS,
  failure: ACTION_DELETE_CARD_FAILURE,
})

const {
  request: autoRechargeCardItemRequest,
  success: autoRechargeCardItemSuccess,
  failure: autoRechargeCardItemFailure,
} = createAsyncActions({
  request: ACTION_AUTO_RECHARGE_CARD_REQUEST,
  success: ACTION_AUTO_RECHARGE_CARD_SUCCESS,
  failure: ACTION_AUTO_RECHARGE_CARD_FAILURE,
})

const {
  request: getBalanceRequest,
  success: getBalanceSuccess,
  failure: getBalanceFailure,
} = createAsyncActions({
  request: ACTION_GET_BALANCE_REQUEST,
  success: ACTION_GET_BALANCE_SUCCESS,
  failure: ACTION_GET_BALANCE_FAILURE,
})

const {
  request: generateProformaInvoiceRequest,
  success: generateProformaInvoiceSuccess,
  failure: generateProformaInvoiceFailure,
} = createAsyncActions({
  request: ACTION_GENERATE_PROFORMA_INVOICE_REQUEST,
  success: ACTION_GENERATE_PROFORMA_INVOICE_SUCCESS,
  failure: ACTION_GENERATE_PROFORMA_INVOICE_FAILURE,
})

const {
  request: transferFundRequest,
  success: transferFundSuccess,
  failure: transferFundFailure,
} = createAsyncActions({
  request: ACTION_TRANSFER_FUNDS_REQUEST,
  success: ACTION_TRANSFER_FUNDS_SUCCESS,
  failure: ACTION_TRANSFER_FUNDS_FAILURE,
})

export const getCards =
  ({ token, orgId }) =>
  (dispatch) => {
    dispatch(getCardsRequest())

    return request.creditCards
      .getList({ token, orgId })
      .then((cards = []) => {
        dispatch(getCardsSuccess(cards))
      })
      .catch((err) => {
        dispatch(
          getCardsFailure({
            status: err.status,
            message: ERROR_GET_CARDS_SERVER,
          }),
        )
        return err
      })
  }

export const saveCard =
  ({ token, orgId, stripe, item = {}, card }) =>
  (dispatch) => {
    dispatch(saveCardItemRequest())

    const providerReq = stripe.createSource(card, {
      type: 'card',
      usage: 'reusable',
      currency: 'usd',
      owner: {
        name: item.name || '',
        email: item.email || '',
        address: item.address,
      },
    })

    return providerReq
      .then(({ source }) =>
        request.creditCards.saveItem({
          token,
          orgId,
          item: {
            label: `${source.card.brand} card: **** **** **** ${source.card.last4}`,
            stripeSourceId: source.id,
          },
        }),
      )
      .then((res) => {
        dispatch(saveCardItemSuccess(res))
        dispatch(
          createNotification({
            'charge-card': {
              type: 'success',
              message: SUCCESS_SAVE_CARD_SERVER,
            },
          }),
        )
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_SAVE_CARD_SERVER)
        dispatch(
          saveCardItemFailure({
            status: err.status,
            message: errorMessage,
          }),
        )
        dispatch(
          createNotification({
            'save-card': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

const chargeResponseMap = (amount) => ({
  success: SUCCESS_CHARGE_CARD_SERVER.replace('%AMOUNT%', amount),
  'on hold': UNDER_REVIEW_CHARGE_CARD_SERVER,
  declined: ERROR_CHARGE_CARD_SERVER,
})

export const chargeCard =
  ({ token, orgId, itemId, amount }) =>
  (dispatch) => {
    dispatch(chargeCardItemRequest())

    return request.creditCards
      .chargeItem({
        token,
        orgId,
        itemId,
        item: {
          amountUSDcent: amount * 100,
        },
      })
      .then(({ status }) => {
        dispatch(chargeCardItemSuccess())
        dispatch(getUser({ token }))
        dispatch(
          createNotification({
            'charge-card': {
              type: 'success',
              message: chargeResponseMap(amount)[status],
            },
          }),
        )
      })
      .catch((err) => {
        let errorMessage = processErrors(err, ERROR_CHARGE_CARD_SERVER)
        if (err.status === 423) {
          errorMessage = ERROR_CHARGE_UNDER_REVIEW
        }
        dispatch(
          chargeCardItemFailure({
            status: err.status,
            message: errorMessage,
          }),
        )
        dispatch(
          createNotification({
            'charge-card': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const deleteCard =
  ({ token, orgId, itemId }) =>
  (dispatch) => {
    dispatch(deleteCardItemRequest(itemId))

    return request.creditCards
      .deleteItem({ token, orgId, itemId })
      .then(() => {
        dispatch(deleteCardItemSuccess(itemId))
        dispatch(
          createNotification({
            'charge-card': {
              type: 'success',
              message: SUCCESS_DELETE_CARD_SERVER,
            },
          }),
        )
        return itemId
      })
      .catch((err) => {
        const errorMessage = processErrors(err, ERROR_DELETE_CARD_SERVER)
        dispatch(
          deleteCardItemFailure({
            id: itemId,
            status: err.status,
            message: errorMessage,
          }),
        )
        dispatch(
          createNotification({
            'delete-card': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return err
      })
  }

export const autoRechargeCard =
  ({ token, orgId, item }) =>
  (dispatch) => {
    dispatch(autoRechargeCardItemRequest())

    return request.balance
      .post({
        token,
        orgId,
        item,
      })
      .then(() => {
        dispatch(autoRechargeCardItemSuccess(item))
        dispatch(
          createNotification({
            'auto-charge-card': {
              type: 'success',
              message: `You successfully ${item.autoRecharge ? 'enabled' : 'disabled'} auto recharge.`,
            },
          }),
        )
      })
      .catch((err) => {
        dispatch(
          autoRechargeCardItemFailure({
            status: err.status,
            message: ERROR_AUTO_RECHARGE_CARD_SERVER,
          }),
        )
        dispatch(
          createNotification({
            'auto-charge-card': {
              type: 'error',
              message: ERROR_AUTO_RECHARGE_CARD_SERVER,
            },
          }),
        )
        return err
      })
  }

export const getBalance =
  ({ token, orgId }) =>
  (dispatch, getState) => {
    const orgs = selectOrgs(getState())
    const org = orgs.find((o) => o.orgId === orgId)
    if (!org?.permissions?.getBalance) {
      return Promise.resolve()
    }

    dispatch(getBalanceRequest())

    return request.balance
      .get({ token, orgId })
      .then((balance = {}) => {
        dispatch(getBalanceSuccess(balance))
      })
      .catch((err) => {
        dispatch(
          getBalanceFailure({
            status: err.status,
            message: ERROR_GET_BALANCE_SERVER,
          }),
        )
        return err
      })
  }

export const generateProformaInvoice =
  ({ token, orgId, item }) =>
  (dispatch) => {
    dispatch(generateProformaInvoiceRequest())
    return request.invoices
      .generateProforma({ token, orgId, item })
      .then((invoice = {}) => {
        dispatch(generateProformaInvoiceSuccess())
        return invoice
      })
      .catch((err) => {
        dispatch(generateProformaInvoiceFailure())
        dispatch(
          createNotification({
            'proforma-invoice': {
              type: 'error',
              message: ERROR_GENERATE_PROFORMA_INVOICE,
            },
          }),
        )
        return err
      })
  }

export const transferFunds =
  ({ token, orgId, item }) =>
  (dispatch) => {
    dispatch(transferFundRequest())
    return request.organizations
      .transferFunds({ token, orgId, item })
      .then((res) => {
        dispatch(transferFundSuccess())
        dispatch(getBalance({ token, orgId }))
        dispatch(getUser({ token }))
        dispatch(
          createNotification({
            'transfer-funds-success': {
              type: 'success',
              message: SUCCESS_TRANSFER_FUNDS,
            },
          }),
        )
        return res
      })
      .catch((err) => {
        dispatch(transferFundFailure(err))
        dispatch(
          createNotification({
            'transfer-funds-error': {
              type: 'error',
              message: processErrors(err, ERROR_TRANSFER_FUNDS),
            },
          }),
        )
        return err
      })
  }
