import { Grid, TextField, Typography } from '@mui/material'
import React from 'react'

import { Select } from '@/app/component/atom/form'
import Tooltip from '@/app/component/atom/tooltip'
import { repeatQuestionOptions } from '@/app/module/campaigns/definitions'
import { SpokenType } from '@/app/module/campaigns/types'

type Props = {
  spoken: SpokenType

  onChange: (value: Partial<SpokenType>) => void
}

const inputLabelProps = {
  shrink: true,
}

const ResponseVoiceSpokenConfig: React.FC<Props> = ({ spoken, onChange }) => {
  const handleChange = React.useCallback((value: Partial<SpokenType>) => onChange(value), [onChange])

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Typography>Configuration</Typography>
      </Grid>
      <Grid container item spacing={2} xs={10}>
        <Grid item xs={4}>
          <Tooltip title="How much time the contact has to speak their response. If the contact presses the stop key, the question will end, even if there is still time left in the duration. If the contact is silent for 3 seconds, we'll consider them done speaking, even if they haven't pressed the Stop Key.">
            <TextField
              variant="standard"
              id="maxSeconds"
              label="Response time limit (seconds)"
              helperText="How much time the contact has to speak their response. "
              type="number"
              InputLabelProps={inputLabelProps}
              value={spoken.maxSeconds ?? 10}
              onChange={(e) => {
                handleChange({
                  maxSeconds: Number(e.currentTarget.value),
                })
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Select
            id="campaign-item-stopkey"
            label="Select stop key"
            name="stop-key"
            info="The key the contact should press to end the recording."
            editable
            value={spoken.stopKey ?? '#'}
            values={repeatQuestionOptions}
            onChange={({ value }) => {
              handleChange({
                stopKey: value,
              })
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Tooltip title="How many seconds of silence should we wait before stopping recording?">
            <TextField
              variant="standard"
              id="silence"
              label="Silence (seconds)"
              helperText="How long to wait before stopping recording."
              type="number"
              InputLabelProps={inputLabelProps}
              value={spoken.silence ?? 3}
              onChange={(e) => {
                handleChange({
                  silence: Number(e.currentTarget.value),
                })
              }}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResponseVoiceSpokenConfig
