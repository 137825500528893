import React from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import Navigator, { TNavigatorItem } from '@/app/component/atom/navigator'
import SidebarLayout from '@/app/component/layout/sidebar-layout'
import { OrgReportFunction } from '@/app/module/reports/types'

import OrganizationReportContainer from './organization-report.container'
import SMSReportContainer from './sms-report.container'

type Props = {
  initialType: OrgReportFunction
}

const navigatorItems: TNavigatorItem<OrgReportFunction>[] = [
  {
    icon: 'money_off',
    label: 'Spend Report',
    path: OrgReportFunction.Spend,
  },
  {
    icon: 'analytics',
    label: 'Deliverability Report',
    path: OrgReportFunction.Deliverability,
  },
]

const AnalyticsContainer: React.FC<Props> = ({ initialType }) => {
  const navigate = useNavigate()
  const [activePath, setActivePath] = React.useState<OrgReportFunction>(initialType)

  const handleItemClick = React.useCallback(
    (path: OrgReportFunction) => {
      setActivePath(path)
      navigate(`/analytics?type=${path}`)
    },
    [navigate],
  )

  const sidebar = (
    <Navigator
      activePath={activePath}
      id="analytics"
      items={navigatorItems}
      title="Analytics"
      onItemClick={handleItemClick}
    />
  )

  const content = (
    <>
      {activePath === OrgReportFunction.Spend && <OrganizationReportContainer />}
      {activePath === OrgReportFunction.Deliverability && <SMSReportContainer />}
    </>
  )

  return <SidebarLayout sidebar={sidebar} content={content} />
}

export default AnalyticsContainer
