import { Typography } from '@mui/material'
import React from 'react'

import { Checkbox } from '@/app/component/atom/form'

type Props = {
  disabledText?: string
  registerTemplate: boolean

  onChange: (value: boolean) => void
}

const WhatsappTemplateCheckbox: React.FC<Props> = ({ disabledText, registerTemplate, onChange }) => (
  <Checkbox
    disabledText={disabledText}
    editable={!disabledText}
    label={
      <Typography color="textSecondary">
        Register this message with WhatsApp so it can be used outside the 24-hour message window.
      </Typography>
    }
    name="registerTemplate"
    value={registerTemplate.toString()}
    onChange={({ value }) => onChange(value === 'true')}
  />
)

export default WhatsappTemplateCheckbox
