import {
  ACTION_GET_DEMOS_FAILURE,
  ACTION_GET_DEMOS_REQUEST,
  ACTION_GET_DEMOS_SUCCESS,
  ACTION_SELECT_DEMO_ITEM_FAILURE,
  ACTION_SELECT_DEMO_ITEM_REQUEST,
  ACTION_SELECT_DEMO_ITEM_SUCCESS,
} from '@/app/module/demos/definitions'
import { createReducer } from '@/app/service/util'

import defaultState from './state'

export default createReducer(defaultState, {
  [ACTION_GET_DEMOS_REQUEST]: (state) => ({
    ...state,
    list: {
      ...state.list,
      loading: true,
    },
  }),
  [ACTION_GET_DEMOS_SUCCESS]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      data: value,
    },
  }),
  [ACTION_GET_DEMOS_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      error: value,
    },
  }),

  // SAVE
  [ACTION_SELECT_DEMO_ITEM_REQUEST]: (state) => ({
    ...state,
    list: {
      ...state.list,
      loading: true,
    },
  }),
  [ACTION_SELECT_DEMO_ITEM_SUCCESS]: (state) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
    },
  }),
  [ACTION_SELECT_DEMO_ITEM_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      error: value,
    },
  }),
})
