import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
  append,
  compose,
  equals,
  filter,
  findIndex,
  flatten,
  gt,
  identity,
  ifElse,
  insertAll,
  isEmpty,
  keys,
  length,
  move,
  path,
  pathOr,
  pick,
  propEq,
  remove,
  update,
  values,
} from 'ramda'
import { Component } from 'react'
import { v1 as uuid } from 'uuid'

import { Select } from '@/app/component/atom/form'
import Tooltip from '@/app/component/atom/tooltip'
import IconText from '@/app/component/layout/icon-text'
import { DEFAULT_SENDER_ID_NAME, DEFAULT_SENDER_ID_VALUE } from '@/app/definitions'
import { useItemContext } from '@/app/module/campaigns/component/item-provider'
import TranscribeToggle from '@/app/module/campaigns/component/item/steps/content/transcribe-toggle'
import { CAMPAIGN_CHANGE_DELAY, Q_ACTIONS, repeatQuestionOptions } from '@/app/module/campaigns/definitions'
import { postActionDisableRules } from '@/app/module/campaigns/utils/actions/disable-rules'

import Action from './action'
import ChoiceLabel from './choiceLabel'
import AddFromLibrary from './dialog/addFromLibrary'
import API from './dialog/api'
import JumpToQuestion from './dialog/jumpToQuestion'
import ResumeSubscription from './dialog/resume-subscription'
import SendEmail from './dialog/sendEmail'
import SMS from './dialog/sms'
import SMSToPhoneNumbers from './dialog/smsToPhoneNumbers'
import SubscribeToCampaign from './dialog/subscribeToCampaign'
import Topup from './dialog/topup'
import Transfer from './dialog/transfer'
import UpdateContact from './dialog/update-contact'
import Voice from './dialog/voice'
import Whatsapp from './dialog/whatsapp'

const defaultArray = []

export class CampaignContentQuestionBoxClass extends Component {
  state = {
    timeout: null,
    dialogActive: null,
    dialogSource: null,
    addFromLibraryActive: null,
    addFromLibrarySource: null,
    choiceList: pathOr([], ['choiceList'], this.props),
    actionsList: pathOr([], ['actionsList'], this.props),
    spoken: path(['spoken'], this.props),
  }

  item = null

  actionsMap = {
    [Q_ACTIONS.sms.type]: (index, value) =>
      this.setState({
        dialogSource: index,
        dialogActive: value,
      }),
    [Q_ACTIONS.api.type]: (index, value) =>
      this.setState({
        dialogSource: index,
        dialogActive: value,
      }),
    [Q_ACTIONS.voice.type]: (index, value) =>
      this.setState({
        dialogSource: index,
        dialogActive: value,
      }),
    [Q_ACTIONS.transfer.type]: (index, value) =>
      this.setState({
        dialogSource: index,
        dialogActive: value,
      }),
    [Q_ACTIONS.replay.type]: (index) =>
      this.changeHandler({
        actionsList: compose(
          ifElse(compose(equals(index + 1), length), append({ type: '' }), identity),
          update(index, { type: 'replay', replayQuestion: {}, id: uuid() }),
        )(this.state.actionsList),
      }),
    [Q_ACTIONS.resend.type]: (index) =>
      this.changeHandler({
        actionsList: compose(
          ifElse(compose(equals(index + 1), length), append({ type: '' }), identity),
          update(index, { type: 'resend', resendQuestion: {}, id: uuid() }),
        )(this.state.actionsList),
      }),
    [Q_ACTIONS.hangup.type]: (index) =>
      this.changeHandler({
        actionsList: compose(
          ifElse(compose(equals(index + 1), length), append({ type: '' }), identity),
          update(index, { type: 'hangup', hangup: {}, id: uuid() }),
        )(this.state.actionsList),
      }),
    [Q_ACTIONS.finishFlow.type]: (index) =>
      this.changeHandler({
        actionsList: compose(
          ifElse(compose(equals(index + 1), length), append({ type: '' }), identity),
          update(index, { type: 'finishFlow', finishFlow: {}, id: uuid() }),
        )(this.state.actionsList),
      }),
    [Q_ACTIONS.unsubscribeAll.type]: (index) =>
      this.changeHandler({
        actionsList: compose(
          ifElse(compose(equals(index + 1), length), append({ type: '' }), identity),
          update(index, { type: 'unsubscribeAll', unsubscribeAll: {}, id: uuid() }),
        )(this.state.actionsList),
      }),
    [Q_ACTIONS.subscribeToCampaign.type]: (index, value) =>
      this.setState({
        dialogSource: index,
        dialogActive: value,
      }),
    [Q_ACTIONS.sendSMSToPhoneNumbers.type]: (index, value) =>
      this.setState({
        dialogSource: index,
        dialogActive: value,
      }),
    [Q_ACTIONS.jump.type]: (index, value) =>
      this.setState({
        dialogSource: index,
        dialogActive: value,
      }),
    [Q_ACTIONS.topup.type]: (index, value) =>
      this.setState({
        dialogSource: index,
        dialogActive: value,
      }),
    [Q_ACTIONS.updateContact.type]: (index, value) =>
      this.setState({
        dialogSource: index,
        dialogActive: value,
      }),
    [Q_ACTIONS.sendEmail.type]: (index, value) =>
      this.setState({
        dialogSource: index,
        dialogActive: value,
      }),
    [Q_ACTIONS.sendWhatsapp.type]: (index, value) =>
      this.setState({
        dialogSource: index,
        dialogActive: value,
      }),
    [Q_ACTIONS.pauseSubscription.type]: (index) =>
      this.changeHandler({
        actionsList: compose(
          ifElse(compose(equals(index + 1), length), append({ type: '' }), identity),
          update(index, { type: 'pauseSubscription', setPaused: { current: true, paused: true }, id: uuid() }),
        )(this.state.actionsList),
      }),
    [Q_ACTIONS.resumeSubscription.type]: (index, value) =>
      this.setState({
        dialogSource: index,
        dialogActive: value,
      }),
    addFromLibrary: (index, value) =>
      this.setState({
        addFromLibrarySource: index,
        addFromLibraryActive: value,
      }),
  }

  dialogPropsMap = {
    [Q_ACTIONS.sms.type]: () => ({
      personalizationList: this.props.personalizationList,
      senderIds: [
        {
          label: DEFAULT_SENDER_ID_NAME,
          value: DEFAULT_SENDER_ID_VALUE,
        },
        ...this.props.senderIds,
      ],
      phoneNumber: this.props.phoneNumber,
      defaultSenderId: this.props.defaultSenderId,
      languages: this.props.languages,
      defaultLanguage: this.props.defaultLanguage,
    }),
    [Q_ACTIONS.transfer.type]: () => ({
      countriesTimezones: this.props.countriesTimezones,
      countryCode: this.props.countryCode,
    }),
    [Q_ACTIONS.sendSMSToPhoneNumbers.type]: () => ({
      personalizationList: this.props.personalizationList,
      senderIds: [
        {
          label: DEFAULT_SENDER_ID_NAME,
          value: DEFAULT_SENDER_ID_VALUE,
        },
        ...this.props.senderIds,
      ],
      phoneNumber: this.props.phoneNumber,
      defaultSenderId: isEmpty(this.props.defaultSenderId)
        ? {}
        : {
            value: 'senderId',
            label: 'SMS Messages Sender ID',
          },
      languages: this.props.languages,
      defaultLanguage: this.props.defaultLanguage,
    }),
    [Q_ACTIONS.subscribeToCampaign.type]: () => ({
      getCampaigns: this.props.getCampaigns,
      token: this.props.token,
      orgId: this.props.orgId,
      campaignIds: this.props.campaignIds,
      timezone: this.props.timezone,
      timezones: this.props.timezones,
      countriesTimezones: this.props.countriesTimezones,
      countryCode: this.props.countryCode,
    }),
    [Q_ACTIONS.voice.type]: (index) => ({
      voiceProps: this.props.getVoiceProps(`${this.props.voiceId}-${index}`),
      personalizationList: this.props.personalizationList,
      languages: this.props.languages,
      defaultLanguage: this.props.defaultLanguage,
    }),
    [Q_ACTIONS.jump.type]: () => ({
      parts: this.props.parts,
      index: this.props.index,
    }),
    [Q_ACTIONS.updateContact.type]: () => ({
      personalizationList: this.props.personalizationList,
      question: pathOr(false, ['question'], this.props),
      openResponse: pathOr(false, ['openResponse'], this.props),
      invalid: pathOr(false, ['invalid'], this.props),
      transcribeAvailable: this.props.hasTranscribe || false,
      transcribeEnabled: this.props.transcribeEnabled || false,
      type: pathOr(false, ['type'], this.props),
    }),
    [Q_ACTIONS.sendEmail.type]: () => ({
      personalizationList: this.props.personalizationList,
    }),
    [Q_ACTIONS.sendWhatsapp.type]: (index) => ({
      personalizationList: this.props.personalizationList,
      senderIds: this.props.whatsappSenderIds,
      phoneNumber: this.props.phoneNumber,
      imageProps: this.props.getImageProps(`${this.props.imageId}-${index}`),
      voiceProps: this.props.getVoiceProps(`${this.props.voiceId}-${index}`, false),
      languages: this.props.languages,
      defaultLanguage: this.props.defaultLanguage,
    }),
    [Q_ACTIONS.resumeSubscription.type]: () => ({
      campaignIds: this.props.campaignIds,
      orgId: this.props.orgId,
      token: this.props.token,
      getCampaigns: this.props.getCampaigns,
    }),
  }

  componentWillUnmount() {
    const { timeout } = this.state
    if (timeout) {
      clearTimeout(timeout)
    }
    if (this.item) {
      this.props.changeHandler(this.item)
    }
  }

  componentDidUpdate(prevProps) {
    const { choiceList, actionsList, spoken } = this.props

    if (!equals(spoken, prevProps.spoken)) {
      this.setState({
        spoken,
      })
    }

    if (!equals(choiceList, prevProps.choiceList) || !equals(actionsList, prevProps.actionsList)) {
      this.setState({
        choiceList,
        actionsList: actionsList || [],
      })
    }
  }

  changeHandler = (item = {}) => {
    const { changeHandler } = this.props
    if (this.state.timeout) {
      clearTimeout(this.state.timeout)
    }
    this.item = item

    this.setState({
      ...item,
      timeout: setTimeout(() => {
        changeHandler(item)
        this.item = null
        this.setState((state) => ({
          timeout: clearTimeout(state.timeout),
        }))
      }, CAMPAIGN_CHANGE_DELAY),
    })
  }

  addToLibrary = (action = {}) => {
    const { saveSnippet, orgId, token } = this.props
    return saveSnippet({
      orgId,
      token,
      item: {
        category: 'action',
        snippet: action,
      },
    })
  }

  handleChoiceListChange = (choiceList) => {
    this.changeHandler({
      choiceList,
    })
  }

  renderAddFromLibraryDialog = () => {
    const { actionsValues, orgId, personalizationList, snippets, token, getSnippets } = this.props
    const { actionsList, addFromLibraryActive, addFromLibrarySource } = this.state

    if (!addFromLibraryActive) {
      return null
    }

    return (
      <AddFromLibrary
        orgId={orgId}
        personalizationList={personalizationList}
        snippets={snippets}
        token={token}
        valuesList={actionsValues}
        getSnippets={getSnippets}
        onClose={() => {
          this.setState({
            addFromLibraryActive: null,
            addFromLibrarySource: null,
          })
        }}
        onPreview={(value, index) => {
          if (this.actionsMap[value.type]) {
            this.actionsMap[value.type](index, value)
          }
        }}
        onSave={(value = []) => {
          const newValue = value.map((item) => ({
            ...item,
            id: uuid(),
          }))
          this.changeHandler({
            addFromLibraryActive: null,
            addFromLibrarySource: null,
            actionsList: compose(
              ifElse(compose(equals(addFromLibrarySource + 1), length), append({ type: '' }), identity),
              insertAll(addFromLibrarySource, newValue),
            )(actionsList),
          })
        }}
      />
    )
  }

  renderActionDialog = () => {
    const { personalizationList } = this.props
    const { actionsList, dialogActive, dialogSource } = this.state
    const ActionDialog = actionDialogMap[dialogActive]
    if (!ActionDialog) {
      return null
    }
    return (
      <ActionDialog
        countryCode={this.props.countryCode}
        personalizationList={personalizationList}
        value={actionsList[dialogSource]}
        {...pathOr(() => ({}), [dialogActive], this.dialogPropsMap)(dialogSource)}
        onSave={(value = {}) => {
          const newAction = {
            id: actionsList[dialogSource].id || uuid(),
            type: dialogActive,
            ...value,
          }
          this.changeHandler({
            dialogActive: null,
            dialogSource: null,
            actionsList: compose(
              ifElse(compose(equals(dialogSource + 1), length), append({ type: '' }), identity),
              update(dialogSource, newAction),
            )(actionsList),
          })
        }}
        onClose={() => {
          this.setState({
            dialogActive: null,
            dialogSource: null,
          })
        }}
      />
    )
  }

  renderSpoken = () => {
    const { hasTranscribe } = this.props
    const { spoken } = this.state
    if (!spoken) {
      return null
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Typography style={{ marginTop: '16px' }}>Configuration</Typography>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title="Transcribe answer with AI">
            <div>
              <TranscribeToggle
                disabled={!hasTranscribe}
                transcribe={spoken.transcribe}
                onChange={(value) => {
                  this.changeHandler({
                    spoken: {
                      ...spoken,
                      transcribe: value,
                    },
                  })
                }}
              />
            </div>
          </Tooltip>
        </Grid>
        <Grid container item spacing={2} xs={8}>
          <Grid item xs={4}>
            <Tooltip title="How much time the contact has to speak their response. If the contact presses the stop key, the question will end, even if there is still time left in the duration. If the contact is silent for 3 seconds, we'll consider them done speaking, even if they haven't pressed the Stop Key.">
              <TextField
                variant="standard"
                id="maxSeconds"
                label="Response time limit (seconds)"
                helperText="How much time the contact has to speak their response. "
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={pathOr(10, ['maxSeconds'], spoken)}
                onChange={(e) => {
                  this.changeHandler({
                    spoken: {
                      ...spoken,
                      maxSeconds: Number(e.currentTarget.value),
                    },
                  })
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <Select
              id="campaign-item-stopkey"
              label="Select stop key"
              info="The key the contact should press to end the recording."
              editable={true}
              value={pathOr('#', ['stopKey'], spoken)}
              values={repeatQuestionOptions}
              onChange={({ value }) => {
                this.changeHandler({
                  spoken: {
                    ...spoken,
                    stopKey: value,
                  },
                })
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Tooltip title="How many seconds of silence should we wait before stopping recording?">
              <TextField
                variant="standard"
                id="silence"
                label="Silence (seconds)"
                helperText="How long to wait before stopping recording."
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={pathOr(3, ['silence'], spoken)}
                onChange={(e) => {
                  this.changeHandler({
                    spoken: {
                      ...spoken,
                      silence: Number(e.currentTarget.value),
                    },
                  })
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  renderChoiceTag = () => {
    const { ChoiceTag, choiceLabel, isChoiceDisabled } = this.props
    const { choiceList } = this.state
    if (!ChoiceTag) {
      return null
    }
    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            width: '140px',
            marginTop: '15px',
          }}
        >
          <Typography>{choiceLabel || 'List of choices'}</Typography>
        </div>
        <div
          style={{
            width: '100%',
          }}
        >
          <ChoiceTag disabled={isChoiceDisabled} tagList={choiceList} onChange={this.handleChoiceListChange} />
        </div>
      </div>
    )
  }

  renderActionList = () => {
    const {
      dragContext,
      actionsValues,
      // key value pairs where key is an action.type and value is a list of action.type
      // when the key found in the current list of actions, the action types matching
      // the value list will be disabled after the matchin index
      files = {},
    } = this.props

    const disabledIndexesFrom = Object.keys(postActionDisableRules).reduce((acc, key) => {
      const i = findIndex(propEq('type', key), this.state.actionsList)
      return i === -1
        ? acc
        : {
            ...acc,
            [key]: i,
          }
    }, {})

    return (
      <Grid container>
        <Grid
          item
          style={{
            marginTop: '20px',
          }}
          xs={2}
        >
          <Typography>List of Actions</Typography>
        </Grid>
        <Grid id="campaign-actions-list" className="campaign-actions-list" item xs={10}>
          {this.state.actionsList.map((action, index) => {
            const last = this.state.actionsList.length - 1
            const disabledKeys = compose(keys, filter(gt(index)))(disabledIndexesFrom)
            // collect all values that should be disabled for this action item
            // based on the rules prop
            const disabledValues = compose(flatten, values, pick(disabledKeys))(postActionDisableRules)

            const valuesList = actionsValues.map((item) => ({
              ...item,
              disabled: disabledValues.indexOf(item.value) > -1,
            }))

            return (
              <Action
                key={index}
                index={index}
                dragContext={dragContext}
                action={action}
                isLast={index === last}
                values={valuesList}
                parts={this.props.parts}
                personalizationList={this.props.personalizationList}
                files={files}
                onMove={(fromIndex) => {
                  if (fromIndex !== last && index !== last) {
                    this.changeHandler({
                      actionsList: move(fromIndex, index, this.state.actionsList),
                    })
                  }
                }}
                onRemove={() => {
                  const isLast = index === last
                  if (action.type && !isLast) {
                    this.changeHandler({
                      actionsList: remove(index, 1, this.state.actionsList),
                    })
                  }
                }}
                onChange={({ value }) => {
                  if (this.actionsMap[value]) {
                    this.actionsMap[value](index, value)
                  }
                }}
                onCopy={(elem) => this.addToLibrary(elem)}
              />
            )
          })}
        </Grid>
      </Grid>
    )
  }

  render() {
    const { info, onClose, isCloseDisabled } = this.props

    const isClosable = typeof onClose === 'function'
    const hasLabel = !!this.props.ChoiceTag && !this.props.disableLabel

    return (
      <div
        style={{
          backgroundColor: 'rgb(245, 245, 245)',
          padding: '12px',
          margin: '12px 0',
        }}
      >
        {this.renderAddFromLibraryDialog()}
        {this.renderActionDialog()}
        {isClosable && (
          <Grid container justifyContent={hasLabel ? 'space-between' : 'flex-end'}>
            {hasLabel && (
              <>
                <Grid
                  item
                  style={{
                    height: '32px',
                  }}
                >
                  <ChoiceLabel label={this.props.label} changeHandler={this.changeHandler} />
                </Grid>
              </>
            )}
            <Grid item>
              <IconButton
                className="campaign-question-onAnswer-choice-delete-btn"
                data-testid="campaign-question-answer-delete-btn"
                onClick={onClose}
                disabled={isCloseDisabled}
                size="small"
              >
                <Icon fontSize="small">close</Icon>
              </IconButton>
            </Grid>
          </Grid>
        )}
        {this.renderSpoken()}
        {this.renderChoiceTag()}
        {this.renderActionList()}
        {info && (
          <div
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              padding: '0.35rem 0',
            }}
          >
            <Divider style={{ margin: '1rem -12px' }} />
            <IconText>
              <Icon>info</Icon>
              {info}
            </IconText>
          </div>
        )}
      </div>
    )
  }
}

const actionDialogMap = {
  addFromLibrary: AddFromLibrary,
  api: API,
  jump: JumpToQuestion,
  sendEmail: SendEmail,
  sendSMSToPhoneNumbers: SMSToPhoneNumbers,
  sendWhatsapp: Whatsapp,
  sms: SMS,
  subscribeToCampaign: SubscribeToCampaign,
  topup: Topup,
  transfer: Transfer,
  updateContact: UpdateContact,
  voice: Voice,
  resumeSubscription: ResumeSubscription,
}

const CampaignContentQuestionBox = (props) => {
  const { item } = useItemContext()

  const parts = pathOr(defaultArray, ['variables', 'parts'], item)

  return <CampaignContentQuestionBoxClass {...props} parts={parts} />
}

export default CampaignContentQuestionBox
