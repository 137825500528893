import { cannot } from '@/app/helpers'
import { changeActionsLang, LanguageConfig, processAudio, processText } from '@/app/module/campaigns/language-helpers'
import {
  CampaignTemplateType,
  CATICampaignItem,
  CATIPart,
  DripCampaignItem,
  DripPart,
  IVRCampaignItem,
  LegacyMultimessageItem,
  PartTypes,
  SMSSurveyCampaignItem,
  SMSSurveyPart,
  WhatsAppSurveyCampaignItem,
  WhatsAppSurveyPart,
} from '@/app/module/campaigns/types'

import {
  changeActionsPartLang,
  changeAPIPartLang,
  changeCATIMessagePartLang,
  changeCATIQuestionPartLang,
  changeConditionActionsLang,
  changeSMSMessagePartLang,
  changeSMSQuestionPartLang,
  changeTopupPartLang,
  changeVoiceMessagePartLang,
  changeVoiceQuestionPartLang,
  changeWhatsAppMessagePartLang,
  changeWhatsAppQuestionPartLang,
} from './parts'

export function changeLanguage(
  item: LegacyMultimessageItem,
  newLanguages: LanguageConfig['newLanguages'],
  newDefaultLanguage: LanguageConfig['newDefaultLanguage'],
): LegacyMultimessageItem {
  const languageConfig: LanguageConfig = {
    languages: item.uiStore?.languages || [],
    newLanguages,
    defaultLanguage: item.uiStore?.defaultLanguage || '',
    newDefaultLanguage,
  }

  switch (item.type) {
    case CampaignTemplateType.CATI:
      return changeCATILanguage(item, languageConfig)
    case CampaignTemplateType.Drip:
      return changeDripLanguage(item, languageConfig)
    case CampaignTemplateType.SMSSurvey:
      return changeSMSSurveyLanguage(item, languageConfig)
    case CampaignTemplateType.VoiceSurvey:
      return changeIVRLanguage(item, languageConfig)
    case CampaignTemplateType.WhatsAppSurvey:
      return changeWhatsAppSurveyLanguage(item, languageConfig)
    default:
      return cannot(item)
  }
}

function changeCATILanguage(item: CATICampaignItem, languageConfig: LanguageConfig): CATICampaignItem {
  const processedParts = item.variables.parts.map((part: CATIPart) => {
    if (PartTypes.CATIMessage in part) {
      return changeCATIMessagePartLang(part, languageConfig)
    }

    if (PartTypes.CATIQuestion in part) {
      return changeCATIQuestionPartLang(part, languageConfig)
    }

    if (PartTypes.ActionsOnly in part) {
      return changeActionsPartLang(part, languageConfig)
    }

    return cannot(part)
  })

  return {
    ...item,
    variables: {
      ...item.variables,
      parts: processedParts,
    },
  }
}

function changeDripLanguage(item: DripCampaignItem, languageConfig: LanguageConfig): DripCampaignItem {
  const processedParts = item.variables.parts.map((part: DripPart) => {
    if (PartTypes.SMSMessage in part) {
      return changeSMSMessagePartLang(part, languageConfig)
    }

    if (PartTypes.SMSQuestion in part) {
      return changeSMSQuestionPartLang(part, languageConfig)
    }

    if (PartTypes.VoiceMessage in part) {
      const processedPart = changeVoiceMessagePartLang(part, languageConfig)

      if (part.callResultActions) {
        processedPart.callResultActions = changeConditionActionsLang(part.callResultActions, languageConfig)
      }

      return processedPart
    }

    if (PartTypes.VoiceQuestion in part) {
      const processedPart = changeVoiceQuestionPartLang(part, languageConfig)

      if (part.callResultActions) {
        processedPart.callResultActions = changeConditionActionsLang(part.callResultActions, languageConfig)
      }

      return processedPart
    }

    if (PartTypes.WhatsAppMessage in part) {
      return changeWhatsAppMessagePartLang(part, languageConfig)
    }

    if (PartTypes.WhatsAppQuestion in part) {
      return changeWhatsAppQuestionPartLang(part, languageConfig)
    }

    if (PartTypes.ActionsOnly in part) {
      return changeActionsPartLang(part, languageConfig)
    }

    if (PartTypes.ApiCall in part) {
      return changeAPIPartLang(part, languageConfig)
    }

    if (PartTypes.Topup in part) {
      return changeTopupPartLang(part, languageConfig)
    }

    return cannot(part)
  })

  const processedOnTransferFailed = item.variables.onTransferFailed
    ? {
        ...item.variables.onTransferFailed,
        actions: item.variables.onTransferFailed.actions
          ? changeActionsLang({ actions: item.variables.onTransferFailed.actions }, languageConfig).actions
          : null,
      }
    : item.variables.onTransferFailed

  return {
    ...item,
    variables: {
      ...item.variables,
      parts: processedParts,
      onTransferFailed: processedOnTransferFailed,
    },
  }
}

function changeSMSSurveyLanguage(item: SMSSurveyCampaignItem, languageConfig: LanguageConfig): SMSSurveyCampaignItem {
  const processedParts = item.variables.parts.map((part: SMSSurveyPart) => {
    if (PartTypes.SMSMessage in part) {
      return changeSMSMessagePartLang(part, languageConfig)
    }

    if (PartTypes.SMSQuestion in part) {
      return changeSMSQuestionPartLang(part, languageConfig)
    }

    if (PartTypes.ActionsOnly in part) {
      return changeActionsPartLang(part, languageConfig)
    }

    if (PartTypes.ApiCall in part) {
      return changeAPIPartLang(part, languageConfig)
    }

    return cannot(part)
  })

  const processedWelcome = item.variables.welcome ? processText(item.variables.welcome, languageConfig) : null

  return {
    ...item,
    variables: {
      ...item.variables,
      parts: processedParts,
      welcome: processedWelcome,
    },
  }
}

function changeIVRLanguage(item: IVRCampaignItem, languageConfig: LanguageConfig): IVRCampaignItem {
  const processedParts = item.variables.parts.map((part) => {
    if (PartTypes.VoiceMessage in part) {
      return changeVoiceMessagePartLang(part, languageConfig)
    }

    if (PartTypes.VoiceQuestion in part) {
      return changeVoiceQuestionPartLang(part, languageConfig)
    }

    if (PartTypes.ActionsOnly in part) {
      return changeActionsPartLang(part, languageConfig)
    }

    return cannot(part)
  })

  const processedWelcome = item.variables.welcome ? processAudio(item.variables.welcome, languageConfig) : null

  const processedReconnectActions = changeConditionActionsLang(item.variables.reconnectActions, languageConfig)
  const processedCallResultActions = changeConditionActionsLang(item.variables.callResultActions, languageConfig)

  return {
    ...item,
    variables: {
      ...item.variables,
      parts: processedParts,
      welcome: processedWelcome,
      reconnectActions: processedReconnectActions,
      callResultActions: processedCallResultActions,
    },
  }
}

function changeWhatsAppSurveyLanguage(
  item: WhatsAppSurveyCampaignItem,
  languageConfig: LanguageConfig,
): WhatsAppSurveyCampaignItem {
  const processedParts = item.variables.parts.map((part: WhatsAppSurveyPart) => {
    if (PartTypes.WhatsAppMessage in part) {
      return changeWhatsAppMessagePartLang(part, languageConfig)
    }

    if (PartTypes.WhatsAppQuestion in part) {
      return changeWhatsAppQuestionPartLang(part, languageConfig)
    }

    if (PartTypes.ActionsOnly in part) {
      return changeActionsPartLang(part, languageConfig)
    }

    if (PartTypes.ApiCall in part) {
      return changeAPIPartLang(part, languageConfig)
    }

    return cannot(part)
  })

  return {
    ...item,
    variables: {
      ...item.variables,
      parts: processedParts,
    },
  }
}
