import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'

import Loader from '@/app/component/atom/loader'
import { useGetOrganization } from '@/app/module/user/hooks/use-get-organization'
import { useUpdateOrganization } from '@/app/module/user/hooks/use-update-organization'

import AutoDeletionDialog from './auto-deletion-dialog'

type Props = {
  orgId: number
}

const AutoDeletion: React.FC<Props> = ({ orgId }) => {
  const { data: organization, isFetching, isLoading, isUninitialized } = useGetOrganization(orgId)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [updateOrganization, { isLoading: isUpdating }] = useUpdateOrganization()

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleSave = async (enabled: boolean, newDays: number) => {
    await updateOrganization(orgId, {
      contactsAutodelete: enabled,
      contactsAutodeleteDays: newDays,
    })
    setIsModalOpen(false)
  }

  const isEnabled = !!organization?.contactsAutodelete
  const days = organization?.contactsAutodeleteDays || 365
  const loading = isFetching || isLoading || isUninitialized

  return (
    <Box sx={{ mt: 3 }}>
      {!!organization && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <Typography variant="subtitle1">
              Auto-delete contacts is{' '}
              {isEnabled ? (
                <Typography component="span" color="success.main" fontWeight="bold">
                  ENABLED
                </Typography>
              ) : (
                <Typography component="span" color="error.main" fontWeight="bold">
                  DISABLED
                </Typography>
              )}
            </Typography>
            <Button
              variant={isEnabled ? 'text' : 'contained'}
              size="small"
              disabled={isUpdating}
              onClick={() => setIsModalOpen(true)}
            >
              {isEnabled ? 'Edit settings' : 'Enable'}
            </Button>
          </Box>

          <Typography data-testid="auto-deletion-label">
            {isEnabled ? (
              <>
                Contacts are <b>deleted</b> automatically after <b>{days}</b> days, unless protected
              </>
            ) : (
              <>
                Contacts are <b>NOT</b> automatically deleted
              </>
            )}
          </Typography>

          <AutoDeletionDialog
            initialDays={days}
            isEnabled={isEnabled}
            isLoading={isUpdating}
            open={isModalOpen}
            onClose={handleModalClose}
            onSave={handleSave}
          />
        </>
      )}
      {loading && <Loader />}
    </Box>
  )
}

export default AutoDeletion
export type { Props as AutoDeletionProps }
