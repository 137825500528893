import { styled } from '@mui/material'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import moment from 'moment-timezone'
import React from 'react'

import { DateTime, Input, Select, Time } from '@/app/component/atom/form'
import TimezonePicker from '@/app/component/atom/timezone-picker'
import Tooltip from '@/app/component/atom/tooltip'
import { changeTimezoneAndKeepDate } from '@/app/module/campaigns/helpers'
import { DelayState, TimeUnit } from '@/app/module/campaigns/types'
import { noTimezoneDateTimeFormat } from '@/app/service/util/format'
import { CountryTimezone, TimezoneWithCountryCodeType } from '@/app/types'

const ERROR_DELAY: string = 'Delay has to be bigger than one minute.'

const defaultTime: string = '09:00'
const defaultDay: string = 'monday'

const defaultDaysOfWeek = { [defaultDay]: true }

const getUnitList = (isFirst: boolean) => [
  {
    label: 'immediately',
    value: TimeUnit.Immediate,
    disabled: !isFirst,
  },
  {
    label: 'seconds',
    value: 'second',
  },
  {
    label: 'minutes',
    value: 'minute',
  },
  {
    label: 'hours',
    value: 'hour',
  },
  {
    label: 'days',
    value: 'day',
  },
  {
    label: 'weeks',
    value: TimeUnit.Week,
  },
  {
    label: 'specific date & time',
    value: 'at',
  },
]

const daysOfTheWeek = [
  {
    label: 'Monday',
    value: 'monday',
  },
  {
    label: 'Tuesday',
    value: 'tuesday',
  },
  {
    label: 'Wednesday',
    value: 'wednesday',
  },
  {
    label: 'Thursday',
    value: 'thursday',
  },
  {
    label: 'Friday',
    value: 'friday',
  },
  {
    label: 'Saturday',
    value: 'saturday',
  },
  {
    label: 'Sunday',
    value: 'sunday',
  },
]

const daysOfTheWeekOrder: Record<string, number> = daysOfTheWeek.reduce(
  (obj, item, index) => ({ ...obj, [item.value]: index }),
  {},
)

const scheduleFirstList = (unit: TimeUnit, value: number) => [
  {
    label: 'at the same time you subscribe the contact',
    value: 'same',
    disabled: (unit === TimeUnit.Day || unit === TimeUnit.Week) && value === 0,
  },
  {
    label: 'at a specific time of the day',
    value: 'specific',
  },
]

const scheduleList = (unit: TimeUnit, value: number) => [
  {
    label: 'same as previous message',
    value: 'same',
    disabled: (unit === TimeUnit.Day || unit === TimeUnit.Week) && value === 0,
  },
  {
    label: 'specific time of the day',
    value: 'specific',
  },
]

type Props = {
  isFirst: boolean
  published: boolean
  item: DelayState
  changeHandler: (item: any) => void
  timezones: TimezoneWithCountryCodeType
  countriesTimezones: CountryTimezone[]
  setLoading: (value: boolean) => void
  type?: string
  text: string
}

type Action =
  | { type: 'SELECT_COUNTRY'; payload: { country: string; timezone: string; at?: string } }
  | {
      type: 'SELECT_UNIT'
      payload: { unit: TimeUnit; value: number; dayOfWeek: string; daysOfWeek: DelayState['daysOfWeek']; time: string }
    }
  | {
      type: 'SELECT_VALUE'
      payload: { value: number; error: string; time: string; dayOfWeek: string; daysOfWeek: DelayState['daysOfWeek'] }
    }
  | { type: 'SELECT_TIME'; payload: { time: string } }
  | { type: 'SELECT_DAY_OF_WEEK'; payload: { dayOfWeek: string; daysOfWeek: DelayState['daysOfWeek'] } }
  | { type: 'SELECT_TIMEZONE'; payload: { timezone: string; at?: string } }
  | { type: 'SELECT_AT'; payload: { at: string | null } }
  | { type: 'SELECT_SCHEDULE'; payload: { time: string; dayOfWeek: string; daysOfWeek: DelayState['daysOfWeek'] } }

const reducer = (state: DelayState, action: Action): DelayState => {
  switch (action.type) {
    case 'SELECT_COUNTRY':
      return { ...state, ...action.payload }
    case 'SELECT_UNIT':
      return { ...state, ...action.payload }
    case 'SELECT_VALUE':
      return {
        ...state,
        value: action.payload.value,
        valueError: action.payload.error,
        time: action.payload.time,
        daysOfWeek: action.payload.daysOfWeek,
      }
    case 'SELECT_TIME':
      return { ...state, time: action.payload.time }
    case 'SELECT_DAY_OF_WEEK':
      return { ...state, daysOfWeek: action.payload.daysOfWeek }
    case 'SELECT_TIMEZONE':
      return { ...state, ...action.payload }
    case 'SELECT_AT':
      return { ...state, at: action.payload.at || undefined }
    case 'SELECT_SCHEDULE':
      return {
        ...state,
        time: action.payload.time,
        daysOfWeek: state.unit === TimeUnit.Week ? action.payload.daysOfWeek : {},
      }
    default:
      throw new Error('Unexpected delay type')
  }
}

export default function Delay({ isFirst, published, item, text, timezones, countriesTimezones, changeHandler }: Props) {
  const [state, dispatch] = React.useReducer(reducer, item)

  React.useEffect(() => {
    changeHandler(state)
  }, [state, changeHandler])

  const min = state.unit === TimeUnit.Day || state.unit === TimeUnit.Week ? 0 : 1

  const showNumber = state.unit === TimeUnit.Immediate || state.unit === 'at'

  return (
    <div className="campaign-item-content-delay">
      <div>
        <div>
          <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
            {text}
            {published && (
              <div
                style={{
                  float: 'right',
                }}
              >
                <Tooltip title="Changing this delay will not immediately affect current subscriptions. See the support article for details.">
                  <Icon color="error">info</Icon>
                </Tooltip>
              </div>
            )}
          </Typography>
          <Row>
            <Icon style={{ margin: '0.5rem 0.5rem 0 0', color: 'rgba(0, 0, 0, 0.54)' }}>timer</Icon>
            <div style={{ width: showNumber ? '0' : '80px' }}>
              {!showNumber && (
                <Input
                  type="number"
                  name="value-input"
                  value={`${state.value}`}
                  error={state.valueError}
                  inputProps={{ min }}
                  onChange={({ value }: any) => {
                    const error = parseInt(value, 10) < min ? ERROR_DELAY : ''
                    const payload = {
                      value: Number(value),
                      error,
                      time:
                        (state.unit === TimeUnit.Day || state.unit === TimeUnit.Week) &&
                        value === '0' &&
                        state.time === ''
                          ? defaultTime
                          : state.time,
                      dayOfWeek: '',
                      daysOfWeek:
                        state.unit === TimeUnit.Week && value === '0' && state.time === ''
                          ? defaultDaysOfWeek
                          : state.daysOfWeek,
                    }
                    dispatch({ type: 'SELECT_VALUE', payload })
                  }}
                />
              )}
            </div>
            <div style={{ width: showNumber ? '100%' : '190px' }}>
              <Select
                value={state.unit}
                name="subscription-contacts-select-unit"
                values={getUnitList(isFirst)}
                onChange={({ value: unit }: any) => {
                  const value = unit === TimeUnit.Immediate || unit === 'at' ? 0 : Math.max(1, Number(state.value))
                  dispatch({
                    type: 'SELECT_UNIT',
                    payload: {
                      unit,
                      value,
                      dayOfWeek: '',
                      daysOfWeek: unit === TimeUnit.Week && state.time ? defaultDaysOfWeek : {},
                      time: unit !== TimeUnit.Week && unit !== TimeUnit.Day ? '' : state.time,
                      ...(unit === 'at' ? { at: moment().tz(state.timezone).format() } : { at: null }),
                    },
                  })
                }}
              />
            </div>
            {(state.unit === TimeUnit.Day || state.unit === TimeUnit.Week) && (
              <div style={{ flex: 1 }}>
                <Select
                  name="subscription-contacts-select-schedule"
                  value={state.time === '' ? 'same' : 'specific'}
                  values={isFirst ? scheduleFirstList(state.unit, state.value) : scheduleList(state.unit, state.value)}
                  onChange={({ value }: any) => {
                    const payload = {
                      time: value === 'same' ? '' : defaultTime,
                      dayOfWeek: '',
                      daysOfWeek: value === 'same' ? {} : defaultDaysOfWeek,
                    }
                    dispatch({ type: 'SELECT_SCHEDULE', payload })
                  }}
                />
              </div>
            )}
          </Row>
        </div>
        {(state.unit === TimeUnit.Day || state.unit === TimeUnit.Week) && (
          <Row>
            {state.time && (
              <React.Fragment>
                {state.unit === TimeUnit.Week && (
                  <div style={{ width: '6rem' }}>
                    <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                      Days
                    </Typography>
                    <Select
                      name="subscription-contacts-select-day"
                      multiple={true}
                      value={Object.keys(state.daysOfWeek).sort(
                        (a, b) => daysOfTheWeekOrder[a] - daysOfTheWeekOrder[b],
                      )}
                      values={daysOfTheWeek}
                      onChange={(res: { value?: string[] }) => {
                        const { value = [] } = res
                        const daysOfWeekValue = (value.length ? value : Object.keys(state.daysOfWeek))
                          .sort((a, b) => daysOfTheWeekOrder[a] - daysOfTheWeekOrder[b])
                          .reduce(
                            (obj, v) => ({
                              ...obj,
                              [v]: true,
                            }),
                            {},
                          )
                        const payload = {
                          dayOfWeek: '',
                          daysOfWeek: daysOfWeekValue,
                        }
                        dispatch({ type: 'SELECT_DAY_OF_WEEK', payload })
                      }}
                    />
                  </div>
                )}
                <div style={{ width: '4rem' }}>
                  <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    Time
                  </Typography>
                  <Time
                    name="subscription-contacts-select-time"
                    value={state.time}
                    onChange={(res: { value?: string }) => {
                      const { value = '' } = res
                      const payload = {
                        time: value,
                      }
                      dispatch({ type: 'SELECT_TIME', payload })
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <TimezonePicker
                    countriesTimezones={countriesTimezones}
                    countryCode={state.country}
                    name="subscription-contacts-select"
                    timezone={state.timezone}
                    timezones={timezones}
                    onChange={(timezone) => {
                      dispatch({
                        type: 'SELECT_TIMEZONE',
                        payload: { timezone },
                      })
                    }}
                  />
                </div>
              </React.Fragment>
            )}
          </Row>
        )}
        {state.unit === 'at' && (
          <React.Fragment>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                  Select a specific date and time
                </Typography>
                <DateTime
                  timezone={state.timezone}
                  name="schedule-date-time"
                  editable={true}
                  value={moment.parseZone(state.at).format('YYYY-MM-DDTHH:mm')}
                  onChange={({ value }: { value: moment.Moment | null }) => {
                    const at = value ? changeTimezoneAndKeepDate(value, state.timezone).format() : null
                    dispatch({
                      type: 'SELECT_AT',
                      payload: { at },
                    })
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TimezonePicker
                  countriesTimezones={countriesTimezones}
                  countryCode={state.country}
                  name="subscription-contacts-select"
                  timezone={state.timezone}
                  timezones={timezones}
                  onChange={(timezone, country) => {
                    const payload = {
                      country,
                      timezone,
                      at: state.at ? moment.tz(state.at, noTimezoneDateTimeFormat, timezone).format() : '',
                    }

                    dispatch({
                      type: 'SELECT_COUNTRY',
                      payload,
                    })
                  }}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(),
}))
